.dashboard-main {
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.widget-button{
    color: var(--white);
    border-color:var(--secondary);
    background-color: var(--secondary);
    display: inline-block;
    font-weight: 400;
    /* color: #212529; */
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 8px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.facebookLoader { animation-duration: 1s; animation-fill-mode: forwards;  animation-iteration-count: infinite; animation-name: placeHolderShimmer; animation-timing-function: linear;  background: #f6f7f8;  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);background-size: 1000px 104px;  height: 100px;  position: relative;  overflow: hidden;
}
.facebookLoader .inter-crop {background: #FFF; width: 20px; height: 100%;  position: absolute; top: 0;  left: 100px;}

.facebookLoader .inter-right--top {background: #FFF; width: 100%; height: 20px;  position: absolute; top: 20px;  left: 100px;}

.facebookLoader .inter-right--bottom { background: #FFF; width: 100%; height: 20px; position: absolute;  top: 60px; left: 100px;}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.sub-div-01 span{
  font-family:"lato";
}

.widget-view-a{
  color: black;
  font-weight: 600;
}

/*.sub-div-01 div {
  margin-top:10px;
}

.sub-div-01 div span {
    width: 200px;
    padding: 10px;
}*/
/*.banner-type-content{
  height: 30% !important;
  width: 31% !important;
}*/

#type-button{
  position: fixed;
  top:500px;
}


.dashboard-right {
  /*position: fixed;*/
  z-index: 10;
  width: 30vw;
  right: 50px;
}

.dashboard-profile {
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:0px;
}

.close{
  z-index: 1;
  cursor: pointer;
}

.referral-end-content{
  height:200px;
}

.error{
  color: red;
  font-family: 'Lato';
  font-size: 16px;
}

.dash-prof-img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white) !important;
  border: 1px dashed var(--primary);
  box-shadow: 5px 5px 10px 0px var(--primary-rgba);
}

.dash-prof-img img {
  width: 70px;
}

.dash-prof-img-in {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dash-prof-name {
  /*margin-top: 5px;
  margin-bottom: 5px;*/
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  color: var(--text-black);
}

.drop-profile-creds div {
    max-width:300px;
    overflow: hidden;
    font-family: Lato;
    font-weight: 600;
    font-size: 15px;
    background-color: #2DA77D;
    color: var(--white);
    display: inline-block;
    width: max-content;
    margin-top: 10px;
    margin-top: 2px;
    padding: 8px 12px 9px 12px;
    border-radius: 8px;
    margin-top: 2%;
    margin-bottom: 0px;
}

.drop-profile-creds-faculty div{
  max-width: 140px;
    overflow: hidden;
    font-family: Lato;
    font-weight: 600;
    font-size: 15px;
    background-color: var(--primary);
    color: var(--white);
    /* display: inline-block; */
    /* width: max-content; */
    /* margin-top: 10px; */
    margin-bottom: 10px;
    padding: 8px 12px 9px 12px;
}

.dashboard-profile .drop-profile-creds {
  margin-left: 0;
  text-align: center;
  line-height: 1.4;
  cursor: pointer;
  margin-bottom:10px;
  font-family:lato;
}

.dashboard-profile .drop-profile-creds:hover {
  opacity: 0.85;
}

.dashboard-title{display: flex; width: 100%; padding: 0 15px 15px;  justify-content: space-between; align-items: center;}
.dashboard-title .drop-profile-creds{margin:0; padding:0; font-family: Source Sans Pro;
  font-size: 18px; flex-direction: column; display: flex;
  font-weight: 600;
  color: var(--primary);} 
.dashboard-title .drop-profile-creds span{width:50px; margin-top: 5px; display: flex; height: 2px; background-color: #000;}
.dashboard-title .viewall-btn{padding: 0;background-color: var(--primary) !important;}

.dashboard-schedule {
  z-index: 10;
  bottom: 25px;
  /* right: 50px; */
  width: 100%;
  /* height: calc(100vh - 440px); */
  background-color: var(--primary-rgba);
  border-radius: 6px;
  padding: 24px 0px 100px 0px;
  /* overflow-y: auto; */
  margin-top: 40px;
  margin-left: 34px;
}

.dashboard-left .dashboard-schedule{display: none;}

.dash-sch-head {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:nth-child(2) span {
    border: 1px solid inherit;
    text-decoration: none;
    display: flex;
    color:inherit;
    padding: 5px 15px;
    border-radius: inherit;
    background-color:inherit;
}

.quick-links-widget button{
  font-size: 14px;
}

.dash-sch-head div {
  display: flex;
  font-family: Lato;
  color: var(--text-black2);
  font-size: 16px;
  font-weight: 600;
  flex-direction: column;
}

.dash-sch-head span {
  color: var(--primary);
  font-size: 18px;
  margin-bottom: 6px;
}

.dash-lecture {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

#activity_type {
  position: absolute;
  background-color: var(--primary);
  color: var(--white);
  font-family: Lato;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  /* right: 30px;
  bottom: 0px; */
  text-transform: uppercase;
  margin-left: 0px;
  margin-top: -19px;
}

.dash-lecture:last-child {
  margin-bottom: 0;
}

.dash-lecture-date {
  /*width: 118px;*/
  padding-left: 18px;
  padding-right: 11px;
  border-right: 1px solid var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: var(--secondary);
  padding-top: 14px;
  padding-bottom: 14px;
  white-space: nowrap;
}

.dash-lecture-date div {
  font-size: 16px;
  color: var(--text-black);
}

.dash-lecture-date span {
  font-size: 14px;
  color: var(--primary);
  margin-top: 5px;
  margin-bottom: 5px;
}

.dash-lecture-date-third{
  padding-left: 20px;
  /* padding-right: 66px; */
  /* border-right: 1px solid var(--white); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Lato;
  width: 100%;
}

.dash-lecture-date-third div {
  /* background-color: #c5e5ea; */
  color: black;
  font-family: Lato;
  /* font-size: 12px; */
  /* font-weight: 600; */
  padding: 3px 15px;
  /* text-transform: uppercase; */
  /* width: 100px; */
  text-align: center;
  white-space: nowrap;
}


.dash-lecture-date-third div:first-child {
  text-transform: uppercase;
  background-color: #c5e5ea;
  border: 1px solid #e3e3ec;
}

.dash-lecture-date-third div:last-child {
  font-family: Lato;
  /* padding: 3px 15px; */
  /* border: 2px solid #c5e5ea; */
  /* text-align: center; */
  white-space: nowrap;
  /* border-radius: 17px; */
  color: white !important;
  margin-top: 5px;
}

.dash-lecture-date-third div:last-child a{
  color: white !important;
  margin-right: 4px;
  padding: 4px;
  text-align: center;
  background-color: var(--primary);
  border-radius: 5px;
}

.dash-lecture-date-third span {
  color: #000000;
}

.dash-lecture-title {
  padding-left: 20px;
  padding-right: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  font-family: Lato;
  justify-content: center;
}

.dash-lecture-title a, .dash-lecture-title span {
  margin-top: 4px;
  font-family: Lato;
  color: var(--primary);
  font-weight: 700;
  font-size: 15px;
}

.dash-lecture-title a:hover {
  opacity: 0.85;
}

.dash-lec-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-black);
  display: flex;
  /* overflow: hidden; */
  /* -webkit-box-decoration-break: clone; */
  box-decoration-break: clone;
  line-height: 1.4;
  /* max-height: 23px; */
  /* -webkit-line-clamp: 1; */
  /* -webkit-box-orient: vertical; */
  /* max-width: 128px; */
  word-break: break-word;
  width: 100%;
  /* border: 1px solid; */
  white-space: normal;
}

.dash-lec-title-hrs{
  font-size: 14px;
  font-weight: 600;
  color:var(--black);
  display: -webkit-box;
  overflow: hidden;
  box-decoration-break: clone;
  line-height: 1.4;     /* fallback */
  max-height: 23px;      /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-width: 180px;
}

.dash-lec-hrs {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-black3);
  margin-top: 8px;
  margin-bottom: 6px;
  display: -webkit-box;
  /*overflow: hidden;*/
  box-decoration-break: clone;
  line-height: 1.4;     /* fallback */
  /*max-height: 20px; */     /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  /*-webkit-box-orient: vertical;*/
}

.dash-lec-hrs-faculty{
  color:var(--black);
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 6px;
}

.dashboard-left {
  width: 40vw;
}

.dashboard-main h1 {
  font-size: 20px;  
} 

.dashboard-subjects {
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.dash-subject {
  width: 30%;
  height: 150px;
  margin-bottom: 30px;
  border-radius: 10px;
  transition: .2s all;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px;
}
.dash-subject-fac {
  width: 30%;
  height: 150px;
  margin-bottom: 30px;
  border-radius: 10px;
  transition: .2s all;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.dash-subject span {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: var(--white);
  text-decoration: none;
  text-align: center;
  line-height: 1.36;
  display: -webkit-box;
  overflow: hidden;
  box-decoration-break: clone;
  height: 39px;      /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.dash-subject-fac span {
  font-family: Lato;
  font-size: 11px;
  font-weight: 600;
  color: var(--white);
  text-decoration: none;
  text-align: center;
  line-height: 1.36;
  display: -webkit-box;
  overflow: hidden;
  box-decoration-break: clone;
  height: 39px;      /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.dash-fac-detail {
  font-size: 15px;
  line-height: 1.36;
}
.dash-fac-detail span{
  margin-top: 6px;
}

.dashboard-practices {
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.dash-practice {
    width: 47%;
    height: 150px;
    margin-bottom: 30px;
    border-radius: 10px;
    transition: .2s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    color: var(--white);
    text-decoration: none;
    background-color: var(--primary-rgba);
    padding: 16px 18px;
    background-repeat: no-repeat;
    background-position: 160px 7px;
    box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2);
}

.dash-practice:nth-child(2n) {
  margin-left: 6%;
}

#dash-practice1 {
  background-color: var(--secondary-rgba);
}

.dash-practice span {
  width: 100%;
  color: var(--text-black2);
}

.dash-practice div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dash-practice button {
  /*font-size: 16px !important;*/
  white-space: nowrap !important;
  /*padding: 5px 14px 6px 14px !important;*/
  padding: 5px 10px 5px 10px !important;
  /*background-color: #c5e5ea !important;*/
  color: var(--white);
}

.ra-button{
  white-space: nowrap !important;
  padding: 1px !important;
  background-color:var(--primary-rgba) !important;
  color: #489198 !important;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
  border: 1px solid var(--primary);
  background-color: var(--primary);
  border: none;
  padding: 6px 20px 8px 20px;
  border-radius: 40px;
  cursor: pointer;
}

.dash-practice a {
  margin-right: 10px;
}

.dash-practice a:last-child {
  margin-right: 0px;
}

.dash-subject div {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.dash-subject-fac div {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.dash-subject:hover {
  box-shadow: 4px 4px 12px -6px var(--text-black2);
}
.dash-subject-fac:hover {
  box-shadow: 4px 4px 12px -6px var(--text-black2);
}

.dash-subject:nth-child(3n), .dash-subject:nth-child(3n - 1) {
  margin-left: 5%;
}
.dash-subject-fac:nth-child(3n), .dash-subject-fac:nth-child(3n - 1) {
  margin-left: 5%;
}

.dash-subject img {
  width: 40px;
}
.dash-subject-fac img {
  width: 40px;
}

.dashboard-exercise {
  margin-top: 40px;
}

.dashboard-exercise a {
  display: flex;
  align-items: center;
}

.dashboard-exercise h1 {
  margin-bottom: 0;
}

.dashboard-exercise a img {
  width: 18px;
  margin-left: 18px;
}

.dashboard-category {
  margin-top: 40px;
}

.dashboard-category a {
  display: flex;
  /*align-items: center;*/
}

.dashboard-category .careerBoosterLink{align-items: flex-start;}

.dashboard-category h1 {
  margin-bottom: 0;
}

.dashboard-category a img {
  width: 18px;
  margin-left: 18px;
}

.dashboard-category .course-image-div  img {
  width: 177px;
  height: 128px;
  object-fit: contain;
  padding-top: 16px;
}

.dashboard-assignment-list{
    display: flex;
    margin-top: 25px;
  }
  .dashboard-assignment-list a{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 15px;
    box-shadow: 0px 6px 8px #1D73F238;
    padding: 15px;
    border-radius: 12px;
    position: relative;
    margin-right: 10px;
  }

.welcomeheading{display: flex; margin-bottom:25px; line-height: 28px; font-family: Lato; font-size: 18px;}
.welcomeheading span{font-weight: bold; color:var(--primary); margin-left:10px;}

  .dashboard-assignment-list a:nth-child(1){
    background: transparent linear-gradient(122deg, #FF8A57 0%, #FF5502 100%) 0% 0% no-repeat padding-box;
  }
  .dashboard-assignment-list a:nth-child(3){
    background: transparent linear-gradient(121deg, #7ecacd 0%, #129ac2 100%) 0% 0% no-repeat padding-box;
  }

  .dashboard-assignment-list a:nth-child(2){
    background: transparent linear-gradient(121deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;
  }

  .dashboard-list-downarrow {cursor: pointer; width:100%; color:#000; display: flex; justify-content: center; align-items: center; height:40px; margin-top:-50px; position: relative; background: rgb(255,255,255);
background: linear-gradient(97deg, rgba(255,255,255,0.31976540616246496) 5%, rgba(250,254,255,1) 48%, rgba(255,255,255,0.5550595238095238) 85%); }

  .dashboard-assignment-list a .arrow {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: rgba(255,255,255,0.2);
  }

   .dashboard-assignment-list a .icon {
    width: 48px;
    height: 48px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }


.all-courses {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 20px;
}

.goto-course {
  display: block;
  position: relative;
  width: 30%;
  height: 200px;
  border-radius: 15px;
  background-color: var(--primary-rgba);
  border: 1px solid #dddddd;
  overflow: hidden;
  cursor: pointer;
}

.goto-course-list{
  display: block;
  position: relative;
  height: 200px;
  border-radius: 3px;
  /*background-color: var(--primary-rgba);*/
  border: 1px solid #dddddd;
  overflow: hidden;
  cursor: pointer;
}

.goto-course:hover img {
  transform: scale(1.32);
}

/*.goto-course-list:hover img {
  transform: scale(1.32);
}*/

.goto-course:nth-child(3n), .goto-course:nth-child(3n - 1) {
  margin-left: 5%;
}

.goto-course-list:nth-child(3n), .goto-course-list:nth-child(3n - 1) {
  margin-left: 5%;
}

.course-image {
  width: 90%;
  height: 140px;
  top: 0;
  overflow: hidden;
}

.course-image-div {
  width: 100%;
  height: 100%;
  /*background-color: var(--secondary-rgba);*/
  transition: .3s all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.course-image-div img {
  width: 60px;
  transition: .3s all;
}

.goto-course span{
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  color: var(--text-black);
  text-decoration: none;
}

.goto-course-list span {
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.59;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.course-div {
  width: 30%;
  display: block;
  position: relative;
  height: 200px;
  border-radius: 15px;
  border: 1px solid #eeeeee;
  overflow: hidden;
  cursor: pointer;
}

.course-div div:first-child {
  /* position: absolute; */
  /* top: 0; */
  height: 100px;
  width: 100%;
  background-color: var(--secondary-rgba);
  display: flex;
  align-items: center;
  justify-content: center;
}

.course-div div img {
  height: 60px;
  transition: .2s all;
}

.course-div:hover img {
  transform: scale(1.16);
}

.course-div div:last-child {
  line-height: 1.5;
  color: var(--text-black);
  font-family: Lato;
  font-size: 15px;
  /* padding-left: 50px; */
  /* padding-right: 15px; */
  padding-top: 25px;
  /* padding-bottom: 20px; */
  justify-content: center;
  text-align: center;
}

.course-div:nth-child(3n), .course-div:nth-child(3n - 1) {
  margin-left: 5%;
}

.courses-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.courses-popup span {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  color: var(--text-black);
}

.courses-popup-tray {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -15px !important;
}

.courses-popup-tray li {
  min-width: 180px;
  max-width: 180px;
  height: 200px;
  margin-left: 17px;
  margin-right: 17px;
  display: block;
  position: relative;
}

.courses-popup-tray .course-div {
  width: 100% !important;
  margin-right: 0;
  margin-left: 0;
}

.dash-lec-title-list{
      /* padding-left: 20px; */
    padding-right: 30px;
    /* padding-bottom: 20px; */
    display: flex;
    flex-direction: column;
    /* font-family: Lato; */
    justify-content: center;
    /* width: 416px; */
    border: 1px solid;
}


  .dash-lecture-date-title{    
    width: 400px;
    /* padding-right: 25px; */
    /* border-right: 1px solid var(--white); */
    display: inline-block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    color: var(--secondary);
    padding-top: 14px;
    padding-bottom: 14px;
    /* white-space: nowrap; */
    /* margin-right: 75px; */
    margin-left: 8px;
  }

  .dash-lecture-date-title{width:100%; display:flex; flex-direction: row; align-items: flex-start;}
  .dash-lecture-date-title .left{width:100%; display:flex; flex-direction: column; align-items: flex-start;}

  .dashboard-main-learner{
    z-index: 10;
    width: 100%;
    /* display: flex; */
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
  }

  .dashboard-main-learner span{
    color:#415973 !important;
  }

  .category-list{
    width:200px;
    margin-right: 20px;
    margin-bottom: 16px;
    box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2)
  }

  .dashboard-practices-course{
    margin-top: 18px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }


  /* .column1x3{
    width:100% !important;
  }
  .column1x2{
    width:66.66% !important;
  }
  .column1x1{
    width:33.33% !important;
  } */

  .dash-flex2-title-label{
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    line-height: 1.35;
    /* font-size: 16px; */
    font-size: 13px;
    margin-bottom: 0px;
    max-height: 44px;
    color:var(--black) !important;
    padding-top:3px;
    margin-top: 2px;
  }

  .dash-flex2-title-1{
    font-size:14px !important;
    display: -webkit-box;
    overflow: hidden;
    box-decoration-break: clone;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    line-height: 1.35;
    margin-bottom: 14px;
    max-height: 44px;
  }

  .dash-subject-fac{position: relative;}
  .dash-subject-fac .classMap{display:flex; font-family: Lato; padding: 0 0 5px; white-space:normal; flex-wrap: wrap; width:100%; height:auto; margin:0;}
  .dash-subject-fac .classMap div{display:flex; width:100%; height:auto; align-items: flex-start; margin:1px 0; color:#fff; font-size:12px;}
  .dash-subject-fac .classMap div i{width:100%; max-width:72px; text-align: center; font-style: normal; white-space: nowrap; margin-right:4px; overflow:hidden; display: block; font-size:12px;}
  .dash-subject-fac .classMap .morecontent{width:100%; margin-top:10px; font-size:12px; color:#fff; height:auto; display: flex; justify-content: center;}
  .dash-subject-fac .hoverOverlay{position: absolute; border-radius: 10px; padding:10px; transition:all 0.2s ease; height:0px; width:100%; font-family: Lato; margin:0; opacity: 0; visibility: hidden; top:0; right:0; bottom:0; left:0; background-color:rgba(0,0,0,0.8);}
  .dash-subject-fac .hoverOverlay ul{display: flex; flex-direction: column; margin:0; padding:0; width: 100%; font-family: Lato;}
  .dash-subject-fac .hoverOverlay ul li{display: flex;margin:2px 0; width: 100%; justify-content: flex-start; color:#fff; font-size:12px; font-family: Lato;}
  .dash-subject-fac .hoverOverlay ul li i{max-width: 80px; white-space: nowrap; overflow: hidden; margin-right:5px; justify-content: flex-start; font-family: Lato;}
  .dash-subject-fac .hoverOverlay ul li p{margin:0}
  .dash-subject-fac:hover .hoverOverlay{height:100%; opacity: 1; visibility: visible;}


/*.IADashboardWrapper1 Css Start From here*/



.IADashboardWrapper1{display: flex; flex-direction: column; width: 100%; font-family: Lato;}
.IADashboardWrapper1 .main-layout-section{display: flex; width: 100%; flex-direction: column!important;}
.IADashboardWrapper1  .rowWrapper{display:flex; width:100%; flex-direction: column; overflow-x: auto; position: relative;}
.IADashboardWrapper1  .rowWrapper .rowInner {display:flex; width:100%;}

.overlay-actions{visibility:hidden; display: flex; padding:5px 10px; background-color: #e1e1e1; z-index:1; align-items: center; opacity: 0; border-radius:5px; position: absolute; font-family: lato; font-weight: 400; right:10px; top:10px;}
.overlay-actions a{margin-left: 10px; display: flex; align-items: center; font-family: lato; text-decoration: underline; color:#000; font-weight: 400; font-size: 14px;}
.overlay-actions a:first-child{margin-left:0px;}
.overlay-actions a img{max-width: 20px; margin-right: 5px;}
.overlay-actions a:hover{text-decoration: none;}
.IADashboardWrapper1 .rowWrapper .rowWidget{min-height: 45px;}
.IADashboardWrapper1 .rowWrapper .rowWidgetPreview{min-height: 11px;}
.IADashboardWrapper1 .rowWrapper h3{font-size: 18px; padding:10px; width:100%; margin:0; display: flex;  font-weight: bold;}

.IADashboardWrapper1 .column-widget.rowInnerBorder{border:2px dashed  #000!important}
.IADashboardWrapper1 .rowWrapperBorder{border:2px dashed  #000!important}


.IADashboardWrapper1  .rowWrapper .column-widget:hover .overlay-actions{visibility:visible; opacity: 1;}
.IADashboardWrapper1  .rowWrapper .rowWidget:hover .overlay-actions{visibility:visible; opacity: 1;}
.IADashboardWrapper1 a{color:inherit}

.IADashboardWrapper1 .main-layout-section .left-section-layout{display: flex; flex-direction: column; width: 100%; max-width: 33.333%;}
.IADashboardWrapper1 .main-layout-section .right-section-layout{display: flex; flex-direction: column; width: 100%; max-width: 66.666%}

.IADashboardWrapper1 .IADashboardContainer1{display: flex; width: 100%; flex-direction: column;}
.IADashboardWrapper1 .userinfo-widget{display: flex; width: 100%; flex-direction: column; border:0px solid inherit; padding:0px; border-radius:inherit;}
.IADashboardWrapper1 .userinfo-widget div{font-weight: inherit; color:inherit;  font-size: inherit; margin-bottom: 15px;}
.IADashboardWrapper1 .userinfo-widget > div:first-child{margin-bottom: 0;}
.IADashboardWrapper1 .userinfo-widget p{ margin:0; padding:0; margin-top:5px; white-space: normal; line-height:24px; font-size:inherit; color:inherit;}

.IADashboardWrapper1 .userinfo-widget .explorebtn{display: flex; margin:15px 0 0}
.IADashboardWrapper1 .userinfo-widget .explorebtn a{ border: 1px solid inherit;background: transparent;border: 1px solid white;box-shadow: 0px 1px 0px 4px #555; text-decoration: none; color:x;  padding: 8px 15px; font-size: 17px;  border-radius: 5px;}

.IADashboardWrapper1 .column-widget{display: flex; flex-shrink: 0; position: relative; margin:0px;padding:6px; border-radius:inherit; flex-direction: column; width:inherit}

.IADashboardWrapper1 .column-widget.column1x1{width: 33.333333333%;}
.IADashboardWrapper1 .column-widget.column1x2{width:66.66666666%;}
.IADashboardWrapper1 .column-widget.column1x3{width: 100%;}
.IADashboardWrapper1 .column-widget.column2x1{width: 33.333333333%;}
.IADashboardWrapper1 .column-widget.column2x2{width:66.66666666%;}
.IADashboardWrapper1 .column-widget.column2x3{width: 100%;}



.col-75-partition{
  display: flex;
}

.col-75-partition select{
  width:68%;
}

.popup-content.banner-type-content .col-75 .multi-select{
  width: 68% !important;
  justify-content: space-between;
}

.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item.paid-course-single-item:first-child {
    background: transparent linear-gradient(122deg,#ff8a57,#ff5502) 0 0 no-repeat padding-box;margin-bottom: 0;
}

.IADashboardWrapper1 .courses-widget.image-jugad ol.referal-widget li{ background-repeat: no-repeat; background-position: center; background-size: cover; background-image: url(https://test-rakeshxp.s3.ap-south-1.amazonaws.com/My+Coach+Banner.png);}
.IADashboardWrapper1 .courses-widget.image-jugad ol.referal-widget li .course-thumb{opacity: 0;}
.IADashboardWrapper1 .courses-widget.image-jugad ol.referal-widget li .course-title{opacity: 0;}
.IADashboardWrapper1 .courses-widget.image-jugad ol.referal-widget li .course-description{opacity: 0;}

.IADashboardWrapper1 .courses-widget.className-widget-heading .userinfo-widget{
  padding:0;
  flex-direction: row;
}

.IADashboardWrapper1 .courses-widget.className-widget-heading .userinfo-widget h3{padding:0}
.IADashboardWrapper1 .userinfo-widget div{margin-bottom: 0;}
.IADashboardWrapper1 .userinfo-widget div:first-child{margin-right: 0px;}
.IADashboardWrapper1 .userinfo-widget div:last-child{margin-right:0px;}
.IADashboardWrapper1 .userinfo-widget div p{margin-top:0px;}

.IADashboardWrapper1 .className-widget-heading img {
    max-width: 50px !important;
}

.carousel .slide{text-align: inherit;}

.IADashboardWrapper1 .courses-widget.className-widget-heading{
      display: flex;
    box-shadow: 0 0 0 0;
    padding-top: 0;
    margin: 15px 0 0;
    padding-bottom: 0;
}

.admin-loader-v1{
    border: 4px solid #ffffff;
    border-radius: 50%;
    border-top: 4px solid #80f6a3;
    width: 50px;
    height: 50px;
    animation: admin-spin 1s linear infinite;
}


.IADashboardWrapper1 .courses-widget .quick-links-widget{display: flex; border-radius:5px; width: 100%; background-color: #fff; flex-direction: column; padding:0px;}
.IADashboardWrapper1 .courses-widget .quick-links-widget .heading-title{font-weight: bold; margin-bottom: 10px; color:#000; font-size: 19px; text-align: center;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol{margin:0; padding: 0;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li{margin:10px 0 0; flex-direction: row; font-weight: 500;  line-height: 22px; font-size: 14px; max-width: none; border-radius:5px; padding: 10px; border:1px solid #ddd; align-items: center;  list-style-type: none; justify-content: space-between; width: 100%; display: flex;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol{max-height: 261px; overflow-y: auto;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li .notesTitle{text-transform:capitalize;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li .videoTitle{text-transform:capitalize;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li .action-button{padding: 5px 10px; border-radius:5px; color:#fff;  background-color: var(--primary); margin-right:0;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li:first-child{margin-top:0}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:first-child{flex:1;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:first-child a{background-color: #3ac47d; color:#fff}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:last-child{width:130px; padding: 10px; color:#999; display: flex; justify-content: center;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:last-child a{border:1px solid #ddd; text-decoration: none; color:#fff; padding:5px 15px; border-radius:5px;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li:nth-child(even) div:last-child a {background-color:var(--primary); border-radius:40px}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:nth-child(2) a {border:1px solid #ddd; text-decoration: none; display:flex; color:#fff; padding:5px 15px; border-radius:5px; background-color: #1d73f2;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li:nth-child(odd) div:last-child a {background-color: var(--primary); border-radius:40px;     padding: 6px 20px 6px 20px;
  font-family: Source Sans Pro;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li:nth-child(odd){border-left:4px solid #3ac47d;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li:nth-child(even){border-left:4px solid #f7b924;}

.IADashboardWrapper1 .courses-widget{display: flex; width: 100%; flex-direction: column; padding:0px; margin:0px;}
.IADashboardWrapper1 .courses-widget ol{margin:0; padding: 0; flex-wrap: wrap; width: 100%; display: flex;}
.IADashboardWrapper1 .courses-widget ol li{margin:10px 0px; width: 100%; align-items: center; max-width:none; padding: 0; flex-direction: column; display: flex; }
.IADashboardWrapper1 .courses-widget ol li .course-title{font-family:inherit; margin-top: 8px; color:#000; font-size:14px; text-align: center;}
.IADashboardWrapper1 .courses-widget ol li .course-thumb{border:1px solid #ddd; display: block; width: 100%; max-width: 80px; overflow: hidden; height: 80px; max-height: 80px;}



.IADashboardWrapper1 .courses-widget.text-color-custom .userinfo-widget div{color:#000}
.IADashboardWrapper1 .courses-widget.text-color-custom .userinfo-widget p{color:inherit}
.IADashboardWrapper1 .courses-widget.text-color-custom .userinfo-widget div:first-child{margin-bottom: 0;}

.IADashboardWrapper1 .courses-widget.image-left .userinfo-widget{display: flex; flex-direction: row;}
.IADashboardWrapper1 .courses-widget.image-left .userinfo-widget > div:first-child{max-width: 100px; margin-right: 15px;  margin-bottom: 0; display: flex; flex-direction: column;}
.IADashboardWrapper1 .courses-widget.image-left .userinfo-widget > div:last-child{flex:1; margin-bottom: 0;}

.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(1) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(2) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(3) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(4) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(5) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(6) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(7) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(8) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(9) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(10) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(11) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(12) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}


.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(1) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(2) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(3) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(4) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(5) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(6) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(7) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(8) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(9) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(10) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(11) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(12) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}

.IADashboardWrapper1 .carousel-root .carousel-slider{padding-bottom: 0px;}
.IADashboardWrapper1 .carousel .control-dots{margin: 0;}
.IADashboardWrapper1 .carousel .control-dots .dot{width:12px; height:12px; border:1px solid #2c2c2c;}
.IADashboardWrapper1 .carousel .control-dots .dot.selected{background-color: #ddd; border:1px solid #ddd;}

.IADashboardWrapper1 .courses-widget.background-color-random  .slick-slide p{color: #fff; font-size: 17px;}
.IADashboardWrapper1 .courses-widget.background-color-random  .slick-slide div{color: #fff; font-size: 24px; margin-bottom:0px;}
.IADashboardWrapper1 .column-widget .slick-dots{bottom:0;}
.IADashboardWrapper1 .column-widget .slick-dots li button:before{font-size: 14px;}
/*.IADashboardWrapper1 .slick-slider {margin:0 -30px;}
.IADashboardWrapper1 .slick-slide{margin-left: 15px; margin-right: 15px;}*/

.popup-content.banner-type-content{height:auto; padding: 20px!important; font-family: "lato"; overflow: inherit;}
.popup-content.banner-type-content .sub-div-01 span{font-size: 17px; font-weight: bold;}
.popup-content.banner-type-content .col-75{margin-top: 0;}
.popup-content.banner-type-content .col-75 label{padding:0;}
.popup-content.banner-type-content .row{margin-top: 15px;}
.popup-content.banner-type-content .col-25 label{padding:0;}
.popup-content.banner-type-content #type-button.dialogactions{position:static; margin-top:15px; border-top: 1px solid #ddd; padding-top: 10px; display: flex; width: 100%; justify-content: center;}
.popup-content.banner-type-content .col-75 .multi-select{width: 100%; margin-left: 0;}

.popup-content.banner-type-content .dropdown-content{padding: 10px;}
.popup-content.banner-type-content .dropdown-content label{padding: 10px;}
.popup-content.banner-type-content .multi-select .dropdown-container{height: auto;}

.popup-content.row-type-content{height:auto!important; padding: 20px!important; font-family: "lato"; overflow: inherit;}
.popup-content.row-type-content .sub-div-01 span{font-size: 17px; font-weight: bold;}
.popup-content.row-type-content .col-75{margin-top: 0;}
.popup-content.row-type-content .col-75 label{padding:0;}
.popup-content.row-type-content .row{margin-top: 15px;}
.popup-content.row-type-content .col-25 label{padding:0;}
.popup-content.row-type-content #type-button.dialogactions{position:static; margin-top:15px; border-top: 1px solid #ddd; padding-top: 10px; display: flex; width: 100%; justify-content: center;}
.popup-content.row-type-content #type-button.dialogactions button{position: static;}
.popup-content.row-type-content .dropdown-content{padding: 10px;}
.popup-content.row-type-content .multi-select .dropdown-container{height: auto;}

.banner-type-content input {
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 5px 16px;
    border-radius: 4px;
    width: 160px;
    width: 100%;
}


.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item { background: transparent linear-gradient(122deg,#ff8a57,#ff5502) 0 0 no-repeat padding-box;position: relative; margin: 0 10px;   max-width: none;align-items: flex-start;  padding: 15px;  margin-bottom: 15px;  width: auto!important;  display: flex!important;  border-radius: 12px;  border:1px;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-thumb{ width:70px; height: 70px; flex-shrink: 0; padding:10px; border-radius:100%; background-color: #fff; display: flex; align-items: center; justify-content: center; margin-right: 10px;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-thumb img{max-width: 100%;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-title{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-title a{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-content{display: flex; flex-direction: column;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-desc{font-size: 15px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-description{font-size: 18px; text-align: left; line-height: 24px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .arrow{position: absolute; right: 10px;display: flex;align-items: center;justify-content: center;  top: 10px;  width: 28px;  height: 28px;  border-radius: 100%; background-color: hsla(0,0%,100%,.2);}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .arrow a{display: flex; align-items: center;}  
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .arrow a img{max-width: 100%;}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:first-child{margin-top: 0;}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide{border-radius:5px;}

.IADashboardWrapper1 .courses-widget.paid-course .slick-slide .paid-course-item{border:1px solid #ddd; background-color: #fff; background: #fff; }
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide .paid-course-item  .course-title{color: var(--primary);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide .paid-course-item  .course-title a{color: var(--primary);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide .paid-course-item  .course-description{color:#666; line-height: 30px;}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide .paid-course-item .arrow{background-color: var(--primary);}

.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item{border:1px solid #ddd; background-color: #fff; background: #fff; }
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-title{color: var(--primary);}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-title a{color: var(--primary);}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-description{color:#666; line-height: 30px;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .arrow{background-color: var(--primary);}

/* .IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(1) .paid-course-item {background: transparent linear-gradient(122deg, #FF8A57 0%, #FF5502 100%) 0% 0% no-repeat padding-box; margin-bottom: 0;}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(2) .paid-course-item{background: transparent linear-gradient(123deg, #FFCE00 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(3) .paid-course-item{background:transparent linear-gradient(123deg, #fb00ff 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(4) .paid-course-item{background: linear-gradient(to right, #02aab0, #00cdac);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(5) .paid-course-item{background: linear-gradient(to right, #ffe259, #ffa751);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(6) .paid-course-item{background: linear-gradient(to right, #1a2980, #26d0ce);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(7) .paid-course-item{background: linear-gradient(to right, #ed4264, #ffedbc);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(8) .paid-course-item{background: linear-gradient(to right, #c21500, #ffc500);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(9) .paid-course-item{background: linear-gradient(to right, #00d2ff, #3a7bd5);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(10) .paid-course-item{background: linear-gradient(to right, #52c234, #061700);} */


.IADashboardWrapper1 .courses-widget.paid-course{padding-top: 0; padding-bottom: 0;}
.IADashboardWrapper1 .courses-widget.paid-course ol li { position: relative; width:100%; max-width: none; align-items: flex-start; padding: 15px; margin-bottom:15px; border-radius:12px;}
.IADashboardWrapper1 .courses-widget.paid-course ol li .course-title{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper1 .courses-widget.paid-course ol li .course-title a{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper1 .courses-widget.paid-course ol li .course-desc{font-size: 15px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget.paid-course ol li .course-description{font-size: 18px; line-height: 24px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget.paid-course ol li .arrow{    position: absolute; right: 10px;display: flex;align-items: center;justify-content: center;  top: 10px;  width: 28px;  height: 28px;  border-radius: 100%;
  background-color: hsla(0,0%,100%,.2);}
  .IADashboardWrapper1 .courses-widget.paid-course ol li:first-child{margin-top: 0;;}
.IADashboardWrapper1 .courses-widget.paid-course ol li:nth-child(1){background: transparent linear-gradient(122deg, #FF8A57 0%, #FF5502 100%) 0% 0% no-repeat padding-box; margin-bottom: 0;}
.IADashboardWrapper1 .courses-widget.paid-course ol li:nth-child(2){background: transparent linear-gradient(123deg, #FFCE00 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course ol li:nth-child(3){background:transparent linear-gradient(123deg, #fb00ff 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course ol li:nth-child(4){background:transparent linear-gradient(123deg, #00e1ff 0%, #1e0b03 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course ol li:nth-child(5){background:transparent linear-gradient(123deg, #00dcff 0%, #ff5a14 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course ol li:nth-child(6){background:transparent linear-gradient(123deg, #ff000c 0%, #fd14ff 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course ol li .course-thumb{ width:70px; height: 70px; border-radius:100%; background-color: #fff; display: flex; align-items: center; justify-content: center; margin-bottom: 10px;}
.IADashboardWrapper1 .courses-widget.free-course ol li .course-thumb img{max-width: 100%; width: 60px; height: 60px;}

.IADashboardWrapper1 .courses-widget.free-course ol li a{width:100%}

.IADashboardWrapper1 .courses-widget.free-course ol{ flex-wrap: wrap; width: 100%; display: flex; margin:0; padding: 0;}
.IADashboardWrapper1 .courses-widget.free-course ol li {margin:8px 8px; border-radius: 10px; height: 120px; transition: all 0.2s ease; ; width: 100%; align-items: center;  max-width: 30%;  padding: 0;  flex-direction: column; display: flex;}
.IADashboardWrapper1 .courses-widget.free-course ol li .course-thumb { display: flex; background-color: #eef0f3; border-radius:5px;  align-items: center;  justify-content: center; font-size: 39px;  border: 0px solid #ddd;  width: 100%;  max-width: 125px;  overflow: hidden;  height:85px; margin:0 auto;  max-height: 85px;}
.IADashboardWrapper1 .courses-widget.free-course ol li .course-title {font-family:inherit; font-weight: 400; margin-top: 8px;  color: #000;  font-size: 16px; text-align: center;}
.IADashboardWrapper1 .courses-widget.free-course ol li .course-title a {font-family: inherit; margin-top: 8px;  color: #000;  font-size: 16px; text-align: center;}
.IADashboardWrapper1 .courses-widget.free-course ol li:hover{transform: translateY(-6px); box-shadow: 4px 4px 12px -6px var(--text-black2);};
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(1) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(2) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(3) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(4) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(5) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(6) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(7) .course-thumb{background-color: #faecef; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(8) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(9) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(10) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(11) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(12) .course-thumb{background-color: #eef0f3; color:#fff;}

.IADashboardWrapper1 .courses-widget ol.referal-widget li:first-child{margin-top: 0;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li { position: relative; width:100%; max-width: none; align-items: flex-start; padding: 15px; margin-bottom:0px; border-radius:12px;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-title{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-title a{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-desc{font-size: 15px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-description{font-size: 18px; line-height: 24px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .arrow{position: absolute; right: 10px;display: flex;align-items: center;justify-content: center;  top: 10px;  width: 28px;  height: 28px;  border-radius: 100%;
  background-color: hsla(0,0%,100%,.2);}
.IADashboardWrapper1 .courses-widget ol.referal-widget li{background: linear-gradient(to right, var(--primary), var(--primary));}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-description{font-size: 18px; line-height: 24px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-thumb{ width:70px; height: 70px; border-radius:100%; background-color: #fff; display: flex; align-items: center; justify-content: center; margin-bottom: 10px;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-thumb img{max-width: 100%; width: 60px; height: 60px;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li a{text-decoration: underline; color:#000}
.IADashboardWrapper1 .courses-widget ol.referal-widget li a:hover{text-decoration: none; color:#000}

.IADashboardWrapper1 .courses-widget.tag-course ol li { align-items: flex-start; display: flex; justify-content: space-between;  transition: all .2s ease;  width: 100%;  max-width: 31%;  border-radius: 18px;  color: #fff;  margin: 0 0 0 20px;  padding: 15px;  flex-direction: row;min-height: 150px}
.IADashboardWrapper1 .courses-widget.tag-course ol li a{display: flex;width:100%; justify-content: space-between;}
.IADashboardWrapper1 .courses-widget.tag-course ol li:first-child { background-image: url(../../public/images/veranda-innovation.png); background-repeat: no-repeat;  background-size: cover;  margin-left: 0;}
.IADashboardWrapper1 .courses-widget.tag-course ol li:nth-child(2) {background-color: rgba(255,82,2,.7);}
.IADashboardWrapper1 .courses-widget.tag-course ol li:nth-child(3) {background: transparent linear-gradient(121deg,#48a9a6,#48a9a6) 0 0 no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.tag-course ol li .course-thumb{ margin-left: 15px; order:2;  align-items: center;  justify-content: center; display: flex;  background-color: rgba(96,96,25,.2);  border-radius: 100%;  width: 60px;  height: 60px;}
.IADashboardWrapper1 .courses-widget.tag-course ol li:first-child a{display: flex; justify-content: space-between;}

.IADashboardWrapper1 .courses-widget.tag-course ol li div.arrow{ margin-left: 15px; order:2;  align-items: center;  justify-content: center; display: flex;  background-color: rgba(96,96,25,.2);  border-radius: 100%;  width: 19px;  height: 19px;}
.IADashboardWrapper1 .courses-widget.tag-course ol li:first-child div.arrow .content{order:1}
.IADashboardWrapper1 .courses-widget.tag-course ol li:first-child .content h3{color:#fff; font-size: 17px;}
.IADashboardWrapper1 .courses-widget.tag-course ol li:first-child .content p{color:#fff; font-size: 17px; margin:10px 0 0 ;}
.IADashboardWrapper1 .courses-widget.tag-course ol li:first-child .content p:last-child{color:#fff; font-size: 12px;}
.IADashboardWrapper1 .courses-widget.tag-course ol li .course-title {font-size: 17px; order:1; color: #fff;  margin: 0 0 15px;}
.IADashboardWrapper1 .courses-widget.tag-course ol li .course-title a{font-size: 17px; order:1; color: #fff;  margin: 0 0 15px;}

.IADashboardWrapper1 .courses-widget.image-background{padding:0; border-radius:5px;}
.IADashboardWrapper1 .courses-widget.image-background img{padding:0; border-radius:5px; display: flex;}
.IADashboardWrapper1 .userinfo-widget div img{max-width: 100%;}

.app-body.widget-dashboard .app-div{width: auto; padding:10px 20px 25px 20px; top: 50px; left:150px; height: calc(100vh - 55px);}
.header2.new-dashboard-navigation{width:100%; max-width: 150px; left:0; top:0; padding:0; height: calc(100vh - 0px);}
.header2.new-dashboard-navigation .sidebar-links.new-dashboard-navigation{flex-direction: column;}
.header2.new-dashboard-navigation .sidebar-links.new-dashboard-navigation a{flex-direction: column; width: 100%;}
.header2.new-dashboard-navigation .sidebar-links.new-dashboard-navigation a span:first-child{margin-bottom: 10px; width:45px; height:45px;}
.header2.new-dashboard-navigation .sidebar-links.new-dashboard-navigation a span:last-child{margin-left:0px;word-break: break-word;white-space: normal;}

.IADashboardWrapper1 .courses-widget .quick-recommendations-widget {display: flex; width: 100%; flex-direction: column; padding:0px; background-color: #fff;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget .heading-title{display: flex; width: 100%; justify-content: flex-start; font-weight: bold; text-align: left;  margin-bottom: 10px; color:#000; font-size: 19px;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol{margin:0; padding: 0; flex-direction: column; overflow-y: auto; max-height: 261px; flex-wrap: inherit;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li{margin:10px 0 0; flex-direction: row;  line-height: 22px; font-size: 14px; max-width: none; border-radius:5px; padding: 10px; border:1px solid #ddd; align-items: flex-start;  list-style-type: none; width: 100%; display: flex;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li .course-thumb{ overflow: hidden; flex-shrink: 0; display: flex; width:64px; height: auto; margin-right: 10px; border:1px solid #ddd;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li .course-thumb img{max-width: 100%;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li a{display: flex; align-items: flex-start; width:100%; color: #000; padding:0px; font-weight: bold;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li:hover{background-color: #f6f6f6;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li .details{color:#999; margin-top: 5px; display: flex; flex-wrap: wrap;;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li .details span:first-child{color:#000; font-weight: normal;  margin-left:0;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li .details span:last-child{color:#999;  font-weight: normal; margin-left: 10px;}


.arrow img{width:20px !important;}
.app-header.widget-dashboard{width:auto; z-index: 220002; left: 150px; padding:10px 20px 20px 20px; margin-bottom: 0; top: 0px; right:0;}

.app-header.widget-dashboard .profile{/*justify-content: space-between; width: 100%;*/ align-items: center;}
.app-header.widget-dashboard .profile .TopHeaderLinks{display: flex; font-family: Lato; font-size: 17px; justify-content: space-between; align-items: center; width: 100%;}
.app-header.widget-dashboard .profile .TopHeaderLinks .innovationText{display: flex; align-items: center;}
.app-header.widget-dashboard .profile .TopHeaderLinks .innovationText img{margin-right: 10px;}

.widget-dashboard .referralDialog-overlay{z-index:220000!important;}
.widget-dashboard .referralDialog-content{height:auto; padding:0px !important; background-color:#ffe198!important}
.widget-dashboard .referralDialogWrapper{display:flex; flex-direction:row; position: relative; font-family: Lato;}
.widget-dashboard .referralDialogWrapper .leftsection{width:60%; position: relative; display:flex; padding:15px; flex-direction: column;}
.widget-dashboard .referralDialogWrapper .rightsection{width:40%; display:flex; flex-direction: column;  background-size: cover; background-image: url("../../public/images/referral-rightbg1.png"); background-repeat:no-repeat; }
.widget-dashboard .referralDialogWrapper .shape1{position:absolute; z-index:0; width:150px; background-color: #00f8b9; right:-40px; top:-40px; height: 150px; border-radius:100px;}
.widget-dashboard .referralDialogWrapper .shape2{position:absolute; width:150px; z-index:0; background-color: #0096f8; left:-40px; bottom:-40px; height: 150px; border-radius:100px;}
.widget-dashboard .referralDialogWrapper .headingtitle{border-bottom:1px solid #000; font-weight:bold; margin-bottom: 15px; padding-bottom: 10px; font-size:26px; color:#000;}
.widget-dashboard .referralDialogWrapper ol{margin:0; padding:0; display: flex; flex-direction:column; overflow-y: auto; max-height:300px;}
.widget-dashboard .referralDialogWrapper ol li{margin:15px 0 0; padding:0; display: flex; flex-direction:column;border: 0px solid #0edf0e;padding:0px; border-radius: 5px;}
.widget-dashboard .referralDialogWrapper ol li:first-child{margin-top: 0;}
.widget-dashboard .referralDialogWrapper ol li .question-title{font-weight:bold; margin-bottom:5px; padding-bottom:0px; font-size: 17px; color:#000;}
.widget-dashboard .referralDialogWrapper ol li .labelblock{display: flex; width: 100%; flex-wrap: wrap;}
.widget-dashboard .referralDialogWrapper ol li .labelinputblock{display: flex; width: 100%; flex-wrap: wrap;}
.widget-dashboard .referralDialogWrapper ol li input[type="text"]{max-width: none; width: 100%;}
.widget-dashboard .referralDialogWrapper ol li label{display: flex; width:100%; max-width: 50%; align-items: flex-start;justify-content:flex-start; padding:3px;}
.widget-dashboard .referralDialogWrapper ol li label input{margin-right:10px;}
.widget-dashboard .referralDialogWrapper ol li label span{min-width:60px; margin-right: 10px;}
.widget-dashboard .referralDialogWrapper ol li input[type="text"]{border:1px solid #ddd; padding:10px 10px; font-family: Lato;}
.widget-dashboard .referralDialogWrapper .question-action{text-align:right; align-items: flex-end; justify-items: flex-end;  border-top:0px solid #ddd; margin-top:15px; padding-top:0px;}
.widget-dashboard .referralDialogWrapper .question-action button{background-color: #000; color:#fff}
/*.IADashboardWrapper1 Css ends here*/

.app-div.app-dashboard{left:250px;top:70px; height:calc(100vh - 75px)}
.app-div.app-dashboard .dashboard-schedule{width:auto}
.app-div.app-dashboard .dashboard-schedule .viewall-btn{background-color: transparent!important;}
.app-div.app-dashboard .dashboard-schedule .viewall-btn a{border:0px solid #ddd; font-family: Source Sans Pro; text-decoration: none; display: flex;  color: #fff;  padding: 5px 15px;  border-radius: 5px;  background-color: var(--primary);}

.app-div.widget-app-dashboard{left:150px; top:70px; height:calc(100vh - 75px)}
.app-div.widget-app-dashboard .dashboard-schedule{width:auto}
.app-div.widget-app-dashboard .dashboard-schedule .viewall-btn{background-color: transparent!important;}
.app-div.widget-app-dashboard .dashboard-schedule .viewall-btn a{border:0px solid #ddd; font-family: Source Sans Pro; text-decoration: none; display: flex;  color: #fff;  padding: 5px 15px;  border-radius: 5px;  background-color: var(--primary);}
.app-div.widget-app-div{left:150px; top:70px; height:calc(100vh - 75px)}
.app-div.widget-app-div .dashboard-schedule{width:auto}
.app-div.widget-app-div .dashboard-schedule .viewall-btn{background-color: transparent!important;}
.app-div.widget-app-div .dashboard-schedule .viewall-btn a{border:0px solid #ddd; font-family: Source Sans Pro; text-decoration: none; display: flex;  color: #fff;  padding: 5px 15px;  border-radius: 5px;  background-color: var(--primary);}

.app-list-div .list-sticky{width:auto; left:0;}

.list-sticky.notwidgetheader{width:auto; left:0; right:0;}

.page-header-test{z-index: 2;}
.list-sticky{width: 87vw; height: calc(100vh - 180px); top:110px}
/*.app-div{left: 150px; top:70px; height:calc(100vh - 75px)}*/

.app-body.widget-dashboard .referral-thanksmsg{display: flex; flex-direction: column; font-family: Lato;}
.app-body.widget-dashboard .referral-thanksmsg .thanksmsg{font-size: 26px; justify-content: center;  align-items: center;display: flex; flex-direction: column; font-family: Lato; font-weight: bold;}
.app-body.widget-dashboard .referral-thanksmsg .thanksmsg i{margin-bottom:0px; max-width: 65px;}
.app-body.widget-dashboard .referral-thanksmsg .thanksmsg i img{max-width: 100%;}
.app-body.widget-dashboard .referral-thanksmsg .addmore-msg{display: flex; align-items: center; margin-top: 20px; flex-direction: column; width: 100%;}
.app-body.widget-dashboard .referral-thanksmsg .addmore-msg a{font-size: 17px; color:#fff; background-color:#1bce52; padding:8px 25px; display: flex; border-radius:5px; text-transform: capitalize; margin-top: 15px; text-decoration: none;}
.app-body.widget-dashboard .referral-thanksmsg .addmore-msg a:hover{font-size: 17px; text-decoration: none;}
.app-body.widget-dashboard .referral-end-content{height:auto}
/*Responsive Css Start From here*/

@media only screen and (max-width: 1150px){
  .app-header.widget-dashboard{background-color: #fff; position: fixed;}
  .app-body.widget-dashboard .app-div{background-color: var(--white); border-radius:0px;}
}

@media only screen and (max-width: 1150px){
  .app-header.widget-dashboard{background-color: #fff; position: fixed;}
  .app-body.widget-dashboard .app-div{background-color: var(--white); border-radius:0px;}
  .IADashboardWrapper1 .main-layout-section{flex-direction: column;}
  .IADashboardWrapper1 .main-layout-section .left-section-layout{flex-direction: column;  max-width: none;}
  .IADashboardWrapper1 .main-layout-section .right-section-layout{flex-direction: column;  max-width: none;}
}


@media only screen and (max-width: 1050px){
  .IADashboardWrapper1 .rowWrapper{overflow-x: auto;}
  .IADashboardWrapper1 .rowWrapper .rowInner{min-width:900px;}
}

@media only screen and (max-width: 850px){
  .IADashboardWrapper1 .courses-widget.tag-course ol li{width: 29%;}
}



@media only screen and (max-width: 600px){
  .app-body.widget-dashboard .app-div{left: 0;  top:90px; height: calc(100vh - 135px);}
  .IADashboardWrapper1 .courses-widget.free-course ol{width: 100%; flex-wrap: wrap;}
  .IADashboardWrapper1 .courses-widget.free-course ol li{max-width: 29%; height: auto;}
  .app-div.widget-app-dashboard{left:0;}
  .app-header.widget-dashboard{position: static;}
  .app-div.widget-app-dashboard{top:95px;}
  .IADashboardWrapper1 .rowInner .tag-course.courses-widget ol{flex-wrap: nowrap; flex-direction: row;}
  .IADashboardWrapper1 .rowInner .courses-widget.tag-course ol li {margin-left: 20px;}
  .IADashboardWrapper1 .rowInner .courses-widget.tag-course ol li:first-child{margin-left:0px;}
}




@media only screen and (max-width: 812px) and (orientation: landscape){
  .IADashboardWrapper1 .tag-course.courses-widget ol{flex-direction: column;}
  .IADashboardWrapper1 .tag-course.courses-widget ol li{max-width: none; margin:0 0 15px 0; width: 100%;}
  .app-body.widget-dashboard .app-div{right:0;}
  .IADashboardWrapper1 .rowInner .tag-course.courses-widget ol{flex-wrap: nowrap; flex-direction: row;}
  .IADashboardWrapper1 .rowInner .courses-widget.tag-course ol li {margin-left: 20px;}
  .IADashboardWrapper1 .rowInner .courses-widget.tag-course ol li:first-child{margin-left:0px;}
}

  
@media only screen and (max-width:670px) and (orientation: landscape){
  .IADashboardWrapper1 .courses-widget.free-course ol li{max-width: 29%; }
 
 }

   
@media only screen and (max-width:570px) and (orientation: landscape){
  .IADashboardWrapper1 .courses-widget.free-course ol{flex-direction: row;}
  .IADashboardWrapper1 .courses-widget.free-course ol li{max-width: 29%; }
 
 }

  @media only screen and (max-width: 1000px) {  

    .dashboard-main .dashboard-left{width:100%}
    .dashboard-schedule{width:auto}
    .dashboard-main .dashboard-left .dashboard-practices-course{width: 100%; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-main .dashboard-left .dashboard-practices-course .course-div{width: 100%; flex-shrink: 0;  max-width: 300px;}

    .dashboard-main .dashboard-left .dashboard-subjects{width: 100%; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-main .dashboard-left .dashboard-subjects .dash-subject-fac{width: 100%; flex-shrink: 0;  max-width: 300px;}

    .dashboard-subjects{width: 100%; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-subjects .dash-subject{width: 100%; flex-shrink: 0; margin-left:15px;  max-width: 300px; flex-wrap: nowrap;}
    .dashboard-subjects .dash-subject:first-child{margin-left:0}
    .dashboard-exercise .dashboard-practices{width: 100%; padding:10px 0; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-exercise .dashboard-practices .dash-practice{width: 100%; flex-shrink: 0; margin:0 10px;  max-width: 300px; flex-wrap: nowrap;}
  
    .dashboard-practices{width: 100%; padding:10px 0; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-main .dashboard-right{display:none;}
    .drop-profile-creds{position: relative;}
    .drop-profile-creds div{width: 100%;}
    .drop-profile-creds + #edit-prof{position: absolute; top:0;}

  }

  @media screen and (max-width: 770px){
    .app-div.app-dashboard{left:0; width: calc(100%);}
    .dashboard-main .dashboard-left .dash-subject{margin-left: 20px;}
    .dashboard-main .dashboard-left .dash-subject:first-child{margin-left:0px;}
    .dashboard-main .dashboard-left .dashboard-schedule{display: flex; flex-direction: column; margin-left: 0; margin-bottom: 50px;}
    .dash-lecture {flex-direction: column;  padding: 10px;  background-color: #fff;  align-items: flex-start; }
    .app-div.app-dashboard .dashboard-schedule .viewall-btn {
        background-color: transparent!important;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
    .app-div.app-dashboard, .app-div.app-studymaterial, .app-div.app-webinar{left:0; width:100%; height: calc(100vh - 139px);}
  }

  @media only screen and (max-width: 600px) {  
    .dashboard-exercise:first-child{margin-top:0}
    .dashboard-main .dashboard-left .dashboard-practices-course .course-div{width: 100%; flex-shrink: 0;  max-width: 200px;}
    .dashboard-subjects .dash-subject{width: 100%; flex-shrink: 0;  max-width:200px; flex-wrap: nowrap;}
    .dash-subject-fac{ width: 100%; flex-shrink: 0;  max-width:200px; flex-wrap: nowrap;}
    .sm-layers{width:100%}
    .sm-layers .sm-chapters{width: 100%;}
    .dashboard-schedule{padding: 10px;}
    .dash-lecture{flex-direction:column; padding:10px; background-color:#fff; align-items: flex-start;}
    .dash-lecture .dash-lecture-date{display: flex;  border-right:0px none; flex-direction: row; padding:0px}
    .dash-lecture .dash-lecture-date span{margin:0 5px}
    .dash-lecture-date-title{flex-direction:column; width:100%; margin-left:0}
    .dash-lecture-date-third{align-items: flex-end; padding-left:0}
    .dash-lecture-date-third div + div{padding-right:0; margin-top:15px;}
    .dash-sch-head{padding-left:0;}
    .dash-sch-head div{display: flex;}
    .dashboard-main .dashboard-left .dashboard-schedule{margin-left:0; margin-bottom:50px;display:flex; flex-direction: column;}

    .app-body.widget-dashboard .app-div{width: 100%;}

    .app-header.widget-dashboard{width: auto; left: 6px;  right: 15px;}

    .IADashboardWrapper1 .courses-widget.tag-course ol{flex-direction: column;}
    .IADashboardWrapper1 .courses-widget.tag-course ol li{width: 100%; max-width: none; margin:0 0 15px 0}
    .IADashboardWrapper1 .courses-widget.tag-course ol li a{width: 100%;}
    body.mycoachpageadded{background-color: #fff!important;}

    .app-header.widget-dashboard .profile .TopHeaderLinks{flex-direction: column;}
    .app-header.widget-dashboard .profile .TopHeaderLinks .appDownload{display: none;}
    .app-header.widget-dashboard .innovationText{flex-direction: column; margin-bottom:5px; font-size: 16px; align-items: flex-start;}
    .app-header.widget-dashboard .profile{flex-direction: column;}
    .app-header.widget-dashboard .profile #dropper{display: flex; position: absolute; top:10px; right:10px;}
    .app-header.widget-dashboard .logout-setting{top:12px; right:30px; position: absolute;}
    .mycoachpageadded .header2.mobilenavigationActive{height:auto}
    .app-header.widget-dashboard .profile .TopHeaderLinks{justify-content: flex-start; align-items: flex-start;}
    .app-header.widget-dashboard .profile .TopHeaderLinks .innovationText{align-items: flex-start;}

    .app-div.widget-app-div{left:0}
    .app-div.widget-app-div{top:85px}

    .app-header.widget-dashboard .app-div.mycoach-div{height: calc(100vh - 283px);}

    .widget-dashboard .referralDialog-content{width: 85%!important;}

    .app-div.app-dashboard{left:0}

    .dashboard-main .dashboard-left .dash-subject{margin-left: 20px;}
    .dashboard-main .dashboard-left .dash-subject:first-child{margin-left:0px;}
    
  }

  @media screen and (max-width: 770px) and (orientation: landscape){  
    .app-div.app-dashboard{left:0; width: calc(100%);}
    .dashboard-main .dashboard-left .dash-subject{margin-left: 20px;}
    .dashboard-main .dashboard-left .dash-subject:first-child{margin-left:0px;}
    .dashboard-main .dashboard-left .dashboard-schedule{display: flex; flex-direction: column; margin-left: 0; margin-bottom: 50px;}
    .dash-lecture {flex-direction: column;  padding: 10px;  background-color: #fff;  align-items: flex-start; }
    .app-div.mycoach-div {height: calc(100vh - 203px)}
    .app-div.app-dashboard, .app-div.app-studymaterial, .app-div.app-webinar{left:0; width:100%; height: calc(100vh - 60px);}
  }

  @media screen and (max-width: 812px) and (orientation: landscape){  
    .app-div.app-dashboard{left:0; width: calc(100%);}
    .dashboard-main .dashboard-left .dash-subject{margin-left: 20px;}
    .dashboard-main .dashboard-left .dash-subject:first-child{margin-left:0px;}
    .dashboard-main .dashboard-left .dashboard-schedule{display: flex; flex-direction: column; margin-left: 0; margin-bottom: 50px;}
    .dash-lecture {flex-direction: column;  padding: 10px;  background-color: #fff;  align-items: flex-start; }
    .app-div.app-dashboard .dashboard-schedule .viewall-btn {
          background-color: transparent!important;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          margin-bottom: 10px;
      }
      .app-div.app-dashboard, .app-div.app-studymaterial, .app-div.app-webinar{left:0; width:100%; height: calc(100vh - 60px);}
  }
/*Responsive Css ends here*/