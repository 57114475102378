.action-btn{
    display: flex;
    justify-content: space-between;
    flex-grow: 1;


}

.action-btn button{
    /* height: 50px; */
    margin-top : 7px !important

}
.select-range{
    display: flex;
    flex-grow: 1;
    align-items: center;
}
/* App.css or inline CSS */

.organization-toggle {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px; 

}

.organization-toggle p {
  margin: 0;
  padding-right: 10px;
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  justify-items: center;
  
  
}
span.slider{
  padding: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--primary);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: white;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
