html:lang(ar) *{
	text-align: right;
}

.languageTranslator.admin{position: static;}
.languageTranslator.faculty{position: static;}

html:lang(ar) input[type="text"]{direction: rtl}
html:lang(ar) input[type="password"]{direction: rtl}
html:lang(ar) input[type="tel"]{direction: rtl}
html:lang(ar) input[type="email"]{direction: rtl}
html:lang(ar) input[type="datetime-local"]{direction: rtl}
html:lang(ar) select{direction: rtl}
html:lang(ar) textarea{direction: rtl}

html:lang(ar) .OTPFormWrapper .step1 input{text-align: right;  direction: rtl}
html:lang(ar) .login_right .passwordInput{padding-right:20px; direction: rtl;}
html:lang(ar) .prof-options-div{align-items: flex-end;}
html:lang(ar) .changePasswordWrapper .input_field input{padding-right: 20px; direction: rtl;}
html:lang(ar) input[type="text"]{padding-right: 20px; direction: rtl}
html:lang(ar) input[type="email"]{padding-right: 20px; direction: rtl}
html:lang(ar) input[type="password"]{padding-right: 20px; direction: rtl}
html:lang(ar) .forgot-right input{padding-right:10px; direction: rtl;}
html:lang(ar) .login_right .userNameInput{padding-right: 20px;}
html:lang(ar) .togglePassword-on-forgot{left:38px}
html:lang(ar) .togglePassword{left:2px; top:0!important}
html:lang(ar) .button-class{justify-content: flex-end;}
html:lang(ar) .IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:first-child{order:2}
html:lang(ar) .IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:last-child{order:1; width:auto}
html:lang(ar) .IADashboardWrapper1 .courses-widget .quick-links-widget ol li:first-child{order:2}
html:lang(ar) .IADashboardWrapper1 .courses-widget .quick-links-widget ol li:last-child{order:1}
html:lang(ar) .IADashboardWrapper1 .courses-widget .quick-links-widget ol li a{margin-right: 15px;}
html:lang(ar) .dashboard-title .drop-profile-creds{order:2}
html:lang(ar) .app-div.widget-app-dashboard .dashboard-schedule .viewall-btn{order:1}
html:lang(ar) .dash-practice div{order:unset!important; background-position: left;}
html:lang(ar) .ask-doubt-content textarea{padding-right:20px; direction: rtl;}
html:lang(ar) .liveprojects-block .design-block li .thumb-content{ justify-content: flex-end;}
html:lang(ar) .dash-flex2 div:nth-child(1){order:2}
html:lang(ar) .dash-flex2 div:nth-child(2){order:1}
html:lang(ar) .dash-flex2 div:nth-child(2) .dash-flex2-img{ justify-content: flex-start;}
html:lang(ar) .lec-mode-dash{left:0; border-radius:0 4px 4px 0}
html:lang(ar) .popup-inputs input[type="text"]{padding-right: 20px;}
html:lang(ar) .popup-inputs-session input, html:lang(ar) .popup-inputs2 input, html:lang(ar) .popup-inputs-session select, html:lang(ar) .popup-inputs5 input{padding-right: 20px;}
html:lang(ar) .popup-data input[type="text"]{padding-right: 20px;}
html:lang(ar) .popup-data input[type="email"]{padding-right: 20px;}
html:lang(ar) .popup-data input[type="password"]{padding-right: 20px;}
html:lang(ar) .popup-data select{padding-right: 20px;}
html:lang(ar)  .popup-session-content .popup-actions{justify-content: flex-start;}
html:lang(ar) .grid-item .grid-up-v1 .grid-sec{order:2}
html:lang(ar) .grid-item .grid-up-v1 .grid-sec{order:1}
html:lang(ar) .grid-item .grid-up-v1 .title-grid{order:2}
html:lang(ar) .grid-item .grid-up-v1 .grid-actions{order:1}
html:lang(ar)  .grid-item .grid-up-v1 .title-grid4{order:2; justify-content:flex-end; align-items: center;}
html:lang(ar)  .grid-item .grid-up-v1 .title-grid4 .dp_div{order:2; margin-right: 0; margin-left: 12px;}
html:lang(ar)  .grid-item .grid-up-v1 .title-grid4 .dp_div + div{order:1}
html:lang(ar) .grid-item .grid-up-v1 #section-button2:last-child{margin-left: 0;}
html:lang(ar) .grid-item .grid-up-v1 .title-grid2{order:2; justify-content:flex-end; align-items: center;}
html:lang(ar) .grid-item .grid-up-v1 .grid-actions{order:1;}
html:lang(ar) .grid-item .grid-up-v1 .grid-sec{order:1;}
html:lang(ar) .grid-item .grid-up-v1 .grid-image{order:2;}
html:lang(ar) .delete-down button{padding: 0 20px!important;}
html:lang(ar) .edit-div-5{text-align: left;}
html:lang(ar) .back-to-recent{ padding-right: 10px;}
html:lang(ar) .faculty-dash-actions{left:24px;}
html:lang(ar) .faculty-dash-actions a:nth-child(1){margin-right: 10px;}
html:lang(ar) .dash-test-bottom div:nth-child(1){order:2; align-items: flex-end;}
html:lang(ar) .dash-test-bottom div:nth-child(2){order:1}
html:lang(ar) .sm-units.newDesign .sm-unit-div .sm-unit-div1 span{text-align: left;}
html:lang(ar) .sm-layers h3{text-align: left;}
html:lang(ar) .sm-layers .sm-content-title *{text-align: center;}
html:lang(ar) .your-answer{left:0;}
html:lang(ar) .grid-item .grid-up{text-align: left;}
html:lang(ar) .grid-item .grid-up .title-grid{text-align: left;}
html:lang(ar)  .grid-item .grid-up span{text-align: left;}
html:lang(ar) .assg-header1 *{text-align: left;}
html:lang(ar) .asm-info *{text-align: left;}
html:lang(ar)  #session-band{left:0;right: auto;}
html:lang(ar) .session-content{align-items: flex-end;}
html:lang(ar) .edit-lecture-button{left: 143px !important;}
html:lang(ar) .edit-lecture-button2{left: 98px !important;}
html:lang(ar) .edit-lecture-button3{left: 54px !important;}
html:lang(ar) .edit-lecture-button4{left: 6px !important;}
html:lang(ar) .circle1{text-align: center;}
html:lang(ar) .dash-test-bottom div:first-child{width:100%}