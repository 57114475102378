.blog-post-container {
    background-color: #fff;
}

.action-btn {
    display: flex;
    justify-content: space-between;


}

.app-div.blog-post-container.faculty {
    left: 250px
}

.app-div.blog-post-container {
    left: 150px;
    top: 65px;
    height: calc(100vh - 90px);
}


.app-div.blog-post-container .action-btn {
    border-bottom: 1px solid #dadada;
    padding-bottom: 10px;
}

.app-div.blog-post-container .action-btn h1 {
    margin-bottom: 0;
}

.studioProjectDialog .studioProjectDialogContainer {

    position: relative;
    max-width: 850px;
}

.studioProjectDialog .editor {
    height: 40% !important;
}

.close {
    top: 8px !important;
    right: 15px !important;
    position: absolute;
    padding: 0px;
}



input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
}



.editor-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.editor-form h3 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.editor-form .block {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.editor-form .block label {
    margin: 0;
    padding: 0;
    color: #999
}

.editor-form .block select {
    bordeR: 1px solid #ccc;
    font-size: 14px;
    background-color: transparent;
}

.editor-form .block input {
    bordeR: 1px solid #ccc;
    font-size: 14px;
    background-color: transparent;
    margin: 0;
}

.editor-form .block .ql-editor p {
    font-size: 14px;
    font-style: normal;
}

.editor-form .block .ql-editor.ql-blank::before {
    font-style: normal;
}

.editor-form .block .noteText {
    text-align: right;
}

.ql-editor {
    min-height: 100px !important;
    max-height: 100px;
}

.submit-btn {
    margin: 10px 0px;
}

.blog-post-container img {
    margin-top: 10px;
}

h1 {
    font-size: 1.4rem !important;
}

h1.marginBottomZero {
    margin-bottom: 0;
}

.add-blog {
    height: 40px !important;
}

.submit-btn {
    margin: 0px auto;
    margin-top: 10px;
}

.blog-list table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1em;
    font-family: 'Arial', sans-serif;
    min-width: 400px;

}

.blog-post-container .action-btn .actions {
    width: auto;
}

.blog-post-container .action-btn .actions select {
    max-width: 250px;
}

.blog-list th,
.blog-list td {
    padding: 12px 15px;
    text-align: left;
}

.blog-list th {

    text-transform: uppercase;
}

.blog-list tr {
    border-bottom: 1px solid #dddddd;

}

.blog-list td:nth-child(2),
.blog-list td:last-child {
    cursor: pointer;
}

.blog-list tr:nth-of-type(even) {
    background-color: #f3f3f3;
}



/* Additional styles to align the user name at the end */
.blog-list .user-name {
    text-align: right;
    /* Align text to the right */
    width: 100%;
    /* Ensure it takes up remaining space */
}

.preview-title {
    margin: 10px 0px;
}

.selectedBlogContent {
    max-width: 900px !important;



}

.selected-content {

    overflow: auto;
    max-height: 300px !important;
}

.jodit-wysiwyg {
    max-height: 200px;
}

.jodit-source,
.jodit-source .jodit-source__mirror-fake,
.jodit-container:not(.jodit_inline) {
    max-height: 200px;
}

.jodit_fullsize-box_true {
    z-index: 2000000000000 !important;
}

.flex {
    display: flex;
    gap: 20px;
}

.jodit-container:not(.jodit_inline) .jodit-workplace {
    max-height: 100px !important;
}