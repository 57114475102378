.organization-overview {
    font-family: Arial, sans-serif;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    min-height: 100vh;
  }
  
  .breadcrumb {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .student-details-container {
    margin-bottom: 20px;
  }
  
  .student-details-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .student-email-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 300px;
    text-align: center;
    background-color: #f9f9f9;
    color: #333;
    font-size: 14px;
  }
  
  .action-buttons {
    margin-top: 20px;
  }
  
  .action-buttons .btn {
    padding: 10px 15px;
    margin: 5px;
    background-color:var(--primary);
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .action-buttons .btn:hover {
    background-color: #5a3fcc;
  }
  
  .action-buttons .btn.disabled {
    background-color: #ddd;
    color: #aaa;
    cursor: not-allowed;
  }
  
  .user-not-found {
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  