.pdf-web {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: #ffffff;
	z-index: 1000000 !important;
	background-color: #f5f5f5;
}

.pdf-noweb .e-pv-viewer-container {
	width: 711px !important;
	/*height: 430px!important;*/
}

.downloadMessage{display: flex; width: 100%; justify-content:center;  background-color: #ddd; padding:20px; font-family: Lato;}

.pdf-noweb .e-pv-page-container{
	width: 702px !important;
}

.pdf-noweb .e-pv-page-div{
	left:20px !important;
}

.pdf-web .e-pv-viewer-container {
  width: 1248px !important;
}

.pdf-web .e-pv-page-container{
  width: 1239px !important;
}

.pdf-web .e-pv-page-div{
  left:288px !important;
}



.PdfHighlighter {
  left: 0;
  top: 0;
}

.full-view {
	position: absolute;
	top: 63px;
	right: 40px;
	z-index: 100;
	width: 46px;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: transparent;
	cursor: pointer;
	float: right;
}

.full-view img {
	width: 40px;
}


.pdfViewer.removePageBorders .page{
	margin: 0px auto 10px auto;
	border: none;
	width: 100% !important;
	height:auto !important;
}

.pdfViewer .canvasWrapper{
	width: 100% !important;
    height: auto !important;
}

.textLayer{
	width: 100% !important;
	height: auto !important;
}

.pdfViewer .page canvas{
	width: 100% !important;
    height: auto !important;
}

@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-react-pdfviewer/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-notifications/styles/material.css";

.e-pv-open-document-container ,.e-pv-annotation-container,.e-pv-print-document-container,.e-pv-comment-container,.e-pv-submit,.e-pv-text-search-container,.e-pv-undo-container,.e-pv-redo-container,.e-pv-thumbnail-view-button, .e-pv-bookmark-button ,.e-pv-text-select-tool-container,.e-pv-pan-tool-container{
	visibility: hidden;
}