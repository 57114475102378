.university-list-container {
  padding: 20px;
  background-color: #f9f9f9;
 height: 700px;
 margin-left: 200px;
 object-fit: contain;

  overflow-x: hidden;
}


.university-list-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 1.5rem;
}


.university-list {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 10px;
  scroll-snap-type: x mandatory;
}


.university-card {
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-snap-align: start;
  padding: 20px;
}


.university-logo {
  width: 100%;
  height: 150px;
  object-fit: contain;
  background-color: #f5f5f5;
  padding: 20px;
}


.university-details {
  padding: 15px;
  text-align: center;
  width: 100%;
}


.university-details h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}


.programs-container {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
}

.programs-list {
  width: 800px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding-bottom: 10px;
  scroll-snap-type: x mandatory;
}


.program-card {



  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  text-align: bottom;
  gap: 10px;
}


.program-logo {
  width: 60px;
  height: 60px;
  object-fit: cover;
}


.program-info {
  display: inline-flex;
  flex-direction: column;
}

.program-info h4 {
  font-size: 1rem;
  margin: 0;
  margin-bottom: 5px;
}

.program-info p {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}