@import url(https://fonts.googleapis.com/css?family=Lato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200italic,300,300italic,400,400italic,600,600italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Muli:200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,900);
* {
  box-sizing: border-box;
}

:root {
  --primary: #2ea87e;
  --primary-rgba: rgba(167,37,38,0.10);
  --secondary: #FED41E;
  --secondary-rgba:var(--primary-rgba);
  --white: #ffffff;
  --black: #000000;
  --black2: rgba(0,0,0,0.90);
  --text-black: rgba(0,0,0,0.84);
  --text-black2: rgba(0,0,0,0.74);
  --text-black3: rgba(0,0,0,0.60);
  --light-black: rgba(0,0,0,0.20);
  --light-black3: rgba(0,0,0,0.10);
  --light-black2: rgba(0,0,0,0.02);
}

::selection {
  background: #2ea87e;
  background: var(--primary); /* WebKit/Blink Browsers */
  color: #ffffff;
}

::-moz-selection {
  background: #2ea87e;
  background: var(--primary); /* Gecko Browsers */
  color: #ffffff;
}

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  width: 100vw;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  background-color: #2ea87e;
  background-color: var(--primary);
  align-items: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;  
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: rgba(167,37,38,0.10);
  background: var(--primary-rgba);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2ea87e;
  background: var(--primary);
}

html {
  overflow-y: auto;
}

a, button, input, textarea, select, option {
  outline: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

textarea {
  resize: none;
}

input[type=checkbox] {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  background-color: var(--white);
  cursor: pointer;
  margin-right: 14px;
}

button {
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  color: var(--white);
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  background-color: #2ea87e;
  background-color: var(--primary);
  border: none;
  padding: 6px 20px 8px 20px;
  border-radius: 40px;
  cursor: pointer;
}

#button-reverse {
  background-color: white;
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  color: #2ea87e;
  color: var(--primary);
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

button:hover {
  opacity: 0.9;
}

header {
  position: fixed;
  top: 15px;
  top: 0px;
  width: 260px;
  background-color: transparent;
  left: 15px;
  height: calc(100vh - 30px);
  padding-top: 20px;
  padding-bottom: 20px;
}

.header2 {
  z-index: 2200001;
  position: fixed;
  top: 40px;
  width: 20.9vw;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #ffffff;
  background-color: var(--white);
  box-shadow: -4px -4px 15px 0px #ffffff9e, 4px 4px 15px 0px #a3b1c6a8;
  left: 40px;
  height: calc(100vh - 80px);
  height: calc(100%);
  padding-top: 45px;
  padding-bottom: 65px;
  padding-left: 40px;
  padding-right: 40px;
  overflow-y: auto;
  transition: all 0.2s ease;
  z-index: 998;
}

.header2{top:0; left:0; padding-top:0; padding-left:0px; padding-right:0px; width:250px; z-index:12; border-radius: 0px; box-shadow: -3px -3px 6px 0px #ffffff9e, 3px 3px 6px 0px #a3b1c6a8;}
.header2 .sc-logo-div-up{background-color:#2ea87e;background-color:var(--primary); margin: 10px 0;}
/* .header2{width:5.9vw; padding:10px; border-radius:5px} */
.header2 .sidebar-links{margin-top:0px;}
.header2 .sidebar-links a{position: relative; border-left:6px solid transparent; padding:15px; margin-bottom:0}
.header2 .sidebar-links a:hover{background-color: #F5F7FB; color:#2ea87e; color:var(--primary); border-left:6px solid #2ea87e; border-left:6px solid var(--primary);;}
.header2 .sidebar-links a span{opacity: 1; white-space: nowrap; visibility: visible; -webkit-transform: translate3d(0%,0,0); transform: translate3d(0%,0,0);}
.header2 .sidebar-links a img{margin: 0 15px 0 0;}
.sc-logo-div{background-size: contain;}
.sc-logo-div img{width: 50px;}
.header2 .sidebar-links #selected-sl{background-color: #F5F7FB; color:#2ea87e; color:var(--primary); font-weight: normal; border-left:6px solid #2ea87e; border-left:6px solid var(--primary);}
/* .sidebar-links #selected-sl:before{content: " "; position: absolute; height: 6px; width: 6px; top: 50%; margin-top:-3px; left:0px; background-color:var(--primary); border-radius: 50%;} */

/* .header2:hover{width: 15.9vw;} */
.header2:hover .sidebar-links a span {opacity: 1; visibility: visible; -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0);}




.paper-modal {
	position: fixed !important;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  z-index: 10000 !important;
  display: flex;
  align-items: flex-start;
  overflow-y: hidden;
  justify-content: center;
  background-color: #ffffff;
}

.app-body {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #ffffff;
  background-color: var(--white);
}

.content-body-container {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #ffffff;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-body {
  position: relative;
  width: 1200px;
  max-width: 1200px;
  min-height: 100vh;
}

.app-div {
  width: 76.1vw;
  height: calc(100vh - 130px);
  position: fixed;
  top: 120px;
  right: 0px;
  border-radius: 30px;
  background-color: transparent;
  padding: 10px 50px 25px 50px;
  overflow-y: auto;
  padding-bottom: 80px;
}
.app-div{width: auto; left:250px; padding-left:25px; padding-right:15px}


#path-b, #path-d {
  z-index: -1 !important;
  position: fixed;
  top: 0;
  right: 0;
}

#path-b {
  width: 500px;
 
}

#path-d {
  width: 600px;
}

.admin-div {
  overflow: hidden;
  width: calc(100vw - 290px);
  height: calc(100vh - 30px);
  position: fixed;
  top: 15px;
  right: 15px;
  border-radius: 30px;
  background-color: #ffffff;
  background-color: var(--white);
  padding: 30px 40px;
}

.react-notification-root {
  top: 0;
  left: 0;
}

.notification-content {
  font-family: Lato;
  padding-bottom: 12px !important;
}

.notification-item .notification-title {
  font-size: 16px !important;
}

.notification-item .notification-message {
  font-size: 15px !important;
  line-height: 1.4 !important;
}

.admin-div-loader {
  z-index: 100;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.study-material-loader{
  z-index: 100;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.05); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-loader {
  border: 8px solid #ffffff;
  border-radius: 50%;
  border-top: 8px solid #2ea87e;
  border-top: 8px solid var(--primary);
  width: 60px;
  height: 60px;
  -webkit-animation: admin-spin 1s linear infinite; /* Safari */
  animation: admin-spin 1s linear infinite;
}

@-webkit-keyframes admin-spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes admin-spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.list-loader {
  border: 4px solid rgba(167,37,38,0.10);
  border: 4px solid var(--primary-rgba);
  border-radius: 50%;
  border-top: 4px solid #2ea87e;
  border-top: 4px solid var(--primary);
  width: 40px;
  height: 40px;
  -webkit-animation: admin-spin 0.5s linear infinite; /* Safari */
  animation: admin-spin 0.5s linear infinite;
}

.admin-header {
  z-index: 100 !important;
	position: sticky;
	top: 5px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
  /* background-color: var(--white); */
  margin-bottom: 16px;
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(0,0,0,0.14);
}

.app-header {
  z-index: 2200000;
	position: fixed;
  top: 55px;
  right: 50px;
	width: 69.16vw;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
  background-color: transparent;
  margin-bottom: 16px;
}

.app-header{display: flex; align-self: flex-end; justify-content: space-between; left:250px; padding-left: 12px; width: auto;}

/*Page header*/
.studioProjectWrapper .studioProjectContainer .filtersBlock{border-bottom: 1px solid #dadada; padding-bottom: 10px;}

.page-header.withStyle{border-bottom: 1px solid #dadada; padding-bottom:10px}
.page-header.withStyle h1{margin-bottom: 0;}


.page-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: transparent;
}

.page-header.styleChange{align-items: center;}

.page-header-right {
  z-index: 10;
  display: flex;
  align-items: center;
}

.page-header-right button {
  margin-left: 30px;
}

.page-header-right select {
  min-width: 150px;
  font-family: Lato;
  font-size: 16px;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
  padding: 8px 14px;
  border-radius: 6px;
  margin-left: 20px;
}

.page-header-right select:first-child {
  margin-left: 0;
}

.page-data {
  position: relative;
  margin-top: 40px;
  width: 100%;
}

.search-head {
  display: flex;
  align-items: center;
}

.search-head img {
  width: 18px;
  margin-right: 8px;
}

.search-head input {
	width: 320px;
	border: none;
	outline: none;
	background-color: #ffffff;
	background-color: var(--white);
	font-family: Lato;
  font-size: 18px;
}

.app-search-head {
  width: 360px;
  border-radius: 30px;
  height: 45px;
  /*background-color: var(--primary-rgba);*/
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-search-head img {
  width: 20px;
  margin-left: 16px;
}

.app-search-head input {
  background-color: transparent;
  border: none;
  height: 100%;
  width: calc(100% - 62px);
  font-family: Lato;
  font-size: 16px;
  margin-right: 16px;
}

.profile {
	display: flex;
	align-items: center;
}
.profile-option .colorbg{background-color: #2ea87e;background-color: var(--primary); border-radius:5px; display: flex; align-items: center; justify-self:center}
.profile-option .colorbg img{padding:5px; margin-right: 0;}
.profile-option {
  position: relative;
	margin-right: 25px;
	font-family: Lato;
	font-weight: 600;
	font-size: 15px;
	display: flex;
	align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.profile-option .profileArrow{width: 17px; margin-right: 0;}

#dropper .profile-option {
  margin-right: 0;
}

#dropper .profile-option span{word-wrap: break-word; max-width: 160px;}

.profile-option img {
  width: 25px;
  margin-right: 8px;
}

.profile-option div {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}

.profile-option:last-child {
	margin-right: 0px;
}

.profile-option:last-child img {
	margin-right: 8px;
}

.main-part {
  z-index: 1;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  height: calc(100% - 60px);
  overflow-y: auto;
}

.popup-content {
  z-index: 100 !important;
  position: relative;
  width: 600px;
  height: auto;
  background-color: white;
  border-radius: 15px;
  border: none !important;
  padding: 32px 40px !important;
  overflow: hidden;
}

.image-content {
  width: 100vw !important;
  height: 100vh !important;
  background: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-content .close {
  position: absolute;
  top: 30px;
  right: 40px;
  font-weight: bold;
  color: #ffffff;
  color: var(--white);
  font-size: 31px !important;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.image-popup {
  position: relative;
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-popup img {
  max-height: 100%;
  max-width: 100%;
  transition: .3s all;
  cursor: zoom-in;
}

.image-popup img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.description-popup {
  height: auto;
  width: 100%;

  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  font-size: 18px;
  line-height: 1.7;
  color: rgba(0,0,0,0.74);
  color: var(--text-black2);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  position: fixed; top:0;
  right:0; bottom:0; left:0;
  
}

.description-popup-container{padding: 20px;  background-color: #ffffff;  background-color: var(--white);  border-radius: 15px; width: 100%; max-width: 600px; max-height: 500px; overflow-y: auto;}

.popup-doubt-content, .popup-doubt2-content {
  z-index: 100 !important;
  position: relative;
  width: 860px !important;
  height: 480px;
  height: auto;
  background-color: white;
  border-radius: 15px;
  border: none !important;
  padding: 32px 40px !important;
  overflow: hidden;
}

.popup-doubt2-content {
  height: 400px !important;
}

.react-multiple-carousel__arrow {
  z-index: 10 !important;
}

.popup-content2-content {
  position: relative;
  width: 600px;
  height: auto;
  background-color: white;
  border-radius: 15px;
  border: none !important;
  padding: 32px 40px !important;
  overflow: hidden;
}

.popup-header, .popup-header2 {
  width: 100%;
  text-align: center;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
}

.popup-header2 {
  font-size: 20px;
}

.popup-content .close, .popup-content2-content .close {
  position: absolute;
  top: 30px;
  right: 40px;
  font-weight: bold;
  font-size: 21px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.popup-content .close:hover, .popup-content2-content .close:hover {
  opacity: 0.8;
}

.popup-content3-content {
  width: 600px !important;
  height: 340px !important;
  background-color: white;
  border-radius: 25px;
  border: none !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 0 !important;
}

.popup-content6-content {
  width: 600px !important;
  height: auto !important;
  background-color: white;
  border-radius: 25px;
  border: none !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 40px !important;
}

.assessment-descrip {
  width: 100%;
  height: 100%;
}

.assessment-descrip {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.assessment-descrip div {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.assessment-descrip span {
  font-family: Lato;
  color: rgba(0,0,0,0.74);
  color: var(--text-black2);
  font-size: 18px;
  font-weight: 700;
}

.assessment-descrip textarea {
  margin-top: 20px;
  width: 100%;
  height: 150px;
  border-color: #2ea87e;
  border-color: var(--primary);
  background-color: rgba(167,37,38,0.10);
  background-color: var(--primary-rgba);
  border-radius: 4px;
  font-family: Lato;
  font-size: 16px;
  line-height: 1.5;
  padding: 12px 20px;
}

.popup-content4-content {
  width: 700px !important;
  height: auto ;
  background-color: white;
  border-radius: 25px;
  border: none !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 35px 40px !important;
}

.popup-content5-content {
  position: relative;
  width: 600px;
  height: auto;
  background-color: white;
  border-radius: 15px;
  border: none !important;
  padding: 32px 40px !important;
  overflow: hidden;
}

.popup-data4 {
  width: 100%;
  height: auto;
}

.delete-up {
  height: 270px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Lato;
  font-weight: 600;
  font-size: 23px;
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
  letter-spacing: 0.01em;
  line-height: 1.5;
}

.delete-up img {
  width: 74px;
  margin-bottom: 20px;
}

.delete-down {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
}

.delete-down button {
  width: 50% !important;
  height: 100% !important;
  padding: 0 0 !important;
  border: none;
  font-family: Lato;
  font-size: 24px;
  border-radius: 0 !important;
}

.delete-down button:hover {
  opacity: 0.85;
}

.delete-down button:first-child {
  background-color: #ffffff;
  background-color: var(--white);
  color: #2ea87e;
  color: var(--primary);
}

.delete-down button:last-child {
  background-color: #2ea87e;
  background-color: var(--primary);
  color: #ffffff;
  color: var(--white);
}

.popup-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  padding-top: 25px;
  padding-bottom: 25px;
}

.popup-data2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 195px;
  padding-top: 45px;
  padding-bottom: 30px;
}

.popup-data4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 45px;
  padding-bottom: 0px;
}

.popup-data5 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  padding-top: 45px;
  padding-bottom: 0px;
}

.condition-goto {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.condition-goto img {
  width: 80px;
  margin-bottom: 20px;
}

.condition-goto a {
  font-size: 21px !important;
  border-radius: 50px !important;
  padding: 10px 20px 11px 20px !important;
}

.condition-goto span {
  margin-top: 20px;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0.01em;
}

.popup-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.popup-content .popup-inputs:last-child{margin-bottom: 0;}
.popupx3-inputs{margin-bottom: 15px;}
.popupx-inputs, .popup-inputs5{margin-bottom: 15px;}
.popup-data4 .popup-inputs5{margin-bottom: 15px;}
.popup-data4 .popup-inputs5:last-child{margin-bottom: 0;}
.popupx-dropdowns{margin-bottom: 15px;}
.popup-data4 .singlerow{margin-bottom: 15px;}
.popup-content4-content{height: auto;}
.popup-instructions {
  margin-top: 20px;
  width: 100%;
  height: 125px;
  border-color: #2ea87e;
  border-color: var(--primary);
  border-radius: 4px;
  font-family: Lato;
  font-size: 16px;
  line-height: 1.5;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  padding: 12px 20px;
}

#popup-inputs-y {
  justify-content: flex-start;
}

.popup-inputs2 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.popup-inputs2 .img-prev {
  margin-right: 35px;
  margin-left: 0;
}

.popup-inputs input, .popup-inputs2 input, .popup-inputs select, .popup-inputs5 input {
  font-family: Lato;
  font-size: 16px;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

.popup-inputs5 input {
  width: 60%;
}

.popup-inputs5 span {
  width: 160px;
}

.popupx-inputs, .popup-inputs5 {
  display: flex;
  align-items: center;
}

.popup-admin {
  display: flex;
  align-items: center;
}

.popup-admin-child {
  display: flex;
  align-items: center;
  margin-right: 45px;
}

.popup-admin-child span {
  font-family: Lato;
  font-weight: 400;
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
  font-size: 18px;
  margin-left: 20px;
}

.popup-admin-child:last-child {
  margin-right: 0;
}

.popupx-inputs .asm-setting-duration {
  margin-top: 0 !important;
  margin-left: 25px !important;
}

.popupx-inputs span, .popup-inputs5 span {
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
}

.popupx-inputs input, .popupx2-inputs input, .popupx3-inputs input {
  width: 50%;
  font-family: Lato;
  font-size: 16px;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

.popupx2-inputs input {
  width: 100%;
}

.popupx3-inputs {
  display: flex;
  justify-content: space-between;
}

.popupx3-inputs input {
  height: 43px;
}

.popupx3-inputs input:first-child {
  width: 46% !important;
}

.popupx3-inputs input:nth-child(2) {
  width: 50% !important;
}

.popup-inputs input:first-child, .popup-inputs select:first-child {
  width: 64%;
}

.popup-inputs select:nth-child(2) {
  width: 33%;
}

#sp-popup-inputs, #doc-input {
  width: 100%;
}

.img-prev {
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  padding: 8px 20px;
  cursor: pointer;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.img-prev span {
  color: #2ea87e;
  color: var(--primary);
  font-size: 22px;
  margin-right: 16px !important;
  margin-left: 0 !important;
}

.img-prev:hover {
  opacity: 0.8;
}

.img-prev img {
  max-height: 50px;
  max-width: 100px;
}

.css-19gs8ny-Popover {
  padding: 8px 8px !important;
}

.css-mw1il1-ColorPicker {
  width: 160px !important;
}

.popup-color {
  width: 32%;
  height: 100%;
  display: flex;
  align-items: center;
}

#back_name {
  text-transform: uppercase;
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
  margin-left: 12px;
}

.css-cnfek5-Popover {
  top: -10px !important;
}

.css-1yn2e29-InputColor {
  height: 100% !important;
}

.css-yxnuqv-ColorPicker {
  width: 150px !important;
  height: 100px !important;
}

.css-199jh5z-ColorPicker {
  display: none !important;
}

.popup-dropdowns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-dropdown-div {
  width: 31%;
  display: flex;
  flex-direction: column;
}

.popup-dropdown-div label {
  padding-left: 10px;
  padding-right: 10px;
  font-family: Lato;
  font-weight: 700;
  color: #2ea87e;
  color: var(--primary);
  font-size: 16px;
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
  padding:0;
}

.popup-data select, .popup-data2 select {
  width: 100%;
  font-family: Lato;
  font-size: 16px;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

#slight-popup-data {
  padding-top: 24px;
}

.popup-data4 select {
  width: 35%;
  font-family: Lato;
  font-size: 16px;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

.popup-data4 select:first-child {
  margin-right: 25px;
}

.popup-inputs input:last-child {
  width: 32%;
}

.popup-actions {
  text-decoration: none;
  width: 100%;
  bottom: 35px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.sub-head {
  margin-top: 16px;
	width: 100%;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.sub-head2 {
  margin-top: 24px;
	width: 100%;
	display: flex;
	align-items: baseline;
  justify-content: flex-end;
  font-family: Lato;
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
}

.sub-head2 a {
  color: #2ea87e;
  color: var(--primary);
}

.heading-part1 {
	min-width: 50%;
}

.heading-part2 {
  display: flex;
  align-items: center;
  min-width: 50%;
  justify-content: flex-end;
}

.heading-part2 button:last-child {
  margin-left: 15px;
}

.heading-part2 select {
  font-family: Lato;
  font-size: 16px;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
  padding: 9px 14px;
  border-radius: 4px;
}

.heading {
  display: flex;
  align-items: center;
  overflow-x: hidden;
}

#trace-back {
  display: block;
  width: 46px;
  height: 38px;
  border-radius: 8px;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 0 !important;
  margin-right: 14px;
}

#trace-back:hover {
  opacity: 0.8;
}

#trace-back img {
  width: 20px;
}

.filter-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filter-buttons span {
  font-weight: 700;
  font-family: Lato;
  font-size: 18px;
  cursor: pointer;
  color: #2ea87e;
  color: var(--primary);
}

.filter-buttons button {
  margin-left: 20px;
}

h1 {
	font-weight: 600;
	font-family: Lato;
	font-size: 24px;
	color: rgba(0,0,0,0.84);
	color: var(--text-black);
	margin: 0 0;
}

h2 {
  font-weight: 600;
  line-height: 1.4;
	font-family: Lato;
	font-size: 19px;
	color: rgba(0,0,0,0.84);
	color: var(--text-black);
	margin: 0 0;
}

h3 {
  font-weight: 600;
  line-height: 1.4;
	font-family: Lato;
	font-size: 18px;
	color: rgba(0,0,0,0.84);
	color: var(--text-black);
	margin: 0 0;
}

.sub-head span, .sub-head a {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #FED41E;
  background-color: var(--secondary);
  padding: 11px 14px 12px 14px;
  margin-left: 20px;
  cursor: pointer;
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
  color: white;
  text-decoration: none;
}

.sub-head span:hover, .sub-head a:hover {
  opacity: 0.8;
}

.sub-head img {
  width: 18px;
  margin-right: 8px;
}

.sub-filter {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
  margin-bottom: 10px;
}

.sub-filter select, .sub-filter-x {
  background-color: rgba(167,37,38,0.10);
  background-color: var(--primary-rgba);
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 12px;
  width: 200px;
  border-radius: 5px;
  margin-right: 25px;
}

.sub-filter-x {
  cursor: default;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--primary-rgba);
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 16px;
  width: 200px;
  border-radius: 5px;
  margin-right: 25px;
}

.sub-filter select:last-child {
  margin-right: 0;
}

#send-invite {
  display: flex;
  align-items: center;
  font-family: Lato;
  color: #374957;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

#send-invite img {
  width: 20px;
  margin-right: 10px;
}

#send-invite:hover {
  color: #2ea87e;
  color: var(--primary);
}

.list-head {
  position: sticky;
  top: 0px;
	background-color: #ffffff;
	background-color: var(--white);
	width: 100%;
	border-bottom: 1px solid #ccc;
	padding: 20px 24px 16px 24px;
	display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 18px;
}

.list-headx {
  position: sticky;
  top: 0px;
	background-color: #ffffff;
	background-color: var(--white);
	width: 100%;
	padding: 20px 24px 16px 24px;
	display: flex;
	align-items: center;
  margin-bottom: 18px;
}

.list-head div {
	font-family: Lato;
	color: rgba(0,0,0,0.90);
	color: var(--black2);
	font-size: 16px;
	font-weight: 700;
	padding-left: 1px;
}

.the-list {
  width: 100%;
	padding-left: 3px;
  padding-right: 3px;
}

.list-item {
	width: 100%;
	display: flex;
	box-shadow: 1px 1px 6px 0px #eeeeee;
  padding: 20px 24px;
  min-height: 70px !important;
	border-radius: 10px;
  margin-bottom: 15px;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
}

.list-item div {
	font-family: Lato;
	color: rgba(0,0,0,0.74);
	color: var(--text-black2);
	font-size: 16px;
	font-weight: 400;
  padding-right: 40px;
  overflow-wrap: break-word;
}

.list-item div:last-child {
	padding-right: 0;
}

.the-grid {
  margin-top: 50px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill,minmax(30%,1fr));
  grid-template-columns: repeat(3,minmax(0,1fr));
  padding-bottom: 40px;
}

.grid-item {
  width: 100%;
  height: 200px !important;
  border-radius: 15px !important;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  box-shadow: 1px 1px 6px 0px #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px;
}

.grid-up {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.grid-up:nth-child(2) {
  align-items: flex-end;
}

.grid-up span {
  margin-top: 4px;
  font-family: Lato;
  font-size: 16px;
}

#grid-up-span {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  width: 60%;
  overflow-wrap: break-word;
  line-height: 1.4;
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
}

.grid-image {
  width: 46px;
  height: 46px;
  border-radius: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title-image {
  width: 40px !important;
  height: 40px !important;
  border-radius: 6px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 12px !important;
}

.grid-sec {
  width: 60%;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  font-family: Lato;
  font-size: 14px;
  color: rgba(0,0,0,0.60);
  color: var(--text-black3);
}

.grid-sec2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.grid-sec2 a:last-child {
  margin-top: 10px;
}

#section-button {
  width: -webkit-max-content !important;
  width: max-content !important;
  height: -webkit-max-content !important;
  height: max-content !important;
  color: #ffffff;
  color: var(--white);
  background-color: #2ea87e;
  background-color: var(--primary);
  padding: 8px 16px;
  border-radius: 10px;
  font-family: Lato;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  text-decoration: none;
}

#section-button:hover {
  opacity: 0.8;
}

#section-button2 {
  width: -webkit-max-content !important;
  width: max-content !important;
  height: -webkit-max-content !important;
  height: max-content !important;
  background-color: transparent !important;
  color: #2ea87e;
  color: var(--primary);
  font-family: Lato;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 0;
  padding: 0;
}

#section-button2 img {
  width: 18px;
  margin-right: 12px;
}

#section-button2:hover {
  opacity: 0.8;
}

#section-button2:last-child {
  margin-left: 20px;
}

#section-button3 {
  color: #2ea87e;
  color: var(--primary);
  font-family: Lato;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}

#section-button3:hover {
  opacity: 0.8;
}

.grid-middle {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  font-family: Lato;
  font-size: 14px;
  color: rgba(0,0,0,0.60);
  color: var(--text-black3);
}

.grid-middle span {
  margin-top: 4px;
  font-family: Lato;
  font-size: 16px;
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
}

.grid-down {
  display: flex;
  justify-content: flex-end;
}

.grid-actions {
  display: flex;
  align-items: center;
}

.action-item, .action-item2 {
	display: flex;
	align-items: center;
}

.action-item span, .action-item a, .action-item2 span {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 15px;
	cursor: pointer;
	border-radius: 10px;
}

#asm-edit-button {
  background-color: rgba(167,37,38,0.10);
  background-color: var(--primary-rgba);
  color: #2ea87e;
  color: var(--primary);
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
  border-radius: 8px;
  padding: 4px 12px;
  cursor: pointer;
  margin-left: 30px;
  text-decoration: none;
}

#asm-edit-button:hover {
  opacity: 0.8;
}

.action-item span:hover, .action-item a:hover, .action-item2 span:hover {
	background-color: rgba(95, 125, 149, 0.15);
}

.grid-actions span, .grid-actions a {
  display: block;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
  margin-right: 10px;
  margin-top: 0px;
	cursor: pointer;
	border-radius: 10px;
}

.grid-actions span:hover, .grid-actions a:hover {
	background-color: rgba(95, 125, 149, 0.15);
}

.action-item button, .action-item2 button {
	background-color: #2ea87e;
	background-color: var(--primary);
	color: #ffffff;
	color: var(--white);
	font-family: Lato;
	font-size: 16px;
	border: none;
	font-weight: 600;
	padding: 7px 12px 8px 12px;
	border-radius: 10px;
  cursor: pointer;
  margin-right: 15px;
}

.action-item2 a {
  margin-right: 15px;
}

.action-item2 a:last-child {
  margin-right: 0;
}

.grid-actions button {
	background-color: #2ea87e;
	background-color: var(--primary);
	color: #ffffff;
	color: var(--white);
	font-family:Lato;
	font-size: 16px;
	border: none;
	font-weight: 600;
	padding: 7px 12px 8px 12px;
	border-radius: 10px;
  cursor: pointer;
}

.action-item button:last-child, .action-item2 button:last-child {
  margin-right: 0;
}

.action-item button:hover, .action-item2 button:hover {
	opacity: 0.95;
}

.grid-actions button:hover {
	opacity: 0.95;
}

.action-item span:last-child, .action-item2 span:last-child {
	margin-right: 0;
}

.grid-actions span:last-child {
	margin-right: 0;
}

.action-item img, .action-item2 img {
	width: 20px;
}

.grid-actions img {
	width: 20px;
}

.asm-head-edit {
  display: flex;
  width: -webkit-max-content;
  width: max-content;
  align-items: baseline;
  justify-content: flex-start;
}

/*Assessment*/

.list-assessment .list-title, .the-assessment-list .title-item, .the-assessment-list .title-item2,
.the-assessment-list .title-item3 {
	width: 25%;
}

.title-item3 input[type=checkbox] {
  align-self: center;
}

.list-assessment .list-section, .the-assessment-list .section-item {
	width: 12.5%;
}

.list-assessment .list-class, .the-assessment-list .class-item {
	width: 12.5%;
}

.list-assessment .list-email, .the-assessment-list .email-item {
  width: 25%;
  overflow-wrap: break-word;
  display: flex;
  align-items: flex-start;
}

.list-assessment .list-subject, .the-assessment-list .subject-item {
	width: 12.5%;
}

.list-assessment .list-faculty, .the-assessment-list .faculty-item {
	width: 15%;
}

.list-assessment .list-action, .the-assessment-list .action-item {
	width: 25%;
}

.the-assessment-list .action-item2 {
  width: 35%;
}

.list-assessment .list-action2 {
  width: 35%;
}

.title-grid, .title-grid2, .title-grid3, .title-grid4 {
  width: 60%;
}

.title-grid, .title-item {
  font-family: Lato;
	display: flex;
	flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
}

.title-grid3 {
  font-family: Lato;
  display: flex;
  align-items: flex-start;
}

.title-grid3 img {
  width: 25px;
  margin-top: 3px;
  margin-right: 12px;
}

.title-grid3 div {
  display: flex;
  flex-direction: column;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
}

.title-grid3 div span {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.title-grid4 {
  font-family: Lato;
  display: flex;
  align-items: center;
}

.title-item3 {
  font-family: Lato;
  display: flex;
  align-items: flex-start;
}

.title-grid4 img, .title-item3 img {
  width: 40px;
  margin-right: 12px;
}

.dp_div {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 12px;
}

#study-material-title {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
}

.title-item2 {
  text-decoration: none;
  font-family: Lato;
  display: flex;
  cursor: pointer;
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
}

.title-grid2 {
  text-decoration: none;
  font-family: Lato;
  display: flex;
  align-items: center;
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
}

.section-item {
  display: flex;
  align-items: flex-start;
}

.title-grid2 div {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 8px;
}

.section-item div {
  display: flex;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 8px;
}

.title-grid span, .title-item span {
  margin-top: 4px;
	font-size: 14px;
	font-weight: 700;
	color: rgba(0,0,0,0.60);
	color: var(--text-black3);
}

.edit-div {
  margin-top: 30px;
  width: 100%;
  min-height: 200px;
  background-color: rgba(0,0,0,0.02);
  background-color: var(--light-black2);
  border-radius: 20px;
  padding: 45px 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.edit-div-1 {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.edit-dp {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid rgba(167,37,38,0.10);
  border: 2px solid var(--primary-rgba);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.edit-dp #img-holder{
  color:#2ea87e;
  color:var(--primary)
}

.edit-dp:hover {
  opacity: 0.8;
}

.edit-dp span {
  background-color: #000000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  color: white;
  font-weight: bold;
  top: 20px;
  font-size: 20px;
}

.edit-dp #img-holder {
  width: 100px !important;
  border-radius: 0;
}

.edit-sub-div-1 {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.edit-sub-div-1 input {
  font-family: Lato;
  width: 320px;
  font-size: 16px;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
  margin-top: 20px;
}

.edit-div-2 {
  margin-top: 30px;
  display: flex;
}

.edit-div-2 input, .edit-div-2 div {
  font-family: Lato;
  width: 275px;
  font-size: 16px;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
  margin-right: 20px;
}

.edit-div-3, .edit-div-4 {
  margin-top: 30px;
  display: flex;
}

.edit-div-5 {
  margin-top: 30px;
}

.edit-div-3 select, .edit-div-5 select, .edit-div-3 input, .edit-div-4 select {
  font-family: Lato;
  font-size: 16px;
  background-color: rgba(167,37,38,0.10);
  background-color: var(--secondary-rgba);
  border: 1px solid #2ea87e;
  border: 1px solid var(--primary);
  color: rgba(0,0,0,0.84);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

.edit-div-3 select, .edit-div-3 input, .edit-div-5 select {
  width: 275px;
  margin-right: 20px;
}

.edit-div-4 select {
  width: 570px;
}

.edit-buttons {
  margin-top: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.edit-buttons button:last-child {
  margin-left: 20px;
}

#no-data {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-weight: 700;
  font-size: 25px;
  padding-top: 150px;
  color: rgba(0,0,0,0.74);
  color: var(--text-black2);
}

.react-notification-root {z-index: 2200000!important;}

@media only screen and (max-width: 600px) { 
  .popup-doubt2-content{height:auto!important; padding:15px!important; width:90%!important;}
  .popup-doubt2-content .ask-doubt-action{margin-top:10px}
  .profile-option div{flex-shrink: 0;}
  .profile-option{margin-right:10px;}
  .profile-option img{margin-right:0px;}
  .popup-content5-content{width: 90%!important; height:auto;padding:15px!important;}
  .popup-content5-content .popup-data5{height:auto; padding-top:20px;}
  .popup-content5-content .popup-inputs{flex-direction:column}
  .popup-content5-content .popup-inputs input{width:100%; margin-bottom:10px;}
}

.switchCourseDD {
  display: flex;
  align-items: center;
  font-family: Lato;
  margin-right: 20px;
}

.switchCourseDD label {
  margin-right: 10px;
  text-transform: capitalize;
  padding: 0;
  display: flex;
  white-space: nowrap;
}

.switchCourseDD select {
  border-radius: 5px;
  min-width: 168px;
  max-width: 200px;
  background-color: transparent;
  border: 1px solid var(--primary);
}

.switchCourseDD select option {
  padding: 5px 0
}

.switchCourseDD select optgroup {
  text-transform: capitalize;
  line-height: 30px;
}

/*Sidebar*/

.viewAttendanceReport {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  font-family: Lato;
  margin-right: 20px;
}

.viewAttendanceReport a {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  font-family: Lato;
  color: var(--primary);
  font-size: 16px;
  white-space: nowrap;
  align-items: center;
}

.side-logo {
  cursor: pointer;
  margin-left: 15px;
  margin-left: 0;
  margin-right: 30px;
  font-family: Lato;
  font-weight: 900;
  font-size: 17px;
  color: var(--white);
  display: block;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 12px;
  height: 60px;
  height: 86px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  text-decoration: none;
  overflow: hidden;
  width: 100%;
}

.top-csk-button {
  display: flex;
  text-decoration: none;
  color: #000;
  padding: 4px 6px;
  font-size: 17px;
  border-radius: 5px;
  margin-right: 10px;
  text-align: center;
  align-items: center;
  width: auto;
}

.top-csk-button span {
  margin: 0 10px;
  color: #999
}

.top-csk-button a {
  width: 80px;
  margin-left: 3px;
  border: 6px;
  border-radius: 4px;
  font-size: 15px;
  padding: 0px 7px 2px 5px;
  /* background-color: #fdbc4b; */
  text-decoration: underline;
}

.top-csk-button a:hover {
  text-decoration: none;
}

.side-logo img {
  width: 60px;
  margin-right: 20px;
}

.profile-dp {
  min-width: 45px;
  height: 75px;
  /* border-radius: 50%; */
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0px;
  width: 100%;
  max-width: none;
}

.sidebar {
  margin-top: 5px;
  padding-top: 35px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  overflow-y: auto;
}

@-webkit-keyframes blink-animation {
  50% {
    opacity: 0.0;
  }
}

@keyframes blink-animation {
  50% {
    opacity: 0.0;
  }
}

.OnlineStatus {
  display: flex;
  align-items: center;
  margin-right: 20px;
  grid-gap: 5px;
  gap: 5px;
  font-family: lato;
}

.OnlineStatus span {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.OnlineStatus i {
  width: 14px;
  height: 14px;
  -webkit-animation: blink-animation 1s step-start 0s infinite;
          animation: blink-animation 1s step-start 0s infinite;
  border-radius: 100%;
  flex-shrink: 0;
}

.OnlineStatus i.greenCircle {
  background-color: green;
}

.OnlineStatus i.redCircle {
  background-color: red;
}

.sidebar hr {
  margin-left: 15px;
  margin-right: 30px;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.sidebar a.logo-noesis {
  margin-top: 40px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
}

.sidebar a {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
  text-decoration: none;
  display: inline-block;
  padding: 15px 24px 15px 24px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.sidebar a:hover {
  color: var(--white);
}

.sidebar #selected {
  z-index: 1;
  position: relative;
  background-color: var(--white);
  color: var(--primary);
}

.sidebar .crescent {
  position: absolute;
  width: 36px;
  height: 36px;
  right: 0;
  top: -36px;
  background-color: var(--white);
}

.sidebar .crescent2 {
  position: absolute;
  width: 36px;
  height: 36px;
  right: 0;
  bottom: -36px;
  background-color: var(--white);
}

.shadowbar {
  width: 100%;
  height: 100%;
  position: absolute;
}

.clipboard {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--primary);
  -webkit-clip-path: circle(100% at 0 0);
          clip-path: circle(100% at 0 0);
}

.clipboard2 {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--primary);
  -webkit-clip-path: circle(100% at 0 100%);
          clip-path: circle(100% at 0 100%);
}

.contact-us {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  margin-left: 15px;
  margin-right: 30px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  font-family: Montserrat;
  color: #ffffff;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.5;
}

.contact-us button {
  margin-top: 20px;
  margin-bottom: 15px;
}

.contact-us img {
  height: 160px;
  margin-right: 5px;
  margin-left: 5px;
}

.dropdown-content {
  top: 40px;
  right: 0px;
  position: absolute;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 2px 2px 8px -4px rgba(0, 0, 0, 0.4);
  width: 180px;
}

.dropdown-content2 {
  top: 40px;
  right: 0px;
  position: absolute;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 2px 2px 8px -4px rgba(0, 0, 0, 0.4);
  width: 360px;
  width: 320px;
  padding: 35px 35px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  /* New shadow */
}

.dropdown-content div {
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  padding: 16px 24px;
  color: var(--text-black);
}

.drop-profile {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-direction: column;
}

.drop-profile ul li {
  /* border: 1px solid; */
  padding: 10px;
  border-radius: 6px;
  background-color: #e9f9f7;
  margin-bottom: 11px;
  /*width: 214px;*/
  cursor: pointer;
}

.drop-profile img {
  width: 75px;
  width: 50px;
}

/* .dpp-prof-img {
  min-width: 60px;
  max-width: 60px;
  height: 78px;
  border-radius:0%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

} */
.dpp-prof-img {
  min-width: 60px;
  max-width: 60px;
  height: 60px;

  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.drop-profile-creds div {
  margin-top: 2px;
}

.dpp-prof-img.learnerProfile {
  margin-right: 10px;
}

.dpp-prof-img.adminProfile {
  min-width: 148px;
  max-width: 148px;
}

.dpp-prof-img.adminProfile {
  display: none;
}

.drop-profile-creds div {
  position: relative;
}

#edit-prof.teacherLogin {
  background-color: var(--secondary);
  position: static;
  width: 30px !important;
  height: 30px !important;
  margin-top: 20px;
  margin-left: 5px;
  flex-direction: row;
  margin-left: auto;
  margin-top: 0;
}

#edit-prof.teacherLogin + .drop-profile .drop-profile-creds span{margin-left:0; padding-left: 0; text-align: center;}

#edit-prof {
  position: absolute;
  /* width: 20px !important; */
  height: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  right: 10px;
  bottom: 10px;
  
  background-color: var(--primary-rgba);
  background-color: transparent;
  margin-left: 0px;
  flex-shrink: 0;
  margin-left: auto;

}

#edit-prof:hover {
  background-color: var(--primary-rgba);
}

#edit-prof img {
  width: 20px;
}

.drop-profile-creds {

  margin-left: 20px;
  margin-left: 0px;
  display: flex;
  flex-direction: column;
}

.drop-profile-creds span {
  font-family: Lato;
  font-weight: 600;
  color: var(--text-black);
  font-size: 18px;
  max-width: none;

  overflow: hidden;
  word-break: break-word;
  padding-left: 35%;
  margin-top: 2%;
}

.drop-profile-creds div {
  max-width: 140px;
  overflow: hidden;
  font-family: Lato;
  font-weight: 600;
  font-size: 15px;
  background-color: var(--secondary);
  color: var(--white);
  display: inline-block;
  width: -webkit-max-content;
  width: max-content;
  margin-top: 10px;
  margin-top: 2px;
  padding: 8px 12px 9px 12px;
  border-radius: 8px;
}

.prof-options-div {
  font-family: Lato;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  font-size: 16px;
  color: var(--text-black2);
  margin-bottom: 12px;
}

.prof-options-div span {
  margin-bottom: 6px;
  font-weight: 600;
  color: var(--text-black);
}

.click-prof {
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
  cursor: pointer;
}

.click-prof:hover {
  opacity: 0.8;
}

/* Sidebar 2 */

.sc-logo-div-up {
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.sc-logo-div {
  width: 110px;
  height: auto;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.sc-logo-div {
  background-size: contain;
}

.sc-logo-div img {
  width: 100%;
}

.sidebar-links {
  margin-top: 40px;
}

.header2 .sc-logo-div-up {
  background-color: white !important;
}

.sidebar-links a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-black);
  font-family: Lato;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 40px;
}

.sidebar-links img {
  width: 30px;
  margin-right: 25px;
  flex-shrink: 0;
}

.sidebar-links a:last-child {
  margin-bottom: 0;
}

.sidebar-links #selected-sl {
  font-weight: 800;
  color: var(--primary);
}

.logo-noesis {
  margin-top: 30px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-family: Lato;
  font-weight: 700 !important;
  font-size: 21px !important;
  letter-spacing: 0.01em;
  color: var(--white);
  padding-right: 48px !important;
}

.logo-noesis img {
  width: 140px;
  width: 180px;
  margin-bottom: 16px;
}

.brand {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.logo-sow {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  font-family: Lato;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: 0.01em;
  color: var(--primary);
}

.logo-sow img {
  width: 90px;
  margin-bottom: 5px;
}

.dropdown-content2-setting {
  top: 33px;
  right: 122px;
  position: absolute;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 2px 2px 8px -4px rgba(0, 0, 0, 0.4);
  width: 297px;
  /* padding: 0px 16px; */
  padding: 10px;
  font-family: Muli;
  border: solid 1px #e6f3f7;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 360px !important;
  align-items: center;
  margin-top: 3px;
}

.input_field {
  width: 100%;
  height: auto;
  position: relative;
}

.input_field input {
  border: none;
  width: 234px;
  height: 30px;
  border-bottom: 1px solid;
}

.click-prof a {
  color: var(--primary);
  font-size: 17px
}

.img-prev {
  margin-left: 40px;
  margin-right: 50px;
}

.first_login_popup-content {
  width: 40% !important;
  height: 27%;
}

.drop-profile-setting {
  position: relative;
  width: 100%;
  align-items: center;
}


.drop-profile-setting img {
  width: 12px;
}


.settings {
  display: flex;
  background-color: var(--primary-rgba);
  height: 40px;
  border-radius: 4px;
  margin-bottom: 11px;
  width: 100%;
  padding: 11px;
  cursor: pointer;
}

.settings a {
  color: black;
}

.logout-setting {
  display: flex;
  font-family: Lato;
  cursor: pointer;
  width: auto
}

.logout-setting img {
  margin-right: 5px;
}

.togglePassword {
  height: 29px !important;
  top: 8px !important;
}

.change-password-content {
  padding: 0px !important;
  height: auto ! important;
}

.button-class {
  margin-top: 42px;
}

.edit-profile-title {
  background-color: #bde2e8;
  width: 100%;
  height: 44px;
  padding-top: 12px;
  padding-left: 200px;
  font-size: 19px;
  font-family: Lato;
}

.input-box input {
  width: 287px;
  border: 2px solid #dcd4d4ab;
  border: 1px solid #efb953;
  background-color: #fff6e4;
  border-radius: 4px;
  font-family: Lato;
  height: 43px;
}

.input-box {
  padding-left: 96px;
  margin: 15px;
}

.login-actions-edit-form {
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  margin-left: 193px;
}

.edit-profile-content {
  padding: 0px !important;
  width: 40% !important;
}

.share-app-div {
  height: 54px;
  background-color: var(--primary);
  margin-bottom: 20px;
  font-size: 23px;
  font-family: lato;
  padding: 15px;
  text-align: center;

}

.go-to-assessment {
  background-color: var(--primary);
  border: 1px solid #ddd;
  text-decoration: none;
  color: var(--white);
  padding: 5px 15px;
  border-radius: 5px;
  font-family: lato;
  font-size: 15px
}

.react-share__ShareButton {
  background-color: transparent;
  border: none;
  padding: 17px !important;
  font: inherit;
  color: inherit;
  cursor: pointer;
}

.shareapp-content {
  padding: 0px !important;
}


.social-share {
  background: rgba(256, 256, 256, 0.5);
  border-radius: 10px;
  text-align: center;
  margin: 31px 10px;
}

.social-share textarea {
  width: 612px;
  height: 135px;
  font-size: 16px;
  font-family: lato;
  text-align: center;
  padding: 9px;
  border-radius: 10px;
  margin: 8px 7px;
  border: 1px solid #efb953;
  background-color: #fff6e4;
}

.first_login_popup_page {
  color: blue;
  cursor: pointer;
  font-size: 19px;
  font-family: lato;
}

.message-alert {
  margin-top: 4px;
  font-size: 15px;
  font-family: lato;
  margin-left: 10px;
  color: green;
}

.social-buttons {

  margin-top: 22px;
  box-shadow: 0px 0px 32px -7px #00199f;
  border-radius: 10px;
}

.social-margin {
  margin-right: 15px;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

.social-icon {
  margin-bottom: 15px;
  box-sizing: border-box;
  border-radius: 138px;
  border: 5px solid;
  text-align: center;
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 1px;
  padding-top: 11px;
  transition: all 0.5s;
}

.react-share__ShareButton:hover {
  -webkit-transform: rotate(360deg)scale(1.3);
          transform: rotate(360deg)scale(1.3);
}

/* Facebook Button Styling */
.facebook {
  font-size: 22px;
  padding-top: 9px;
  border-color: #3b5998;
  background-color: #3b5998;
  color: #ffffff;

}

.facebook:hover {
  background-color: #ffffff;
  color: #3b5998;
}

.notification-container-top-right {
  right: 20px !important;
  top: 92px !important;
}

.popup-content-edit-pofile-content {
  width: 45% !important;
}

.assesment-preview2 {
  height: 440px !important;
}

.popup-inputs-edit-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-inputs-edit-profile input:last-child {
  width: 42% !important;
  font-family: Lato;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--secondary);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

.popup-inputs-edit-profile input:first-child {
  width: 52% !important;
  font-family: Lato;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

.popup-inputs-edit-profile select {
  max-width: 150px;
  margin-right: 10px;
}

.popup-inputs-edit-profile .input {
  width: 52% !important;
  font-family: Lato;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

.change-password-notification-popup {
  text-align: center;
  font-family: lato;
  color: #0e7486;
  font-size: 17px;
  margin-top: 7px;
}

.search-wrapper-admin {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  font-family: inherit;
}

.search-wrapper-admin .search-container {
  display: flex;
  width: 100%;
  position: relative;
}

.search-wrapper-admin .search-container input[type="search"] {
  border: 1px solid var(--primary);
  background-color: var(--primary-rgba);
  border-radius: 5px;
  padding: 8px 10px;
  background-image: url("");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px 20px;
}

.search-wrapper-admin .search-container input[type="search"]:focus {
  outline: 0px none;
}

.search-overlay-wrapper {
  position: absolute;
  top: 105%;
  right: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.search-overlay-wrapper .search-overlay-container {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #489198;
}

.search-wrapper-admin .search-container .search-overlay-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.search-wrapper-admin .search-container .tabs {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.search-wrapper-admin .search-container .tabs a {
  color: #489198;
  text-decoration: none;
  font-size: 17px;
  margin-left: 30px;
  border-bottom: 3px solid #489198;
  padding-bottom: 3px;
}

.search-wrapper-admin .search-container .tabs a:hover {
  text-decoration: none;
}

.search-wrapper-admin .search-container .tabs a:first-child {
  margin-left: 0px;
}

.search-wrapper-admin .search-container .tabs a.inactive {
  color: #7F7F7F;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent;
  padding-bottom: 3px;
}

.search-wrapper-admin .search-container .tab-content {
  display: flex;
  display: none;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
}

.search-wrapper-admin .search-container .tab-content ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.search-wrapper-admin .search-container .tab-content ul li {
  margin: 5px 0 0;
  padding: 5px 0 0;
  display: flex;
  border-top: 1px solid #ddd;
}

.search-wrapper-admin .search-container .tab-content ul li a {
  align-items: center;
  display: flex;
  text-decoration: none;
  width: 100%;
  color: #000;
  font-size: 20px;
}

.search-wrapper-admin .search-container .tab-content ul li:first-child {
  border-top: 0px none;
}

.search-wrapper-admin .search-container .tab-content ul li a i {
  width: 62px;
  flex-shrink: 0;
  margin-right: 15px;
}

.search-wrapper {
  display: flex;
  flex-direction: column;
  width: 45%;
  max-width: 500px;
  font-family: inherit;
}

.search-wrapper .search-container {
  display: flex;
  width: 100%;
  position: relative;
}

.search-wrapper .search-container input[type="search"] {
  width: 100%;
  border: 1px solid var(--primary);
  border-radius: 5px;
  padding: 8px 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAV9JREFUKFOVUsFVwkAQ/R9uXow3HhyUCsyGBrCDpQKxArECsQOswFiB2wFQgIQSuAQ5xoPezPh22eRt8vBgDnm7M/P//D+zRPANMj0VwT2B2IYF2IIw38BzoUwR1toz7S/KdHQmWFagdpEAOxKTXJltmHPgwUbb4LVPrEWwEqAgERO49fHiixiGCmilQvDiZAqe9iMzD9n7mdYUvHkbr/vETKs8g67rPDHjtmR772906hUUeWIuQrD81bUq6mV63BUs7f2HGB6U2bmBDTbagUvBw8fILE51DsEgVDW4WrYADT8N3+96TuLRxvLEuCG7zv0gIcRkr4xp7T7GUXLUbkC/4y2BS+89LcnUnjuUMQUzC/R+bw7KrOrObs+ZjlFiBeL8lOdGjLjLlXHktX6vYEGBDkms1JJIq2kfzR4JanDI3sv0VReIwucYPqaK4CT4L+kNAsHnv8B+PlOUWKCD2S+bHptswMHaNwAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: inherit;
}

.search-wrapper .search-container input[type="search"]:focus {
  outline: 0px none;
}

.search-overlay-wrapper {
  position: absolute;
  top: 105%;
  right: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.search-overlay-wrapper .search-overlay-container {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #489198;
}

.search-wrapper .search-container .search-overlay-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.search-wrapper .search-container .tabs {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.search-wrapper .search-container .tabs a {
  color: #489198;
  text-decoration: none;
  font-size: 17px;
  margin-left: 30px;
  border-bottom: 3px solid #489198;
  padding-bottom: 3px;
}

.search-wrapper .search-container .tabs a:hover {
  text-decoration: none;
}

.search-wrapper .search-container .tabs a:first-child {
  margin-left: 0px;
}

.search-wrapper .search-container .tabs a.inactive {
  color: #7F7F7F;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent;
  padding-bottom: 3px;
}

.search-wrapper .search-container .tab-content {
  display: flex;
  display: none;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
}

.search-wrapper .search-container .tab-content ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.search-wrapper .search-container .tab-content ul li {
  margin: 5px 0 0;
  padding: 5px 0 0;
  display: flex;
  border-top: 1px solid #ddd;
}

.search-wrapper .search-container .tab-content ul li a {
  align-items: center;
  display: flex;
  text-decoration: none;
  width: 100%;
  color: #000;
  font-size: 20px;
}

.search-wrapper .search-container .tab-content ul li:first-child {
  border-top: 0px none;
}

.search-wrapper .search-container .tab-content ul li a i {
  width: 62px;
  flex-shrink: 0;
  margin-right: 15px;
}

.sc-logo-div+.sc-logo-div {
  display: none;
}

.content-state {
  margin: 1px;
  font-family: lato;
  font-size: 17px;
  color: #053e67;
  ;
}

.content-button {
  margin-top: 50px;
  margin-left: 306px;
  display: flex;
  justify-content: space-between;
}

.content-button button {
  margin-right: 16px;
}


.complete-details-content {
  width: 49% !important;
  height: 270px !important;
  font-family: lato;
}

.qod-list-overlay .qod-list-content {
  padding: 0 !important;
  height: 520px !important;
}

.qod-list-overlay .qod-list-content .close {
  width: 26px;
  height: 26px;
  right: 15px;
  font-size: 14px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: var(--primary);
  color: #fff
}

/*.questionOfTheDayDialog{position: fixed; font-family: lato; display:flex; align-items: center; z-index: 2250000; justify-content: center; background-color:rgba(0,0,0,0.2); top:0; right:0; left:0; bottom:0}*/
.questionOfTheDayDialog .questionOfTheDayDialog-container {
  background-color: #fff;
  width: 100%;
  max-width: none;
  width: 100%;
  padding: 0;
  border-radius: 15px;
  display: flex;
  position: relative;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogLeft {
  display: flex;
  width: 210px;
  position: relative;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogLeft img {
  position: relative;
  z-index: 0;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .popup-header {
  font-family: Lato;
  text-align: left;
  font-size: 32px;
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 1;
  margin-bottom: 0px;
  font-weight: 700;
  color: var(--white);
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .view-history {
  font-family: Lato;
  text-align: left;
  font-size: 19px;
  position: absolute;
  bottom: 27px;
  left: 16px;
  z-index: 1;
  margin-bottom: 0px;
  font-weight: 700;
  color: var(--white);
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight {
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(to right, #fff, #FEF4F1);
  display: flex;
  padding: 30px;
  flex: 1 1;
  flex-direction: column;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .slick-slider:focus {
  outline: 0px none;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .SliderContent:focus {
  outline: 0px none;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .SliderContent .item:focus {
  outline: 0px none;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .SliderContent .slick-dots {
  position: static;
  padding: 0;
  margin-top: 25px;
  order: 2
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .SliderContent .slick-dots ul {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .SliderContent .slick-dots ul li {
  border: 1px solid #ddd;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .SliderContent .slick-dots ul li div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .SliderContent .slick-dots ul li.slick-active {
  border: 1px solid #EE5A29;
  background-color: #EE5A29;
  color: #fff
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .SliderContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .SliderContent .slick-prev {
  width: 30px;
  height: 30px;
  left: -35px
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .SliderContent .slick-next {
  width: 30px;
  height: 30px;
  right: -35px
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .SliderContent .slick-prev::before {
  color: #EE5A29;
  font-size: 30px;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .SliderContent .slick-next::before {
  color: #EE5A29;
  font-size: 30px;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .shape1 {
  border: 15px solid #FF3B01;
  opacity: 0.6;
  border-radius: 100%;
  position: absolute;
  top: -60px;
  right: 150px;
  width: 86px;
  height: 86px;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .dialogRight .shape2 {
  border: 15px solid #FF3B01;
  opacity: 0.6;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  right: -60px;
  width: 86px;
  height: 86px;
}

.questionOfTheDayDialog .questionOfTheDayDialog-container .close {
  position: absolute;
  top: 30px;
  right: 40px;
  font-weight: bold;
  font-size: 21px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.questionOfTheDayDialog .questionOfTheDayDialog-content {
  display: flex;
  width: 100%;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 0px;
  border-bottom: 0px solid var(--primary);
}

.questionOfTheDayDialog .questionOfTheDayDialog-content .left-content {
  display: flex;
  flex: 1 1;
  color: #090807;
  font-size: 17px;
  align-items: center;
  font-weight: bold;
}

.questionOfTheDayDialog .questionOfTheDayDialog-content .right-content {
  display: flex;
  width: 100px;
  height: 100px;
  margin-right: 20px;
  color: #090807;
  font-size: 17px;
  font-weight: bold;
}

.questionOfTheDayDialog .questionOfTheDayDialog-content .right-content img {
  max-width: 100%;
}

.questionOfTheDayDialog .questionOfTheDayDialog-inner ol {
  display: flex;
  width: 100%;
  overflow-y: auto;
  max-height: 285px;
  flex-direction: column;
  margin: 0;
  padding: 0
}

.questionOfTheDayDialog .questionOfTheDayDialog-inner ol::-webkit-scrollbar-thumb {
  background-color: #EE5A29
}

.questionOfTheDayDialog .questionOfTheDayDialog-inner ol li {
  display: flex;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #E4D2D2;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
}

.questionOfTheDayDialog .questionOfTheDayDialog-inner ol li img {
  max-width: 100%;
}

.questionOfTheDayDialog .questionOfTheDayDialog-inner ol li:hover {
  border: 1px solid #299cee
}

.questionOfTheDayDialog .questionOfTheDayDialog-inner ol li:focus {
  bordeR: 0px none;
  outline: 0px none;
}

.questionOfTheDayDialog .questionOfTheDayDialog-inner ol li.selected {
  border: 1px solid #60b236;
}

.questionOfTheDayDialog .questionOfTheDayDialog-inner ol li.selected .right-content {
  color: var(--primary)
}

.questionOfTheDayDialog .questionOfTheDayDialog-inner ol li .left-content {
  display: flex;
  width: 120px;
  height: 120px;
  margin-right: 20px;
  color: #090807;
  font-size: 14px;
  font-weight: bold;
}

.questionOfTheDayDialog .questionOfTheDayDialog-inner ol li .right-content {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 0px;
  color: #B1B1B1;
  font-size: 14px;
  font-weight: normal;
}

.questionOfTheDayDialog .questionOfTheDayDialog-actions {
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
}

.questionOfTheDayDialog .questionOfTheDayDialog-actions button {
  background-color: #EE5A29;
  font-weight: normal;
  border: 1px solid #EE5A29;
}

.questionOfTheDayDialog .questionOfTheDayDialog-actions a {
  margin-left: 15px;
}

.questionOfTheDayDialog .questionOfTheDayDialog-reviewactions {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.questionOfTheDayDialog .questionOfTheDayDialog-reviewactions .gotit-link {
  margin-bottom: 15px;
  color: var(--primary);
  display: flex;
}

.questionOfTheDayDialog .questionOfTheDayDialog-reviewactions a {
  color: var(--primary)
}

.questionOfTheDayDialog .questionOfTheDayDialog-reviewactions div button {
  margin-left: 15px;
  background-color: #EE5A29;
  border: 1px solid #EE5A29;
}

.questionOfTheDayDialog .questionOfTheDayDialog-reviewactions div button:first-child {
  margin-left: 0px;
  background-color: transparent;
  border: 1px solid var(--primary);
  color: var(--primary)
}

.questionOfTheDayDialog .questionOfTheDayDialog-navigation {
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.questionOfTheDayDialog .questionOfTheDayDialog-navigation button {
  background-color: #EE5A29;
  font-weight: normal;
  border: 1px solid #EE5A29;
}

.questionOfTheDayDialog {
  /* position: fixed; */
  font-family: lato;
  display: flex;
  /* display: none; */
  align-items: center;
  /* z-index: 2250000; */
  justify-content: center;
}

.headerNotificationLink {
  margin-right: 20px;
  display: flex;
}

.headerNotificationLink a {
  display: flex;
  position: relative;
  align-items: center;
  color: var(--primary);
}

.headerNotificationLink a span {
  position: absolute;
  width: auto;
  line-height: 15px;
  padding: 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  height: 15px;
  border-radius: 5px;
  right: -10px;
  top: -10px;
  background-color: var(--primary);
  color: var(--white);
}

.qod-list-content {
  height: 597px !important;
  width: 55% !important;
}

.notification-box {
  border: 1px solid gray;
  z-index: 19;
  position: fixed;
  /* width: 113px; */
  padding: 14px;
  opacity: 0.7;
  right: 25px;
  top: 17px;
  background-color: red;
  color: white;
  font-size: 16px;
  font-family: lato;
  border-radius: 5px;
  display: none;
}

.no-scheduled-question {
  font-size: 19px;
  font-family: lato;
  font-weight: 600;
  margin-top: 200px;
  margin-left: 85px;
}

.question-head {
  font-weight: 600;
  font-size: 24px;
  font-family: lato;
}


.complete-details-config {
  height: 65% !important;
  width: 45% !important;
}

.sidebar-links a .menu-icon {
  width: 30px;
  height: 30px;
  margin-right: 0;
  flex-shrink: 0;
}

.sidebar-links a .menu-icon img {
  max-width: 100%;
  margin-right: 0;
  width: auto;
  height: auto;
}

.sidebar-links a .menu-text {
  visibility: hidden;
  transition: all 0.2s ease;
  opacity: 0;
  margin-left: 15px
}

.header2 .CloseLink {
  display: flex;
  display: none;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 15px 15px 0 15px;
  color: #000
}

.header2 .CloseLink a {
  color: #000;
  font-size: 23px;
  font-weight: bold;
}


.sidebar a .menu-icon {
  width: 30px;
  height: 30px;
  margin-right: 0;
  flex-shrink: 0;
}

.sidebar a .menu-icon img {
  max-width: 100%;
  margin-right: 0;
  width: auto;
  height: auto;
}

.sidebar a .menu-text {
  visibility: hidden;
  transition: all 0.2s ease;
  opacity: 0;
  margin-left: 15px
}

header .CloseLink {
  display: flex;
  display: none;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 15px 15px 0 15px;
  color: #000
}

header .CloseLink a {
  color: #fff;
  font-size: 23px;
  font-weight: bold;
}

.bottomSidebar {
  position: fixed;
  box-shadow: 0 0 5px #999;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  display: none;
  align-items: center;
  z-index: 2200000;
  height: 50px;
  right: 0;
  padding: 20px 30px;
  background-color: #fff;
}

.bottomSidebar .toggleMenu {
  display: flex;
  flex-direction: column;
}

.bottomSidebar .toggleMenu span:nth-child(1) {
  width: 30px;
  height: 4px;
  background-color: #489198
}

.bottomSidebar .toggleMenu span:nth-child(2) {
  width: 30px;
  margin-top: 4px;
  height: 4px;
  background-color: #489198
}

.bottomSidebar .toggleMenu span:nth-child(3) {
  width: 30px;
  margin-top: 4px;
  height: 4px;
  background-color: #489198
}

.notifications-count a {
  position: relative;
}

.notifications-count .count {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -15px;
  right: -10px;
  width: 25px;
  height: 25px;
  color: #489198;
  border-radius: 100%;
  background-color: #fff;
}

.complete-details-dialog-config-content {
  height: auto !important;
  padding: 0px !important
}

.complete-details-dialog-config-content div {
  padding: 8px !important
}

.complete-details-dialog-config-content .complete-details-dialog-config-container {
  display: flex;
  width: 100%;
  font-family: 'Lato';
}

.complete-details-dialog-config-content .content-image {
  width: 100%;
  max-width: 150px;
  margin-right: 15px;
}

.complete-details-dialog-config-content .content-image img {
  max-width: 100%;
}

.complete-details-dialog-config-content .config-dialog-content {
  display: flex;
  flex-direction: column;
}

.complete-details-dialog-config-content .content-title {
  margin: 0 0 10px;
  color: #000;
  font-size: 23px;
}

.complete-details-dialog-config-content .content-desc {
  margin: 0 0 10px;
  color: #666;
  font-size: 16px;
  line-height: 24px;
}

.complete-details-dialog-config-content .content-button {
  margin: 0 0 10px;
}

.complete-details-dialog-config-content .content-button a {
  border: 1px solid #3ac47d;
  color: #fff;
  background-color: #3ac47d;
  font-size: 18px;
  ;
  border-radius: 5px;
  padding: 6px 25px;
}

/*Responsive Css Start From here*/
@media only screen and (min-width: 1000px) {
  .sidebar-links a .menu-text {
    visibility: visible;
    transition: all 0.2s ease;
    opacity: 1;
    margin-left: 15px
  }

  .sidebar a .menu-text {
    visibility: visible;
    transition: all 0.2s ease;
    opacity: 1;
    margin-left: 15px
  }
}

@media only screen and (max-width: 1000px) {
  .sidebar-links {
    display: flex;
    flex-direction: column;
  }

  .header2 {
    width: 98px;
    padding: 0px;
    overflow-x: hidden;
    transition: all 0.2s ease;
  }

  header {
    width: 98px;
    padding: 0px;
    overflow-x: hidden;
    transition: all 0.2s ease;
    box-shadow: -4px -4px 15px 0px #ffffff9e, 4px 4px 15px 0px #a3b1c6a8;
  }

  .header2:hover {
    width: 210px;
    white-space: nowrap;
  }

  .header2:hover .sidebar-links a .menu-text {
    visibility: visible;
    opacity: 1;
  }

  .sidebar-links a {
    flex-direction: row;
    display: flex;
    padding: 10px 10px 10px 28px;
    margin-bottom: 15px
  }

  header:hover {
    width: 210px;
    white-space: nowrap;
    background-color: #fff
  }

  header:hover .sidebar a .menu-text {
    visibility: visible;
    opacity: 1;
  }

  .sidebar a {
    flex-direction: row;
    display: flex;
    padding: 10px 10px 10px 28px;
    margin-bottom: 15px
  }


  .header2 .logo-sow img {
    width: 60px
  }

  .header2 .brand span {
    display: none;
  }

  header .logo-sow img {
    width: 60px
  }

  header .brand span {
    display: none;
  }

  .app-header {
    left: 180px;
    width: auto;
    right: 20px
  }

  .app-div {
    width: calc(100% - 153px);
    right: 20px
  }

  .admin-div {
    width: calc(100% - 153px);
    right: 20px
  }

  .complete-details-content {
    width: 92% !important;
    height: auto !important
  }

  .complete-details-content .content-button {
    margin-left: 0;
    justify-content: center;
  }
}


@media only screen and (max-width: 600px) {
  .header2 {
    display: none;
    width: 100%;
  }

  header {
    display: none;
    width: 100%;
  }

  .sidebar-links {
    padding: 0px 0 20px;
    margin-top: 0
  }

  .header2.mobilenavigationActive .CloseLink {
    display: flex;
  }

  .header2.mobilenavigationActive {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 220000;
    left: 0;
    right: 0;
    height: 520px;
    bottom: 50px;
    top: auto
  }

  .header2.mobilenavigationActive .brand {
    display: none;
  }

  .header2.mobilenavigationActive .sc-logo-div-up {
    display: none;
  }

  .header2 .sidebar-links a .menu-text {
    visibility: visible;
    opacity: 1;
  }

  header.mobilenavigationActive .CloseLink {
    display: flex;
  }

  header.mobilenavigationActive {
    display: flex;
    background-color: #489198;
    flex-direction: column;
    width: 100%;
    z-index: 220000;
    left: 0;
    right: 0;
    height: 520px;
    bottom: 50px;
    top: auto
  }

  header.mobilenavigationActive .brand {
    display: none;
  }

  header.mobilenavigationActive .sc-logo-div-up {
    display: none;
  }

  header .sidebar-links a .menu-text {
    visibility: visible;
    opacity: 1;
  }

  header.mobilenavigationActive .sidebar .crescent {
    display: none
  }

  header.mobilenavigationActive .sidebar .crescent2 {
    display: none
  }

  header.mobilenavigationActive .sidebar a.logo-noesis {
    display: none !important;
  }

  header.mobilenavigationActive .contact-us {
    display: none;
  }


  .bottomSidebar {
    display: flex;
  }

  .app-div {
    left: 0px;
    width: 100%;
    padding: 10px 20px 20px 20px;
    height: calc(100vh - 225px);
    top: 70px
  }

  .app-div #path-b {
    display: none
  }

  .app-div #path-d {
    display: none
  }

  .admin-div {
    left: 20px;
    width: auto;
    padding: 10px 20px 20px 20px;
    height: calc(100vh - 225px);
    top: 70px
  }

  .app-header {
    left: 20px;
    width: auto;
    right: 20px;
    top: 20px !important
  }

  .app-header .search-wrapper {
    width: 60%;
    margin-right: 15px
  }

  .app-header {
    left: 20px;
    width: auto;
    right: 20px;
    top: 20px !important
  }

  .app-header .search-wrapper {
    width: 60%;
    margin-right: 15px
  }

  .dropdown-content2-setting {
    width: 203px;
  }

  .dropdown-content2 {
    width: 315px;
  }

  .qod-list-overlay .qod-list-content {
    height: auto !important;
    width: 95% !important
  }

  .questionOfTheDayDialog .questionOfTheDayDialog-inner ol li .left-content {
    width: 80px;
    height: 80px;
  }

  .questionOfTheDayDialog .questionOfTheDayDialog-actions a {
    color: #EE5A29
  }

  .questionOfTheDayDialog .questionOfTheDayDialog-container .dialogLeft {
    display: none;
  }

  .page-header {
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
  }

  .page-header .page-header-left {
    margin-bottom: 20px;
  }

  .page-header .page-header-right select {
    min-width: 80px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-left: 0;
    max-width: 100px;
  }

  .page-header .page-header-right select:last-child {
    margin-right: 0
  }

  .page-header .page-header-right {
    flex-wrap: wrap;
  }


  .admin-div .the-grid {
    flex-direction: column;
    display: flex;
  }

  .admin-div .main-part {
    padding: 0 10px 10px;
    height: calc(100% - 100px) !important;
  }

  .admin-div .main-part .sub-head {
    align-items: center
  }

  .admin-div .main-part .sub-head .heading-part1 {
    min-width: inherit !important
  }

  .admin-div .main-part .sub-head .heading-part1 h1 {
    font-size: 20px;
  }

  .admin-div .main-part .sub-head .heading-part2 span {
    margin-left: 0
  }

  .admin-div .search-wrapper-admin .search-container input[type="search"] {
    width: auto
  }

  .admin-div .list-item {
    display: flex;
    flex-direction: column
  }

  .admin-div .list-item .title-item3 {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px
  }

  .admin-div .list-item .section-item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px
  }

  .admin-div .list-item .class-item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px
  }

  .admin-div .list-item .subject-item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px
  }

  .admin-div .list-item .faculty-item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px
  }

  .admin-div .list-item .action-item {
    width: 100%;
    padding-right: 0;
    justify-content: flex-end;
    margin-bottom: 10px
  }

  .admin-div .the-assessment-list .title-item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px
  }

  .admin-div .the-assessment-list .email-item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px
  }

  .admin-div .the-assessment-list .subject-item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px
  }

  .admin-div .the-assessment-list .faculty-item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px
  }

  .admin-div .the-assessment-list .action-item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }

  .admin-div .the-assessment-list .action-item2 {
    width: 100%;
    padding-right: 0;
    margin-bottom: 0px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row
  }

  .admin-div .list-assessment .list-title {
    width: auto;
    padding-right: 10px;
    margin-bottom: 10px
  }

  .admin-div .list-assessment .list-section {
    width: auto;
    padding-right: 10px;
    margin-bottom: 10px
  }

  .admin-div .list-assessment .list-class {
    width: auto;
    padding-right: 10px;
    margin-bottom: 10px
  }

  .admin-div .list-assessment .list-faculty {
    width: auto;
    padding-right: 10px;
    margin-bottom: 10px
  }

  .admin-div .list-assessment .list-subject {
    width: auto;
    padding-right: 10px;
    margin-bottom: 10px
  }

  .admin-div .list-assessment .action-item {
    width: 100%;
    padding-right: 10px;
    margin-bottom: 10px
  }

  .admin-div .list-assessment {
    flex-wrap: wrap;
    justify-content: center;
  }

  .admin-div .list-assessment div {
    margin-bottom: 5px
  }

  .admin-div .unitwrapper {
    flex-direction: column
  }

  .admin-div .unitwrapper .unit-head-data {
    flex-direction: column
  }

  .admin-div .unitwrapper .unit-head-data .unit-head-label {
    margin-bottom: 10px;
    font-size: 19px;
    font-weight: normal
  }

  .admin-div .unitwrapper .unit-head-data .unit-head-label+div {
    flex-direction: column
  }

  .admin-div .unitwrapper .unit-head-data .unit-head-label+div span {
    margin-bottom: 10px
  }

  .admin-div .unitwrapper .unit-head-data .unit-head-label+div button {
    margin-bottom: 10px;
    margin-left: 0
  }

  .admin-div .unitwrapper .unit-head-data .unit-head-label+div span:nth-child(2) {
    margin-left: 0
  }

  .admin-div .unit-handler {
    padding: 15px;
    max-height: none;
    flex-direction: column
  }

  .admin-div .single-unit {
    max-height: none !important
  }

  .admin-div .sub-head {
    flex-direction: column
  }

  .admin-div .sub-head .heading-part1 {
    margin-bottom: 10px;
  }

  .admin-div .sub-head .heading-part1-ques {
    margin-bottom: 10px;
  }

  .admin-div .sub-head .heading-part2 {
    overflow: auto;
    width: 100%;
    justify-content: flex-start;
    min-width: inherit;
    white-space: nowrap
  }

  .admin-div .sub-head .heading-part2 span {
    margin-right: 10px;
  }

  .admin-div .sub-head .heading-part2 span:last-child {
    margin-right: 0
  }

  .admin-div .sub-filter {
    overflow: auto;
    width: 100%;
    justify-content: flex-start;
    min-width: inherit;
    white-space: nowrap
  }

  .admin-div .sub-head span {
    margin-left: 0
  }

  .admin-div .admin-calendar {
    height: 100px;
  }

  .admin-div .admin-calendar .cal-buttons {
    flex-direction: row;
    overflow: auto;
    flex-wrap: nowrap;
  }

  .admin-div .admin-calendar .cal-header {
    width: 100%;
    flex-direction: row;
    overflow: auto;
    flex-wrap: nowrap;
  }

  .admin-div .admin-calendar .cal-header .header-op2 {
    margin-left: 10px;
  }

  .change-password-content {
    width: 90% !important;
  }

  .change-password-content .changePasswordWrapper {
    flex-direction: column;
    padding: 10px
  }

  .change-password-content .changePasswordWrapper .leftImage {
    width: 100% !important;
    text-align: center;
  }

  .change-password-content .changePasswordWrapper .leftImage img {
    width: 150px !important;
    height: auto !important;
  }

  .change-password-content .changePasswordWrapper .RightCnt {
    width: 100% !important;
    padding-top: 0 !important
  }

  .change-password-content .changePasswordWrapper .button-class {
    margin-top: 0
  }


  .popup-content-edit-pofile-content {
    width: 95% !important;
    height: auto
  }

  .popup-inputs-edit-profile {
    display: flex;
    width: 100%;
    flex-direction: column
  }

  .popup-inputs-edit-profile input[type="text"] {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .popup-inputs-edit-profile input[type="email"] {
    width: 100% !important;
  }

  .popup-inputs-edit-profile .img-prev {
    padding: 0;
    width: 100%;
  }


  .no-scheduled-question {
    margin: 0
  }

  .shareapp-content {
    width: 90% !important;
  }

  .shareapp-content .social-share textarea {
    width: 100%;
    margin: 0
  }

  .shareapp-content .social-share {
    max-height: 300px;
    overflow-y: auto
  }


}

.textContent {
  word-break: break-word;
  overflow-wrap: break-word;
  width: 210px;
  color: #aa9d9d;
  font-size: 13px;
  font-family: 'Lato';
}

.appDownload {
  color: white;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  display: none;
}

.appDownload a {
  color: white;
  display: flex;
  white-space: nowrap;
  border-radius: 9px;
  padding: 6px 10px;
  font-size: 16px;
  align-items: center;
  color: #3ac47d;
  border: 1px solid var(--primary);
  margin-left: 15px;
}

.appDownload a:first-child {
  margin-left: 0;
}

.appDownload img {
  width: 22px;
  height: 22px;
  margin-right: 0px;
}

/*Responsive Css ends here*/
.error-div {
  z-index: 100;
  overflow: hidden;
  width: calc(100vw - 30px);
  height: calc(100vh - 30px);
  position: fixed;
  top: 15px;
  right: 15px;
  border-radius: 30px;
  background-color: var(--white);
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-box-div{display: flex; justify-content: center;}

.error-div img {
  /* width: 580px; */
  width: 56%;
}

.error-div div {
  margin-top: 50px;
  display: flex;
  align-items: center;
}

.error-div a {
  font-family:Lato;
  font-size: 24px;
  font-weight: 600;
  color: var(--primary);
  text-decoration: none;
  border-bottom: 1px solid transparent;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
}

.error-div a:hover {
  border-color: var(--primary);
}

.empty-div {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Lato;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: var(--text-black3);
}

.empty-div img {
  width: 280px;
  margin-bottom: 30px;
}

.empty-box-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Lato;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: var(--text-black3);
}

.empty-box-div img {
  width: 280px;
  height: 250px;
}


@media only screen and (max-width: 600px) { 
  .error-div img{width: 100%;}
}
.asm-header {
  display: block;
  z-index: 10;
  position: sticky;
	top: 10px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
  background-color: var(--white);
  margin-bottom: 16px;
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(0,0,0,0.14);
}

.print-only{margin-bottom: 0;}

@media print {
  body {
    visibility: hidden;
  }

  .print-only {
    visibility: visible;
  }
}

.questionSummaryDialog.open{display: flex;}
.questionSummaryDialog{position:fixed; z-index: 3399999999; background-color: rgba(0,0,0,.6);  font-family: Lato; width: 100%; top:0; right:0; bottom:0; left:0; display: flex; display: none; align-items: center; justify-content: center;}
.questionSummaryDialog .questionSummaryContainer{background-color: #fff; display: flex; flex-direction: column; width: 100%; max-width: 550px; padding:20px; border-radius: 10px;}
.questionSummaryDialog .questionSummaryContainer h3{display: flex; justify-content: center;} 
.questionSummaryDialog .questionSummaryContainer .dateTime{display: flex; justify-content: center; grid-gap:15px; gap:15px; margin-top: 10px;} 
.questionSummaryDialog .questionSummaryContainer .dateTime  span{display: flex; align-items: center; grid-gap:5px; gap:5px; font-size: 14px; color: #828282;}
.questionSummaryDialog .questionSummaryContainer .summary{display: flex; padding: 10px 0; margin:15px 0; width: 100%;}
.questionSummaryDialog .questionSummaryContainer .summary ol{margin: 0; justify-content: space-between; width: 100%; display: flex; grid-gap:20px; gap:20px; padding: 10px 0; border-top:1px solid #D9D9D9; border-bottom:1px solid #D9D9D9}
.questionSummaryDialog .questionSummaryContainer .summary ol li{display: flex; margin:0; padding: 0; grid-gap:10px; gap:10px; flex-direction: column;}
.questionSummaryDialog .questionSummaryContainer .summary ol li p{ margin:0; padding: 0; display: flex; align-items: center; grid-gap:5px; gap:5px;  text-align: center; font-size: 14px; color: #828282;}
.questionSummaryDialog .questionSummaryContainer .summary ol li p + p{text-align: center; justify-content: center; font-weight: bold;}
.questionSummaryDialog .questionSummaryContainer .summary ol li p .greenColor{width: 10px; background-color: forestgreen; height: 10px; border-radius: 100%; display: flex;}
.questionSummaryDialog .questionSummaryContainer .summary ol li p .orangeColor{width: 10px;  background-color:#FF7B17; height: 10px; border-radius: 100%; display: flex;}

.questionSummaryDialog .questionSummaryContainer .summary ol li.greenColor p + p{color: forestgreen; font-weight: bold;}
.questionSummaryDialog .questionSummaryContainer .summary ol li.orangeColor p + p{color: #FF7B17; font-weight: bold;}

.questionSummaryDialog .questionSummaryContainer .attemptSummary .infoBlock h3{font-size: 14px;}
.questionSummaryDialog .questionSummaryContainer .attemptSummary .infoBlock{display: flex; justify-content: space-between; align-items: flex-start; width: 100%;}
.questionSummaryDialog .questionSummaryContainer .attemptSummary .infoBlock div{display: flex; align-items: center; grid-gap:20px; gap:20px}
.questionSummaryDialog .questionSummaryContainer .attemptSummary .infoBlock div span{font-size: 14px; color: #828282; display: flex; grid-gap:5px; gap:5px; align-items: center;}
.questionSummaryDialog .questionSummaryContainer .attemptSummary .infoBlock div span i{width: 10px; height: 10px; border-radius: 100%; display: flex;}
.questionSummaryDialog .questionSummaryContainer .attemptSummary .infoBlock div span:nth-child(1) i{background-color: forestgreen;}
.questionSummaryDialog .questionSummaryContainer .attemptSummary .infoBlock div span:nth-child(2) i{background-color:#FF7B17;}
.questionSummaryDialog .questionSummaryContainer .attemptSummary{display: flex; flex-direction: column; width: 100%;} 
.questionSummaryDialog .questionSummaryContainer .questionBlock{background-color: #f5f5f5; margin:20px 0; width: 100%; overflow-y: auto; max-height: 200px; flex-wrap: wrap; grid-gap:10px; gap:10px; display: flex; padding:10px; border-radius: 10px;}
.questionSummaryDialog .questionSummaryContainer .questionBlock span{width:34px; cursor: pointer; background-color:#FF7B17; font-size: 12px; height: 24px; display: flex; align-items: center; justify-content: center; border-radius: 5px; color: #fff;}
.questionSummaryDialog .questionSummaryContainer .questionBlock span.selected{background-color: forestgreen;}
.questionSummaryDialog .questionSummaryContainer .actions{margin:0px 0; grid-gap:20px; gap:20px; border-top: 1px solid #D9D9D9; padding-top: 10px; width: 100%;  justify-content: center; align-items: center; flex-direction: column; display: flex;}
.questionSummaryDialog .questionSummaryContainer .actions p{color: #ff0000; margin:0; padding: 0;}
.questionSummaryDialog .questionSummaryContainer .actions div{display: flex; grid-gap:15px; gap:15px;  align-items: center; justify-content: center; width: 100%;}






.participationMessage{font-family: lato; font-size:16px; color:#727C95; text-align: center; line-height:26px;}
.participationMessage + .AssessmentInfo{display: flex; grid-gap:10px; gap:10px; margin-top:0px; padding-top: 15px; border-top:1px solid #CED4E7;  flex-direction: column; width: 100%; font-family: lato;}
.participationMessage + .AssessmentInfo h3{font-family: Lato; margin:0; padding: 0; font-weight: normal; font-size: 18px; text-align: center;}
.participationMessage + .AssessmentInfo h5{font-family: Lato; margin:0; padding: 0;  font-size: 18px; text-align: center;}
.participationMessage + .AssessmentInfo p{font-family: lato; margin:0; padding: 0;  font-size: 18px; color:#727C95; text-align: center; line-height: 30px;}
.participationMessage + .AssessmentInfo .actions{display: flex; grid-gap:20px; gap:20px; align-items: center; justify-content: center; }
.participationMessage + .AssessmentInfo .summary{display: flex; padding: 10px 0; margin:0px 0; width: 100%;}
.participationMessage + .AssessmentInfo .summary ol{margin: 0; justify-content: space-between; width: 100%; display: flex; grid-gap:20px; gap:20px; padding: 10px 0; border-top:1px solid #D9D9D9; border-bottom:1px solid #D9D9D9}
.participationMessage + .AssessmentInfo .summary ol li{display: flex; margin:0; padding: 0; grid-gap:0px; gap:0px; flex-direction: column;}
.participationMessage + .AssessmentInfo .summary ol li p{ margin:0; padding: 0;; font-size: 14px; color: #828282;}
.participationMessage + .AssessmentInfo .dateTime{display: flex; justify-content: center; align-items: center; grid-gap:15px; gap:15px; margin-top:0px;} 
.participationMessage + .AssessmentInfo .dateTime  span{display: flex; width: auto; align-items: center; grid-gap:5px; gap:5px; font-size: 14px; color: #828282;}


.DisplayRandomizeQuestionsInput{max-width: 100px;}
.DisplayRandomizeQuestionsInput::-webkit-outer-spin-button,
.DisplayRandomizeQuestionsInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.DisplayRandomizeQuestionsInput{
  -moz-appearance: textfield;
}
.grid-actions .increaseAttempt{display: flex; width:auto; grid-gap:5px; gap:5px; font-weight: 600; font-size: 18px; font-family:Lato; height: auto; color:var(--primary)}
.grid-actions .increaseAttempt:hover{background-color: transparent;}

.asm-header div {
  font-family: Lato;
  font-weight: 700;
  font-size: 18px;
}

#no_save {
  width: 78px;
  height: 10px;
}

.sec-ques-head :nth-last-child(1) img {
  margin-left: 10px;
}

.asm-bottom {
  position: absolute;
  right: 50px;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.asm-bottom button:last-child {
  margin-left: 20px;
}

.questionImage img{
  width: 300px;
  cursor: pointer;
  /* border: 1px solid #a39be6; */
  box-shadow: 2px 2px 2px 2px #dcdcef;
}

.asm-container {
  height: calc(100vh - 200px);
  overflow: auto;
  margin-top: 35px;
  padding-bottom: 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#asm-titles {
  font-family: Lato;
  font-weight: 600;
  color: var(--text-black);
  font-size: 18px;
  display: inline-block;
  margin-top: 30px;
}

#asm-titles3 {
  font-family: Lato;
  font-weight: 600;
  color: var(--text-black);
  font-size: 18px;
  display: inline-block;
}

.base-settings {
  display: flex;
}

.base-settings div:nth-child(2) {
  margin-left: 80px;
}

#asm-titles2 {
  width: 100%;
  font-family: Lato;
  font-weight: 600;
  color: var(--text-black);
  font-size: 20px;
  display: inline-block;
  margin-bottom: 25px;
}

.asm-ques {
  width: 100%;
}

.asm-options {
  width: 350px;
}

.asm-options-tab {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
}

.asm-options-tab div {
  cursor: pointer;
  width: 50%;
  text-align: center;
  padding: 12px 20px;
  font-weight: 600;
  color: var(--text-black);
  font-family: Lato;
  font-size: 18px;
  background-color: var(--secondary-rgba);
}

.asm-options-tab div:hover {
  opacity: 0.8;
}

.asm-options-tab #tab-selected {
  background-color: var(--secondary);
}

.asm-main-sett {
  margin-top: 50px;
  width: 100%;
}

.asm-settings {
  width: 100%;
  height: calc(100vh - 330px);
  overflow-y: auto;
  margin-top: 30px;
  border-bottom: 1px solid #f0f0f0;
}

.asm-setting-head {
  padding-bottom: 15px;
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 1px solid #dddddd;
  color: var(--text-black2);
  margin-bottom: 15px;
}

.asm-setting-div {
  width: 60%;
  margin-top: 30px;
  background-color: var(--secondary-rgba);
  padding: 12px 20px;
  border-radius: 6px;
  transition: .3s all;
}

.asm-setting-div2 {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
}

.asm-setting-div3 {
  margin-top: 40px;
  width: 60%;
  display: flex;
  align-items: center;
}

.asm-setting-div3 span {
  width: -webkit-max-content;
  width: max-content;
  font-weight: 500;
  font-family: Lato;
  font-size: 18px;
  color: var(--text-black);
  background-color: var(--primary-rgba);
  margin-right: 35px;
  padding: 12px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.asm-setting-div3 span:last-child {
  margin-right: 0;
}

.asm-setting-sections {
  margin-left: 60px;
}

.asm-setting-option {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.asm-setting-option span {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: Lato;
  font-size: 17px;
  color: var(--text-black);
  font-weight: 500;
}

.asm-setting-horizon {
  margin-top: 12px;
  height: 32px;
  display: flex;
  margin-left: 0 !important;
  align-items: center;
  font-weight: 400;
  font-family: Lato;
  font-size: 18px;
  color: var(--text-black2);
  letter-spacing: 0.01em;
}

.asm-setting-horizon2 {
  margin-top: 15px;
  margin-left: 0 !important;
  font-family: Lato;
	font-size: 16px;
	border-radius: 4px;
  border: solid 1px var(--primary);
  background-color: var(--secondary-rgba);
  padding: 8px 16px 9px 16px;
}

.asm-setting-horizon div:last-child {
  margin-left: 35px;
}

.asm-setting-horizon .asm-setting-duration {
  margin-left: -10px !important;
  margin-top: 0 !important;
}

.asm-setting-horizon div {
  display: flex;
  align-items: baseline;
}

.asm-setting-horizon input {
  margin: 0 0 !important;
}

.asm-setting-horizon label {
  margin-left: 10px;
  font-weight: 400;
  font-family: Lato;
  font-size: 16px;
  color: var(--text-black2);
  letter-spacing: 0.01em;
}

.asm-options-layer {
  display: flex;
  align-items: flex-start;
}

#asm-options-layer1 {
  margin-top: 15px;
}

.asm-single-sett {
  width: 50%;
}

.asm-single-sett:first-child {
  padding-left: 10px;
}

.asm-single-sett input {
  margin: 0 0 !important;
}

.asm-single-sett label {
  margin-left: 10px;
  font-weight: 600;
  font-family: Lato;
  font-size: 17px;
  color: var(--text-black);
  letter-spacing: 0.01em;
}

/*Toggle Button*/
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 26px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(20, 20, 20, 0.20);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 3px);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

/*Settings Data*/

.asm-setting-data {
  margin-top: 18px;
  margin-bottom: 6px;
  width: 100%;
}

#asm-setting-graded {
  display: flex;
  justify-content: space-between;
}

#asm-setting-graded input {
  /*width: 48%;*/
  font-family: Lato;
  font-size: 16px;
  border-radius: 4px;
  border: solid 1px var(--primary);
  background-color:var(--secondary-rgba);
  padding: 8px 16px 9px 16px;
}

.asm-setting-duration {
  margin-left: 0 !important;
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.asm-difficulty {
  margin-left: 0 !important;
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.asm-difficulty span {
  height: 33px;
  width: -webkit-max-content;
  width: max-content;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid var(--primary);
  background-color: var(--primary-rgba);
  color: var(--text-black);
  border-radius: 40px;
  padding-left: 16px;
  padding-right: 16px;
  font-family: Montserrat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selected-span {
  background-color: var(--primary) !important;
  color: white !important;
  border-color: var(--primary) !important;
}

.asm-difficulty span:hover {
  opacity: 0.85;
}

.asm-period {
  margin-top: 15px;
}

.asm-setting-duration span {
  background-color: var(--primary);
  color: white;
  font-size: 27px;
  font-weight: bold;
  padding-bottom: 3px;
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.asm-ques-settings span{
  background-color: var(--primary);
  color: white;
  font-size: 27px;
  font-weight: bold;
  padding-bottom: 3px;
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer !important;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.asm-ques-settings span {
  cursor: default;
}

.asm-setting-duration span:hover {
  opacity: 0.8;
}

.asm-setting-duration input, .asm-ques-settings input {
  width: 60px;
  border: none;
  padding: 3px 0 3px 0;
  border-bottom: 1px solid #bbbbbb;
  margin-right: 4px !important;
}

.asm-setting-duration div,.asm-ques-settings div, .asm-setting-duration input, .asm-ques-settings input {
  text-align: center;
  font-family: Lato;
  margin-right: 12px !important;
  margin-left: 12px !important;
  font-size: 16px;
  font-weight: 700;
  color: var(--text-black);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.asm-ques-settings input{border: 1px solid var(--primary); background-color: var(--primary-rgba);}
.asm-setting-duration input{border: 1px solid var(--primary); background-color: var(--primary-rgba);}

.ques-sett-ops select{border: 1px solid var(--primary); background-color: var(--primary-rgba);}
.asm-setting-levels {
  display: flex;
  flex-wrap: wrap;
}

.asm-setting-levels div {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 14px 6px 14px;
  border-radius: 40px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid var(--primary);
  color: var(--primary);
  background-color: white;
}

.asm-setting-levels #selected {
  background-color: var(--primary);
  color: white;
}

.asm-info {
  width: 100%;
}

#asm-info2 {
  margin-top: 60px;
}

.asm-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.asm-title input, .asm-title span {
  font-family: Lato;
	font-size: 16px;
	border-radius: 4px;
  border: solid 1px var(--primary);
  background-color: var(--secondary-rgba);
  padding: 8px 16px 9px 16px;
}

.asm-title input:first-child, .asm-title span:first-child {
  width: 65%;
}

.asm-title input:last-child, .asm-title span:last-child {
  width: 33.2%;
}

.asm-title select {
  outline: none;
	border: 1px solid var(--primary);
	font-family: Lato;
  font-size: 16px;
  width: 190px;
	color: var(--text-black2);
	background-color: var(--secondary-rgba);
	padding: 7px 10px 8px 10px;
	border-radius: 4px;
}

.asm-check {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.asm-check:last-child {
  margin-right: 0px;
}

.asm-check input {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.asm-check span {
  font-family: Lato;
  font-size: 16px;
  color: var(--text-black);
  font-weight: 400;
  margin-left: 8px;
}

.asm-analytics {
  width: 100%;
  height: calc(100vh - 330px);
  overflow-y: auto;
  margin-top: 30px;
  border-bottom: 1px solid #f0f0f0;
}

/*Sections*/

#sec-expand span {
  margin-right: 18px;
  background-color: var(--primary);
  width: 40px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#sec-expand img {
  width: 20px;
}

#empty-sec {
  margin-top: 50px;
  margin-bottom: 50px;
}

/* Questions */
.edit-question {
  width: 100%;
}

.edit-ques-head-on {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-ques-head-on span {
  font-family: Lato;
  font-size: 18px;
  color: var(--text-black);
  font-weight: 600;
  width: 100%;
}
.sub-head.newdesign .heading-part1{min-width: inherit;}
.sub-head.newdesign .heading-part2{min-width: inherit; flex:1 1}
.edit-ques-head-on select {
  font-family: Lato;
  font-size: 16px;
  background-color: var(--primary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 9px 14px 10px 14px;
  border-radius: 4px;
}

.ques-statement {
  width: 100%;
  margin-top: 25px;
  display: block;
  margin-bottom: 20px;
}

.ques-sett-ops {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#ques-sett-ops2 {
  margin-top: 24px;
}

.ques-sett-ops select {
  font-family: Lato;
  font-size: 16px;
  background-color: var(--primary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 10px 14px;
  border-radius: 4px;
  width: 40%;
}

.ques-sett-ops-uid input{
  font-family: Lato;
  font-size: 16px;
  background-color: var(--white);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 10px 14px;
  border-radius: 4px;
  margin-top:20px;
}

.ques-sett-ops-uid{
  margin-top: -20px;
  margin-left: 20px;
}


#ques-explanation {
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  font-family: Lato;
  color: var(--primary);
}

#ques-explanation-add {
  margin-left: 40px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  font-family: Lato;
  color: var(--primary);
}

.asm-ques-settings {
  display: flex;
  align-items: center;
  font-family: Lato;
  font-weight: 600;
  font-size: 16px;
  margin-top: 12px;
  margin-right: 56px;
}

.asm-ques-settings input {
  margin-left: 16px;
  margin-right: 20px !important;
}
.popupx-inputs, .popup-inputs5{font-family: Lato;}
.popupx3-inputs{font-family: Lato;}
.popupx3-inputs div span{margin-bottom: 10px; display: flex;}

.edit-question .edit-ques-head span{font-family: Lato; margin-bottom: 10px; display: flex;}
.grid-item .grid-up.topActions{display: flex; justify-content: space-between; flex-direction: row;}
.ques-statement textarea ,.ques-statement img {
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  font-family: Lato;
  color: var(--text-black);
  background-color: var(--primary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 9px 14px 10px 14px;
  border-radius: 4px;
}

.ques-options {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
}

.ques-options span, .sec-ques-options span {
  width: -webkit-max-content;
  width: max-content;
  font-family:Lato;
  font-size: 18px;
  display: block;
  font-weight: 400;
  margin-bottom: 25px;
}

.ques-options button {
  margin-top: 10px;
  width: -webkit-max-content;
  width: max-content;
  font-size: 18px;
  display: inline-block;
}

.ques-option {
  width: 60%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.ques-option span {
  display: block;
  min-width: 37px;
  height: 37px;
  border-radius: 5px;
  background-color: var(--white);
  border: 1px solid var(--primary);
  color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 0;
  font-weight: bold;
  font-family: Lato;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ques-option span:hover {
  opacity: 0.85;
}

#is_correct {
  background-color: var(--primary-rgba);
}

.ques-option input, #sec-ques-option {
  margin-left: 20px;
  width: 100%;
  font-size: 16px;
  font-family: Lato;
  color: var(--text-black);
  background-color: var(--primary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 9px 14px 10px 14px;
  border-radius: 4px;
  margin-right: 20px;
}

#remove-opt {
  border-radius: 50%;
  background-color: var(--primary);
  font-weight: bold;
  color: var(--white);
  cursor: pointer;
}

.ques-save {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.ques-save button:last-child {
  margin-left: 20px;
}

.sec-ques-list {
  width: 100%;
}

.sec-ques-data {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  margin-bottom: 30px;
}

.sec-ques-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sec-ques-head span {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
}

.sec-ques-head div {
  display: flex;
  align-items: center;
}

.sec-ques-head div span:nth-child(2) {
  background-color: var(--primary);
  color: white;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 3px;
  padding-left: 1px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 16%;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  margin-left: 20px;
  margin-right: 14px;
}

#sec-ques-type {
  font-family: Lato;
  font-size: 16px;
  background-color: var(--white);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 9px 14px 10px 14px;
  border-radius: 4px;
}

.sec-ques-head img {
  width: 20px;
  margin-left: 0px;
  cursor: pointer;
}

.sec-ques-head :nth-last-child(1) img {
  margin-left: 10px;
}

.sec-ques-statement {
  margin-top: 15px;
  font-size: 16px;
  font-family: Lato;
  color: var(--text-black);
  background-color: var(--white);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 9px 14px 10px 14px;
  line-height: 1.5;
  border-radius: 4px;
}

.sec-ques-options {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.sec-ques-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.single-sec-ques-detail {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 17px;
  margin-right: 30px;
}

#single-sec-ques-detail2 {
  cursor: pointer;
}

#see_explanation_ques {
  margin-top: 20px;
  width: 100%;
  height: 150px;
  border: 1px solid var(--primary);
  background-color: var(--primary-rgba);
  border-radius: 4px;
  font-family: Lato;
  font-size: 16px;
  line-height: 1.5;
  padding: 12px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: wrap;
}

.single-sec-ques-detail span {
  font-family: Lato;
  color: var(--text-black2);
  font-weight: 400;
  margin-bottom: 0;
}

.single-sec-ques-detail p {
  font-family: Lato;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
  color: var(--primary);
  font-weight: 700;
}

.sch-tests {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 80px;
}

.sch-test {
  width: 100%;
  display: flex;
  height: 130px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--light-black3);
}

.sch-left {
  width: 180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-weight: 400;
  font-size: 20px;
  color: var(--text-black);
  border-right: 3px solid var(--primary);
}

.sch-left span {
  font-size: 18px;
  color: var(--primary);
  font-weight: 600;
  margin-bottom: 4px;
}

.sch-right {
  position: relative;
  width: calc(100% - 180px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  font-family: Lato;
  font-weight: 600;
  color: var(--text-black);
  font-size: 18px;
  letter-spacing: 0.01em;
}

.sch-right a {
  color: var(--text-black2);
  display: flex;
  flex-direction: column;
}

.sch-right span {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-black3);
}

.sch-options {
  position: absolute;
  right: 35px;
  display: flex;
  align-items: center;
}

.sch-options span, .sch-options a {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 15px;
	cursor: pointer;
	border-radius: 10px;
}

.sch-options span:hover, .sch-options a:hover {
	background-color: rgba(95, 125, 149, 0.15);
}

.sch-options span:last-child {
  margin-right: 0;
}

.sch-options img {
	width: 20px;
}

/* Assessment Preview */

.assessment-div {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.assessment-head {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
}

.assessment-head1, .assessment-head3 {
  position: absolute;
  width: 225px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.timerClass{
  display: flex;
  margin-top: 10px;
}

.assessment-head4 {
  position: absolute;
  right: 40px;
  display: flex;
  align-items: center;
}

.assessment-head4 button:last-child {
  margin-left: 20px;
}

.assessment-head1 {
  left: 40px;
  top:14px;
}

.assessment-head1 span {
  font-weight: 700;
  font-family: Lato;
  font-size: 19px;
  font-size: 17px;
}

.assessment-head3 {
  right: 40px;
  align-items: flex-end;
}

.assessment-head3 button {
  margin-top: 15px;
  width: -webkit-max-content;
  width: max-content;
}

.five_minutes {
  background-color: #ff6464;
  color: var(--white);
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 8px 6px 8px;
  border-radius: 4px;
  text-align: center;
  margin-right: 16px;
  width: 150px;
}

#time_left {
  margin-right: 10px;
}

.time-remaining .timer {
	font-family: Lato;
	font-size: 16px;
	font-weight: bold;
	color: #777;
}

.timer{
  color: var(--primary)
}

.subject .timer{
  color: #777;
}

.assessment-head4 {
  font-family: Lato;
  font-size: 18px !important;
  font-weight: 700;
  top:14px;
}

.assessment-head2 {
  position: relative;
  max-width: 790px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -2px;
}

.assessment-section-buttons {
  font-family: Lato;
  color: var(--text-black);
  font-size: 17px;
  font-family: Lato;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assessment-section-buttons img {
  width: 21px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.section-layer-tray {
  z-index: 10 !important;
  padding-top: 16px;
  padding-bottom: 4px;
  z-index: 50;
  min-width: 68px;
  max-width: 690px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.section-layer-tray li {
  display: block !important;
  height: 37px;
  width: -webkit-max-content !important;
  width: max-content !important;
  margin-left: 16px;
  margin-right: 16px;
}

.sec-ques-div {
  position: relative;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: var(--white);
  border: 1px solid var(--light-black);
  color: var(--text-black2);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: Lato;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 17px;
  font-weight: 700;
}

.sec-ques-div hr {
  position: absolute;
  width: 18px;
  height: 1px;
  border-top: 1px solid #666666;
}

.sec-line-one {
  right: 36px;
}

.sec-line-two {
  left: 36px;
}

#current-ques {
  background-color: var(--primary);
  color: var(--white);
}

.assessment-ques {
  position: fixed;
  height: calc(100vh - 215px);
  top: 120px;
  left: 0;
  width: 55%;
  overflow: auto;
  border-right: 1px solid #dddddd;
  padding: 40px !important;
  background-color: var(--white);
}

.assessment-ques .subject {
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  color: #888;
  margin-bottom: 15px;
}

.assessment-ques .time-remaining {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 10px
}

.assessment-ques .time-remaining .question_no {
  display: flex;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  color: #2a2929;
}

.ques_type_prev {
  font-family: Lato;
  font-size: 15px;
  color: var(--text-black);
  border: 1px solid #f1d5d5;
  border-radius: 15px;
  padding-left: 11px;
  padding-right: 11px;
  background-color: #f7f5f0;
}

.assessment-ques p {
  font-family: Lato;
  font-size: 20px;
  letter-spacing: .01em;
  color: #2a2929;
  margin-top: 25px;
  word-wrap: break-word;
}

.assessment-options {
  position: fixed;
  height: calc(100vh - 215px);
  top: 120px;
  right: 0;
  width: 45%;
  overflow-y: auto;
  padding: 19px 40px;
  background-color: var(--white);
}

.option-container {
  position: relative;
  margin: 19px 0;
  /*display: flex;*/
  align-items: center;
  margin-bottom: 35px;
}

.choose-option {
  font-family: Lato;
  font-size: 18px;
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
}

.select-option {
  font-family: Lato;
  line-height: 1.5;
  color: #343434;
  border: 2px solid #74bcd2;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
}

.subjective_sol {
  font-family: Lato;
  line-height: 1.5;
  color: #343434;
  border: 2px solid #eeefef;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  word-break: break-all;
}


.select-option{
  font-family: lato;
  margin-bottom: 10px;
  font-size: 20px;
}

.subjective {
  font-family: lato;
  margin-bottom: 10px;
  font-size: 20px;
}

.select-option {
  border-radius: 8px;
  box-shadow: 0 6px 7px 0 rgba(0,0,0,.06);
  border: 1px solid #dfdfdf;
  background-color: #fff;
  font-size: 16px;
  padding: 20px;
  width: 326px;
  cursor: pointer;
}

.assessment-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 95px;
  border-top: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 80px;
  padding-right: 80px;
  background-color: var(--white);
}

.assessment-bottom button {
  margin-right: 25px;
  border-radius: 4px !important;
  box-shadow: 2px 2px 6px -2px #dddddd;
}

.assessment-bottom button:last-child {
  margin-right: 0px;
}

.clear-response {
  background-color: var(--white);
  color: #444444;
  border: 1px solid #444444;
}

.mark-for-review {
  background-color: var(--white);
  color: #2196f3;
  border: 1px solid #2196f3;
}

.assessment-bottom2 {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 95px;
  border-top: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  background-color: var(--white);
}

.assessment-bottom-left {
  /*width: 73%;*/
}

.assessment-bottom-right {
  display: flex;
  align-items: center;
  /*width: 25%;*/
}

.assessment-bottom span, .assessment-bottom-right span {
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  color: var(--primary);
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-right: 25px;
}

.assessment-bottom span:hover, .assessment-bottom-right span:hover {
  opacity: 0.8;
}

.assessment-bottom span:first-child, .assessment-bottom-right span:first-child {
  /*argin-right: 50px;*/
}

.slide-buttons-xy {
	z-index: 1 !important;
  position: absolute;
  bottom: 13px !important;
	width: 790px !important;
	display: inline-block;
}

.slide-buttons-xy img:first-child {
	float: left;
}

.slide-buttons-xy img:last-child {
	float: right;
}

.slide-button-xy {
	background: none;
	border: none;
	outline: none;
	padding: 0;
	cursor: pointer;
}

.slide-buttons-xy img {
  width: 21px;
  cursor: pointer;
}

#no-questions {
  font-family: Lato;
  font-size: 22px;
  letter-spacing: 0.01em;
  color: var(--text-black);
}

.correct-answer{
	display: flex;
}

.correct-answer >div:first-child{
	font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  color: #368d3a;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
  background-color: #ccfcce;
  padding: 3px 8px;
}

.correct-answer > div img{
	width: 12px;
}

.correct-answer >div:last-child{
	background-color: #73e278;
  font-family: Lato;
  font-size: 12px;
  color: #368d3a;
  padding: 3px 8px;
  border-top-right-radius: 4px;
  font-weight: bold;
  border-bottom-right-radius: 4px;
}

.incorrect-answer{
	display: flex;
}

.incorrect-answer >div:first-child{
	font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  color: #ff6464;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
  background-color: #f7d4d4;
  padding: 3px 8px;
}

.incorrect-answer >div img{
	width: 12px;
}

.incorrect-answer >div:last-child{
	background-color: #ffbebe;
  font-family: Lato;
  font-size: 12px;
  color: #ff6464;
  padding: 3px 8px;
  border-top-right-radius: 4px;
  font-weight: bold;
  border-bottom-right-radius: 4px;
}

.finish-modal-content {
  padding: 0 0 !important;
  width: 450px !important;
	height: 280px !important;
}

.finish-modal {
	width: 100% !important;
	height: 100% !important;
	border-radius: 12px !important;
	border: none !important;
	outline: none !important;
	box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.84);
	overflow: hidden;
}

.finish-div {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: transparent;
}

.finish-div1 {
	width: 100%;
	height: 220px;
	padding-left: 60px;
	padding-right: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: Lato;
	font-size: 19px;
	font-weight: 600;
	color: rgba(0,0,0,0.84);
	text-align: center;
	letter-spacing: 0.02em;
	line-height: 1.3;
	padding-bottom: 10px;
}

.finish-div1 img {
	width: 62px;
	margin-bottom: 18px;
}

.finish-div2 {
	width: 100%;
	height: 60px;
	background-color: #e7e7e7;
}

.finish-div2 button {
	border: none;
  width: 50%;
  border-radius: 0 !important;
	height: 100%;
	outline: none;
	font-family: Lato;
	font-weight: 600;
	letter-spacing: 0.02em;
	font-size: 18px;
	text-align: center;
	cursor: pointer;
}

.finish-div2 button:hover {
	opacity: 0.8;
}

.finish-div2 button:first-child {
	background-color: #ffffff;
	color: rgba(0,0,0,0.74);
  margin-top: 0;
}

.finish-div2 button:last-child {
	background-color: var(--primary);
	color: #ffffff;
  margin-top: 0;
}

.success-modal {
	border-radius: 0 !important;
	margin: 0 0 !important;
	padding: 0 0 !important;
	width: 100vw !important;
	height: 100vh !important;
	border: none !important;
	outline: none !important;
}

.success-div {
	background-color: #ffffff;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 20px;
  padding-bottom: 0;
}

.success-div img {
	width:80px;
	margin-bottom:10px;
}

.success-div span {
	width: 640px;
	text-align: center;
	font-size: 23px;
	font-family: Lato;
	font-weight: 600;
	letter-spacing: 0.02em;
	line-height: 1.4;
}

.marks-status {
	margin-left: 15px;
}

.marks-status > span:first-child{
	background-color:var(--primary-rgba);
  padding: 4px 10px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  color:var(--primary);
}

.marks-status > span:last-child{
	background-color: #ffe0e0;
  padding: 4px 10px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  color: #efa2a2;
}




.assessment-head4 select{
    min-width: 150px;
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 8px 14px;
    border-radius: 6px;
    margin-left: 20px;
}

.heading-part1 {
  min-width: 45% !important;
}

.img-prev-statement{
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  padding-left: 8px;
  cursor: pointer;
  background-color: var(--secondary-rgba);
  border-radius: 6px;
  display: flex;
  /* align-items: center;*/
}

.img-prev-statement div:nth-child(1){
  color: red;
  font-size: 20px;
  margin-right: 5px;
}

.img-prev-statement img{
    max-height: 50px;
    max-width: 100px;
}

.img-prev-statement span {
  color: var(--primary);
  font-size: 21px;
  margin-right: 16px !important;
  margin-left: 0 !important;
  font-family: Lato;
  align-items: center;
  display:flex
}
.header-view{
  display: flex;
  text-align: center;
  height: 37px;
  background-color:var(--primary);
  padding: 8px;
  font-family: lato;
  border-radius: 20px;
  color: var(--white);
}

.header-view div{
  width: 20%;
}
.row-view div{
  width: 20%;
}
.row-view{
  display: flex;
  text-align: center;
  height: 60px;
  font-family: lato;
  margin-bottom: 4px;
  padding: 8px;
  border-bottom: 1px solid #dad2d2;
}


.row-view div textarea{
  height: 50px;
  font-family: lato;
  border: 1px solid #f5efe5;
  overflow: hidden;
  cursor: pointer;
}

.row-view div:nth-child(2):hover .tooltiptext{
  visibility: visible;
}
.row-view div input{
  width: 100px;
  border: 1px solid #f5efe5;;
  height: 25px;
}

.row-view div:nth-child(4) span{
  margin-left: 6px;
}
.row-view div span img{
  width: 17px;
  cursor: pointer;
  margin-left: 5px;
}

/*.row-view div:nth-child(5){
  width: 67px;
  color: white;
  border: 1px solid #e8a2a2;
  border-radius: 4px;
  background-color: #66b0ef;
  font-weight: 900;
  cursor: pointer;
  height: 25px;
}*/
.row-div{
  overflow-y: scroll;
  height: 265px;
  border-bottom: 1px solid #ecdddd;
}
.tooltiptext{
  visibility: hidden;
  width: 146px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 11px;
  position: absolute;
  z-index: 1;
}

.addMarks{
  float: right;
  margin: 20px;
}

.option-image{
  cursor: pointer;
  width: 220px;
  height: 128px;
  display: block;
}
.option-image img{
  border-radius: 3%;
  width: 220px;
  height: 128px;
}

.option-image span{
  font-family: lato;
  font-size: 12px;
  color:var(--primary);
}

img1:hover { /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
}

.pagination {
  display: inline-block;
}

.pagination a {
  color:var(--black);
  float: left;
  padding: 6px 22px;
  text-decoration: none;
  margin-top: 10px;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.view-image img{
  width: 200px;
  height: 200px;
  margin-top: 20px;
  box-shadow: 1px 1px 1px 2px #f1dee9;
  cursor: pointer;
}

.input_field_file {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
}

.input_field_file input {
  border: none;
  width: 234px;
  height: 30px;
}

.change-password-list-content{
  top: 200px !important;
  width: 100% !important;
  margin: 0px !important;
}

.change-password-list-content{
  padding: 0px !important;
  height: auto ! important;
}

.asm-setting-div-popup{
  width: 100%;
  margin-top: 30px;
  background-color: var(--secondary-rgba);
  padding: 12px 20px;
  border-radius: 6px;
  transition: .3s all;
}

.full-answer-content-content{
  border-radius: 8px !important;
  width: 40% !important;
  height: 200px;
  font-family: lato;
  overflow-y: scroll;
  word-break: break-all;
}

.assessment-bottom-on{
  left: 0;
  bottom: 0;
  width: 100%;
  height: 95px;
  border-top: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--white);
  padding: 17px;
}

.assessment-bottom-on .assessment-bottom-left{
  width:100%;
}
.assessment-bottom-on .assessment-bottom-right {
  width:0px;
}

.toggleImage{
  position: fixed;
  bottom: 34px;
  right: 650px;
  background-color: rgb(218 144 144);
  /* width: 100px; */
  height: 100px;
  border-radius: 100%;
  padding: 7px;
  cursor: pointer;
}

.toggleImage img{
  width: 68px;
  height: 29px;
}

.zoom{
  position: absolute;
  margin-left: 5px;
  cursor: pointer;
}
.zoom img {
  width:20px;
}

.zoom-image img{
  width:100%;
  height:400px;
}

.zoom-image-on-content{
  padding: 0px !important;
  border-radius: 0px !important;
  width:40% !important;
}

.download-file{
  margin: 20px;
}


.download-file a ,.download-file-attempt a,.download-file-attempt-question a {
  text-decoration: none;
  display: flex;
}

.download-file img {
  height: 20px;
  margin-right: 6px;
}

.download-file-attempt img,.download-file-attempt-question img {
  height: 20px;
  margin-right: 6px;
  box-shadow: 0px 0px 0px 0px white;
  width: 20px;
}

.right button{
  font-family: Lato;
  font-size: 13px;
  font-weight: 600;
  color: var(--white);
  border: 1px solid var(--primary);
  background-color: var(--primary);
  border: none;
  padding: 3px 5px 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.download-file-attempt-question{
  margin-top:12px;
}

.ques-option img{
  height: 50px;
  width: 80px;
}

.mark-for-review-icon{
  display: flex;
}

.mark-for-review-icon div{
  margin-right:14px;
  cursor: pointer;
}

.mark-for-review-icon img{
  height:20px;
}

.your-answer{
  position: absolute;
  top: -13px;
  right: 393px;
  padding: 4px;
  /* border: 1px solid #bfbfb3; */
  background: #dad6d6;
  font-size: 12px;
  font-family: lato;
  border-radius: 4px;
  color: black;
  width: 90px;
  text-align: center;
}

.correct-option{
  border: 1px solid var(--primary);
}

.incorrect-option{
  border: 1px solid rgb(230, 12, 28);
}

.marksVerification{
  font-family: lato;
}

.question-table{
  margin-top:10px;
  overflow-y: scroll;
  height: 221px;
}

.firstChild{
  display: flex;
  justify-content: space-between;
  font-weight: 700
}

.firstChild div{
  width:20%;
}

.secondChild{
  display: flex;
  justify-content: space-between;
  padding-top:14px;
  padding-bottom:14px;
}

.secondChild div{
  width:20%;
}

.secondChild input{
  height: 28px;
  width: 80%;
  border:1px solid var(--primary)
}

.marks-verification-content{
  width:75% !important;
}
.footer-bottom{
  position: absolute;
  right: 0;
  padding-right: 30px;
}

.footer-bottom.newdesign{position: static; text-align: right; padding-right: 0;}

.footer-bottom button{
  margin: 8px
}

hr{
  background-color: #f3eeee;
  height: 2px;
  border: 0px;
}

.question-mark{
  margin-left: 5px;
  font-size: 17px;
  color: white;
  border: 1px solid aliceblue;
  background: #60c723;
  padding: 2px;
}

.ques-save-noedit{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
}

.ques-save-noedit button:last-child {
    margin-left: 20px;
}

.popup-content4-content {
  height: auto !important;
}

.popup-actions-onedit{
  text-decoration: none;
  width: 100%;
  /* bottom: 200px; */
  display: flex;
  justify-content: flex-end;
  margin-top: 34px;
}

.option-area{
  background: white !important;
}

.input-box {
  background: white !important;
}
.input-area{
  background: white !important;
}

.popup-content .close, .popup-content2-content .close{
  top: 8px !important;
}

.add-question-bank{
  text-align: center;
  font-size: 18px;
  font-family: lato;
}

.selected-questions{
  overflow-y: scroll;
  margin-top: 18px;
  height: 200px;
}

.selected-questions ul{
  padding: 0px;
}

.selected-questions ul li{
  height: 32px;
  margin-top: 10px;
  /* text-align: center; */
  font-size: 17px;
  font-family: lato;
  background-color: #d8e8e8;
  border-radius: 10px;
  padding: 7px;
}

.selected-questions-button{
    position: fixed;
    right: 384px;
    
}

.add-from-question-bank{
  margin: 10px;
  font-size: 18px;
  font-family: lato;
  color: var(--primary);
  font-weight: 600;
}



@media only screen and (max-width: 1000px) { 
  .assessment-head{flex-direction: column; display: flex;} 
  .assessment-head .assessment-head1{order:1; width:140px; }
  .assessment-head .assessment-head2{ order:3;}
  .assessment-head .assessment-head4{order:2}
  .option-container{flex-direction: column; display: flex;}
  .option-container .select-option{width:100%; margin-top:10px}
  .option-container .option-image{width: auto; display:flex; flex-direction:column;}
  .option-container .option-image img{width: auto; height:65px}
  .section-layer-tray{margin-top:10px}
  .assessment-section-buttons{margin-top:15px;}
  .download-file-attempt{word-break: break-word; width:100%;}
  .questionImage img{width:auto; max-width: 100%;}
  
}

@media only screen and (max-width: 600px) {  
  .option-container .option-image{width: 100%;display:flex; flex-direction:column;}
  .option-container .option-image img{width: 100%; max-width: 150px; margin:0 auto; height:auto}
  .assessment-head{position: static;display: flex; flex-direction:column; height:auto; padding:15px;}
  .assessment-head .assessment-head1{width:auto; order:1; align-items:center; overflow-y:visible; left:0; position: static;}
  .assessment-head .assessment-head2{width:100%; order:2; margin:0; overflow-y:visible;}
  .assessment-head4 button:last-child{font-size:13px; margin-left:0}
  .assessment-head .assessment-head4{right: 10px; position: static; order:3; margin-top: 15px;}
  .assessment-head .assessment-head4 a{margin-right:15px;}
  .assessment-main{display: flex; width: 100vw; flex-direction: column; margin-top:0; height:auto; overflow-y: auto;}
  .assessment-main .assessment-ques{width:100%; position: relative; height:auto; min-height:150px; padding: 15px!important; top:0; margin-bottom:0px}
  .assessment-main .assessment-options{width:100%; padding:15px; border-top:1px solid #ddd; position: relative; height:auto; top:0}
  .assessment-main .assessment-options textarea{bordeR:1px solid #ddd;}
  .assessment-main .assessment-options .subjective_sol{min-height:100px;}

  .sch-test{display:flex; flex-direction: column; height:auto; position: relative;}
  .sch-test .lecture-list-x2{height: calc(100vh - 460px);}
  .sch-test .sch-right{width: 100%; padding:10px}
  .sch-test .sch-right span{margin-top:0}
  .sch-test .sch-right a span{margin-top:10px}
  .sch-test .sch-left{width: 100%; justify-content: flex-start; align-items: center; padding-left:5px; flex-direction: row; border-right:0px none; border-left: 3px solid var(--primary);}
  .sch-test .sch-left span{margin-right:5px; margin-bottom: 0;}
  .sch-test .sch-options{position: relative; width: 100%; align-items:flex-end; justify-content:flex-end; bottom:0; right:0}

  .finish-modal-content{width:300px!important;}
  .assessment-div{align-items: flex-start; flex-direction:column; justify-content: flex-start; width: 100vw;}
  .assessment-div #no-questions{justify-content:center; display: flex; align-items:center; width:100vw; height:100vh;}
  .assessment-bottom{position: relative; justify-content: center; height:auto; align-items:center; padding:15px;}
  .assessment-bottom2{position: relative; flex-direction:column; display:flex; justify-content: center; height:auto; align-items:center; padding:15px;}
  .assessment-bottom2 .assessment-bottom-left{display: flex; width:100%; flex-direction:column}
  .assessment-bottom2 .assessment-bottom-left .dpp-details{width:100%; display: flex; flex-direction:column}
  .assessment-bottom2 .assessment-bottom-left .dpp-details .dpp-difficulty{margin-right:0; margin-bottom:0px;}
  .assessment-bottom2 .assessment-bottom-left .dpp-details .dpp_average{margin-right:0; padding:0; margin-top:15px;}
  .assessment-bottom2 .assessment-bottom-right{margin-top:15px;}

  .asm-container{height:auto;}
  .asm-container .base-settings{flex-direction:column;}
  .asm-container .asm-title{flex-direction:column;}
  .asm-container .asm-title input{width:100%; margin-bottom:10px;}
  .asm-container .base-settings div:nth-child(2){margin-left:0}

}
.edit-div-2 select{
	font-family: Lato;
    width: 275px;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 12px 16px;
    border-radius: 4px;
    margin-right: 20px;
}
#no-data-admin{
	/* text-align: center; */
	width: 100%;
	display: flex;
	/* align-items: center; */
	justify-content: center;
	font-family: Lato;
	font-weight: 700;
	font-size: 25px;
	/* padding-top: 150px; */
	color: var(--text-black2);
}

.createGroupDialog .createGroupDialogContainer .formWrapper .formGroup .formBlock .connectStudentsDownloadSampleFileLink i{margin: 0; font-style: normal; 	white-space: nowrap;}

.connectStudentsDownloadSampleFileLink{
	display: flex; align-items: center;
	grid-gap:5px;
	gap:5px;
	margin-left: 20px;
	color:var(--text-black2)

}
.pagination-button {
	background-color: #f0f0f0; /* Gray background for unselected pages */
	color: #333; /* Darker text color */
	border: 1px solid #ddd;
	padding: 5px 10px;
	margin: 0 5px;
	cursor: pointer;
	border-radius: 4px;
  }
  
  .pagination-button.active {
	background-color: #2DA77D; /* Blue background for the selected page */
	color: white; /* White text for the selected page */
	border-color:#2DA77D;
  }
  
  .pagination-button.disabled {
	background-color: #e9ecef; /* Light gray background for disabled buttons */
	color: #6c757d; /* Medium gray text color for disabled state */
	cursor: not-allowed;
  }
  

.connectStudentsDownloadSampleFileLink img{
	max-width: 24px;
}

.breakOutRoomsLink{display: flex; align-items: center; grid-gap:5px; gap:5px; color:var(--primary)}
.breakOutRoomsLink img{max-width:20px; margin-top: 0;}

.marginBottomZero h1{margin-bottom:0;}



.createGroupWrapper{display: flex; flex-direction: column; width: 100%; font-family: Lato;}
.createGroupWrapper .createGroupContainer{display: flex;  width: 100%; flex-wrap: wrap;}
.createGroupWrapper .createGroupContainer ol{display: flex;margin:20px 0; padding: 0; grid-gap:20px; gap:20px; flex-wrap: wrap; width: 100%;}
.createGroupWrapper .createGroupContainer ol li{display: flex; grid-gap:10px; gap:10px; flex-direction: column; max-width: 400px;  width: 100%;}
.createGroupWrapper .createGroupContainer ol li .cardInfo{display: flex; width: 100%; padding:0;}
.createGroupWrapper .createGroupContainer ol li .cardInfo label{font-weight: bold; margin:0; padding: 0;}
.createGroupWrapper .createGroupContainer ol li .actions{display: flex; width: 100%; justify-content:center; align-items: center; grid-gap:20px; gap:20px}
.createGroupWrapper .createGroupContainer ol li .actions a{color:var(--primary);}

.createGroupDialog.open{display: flex;}
.createGroupDialog{display: flex; display: none; flex-direction: column; font-family: Lato; z-index: 2200005; width: 100%; align-items: center; justify-content: center; position: fixed; top:0; right:0; bottom:0; left:0; background-color: rgba(0,0,0,0.6);}
.createGroupDialog .createGroupDialogContainer{display: flex;  width: 100%; flex-direction: column; background-color: #fff; max-width: 700px; padding: 15px; border-radius: 15px;}
.createGroupDialog .createGroupDialogContainer h6{display: flex; margin:0px 0 10px; grid-gap:5px; gap:5px; font-size: 15px;}
.createGroupDialog .createGroupDialogContainer h6 span{color: #6d6d6d;}
.createGroupDialog .createGroupDialogContainer h5{margin: 0 0 15px; padding:0; border-bottom:1px solid #dbdbdb; font-size: 15px; align-items: center; justify-content: space-between; display: flex;  padding-bottom:5px; margin-bottom: 15px;}
.createGroupDialog .createGroupDialogContainer h3{border-bottom:1px solid #dbdbdb; align-items: center; justify-content: space-between; display: flex;  padding-bottom:5px; margin-bottom:5px;}
.createGroupDialog .createGroupDialogContainer h3 span{cursor: pointer;}
.createGroupDialog .createGroupDialogContainer .formWrapper{display: flex; flex-direction: column; margin:0px 0; padding: 0; width: 100%;}
.createGroupDialog .createGroupDialogContainer .formWrapper .formGroup{display: flex; max-height: 300px; overflow-y: auto; grid-gap:20px; gap:20px; width: 100%; flex-direction: column;}
.createGroupDialog .createGroupDialogContainer .formWrapper .formGroup .formBlock.noStyle{border:0px none; padding: 0;}
.createGroupDialog .createGroupDialogContainer .formWrapper .formGroup .formBlock{display: flex; grid-gap:20px; gap:20px; border-radius: 5px; padding:10px; border:1px solid #dbdbdb; width: 100%;}
.createGroupDialog .createGroupDialogContainer .formWrapper label div{display: flex; align-items: center; grid-gap:5px; gap:5px;}
.createGroupDialog .createGroupDialogContainer .formWrapper label div .required{color: #ff0000;}
.createGroupDialog .createGroupDialogContainer .formWrapper label{margin: 0 0 0px; display: flex; grid-gap:5px; gap:5px; flex-direction: column; width: 100%; padding: 0; font-size: 14px; font-family: Lato;}
.createGroupDialog .createGroupDialogContainer .formWrapper label:nth-child(2){max-width: 150px;}
.createGroupDialog .createGroupDialogContainer .formWrapper .formGroup .formBlock i{display: flex; align-items: center; cursor: pointer; margin-top: 15px;}
.createGroupDialog .createGroupDialogContainer .actions{display: flex;  border-top:1px solid #dbdbdb; padding-top: 10px; margin-top:20px; width: 100%; justify-content:center; align-items: center;}
.createGroupDialog .createGroupDialogContainer textarea{border:1px solid #dbdbdb;min-height: 70px; max-height: 70px;  margin:0; border-radius: 5px; resize: none; padding:5px 10px; font-size: 14px; font-family: Lato;}
.createGroupDialog .createGroupDialogContainer input[type="text"]{border:1px solid #dbdbdb; margin:0; padding:5px 10px; font-size: 14px; font-family: Lato;}
.createGroupDialog .createGroupDialogContainer input[type="file"]{border:1px solid #dbdbdb; margin:0; padding:5px 10px; font-size: 14px; font-family: Lato;}
.createGroupDialog .createGroupDialogContainer input[type="number"]{border:1px solid #dbdbdb; margin:0; max-width: 150px; padding:5px 10px; font-size: 14px; font-family: Lato;}
.createGroupDialog .createGroupDialogContainer .addMoreLink{display: flex;font-size: 14px; font-family: Lato; justify-content:space-between; margin-top: 10px; align-items: center;}
.createGroupDialog .createGroupDialogContainer .addMoreLink span:nth-child(1){display: flex; cursor: pointer; grid-gap:5px; gap:5px; align-items: center;}
.createGroupDialog .createGroupDialogContainer .addMoreLink span:nth-child(2){display: flex; cursor: pointer; grid-gap:5px; gap:5px; align-items: center;  color:var(--primary); }
.createGroupDialog .createGroupDialogContainer .errorMessage{text-align: center; color:#ff0000; font-size: 14px; font-family: Lato; }
.createGroupDialog .createGroupDialogContainer input[type="number"]::-webkit-outer-spin-button,
.createGroupDialog .createGroupDialogContainer input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; /* WebKit/Blink */
  margin: 0; /* Removes the default margin */
}

.createGroupDialog .createGroupDialogContainer .actions .LoadingMessage{width: auto;}
.createGroupDialog .createGroupDialogContainer .actions .LoadingMessage img{max-width: 50px;}
.createGroupDialog .createGroupDialogContainer .uploadErrorMessage { font-size: 14px; font-family: Lato; color:#ff0000}



.connectStudentsFileClick{border:2px dashed var(--primary); margin-top: 5px; width: 100%; max-width: 130px; color:var(--primary); padding: 10px; display: flex; align-items: center; grid-gap:5px; gap:5px}
.createGroupDialog .createGroupDialogContainer .formWrapper .formGroup .formBlock .connectStudentsFileClick i{margin-top: 0;}

.createGroupDialog .createGroupDialogContainer h3.marginBottom20{margin-bottom: 20px;}
.blog-list-table table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1em;
	font-size: 15px; 
	font-family: Lato;
 }

 .blog-list-table .actionsLink{display: flex; grid-gap:20px; gap:20px;}
 .blog-list-table .actionsLink a{color: var(--primary); display: flex;  grid-gap:5px;  gap:5px; font-size: 14px; }

.blog-list-table .PreviewLink{display: flex; align-items: center; grid-gap:5px; gap:5px; color: var(--primary); cursor: pointer; text-decoration: none; font-size: 14px; font-family: Lato; }

.blog-list-table th, .blog-list-table td {
    padding: 12px 15px;
    text-align: left;
}

.heading + h6{font-family: Lato; font-weight: normal; font-size: 14px; margin: 10px 0 0;}

.heading.marginBottomZero  #trace-back img{margin-top: 0;}

.textAlignCenter{text-align: center;}
.blog-list-table td.textAlignCenter{text-align: center;}
.blog-list-table th.textAlignCenter{text-align: center;}
.totalStudents{ font-family: Lato; font-weight: normal; font-size: 14px; margin-top: 20px;}

.LoadingMessage{display: flex; align-items: center; width: 100%; justify-content: center;}
.LoadingMessage img{max-width: 100px;}

.blog-list-table tr {
    border-bottom: 1px solid #dddddd;
    
}

.blog-list-table tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.blog-list-table .noContentMessage{text-align: center;}

.noDataFound{text-align: center; font-family: Lato; font-size: 14px;}

.actions{display: flex; align-items: center; justify-content: center;}

.thanksGroupDialog.open{display: flex;}
.thanksGroupDialog{display: flex; display: none; flex-direction: column; font-family: Lato; z-index: 2200005; width: 100%; align-items: center; justify-content: center; position: fixed; top:0; right:0; bottom:0; left:0; background-color: rgba(0,0,0,0.6);}
.thanksGroupDialog .thanksGroupContainer{display: flex;  width: 100%; flex-direction: column; background-color: #fff; max-width: 600px; padding: 15px; border-radius: 15px;}
.thanksGroupDialog .thanksGroupContainer span{cursor: pointer; text-align: right;}
.thanksGroupDialog .thanksGroupContainer h6{color:green; font-size: 35px; margin:0; text-align: center;}
.thanksGroupDialog .thanksGroupContainer .formWrapper{display: flex; align-items: center; justify-content: center; flex-direction: column; margin:0px 0; padding: 0; width: 100%;}
.thanksGroupDialog .thanksGroupContainer .fa-thumbs-up{color:green; font-size: 35px; margin:0; text-align: center;}

.sc-dmlqKv.hHSTiF{height: calc(100% - 150px);}

.blog-list-table .downloadReportBtn{display: flex; justify-content: flex-end; align-items: center; margin-bottom: 20px;}

.confirmationGroupDialog.open{display: flex;}
.confirmationGroupDialog{display: flex; display: none; flex-direction: column; font-family: Lato; z-index: 2200005; width: 100%; align-items: center; justify-content: center; position: fixed; top:0; right:0; bottom:0; left:0; background-color: rgba(0,0,0,0.6);}
.confirmationGroupDialog .confirmationGroupContainer{display: flex;  width: 100%; flex-direction: column; background-color: #fff; max-width: 450px; padding: 15px; border-radius: 15px;}
.confirmationGroupDialog .confirmationGroupContainer span{cursor: pointer; text-align: right;}
.confirmationGroupDialog .confirmationGroupContainer h6{color:var(--text-black); border-bottom:1px solid #e9e8e8; font-size:18px; padding-bottom: 10px;  margin:0;}
.confirmationGroupDialog .confirmationGroupContainer .formWrapper{display: flex; align-items: center; justify-content: center; flex-direction: column; margin:0px 0; padding: 0; width: 100%;}
.confirmationGroupDialog .confirmationGroupContainer .actions{display: flex; align-items: center; grid-gap:15px; gap:15px; padding-top: 10px; border-top:1px solid #e9e8e8; }
.confirmationGroupDialog .confirmationGroupContainer .formWrapper p{text-align: center;}
.confirmationGroupDialog .confirmationGroupContainer .actions a{color:var(--text-black2)}


.openDiscussionDialog.open{display: flex;}
.openDiscussionDialog{display: flex; display: none; flex-direction: column; font-family: Lato; z-index: 2200005; width: 100%; align-items: center; justify-content: center; position: fixed; top:0; right:0; bottom:0; left:0; background-color: rgba(0,0,0,0.6);}
.openDiscussionDialog .openDiscussionContainer{display: flex;  width: 100%; flex-direction: column; background-color: #fff; max-width: 700px; padding: 15px; border-radius: 15px;}
.openDiscussionDialog .openDiscussionContainer h6{display: flex; margin:0px 0 10px; grid-gap:5px; gap:5px; font-size: 15px;}
.openDiscussionDialog .openDiscussionContainer h6 span{color: #6d6d6d;}
.openDiscussionDialog .openDiscussionContainer h5{margin: 0 0 15px; padding:0; border-bottom:1px solid #dbdbdb; font-size: 15px; align-items: center; justify-content: space-between; display: flex;  padding-bottom:5px; margin-bottom: 15px;}
.openDiscussionDialog .openDiscussionContainer h3{border-bottom:1px solid #dbdbdb; align-items: center; justify-content: space-between; display: flex;  padding-bottom:5px; margin-bottom:5px;}
.openDiscussionDialog .openDiscussionContainer h3 span{cursor: pointer;}



.spaceBetween{justify-content: space-between; display: flex; flex-direction: row; align-items: center;}
.spaceBetween div{flex-direction: column; display: flex;}
.spaceBetween .mapUser{display: flex; align-items: center; cursor: pointer; font-size: 15px; flex-direction: row;}
.spaceBetween .mapUser img{max-width: 20px; margin-right: 5px;}
.popup-data2{
	padding-top: 22px !important;
    padding-bottom: 0px !important;
}

.grid-item .grid-up-v1{
	flex-direction: unset !important;
}
.popup-data.newdesign{height:auto}
.popup-data.newdesign .popup-inputs{margin-bottom: 20px;}
.popup-data.newdesign .popup-inputs:last-child{margin-bottom: 0;}
.popup-data.newdesign .popup-inputs input{width:100%; margin-left: 15px;}
.popup-data.newdesign .popup-inputs select{width:100%; margin-left: 15px;}
.popup-data.newdesign .popup-inputs select:first-child{width:100%; margin-left:0px;}
.popup-data.newdesign .popup-inputs input:first-child{margin-left: 0px;}
.popup-data.newdesign .classtags{width:100%}

.addBannerClassWrapper.width{width: 68%;}
.addBannerClassWrapper.width + div{width: 32%; display: flex; justify-content: flex-end; align-items: center; margin-left: 20px;}
.addBannerClassWrapper.width + div .widget-button{margin-left: 20px;}
.popup-content.banner-type-content .col-75 .addBannerClassWrapper .multi-select{width:100%!important; margin-left: 0; font-family: Lato; font-weight: normal;}
.addBannerClassWrapper .multi-select .clear-selected-button{display: none;}
.addBannerClassWrapper .multi-select .dropdown-heading-value{font-family: Lato; font-size: 14px; font-weight: normal;}
.addBannerClassWrapper .multi-select .dropdown-heading-value span{font-family: Lato; font-size: 14px; font-weight: normal;}
.addBannerClassWrapper .dropdown-content label{display:block; padding:0;}
.addBannerClassWrapper .multi-select .dropdown-content .item-renderer{display:flex; font-family: Lato; font-size: 14px;}
.addBannerClassWrapper .multi-select .dropdown-content .item-renderer input[type="text"]{margin:0 10px 0 0; width:auto;}
.addBannerClassWrapper .multi-select .dropdown-content .item-renderer input[type="checkbox"]{margin:0 10px 0 0; width:auto;}
.addBannerClassWrapper .multi-select .dropdown-content .panel-content{display: flex; flex-direction: column; padding:10px; width:100%; font-family: Lato; font-size: 14px; font-weight: normal;}
.addBannerClassWrapper .multi-select .dropdown-content .panel-content .select-panel{display: flex; padding:10px; flex-direction: column; width:100%; font-family: Lato; font-size: 14px; font-weight: normal;}
.addBannerClassWrapper .multi-select .dropdown-content .panel-content .select-panel .select-item{width:100%;margin-bottom: 10px;}
.addBannerClassWrapper .multi-select .dropdown-content .panel-content span{text-align: left; display: flex; font-family: Lato; font-size: 14px; font-weight: normal;}
.addBannerClassWrapper .multi-select .dropdown-content .panel-content label{margin:0 0px 0 0; padding: 10px; display: flex; align-items: flex-start; font-family: Lato; font-size: 14px; font-weight: normal;}
.addBannerClassWrapper .multi-select .dropdown-content .panel-content ul{display: flex; flex-direction: column; width:100%}
.addBannerClassWrapper .multi-select .dropdown-content .panel-content ul li{display: flex; width:100%; margin:0;}
.addBannerClassWrapper .multi-select .dropdown-content .panel-content ul li label{display: flex; width:100%}



.addBannerClassWrapper{display: flex; width: 100%; margin-top: 10px; }
.addBannerClassWrapper .block{display: flex; width:100%; max-width: 50%; flex-direction: column; align-items: flex-start; margin:0 10px;}
.addBannerClassWrapper .block:last-child{margin-left: 10px;}
.addBannerClassWrapper .block label{display: flex; margin:0 0 5px; padding:0;}
.addBannerClassWrapper .block .css-b62m3t-container{width:100%}
.addBannerClassWrapper .block .multi-select{width:100%; margin-left: 0; font-family: Lato; font-weight: normal;}
.addBannerClassWrapper .block .multi-select .clear-selected-button{display: none;}
.addBannerClassWrapper .block .multi-select .dropdown-heading-value{font-family: Lato; font-size: 14px; font-weight: normal;}
.addBannerClassWrapper .block .multi-select .dropdown-heading-value span{font-family: Lato; font-size: 14px; font-weight: normal;}
.addBannerClassWrapper .block .dropdown-content label{display:block; padding:0;}
.addBannerClassWrapper .block .multi-select .dropdown-content .item-renderer{display:flex; font-family: Lato; font-size: 14px;}
.addBannerClassWrapper .block .multi-select .dropdown-content .item-renderer input[type="text"]{margin:0 10px 0 0; width:auto;}
.addBannerClassWrapper .block .multi-select .dropdown-content .item-renderer input[type="checkbox"]{margin:0 10px 0 0; width:auto;}
.addBannerClassWrapper .block .multi-select .dropdown-content .panel-content{display: flex; flex-direction: column; padding:10px; width:100%; font-family: Lato; font-size: 14px; font-weight: normal;}
.addBannerClassWrapper .block .multi-select .dropdown-content .panel-content .select-panel{display: flex; padding:10px; flex-direction: column; width:100%; font-family: Lato; font-size: 14px; font-weight: normal;}
.addBannerClassWrapper .block .multi-select .dropdown-content .panel-content .select-panel .select-item{width:100%;margin-bottom: 10px;}
.addBannerClassWrapper .block .multi-select .dropdown-content .panel-content span{text-align: left; display: flex; font-family: Lato; font-size: 14px; font-weight: normal;}
.addBannerClassWrapper .block .multi-select .dropdown-content .panel-content label{margin:0 0px 0 0; padding: 10px; display: flex; align-items: flex-start; font-family: Lato; font-size: 14px; font-weight: normal;}
.addBannerClassWrapper .block .multi-select .dropdown-content .panel-content ul{display: flex; flex-direction: column; width:100%}
.addBannerClassWrapper .block .multi-select .dropdown-content .panel-content ul li{display: flex; width:100%}
.addBannerClassWrapper .block .multi-select .dropdown-content .panel-content ul li label{display: flex; width:100%}
.addBannerClassWrapper .block .css-1s2u09g-control{font-family: Lato; font-size: 16px; background-color: var(--secondary-rgba);
    border: 1px solid var(--primary); color: var(--text-black); padding:0px; border-radius: 4px; width: 100%;}
.addBannerClassWrapper .block .css-1s2u09g-control:focus{font-family: Lato; font-size: 16px; background-color: var(--secondary-rgba);
		border: 1px solid var(--primary); color: var(--text-black); box-shadow: 0px 0px 0px 0px!important; padding:0px; border-radius: 4px; width: 100%;}	
.addBannerClassWrapper .block .css-1s2u09g-control:hover{box-shadow: 0px 0px 0px 0px!important; }

.add-row.newDesign .newDesign {display: flex; width: 100%; flex-wrap: wrap;}
.add-row.newDesign .newDesign .row{display: flex; flex-direction: column; margin:10px  10px 0; max-width: 46%; width:100%}
.add-row.newDesign .newDesign .row label{margin:0 0 5px; width:auto;}
.add-row.newDesign .newDesign .row .col-25{width: auto; float:none; margin-top: 0;}
.add-row.newDesign .newDesign .row .col-75{width: auto; float:none;}

.batchesTray div{width: -webkit-max-content;width: max-content;  padding: 7px 15px 8px 15px; border-radius: 50px; border: 1px solid var(--primary); background-color: var(--primary-rgba); color: var(--text-black);
    margin-right: 14px; margin-bottom: 10px;  font-family: Lato;  font-weight: 700; font-size: 14px;}
.batchesTray span {color: var(--primary);cursor: pointer;margin-left: 10px;	}

.setMaxWidth{max-width: 70%;}

.heading.directionChange{display: flex; flex-direction: column;  font-family: Lato; align-items: flex-start;}

.electiveStudentsWrapper{display: flex; flex-direction: column; width: 100%; margin-top: 30px;}
.electiveStudentsWrapper h3{text-align: center; margin:0 0 20px}
.electiveStudentsWrapper .filtersWrapper{display: flex; align-items: center; grid-gap:20px; gap:20px; justify-content: center; width: 100%;}
.electiveStudentsWrapper .filtersWrapper .block{display: flex; flex-direction: column;  font-family: Lato;}
.electiveStudentsWrapper .filtersWrapper label{margin:0 0 5px; padding: 0;}
.electiveStudentsWrapper .filtersWrapper select{max-width: 250px; min-width: 250px; width: 100%;}
.edit-div-tray {
  margin-top: 20px;
  display: flex;
  width: 50%;
  flex-wrap: wrap;
}

.edit-div-tray div {
  width: -webkit-max-content;
  width: max-content;
  padding: 7px 15px 8px 15px;
  border-radius: 50px;
  border: 1px solid var(--primary);
  background-color: var(--primary-rgba);
  color: var(--text-black);
  margin-right: 14px;
  margin-bottom: 10px;
  font-family: Lato;
  font-weight: 700;
  font-size: 14px;
}

.edit-div-tray span {
  color: var(--primary);
  cursor: pointer;
  margin-left: 10px;
}

.grid-up .uploadDocumentLink{display: flex; align-items: center; margin-top: 0; margin-right: 10px;}
.grid-up .uploadDocumentLink img{max-width: 20px; margin-right: 5px;}
.grid-up #send-invite{margin-top: 0;}
.grid-up #send-invite:hover{background-color: transparent; color: #489198;}
.main-part.hide{display: none;}


.uploadDocumentWrapper.open{display: flex;}
.uploadDocumentWrapper{display: flex; display: none; flex-direction: column; overflow-y: auto; z-index: 1;width: 100%; padding-left: 5px; padding-right: 5px; height: calc(100% - 73px ) !important; background-color: #fff; width: 100%; font-family: Lato; margin-left:0px;}
.uploadDocumentWrapper .uploadDocumentBlock{display: flex; flex-direction: column; width:100%;}
.uploadDocumentWrapper .uploadDocumentBlock .title{ display: flex; align-items: center; justify-content: space-between; font-size: 22px; color: #000; font-weight: 700; border-bottom:1px solid #ddd; margin-bottom: 15px; padding-bottom: 10px;}
.uploadDocumentWrapper .uploadDocumentBlock .title .uploadActions{display: flex; bordeR:0px none; margin-top:0;padding-top: 0; justify-content: flex-end; align-items: center;}
.uploadDocumentWrapper .uploadDocumentBlock .title .uploadActions span{ cursor: pointer; background-color:var(--primary); font-weight: 700; color:#fff; font-size: 18px; padding:6px 20px 8px 20px; border-radius:40px;}
.uploadDocumentWrapper .uploadDocumentBlock .uploadDocumentPreview{display: flex; width: 100%; max-width:250px;}
.uploadDocumentWrapper .uploadDocumentBlock .uploadDocumentPreview img{max-width: 100%;}
.uploadDocumentWrapper .uploadDocumentLeft{display: flex; flex-direction: column; width:100%; flex:1 1;}
.uploadDocumentWrapper .uploadDocumentLeft ol{display: flex; width: 100%;  margin: 0; padding:0; flex-wrap: wrap;}
.uploadDocumentWrapper .uploadDocumentLeft ol li{display: flex;  margin:10px; background-color: var(--secondary-rgba); box-shadow: 1px 1px 6px 0px #eeeeee; max-width: 350px; flex-direction: column; border-radius:15px; align-items: flex-start; width: 100%; padding:20px;}
.uploadDocumentWrapper .uploadDocumentLeft ol li.noDataFound{box-shadow: 0px 0px 0px 0px; align-items: center; text-align: center; width:100%; max-width:none; padding:0;}
.uploadDocumentWrapper .uploadDocumentLeft ol li.noDataFound .noDataFound{text-align: center; padding:20px;}
.uploadDocumentWrapper .uploadDocumentLeft ol li .documentThumb{width:100%; min-height: 80px; max-width: 350px; display: flex; margin-right: 15px;}
.uploadDocumentWrapper .uploadDocumentLeft ol li .documentType{width:100%; font-weight: 700; margin-bottom: 10px; text-transform: capitalize; display: flex;}
.uploadDocumentWrapper .uploadDocumentLeft ol li .documentType span{margin-left: 15px; font-weight: 700;}
.uploadDocumentWrapper .uploadDocumentLeft ol li .documentActions{width:100%; display: flex; justify-content: flex-end; align-items: center; margin-top: 25px; border-top:1px solid #ddd; padding-top: 10px;}
.uploadDocumentWrapper .uploadDocumentLeft ol li .documentActions span{margin-left: 15px; cursor: pointer; display: flex; align-items: center;}
.uploadDocumentWrapper .uploadDocumentLeft ol li .documentActions span img{max-width:20px; margin-right: 5px;}
.uploadDocumentWrapper .uploadDocumentLeft ol li .documentActions span:first-child{margin-left:0px;}
.uploadDocumentWrapper .uploadDocumentMain{display: flex; width: 100%;}
.uploadDocumentWrapper .uploadDocumentBlock .uploadActions{display: flex; justify-content: center; align-items: center; border-top:1px solid #ddd; padding-top: 10px; margin-top: 15px;}
.uploadDocumentWrapper .uploadDocumentBlock .uploadActions span{ cursor: pointer;  background-color:var(--primary); font-weight: 700; color:#fff; font-size: 18px; padding:6px 20px 8px 20px; border-radius:40px;}
.uploadDocumentWrapper .uploadDocumentBlock .uploadActions span:last-child{margin-left: 15px;}
.uploadDocumentWrapper .uploadDocumentBlock .uploadActions span:first-child{margin-left:0px;}

.uploadDocumentDialog.open{display: flex;}
.uploadDocumentDialog{position: fixed; top:0; right:0; bottom:0; font-family: Lato; left:0; display: flex; display: none; align-items: center; background-color: rgba(0,0,0,0.5); justify-content:center; z-index: 100!important;}
.uploadDocumentDialog .uploadDocumentContainer{position: relative; width:100%; padding:15px;  background-color: #fff; max-width: 500px; border-radius: 15px;}
.uploadDocumentDialog .uploadDocumentContainer .dialogClose{position: absolute; right:10px; top:10px; font-weight: 700; font-size: 20px;}
.uploadDocumentDialog .uploadDocumentContainer .dialogClose span{cursor: pointer;}
.uploadDocumentDialog .uploadDocumentContainer .formWrapper{display: flex; width: 100%; margin-bottom: 20px;}
.uploadDocumentDialog .uploadDocumentContainer .formBlock{display: flex; align-items: center; width:50%;}
.uploadDocumentDialog .uploadDocumentContainer .formBlock span{ cursor: pointer; display: flex; justify-content: flex-end;   font-weight: 700; color:var(--primary); font-size: 18px; width:100%; padding:0px; border-radius:40px;}
.uploadDocumentDialog .uploadDocumentContainer .formWrapper select{border:1px solid #ddd; width:100%; max-width: 400px; border-radius:5px; padding: 5px 10px;}
.uploadDocumentDialog .uploadDocumentContainer .title{ display: flex; align-items: center; justify-content: space-between; font-size: 18px; color: #000; font-weight: 700; border-bottom:1px solid #ddd; margin-bottom:25px; padding-bottom: 10px;}
.uploadDocumentDialog .uploadDocumentPreview{display: flex; width: 100%; max-height: 200px;}
.uploadDocumentDialog .uploadDocumentPreview img{max-width: 100%;}
.uploadDocumentDialog .uploadActions{display: flex; justify-content: center; align-items: center; border-top:1px solid #ddd; padding-top: 10px; margin-top: 15px;}
.uploadDocumentDialog .uploadActions span{ cursor: pointer;  background-color:var(--primary); font-weight: 700; color:#fff; font-size: 18px; padding:6px 20px 8px 20px; border-radius:40px;}
.uploadDocumentDialog .uploadActions span:last-child{margin-left: 15px;}
.uploadDocumentDialog .uploadActions span:first-child{margin-left:0px;}
.uploadDocumentDialog .uploadActions span.is_disabled{pointer-events: none; opacity: 0.6;}
.uploadDocumentLoader{display: flex; align-items: center; justify-content: center; width: 100%;}
.alignmentChange{justify-content: center;}
.alignmentChange .popup-inputs{margin-bottom: 15px;}
.alignmentChange .popup-inputs input:first-child, .alignmentChange .popup-inputs select:first-child{width: 50%;}
.alignmentChange .popup-inputs input:last-child{width: 50%; margin-left: 15px;}

.documentConfirmDialog.open{display: flex;}
.documentConfirmDialog{position: fixed; top:0; right:0; bottom:0; font-family: Lato; left:0; display: flex; display: none; align-items: center; background-color: rgba(0,0,0,0.5); justify-content:center; z-index: 100!important;}
.documentConfirmDialog .documentConfirmContainer{position: relative; width:100%; padding:15px;  background-color: #fff; max-width: 500px; border-radius: 15px;}
.documentConfirmDialog .documentConfirmContainer .documentConfirmContent{display: flex; width: 100%; flex-direction: column;}
.documentConfirmDialog .documentConfirmContainer .documentConfirmContent div img{max-width:100px;}
.documentConfirmDialog .documentConfirmContainer .documentConfirmContent div{ margin-bottom: 20px; display: flex; justify-content:center;}
.documentConfirmDialog .documentConfirmContainer .documentConfirmContent div + div{text-align: center; font-weight: 700; margin-bottom: 20px; color:#000; font-size:25px; }
.documentConfirmDialog .documentConfirmContainer span{ cursor: pointer;  background-color:var(--primary); font-weight: 700; color:#fff; font-size: 18px; padding:6px 30px 8px 30px; border-radius:40px;}
.documentConfirmDialog .documentConfirmContainer span:last-child{margin-left: 15px;}
.documentConfirmDialog .documentConfirmContainer span:first-child{margin-left:0px;}
.documentConfirmDialog .documentConfirmContainer .dialogActions{display: flex; justify-content: center; width: 100%; border-top:1px solid #ddd; padding-top: 20px;}


.uploadDocuFileName{margin-bottom: 10px; display: flex; width: 100%; text-align: right; justify-content: flex-end;}
.grid-item .grid-up .gridAcions{position: absolute;opacity: 0.5; bottom:0px; left:0; right:0; display: flex; padding: 8px 10px; border-radius: 0 0px 15px 15px; transition: all 0.2s ease;  opacity: 1; visibility: visible; justify-content: center; align-items: flex-start;   background-color: #ddd; flex-direction: row; width:100%}
.grid-item .grid-up .gridAcions img{max-width: 20px; margin-bottom: 5px; margin-right: 0;}
.grid-item .grid-up .gridAcions span{width: auto; display: flex; color: #374957; width: 80px; align-items: center; margin-left: 10px; margin-top: 0; transition: all 0.2s ease; flex-direction: column;}
.grid-item .grid-up .gridAcions span:first-child{margin-left: 0;}
.grid-item .grid-up .gridAcions span i{font-style: normal; white-space: normal; transition: all 0.2s ease; opacity: 1; visibility: visible; font-weight: normal; font-family: Lato; font-size:14px}
.grid-item .grid-up .gridAcions span:hover i{visibility: visible; opacity: 1; width:auto;}
.grid-item .grid-up .gridAcions span:hover{background-color: transparent; color: #489198;}
.grid-item:hover .grid-up .gridAcions{bottom:0; visibility: visible; opacity: 1;}
.grid-item .title-grid4{align-items: flex-start;}
.grid-item .title-grid4 div{display: flex; flex-direction: column;}
.grid-item .title-grid4 div .socialIcons{display:flex; width:100%; flex-direction: row; margin-top: 10px;}
.grid-item .title-grid4 div .socialIcons a{display:flex; align-items: center; width:auto; margin-left: 15px;}
.grid-item .title-grid4 div .socialIcons a:first-child{margin-left: 0;}
.grid-item .title-grid4 div .socialIcons a img{max-width: 16px; width:auto; margin-right: 0;}
.list-item .title-item3 div{display: flex; flex-direction: column;}
.list-item .title-item3 div .socialIcons{display:flex; width:100%; flex-direction: row; margin-top: 8px;}
.list-item .title-item3 div .socialIcons a{display:flex; align-items: center; width:auto; margin-left:8px;}
.list-item .title-item3 div .socialIcons a:first-child{margin-left: 0;}
.list-item .title-item3 div .socialIcons a img{max-width: 16px; width:auto; margin-right: 0;}


#send-invite-student {
    display: flex;
    align-items: center;
    font-family: Lato;
    color: #374957;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    margin-top: 5px;
}

.userActionInfo{display: flex; align-items: center; justify-content: center; grid-gap:15px; gap:15px}
.userActionInfo .viewAttendance{display: flex; align-items: center; grid-gap:15px; gap:15px}
.userActionInfo .viewAttendance a{display: flex; align-items: center;}
.userActionInfo .viewAttendance a{font-size: 14px;    font-family: Lato; white-space: nowrap; color: var(--primary); text-decoration: underline;}
.userActionInfo .viewAttendance a:hover{font-size: 14px;    font-family: Lato; white-space: nowrap; color: var(--primary); text-decoration: none;}
.marginLeft15{margin-left: 15px;}
.sub-filter .activeInactiveSelect{max-width: 220px; width:100%}
.invite-class{
	width: 100px;
	padding-left: 13px;
	padding-right: 0px;
}
.edit-div-2{align-items: center;}
.edit-div-2 .passwordBlock{position: relative; padding:0; background-color: transparent; border:0px none; width:auto}
.edit-div-2 .togglePassword {
    cursor: pointer;
    width: 50px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 8px;
    top: 10px!important;
    position: absolute;
    background-color: #fff;
    border:0px none;
}
.edit-div-2 .togglePassword img {
    width: 25px;
}
#send-invite {
    display: flex;
    align-items: center;
    font-family: Lato;
    color: #374957;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    width: 100px;
    margin-top: 7px;
}

.add-more{
    margin-top: 20px;
}

#grid-up-span{
    width: 100%
}

.grid-item{position: relative; overflow: hidden;}
.grid-item .title-grid4{width: 100%;}
.grid-item .grid-up{flex-direction: column;}
.grid-item .grid-up .userEmail#grid-up-span{align-items: flex-start; margin-top: 10px; width:100%;  font-family: Lato; font-size: 16px;}
.grid-item:hover .gridActions{bottom:0; visibility: visible; opacity: 0.9; height: auto;}
.grid-item .gridActions{position: absolute; transition: all 0.2s ease; opacity:0; visibility: hidden; border-radius:0 0px 15px 15px; height:0px; justify-content: center; padding:8px 10px; background-color: #ddd; display: flex; align-items: flex-start; bottom:-20px;  left:0; right:0; width:100%;}
.grid-item .gridActions span{display: flex; flex-direction: column; width:40px; transition: all 0.2s ease; align-items: center; margin-left: 10px; cursor: pointer;}
.grid-item .gridActions span:hover i{width:auto; visibility: visible; opacity: 1;}
.grid-item .gridActions span img{display: flex; max-width: 20px; width: 100%; height: 100%; max-height: 20px; margin-right:0px;}
.grid-item .gridActions .invite-class{width:auto; margin-top: 0; padding:0; display: flex; align-items: flex-start;}
.grid-item .gridActions .invite-class div{width: auto; align-items: center; display: flex;}
.grid-item .gridActions .invite-class  #send-invite{width:40px; text-align: center;  display: flex; flex-direction: column; margin-top: 0; margin-left: 10px;}
.grid-item .gridActions .invite-class  #send-invite img{margin-right: 0; width: 100%; max-height: 20px; height: 100%; max-width: 20px;}
.grid-item .gridActions i{margin-left: 5px;opacity:0; transition: all 0.2s ease; visibility: hidden; width:0px; font-style: normal; white-space: normal; font-family: Lato; font-size: 14px; color: #374957;}
.grid-item .gridActions .invite-class div:hover i{width:auto; visibility: visible; opacity: 1;}

.main-part.paginationshow{height: calc(100% - 106px)!important}

.sub-filter.spaceSet{justify-content: space-between;}
.sub-filter.spaceSet .filters{display: flex; flex:1 1}
.sub-filter.spaceSet .bulkUpload{display: flex; align-items: center;}
.sub-filter.spaceSet .bulkUpload span{background-color: var(--secondary); border-radius: 8px; padding:11px 14px 12px 14px; cursor: pointer; font-family: Lato; font-weight: 700; font-size: 16px; color: white;}

.bulkUserUploadDialog.open{display: flex;}
.bulkUserUploadDialog{background: rgba(0, 0, 0, 0.5); z-index: 999; top:0; right:0; bottom:0; left:0;  z-index: 999; display: flex; display: none; align-items: center; justify-content: center; position: fixed;}
.bulkUserUploadDialog .bulkUserUploadContainer{padding: 32px 40px; border-radius: 15px; background: rgb(255, 255, 255); width: 50%; position: relative;}
.bulkUserUploadDialog .bulkUserUploadContainer .close{position: absolute; cursor: pointer; top:8px; right:15px; font-weight: bold; font-size: 21px;}
.bulkUserUploadDialog .bulkUserUploadContainer .popup-header{width: 100%; text-align: center; font-family: Lato; font-size: 22px; font-weight: 700; color: var(--text-black);}
.bulkUserUploadDialog .bulkUserUploadContainer .actions{display: flex; width: 100%; margin: 20px 0;}
.bulkUserUploadDialog .bulkUserUploadContainer .actions span{width: 100%; justify-content: center; display: flex; align-items: center; font-family: Lato; font-size: 14px; color: var(--text-black);}
.bulkUserUploadDialog .bulkUserUploadContainer .actions a{display: flex; align-items: center;font-family: Lato; font-size: 14px; width: 100%; justify-content: center; color: var(--text-black);}
.bulkUserUploadDialog .bulkUserUploadContainer .actions img{max-width: 26px; margin-right: 5px;}
.bulkUserUploadDialog .bulkUserUploadContainer .actions i{display: flex; align-items: center; font-style: normal;}
.bulkUserUploadDialog .bulkUserUploadContainer .tableWrapper{display: flex; display: none; width: 100%; font-family: Lato; font-size: 14px; font-weight: 400; color: var(--text-black);}
.bulkUserUploadDialog .bulkUserUploadContainer .tableWrapper.display{display: flex;}
.bulkUserUploadDialog .bulkUserUploadContainer .tableWrapper table{width: 100%; border-collapse:collapse; border:1px solid #ddd;}
.bulkUserUploadDialog .bulkUserUploadContainer .tableWrapper table th{padding:5px 10px; text-align: left; border:1px solid #ddd;}
.bulkUserUploadDialog .bulkUserUploadContainer .tableWrapper table td{padding:5px 10px; text-align: left; border:1px solid #ddd;}
.bulkUserUploadDialog .bulkUserUploadContainer input[type="file"]{display: none;}
.bulkUserUploadDialog .bulkUserUploadContainer .somthingWenWrongMsg{display: flex; border:1px solid red; border-radius:5px; background-color: #ffefef; color: red; margin-top: 15px; padding: 10px; width: 100%; font-family: Lato; font-size: 15px;  padding: 10px; justify-content: center;}


.confirmDialogWrapper.open{display: flex;}
.confirmDialogWrapper{background: rgba(0, 0, 0, 0.5); z-index: 999; top:0; right:0; bottom:0; left:0;  z-index: 999; display: flex; display: none; align-items: center; justify-content: center; position: fixed;}
.confirmDialogWrapper .confirmDialogContainer{padding: 32px 40px; border-radius: 15px; background: rgb(255, 255, 255); width: 30%; position: relative;}
.confirmDialogWrapper .confirmDialogContainer .close{position: absolute; cursor: pointer; top:8px; right:15px; font-weight: bold; font-size: 21px;}
.confirmDialogWrapper .confirmDialogContainer .popup-header{width: 100%; margin-bottom: 20px; text-align: center; font-family: Lato; font-size: 22px; font-weight: 700; color: var(--text-black);}
.confirmDialogWrapper .confirmDialogContainer .formWrapper{display:flex;  justify-content: center; align-items: center; width: 100%; position: relative; font-family: Lato; font-size: 16px; font-weight: 400; color: var(--text-black);}
.confirmDialogWrapper .confirmDialogContainer .formActions{display: flex; width: 100%; justify-content: center; margin-top: 30px;}
.confirmDialogWrapper .confirmDialogContainer .formActions button{margin-left: 15px;}
.confirmDialogWrapper .confirmDialogContainer .formActions button:first-child{margin-left:0px;}


.updatePasswordDialog.open{display: flex;}
.updatePasswordDialog{background: rgba(0, 0, 0, 0.5); z-index: 999; top:0; right:0; bottom:0; left:0;  z-index: 999; display: flex; display: none; align-items: center; justify-content: center; position: fixed;}
.updatePasswordDialog .updatePasswordContainer{padding: 32px 40px; border-radius: 15px; background: rgb(255, 255, 255); width: 30%; position: relative;}
.updatePasswordDialog .updatePasswordContainer .close{position: absolute; cursor: pointer; top:8px; right:15px; font-weight: bold; font-size: 21px;}
.updatePasswordDialog .updatePasswordContainer .popup-header{width: 100%; margin-bottom: 20px; text-align: center; font-family: Lato; font-size: 22px; font-weight: 700; color: var(--text-black);}
.updatePasswordDialog .updatePasswordContainer .formWrapper{display:flex; align-items: center; width: 100%; position: relative;}
.updatePasswordDialog .updatePasswordContainer .formWrapper input{width:100%; border-radius: 4px;      border: 1px solid var(--primary);   color: var(--text-black);   padding: 12px 16px; background-color: var(--secondary-rgba); font-family: Lato; font-size:16px;}
.updatePasswordDialog .updatePasswordContainer .togglePassword{position: static;}
.updatePasswordDialog .updatePasswordContainer .formActions{display: flex; width: 100%; justify-content: flex-end; margin-top: 20px;}

.grid-item .grid-up .userActiveInactive{display: flex; width: 100%; justify-content: flex-end;}
.grid-item .grid-up .userActiveInactive div{width:60px; height: 25px;  position: relative; display:flex;}
.grid-item .grid-up .userActiveInactive div span{border-radius: 35px; background-color: var(--white); transition: .4s; top:0; right:0; bottom:0; left:0; position: absolute;  margin-top:0;   cursor: pointer;}
.grid-item .grid-up .userActiveInactive div span:before{border-radius: 50%; background-color: var(--secondary-rgba); content: ""; transition: .4s; left: 4px; bottom:3px;  width: 20px; height: 20px; position: absolute;}
.grid-item .grid-up .userActiveInactive div input{margin:0; display: none;}
.grid-item .grid-up .userActiveInactive div input:checked + span:before { border-radius: 50%; background-color: var(--primary); -webkit-transform: translateX(32px); transform: translateX(32px);}

.aicteResetWrapper{display:flex; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; height:100%; font-family: Lato;  background: linear-gradient(to right, #00b09b, #96c93d);}
.aicteResetWrapper img{max-width: 100%;}
.aicteResetWrapper .aicteResetContainer{display:flex; width: 100%; flex-direction: column; align-items: center; justify-content: center;}
.aicteResetWrapper .aicteResetContainer .resetBlock{box-shadow: 0 8px 20px 0 rgb(0 0 0 / 15%); max-width:350px; width:100%; border-radius:10px; align-items: center; display: flex; flex-direction: column; background-color: #fff;  padding:10px 10px; border-radius:5px;}
.aicteResetWrapper .aicteResetContainer .resetBlock .errorMsg{color:#ff0000; font-size: 14px; margin: 5px;}
.aicteResetWrapper .aicteResetContainer .resetBlock .formblock{display: flex; flex-direction: column; width: 100%;}
.aicteResetWrapper .aicteResetContainer input[type="text"]{border: 1px solid var(--light-black); border-radius:5px; height:40px; padding: 10px 10px;}
.aicteResetWrapper .aicteResetContainer button{border:0px none;  margin-top: 15px; border-radius: 40px; background-color:var(--primary); padding:10px 40px; color:#fff;}
.aicteResetWrapper .aicteResetContainer .resetBlock .logo{display: flex; width: 100%; align-items: center; justify-content: center;}
.aicteResetWrapper .aicteResetContainer .resetBlock .logo span{display: flex; align-items: center; justify-content: center; width:115px; height: 115px;  border-radius: 40px;  background-color: rgba(96, 176, 203, 0.1);}
.aicteResetWrapper .aicteResetContainer .resetBlock h2{ font-size: 27px; font-weight: 600; color: var(--primary);  margin-bottom:15px;}

.aicteSignupWrapper{display:flex; width: 100%; height:100%; flex-direction: column; font-family: Lato; background-color: #eff0f5;}
.aicteSignupWrapper img{max-width: 100%;}
.aicteSignupWrapper .aicteSignupContainer{display:flex; width: 100%; flex-direction: column;}
.aicteSignupWrapper .aicteSignupContainer .headerWrapper{background-color:#27346B; position: relative; background-image:url(/static/media/searching-page-herobg.94433380.jpg); background-position: center; background-repeat: no-repeat; background-size: cover;}
.aicteSignupWrapper .aicteSignupContainer .headerWrapper:after{background-color: rgba(29,52,107,0.6); content: ''; position: absolute; top:0; left:0; right:0; bottom:0}
.aicteSignupWrapper .aicteSignupContainer .headerWrapper .headerContainer{ display: flex;position: relative;  z-index: 21; max-width: 1000px; margin:0 auto; width: 100%;}
.aicteSignupWrapper .aicteSignupContainer .headerWrapper .searchWrapper{display: flex; flex-direction: column;    margin-bottom: -27px;  width:100%; height:250px; justify-content: flex-end; }
.aicteSignupWrapper .aicteSignupContainer .headerWrapper .searchWrapper h1{color:#fff; font-size: 31px; text-align: center; margin:0 0 5px; padding:0;}
.aicteSignupWrapper .aicteSignupContainer .headerWrapper .searchWrapper p{color:#f4f2f2; font-size: 18px; text-align: center; margin:0 0 15px; padding:0;}
.aicteSignupWrapper .aicteSignupContainer .headerWrapper .searchWrapper .searchBlock{box-shadow: 0 8px 20px 0 rgb(0 0 0 / 15%); align-items: center; display: flex; background-color: #fff;  padding:10px 10px; border-radius:5px;}
.aicteSignupWrapper .aicteSignupContainer .headerWrapper .searchWrapper .searchBlock .errorMsg{color:#ff0000; font-size: 14px; margin: 5px;}
.aicteSignupWrapper .aicteSignupContainer .headerWrapper .searchWrapper .searchBlock .formblock{display: flex; flex-direction: column; width: 100%;}
.aicteSignupWrapper .aicteSignupContainer .headerWrapper .searchWrapper input[type="search"]{border:0px none; font-size: 18px; height:40px; background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAdpJREFUSEu1lc1xwjAQhSWZk7lABUAFgQoCN5AHYioIqQA6iEtIB5AK4gA23OJUEKgg7gBygBNI2XUwY35seTLGF81o7fdpd9/KlNz4oTfWJ7EAs9ksC8YeJKUmlbIMq0+kXFNK7bHjvKY92FVA2zAGEHiWUv7AaqM4QAqE0jJATBBf7Qjpuq67UIEuAB3DGKEIiFoTx3k5FzDr9YLI53H/UQrRncxmdhLkBNButUzK2NtOyprqdB3OLcioz/b7mj2f+3GQUwDnPmyMxq5rqVLHeJtzD3riQ096SkB4erbZFG3PW6cC/GU8BEBRCcCUJSH1ievW04jjO4d+rASljel06l377liioLnwJKV7TQC+k+kA/8gAgakBgfel7EODK2lLxDmv5ij9AidV4px0LFEwuZr2nZTuORjLCn2rwrxUlU0+pItDdse224bKSeHpVcN2Mgfoir2uL2BzlQQ5iH/AVbJUue7iqsBS7TXNhkAJsrGYEO9hfVFYI6QPw9UD8U9Y72F9AkjgwESbRoOBv3V9APXtgUgpGoO9JRHCwjsIJhnjwySI8n+Ap2aMFRCS2+38c7eoIEpAGssmQTIB4CHiIJkB4iCZAqKQcLozBwS3LFg9NMNNAFFj/AIqGw0ofkIyUQAAAABJRU5ErkJggg==); background-repeat: no-repeat;   background-position: left 8px; padding: 10px 30px;}
.aicteSignupWrapper .aicteSignupContainer .headerWrapper .searchWrapper button{border:0px none; border-radius: 40px; background-color:var(--primary); padding:10px 40px; color:#fff;}
.aicteSignupWrapper .aicteSignupContainer .bottomListingWrapper{display: flex; flex-direction: column; width: 100%;}
.aicteSignupWrapper .aicteSignupContainer .bottomListingContainer{display: flex; max-width: 1000px; flex-direction: column;  padding-top:45px;  margin:0 auto; width: 100%;}
.aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .SearchDefaultContent{background-color:#e4e6f0; padding:25px; display: flex; display: none; flex-direction: column; width: 100%;}
.aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .SearchDefaultContent p{font-size: 14px; color:#7b7979; margin:0; font-weight:bold; margin-bottom: 15px;;}
.aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .SearchDefaultContent ol{display: flex; margin:0 0 0 20px; padding: 0; flex-direction: column; width: 100%;}
.aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .SearchDefaultContent ol li{font-size: 14px;  list-style-position: inside; list-style-type: auto; color:#7b7979; margin-bottom: 15px;}
.aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .SearchDefaultContent strong{margin-bottom: 15px;}

.SearchEmailExist{display: flex; width: 100%; flex-direction: column;}
.SearchEmailExist p{color:var(--primary); font-size: 22px; line-height: 35px; font-weight: bold; text-align:center;}
.SearchEmailExist .scancode-section{display: flex; width: 100%; flex-direction: column;}
.SearchEmailExist .scancode-section .scancode-container{display: flex; padding:50px 0;  width: 100%; max-width: 1000px; margin: auto; align-items: flex-start;}
.SearchEmailExist .scancode-section .scancode-container .left-section{display: flex; flex-direction: column; width: 100%; max-width:70%;}
.SearchEmailExist .scancode-section .scancode-container .right-section{display: flex; flex-direction: column; width: 100%; max-width:30%;}
.SearchEmailExist .scancode-section h2{margin: 0 0 20px; color: #333; text-align: center; margin:0 0 15px;}
.SearchEmailExist .scancode-section .scancode-container .left-section p{margin: 0 0 20px; color: #999;}
.SearchEmailExist .scancode-section .scancode-container .right-section .qr-code-section{margin: 0 0 20px; display: flex; flex-direction: column; align-items: flex-start;}
.SearchEmailExist .scancode-section .scancode-container .right-section .qr-code-section p{max-width: 230px; text-align: left; margin: 0 auto}
.SearchEmailExist .scancode-section .scancode-container .right-section img{max-height: 230px; border-radius: 5px; border: 1px solid #ddd; padding: 5px; margin: 0 auto; width: auto; height: auto; margin-top: 20px;}
.SearchEmailExist .scancode-section .scancode-container .right-section .app-store-links{display: flex; width: 100%; position: relative; align-items: flex-start; padding-top: 20px; margin-top: 30px; margin-left: auto; margin-right: auto; border-top: 1px solid #ddd;}
.SearchEmailExist .scancode-section .scancode-container .right-section .app-store-links::before { content: 'Or';  font-size: 14px; text-align: center; font-weight: 500; color: #666; display: inline-block; width: 30px; height: 20px;  background: #fff; position: absolute; top: -13px; left: 0; right: 0;  margin: auto;}
.SearchEmailExist .scancode-section .scancode-container .right-section .app-store-links .playstore-btn{display: flex; width: 100%; justify-content: center;}
.SearchEmailExist .scancode-section .scancode-container .right-section .app-store-links .playstore-btn img{margin-top: 0; border: 0px none; padding: 0;}
.SearchEmailExist .scancode-section .scancode-container .right-section p{color: #333; font-size: 14px; line-height: 24px;}

.SearchEmailExist .searchResultWrapper{display: flex; width: 100%;}
.SearchEmailExist .searchResultWrapper .searchResultTableWrapper{display: flex; width: 100%; flex-direction: column;}
.SearchEmailExist .searchResultWrapper .searchResultTableWrapper p{color:var(--primary); font-size: 22px; line-height: 35px; margin-bottom: 30px; font-weight: bold; text-align:center;}
.SearchEmailExist .searchResultWrapper .searchResultTableWrapper ol{display:flex; border:0px none; width:100%; margin:0 0 30px; padding: 0; flex-direction: column;}
.SearchEmailExist .searchResultWrapper .searchResultTableWrapper ol li{text-align: left; align-items: center; justify-content: space-between; flex-direction:row; display:flex; border-radius:5px; background-color: #fff; box-shadow: 0 0 5px #ddd; padding:10px 10px;}
.SearchEmailExist .searchResultWrapper .searchResultTableWrapper ol li .listname{font-size: 15px;}
.SearchEmailExist .searchResultWrapper .searchResultTableWrapper ol li .listemail{font-size: 15px; margin-top: 5px;}
.SearchEmailExist .searchResultWrapper .searchResultTableWrapper ol li .listmobile{font-size: 15px; margin-top: 5px;}
.SearchEmailExist .searchResultWrapper .searchResultTableWrapper ol li .listaction{font-size: 15px; margin-top: 5px; color: var(--primary); text-decoration: underline;}
.SearchEmailExist .searchResultWrapper .searchResultTableWrapper ol li .listaction div{font-size: 15px; cursor: pointer; color: var(--primary); text-decoration: underline;}
.SearchEmailExist .searchResultWrapper .searchResultTableWrapper ol li .listaction div:hover{font-size: 15px; color: var(--primary); text-decoration: none;}


.SearchEmailNotExistAfterSignUp{display: flex; width: 100%; flex-direction: column;}
.SearchEmailNotExistAfterSignUp p{color:var(--primary); font-size: 22px; line-height: 35px; font-weight: bold; text-align:center;}
.SearchEmailNotExistAfterSignUp .scancode-section{display: flex; width: 100%; flex-direction: column;}
.SearchEmailNotExistAfterSignUp .scancode-section .scancode-container{display: flex; padding:50px 0;  width: 100%; max-width: 1000px; margin: auto; align-items: flex-start;}
.SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .left-section{display: flex; flex-direction: column; width: 100%; max-width:70%;}
.SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .right-section{display: flex; flex-direction: column; width: 100%; max-width:30%;}
.SearchEmailNotExistAfterSignUp .scancode-section h2{margin: 0 0 20px; color: #333; text-align: center; margin:0 0 15px;}
.SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .left-section p{margin: 0 0 20px; color: #999;}
.SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .right-section .qr-code-section{margin: 0 0 20px; display: flex; flex-direction: column; align-items: flex-start;}
.SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .right-section .qr-code-section p{max-width: 230px; text-align: left; margin: 0 auto}
.SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .right-section img{max-height: 230px; border-radius: 5px; border: 1px solid #ddd; padding: 5px; margin: 0 auto; width: auto; height: auto; margin-top: 20px;}
.SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .right-section .app-store-links{display: flex; width: 100%; position: relative; align-items: flex-start; padding-top: 20px; margin-top: 30px; margin-left: auto; margin-right: auto; border-top: 1px solid #ddd;}
.SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .right-section .app-store-links::before { content: 'Or';  font-size: 14px; text-align: center; font-weight: 500; color: #666; display: inline-block; width: 30px; height: 20px;  background: #fff; position: absolute; top: -13px; left: 0; right: 0;  margin: auto;}
.SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .right-section .app-store-links .playstore-btn{display: flex; width: 100%; justify-content: center;}
.SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .right-section .app-store-links .playstore-btn img{margin-top: 0; border: 0px none; padding: 0;}
.SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .right-section p{color: #333; font-size: 14px; line-height: 24px;}


.SearchEmailNotExist{display: flex; width: 100%; flex-direction: column;}
.SearchEmailNotExist p{color:#ff0000; font-size: 22px; line-height: 35px; font-weight: bold; text-align:center;}
.SearchEmailNotExist .actions{display: flex; align-items: center; width: 100%; justify-content: center;}
.SearchEmailNotExist .actions button{border:0px none; border-radius: 40px; background-color:var(--primary); padding:10px 40px; color:#fff;}
.SearchEmailNotExist .signupFormWrapper{max-width:400px; width:100%; display: flex; flex-direction: column; margin:30px auto; border-radius:5px; padding: 15px; background-color: #fff6; box-shadow: 0 0 6px #e8e7e7;}
.SearchEmailNotExist .signupFormWrapper h2{margin-bottom:0; text-align: center; width:100%; font-size: 26px; line-height: normal;}
.SearchEmailNotExist .signupFormWrapper .seperator{background-color:var(--primary); width:50px; height: 4px; margin:10px auto 30px;}
.SearchEmailNotExist .signupFormWrapper .formblock{flex-direction: column; display: flex; width: 100%; margin-bottom: 15px; }
.SearchEmailNotExist .signupFormWrapper .formblock input{border:0px solid #ddd; background-color:#eaeaea; border-radius:5px; padding:12px 10px;}
.SearchEmailNotExist .signupFormWrapper .formblock input:focus{border:0px solid #ddd;     box-shadow:0 0 6px var(--primary); background-color:#fff; border-radius:5px; padding:12px 10px;}
.SearchEmailNotExist .signupFormWrapper .formblock .errorMsg{color:#ff0000; font-size: 13px; margin: 5px; display: block;}
.SearchEmailNotExist .signupFormWrapper .termsBlock{display: flex; width:100%; margin-top: 15px;}
.SearchEmailNotExist .signupFormWrapper .termsBlock label{cursor: pointer;color: #999; font-size:13px; display: flex; align-items: center;}
.SearchEmailNotExist .signupFormWrapper .termsBlock label input{margin:0 10px 0;}

.aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .tableWrapper{ display: flex; width:100%;}
.aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .tableWrapper table {width:100%; border-collapse: collapse;}
.aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .tableWrapper table tr:first-child{background-color: #eaeaea;}
.aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .tableWrapper table tr:nth-child(odd){background-color: #e9edfa;}
.aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .tableWrapper table th{padding:10px; font-size: 14px;}
.aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .tableWrapper table td{padding:10px; font-size: 14px; text-align: center; color:#999}


@media screen and (max-width:770px) {

    .aicteSignupWrapper .aicteSignupContainer .headerWrapper .headerContainer{padding: 0 20px;}


    .aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .SearchDefaultContent ol{margin-left: 0; padding-left: 0;}
    .aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .SearchDefaultContent p{text-align:left; line-height: 24px;}
    .aicteSignupWrapper .aicteSignupContainer .bottomListingContainer .SearchDefaultContent strong{margin-bottom: 15px;}

    .aicteSignupWrapper .aicteSignupContainer .bottomListingContainer{padding-left: 20px; padding-right: 20px;}
    .SearchEmailExist .scancode-section .scancode-container{flex-direction: column; align-items: center}
    .SearchEmailExist .scancode-section .scancode-container .left-section{max-width: none; align-items: center;}
    .SearchEmailExist .scancode-section .scancode-container .right-section{max-width: none; align-items: center; margin-top: 30px;}
    .SearchEmailExist .scancode-section .scancode-container .right-section .qr-code-section p{max-width: none;}
    .SearchEmailExist p br{display: none;}

    .SearchEmailNotExistAfterSignUp .scancode-section .scancode-container{flex-direction: column; align-items: center}
    .SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .left-section{max-width: none; align-items: center;}
    .SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .right-section{max-width: none; align-items: center; margin-top: 30px;}
    .SearchEmailNotExistAfterSignUp .scancode-section .scancode-container .right-section .qr-code-section p{max-width: none;}
    .SearchEmailNotExistAfterSignUp p br{display: none;}

    .aicteSignupWrapper .aicteSignupContainer .headerWrapper .searchWrapper .searchBlock{flex-direction: column;}
    .aicteSignupWrapper .aicteSignupContainer .headerWrapper .searchWrapper h1{font-size: 21px; margin-bottom: 10px;}
    .aicteSignupWrapper .aicteSignupContainer .headerWrapper .searchWrapper p{font-size: 15px; margin-bottom: 10px;}

    .SearchEmailExist .searchResultWrapper .searchResultTableWrapper ol li{flex-direction: column;}
    .SearchEmailExist .searchResultWrapper .searchResultTableWrapper ol li .listaction{margin-top: 15px; display: flex;}
    .SearchEmailExist .searchResultWrapper .searchResultTableWrapper ol li .listaction button{font-size: 13px;}
}

@media screen and (max-width:500px) {
.aicteResetWrapper .aicteResetContainer .resetBlock{max-width: 300px;}
}

.userProfileLink{font-family: Lato; color:var(--primary); font-weight: 700; text-decoration: underline; display: none;}
.batchHistoryWrapper{display: flex; width: 100%; font-family: Lato;}
.batchHistoryWrapper .batchHistoryContainer{display: flex; width: 100%; padding: 20px 0; flex-direction: column;}
.batchHistoryWrapper .batchHistoryContainer .topHeader{justify-content: center; display: flex; align-items: center; width: 100%;}
.batchHistoryWrapper .batchHistoryContainer .topHeader .profilename{font-size: 20px; color: var(--primary); font-weight: 700;}
.batchHistoryWrapper .batchHistoryContainer .tabsBlock{display: flex; width: 100%; justify-content: center; margin-bottom: 30px;}
.batchHistoryWrapper .batchHistoryContainer .tabsBlock div{background-color:transparent; cursor: pointer;  border-radius:0px;  color:#999; padding:5px 15px; font-family: Lato;};
.batchHistoryWrapper .batchHistoryContainer .tabsBlock div:first-child{padding-left: 0; border-left:0px none; margin-left: 0;}
.batchHistoryWrapper .batchHistoryContainer .tabsBlock span{width:2px; background-color: #ddd; margin:0 10px}
.batchHistoryWrapper .batchHistoryContainer .tabsBlock div:hover{padding:5px 15px; font-family: Lato;  color:var(--primary); border-left:0px none;}
.batchHistoryWrapper .batchHistoryContainer .tabsBlock div.active{padding:5px 15px; font-family: Lato; border-bottom:2px solid var(--primary); font-weight: 700;  color:var(--primary); border-left:0px none;}
.batchHistoryWrapper .batchHistoryContainer .TabsContent{display: flex; font-family: Lato; flex-direction: column; width:100%;}
.batchHistoryWrapper .batchHistoryContainer .accordionItem{width:100%; border:1px solid #ddd; font-weight: 700; display: flex; align-items: center; justify-content: space-between; padding:15px 10px; border-radius:5px;}
.batchHistoryWrapper .batchHistoryContainer .accordion{display: flex; margin-top: 10px; width:100%; flex-direction: column;}
.batchHistoryWrapper .batchHistoryContainer .accordionItem:after{content: "+"; float:right; font-weight: 700;}
.batchHistoryWrapper .batchHistoryContainer .accordion.isactive .accordionItem:after{content: "-"; float:right; font-weight: 700;}
.batchHistoryWrapper .batchHistoryContainer .accordionItem:hover{ cursor: pointer; border:1px solid #ddd; background-color: #f4f3f3;}
.batchHistoryWrapper .batchHistoryContainer .tableWrapper{display: flex; padding:10px; width: 100%; flex-direction: column; display: none;}
.batchHistoryWrapper .batchHistoryContainer .tableWrapper table{width: 100%;}
.batchHistoryWrapper .batchHistoryContainer .tableWrapper th{background-color: #e7e7e7; padding:10px; text-align: center;}
.batchHistoryWrapper .batchHistoryContainer .tableWrapper td{background-color: #f1efef; padding:10px; text-align: center;}
.batchHistoryWrapper .batchHistoryContainer .tableWrapper table tr{opacity: 0.4;}
.batchHistoryWrapper .batchHistoryContainer .tableWrapper table tr:nth-child(1){opacity: 1;}
.batchHistoryWrapper .batchHistoryContainer .tableWrapper table tr:nth-child(2){opacity: 1;}
.batchHistoryWrapper .batchHistoryContainer .accordion.isactive .accordionItem{border-bottom:0px none; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;}
.batchHistoryWrapper .batchHistoryContainer .accordion.isactive  .tableWrapper{display: flex; border:1px solid #ddd; border-top:0px none}
.noDataFound{display: flex; width: 100%; align-items: center; justify-content: center; color: #000;background-color:var(--primary-rgba); padding:30px;}
.BackArrow{display: flex; align-items: center; margin-bottom: 15px;}
.BackArrow a{align-items: center; display: flex; color:#000}
.BackArrow a img{max-width: 20px; margin-right: 10px;}


.educationWrapper{display: flex; flex-direction: column; padding-bottom: 25px; margin-bottom: 25px; font-family: Lato; border-bottom: 1px solid #ddd;}
.educationWrapper h3{ font-size: 18px; color: #000; margin:0 0 20px; display: flex; justify-content: space-between; align-items: center;}
.educationWrapper h3 div {color:var(--primary); font-size:17px; text-decoration: underline; cursor: pointer;}
.educationWrapper h3 div:hover{color:var(--primary); font-size:17px; text-decoration: none;}
.educationWrapper ol{display: flex;  position:relative; flex-wrap: wrap; width:100%; margin:0; padding:0;}
.educationWrapper ol li{display: flex;  position:relative; flex-direction: column; width:100%;}
.educationWrapper ol li:last-child .timelineItem{margin-bottom: 0;}
.educationWrapper ol li.thumb-content{display: flex; flex-direction: column; width: 100%; padding:0 10px 0px;}
.educationWrapper ol li .thumb{overflow: hidden; max-height: 233px;}
.educationWrapper ol li .title{display: flex;  color:#000; margin-bottom:5px; font-size:17px; font-family:var(--fontfamilybold);}
.educationWrapper ol li .subtitle{display: flex; color:#656565;  margin-bottom:0px; font-size:15px;}
.educationWrapper ol li  .timelineItem{display: flex; position: relative; width: 100%; margin-bottom: 25px; margin-left:0px; position: relative;}
.educationWrapper ol li  .timelineItem .actions{position: absolute; right:10px; top:10px; z-index: 1}
.educationWrapper ol li  .timelineItem .actions a{margin-left: 15px;}
.educationWrapper ol li  .timelineItem .actions a:hover{color:var(--color15);text-decoration: underline;}
.educationWrapper ol li  .timelineItem .actions a i{margin-right:5px;}
.educationWrapper ol li  .timelineItem .actions a:first-child{margin-left:0px;}
.educationWrapper ol li  .timelineItem .timelineImg{width: 60px; height: 60px; display: flex; align-items: center; justify-content: center; color:#fff; font-size: 22px; background-color: var(--whitecolor); border:2px dashed var(--primary); border-radius: 50%;  position: static; margin-top:0px;}
.educationWrapper ol li  .timelineItem .timelineImg img{width: 40px; height: 40px; border-radius: 50%;  }
.educationWrapper ol li  .timelineItem .timelineType{display: flex; border-bottom:0px dashed #ddd; width: 100%; margin: 0 0 12px;}
.educationWrapper ol li  .timelineItem .timelineType span{background-color:var(--primary); background-color: #e7e7e7; font-weight: 700; color:#000; border-radius: 5px; padding:4px 10px; font-family: var(--fontfamilybold); }
.educationWrapper ol li .timelineItem .timelineType span span{margin:0 8px; padding:0}
.educationWrapper ol li  .timelineItem .timelineContent{position: relative; flex:1 1;  display: flex; align-items: flex-start; justify-content: flex-start; margin-left:0px;}
.educationWrapper ol li  .timelineItem .timelineInner{position: relative; float:none; width: 100%; background-color: #fff;}
.educationWrapper ol li  .timelineItem .timelineDate{position: absolute; top:17px; left:-150px; font-family: var(--fontfamilybold); color: var(--blackcolor);}
.educationWrapper ol li  .timelineItem .timelineImgHeader .timelineTitle{position: absolute; bottom:5px; left:10px; font-size: 18px; color:var(--whitecolor); font-family: var(--fontfamilybold);}
.educationWrapper ol li  .timelineItem .timelineContent .timelineDesc{display:flex; color: var(--color17); margin-bottom:0px; font-size: 14px; font-family: var(--defaultfont);}
.educationWrapper ol li  .timelineItem .item{display: flex; transition: all 0.2s ease; position: relative; background-color: #fff; box-shadow: 0px 2px 4px 0px rgba(29, 37, 46, 0.1); border-radius:5px; padding:0px; flex-direction: row; padding: 15px;  margin:25px 25px 0px 0; width:100%;}
.educationWrapper ol li  .timelineItem .item:first-child{margin-top: 0}
.educationWrapper ol li  .timelineItem .item a{display:flex; align-items: flex-start; color:var(--blackcolor);}
.educationWrapper ol li  .timelineItem .item .thumb{display: flex; flex-shrink: 0;  margin-right: 15px; position: relative; width: 100%; max-width: 250px; overflow: hidden;}
.educationWrapper ol li  .timelineItem .item .thumb .overlay{position: absolute; cursor: pointer; top:0; right:0;    color: var(--primary); bottom:0; left:0; font-size: 25px; color: var(--color15); opacity: 0; visibility:hidden; background-color: rgba(0,0,0,0.6); display: flex; align-items: center; justify-content: center;}
.educationWrapper ol li  .timelineItem .item .thumb .overlay i{color: var(--primary);}
.educationWrapper ol li  .timelineItem .item:hover .overlay{ opacity: 1; visibility: visible; }
.educationWrapper ol li  .timelineItem .item .thumb-content{display: flex; width: 100%; flex-direction: column;}
.educationWrapper ol li  .timelineItem .item .thumb img{max-width: 100%; width: 100%; height:auto;}
.educationWrapper ol li  .timelineItem .actions{display: flex; justify-content: flex-end; align-items: center;}
.educationWrapper ol li  .timelineItem .actions a{color: var(--primary);}
.educationWrapper ol li  .timelineItem .actions a:hover{color: var(--primary);}
.educationWrapper ol li  .timelineItem .actions a i{margin-right: 5px;}
.educationWrapper ol li  .timelineItem .item .details{display: flex; flex-direction: column; background-color: #fff; width: 100%; padding:0px 0;}
.educationWrapper ol li  .timelineItem .item .details .institute-info{display: flex;  color:#000; margin-bottom:10px; font-size:14px; font-family:var(--defaultfont); align-items: center;}
.educationWrapper ol li  .timelineItem .item .details .institute-info i{margin-right: 10px; padding: 2px; border: 1px solid #ddd; box-shadow: 0 0 5px var(--color18); width:30px; height:30px; border-radius: 100%;}
.educationWrapper ol li  .timelineItem .item .details .title{display: flex;  color:#000; margin-bottom:5px; font-size:18px; font-family:var(--fontfamilybold);}
.educationWrapper ol li  .timelineItem .item .details .title i{display: flex; color:var(--color1); margin-top:3px; margin-right:15px; font-size:22px;}
.educationWrapper ol li  .timelineItem .item .details .desc{display: flex; color:#656565;  margin-bottom:0px; font-size:15px;}
.educationWrapper ol li  .timelineItem .item .details .institute{display: flex; color:#656565;  margin-top:10px; font-size:15px;}
.educationWrapper ol li  .timelineItem .item .details .institute b{margin-right: 10px;}
.educationWrapper ol li  .timelineItem .item .details .subjects{display: flex; color:#656565;  margin-bottom:3px; margin-top: 5px; font-size:13px;}
.educationWrapper ol li  .timelineItem .item .details .subjects span{margin-right: 5px; color: var(--color15);}
.educationWrapper ol li  .timelineItem .item .details .subjects span:last-child{margin-right: 0}
.educationWrapper ol li  .timelineItem .item .details .skills span:last-child{margin-right: 0}
.educationWrapper ol li  .timelineItem .item .detail-top{display: flex; justify-content: space-between; width: 100%;}
.educationWrapper ol li  .timelineItem .item .detail-top .subcategory-block{display: flex; margin-bottom: 5px;}
.educationWrapper ol li  .timelineItem .item .detail-top .subcategory-block span{display: flex;  border-radius: 4px; background-color: var(--color3);  color: var(--color1); text-transform: uppercase;  font-family: var(--fontfamilybold); font-size: 10px; padding: 3px 5px;}
.educationWrapper ol li  .timelineItem .item .details .skills span{margin-right: 5px; color: var(--color15);}
.educationWrapper ol li  .timelineItem .item .details .skills{display: flex; color:#656565; margin-top: 5px; margin-bottom:3px; font-size:13px;}
.educationWrapper ol li  .timelineItem .item .details .student-block{display: flex; align-items: center; color: var(--blackcolor); margin-top: 5px; margin-bottom:0px; font-size:13px;}
.educationWrapper ol li  .timelineItem .item .details .tagline-block{display: flex; align-items: center; color: var(--color4); margin-top: 5px; margin-bottom:0px; font-size:14px;}
.educationWrapper ol li  .timelineItem .item .details .locations{display: flex; align-items: center; color: var(--blackcolor); margin-top: 5px; margin-bottom:0px; font-size:14px;}
.educationWrapper ol li  .timelineItem .item .details .locations i{margin-right: 5px; margin-top: 5px;}
.educationWrapper ol li  .timelineItem .item .details .status{display: flex; align-items: center; color: var(--color15); margin-top: 5px; margin-bottom:0px; font-size:14px;}
.educationWrapper ol li  .timelineItem .item .details .status i{margin-right: 5px; margin-top: 5px;}
.educationWrapper ol li  .timelineItem .item .details .type{display: flex; align-items: center; color: var(--blackcolor); margin-top: 5px; margin-bottom:0px; font-size:14px;}
.educationWrapper ol li  .timelineItem .mentorItem{display: flex; transition: all 0.2s ease; position: relative; width:100%;}
.educationWrapper ol li  .timelineItem .mentorItem .thumb-content{display: flex; width: 100%; flex-direction: column; text-align: center; align-items: center; justify-content: center;}
.educationWrapper ol li  .timelineItem .mentorItem a{display: flex; transition: all 0.2s ease; position: relative; box-shadow: 0px 2px 4px 0px rgba(29, 37, 46, 0.1); border-radius:5px; padding:0px; flex-direction: column; padding:15px; align-items: center;  background-color: #fff;  margin:0 25px 0px 0;}
.educationWrapper ol li  .timelineItem .mentorItem a{display: flex; align-items: center; justify-content: center; flex-direction: column;}
.educationWrapper ol li  .timelineItem .mentorItem .mentorThumb{margin-bottom: 10px; display: flex; align-items: center; justify-content: center; border-radius: 100%; width: 100px; height: 100px; position: relative; color: var(--color15); border: 1px dashed var(--color15)}
.educationWrapper ol li  .timelineItem .mentorItem .mentorThumb .badge{width:30px; height:30px; background-color: #fff;border: 1px solid #ddd; box-shadow: 0 0 5px var(--color18); border-radius:100%; position: absolute; right:0; bottom:0;}
.educationWrapper ol li  .timelineItem .mentorItem .mentorThumb .badge img{border-radius:100%; width: 20px; height:20px; margin:5px; max-width: 100%;}
.educationWrapper ol li  .timelineItem .mentorItem .mentorTitle{display: flex; color:#000; text-align: center; margin-bottom:0px; font-size:15px; font-family:var(--fontfamilybold);}
.educationWrapper ol li  .timelineItem .mentorItem .mentorDesc{display: flex; text-align: center; color:var(--color25); margin-bottom:0px; font-size:16px; font-family:var(--defaultfont);}
.educationWrapper ol li  .timelineItem .item .enrollnow-block{display: flex; align-items: center; color: var(--blackcolor); font-size:14px; width:100%; justify-content: flex-end;}
.educationWrapper ol li  .timelineItem .item .enrollnow-block i{margin-left:5px; margin-top: 6px;}
.educationWrapper ol li  .timelineItem .item .details .type i{margin-right: 5px; margin-top: 7px; color: var(--color15)}
.educationWrapper ol li  .timelineItem .item .status{display: flex; color:var(--color15); padding:0 0px;  margin-bottom:0px; background-color: var(--whitecolor); font-size:14px; ;}
.educationWrapper ol li  .timelineItem .item .footer-content{display: flex; justify-content: space-between; align-items: center; width: 100%; background-color: #fff; border-top:1px solid #ddd; padding:10px 0px 0;}
.educationWrapper ol li  .timelineItem .item .footer-left{color:var(--blackcolor); font-size:16px; font-family:var(--fontfamilybold)}
.educationWrapper ol li  .timelineItem .item .footer-right{border-radius:15px; padding:5px 15px; background-color:var(--color15); color:var(--blackcolor); font-family:var(--defaultfont); font-size:14px;}
.educationWrapper ol li  .timelineItem .item:hover{ box-shadow: 4px 6px 10px -2px #808080;}


.portfolioWrapper{display: flex; flex-direction: column; padding-bottom:0px; margin-bottom:0px; font-family: Lato; border-bottom:0px solid #ddd;}
.portfolioWrapper h3{ font-size: 18px; color: #000; margin:0 0 20px}
.portfolioWrapper ol{display: flex; width: 100%; flex-wrap: wrap;}
.portfolioWrapper ol li{display: flex; position: relative; transition: all 0.2s ease; flex-direction: column; position: relative; background-color: #fff; max-width: 295px; box-shadow: 0px 2px 4px 0px rgba(29, 37, 46, 25%); border-radius:5px; padding:0px; padding:0px;  margin:25px 25px 0px 0; width:100%;}

.portfolioWrapper ol li:hover .actions{visibility: visible; opacity: 1;}
.portfolioWrapper ol li .actions {position: absolute; z-index: 2; opacity: 0; visibility: hidden; display: flex; align-items: center; top:10px; font-size: 14px; right:10px;}
.portfolioWrapper ol li .actions a{color:var(--primary); margin-left: 15px;}
.portfolioWrapper ol li .actions a:hover{color:var(--primary);}
.portfolioWrapper ol li .actions a:first-child{margin-left: 0;}
.portfolioWrapper ol li .actions  i{margin-right: 5px;}
.portfolioWrapper ol li .thumb-content{display: flex; flex-direction: column; width: 100%; padding:10px;}
.portfolioWrapper ol li .thumb{overflow: hidden; position: relative; min-height: 170px; max-height: 170px; margin:0 auto;}
.portfolioWrapper ol li .thumb img{max-width: 100%; width:auto; height: 100%;}
.portfolioWrapper ol li .thumb a{ position: relative; width: 100%; height:100%; justify-content: center; display: flex; }
.portfolioWrapper ol li .thumb .overlay{position: absolute; cursor: pointer; top:0; right:0; bottom:0; left:0; font-size: 25px; color: var(--color15); opacity: 0; visibility:hidden; background-color: rgba(0,0,0,0.6); display: flex; align-items: center; justify-content: center;}
.portfolioWrapper ol li:hover .overlay{ opacity: 1; visibility: visible; }
.portfolioWrapper ol li:hover .overlay i{color: var(--primary);}
.portfolioWrapper ol li .title{display: flex;  color:#000; margin-bottom:10px; font-size:17px; font-family:var(--fontfamilybold);}
.portfolioWrapper ol li .subtitle{display: flex; color:#656565;  margin-bottom:0px; font-size:15px;}
.portfolioWrapper ol li .urllink{display: flex; align-items: center; color:#656565;  margin-bottom:0px; font-size:15px;}
.portfolioWrapper ol li .urllink a{display: flex; color:var(--color15);}
.portfolioWrapper ol li .urllink a:hover{color:var(--color15);}
.portfolioWrapper ol li .urllink i{margin-right: 5px; margin-top: 8px;}
.portfolioWrapper ol li.nocontentfound{max-width: none; box-shadow: 0 0 0 0; border-radius: 0; justify-content: center; align-items: center; display: flex; width: 100%; border:1px solid #ddd; padding:30px; background-color: #ededed; color:#999; font-size: 19px;}
.portfolioWrapper ol li.nocontentfound .addcontentbtn{display:flex; justify-content: center; margin-top: 15px; width: 100%;}
.portfolioWrapper ol li.nocontentfound .addPortfolio-btn{padding: 5px 15px; white-space: nowrap; border: 0px none;  background-color: var(--color15); border-radius: var(--radius5); cursor: pointer; font-size: var(--fontsize16);  font-family: var(--defaultfont);  color: var(--blackcolor);}
.portfolioWrapper ol li.nocontentfound .addPortfolio-btn i{margin-right: 5px;}

.skillsWrapper{display: flex; flex-direction: column; padding-bottom:0px; margin-bottom:0px; font-family: Lato; border-bottom:0px solid #ddd;}
.skillsWrapper ol{display: flex; width: 100%; flex-wrap: wrap;}
.skillsWrapper  ol li{display: flex; position: relative; box-shadow: 0px 2px 4px 0px rgb(29 37 46 / 20%); border-radius:5px;  border: 1px solid transparent; align-items: center; transition: all 0.2s ease; max-width:45%;   position: relative; background-color: #fff; padding:10px;  margin:15px 15px 15px 15px; width:100%;}
.skillsWrapper  ol li:hover{border: 1px solid #ddd;}
.skillsWrapper  ol li .actions {position: absolute; display: flex; align-items: center; top:10px; font-size: 14px; right:10px;}
.skillsWrapper  ol li .actions a{color:var(--color15); margin-left: 15px;}
.skillsWrapper  ol li .actions a:hover{color:var(--color15); text-decoration: underline;}
.skillsWrapper  ol li .actions a:first-child{margin-left: 0;}
.skillsWrapper  ol li .actions  i{margin-right: 5px;}
.skillsWrapper  ol li .skillscontent{display: flex; flex-direction: column; width: 100%; padding:0px;}
.skillsWrapper  ol li .skillsthumb{overflow: hidden; min-width: 100px; max-width: 100px; display: flex; margin-right: 15px; border:1px solid #ddd;}
.skillsWrapper  ol li .title{display: flex; min-width: inherit;  color:#000; margin-bottom:8px; font-size:17px; font-family:var(--fontfamilybold);}
.skillsWrapper  ol li .subtitle{display: flex; color:#656565;  margin-bottom:0px; font-size:15px;}
.skillsWrapper  ol li.nocontentfound{max-width: none; flex-direction: column; justify-content: center; box-shadow: 0 0 0 0; border-radius: 0;display: flex; width: 100%; border:1px solid #ddd; padding:30px; background-color: #ededed; color:#999; font-size: 19px;}
.skillsWrapper  ol li.nocontentfound .addcontentbtn{display:flex; justify-content: center; margin-top: 15px; width: 100%;}
.skillsWrapper  ol li.nocontentfound .addSkills-btn{padding: 5px 15px; white-space: nowrap; border: 0px none;  background-color: var(--color15); border-radius: var(--radius5); cursor: pointer; font-size: var(--fontsize16);  font-family: var(--defaultfont);  color: var(--blackcolor);}
.skillsWrapper  ol li.nocontentfound .addSkills-btn i{margin-right: 5px;}
.skillsWrapper  ol li .progressBlock .title{display: flex;  color:var(--color1); margin-bottom:5px; font-size:14px; font-family:var(--defaultfont); white-space: nowrap; margin-right:0px;}
.skillsWrapper  ol li .progressBlock{display: flex; width:auto; flex:1 1; flex-direction: column;}
.skillsWrapper  ol li .progress{ width: 100%; height: 10px; margin-bottom:0px; overflow: hidden; background-color: #f5f5f5;  border-radius: 4px; box-shadow: inset 0 1px 2px rgba(0,0,0,.1);}
.skillsWrapper  ol li .progress .progress-bar {float: left; width: 0%; height: 100%; font-size: 12px; line-height: 20px; color: #fff; text-align: center; background-color: var(--primary);  box-shadow: inset 0 -1px 0 rgba(0,0,0,.15); transition: width .6s ease;}

.downloadResume{display: flex; justify-content: flex-end; margin-bottom: 10px; margin-right: 10px;}
.downloadResume a{color:var(--primary); text-decoration: underline;}
.downloadResume a i{margin-right: 5px;}
.downloadResume a:hover{color:var(--primary); text-decoration: none;}

.previewDialog.open{display: flex;}
.previewDialog{position: fixed; top:0; right:0; bottom:0; font-family: Lato; left:0; display: flex; z-index: 220000509; display: none; align-items: center; justify-content:center; background-color: rgba(0,0,0.8);}
.previewDialog .previewContainer{position: relative; max-width: 500px; width:100%; background-color: #fff; padding:10px; border-radius:5px; display: flex; flex-direction: column;}
.previewDialog .previewContainer .previewCloseLink{position: absolute; cursor: pointer; font-family: Lato; font-size: 20px; width:35px; background-color: #fff; font-weight: 700;  height: 35px; border-radius:100%; display: flex; align-items: center; justify-content: center; right:-10px; top:-10px; color:#000}
.previewDialog .previewThumb{display: flex; width: 100%; justify-content: center;}
.previewDialog .previewThumb img{max-width: 100%;}


.addEditExperienceDialog.open{display: flex;}
.addEditExperienceDialog{position: fixed; top:0; font-family: Lato; right:0; bottom:0; left:0; display: flex;  display: none; z-index: 220000509; align-items: center; justify-content:center; background-color: rgba(0,0,0.8);}
.addEditExperienceDialog .addEditContainer{position: relative; max-width: 650px; width:100%; background-color: #fff; padding:10px; border-radius:5px; display: flex; flex-direction: column;}
.addEditExperienceDialog .addEditContainer h3{border-bottom:2px solid var(--primary); margin-bottom: 10px; padding-bottom: 10px; font-size: 20px;}
.addEditExperienceDialog .addEditContainer .addEditCloseLink{position: absolute; cursor: pointer; font-family: Lato; font-size: 20px; width:35px; background-color: #fff; font-weight: 700;  height: 35px; border-radius:100%; display: flex; align-items: center; justify-content: center; right:-10px; top:-10px; color:#000}
.addEditExperienceDialog .addEditContainer .formWrapper{display: flex; width: 100%;}
.addEditExperienceDialog .addEditContainer .formWrapper .formBlock{display: flex; width: 100%; flex-direction: column; margin:8px 8px 15px;}
.addEditExperienceDialog .addEditContainer .formWrapper label{padding:0; margin:0; margin-bottom: 5px; font-size: 14px; font-weight: 700;}
.addEditExperienceDialog .addEditContainer .formWrapper label span{color:#ff0000; margin-left: 5px;}
.addEditExperienceDialog .addEditContainer .formWrapper .formBlock input[type="text"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.addEditExperienceDialog .addEditContainer .formWrapper .formBlock input[type="date"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.addEditExperienceDialog .addEditContainer .formWrapper .formBlock input[type="file"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.addEditExperienceDialog .addEditContainer .formWrapper .formBlock select{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:5px 10px;}
.addEditExperienceDialog .addEditContainer .formWrapper .noteText{font-size:12px; color:#666}
.addEditExperienceDialog .formActions{display: flex; align-items: center; justify-content: center; width:100%; border-top:1px solid #ddd; padding-top: 10px; margin-top: 10px;}
.addEditExperienceDialog .formActions a{background-color: var(--primary); padding:10px 20px; color:#fff; border-radius:5px;}
.addEditExperienceDialog .formActions button{background-color: var(--primary); padding:10px 20px; color:#fff; border-radius:5px; opacity:1;}
.addEditExperienceDialog .formActions button:disabled{background-color: var(--primary); padding:10px 20px; color:#fff; border-radius:5px; opacity: 0.3;}


.portfolioDialog.open{display: flex;}
.portfolioDialog{position: fixed; top:0; font-family: Lato; right:0; bottom:0; left:0; display: flex; display: none; z-index: 220000509; align-items: center; justify-content:center; background-color: rgba(0,0,0.8);}
.portfolioDialog .portfolioContainer{position: relative; max-width: 650px; width:100%; background-color: #fff; padding:10px; border-radius:5px; display: flex; flex-direction: column;}
.portfolioDialog .portfolioContainer h3{border-bottom:2px solid var(--primary); margin-bottom: 10px; padding-bottom: 10px; font-size: 20px;}
.portfolioDialog .portfolioContainer .portfolioCloseLink{position: absolute; cursor: pointer; font-family: Lato; font-size: 20px; width:35px; background-color: #fff; font-weight: 700;  height: 35px; border-radius:100%; display: flex; align-items: center; justify-content: center; right:-10px; top:-10px; color:#000}
.portfolioDialog .portfolioContainer .formWrapper{display: flex; width: 100%; flex-direction: column;}
.portfolioDialog .portfolioContainer .formWrapper .formBlock{display: flex; width: 100%; flex-direction: column;  margin: 8px 0;}
.portfolioDialog .portfolioContainer .formWrapper label{padding:0; margin:0; margin-bottom: 5px; font-size: 14px; font-weight: 700;}
.portfolioDialog .portfolioContainer .formWrapper .formBlock input[type="text"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.portfolioDialog .portfolioContainer .formWrapper .formBlock input[type="date"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.portfolioDialog .portfolioContainer .formWrapper .formBlock input[type="file"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.portfolioDialog .portfolioContainer .formWrapper .formBlock select{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:5px 10px;}
.portfolioDialog .portfolioContainer .formWrapper .noteText{font-size:12px; color:#666}
.portfolioDialog .formActions{display: flex; align-items: center; justify-content: center; width:100%; border-top:1px solid #ddd; padding-top: 10px; margin-top: 10px;}
.portfolioDialog .formActions a{background-color: var(--primary); padding:10px 20px; color:#fff; border-radius:5px;}


.addEditEducationDialog.open{display: flex;}
.addEditEducationDialog{position: fixed; top:0; font-family: Lato; right:0; bottom:0; left:0; display: flex;  display: none; z-index: 220000509; align-items: center; justify-content:center; background-color: rgba(0,0,0.8);}
.addEditEducationDialog .addEditContainer{position: relative; max-width: 650px; width:100%; background-color: #fff; padding:10px; border-radius:5px; display: flex; flex-direction: column;}
.addEditEducationDialog .addEditContainer h3{border-bottom:2px solid var(--primary); margin-bottom: 10px; padding-bottom: 10px; font-size: 20px;}
.addEditEducationDialog .addEditContainer .addEditCloseLink{position: absolute; cursor: pointer; font-family: Lato; font-size: 20px; width:35px; background-color: #fff; font-weight: 700;  height: 35px; border-radius:100%; display: flex; align-items: center; justify-content: center; right:-10px; top:-10px; color:#000}
.addEditEducationDialog .addEditContainer .formWrapper{display: flex; width: 100%;}
.addEditEducationDialog .addEditContainer .formWrapper .formBlock{display: flex; width: 100%; flex-direction: column; margin:8px 8px 15px;}
.addEditEducationDialog .addEditContainer .formWrapper label{padding:0; margin:0; margin-bottom: 5px; font-size: 14px; font-weight: 700;}
.addEditEducationDialog .addEditContainer .formWrapper .formBlock input[type="text"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.addEditEducationDialog .addEditContainer .formWrapper .formBlock input[type="date"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.addEditEducationDialog .addEditContainer .formWrapper .formBlock input[type="file"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.addEditEducationDialog .addEditContainer .formWrapper .formBlock select{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:5px 10px;}
.addEditEducationDialog .addEditContainer .formWrapper .noteText{font-size:12px; color:#666}
.addEditEducationDialog .formActions{display: flex; align-items: center; justify-content: center; width:100%; border-top:1px solid #ddd; padding-top: 10px; margin-top: 10px;}
.addEditEducationDialog .formActions a{background-color: var(--primary); padding:10px 20px; color:#fff; border-radius:5px;}

.yearsofExperienceWrapper{display: flex; width: 100%; flex-direction: column;}
.yearsofExperienceWrapper ol li .actions {justify-content: flex-end;  flex-direction: row; display: flex; align-items: center; margin-bottom: 15px; top:10px; font-size: 15px; font-weight: 400; right:10px;}
.yearsofExperienceWrapper ol li .actions a{color:var(--primary); margin-left: 15px;}
.yearsofExperienceWrapper ol li .actions a i{margin-right: 5px;}
.yearsofExperienceWrapper ol li .actions a:hover{color:var(--primary); text-decoration: underline;}
.yearsofExperienceWrapper .addLink {color:var(--primary); font-size:17px; margin-bottom: 20px; font-weight: 700; display: flex; justify-content: flex-end; text-decoration: underline; cursor: pointer;}
.yearsofExperienceWrapper .addLink:hover{color:var(--primary); font-size:17px; text-decoration: none;}
.yearsofExperienceWrapper ol{display: flex; width: 100%; margin:0; padding:0;}
.yearsofExperienceWrapper ol li{display: flex; width: 100%; margin-top: 10px; flex-direction: column; justify-content: space-between; align-items: center; box-shadow: 0px 2px 4px 0px rgba(29, 37, 46, 0.1); border-radius:5px; padding:10px;}
.yearsofExperienceWrapper ol li:first-child{margin-top: 0;}
.yearsofExperienceWrapper ol li div{display: flex; width: 100%; flex-direction: column; font-weight: 700;}
.yearsofExperienceWrapper ol li div span{margin-top: 12px; font-weight: 400; color:#999;}

.languagePreferredWrapper{display: flex; width: 100%; flex-direction: column;}
.languagePreferredWrapper ol li .actions { justify-content: flex-end; display: flex;  flex-direction: row; margin-bottom: 15px; align-items: center; top:10px; font-size: 15px; font-weight: 400; right:10px;}
.languagePreferredWrapper ol li .actions a{color:var(--primary); margin-left: 15px;}
.languagePreferredWrapper ol li .actions a i{margin-right: 5px;}
.languagePreferredWrapper ol li .actions a:hover{color:var(--primary);text-decoration: underline;}
.languagePreferredWrapper .addLink {color:var(--primary); font-size:17px; margin-bottom: 20px; font-weight: 700;  display: flex;  justify-content: flex-end; text-decoration: underline; cursor: pointer;}
.languagePreferredWrapper .addLink:hover{color:var(--primary); font-size:17px; text-decoration: none;}
.languagePreferredWrapper ol{display: flex; width: 100%; margin:0; padding:0;}
.languagePreferredWrapper ol li{display: flex; width: 100%; flex-direction: column; position: relative; margin-top: 10px; justify-content: space-between; align-items: center; box-shadow: 0px 2px 4px 0px rgba(29, 37, 46, 0.1); border-radius:5px; padding:10px;}
.languagePreferredWrapper ol li:first-child{margin-top: 0;}
.languagePreferredWrapper ol li div{display: flex; width: 100%; flex-direction: column; font-weight: 700;}
.languagePreferredWrapper ol li div span{margin-top: 12px; font-weight: 400; color:#999;}


.addEditDialog.open{display: flex;}
.addEditDialog{position: fixed; top:0; font-family: Lato; right:0; bottom:0; left:0; display: flex;  display: none; z-index: 220000509; align-items: center; justify-content:center; background-color: rgba(0,0,0.8);}
.addEditDialog .addEditContainer{position: relative; max-width: 650px; width:100%; background-color: #fff; padding:10px; border-radius:5px; display: flex; flex-direction: column;}
.addEditDialog .addEditContainer h3{border-bottom:2px solid var(--primary); margin-bottom: 10px; padding-bottom: 10px; font-size: 20px;}
.addEditDialog .addEditContainer .addEditCloseLink{position: absolute; cursor: pointer; font-family: Lato; font-size: 20px; width:35px; background-color: #fff; font-weight: 700;  height: 35px; border-radius:100%; display: flex; align-items: center; justify-content: center; right:-10px; top:-10px; color:#000}
.addEditDialog .addEditContainer .formWrapper{display: flex; width: 100%;}
.addEditDialog .addEditContainer .formWrapper .formBlock{display: flex; width: 100%; flex-direction: column; margin:8px 8px 15px;}
.addEditDialog .addEditContainer .formWrapper label{padding:0; margin:0; margin-bottom: 5px; font-size: 14px; font-weight: 700;}
.addEditDialog .addEditContainer .formWrapper .formBlock input[type="text"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.addEditDialog .addEditContainer .formWrapper .formBlock input[type="date"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.addEditDialog .addEditContainer .formWrapper .formBlock input[type="file"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.addEditDialog .addEditContainer .formWrapper .formBlock select{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:5px 10px;}
.addEditDialog .addEditContainer .formWrapper .noteText{font-size:12px; color:#666}
.addEditDialog .formActions{display: flex; align-items: center; justify-content: center; width:100%; border-top:1px solid #ddd; padding-top: 10px; margin-top: 10px;}
.addEditDialog .formActions a{background-color: var(--primary); padding:10px 20px; color:#fff; border-radius:5px;}


.addEditLocationDialog.open{display: flex;}
.addEditLocationDialog{position: fixed; top:0; font-family: Lato; right:0; bottom:0; left:0; display: flex;  display: none; z-index: 220000509; align-items: center; justify-content:center; background-color: rgba(0,0,0.8);}
.addEditLocationDialog .addEditContainer{position: relative; max-width: 650px; width:100%; background-color: #fff; padding:10px; border-radius:5px; display: flex; flex-direction: column;}
.addEditLocationDialog .addEditContainer h3{border-bottom:2px solid var(--primary); margin-bottom: 10px; padding-bottom: 10px; font-size: 20px;}
.addEditLocationDialog .addEditContainer .addEditCloseLink{position: absolute; cursor: pointer; font-family: Lato; font-size: 20px; width:35px; background-color: #fff; font-weight: 700;  height: 35px; border-radius:100%; display: flex; align-items: center; justify-content: center; right:-10px; top:-10px; color:#000}
.addEditLocationDialog .addEditContainer .formWrapper{display: flex; width: 100%;}
.addEditLocationDialog .addEditContainer .formWrapper .formBlock{display: flex; width: 100%; flex-direction: column; margin:8px 8px 15px;}
.addEditLocationDialog .addEditContainer .formWrapper label{padding:0; margin:0; margin-bottom: 5px; font-size: 14px; font-weight: 700;}
.addEditLocationDialog .addEditContainer .formWrapper .formBlock input[type="text"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.addEditLocationDialog .addEditContainer .formWrapper .formBlock input[type="date"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.addEditLocationDialog .addEditContainer .formWrapper .formBlock input[type="file"]{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:8px 10px;}
.addEditLocationDialog .addEditContainer .formWrapper .formBlock select{display: flex; font-family: Lato; width: 100%; border:1px solid #ddd; border-radius:5px; padding:5px 10px;}
.addEditLocationDialog .addEditContainer .formWrapper .noteText{font-size:12px; color:#666}
.addEditLocationDialog .formActions{display: flex; align-items: center; justify-content: center; width:100%; border-top:1px solid #ddd; padding-top: 10px; margin-top: 10px;}
.addEditLocationDialog .formActions a{background-color: var(--primary); padding:10px 20px; color:#fff; border-radius:5px;}

@media screen and (max-width:1400px) {
    .batchHistoryWrapper .batchHistoryContainer .tabsBlock div{font-size: 13px;}
}
@media screen and (max-width:1300px) {
    .batchHistoryWrapper .batchHistoryContainer .tabsBlock div{font-size: 12px;}
}
    
.img-prev-subject{
	width: -webkit-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: max-content;
	padding: 8px 20px;
	cursor: pointer;
	background-color: var(--secondary-rgba);
	border-radius: 6px;
	display: flex;
	align-items: center;
	margin-left: 0px;
    margin-right: 0px;
}

.img-prev-subject span {
  color: var(--primary);
  font-size: 22px;
  margin-right: 16px !important;
  margin-left: 0 !important;
}

.img-prev-subject:hover {
  opacity: 0.8;
}

.img-prev-subject img {
  max-height: 50px;
  max-width: 100px;
}

#no-data-admin{
	/* text-align: center; */
	width: 100%;
	display: flex;
	/* align-items: center; */
	justify-content: center;
	font-family: Lato;
	font-weight: 700;
	font-size: 25px;
	/* padding-top: 150px; */
	color: var(--text-black2);
}
.institute-div {
  width: 100%;
  height: calc(100% - 150px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Source Sans Pro;
}

.institute-div img {
  width: 150px;
  margin-top: 30px;
  margin-bottom: 0px;
  cursor: pointer;
}

.institute-img {
  width: 400px;
  min-height: 140px;
  border-radius: 0%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: contain;
  margin-top: 30px;
  margin-bottom: 0px;
  box-shadow: 2px 2px 6px 0px #eeeeee;
  box-shadow: 0px 0px 0px 0px #eeeeee;
}

#congo-span {
  font-size: 33px;
  font-weight: 700;
  color: var(--primary);
  margin-bottom: 15px;
}

.institute-div span {
  font-size: 27px;
  font-weight: 700;
  color: var(--black);
}

.institute-div p {
  font-size: 20px;
  font-weight: 400;
  color: var(--text-black);
  text-align: center;
  letter-spacing: 0.01em;
  line-height: 1.5;
}

.heading-part2 {
  position: relative;
}

.button {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 1;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.button-img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

/* Container styles */
.container {
  position: relative;
  width: 100%;
  /* or any specific width */
  height: 100%;
  /* or any specific height */
}

/* Button styles */
.top-right-button {
  position: relative;
  top: 0;
  right: 0;
  margin: 10px;
  /* Adjust the margin as needed */
}

/* Add to styles.css or the appropriate stylesheet */
.popup-content {
  max-height: 100%;
  overflow-y: auto;

  width: 100%;
}

.input-container {
  /* display: flex; */
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.input-field {
  flex: 1 1 30%;
  /* Adjust based on how many fields per row */
  min-width: 100px;
  /* Minimum width for responsive design */
  margin-bottom: 10px;
}

/* .row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
} */

label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-container {
  /* display: inline-flex; */
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  /* Adjust the gap as needed */
}

.input-container .column {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - 20px);
  box-sizing: border-box;



}

.input-container .checkbox-row {
  display: inline-flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;

}

.input-field {
  width: 100%;
  padding: 8px;
  /* margin-bottom: 10px; */
  box-sizing: border-box;
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
}

.image-preview {
  position: relative;
  margin: 10px;
}

.image-preview img {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.image-preview button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
}
.image-preview-container {
  display: flex;
  flex-direction: column; /* Stack images vertically for single image */
  align-items: center;
}

.image-preview {
  position: relative;
  margin: 10px;
}

.image-preview img {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 150px; /* Adjust as needed */
  height: 150px; /* Adjust as needed */
}

.image-preview button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  font-size: 14px; /* Adjust as needed */
}


/* In your main CSS file or component file */


.admin-div {
    /* padding: 20px; */
    background-color: #f9f9f9;
    /* overflow-y: auto;
    overflow-x: auto; */
    
}

/* .college-info-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
    scroll-behavior: smooth;

} */
.carousel-section {
    margin-bottom: 20px;
}

.carousel-slide {
    padding: 10px;
}

.carousel-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.college-info-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1500px;
    margin: 0 auto;
    max-height: 80vh; /* Adjust this value as needed */
    overflow-y: auto;
    padding: 20px; /* Optional: Add padding for better spacing */
    box-sizing: border-box;
}


.banner-section {
    position: relative;
}

.college-banner {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.college-logo {
    position: absolute;
    /* bottom: -40px; */
    right: 0px;
    top: 40;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.college-details {
    padding: 60px 20px 20px 20px;
    text-align: center;
}

.college-details h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.meta-info {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

.action-buttons {
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 20px;
}
/* .checkbox-row {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
  
  } */

.admin-div .checkbox-row {
    display: inline-flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
  }

.action-buttons button {
    background-color: #fff;
    border: 1px solid#218838;
    color:#218838;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.action-buttons button:hover {
    background-color:#218838;
    color: #fff;
}

.save-btn, .compare-btn, .brochure-btn {
    min-width: 100px;
}

.brochure-btn {
    background-color: #28a745;
    color: white;
    border: none;
}

.brochure-btn:hover {
    background-color: #218838;
}


.fileBlock{display: flex;}
.fileBlock .deleteBTN{margin-left: 10px;}
.addMoreFile{margin-left:10px; display: flex; align-items: center;}
.addMoreFile img{max-width:20px; margin-right:5px;}
.addMoreFile a{color: #ff9901; font-size: 14px; display: flex; align-items: center; text-decoration:underline; font-size: 16px;}
.addMoreFile a:hover{color: #ff9901; font-size: 14px; text-decoration:none; font-size: 16px;}
.addMoreFile span{color: #ff9901; font-size: 14px; display: flex; align-items: center; cursor: pointer; text-decoration:underline; font-size: 16px;}
.addMoreFile span:hover{color: #ff9901; font-size: 14px; text-decoration:none; font-size: 16px;}
.previewFile{margin-left: 10px; display: flex;}
.previewFile img{max-width:20px;}
.previewFile i{cursor: pointer;}


.previewDialogWrapper{display: flex; width: 100%; position: fixed; z-index: 1; top:0; right:0; left:0; bottom:0; display: none;}
.previewDialogWrapper.open{display: flex;}
.previewDialogWrapper .previewDialogContainer{background-color: #fff; display: flex; align-items: center; justify-content:center; width: 100%; height:100%; position: relative;}
.closeDialogLink{position: absolute; right:10px; top:10px; font-size: 30px; font-family: Source Sans Pro;}

.assg-header {
  display: block;
  z-index: 10;
  position: sticky;
	top: 10px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
  background-color: var(--white);
  margin-bottom: 16px;
  padding-bottom: 18px;
}

.assg-header div {
  font-family: Lato;
  font-weight: 700;
  font-size: 18px;
}
.assg-container {
  height: calc(100vh - 250px);
  overflow: auto;
  margin-top: 35px;
  padding-bottom: 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.removeCursor{cursor: default;}
.actions{display: flex; width: 100%; justify-content: center; margin-top:10px;}
.actions span{margin-left: 10px; cursor: pointer;}
.actions span:first-child{margin-left:0px;}
.listing-bottom .list-view .review-content .actions a{font-family: Lato; font-weight: bold; margin-left:10px; color: var(--primary);}
.listing-bottom .list-view .review-content .actions a:first-child{margin-left:0px;}


.assg-head-edit {
    display: flex;
    width: -webkit-max-content;
    width: max-content;
    align-items: baseline;
    justify-content: flex-start;
    width: 100% !important;
    border-bottom: 1px solid rgba(0,0,0,0.14);
}

#assg-titles2 {
  width: 100%;
  font-family: Source Sans Pro;
  font-weight: 600;
  color: var(--text-black);
  font-size: 20px;
  display: inline-block;
  margin-bottom: 10px;
}
.assg-setting-div2 {
  width: 100%;
  /*display: flex;*/
}

.assg-info {
  width: 100%;
  margin-top: 30px;
}

.assg-setting-div {
  width: 100%;
  margin-top: 30px;
  /*background-color: var(--secondary-rgba);*/
  /*padding: 12px 20px;*/
  border-radius: 6px;
  transition: .3s all;
}

.assg-setting-div .asm-setting-option{
  border-bottom: 1px solid rgba(0,0,0,0.14);
  font-weight: 600 !important;
  padding-bottom: 9px !important;
}

.assg-setting-div2 div {
  width: 100%;
}

.assg-difficulty {
  margin-left: 0 !important;
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.assg-difficulty span {
  height: 33px;
  position: static;
  width: -webkit-max-content;
  width: max-content;
  font-size: 15px;
  font-weight: 500;
  /*border: 1px solid var(--primary);*/
  background-color: var(--primary-rgba);
  color: var(--text-black);
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 16px;
  font-family: Montserrat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 34px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.assg-difficulty span:hover {
  opacity: 0.85;
}
.assg-difficulty .easy {
  background-color: #4ce4f3;
  color: #ffffff;
}
.assg-difficulty .medium {
  background-color: #efb953;
  color: #ffffff;
}
.assg-difficulty .hard {
  background-color: #f77b80;
  color: #ffffff;
}
.assg-difficulty .very-hard {
  background-color: #c74f54;
  color: #ffffff;
}

#assg-titles3 {
  font-family: Source Sans Pro;
  font-weight: 600;
  color: var(--text-black);
  font-size: 18px;
  display: inline-block;
  border-bottom: 1px solid rgba(0,0,0,0.14);
  width:100%;
  padding-bottom: 10px;
}

.assg-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.assg-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assg-title input, .assg-title span {
  font-family: Lato;
  font-size: 16px;
  border-radius: 4px;
  width: 400px;
  border: solid 1px var(--primary);
  background-color: var(--secondary-rgba);
  padding: 8px 16px 9px 16px;
}

.assg-title input:first-child {
  width: 65%;
}

.assg-title input:last-child {
 width: 400px !important; 
}

.assg-title input:last-child {
  width: 33.2%;
}

.assg-title select {
  outline: none;
  border: 1px solid var(--primary);
  font-family: Lato;
  font-size: 16px;
  width: 190px;
  color: var(--text-black2);
  background-color: var(--secondary-rgba);
  padding: 7px 10px 8px 10px;
  border-radius: 4px;
}

.assg-title textarea {
    font-family: Lato;
    font-size: 16px;
    border-radius: 4px;
    border: solid 1px var(--primary);
    background-color: var(--primary-rgba);
    padding: 8px 16px 9px 16px;
    width: 894px;
    height: 100px;
}

.assg-header1 {
  display: flex;
  margin-bottom: 40px;
  font-family: Roboto;
}

.assg-header1 div {
  width: 79%;
}

.assg-setting-data {
  margin-top: 18px;
  margin-bottom: 6px;
  width: 100%;
}

#assg-setting-graded {
  display: flex;
  justify-content: space-between;
}

#assg-setting-graded input {
  /*width: 48%;*/
  font-family: Lato;
  font-size: 16px;
  border-radius: 4px;
  background-color: white;
  padding: 8px 16px 9px 16px;
}


.assg-header-select{
  border: 1px solid;
  border-radius: 33px;
  padding: 26px;
  padding-top: 8px;
  padding-bottom: 0px;
  color: var(--primary);
  font-family: Roboto;
}

.header2-content{
  height: 29px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary);
}

.button-header{
  display: flex;
}

.button-header button{
  border-radius: 7px !important;
  margin-right: 12px;
}

.assignment-div {
    position: relative;
    width: 100vw;
    /*height: 100vh;*/
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
}

.basic-details-row{
  margin-right: 88px;
  font-family: Roboto;
}

.basic-details-row div{
  padding-bottom: 5px;
  font-size: 16px;
  color: #4a4545;
}


.height100vh{height:100vh;}
.alignitemstart{align-items:flex-start;}

.assignment-review-wrapper{display: flex; width: 100%; flex-direction: column; position: relative; font-family: Lato; padding:40px; background-color: #fff; min-height:725px;}
.assignment-review-wrapper .assignment-review-container{display: flex; width: 100%; flex-direction: column;}
.assignment-review-wrapper .assignment-review-container .assignment-review-top{display: flex; flex-direction: column; width: 100%; margin-bottom: 20px;}
.assignment-review-wrapper .assignment-review-container .assignment-review-top .assignment-title{font-weight: 700; margin-bottom: 15px; font-family: Lato; font-size: 19px;}
.assignment-review-wrapper .assignment-review-container .assignment-review-top .assignment-title span{background-color:var(--primary-rgba); font-size: 14px; text-transform: uppercase; color: var(--text-black); padding:5px 10px; border-radius:5px;}
.assignment-review-wrapper .assignment-review-container .assignment-review-top p{font-size: 14px; font-family: Lato; -webkit-columns: var(--text-black); columns: var(--text-black);}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom{display: flex; justify-content: space-between; width: 100%; margin-top: 20px;}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-left{display: flex; flex-direction: column;}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-left .hours-block{display: flex; font-size: 14px; margin-bottom: 10px; font-weight: 700; color: var(--primary);}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-left .hours-block i{margin-right: 5px;}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-left .hours-block i img{max-width:15px;}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-left .marks-block{display: flex; align-items: center; margin-bottom: 10px;}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-left .marks-block span{font-size: 14px; margin-left: 5px; font-weight: 700; color: var(--primary);}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-left .passing-block{display: flex; align-items: center;}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-left .passing-block span{font-size: 14px; margin-left: 5px; font-weight: 700; color: var(--primary);}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-right{display: flex; flex-direction: column; align-items: flex-end;}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-right .tag-block{display: flex; margin-bottom: 10px;}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-right .tag-block span{background-color:var(--primary-rgba); font-size: 14px; text-transform: none; color: var(--text-black); padding:5px 10px; border-radius:5px;}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-right .attempts-block{display: flex; margin-bottom: 10px;}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-right .attempts-block span{background-color:var(--primary-rgba); font-size: 14px; text-transform: none; color: var(--text-black); padding:5px 10px; border-radius:5px;}
.assignment-review-wrapper .assignment-review-container .assignment-review-bottom .assignment-bottom-right p{font-size: 14px; margin:0; font-family: Lato; color: var(--primary);}
.assignment-review-wrapper .assignment-review-container .imagepreview{display:flex; position: relative; margin:15px 0 ; width:100%; max-width: 80%; max-width: 50%; margin-right:20px;}
.assignment-review-wrapper .assignment-review-container .imagepreview img{max-width: 100%; object-fit: contain; }
.assignment-review-wrapper .assignment-review-container .imagepreview + div{max-width:none; display: flex; align-items: flex-end; justify-content: flex-end;  width:100%;}
.UploadButton.column{flex-direction: column; align-items: flex-end;}
.assignment-review-wrapper .assignment-review-container .imagepreview span {    
  position: absolute;
  top: 347px;
  top:20px;
  left: 440px;
  left:auto;
  right:20px;
  cursor: pointer;
}
.assignment-review-wrapper .assignment-review-container .imagepreview span img {    
  width: 64%;
}
.assignment-review-wrapper .assignment-review-container .assignment-review-last-bottom{margin-top: 20px; display: flex; justify-content:space-between; font-size: 14px; font-family: Lato; -webkit-columns: var(--text-black); columns: var(--text-black);}
.assignment-review-wrapper .assignment-review-container .assignment-review-last-bottom .assignment-last-bottom-left {display: flex; align-items: center;}
.assignment-review-wrapper .assignment-review-container .assignment-review-last-bottom .assignment-last-bottom-left .submission-block {display: flex; align-items: center;}
.assignment-review-wrapper .assignment-review-container .assignment-review-last-bottom .assignment-last-bottom-left .submission-block span{font-size: 14px; margin-right: 5px; font-weight: 700; color: var(--primary);}
.assignment-review-wrapper .assignment-review-container .assignment-review-last-bottom .assignment-last-bottom-right {display: flex; align-items: center;}
.assignment-review-wrapper .assignment-review-container .assignment-review-last-bottom .assignment-last-bottom-right .grading-block{display: flex; align-items: center;}
.assignment-review-wrapper .assignment-review-container .assignment-review-last-bottom .assignment-last-bottom-right .grading-block{font-size: 12px; padding:10px; border-radius: 100px; border: solid 1px var(--primary); font-weight: 700; color: var(--primary); margin-right: 11px}
.assignment-review-wrapper .assignment-review-container .assignment-review-last-bottom .assignment-last-bottom-right .grading-block i{margin-right: 5px;}
.assignment-review-wrapper .assignment-review-container .assignment-review-last-bottom .assignment-last-bottom-right .grading-block i img{max-width: 15px;}
.assignment-review-listing{display: flex; flex-direction: column; width: 100%;}
.assignment-review-listing ol{display: flex; flex-direction: column; width: 100%; margin:0 0 45px; padding:0}
.assignment-review-listing ol li{display: flex; flex-direction: column; width: 100%; padding:15px; margin-top: 20px; box-shadow: 0 4px 17px 0 rgba(73, 145, 152, 0.2); border-radius:20px;}
.assignment-review-listing ol li .listing-top{display: flex; width: 100%; justify-content: space-between;}
.assignment-review-listing ol li.notActive{opacity:0.5; pointer-events: none;}
.assignment-review-listing ol li .listing-top .listing-top-left{display: flex;}
.assignment-review-listing ol li .listing-top .listing-top-left .thumb{display: flex; flex-shrink: 0; height:70px; width: 70px; border:1px solid var(--primary); border-radius:100%;}
.assignment-review-listing ol li .listing-top .listing-top-left .thumb img{max-width: 100%; border-radius: 100%;}
.assignment-review-listing ol li .listing-top .listing-top-left .thumb-content{display:flex; flex-direction: column; margin-left: 35px;}
.assignment-review-listing ol li .listing-top .listing-top-left .thumb-content .title{display:flex; font-size:16px; font-weight: 700; color:var(--text-black);}
.assignment-review-listing ol li .listing-top .listing-top-left .thumb-content .title-email{color:var(--primary); display: flex; font-size:12px; margin-top: 4px;}
.assignment-review-listing ol li .listing-top .listing-top-left .thumb-content .title-phone{color:var(--primary); display: flex; font-size:12px; margin-top: 4px;}
.assignment-review-listing ol li .listing-top .listing-top-left .class-content{display:flex; flex-direction: column; margin-left: 35px;}
.assignment-review-listing ol li .listing-top .listing-top-left .class-content .title{display:flex;  font-size:14px; font-weight: 400; color:#9f9f9f;}
.assignment-review-listing ol li .listing-top .listing-top-left .class-content .title-value{display:flex; margin-top: 10px; font-size:14px; color:var(--text-black);}
.assignment-review-listing ol li .listing-top .listing-top-left .section-content{display:flex; flex-direction: column; margin-left: 35px;}
.assignment-review-listing ol li .listing-top .listing-top-left .section-content .title{display:flex;  font-size:14px; font-weight: 400; color:#9f9f9f;}
.assignment-review-listing ol li .listing-top .listing-top-left .section-content .title-value{display:flex; margin-top: 10px; font-size:14px; color:var(--text-black);}
.assignment-review-listing ol li .listing-top .listing-top-left .subject-content{display:flex; flex-direction: column; margin-left: 35px;}
.assignment-review-listing ol li .listing-top .listing-top-left .subject-content .title{display:flex;  font-size:14px; font-weight: 400; color:#9f9f9f;}
.assignment-review-listing ol li .listing-top .listing-top-left .subject-content .title-value{display:flex; margin-top: 10px; font-size:14px; color:var(--text-black);}


.assignment-review-listing ol li .listing-top .listing-top-right{display: flex; flex-direction: column;  align-items: flex-end;}
.assignment-review-listing ol li .listing-top .listing-top-right .date{font-size:12px; font-weight: 700; color:var(--text-black);}
.assignment-review-listing ol li .listing-top .listing-top-right .date span{font-size:12px; font-weight: 700; color:var(--primary);}
.assignment-review-listing ol li .listing-top .listing-top-right .checked{display: flex; margin-top: 15px;}
.assignment-review-listing ol li .listing-top .listing-top-right .checked span{background-color: var(--primary-rgba); font-size: 14px; text-transform: none; color: var(--text-black); padding:5px 10px; border-radius:5px;}
.assignment-review-listing ol li .listing-top .listing-top-right .arrow{margin-top: 15px; display: flex;width: 100%;justify-content: space-between;color: var(--primary);font-weight: 700;}
.assignment-review-listing ol li .listing-top .listing-top-right .arrow img{max-width: 16px; cursor: pointer;height: 12px;}
.assignment-review-listing ol li .listing-top .listing-top-right .arrow.open img{max-width: 16px; -webkit-transform: rotate(-180deg); transform: rotate(-180deg); display: flex;}

.listing-bottom{display: none; flex-direction: column; width: 100%;}
.listing-bottom .list-view{display: flex; width: 100%; margin-top: 15px;  justify-content: space-between;}
.listing-bottom .list-view.newDesign{display: flex; width:100%; justify-content: flex-start; flex-direction: column;}
.listing-bottom .list-view.newDesign .review-content{max-width:none; border:1px solid #ddd; justify-content: space-between; border-radius:4px; margin-top: 15px; align-items: flex-start; padding:10px; margin-right: 0; flex-direction: row;}
.listing-bottom .list-view.newDesign .review-content span{margin-right:10px;}
.listing-bottom .list-view.newDesign .review-content .actions{display: flex; width:auto;}
.listing-bottom .list-view.newDesign .review-content .fileBlock{display: flex;}
.listing-bottom .list-view.newDesign .review-content .fileBlock a{color:var(--primary); text-decoration: underline;}
.assignment-review-listing ol li.active  .listing-bottom .list-view .review-content a{background-color: transparent;}
.listing-bottom .list-view.newDesign .marks-block{align-items:center; justify-content: flex-end; width:100%; max-width:none; margin-top: 20px;}
.listing-bottom .list-view .view-content{display: flex; margin-right:20px; color: #939393; font-size:14px; width: 100%; max-width: 250px; justify-content:center;}
.listing-bottom .list-view  .review-content{display: flex; margin-right:20px; word-break: break-word;  text-align: center; flex-direction: column;  align-items: center; width: 100%; max-width: 250px;}
.listing-bottom .list-view  .review-content a{color:#000; font-size: 14px;}
.listing-bottom .list-view  .review-content span{font-size:14px; font-weight: 700; color:var(--primary); margin-bottom:5px;}
.listing-bottom .list-view  .review-content i{font-size:12px; font-weight: 700; font-style: normal; color:var(--text-black);}
.listing-bottom .list-view  .review-content button{font-size:14px;  font-weight: 400; display: flex; align-items: center; font-style: normal; color:#fff;}
.listing-bottom .list-view  .review-content{display: flex; flex-direction: column;}

.fileWrapper{display: flex; width: 100%; margin-top: 10px; justify-content:space-between; align-items: center; border: 1px solid #ddd; border-radius: 5px;  padding: 5px;}
.fileWrapper .actions{display: flex; align-items: center;}

.ques-statement-assignment{ width: 100%; justify-content: flex-end; padding:10px;}
.listing-bottom .list-view .view-content{max-width: none;     justify-content: flex-start;}
.listing-bottom .list-view.newDesign .review-content.newDesign.marksNotObtained{opacity:0.5; pointer-events: none;}
.listing-bottom .list-view.newDesign.marksNotObtained{opacity:0.5; pointer-events: none;}
.listing-bottom .list-view.newDesign .review-content.newDesign{flex-direction: column;     box-shadow: 0 0 5px #ddd;}
.listing-bottom .list-view.newDesign .review-content.newDesign .Block{display: flex; width:100%; align-items: center; justify-content: space-between;}
.listing-bottom .list-view.newDesign .review-content.newDesign .Block span{margin:0;}
.listing-bottom .list-view.newDesign .review-content.newDesign .Block .view-content{width:auto; margin-right: 0;}
.UploadButton{margin-top:10px; display: flex; justify-content: flex-end; width: 100%;}
.listing-bottom .list-view  .marks-block {display: flex; width: 100%; max-width: 250px; align-items: flex-start;}
.listing-bottom .list-view  .marks-block span{font-size: 14px; padding:5px 10px; display: flex; align-items: center; margin-right: 10px; border-radius:5px; border: solid 1px var(--primary); font-weight: 700; color: var(--primary);}
.listing-bottom .list-view  .marks-block input{font-size: 14px; padding:5px 10px; display: flex; max-width: 80px; align-items: center; margin-right: 10px; border-radius:5px; color:var(--primary); border: solid 1px var(--primary); font-weight: 700; color: var(--primary);}
.listing-bottom .list-view  .marks-block i{font-size:12px;}
.listing-bottom .list-view  .marks-block i img{max-width: 28px;}
.listing-bottom .list-view .checked-block{display:flex; align-items: flex-start; width: 100%; max-width: 75px;}
.listing-bottom .list-view .checked-block span{background-color:var(--primary-rgba); font-size: 14px;text-transform: none;color: var(--text-black); padding: 5px 10px; border-radius: 5px;}


.assignment-review-listing ol li.noResultFound{display: flex; background-color:#f0ebeb; width: 100%; padding:20px 15px; margin-top: 20px; box-shadow:0 0 0 0; border-radius:0px; align-items: center; justify-content:center;}


.assignment-review-listing ol li.noResultFound{display: flex; background-color:#f0ebeb; width: 100%; padding:20px 15px; margin-top: 20px; box-shadow:0 0 0 0; border-radius:0px; align-items: center; justify-content:center;}

.download-file-attempt{
  margin-right: 20px;
  min-height: 150px;
  margin-top: 45px;
  width:100%;
  max-width: 80%;
  margin-top: 0px;
}
.download-file-attempt a{color: var(--primary);} 
.img-prev-statement1.margintopzero{margin-top:0}

.ques-statement1 {
    width: 100%;
    margin-top: 83px;
    display: flex;
    /* margin-bottom: 38px; */
}
.img-prev-statement1 div:nth-child(1) {
    color: red;
    font-size: 20px;
    margin-right: 5px;
    /* width: 200px; */
    /* height: 200px; */
}
.img-prev-statement1 img {
    max-height: 200px;
    max-width: 200px;
}

.img-prev-statement1 a{
  margin-left: 20px;
  font-size: 20px;
}

.uploadNoteText{font-family: Lato; font-size: 12px; color:#999; margin-top:5px;}
.uploadFileError{font-family: Lato; display: none; font-size: 14px; color:#ff0000; margin-top:5px;}
.uploadFileLoading{display: flex; display: none; align-items: center;}
.uploadFileLoading img{max-width: 40px;}
.errorShow .uploadFileError{display: flex;}
.loaderShow .uploadFileLoading{display: flex;}
.uploadFileError.show{display:flex;}

.img-prev-statement1 {
    width: -webkit-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: max-content;
    padding-left: 8px;
    cursor: pointer;
    background-color: var(--secondary-rgba);
    border-radius: 6px;
    display: flex;
    /* align-items: center; */
}

.popup-content4-schedule-content {
    width: 700px !important;
    background-color: white;
    border-radius: 25px;
    border: none !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 35px 40px !important;
}

.popup-data4-schedule {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 0px;
}
.popup-data4-schedule .popup-inputs5:last-child{margin-bottom: 0;}
/*.popup-content4-content {
    height: 393px !important;
}*/

/*.popup-data4 {
    height: 272px !important;
}*/

.empty-message{
  text-align: center;
  color: #1e94a0;
  font-weight: 600;
  font-size: 20px;
}

.srv-validation-message{
  color: #e61313 !important;
}

.back-button-assignment{
  font-family: Source Sans Pro;
  font-size: 18px;
  /* font-weight: 600; */
  /* color: var(--white); */
  /* border: 1px solid var(--primary); */
  background-color: aliceblue !important;
  border: none;
  padding: 0px !important;
  border-radius: 9px;
  cursor: pointer;
  width: 61px !important;
}

.back-button-assignment img {
  width: 25px;
}

.assignment-head4{
  font-family: Lato;
  font-size: 18px !important;
  font-weight: 700;
  margin-right: 15px;
}

.assignment-head4 select{
  min-width: 150px;
  font-family: Lato;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 8px 14px;
  border-radius: 6px;
  margin-right: 11px;
}

.sch-options .increaseAttempt{width:auto; height: auto; flex-direction: row; grid-gap:5px; gap:5px; font-size: 14px;}

.increaseAtmmeptWrapper{display: flex;  font-family: Lato; width: 100%; flex-direction: column;}
.increaseAtmmeptWrapper h1{font-size: 24px;}

.increaseAtmmeptWrapper ol{display: flex; margin:0; padding:0; flex-direction: column; width: 100%; grid-gap:20px; gap:20px;}
.increaseAtmmeptWrapper ol li{background-color: var(--primary-rgba); display: flex; justify-content: space-between;   padding: 20px 24px; border-radius: 10px;  box-shadow: 1px 1px 6px 0px #eeeeee;}
.increaseAtmmeptWrapper ol li .listBlock{display:flex; grid-gap: 10px; gap: 10px; flex:1 1}
.increaseAtmmeptWrapper ol li .listBlock .thumb{display: flex; max-width: 70px; flex-shrink: 0; display: flex; align-items: center; justify-content: center; height: 70px; width: 100%; background-color:#fff; border-radius:100%}
.increaseAtmmeptWrapper ol li .listBlock .thumb img{width: 70px; height: 70px; border-radius:100%; object-fit: contain;}
.increaseAtmmeptWrapper ol li .listBlock .thumbContent{display: flex; grid-gap:6px; gap:6px; flex-direction: column;}
.increaseAtmmeptWrapper ol li .actions{display: flex; width: auto; grid-gap:10px; gap:10px; align-items: center;}
.increaseAtmmeptWrapper ol li .actions input[type="text"]{border:1px solid var(--primary); padding: 5px 10px; max-width: 200px; border-radius:5px;}
.increaseAtmmeptWrapper ol li .actions input[type="number"]{    min-width: 200px; border:1px solid var(--primary); padding: 5px 10px; max-width: 200px; border-radius:5px;}
.increaseAtmmeptWrapper ol li .actions input[type="datetime-local"]{    min-width: 200px; font-family: Lato;  border:1px solid var(--primary); padding: 5px 10px; max-width: 200px; border-radius:5px;}
.increaseAtmmeptWrapper ol li .actions{flex-direction: column;}
.increaseAtmmeptWrapper ol li .actions .increaseAttemptAction{grid-gap:0px;gap:0px; display: flex; flex-direction: column; align-items: flex-start;}
.increaseAtmmeptWrapper ol li .actions .dueDateAction{grid-gap:0px;gap:0px; display: flex; flex-direction: column; align-items:  flex-start;}
.increaseAtmmeptWrapper ol li .actions label{margin:0; padding:0; color: #666464; font-size: 14px;}
.increaseAtmmeptWrapper ol li .actions .increaseAttemptAction div{display: flex; align-items: center; grid-gap:10px; gap:10px;}
.increaseAtmmeptWrapper ol li .actions .dueDateAction div{display: flex; align-items: center; grid-gap:10px; gap:10px;}
.increaseAtmmeptWrapper .filtersWrapper{display: flex; justify-content: space-between; align-items: center; padding: 0 0 10px; grid-gap:10px; gap:10px; bordeR:0px none; border-radius: 0px; border-bottom: 1px solid #ccc; width: 100%; margin: 0 0 15px}
.increaseAtmmeptWrapper .filtersWrapper .filtersBlock{display: flex; width:100%}
.increaseAtmmeptWrapper .filtersWrapper .filtersBlock .block{display: flex; align-items: center; grid-gap:25px; gap:25px}
.increaseAtmmeptWrapper .filtersWrapper .filtersBlock .block select{max-width: 210px;}
.increaseAtmmeptWrapper .searchWrapper{display: flex; align-items: center; max-width: 300px; padding: 0 0 0px; grid-gap:10px; gap:10px; bordeR:0px none; border-radius: 0px; width:100%; margin: 0 0px}
.increaseAtmmeptWrapper .filtersWrapper label{margin:0; padding: 0; white-space: nowrap; font-size: 16px; padding: 0;}
.increaseAtmmeptWrapper .searchWrapper input[type="search"]{border: 1px solid var(--primary); width:100%; max-width: 300px; border-radius: 5px; padding: 8px 10px; background-color: var(--primary-rgba);}
.increaseAtmmeptWrapper .noDataFound{display: flex; width: 100%; background-color:#f7f7f7; color:#000; text-align: center; padding:20px;}
.increaseAtmmeptWrapper .classNameDisplay{margin-bottom: 15px; display: flex;;}
.increaseAtmmeptWrapper .batchNameDisplay{display: flex; justify-content: space-between; align-items: center; width: 100%; margin-bottom: 20px;}
.increaseAtmmeptWrapper .batchNameDisplay .actions{display: flex; width: auto; grid-gap:25px; gap:25px; margin-top: 0;}
.increaseAtmmeptWrapper .batchNameDisplay input[type="text"]{font-family: Lato; border: 1px solid var(--primary); width:100%; max-width: 300px; border-radius: 5px; padding:5px 10px; background-color: var(--primary-rgba);}
.increaseAtmmeptWrapper .batchNameDisplay input[type="number"]{font-family: Lato; min-width: 200px; max-width: 200px; border: 1px solid var(--primary); width:100%; max-width: 300px; border-radius: 5px; padding:5px 10px; background-color: var(--primary-rgba);}
.increaseAtmmeptWrapper .batchNameDisplay input[type="datetime-local"]{font-family: Lato;  min-width: 200px; max-width: 200px; border: 1px solid var(--primary); width:100%; max-width: 300px; border-radius: 5px; padding:5px 10px; background-color: var(--primary-rgba);}
.increaseAtmmeptWrapper ol li .actions .disabled{pointer-events: none; opacity: 0.6;}
.increaseAtmmeptWrapper .batchNameDisplay .actions .disabled{pointer-events: none; opacity: 0.6;}
.increaseAtmmeptWrapper .batchNameDisplay .actions .increaseAttemptAction{display: flex; flex-direction: column; grid-gap:5px; gap:5px; align-items: flex-start;}
.increaseAtmmeptWrapper .batchNameDisplay .actions .dueDateAction{display: flex; flex-direction: column; grid-gap:5px; gap:5px; align-items: flex-start;}
.increaseAtmmeptWrapper .batchNameDisplay .actions label{margin: 0; padding: 0; color:#666464; font-size: 14px;}
.increaseAtmmeptWrapper .batchNameDisplay .actions .dueDateAction div{display: flex; align-items: center; grid-gap:10px; gap:10px;}
.increaseAtmmeptWrapper .batchNameDisplay .actions .increaseAttemptAction div{display: flex; align-items: center; grid-gap:10px; gap:10px;}
/* .assignment-review-wrapper .pagination-list{position: static; margin-top:15px; width:100%; display:flex; justify-content: center; align-items: center;}  */
.clearBoth{flex-direction: column;}
.addMoreFile.clearBoth{flex-direction: column;}
.cngedirection{flex-direction: column}
.cngedirection div{flex-direction: column; display:flex;}
.cngedirection div.uploadFileError.show{display: flex;}
.errorShow .addMoreFile .uploadFileError{display: flex;}
.cngedirection div.uploadFileError{display: none;}
.cngedirection div span{color:#999; font-size: 12px; margin-top: 5px}
.pagination-list{
  position: fixed;
  bottom: 0px;
  /* width: 100%; */
  height: 44px;
  /* background-color: #489198; */
  /* border: 1px solid white; */
  display: flex;
}

.fixed-pagination {box-shadow: 0 4px 17px 0 rgba(73, 145, 152, 0.2); left:0; right:0; height:auto; border-radius:0px 0px 0 0; background-color:#fff; display:flex; justify-content: center; align-items: center; width:100%; padding:10px;}

.img-prev-statement-assignment{
  cursor: pointer;
  background-color: var(--secondary-rgba);
  border-radius: 6px;
  display: flex;
  /* align-items: center; */
  font-size: 10px !important;
}

.listing-bottom .list-view .review-content .img-prev-statement-assignment a{color:var(--primary)}
.ques-statement-assignment{
  margin-top: 15px;
}

.img-prev-statement-assignment a {
  margin-left: 20px;
  font-size: 15px;
}

.assg-setting-duration{
  margin-left: 0 !important;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.react-switch-label{background-color: var(--primary)!important;}
.assg-setting-duration div{
  text-align: center;
  font-family: Lato;
  margin-right: 12px !important;
  margin-left: 12px !important;
  font-size: 16px;
  font-weight: 700;
  color: var(--text-black);
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  display: flex;
  color: var(--black)!important;
}
.assg-setting-duration span {
    background-color: var(--primary);
    color: white;
    font-size: 27px;
    font-weight: bold;
    padding-bottom: 3px;
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}

.marks-block-top input{
  font-size: 14px;
  padding: 5px 10px;
  display: flex;
  max-width: 80px;
  align-items: center;
  margin-right: 10px;
  border-radius: 5px;
  color: var(--primary);
  border: solid 1px var(--primary);
  font-weight: 700;
  color: var(--primary);
}

.action-class{
  color: var(--primary) !important;
}

 .asm-setting-option input {    
    font-family: Lato;
    font-size: 16px;
    background-color: #ecfee2;
    background-color: var(--secondary-rgba);
    border: 1px solid #fed41e;
    border: 1px solid var(--primary);
    color: rgba(0,0,0,.84);
    color: var(--text-black);
    padding: 12px 16px;
    border-radius: 4px;
    background-color: white;
}
.inst-setting{
  width: 100% !important;
  display: flex;
}

.asm-setting-container{font-family: Lato;}
.asm-setting-container .asm-setting-option {justify-content: space-between; width: 100%;}
.asm-setting-container .inst-setting button{margin-left: 15px; padding:8px 25px}
.asm-setting-container .inst-setting{align-items: center;}
.asm-setting-container .inst-setting input[type="text"]{border-radius:40px; padding: 8px 25px;}
.asm-setting-container .asm-setting-option .menuheading{font-weight: bold; margin-bottom: 10px;}
.asm-setting-container .menu-setting .asm-setting-option{flex-direction: column; align-items: flex-start;}
.asm-setting-container .menu-setting .asm-setting-option .menuwrapper{display: flex; width: 100%; flex-wrap: wrap;}
.asm-setting-container .menu-setting .asm-setting-option .menuwrapper .menublock{display: flex; margin-bottom: 15px; margin-right:20px; align-items: center;}
.asm-setting-container .menu-setting .asm-setting-option .menuwrapper .menublock:last-child{margin-right: 0;}
.asm-setting-container .menu-setting .asm-setting-option .menuwrapper .menublock input[type="checkbox"]{margin:0 5px 0 0}

@media only screen and (max-width: 600px) {  
  .download-file-attempt{width: 100%; word-break: break-word; margin-right:0; height:auto}
  .assignment-review-bottom + div{flex-direction:column}
  .img-prev-statement1{margin-top:15px}
  .assignment-review-top .assignment-title{flex-direction:column}
  .assignment-review-wrapper .assignment-review-container .assignment-review-top .assignment-title span{margin-top:15px; display:flex;}
}

.multi-select .dropdown-content  .panel-content .item-renderer input[type="checkbox"]{width:auto;}
.multi-select .dropdown-content .panel-content ul{display: flex; flex-direction: column; overflow-y: auto; max-height: 100px;}
.multi-select .dropdown-content .panel-content ul li{display: flex; width:100%}
.multi-select .dropdown-content .panel-content ul li .item-renderer{width: 100%; display: flex; align-items: center;}
.multi-select .dropdown-content .panel-content ul li .item-renderer input[type="checkbox"]{width:auto;}
.multi-select .dropdown-content .panel-content ul li label{width: 100%;}

.admin-setting-loader {
  z-index: 100;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color:var(--primary-rgba);
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-part.paginationCorrections{height: calc(100% - 110px ) !important;}

.asm-setting-container .inst-setting button{pointer-events: none; opacity: 0.7;}
.asm-setting-container .inst-setting button.btnActive{pointer-events:unset; opacity:1;}

.admin-setting-inner {
  border: 8px solid #ffffff;
  border-radius: 50%;
  border-top: 8px solid var(--primary);
  width: 60px;
  height: 60px;
  -webkit-animation: admin-spin 1s linear infinite; /* Safari */
  animation: admin-spin 1s linear infinite;
}

@-webkit-keyframes admin-spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes admin-spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


.admin-calendar {
  z-index: 90 !important;
  position: sticky;
  padding-top: 40px;
  top: 0px;
  height: 80px;
	width: 100%;
	display: flex;
	flex-direction: column;
  align-items: center;
  background-color: var(--white);
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
.session-up  .hostEmail{margin:5px 0 10px; font-size: 15px;  font-family: Lato; display: flex; align-items: flex-start; flex-direction: row;}
.session-up .hostEmail span{margin-left: 5px; margin-top: 0;}
.popup-data4 select{padding: 12px 5px;}
.ErrorMSG{background-color:#fff; font-family: Lato; font-size: 16px; padding:16px 50px; border-radius:0 0 10px 10px}
.ErrorMSG a{margin-left: 10px; color:#489198}
.zIndexchange{z-index: 122;}
.zIndexchange .admin-header{z-index: 1!important;}
.zIndexchange .admin-calendar{z-index: 1!important;}
.IFrameDialogWrapper{position: fixed; top:0; right:0; bottom:0; left:0; background-color: rgba(0,0,0,0.8); display: flex; display: none; align-items: center; justify-content: center; width: 100%; height:100%; z-index: 12;}
.IFrameDialogWrapper.open{display: flex;}
.IFrameDialogWrapper .IFrameDialogContainer{background-color: #fff; position: relative; width:100%; height: 100%; padding:10px; border-radius:0px;}
.IFrameDialogWrapper .IFrameDialogContainer iframe{width: 100%; height: 100%; bordeR:0px none}
.IFrameDialogWrapper .iframeClose{position:absolute; right:40px; color:#000; font-family: Lato; top:30px; font-size:28px;}

.cal-header {
  margin-top: 25px;
	position: absolute;
	top: 0;
	width: calc(100% - 120px);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.viewRecordingWrapper{display: flex; background-color: var(--white); z-index: 330000; width: 100%; height: 100%; position: fixed; top:0; right:0; bottom:0; left:0}
.viewRecordingWrapper iframe{display: flex; border:0px none; width: 100%; height: 100%;}
.viewRecordingWrapper iframe body{padding: 0; margin: 0;}
.unauthorizedAccessDialog{position:fixed; top:0; right:0; display: flex; align-items: center; justify-content: center; bottom:0; left:0; background-color: rgba(0,0,0,0.6);}
.unauthorizedAccessDialog .unauthorizedAccessContainer{background-color:var(--white); text-align: center; line-height: 31px; font-size: 19px; font-family: Lato; padding:15px; border-radius:10px;}
.cal-month {
	display: flex;
	align-items: center;
}

.cal-month-name {
	font-family: Lato;
	font-size: 16px;
	font-weight: 700;
	margin-left: 15px;
	margin-right: 15px;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.cal-month span {
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cal-month img {
	width: 20px;
}

.cal-dates {
	width: 100%;
	display: flex;
}

.calendar_tray {
	z-index: 2;
	margin-top: 50px;
	width: calc(100% - 120px);
	border-radius: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: var(--secondary-rgba);
}

.header-op {
  display: flex;
  align-items: center;
}

.header-op span {
  cursor: pointer;
  margin-left: 20px;
  margin-right: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 44px;
  border-radius: 8px;
}

.header-op span img {
  margin: 0 0 !important;
}

.header-op span:hover {
  background-color: var(--primary-rgba);
}

.header-op img {
  width: 28px;
}

.header-op2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-op2 select {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 6px 12px;
  border-radius: 4px;
}

.header-op2 select:nth-child(2), .header-op2 select:nth-child(3) {
  margin-left: 15px;
}
 
.slide-buttons {
	z-index: 1;
	position: absolute;
	bottom: 38px;
	width: 100%;
	display: inline-block;
}

.slide-buttons span {
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary);
	border-radius: 50%;
	cursor: pointer;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.slide-buttons img {
	width: 20px;
}

.slide-buttons span:first-child {
	padding-right: 2px;
}

.slide-buttons span:last-child {
	padding-left: 2px;
}

.slide-buttons span:hover {
	opacity: 0.8;
}

.slide-buttons span:first-child {
	float: left;
}

.slide-buttons span:last-child {
	float: right;
}

.slide-buttons .disable {
	opacity: 0.45;
}

.slide-buttons .disable:hover {
	opacity: 0.45;
}

.calendar_tray li {
	width: 12.5%;
	cursor: pointer;
	display: flex;
	justify-content: center;
}

.cal-buttons {
	width: 100%;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
}

.cal-date {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 12px 24px 12px 24px;
	border-radius: 10px;
}

.calendar_tray li:hover .cal-date {
	background-color: var(--secondary);
}

.cal-date span {
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	font-family: Lato;
}

.cal-date span:first-child {
	font-size: 18px;
	font-weight: 700;
	color: var(--text-black3);
}

.cal-date span:last-child {
	margin-top: 10px;
	font-size: 20px;
	font-weight: 900;
	color: var(--text-black);
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;;
}

.cal-selected span:last-child {
	background-color: var(--primary);
	color: white;
}

.choose-content2 {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: flex-start;
  margin-top: 20px;
  align-self: center;
}

.type-content2 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
  cursor: pointer;
}

.type-content2:last-child {
  margin-bottom: 0;
}

.type-content2 img {
  width: 72px;
}

.type-content2 div {
  margin-left: 28px;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
  display: flex;
  flex-direction: column;
}

.type-content2 span {
  font-family: Lato;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-black);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.popupx-inputs div {
  display: flex;
  align-items: center;
}

/* Sessions */

.lecture-list {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 140px;
}

.lecture-list-x2 {
  width: 100%;
  margin-top: 40px;
  overflow-y: scroll;
  height: calc(100vh - 330px);
}

.session-div {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
  z-index: 10 !important;
}

.session-date {
  padding-top: 40px;
  width: 260px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Lato;
  font-weight: 600;
  font-size: 18px;
}

.session-date span {
  font-size: 22px;
  margin-bottom: 5px;
}

.session-content {
  position: relative;
  width: 600px;
  height: 200px !important;
  height: 220px !important;
  border-radius: 15px !important;
  background-color: var(--primary-rgba);
  box-shadow: 1px 1px 6px 0px #eeeeee;
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  transition: .2s all;
  cursor: pointer;
}

.session-up {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Lato;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.4;
  overflow: hidden;
}

.session-up div {
  display: flex;
  flex-direction: column;
}

.lecture_data_labels:first-child {
  margin-bottom: 3px;
}

.session-up span {
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
}

#session-band {
  position: absolute;
  padding: 5px 14px 6px 14px;
  right: 0;
  top: 30px;
  background-color: var(--secondary-rgba);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.01em;
}

.lecture-room {
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.lecture-room input[type="checkbox"] {
  width: 20px !important;
  height: 20px;
  margin-left: 15px !important;
  margin-right: 10px !important;
}



.lecture-room label {
  font-family: Lato;
  font-size: 15px;
}

.lecture_data_labels {
  font-weight: 400;
  font-size: 16px;
}

#live_class_location {
  max-width: 150px;
  overflow: hidden;
  position: absolute;
  text-decoration: none;
  background-color: var(--primary);
  color: var(--white);
  font-family: Lato;
  font-weight: 700;
  font-size: 15px;
  bottom: 25px;
  right: 200px !important;
  padding: 5px 10px 6px 10px;
  border-radius: 4px;
}

.edit-lecture-button {
  position: absolute;
  bottom: 25px;
  right: 155px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px;
  background-color: var(--light-black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-lecture-button2 {
  position: absolute;
  bottom: 25px;
  right: 108px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px;
  background-color: var(--light-black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-lecture-button3{
    position: absolute;
    bottom: 25px;
    right: 60px !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 8px;
    background-color: var(--light-black);
    display: flex;
    align-items: center;
    justify-content: center;
}
.edit-lecture-button4{
    position: absolute;
    bottom: 25px;
    right: 10px !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 8px;
    background-color: var(--light-black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mark-attendance-button{
  position: absolute;
  bottom: 75px;
  right: 10px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px;
  background-color: var(--light-black);
  display: flex;
  align-items: center;
  justify-content: center;
}
.mark-attendance-button img{width: 30px; height: 30px;}
.edit-lecture-button img, .edit-lecture-button2 img ,.edit-lecture-button3 img,.edit-lecture-button4 img {
  width: 20px;
}

.edit-lecture-button img, .edit-lecture-button2 img {
  width: 20px;
}

.dash-test-bottom {
  display: flex;
  align-items: flex-end;
}

.dash-test-bottom div:first-child {
  display: flex;
  flex-direction: column;
  width: 60%;
  overflow: hidden;
}

.dash-test-bottom div span {
  margin-top: 7px;
}

.dash-test-bottom div span:first-child {
  font-size: 15px;
  line-height: 1.36;
  white-space: pre-wrap !important;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  max-height: 62px;      /* fallback */
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.dash-test-bottom span {
  white-space: nowrap;
}

.fac_dp_img {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.fac_dp {
  min-width: 34px !important;
  max-width: 34px !important;
  min-height: 34px !important;
  max-height: 34px !important;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 12px;
}

#action-reverse {
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 18px;
  color: var(--primary);
  width: -webkit-max-content !important;
  width: max-content !important;
  margin-right: 10px;
}

.back-to-recent{
    font-family: Source Sans Pro; 
    font-size: 14px;
    /* font-weight: 600; */
    color: var(--white);
    /* border: 1px solid var(--primary); */
    background-color:var(--primary);
    /* border: none; */
    padding-left: 23px;
    border-radius: 40px;
    cursor: pointer;
    /* margin-left: 16px; */
    width: 88px;
}
.page-header-session-learner{
  align-items: flex-end;
  background-color: transparent;
}
.page-header-faculty{
  align-items: flex-end;
  background-color: transparent;
}

.back-to-recent-faculty{
    font-family: Source Sans Pro;
    font-size: 14px;
    /* font-weight: 600; */
    color: var(--white);
    /* border: 1px solid var(--primary); */
    background-color: var(--primary);
    /* border: none; */
    padding-left: 23x;
    border-radius: 40px;
    cursor: pointer;
    /* margin-left: 16px; */
    width: 88px;
    
}
.dash-test-bottom button{
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
    border: 1px solid var(--primary);
    background-color: var(--primary);
    border: none;
    padding: 6px 20px 8px 20px;
    border-radius: 40px;
    cursor: pointer;
    width: 119px;
}

.list-content{
  height: 100vh
}
.dash-test-no-found{
    width: 530px;
    margin-bottom: 30px;
    border-radius: 10px;
    transition: .2s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: Lato;
    font-size: 19px;
    font-weight: 600;
    color: #2e6d96;
    text-decoration: none;
    background-color:var(--primary-rgba);
    padding: 19px;
}

#view-recording{
  margin-left: 10px;
}

#view-recording button {
  width: 157px !important;
}

.content-create-content{
  height: 490px !important;
  width: 90% !important;
  padding: 4px 24px !important;
}

.content-create-content iframe{
  height: 100%;
}

.popup-content .close, .popup-content2-content .close{
  top: 8px !important;
  right:15px !important;
}

.adminContact img{
  margin-left: 150px;
}

.adminContact div{
    margin: 24px;
    text-align: center;
    font-family: lato;
    font-size: 29px;
}

.content-geturl-content {
  height: 130px !important;
}

.url-child1{
  font-family: lato;
  font-size: larger;
  border-bottom: 1px solid;
  padding: 2px;
}

.url-child2{
  padding-top: 26px;
  /* margin-top: 37px; */
  /* margin: auto; */
  font-family: lato;
  font-weight: 600;
}

.get-url{
  margin-left: 6px;
  padding: 2px;
  width: 54px !important;
  height: 40px !important;
  border-radius: 8px;
  background-color: var(--light-black);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.get-url img{
  width:20px;

}

.app-div.blog-post-container .action-btn h1{display: flex; align-items: center; grid-gap:10px; gap:10px}
.app-div.blog-post-container .action-btn h1 img{margin-top: 0;}
.dash-test span.studentsMap a{display: flex; align-items: center; grid-gap:10px; gap:10px; color:var(--text-black3)}
.studentsMap a{display: flex; align-items: center; grid-gap:10px; gap:10px;}
.studentsMap {display: flex; align-items:center; grid-gap:10px; gap:10px}
.studentsMap img{max-width: 34px;}
.studentsMap div{display: flex; flex-direction: column;}

.previewStudentListDialog.open{display: flex;}
.previewStudentListDialog{position: fixed; background-color: rgba(0,0,0,0.1); display: flex; display: none; font-family: lato;  align-items: center; justify-content: center; top:0; left:0; right:0; bottom:0; z-index: 12;}
.previewStudentListDialog .previewStudentListContainer{border-radius:10px; display: flex; flex-direction: column; grid-gap:15px; gap:15px; position: relative; background-color: #fff; padding:15px; width: 100%; max-width: 700px; box-shadow: 4px 4px 12px -6px var(--text-black2);}
.previewStudentListDialog .previewStudentListContainer h3{border-bottom:1px solid #ddd; display:flex; justify-content: space-between; margin-bottom:0px; padding-bottom: 5px;}
.previewStudentListDialog .previewStudentListContainer h3 span{cursor: pointer;}
.previewStudentListDialog .dialogClose{position: absolute; cursor: pointer; right:-5px; top:-10px; width: 24px; background-color: #fff; color: #000; height: 24px; display: flex; align-items: center; justify-content: center; border-radius: 100%;}
.previewStudentListDialog .listWrapper{display: flex; flex-direction: column; grid-gap:10px; gap:10px; width: 100%; overflow-y: auto; max-height: 300px;}
.previewStudentListDialog .listWrapper th, .previewStudentListDialog .listWrapper td {
  padding: 12px 15px;
  text-align: left;
}

.previewStudentListDialog .listWrapper th {

  text-transform: uppercase;
}

.previewStudentListDialog .listWrapper tr {
  border-bottom: 1px solid #dddddd;
 
}
.previewStudentListDialog .listWrapper td:nth-child(2),.previewStudentListDialog .listWrapper td:last-child {
  cursor: pointer;
}

.previewStudentListDialog .listWrapper tr:nth-of-type(even) {
  background-color: #f3f3f3;
}



.blog-list th, .blog-list td {
  padding: 12px 15px;
  text-align: left;
}

.blog-list th {

  text-transform: uppercase;
}

.blog-list tr {
  border-bottom: 1px solid #dddddd;
 
}
.blog-list td:nth-child(2),.blog-list td:last-child {
  cursor: pointer;
}

.blog-list tr:nth-of-type(even) {
  background-color: #f3f3f3;
}




.lecture-room input[type="checkbox"]:nth-child(2){width:auto;}
.popupx3-inputs{display: flex; width: 100%;}
.popupx3-inputs .block{width:50%; display: flex; margin-left: 20px;}
.popupx3-inputs .block input[type="text"]{width:100%!important;}
.popupx3-inputs .block input{width:100%!important;}
.popupx3-inputs .block select{width:100%;; margin-right: 0;}
.popupx3-inputs .block input[type="date-time-local"]{width:100%;}
.popupx3-inputs .block:first-child{margin-left: 0;}
.popupx3-inputs .block .lecture-room{margin-left: 0;}
.popupx3-inputs .block .lecture-room input[type="text"]{width:126px!important;}
.popupx3-inputs .block .lecture-room input[type="checkbox"]{width:16px!important;}
.popupx3-inputs .block .lecture-room label{padding-right: 0;}
.popup-data4 select{margin-left: 10px}
.popup-data4 select:first-child{margin-left:0px; margin-right:0px;}
.popup-data4 .singlerow{width: 100%; margin-bottom:15px;}
.popup-data4 .singlerow .block{width: 100%;}
.popup-data4{display: flex; width: 100%; height:100%; padding: 20px 0 0;}
.popup-data4 .block{width:50%; display: flex; margin-left:10px;}
.popup-data4 .block input[type="text"]{width:100%!important;}
.popup-data4 .block input{width:100%!important;}
.popup-data4 .block select{width:100%;; margin-right: 0;}
.popup-data4 .block input[type="date-time-local"]{width:100%;}
.popup-data4 .block:first-child{margin-left: 0;}
.popup-data4 .block .lecture-room{margin-left: 0;}
.popup-data4 .block .lecture-room input[type="text"]{width:126px!important;}
.popup-data4 .block .lecture-room input[type="checkbox"]{width:16px!important;}
.popup-data4 .block .lecture-room label{padding-right: 0;}
.popup-data4 .block select + select{margin-left: 10px;}

.popupx-dropdowns{width: 100%;}
.popupx-dropdowns select{width: 100%;}
.popupx-inputs .asm-setting-duration{margin-left: 10px!important;}
.popupx-inputs .asm-setting-duration div input{margin-left: 0!important; border: 1px solid var(--primary);}
.popupx-inputs > div{padding: 12px 16px; width:100%; max-width: 48%; border-radius:4px; background-color: var(--secondary-rgba);border: 1px solid var(--primary);}
.heading-part2 select{max-width:300px; width:100%}
.popupx-inputs .popupx-dropdowns{background-color: transparent; border:0px none; max-width: none; padding: 0; margin-left: 25px;}
.popupx-inputs .popupx-dropdowns select{margin-right: 0;}

@media only screen and (max-width: 1000px) {  
  .list-sticky{padding-right:20px;}
  .dash-test{width: 100%; height:auto}
  .dash-test-wrapper{width:100%; display:flex; position: relative; align-items: flex-start}
  .dash-test-wrapper .dash-test-inner{display: flex!important; margin-right:10px; width: 72%; flex-direction:column}
  .dash-test-wrapper .dash-test-inner br{display: none;}
  .dash-test-wrapper .lec-mode-dash{width:auto!important; top:0; position:static; padding:6px 14px}
}

@media only screen and (max-width: 600px) {  
  .list-sticky-practice{width: auto;}
  .list-sticky{width: auto;}
  .admin-div .session-div{flex-direction: column;}
  .admin-div .session-div .session-date{margin-left:0;  width:100%; align-items: flex-start; margin-top:0; margin:10px 0 10px; padding:0px}
  .admin-div .session-div .session-content{width: 100%; padding:45px 15px 15px; height:auto!important}
  .admin-div .session-div .session-content .session-up{width: 100%;}
  .admin-div #session-band{bottom:10px; top:auto}
  .admin-div #live_class_location{right:0; bottom:0; position: static; margin-top:15px; text-align:center}
  .admin-div .edit-lecture-button{right:0; height:30px!important; width:30px!important; top:10px; bottom:auto; text-align:center}
  .admin-div .edit-lecture-button2{right:0; height:30px!important; width:30px!important; top:10px; bottom:auto;  text-align:center}
  .admin-div .edit-lecture-button3{right:0; height:30px!important; width:30px!important; top:10px;  bottom:auto;text-align:center}
  .admin-div .edit-lecture-button4{right:0; height:30px!important; width:30px!important; top:10px;  bottom:auto;text-align:center}
  .admin-div .edit-lecture-button img{width: 20px;}
  .admin-div .edit-lecture-button2 img{width: 20px;}
  .admin-div .edit-lecture-button3 img{width: 20px;}
  .admin-div .edit-lecture-button4 img{width: 20px;}
  .dash-test-bottom div:first-child{width:auto!important}
  .dash-test .faculty-dash-actions{position: static; flex-direction:column; display:flex;}
  .faculty-dash-actions div{margin:0; margin-bottom:10px;}

  .popup-content2-content{width:90%!important; padding:15px!important; height:auto;}
  .popup-content2-content .choose-content{flex-direction:column;}
  .popup-content2-content .choose-content .type-content{width: auto; height:auto; flex-direction:row;}
  .popup-content2-content .choose-content .type-content img{margin-bottom:0; margin-right:10px;}

  .popup-content-content{width:90%!important; padding:15px!important; height:auto;}
  .popup-content-content .popup-data{padding-top:20px; height: auto; }
  .popup-content-content .popup-data .popup-inputs{margin-bottom:15px; flex-direction: column;}
  .popup-content-content .popup-data .popup-inputs input{width:100%}
  .popup-content-content .popup-data .popup-inputs select{width:100%; margin-top:10px;}
  .popup-content-content .popup-data .popup-inputs .popup-admin-child{width: 100%; margin-right:0; margin-bottom:10px;}
  .popup-content-content .popup-data .popup-inputs .popup-admin-child .img-prev{margin:0}
  
}



.disable-click {
  pointer-events: none;
  opacity: 0.5; /* Optional: makes the element appear disabled */
  position: relative; /* Ensure pseudo-element positioning is relative to the element */
}

.disable-click::after {
  content: ''; /* This creates the pseudo-element */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: translate(-50%, -50%) rotate(0deg); transform: translate(-50%, -50%) rotate(0deg); }
  100% { -webkit-transform: translate(-50%, -50%) rotate(360deg); transform: translate(-50%, -50%) rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: translate(-50%, -50%) rotate(0deg); transform: translate(-50%, -50%) rotate(0deg); }
  100% { -webkit-transform: translate(-50%, -50%) rotate(360deg); transform: translate(-50%, -50%) rotate(360deg); }
}
#unit-part {
  padding-bottom: 100px;
}

.unit-data {
  margin-top: 45px;
}

.fileDownloadableAction{display: flex; align-items:center; font-family: Lato;   margin-bottom: 20px;   justify-content: flex-end;}
.fileDownloadableAction label{margin: 0; padding: 0; display: flex; align-items: center; grid-gap:8px; gap:8px;}
.fileDownloadableAction label input[type="checkbox"]{margin: 0; padding: 0;}

.popup-inputs input:last-child.notes_url{width: 100%;}
.uploadChoose{display: flex; width: 100%; font-family: Lato; margin-bottom: 10px;}
.uploadChoose label{display: flex; cursor: pointer;}
.unit-head-data {
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px;
}

.uploadLabel{display: flex; grid-gap:20px; gap:20px; font-family: Lato;}
.uploadLabel label{font-family: Lato; margin:0; white-space: nowrap;}

.increaseAttempt{font-family: Lato;
  font-size: 15px;
  font-weight: 400; color:var(--primary); position: absolute; right:10px; bottom:60px;}

.unit-head-data div {
  display: flex;
  align-items: center;
}

.unit-head-data div button:nth-child(3) {
  background-color: var(--white);
  color: var(--primary);
}

.unit-head-data div span:nth-child(2) {
  margin-left: 10px;
  margin-right: -10px;
}

.unit-head-data div button {
  margin-left: 10px;
}

#no-span {
  width: 0 !important;
}

.unit-head-data div span {
  width: 40px;
  height: 40px;
	/*display: flex !important;*/
	align-items: center !important;
	justify-content: center !important;
	cursor: pointer;
	border-radius: 10px !important;
}

.unit-head-data img {
  width: 20px;
}

.unit-head-data div span:hover {
	background-color: rgba(95, 125, 149, 0.15);
}

.single-unit {
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: max-height 0.18s ease-in;
  margin-bottom: 20px;
  box-shadow: 2px 2px 6px 0px var(--primary-rgba);
}

.unit-handler {
  width: 100%;
  min-height: 72px;
  max-height: 72px;
  background-color: var(--primary-rgba);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 25px;
}

.unit-handler-left, .unit-handler-right {
  display: flex;
  align-items: center;
}

.unit-handler-right button {
  color: var(--primary);
  background-color: transparent;
  padding: 0 0 !important;
  font-family: Source Sans Pro;
  text-align: center;
  font-weight: 600;
  font-size: 19px;
  text-decoration: none;
  cursor: pointer;
  margin-left: 20px;
}

.unit-handler-right button:hover {
  opacity: 0.8;
} 

.unit-handler-left {
  font-family: Lato;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 0.01em;
}

.unit-handler-left a {
  text-decoration: none;
  color: var(--text-black);
  display: flex;
  align-items: center;
}

.unit-handler-left a:hover {
  text-decoration: underline;
}

.unit-handler-left a img {
  margin-left: 12px;
  width: 17px !important;
}

.unit-handler-right span {
  width: 40px;
  height: 40px;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	cursor: pointer;
	border-radius: 10px !important;
  background-color: var(--primary-rgba);
}

.popup-inputs.direction{flex-direction: column;}
.popup-inputs.direction .uploadNoteText{text-align: left; width:100%;}
.popup-inputs.uploadLabel.direction{flex-direction: column; align-items: flex-start; grid-gap:10px; gap:10px;}
.popup-inputs.uploadLabel.direction .uploadNoteTex{margin-top: 0;}
.popup-inputs.uploadLabel.direction label{padding: 0;}
.uploadNoteText.Width{width:100%; justify-content:flex-end; display: flex; margin-top: 0;}
.uploadNoteText.textAlignRight{width:100%; justify-content:flex-end; display: flex; margin-top: 0;}

.unit-handler-right span {
  margin-left: 10px;
}

.unit-handler-right img {
  width: 20px;
}

.unit-handler-right span:hover {
	background-color: rgba(95, 125, 149, 0.15);
}

.subunit-data {
  width: 100%;
  height: 286px;
  background-color: var(--secondary-rgba);
  display: flex;
  align-items: center;
  justify-content: center;
}

.subunit-data3 {
  width: 100%;
  height: 260px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.subunit-data2 {
  width: 100%;
  height: auto;
  padding: 30px 40px;
  background-color: var(--secondary-rgba);
  display: flex;
  align-items: center;
  justify-content: center;
}

.subunit-data-layer {
  width: 930px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -15px !important;
}

.react-multiple-carousel__arrow {
  padding: 0 0 !important;
  width: 40px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subunit-data-layer li {
  display: block;
  min-width: 280px;
  max-width: 280px;
  height: 200px;
  margin-left: 15px;
  margin-right: 15px;
}

.content-item {
  width: 100%;
  height: 200px;
  border-radius: 15px;
  background-color: var(--white);
  box-shadow: 1px 1px 6px 0px #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 24px 20px;
}

#content-item2 {
  background-color: var(--secondary-rgba);
}

.content-item:last-child {
  margin-right: 0;
}

.content-item .grid-up:nth-child(2) {
  justify-content: flex-end;
}

.content-item button {
  font-size: 16px;
}

.content-stat {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: #dddddd;
  position: relative;
  overflow: hidden;
}

.stat-content {
  background-color: var(--primary);
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.choose-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.type-content {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
  color: var(--primary);
  transition: .2s all;
  cursor: pointer;
  text-decoration: none;
}

.type-content:hover {
  background-color: rgba(0,0,0,0.05);
}

.type-content img {
  width: 110px;
  margin-bottom: 30px;
  height: 86px;
}

.choose-content hr {
  border-left: 1px solid rgba(0,0,0,0.1);
  height: 150px;
}

#content-grid {
  text-decoration: none;
  color: var(--text-black);
  cursor: pointer;
}

/* Content view */

#content-part {
  height: calc(100% - 80px) !important;
}

.content-view-div {
  position: relative;
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: calc(100vh - 280px);
}

.content-table {
  width: 260px;
  height: 100%;
  background-color: var(--secondary-rgba);
  border-radius: 20px;
  padding: 24px 26px;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-table-head {
  font-family: Source Sans Pro;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0,0,0,0.16);
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.content-table-data {
  margin-top: 30px;
  margin-left: 15px;
}

.content-point {
  display: flex;
  align-items: flex-start;
  margin-bottom: 18px;
  cursor: pointer;
  text-decoration: none;
}

.content-point:last-child {
  margin-bottom: 0;
}

.content-point:hover span {
  color: var(--primary) !important;
}

.content-point img {
  width: 16px;
  margin-right: 12px;
  margin-top: 4px;
}

.content-point div {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-black2);
  display: flex;
  flex-direction: column;
}

.content-point span {
  margin-bottom: 3px;
  font-family: Lato;
  font-size: 16px !important;
  font-weight: 600;
  color: var(--text-black);
}

.content-view {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 285px);
  height: 100%;
}

.selected_content span {
  color: var(--primary) !important;
}

.content-view .assesment-preview2{
  height:100% !important;
}


.title-grid3.correctionsClass{width: auto;}
.title-grid3.correctionsClass div{font-size:14px;}
.grid-actions.correctionsClass{margin-left:10px;}
.grid-actions.correctionsClass a{width: auto; height:auto}
.grid-actions.correctionsClass a img{width: 15px;}
.grid-actions.correctionsClass span{width: auto; height:auto}
.grid-actions.correctionsClass span img{width: 15px;}

.unitwrapper{display: flex; width: 100%; flex-direction: column; padding:15px; border-radius:10px; background-color:var(--primary-rgba);}
.unitwrapper .unit-head-data{background-color: var(--primary-rgba);  border: 1px solid var(--primary); padding:10px; border-radius: 5px;}
.unitwrapper .unit-head-data .unit-head-label{font-size: 26px; color:var(--black);}
.unitwrapper .subunit-data3{background-color: transparent; height:auto; padding:25px;}
.unitwrapper #content-item2{background-color: #fff; position: relative;}
.unitwrapper .title-grid3 img{width: 50px; margin-top: 0;}
.unitwrapper .title-grid3{width:100%;}
.unitwrapper .grid-actions{position:absolute; top:10px; right:10px; opacity: 0; visibility: hidden;}
.unitwrapper .grid-actions a{width: auto; height:auto}
.unitwrapper .grid-actions span:last-child{width: auto; height:auto}
.unitwrapper .grid-actions span:first-child{width: auto; height:auto}
.unitwrapper .grid-actions img{width: 15px;}
.unitwrapper #content-item2:hover  .grid-actions{opacity: 1; visibility: visible;}
.unitwrapper .subunit-data-layer{width: 100%;}
.unitwrapper .subunit-data-layer li{max-width: none; height:auto; min-width:inherit; margin:0 }
.unitwrapper #section-button3{position:absolute; bottom:20px; right:10px; background-color:transparent; display: flex; padding:6px 20px 8px 20px; font-size: 16px; align-self: flex-end; color:var(--primary); border-radius:40px; }
.unitwrapper .content-item{height:150px; padding: 25px; margin: 0 10px; width:auto; position: relative;}
.unitwrapper .actions{display: flex; align-self: flex-end; justify-content: flex-end; position:absolute; bottom:10px; right:10px;}
.unitwrapper .react-multiple-carousel__arrow--left{left: calc(1% + 1px);}
.unitwrapper .react-multiple-carousel__arrow--right{right: calc(1% + 1px);}
.react-multiple-carousel__arrow{background-color: var(--primary-rgba)!important; }
.react-multiple-carousel__arrow::before{color:var(--primary)!important}
.unitwrapper .title-grid3 div span{padding:0px; border-radius:4px;}
.unitwrapper .published{display: flex; align-items: flex-start; margin-top: 5px;}
.unitwrapper .published span{padding:0px; color:#898484; border-radius:4px;}
.unitwrapper .draft{display: flex; align-items: flex-start; margin-top: 5px;}
.unitwrapper .draft span{padding:0px; color:#898484; border-radius:4px;}
.unitwrapper .unit-head-data div button {background-color: var(--white); color: var(--primary);}
.unitwrapper .unit-handler{border:0px solid var(--primary); border-radius: 5px;}
.unitwrapper .unit-head-data .unit-head-label + div span{opacity: 1; visibility: visible;background-color:var(--primary-rgba);}
/*.unitwrapper:hover .unit-head-data .unit-head-label + div span{opacity: 1; visibility: visible; background-color:#e0f4f5;}*/
.unitwrapper .unit-handler-left a img{opacity: 0;}
.unitwrapper .unit-head-data div span:nth-child(2){margin-right: 0;}
.unitwrapper .subunit-data{background-color: transparent; height:auto; padding:25px;}
.unitwrapper .subunit-data-layer li:hover .grid-actions{opacity: 1; visibility: visible;}
.unitwrapper .subunit-data-layer li:hover .grid-actions span{width:auto; height:auto;}

.popup-data {
  height: auto ;
  padding-bottom:0px;
}

.video_url{
  width: 100% !important;
}

#section-button6{
  position: absolute;
  bottom: 18px;
  color: var(--primary);
  border-radius: 40px;
  cursor: pointer;
  font-family: lato;
}

#section-button4{
  position: absolute;
  bottom: 7px;
  left: 154px;
  font-size: 14px;
  font-family: lato;
  color: var(--primary);
  font-weight: 700;
  cursor: pointer;
}
.vjs_video_3-dimensions.vjs-fluid{
  padding-top: 48%;
}

.video-container {
  width: 100%;
  position: relative;
}

.video-container2 {
  width: 100vw;
}

video {
  outline: none;
}

.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}

#play_pause {
	background-color: transparent;
	width: 33%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 33%;
}

#rewind {
	background-color: transparent;
	width: 33%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

#rewind img {
	width: 18%;
	opacity: 0.65;
	cursor: pointer;
}

#forward {
	background-color: transparent;
	width: 33%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;	
	display: flex;
	align-items: center;
	justify-content: center;
}

#forward img {
	width: 18%;
	opacity: 0.65;
	cursor: pointer;
}

.YoutubePlayer{display: flex; height:450px}
.videoSurvayDialog.open{display: flex;}
.videoSurvayDialog{position: fixed; background-color: rgba(0,0,0,0.4); top:0; right:0; bottom:0;   font-family: lato;   z-index: 2200000; left:0; display: flex; display:none; align-items: center; justify-content: center;}
.videoSurvayDialog .videoSurvayContainer{position: relative; flex-direction: column; display: flex; width:100%; max-width: 600px; background-color:#fff; border-radius: 10px; padding: 15px; box-shadow: 0 0 5px #ddd;;}
.videoSurvayDialog .videoSurvayContainer .dialogClose{position: absolute; cursor: pointer; top:10px; right:10px; color:#000; font-size: 16px;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper{display: flex; width: 100%; flex-direction: column;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol{margin:0; padding:0; grid-gap:20px; gap:20px; display: flex; flex-direction: column;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li{margin:0; display: flex; flex-direction: row; grid-gap:15px; gap:15px; padding:0; padding:10px; bordeR:1px solid #ddd; padding:10px; border-radius: 10px;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .question{text-align: left; flex:1 1; font-family: inherit; font-size: 16px;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .rating{text-align: center; grid-gap:15px; gap:15px; display: flex; justify-content: center;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .rating label{display:flex; align-items: center; margin:0; padding:0; justify-content: center;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .rating label input[type="radio"]{display: none;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .rating label span{width:32px; height: 32px; display:flex; align-items: center; justify-content: center; border-radius: 100%; border:1px solid #ddd;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .rating label input[type="radio"]:checked +  span{background-color: var(--primary); color:#fff;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .rating span:hover{ cursor: pointer; border:1px solid var(--primary); background-color: var(--primary); color:#fff;}
.videoSurvayDialog .videoSurvayContainer  h3{margin-bottom: 15px; text-align: center;}
.videoSurvayDialog .videoSurvayContainer .formActions{display: flex; grid-gap:15px; gap:15px; width: 100%; justify-content: center; align-items: center; margin-top:15px; border-top:1px solid #ddd;padding-top: 10px;}
.videoSurvayDialog .videoSurvayContainer .formActions span{cursor: pointer; display: none; color:var(--primary); text-decoration: underline;}
.videoSurvayDialog .videoSurvayContainer .formActions span:hover{cursor: pointer; color:var(--primary); text-decoration: none;}

.videoSurvayThankuDialog.open{display: flex;}
.videoSurvayThankuDialog{position: fixed; background-color: rgba(0,0,0,0.4); top:0; right:0; bottom:0;   font-family: lato;   z-index: 2200000; left:0; display: flex; display: none;  align-items: center; justify-content: center;}
.videoSurvayThankuDialog .videoSurvayThankuDialogContainer{position: relative; flex-direction: column; display: flex; width:100%; max-width: 600px; background-color:#fff; border-radius: 10px; padding: 15px; box-shadow: 0 0 5px #ddd;;}
.videoSurvayThankuDialog .videoSurvayThankuDialogContainer .dialogClose{position: absolute; cursor: pointer; top:10px; right:10px; color:#000; font-size: 16px;}
.videoSurvayThankuDialog .videoSurvayThankuDialogContainer  h3{margin:0; margin-bottom: 5px; font-size: 28px; color: green; text-align: center;}
.videoSurvayThankuDialog .videoSurvayThankuDialogContainer  h5{margin:0; margin-bottom: 15px; font-size: 16px; color:#999; text-align: center;}
.videoSurvayThankuDialog .videoSurvayThankuDialogContainer  p{padding:0; margin:0; line-height: 24px; text-align: center;}
.videoSurvayThankuDialog .videoSurvayThankuDialogContainer .formActions{display: flex; width: 100%; justify-content: center; align-items: center; margin-top:15px; border-top:1px solid #ddd;padding-top: 10px;}


/* overrides for pdf_viewer.css from PDF.JS web viewer */

.textLayer {
  z-index: 2;
  opacity: 1;
  mix-blend-mode: multiply;
}

.annotationLayer {
  position: absolute;
  top: 0;

  z-index: 3;
}

html
  body
  .textLayer
  > div:not(.PdfHighlighter__highlight-layer):not(.Highlight):not(.Highlight-emoji) {
  opacity: 1;
  mix-blend-mode: multiply;
}

.textLayer ::selection {
  background: rgba(252, 232, 151, 1);
  mix-blend-mode: multiply;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .textLayer {opacity: 0.5;}
}

/* Internet Explorer support method */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .textLayer {opacity: 0.5 }
}

/* Microsoft Edge Browser 12+ (All) - @supports method */
@supports (-ms-ime-align:auto) {
  .textLayer {opacity: 0.5 }
}

.PdfHighlighter {
  position: absolute;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.PdfHighlighter__highlight-layer {
  position: absolute;
  z-index: 3;
  left: 0;
}

.PdfHighlighter__tip-container {
  z-index: 6;
  position: absolute;
}

.PdfHighlighter--disable-selection {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

.MouseSelection {
  position: absolute;
  border: 1px dashed #333;
  background: rgba(252, 232, 151, 1);
  mix-blend-mode: multiply;
}
/* Internet Explorer support method */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .MouseSelection {opacity: 0.5 }
}

/* Microsoft Edge Browser 12+ (All) - @supports method */
@supports (-ms-ime-align:auto) {
  .MouseSelection {opacity: 0.5 }
}

.Tip__compact {
  cursor: pointer;
  background-color: #3d464d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
}

.Tip__card {
  padding: 10px;
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(37, 40, 43, 0.2);
}

.Tip__card textarea {
  font-size: 16px;
  width: 200px;
  height: 70px;
}

.Tip__card input[type="submit"] {
  margin-top: 5px;
  font-size: large;
}

.Highlight {
  position: absolute;
}

.Highlight__emoji {
  position: absolute;
  color: black;
  opacity: 1;
  font-size: 28px;
}

.Highlight__parts {
  opacity: 1;
}

.Highlight__part {
  cursor: pointer;
  position: absolute;
  background: rgba(255, 226, 143, 1);
  transition: background 0.3s;
}

.Highlight__popup {
  background-color: #3d464d;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  max-width: 300px;
  max-height: 100px;
  overflow-y: scroll;
}

.Highlight--scrolledTo .Highlight__part {
  background: #ff4141;
}

.AreaHighlight {
  border: 1px solid #333;
  background-color: rgba(252, 232, 151, 1.0);
  opacity: 1;
  mix-blend-mode: multiply;
}

.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.pdf-web {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: #ffffff;
	z-index: 1000000 !important;
	background-color: #f5f5f5;
}

.pdf-noweb .e-pv-viewer-container {
	width: 711px !important;
	/*height: 430px!important;*/
}

.downloadMessage{display: flex; width: 100%; justify-content:center;  background-color: #ddd; padding:20px; font-family: Lato;}

.pdf-noweb .e-pv-page-container{
	width: 702px !important;
}

.pdf-noweb .e-pv-page-div{
	left:20px !important;
}

.pdf-web .e-pv-viewer-container {
  width: 1248px !important;
}

.pdf-web .e-pv-page-container{
  width: 1239px !important;
}

.pdf-web .e-pv-page-div{
  left:288px !important;
}



.PdfHighlighter {
  left: 0;
  top: 0;
}

.full-view {
	position: absolute;
	top: 63px;
	right: 40px;
	z-index: 100;
	width: 46px;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: transparent;
	cursor: pointer;
	float: right;
}

.full-view img {
	width: 40px;
}


.pdfViewer.removePageBorders .page{
	margin: 0px auto 10px auto;
	border: none;
	width: 100% !important;
	height:auto !important;
}

.pdfViewer .canvasWrapper{
	width: 100% !important;
    height: auto !important;
}

.textLayer{
	width: 100% !important;
	height: auto !important;
}

.pdfViewer .page canvas{
	width: 100% !important;
    height: auto !important;
}

.e-pv-open-document-container ,.e-pv-annotation-container,.e-pv-print-document-container,.e-pv-comment-container,.e-pv-submit,.e-pv-text-search-container,.e-pv-undo-container,.e-pv-redo-container,.e-pv-thumbnail-view-button, .e-pv-bookmark-button ,.e-pv-text-select-tool-container,.e-pv-pan-tool-container{
	visibility: hidden;
}
.content-filter-section {
  width: 400px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--secondary-rgba);
  border-radius: 20px;
  overflow-y: auto;
  padding: 30px 40px 100px 40px;
  transition: width .3s, height .3s;
}

.extewrnalURL{display: flex; width: 100%; grid-gap:10px; gap:10px; font-family: Lato; flex-direction: column;}
.extewrnalURL a{color:var(--primary); font-size: 16px; text-decoration: underline;}
.extewrnalURL a:hover{color:var(--primary); font-size: 16px; text-decoration: none;}
.extewrnalURL p{margin-bottom: 0; }

.content-filter-section img {
  width: 26px;
}

ul, li {
  list-style-type: none;
}

.dd-wrapper ul {
  padding-left: 0;
}

.dd-wrapper ul ul {
  padding-left: 10px;
}

.dd-wrapper ul ul ul {
  padding-left: 20px;
}

.dd-wrapper ul ul ul ul {
  padding-left: 30px;
}

.has-sub2 ul {
  padding-left: 0 !important;
}

.content-filter-section .main-title, .has-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border: 1px solid var(--light-black);
  padding: 16px 20px;
  border-radius: 5px;
  font-family: Lato;
  font-weight: 600;
  color: var(--text-black);
  font-size: 16px;
  cursor: pointer;
}

.has-sub2 .has-title2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  border: 1px solid var(--light-black);
  padding: 16px 20px;
  border-radius: 5px;
  font-family: Lato;
  font-weight: 400;
  color: var(--text-black);
  font-size: 16px;
  cursor: pointer;
}

.has-title2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.has-title2 div {
  display: flex;
  align-items: center;
}

.content-descriptionx {
  font-family: Source Sans Pro;
  font-size: 17px;
  margin-bottom: 4px;
  margin-left: 36px;
  font-weight: 400 !important;
}

.main-title img, .has-title img {
  width: 19px;
  margin-left: 20px;
}

.has-sub2 .has-title2 img {
  width: 21px;
  margin-right: 16px;
}

#content-selec {
  background-color: var(--primary-rgba) !important;
  color: var(--text-black) !important;
  font-weight: bold;
}

.content-filter-toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  background-color: var(--secondary-rgba);
}

.content-filter-toggle span {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-rgba);
  padding: 8px 10px;
  cursor: pointer;
}

.content-filter-toggle span:hover {
  opacity: 0.85;
}

.content-filter-toggle img {
  width: 24px;
}

.assesment-preview {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding-bottom: 100px;
}

.assesment-preview2 {
  width: 100%;
  height: 440px;
  overflow-y: auto;
}

.assessment-preview-head .title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.assessment-preview-head .title span {
  font-family: Lato;
  font-weight: 700;
  font-size: 19px;
}

.assessment-preview-head .title span span {
  color: var(--primary);
}

.assessment-preview-head .unique-code {
  padding: 10px 20px 11px 20px;
  border-radius: 4px;
  background-color: var(--primary-rgba);
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
}

.submit-by-date, .submit-by-date2 {
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  color: var(--text-black2);
  margin-top: 15px;
  display: flex;
}

.submit-by-date div, .submit-by-date2 div {
  color: var(--primary);
  margin-right: 10px;
}

.assesment-preview .instructions, .assesment-preview2 .instructions {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  color: rgba(0,0,0,0.84);
  margin-bottom: 5px;
  margin-top: 30px;
}

.assesment-preview .preview-container, .assesment-preview2 .preview-container {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.45;
}

.action-buttonsx2 button, .action-buttonsx3 button {
  margin-right: 20px;
}

.action-buttonsx2 button:last-child, .action-buttonsx3 button:last-child {
  margin-right: 0px;
}

.summary-values {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.summary-values #total_question_data {
  display: flex;
  align-items: center;
  width: 46%;
  font-weight: 400;
  font-family: Lato;
  font-size: 15px;
  line-height: 1.4;
  margin-bottom: 25px;
}

#total_question_data img {
  width: 60px;
  margin-right: 15px;
}

#total_question_data:nth-child(2n) {
  margin-left: 8%;
}

#total_question_data div span:nth-child(2) {
  margin-top: 6px;
}

#total_question_data div {
  display: flex;
  flex-direction: column;
}

.action-buttonsx2 {
  background-color: var(--white);
  position: fixed;
  width: 747px;
  bottom: 30px;
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.action-buttonsx3 {
  position: absolute;
  width: 100%;
  bottom: 45px;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
}

.assignment-review-last-bottom-button{
  position: fixed;
  bottom: 59px;
  background-color: white;
  width: 46%;
  height: 43px;
  text-align: center;
}

.assignment-review-last-bottom-button1{
  position: fixed;
  bottom: 30px;
  background-color: white;
  width: 717px;
  height: 43px;
  text-align: center;
}

.link1-review{
  border-radius: 6px;
  padding: 10px;
  background-color: #19b5b5;
  color: white;
  font-family: lato;
  font-weight: 700;
  position: fixed;
}


.link2-review{
  border-radius: 6px;
  padding: 10px;
  background-color: #19b5b5;
  color: white;
  font-family: lato;
  font-weight: 700;
  position: fixed;
}


.admin-calendar {
  z-index: 90 !important;
  position: sticky;
  padding-top: 40px;
  top: 0px;
  height: 80px;
	width: 100%;
	display: flex;
	flex-direction: column;
  align-items: center;
  background-color: var(--white);
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.cal-header {
  margin-top: 25px;
	position: absolute;
	top: 0;
	width: calc(100% - 120px);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cal-month {
	display: flex;
	align-items: center;
}

.cal-month-name {
	font-family: Lato;
	font-size: 16px;
	font-weight: 700;
	margin-left: 15px;
	margin-right: 15px;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.cal-month span {
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cal-month img {
	width: 20px;
}

.cal-dates {
	width: 100%;
	display: flex;
}

.calendar_tray {
	z-index: 2;
	margin-top: 50px;
	width: calc(100% - 120px);
	border-radius: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: var(--secondary-rgba);
}

.header-op {
  display: flex;
  align-items: center;
}

.header-op span {
  cursor: pointer;
  margin-left: 20px;
  margin-right: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 44px;
  border-radius: 8px;
}

.header-op span img {
  margin: 0 0 !important;
}

.header-op span:hover {
  background-color: var(--primary-rgba);
}

.header-op img {
  width: 28px;
}

.header-op2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-op2 select {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 6px 12px;
  border-radius: 4px;
}

.header-op2 select:nth-child(2), .header-op2 select:nth-child(3) {
  margin-left: 15px;
}
.popupx-inputs input:nth-child(2){
  width: 22px;
  margin-left: 100px;
}
 
.slide-buttons {
	z-index: 1;
	position: absolute;
	bottom: 38px;
	width: 100%;
	display: inline-block;
}

.slide-buttons span {
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary);
	border-radius: 50%;
	cursor: pointer;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.slide-buttons img {
	width: 20px;
}

.slide-buttons span:first-child {
	padding-right: 2px;
}

.slide-buttons span:last-child {
	padding-left: 2px;
}

.slide-buttons span:hover {
	opacity: 0.8;
}

.slide-buttons span:first-child {
	float: left;
}

.slide-buttons span:last-child {
	float: right;
}

.slide-buttons .disable {
	opacity: 0.45;
}

.slide-buttons .disable:hover {
	opacity: 0.45;
}

.calendar_tray li {
	width: 12.5%;
	cursor: pointer;
	display: flex;
	justify-content: center;
}

.cal-buttons {
	width: 100%;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
}

.cal-date {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 12px 24px 12px 24px;
	border-radius: 10px;
}

.calendar_tray li:hover .cal-date {
	background-color: var(--secondary);
}

.cal-date span {
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	font-family: Lato;
}

.cal-date span:first-child {
	font-size: 18px;
	font-weight: 700;
	color: var(--text-black3);
}

.cal-date span:last-child {
	margin-top: 10px;
	font-size: 20px;
	font-weight: 900;
	color: var(--text-black);
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;;
}

.cal-selected span:last-child {
	background-color: var(--primary);
	color: white;
}

.choose-content2 {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: flex-start;
  margin-top: 20px;
  align-self: center;
}

.type-content2 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
  cursor: pointer;
}

.type-content2:last-child {
  margin-bottom: 0;
}

.type-content2 img {
  width: 72px;
}

.type-content2 div {
  margin-left: 28px;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
  display: flex;
  flex-direction: column;
}

.type-content2 span {
  font-family: Lato;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-black);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.popupx-inputs div {
  display: flex;
  align-items: center;
}

/* Sessions */

.lecture-list {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 140px;
}

.lecture-list-x2 {
  width: 100%;
  margin-top: 40px;
  overflow-y: scroll;
  height: calc(100vh - 330px);
}

.session-div {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
  z-index: 10 !important;
}

.session-date {
  padding-top: 40px;
  width: 260px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Lato;
  font-weight: 600;
  font-size: 18px;
}

.session-date span {
  font-size: 22px;
  margin-bottom: 5px;
}
.wrap{flex-wrap: wrap;}
.marginTop20{margin-top:20px;}
.session-content {
  position: relative;
  width: 600px;
  height: 200px !important;
  height: 220px !important;
  border-radius: 15px !important;
  background-color: var(--secondary-rgba);
  box-shadow: 1px 1px 6px 0px #eeeeee;
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  transition: .2s all;
  cursor: pointer;
}

.session-up {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Lato;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.4;
  overflow: hidden;
}

.session-up div {
  display: flex;
  flex-direction: column;
}

.lecture_data_labels:first-child {
  margin-bottom: 3px;
}

.session-up span {
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
}

#session-band {
  position: absolute;
  padding: 5px 14px 6px 14px;
  right: 0;
  top: 30px;
  background-color: var(--secondary-rgba);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.01em;
}

.lecture-room {
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.lecture-room input[type="checkbox"] {
  width: 20px !important;
  height: 20px;
  margin-left: 15px !important;
  margin-right: 10px !important;
}

.lecture-room label {
  font-family: Lato;
  font-size: 15px;
}

.lecture_data_labels {
  font-weight: 400;
  font-size: 16px;
}

#live_class_location {
  max-width: 150px;
  overflow: hidden;
  position: absolute;
  text-decoration: none;
  background-color: var(--primary);
  color: var(--white);
  font-family: Lato;
  font-weight: 700;
  font-size: 15px;
  bottom: 25px;
  right: 160px;
  padding: 5px 10px 6px 10px;
  border-radius: 4px;
}

.edit-lecture-button {
  position: absolute;
  bottom: 25px;
  right: 90px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px;
  background-color: var(--light-black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-lecture-button2 {
  position: absolute;
  bottom: 25px;
  right: 30px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px;
  background-color: var(--light-black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-lecture-button img, .edit-lecture-button2 img {
  width: 20px;
}

.dash-test-bottom {
  display: flex;
  align-items: flex-end;
}

.dash-test-bottom div:first-child {
  display: flex;
  flex-direction: column;
  width: 60%;
  overflow: hidden;
}

.dash-test-bottom div span {
  margin-top: 7px;
}

.dash-test-bottom div span:first-child {
  font-size: 15px;
  line-height: 1.36;
  white-space: pre-wrap !important;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  max-height: 62px;      /* fallback */
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.dash-test-bottom span {
  white-space: nowrap;
}

.fac_dp_img {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.fac_dp {
  min-width: 34px !important;
  max-width: 34px !important;
  min-height: 34px !important;
  max-height: 34px !important;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 12px;
}

#action-reverse {
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 18px;
  color: var(--primary);
  width: -webkit-max-content !important;
  width: max-content !important;
}

.back-to-recent{
    font-family: Source Sans Pro; 
    font-size: 14px;
    /* font-weight: 600; */
    color:var(--white);
    /* border: 1px solid var(--primary); */
    background-color:var(--primary);
    /* border: none; */
    padding-left: 23px;
    border-radius: 40px;
    cursor: pointer;
    /* margin-left: 16px; */
    width: 88px;
}
.page-header-session-learner{
  align-items: flex-end;
  background-color: transparent;
}
.page-header-faculty{
  align-items: flex-end;
  background-color: transparent;
}

.back-to-recent-faculty{
    font-family: Source Sans Pro;
    font-size: 14px;
    /* font-weight: 600; */
    color:var(--white);
    /* border: 1px solid var(--primary); */
    background-color: var(--primary);
    /* border: none; */
    padding-left: 23x;
    border-radius: 40px;
    cursor: pointer;
    /* margin-left: 16px; */
    width: 88px;
    
}
.dash-test-bottom button{
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
    border: 1px solid var(--primary);
    background-color: var(--primary);
    border: none;
    padding: 6px 20px 8px 20px;
    border-radius: 40px;
    cursor: pointer;
    width: 119px;
}

.list-content{
  height: 100vh
}
.dash-test-no-found{
    width: 530px;
    margin-bottom: 30px;
    border-radius: 10px;
    transition: .2s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: Lato;
    font-size: 19px;
    font-weight: 600;
    color: #2e6d96;
    text-decoration: none;
    background-color: var(--primary-rgba);
    padding: 19px;
}

.active a {
    background-color: var(--primary);
    color:var(--white)
}

.pagination a {
  color:var(--black);
    float: left;
    padding: 6px 22px;
    text-decoration: none;
    /* margin-top: 10px; */
    border: 1px solid var(--primary) !important;
}


.main-part {
    z-index: 1;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    height: calc(100% - 110px ) !important;
    overflow-y: auto;
}

.pagination {
    display: contents !important;
}


.questionbank-table{border-collapse: collapse; width: 100%; font-family: Source Sans Pro; margin-top: 20px;}
.questionbank-table th{padding:10px 15px; text-align: left; font-weight: bold; color:#586C83; font-size:15px;}
.questionbank-table td{padding:15px 15px; vertical-align: top; text-align: left; border-top:1px solid #E7E7E7; font-weight: normal; color:#586C83; font-size:15px;}
.questionbank-table td .difficulty-easy{white-space: nowrap; border:1px solid var(--primary); color:var(--primary); border-radius:2px; padding:2px 5px;}
.questionbank-table td:first-child{width: 35%; vertical-align: top;}
.questionbank-table td:last-child span{margin-left: 10px; cursor: pointer;}
.questionbank-table td:last-child span img{width: 15px; height:15px;}
.questionbank-table td span:first-child{margin-left: 0px;}
.questionbank-table tr:nth-child(odd){background-color:#FBFBFB}

.question-bank-dialog .popup-content{padding:25px!important}
.question-bank-dialog .popup-content .popup-header{margin-bottom: 15px; text-align: left; border-bottom:1px solid #ddd; padding-bottom:10px;}
.question-bank-dialog .dialog-content{overflow-y: auto; max-height: 260px; margin-bottom: 15px;}
.question-bank-dialog .subunit-data2{padding: 15px;}
.question-bank-dialog .asm-ques-settings{margin-right:0}
.question-bank-dialog .close{top:15px;}

.popup-inputs img{
  width: 50px;
  cursor: pointer;
}

.multi-select {
  width: 71%;
  margin-left: 14px;
}

.dropdown-content div{
  padding:0px !important;
}

.dropdown-content{
  height:100px;
}

.popupx-dropdowns{
  display: flex;
}
.go2646822163 dropdown-container{
  border: 1px solid var(--primary);
}

.popup-data4 .popupx-dropdowns:last-child{margin-bottom: 0;}

.dropdown-container{
  height: 45px;
  /* border: 1px solid #efb953; */
}

.go2139093995 .gray {
    color: #131212;
    font-family: lato;
}

.popupx3-inputs input:nth-child(2){
  width: 84% !important;
}

.schedule-img {
    width: 140px;
    min-height: 72px;
    /* border-radius: 50%; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* margin-top: 30px; */
    /* margin-bottom: 0px; */
    /* box-shadow: 2px 2px 6px 0px #eeeeee; */
}

.heading-part1-ques{
  min-width: 40% !important;
}

.dash-flex2-img img{
  width:100px;
}

.grid-up span{
    color: var(--primary);
    font-weight: 700;
    cursor: pointer;
}

.questionbank-table td span{
  cursor: pointer;
  color: #489198;
  font-weight: 700;
}
.export-file{
  margin-top: 10px;
  cursor: pointer;
}

.export-file img{
  width: 25px;
}

.export-file span{
  font-family: lato;
  font-size: 20px;
  border: 1px solid white;
  padding: 14px;
}


.assessmentsReportsWrapper{display: flex; width: 100%; font-family: lato; margin-top: 20px; border-top:1px solid #ddd; padding-top: 20px;}
.assessmentsReportsWrapper .assessmentsReportsContainer{display: flex; width: 100%; position: relative; flex-direction: column;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper{display: flex; flex-direction: column; margin-right: 10px; margin-bottom: 10px;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper label{margin:0; text-transform: capitalize; padding:0; margin-bottom: 10px;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper select{width:100%; min-width:250px; min-height: 38px; max-width:250px; border:1px solid var(--primary); background-color:var(--primary-rgba);}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .reportsSelectBox {width:100%; min-width:250px; max-width:250px; border-radius: 4px; border:1px solid var(--primary); background-color: var(--primary-rgba);}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .reportsMultiSelectBox {width:100%; min-width:250px; max-width:250px; border-radius: 4px; border:1px solid var(--primary); background-color:var(--primary-rgba);}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .reportsMultiSelectBox:focus{outline: 0px none;  box-shadow: 0px 0px 0px 0px;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .reportsSelectBox:focus{outline: 0px none; box-shadow: 0px 0px 0px 0px;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .css-14el2xx-placeholder{color:inherit}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .css-tlfecz-indicatorContainer{color:inherit}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .reportsMultiSelectBox .css-1s2u09g-control{ border-color:transparent!important; box-shadow: 0px 0px 0px 0px;border-width: 0px;  border:0px none; background-color: transparent;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .reportsMultiSelectBox .css-1s2u09g-control:hover{ border-color:#ff0000!important; box-shadow: 0px 0px 0px 0px!important;border-width: 0px;  border:0px none; background-color: transparent;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .reportsSelectBox .css-1s2u09g-control{ border-color:#ff0000!important; box-shadow: 0px 0px 0px 0px!important; border-width: 0px; border:0px none; background-color: transparent;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .reportsSelectBox .css-1s2u09g-control:hover{ border-color:#ff0000!important; box-shadow: 0px 0px 0px 0px!important; border-width: 0px; border:0px none; background-color: transparent;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper input[type="date"]{width:100%; min-height: 38px; font-family: lato; padding: 5px 16px; border-radius: 4px; min-width:250px; max-width:250px; border:1px solid var(--primary); background-color:var(--primary-rgba);}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper label + div{position: relative;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper div[role="combobox"] input{ height:33px; width:100%; font-family: lato; padding: 5px 16px; border-radius: 4px; min-width:250px; max-width:250px; border:1px solid #489198; background-color: #e9f5f6;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper div ul{background-color: #fff; left:0; right:0; z-index: 2; position:absolute; padding:0; margin:0; border-radius:4px; border: 1px solid rgba(0,0,0,.15);}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper div ul:empty{border:0px none}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper div ul li{padding:10px 10px; font-family: lato; }
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper div ul li:hover{background-color: #e9f5f6;}
.assessmentsReportsWrapper .assessmentsReportsContainer .selectBoxWrapper{display: flex; width: 100%; flex-wrap: wrap; flex-direction: column;}
.assessmentsReportsWrapper .assessmentsReportsContainer .selectBoxWrapper h2{margin-bottom: 15px;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .AssessmentTypesBlock{display: flex; flex-wrap: wrap; width:100%;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .block{display: flex; width:100%; max-width: 250px; flex-direction: column; margin: 0 10px 10px 0;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .block .multiSelectContainer {width:100%; position: relative;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .block .multiSelectContainer .search-wrapper{width: 100%; display:inline-block}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .block .multiSelectContainer .search-wrapper .chip{ white-space: normal; background-color: #489198;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .block .multiSelectContainer input{cursor: pointer;}
.assessmentsReportsWrapper .assessmentsReportsContainer .AssessmentTypesWrapper .block:last-child{margin-right: 0;}
.assessmentsReportsWrapper .filtersWrapper {display:flex; flex-wrap: wrap; width: 100%; flex-direction: column;}
.assessmentsReportsWrapper .filtersWrapper .labelWrapper{display: flex; align-items: center; flex-wrap: wrap; margin:10px 0 10px; border-top:1px solid #ddd; padding-top: 10px;}
.assessmentsReportsWrapper .filtersWrapper .labelBlock{display: flex; align-items: center; margin: 0 10px 15px 0;}
.assessmentsReportsWrapper .filtersWrapper .labelBlock:last-child{margin-right: 0;}
.assessmentsReportsWrapper .filtersWrapper .labelBlock label{margin: 0; padding:0; cursor: pointer; display: flex; align-items: center;}
.assessmentsReportsWrapper .filtersWrapper .labelBlock label input{margin:0 5px 0 0; padding: 0; opacity: 0; visibility: hidden; display: none;} 
.assessmentsReportsWrapper .filtersWrapper .labelBlock label span{width: 16px; height: 16px; border-radius: 2px; margin-right: 5px; background-color: #fff; border: 1px solid var(--primary);}
.assessmentsReportsWrapper .filtersWrapper .labelBlock label input:checked + span{background-size: contain; background-color: transparent; border: 0px none; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDM2MCwgMjAyMC8wMi8xMy0wMTowNzoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkVDRjRGNUEwMjAyQzExRUM4MEYzOTNCNDNFREM2RkIwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkVDRjRGNUExMjAyQzExRUM4MEYzOTNCNDNFREM2RkIwIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RUNGNEY1OUUyMDJDMTFFQzgwRjM5M0I0M0VEQzZGQjAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RUNGNEY1OUYyMDJDMTFFQzgwRjM5M0I0M0VEQzZGQjAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4RB6UFAAAA3ElEQVR42mL8//8/AyWAiYFCQJYBnpNmZgJxO1kGADXWAqlpQFxBsgFAzWVAqgnKPUGSAUDNKUCqE8p9D8Q+RBsA1BwNpGYjCdlsz0t/S5QBQM1hQGoJshBQ8zUYhwWq6B6QAuEwoOQ7JM1+QGolkuZ0oPwObNGoCMTOQHwZqEkWqjkASG1EUtsG1DwLVzpohNJSQHwYqDkDSK9HUrcKqLkamxcZYUkZqOk+kFLAouYyULMeMSnRFoi/osm/gooTTspAW54AKWMgvgMVugLEhkDxj/gMYBzw3AgQYACZfUjpgI1+DgAAAABJRU5ErkJggg==); background-repeat: no-repeat;}
.assessmentsReportsWrapper .assessmentsReportsActions{border-top:1px solid #ddd; display: flex; margin-top: 10px; padding-top: 10px; justify-content: flex-end; align-items: center;}
.assessmentsReportsWrapper .assessmentsReportsDownload{border-top:0px solid #ddd; display: flex; margin: 10px 0 10px; padding: 10px 0; justify-content: flex-end; align-items: center;}
.assessmentsReportsWrapper .assessmentsTableWrapper{display: flex; flex-direction: column; width: 100%; margin-top: 30px;}
.assessmentsReportsWrapper .assessmentsTableWrapper h2{ border-bottom: 1px solid #ddd; padding-bottom: 10px;}
.assessmentsReportsWrapper .assessmentsTableWrapper table{width:100%; border:1px solid #ddd; border-collapse: collapse;}
.assessmentsReportsWrapper .assessmentsTableWrapper table th{padding:10px 10px; text-transform: capitalize; border:1px solid #ddd; width:auto; min-width: 150px; text-align: left;}
.assessmentsReportsWrapper .assessmentsTableWrapper table td{padding:10px 10px; border:1px solid #ddd; width:auto; min-width: 150px; text-align: left;}
.assessmentsReportsWrapper .assessmentsTableWrapper table th:first-child{min-width: 70px;}
.assessmentsReportsWrapper .assessmentsTableWrapper table td:first-child{min-width: 70px;}
.assessmentsReportsWrapper .assessmentsTableWrapper table tr:nth-child(odd) td{background-color: #eeeded;}
.assessmentsReportsWrapper .noReportsFound{border:1px solid #ddd; padding: 10px; margin-top: 30px; text-align: center;background-color:var(--secondary-rgba);}
.assessmentsReportsWrapper .assessmentsReportsContainer .reportsLoader{position: absolute; opacity: 0; visibility:hidden; left:0;  right:0; bottom:0; top:0; z-index: 220000; background-color: rgba(0,0,0,0.1); display: flex; align-items: center; justify-content: center;}
.assessmentsReportsWrapper .assessmentsReportsContainer .reportsLoader.show{opacity: 1; visibility: visible;}

.assessmentsReportsWrapper .assessmentsReportsContainer .reportsLoader span {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid var(--primary); /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.assessmentsReportsWrapper .filtersWrapper .labelBlock{background-color:var(--primary-rgba); border-radius:15px; border:1px solid var(--primary); padding: 5px 15px;}
.student-list{
	background-color: var(--primary-rgba)
}
.student-center{
	width:35% !important;
}
#no-data-admin{
    /* text-align: center; */
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    font-family: Lato;
    font-weight: 700;
    font-size: 25px;
    /* padding-top: 150px; */
    color: var(--text-black2);
}

.popup-add-program-content{
    position: relative;
    background: rgb(255, 255, 255);
    width: 64% !important;
    margin: auto;
    border: 1px solid rgb(187, 187, 187);
    padding: 5px;
    height: 75% !important;
}

.popup-data-program{
    height: 387px;
    padding-bottom: 5px;
    margin-top:10px;
}
.popup-data-program.newdesign select{max-width:none; width: 100%; margin-left: 20px;}
.popup-data-program.newdesign input{max-width:none; width: 100%; margin-left: 20px;}
.popup-data-program.newdesign input:first-child{margin-left:0px; width:100%!important}
.session-label{
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 600;
    margin-left: 16px;
    margin-top: 11px;
}

.popup-data-session{
    padding-top: 15px;
    height: 300px !important;
}

.popup-session-content{
    height:450px !important;
}

.content-message{
    margin-left: 13px;
    line-height: 33;
}

.prog-sem{
    overflow-y: scroll;
    height: 272px;
    margin-top: 26px;
}
.prog-sem div{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.prog-sem div span {
    display: block;
    min-width: 27px;
    height: 37px;
    border-radius: 5px;
    background-color: var(--white);
    border: 1px solid var(--primary);
    color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
    font-weight: bold;
    font-family: Lato;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}

.prog-sem div input{
    margin-left: 12px;
    width: 22%;
    font-size: 16px;
    font-family: Lato;
    color: var(--text-black);
    background-color: var(--white);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 9px 14px 10px 14px;
    border-radius: 4px;
}

.prog-sem div input[type="textarea"]{
    width:40%;
}

.popup-inputs-programs{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup-inputs-programs input, .popup-inputs2 input, .popup-inputs-programs select, .popup-inputs5 input{
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 12px 16px;
    border-radius: 4px;
}

.popup-inputs-programs input:first-child, .popup-inputs-programs select:first-child{
    width:20% !important;
}

.popup-inputs-session {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 640px;
}

.popup-inputs-session-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 640px;
}

.popup-inputs-session input, .popup-inputs2 input, .popup-inputs-session select, .popup-inputs5 input {
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 12px 16px;
    border-radius: 4px;
    width: 205px !important;
}

.popup-inputs-session-date input{
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 12px 16px;
    border-radius: 4px;
    width: 300px !important;
}

.popup-inputs-session input[type="textarea"]{
    width: 100% !important;
    height: 63px;
}

#remove-opt{
    margin-left: 10px;
    border-radius:5px !important;
    padding: 10px;
}

#remove-opt img{
    width: 23px;
}



.app-body.ugpgprogram-page .app-div {
    width: auto;
    padding: 10px 20px 25px 20px;
    top: 50px;
    left: 150px;
    height: calc(100vh - 55px);
    background-color: #fff;
    border-radius: 0px;
    right: 0;
}


.UGPGProgramWrapper{width:100%; border:0px solid #ff0000; font-family: Lato;}

.UGPGProgramWrapper .categoryWrapper{display: flex; width:100%; margin: 20px 0; align-items: center; justify-content: center; flex-direction: column;}
.UGPGProgramWrapper .categoryWrapper .banner{margin-bottom: 30px; text-align: center;}
.UGPGProgramWrapper .categoryWrapper .banner img{max-width: 100%; margin-left: 15px;}
.UGPGProgramWrapper .categoryWrapper .banner img:first-child{margin-left:0px;}
.UGPGProgramWrapper .categoryWrapper .heading{font-size:21px; font-weight:bold; font-family: Lato; margin-bottom: 15px;}
.UGPGProgramWrapper .categoryWrapper ol{display: flex; flex-wrap: wrap; margin:0; padding:0; justify-content: center;}
.UGPGProgramWrapper .categoryWrapper ol li{display: flex; margin:0; margin-left: 15px; padding:0;}
.UGPGProgramWrapper .categoryWrapper ol li a{ color:#000; display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 8px 25px; border-radius:5px; font-family: var(--defaultfont);  margin-left:0px; border:1px solid #ddd;}
.UGPGProgramWrapper .categoryWrapper ol li:first-child{margin-left: 0;}
.UGPGProgramWrapper .categoryWrapper ol li a:hover{background-color: #1bce52; color:#fff;}
.UGPGProgramWrapper .categoryWrapper ol li a.active{background-color: #1bce52; color:#fff;}
.UGPGProgramWrapper .categoryWrapper ol li .title{text-align: center;}


.designCategoryWrapper.businesstab{display: none;}
.designCategoryWrapper.technologytab{display: none;}
.designCategoryWrapper{display: flex; flex-direction: column; width: 100%; font-family: 'Roboto';} 
.designCategoryWrapper .designCategoryContainer{display: flex; width: 100%; align-items: flex-start; flex-direction: column;} 
.designCategoryWrapper .designCategoryContainer .leftsection{display: flex; width: 100%; margin-bottom: 30px;}
.designCategoryWrapper .designCategoryContainer .leftsection ol{list-style-type: none; flex-wrap: wrap; justify-content: center; margin:0; padding:0; width: 100%; display: flex;}
.designCategoryWrapper .designCategoryContainer .leftsection ol li{display: flex; list-style-type: none; margin:0; padding:0; box-sizing: border-box;}
.designCategoryWrapper .designCategoryContainer .leftsection ol li a{width: 100%; box-sizing: border-box; padding: 8px 10px; border-bottom:2px solid transparent; color: #666; font-size: 17px; display: flex;}
.designCategoryWrapper .designCategoryContainer .leftsection ol li a:hover{width: 100%; box-sizing: border-box; padding: 8px 10px; font-size: 17px; border-bottom:2px solid #1bce52; color: #000;}
.designCategoryWrapper .designCategoryContainer .leftsection ol li a.active{width: 100%; box-sizing: border-box; padding: 8px 10px; font-size: 17px; border-bottom:2px solid #1bce52; color: #000;}


.designCategoryWrapper .designCategoryContainer .rightsection{display: flex; flex-direction: column; flex: 1 1; margin-left:0px; margin-top:20px;  width: 100%;} 
.designCategoryWrapper .designCategoryContainer .rightsection ol{display: flex; justify-content: center; margin: 0; padding: 0; flex-wrap: wrap; margin:0; padding: 0; list-style-type: none;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li{box-shadow: 0px 0px 10px #ebebeb; margin: 0 8px 15px; width:23.333333%;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li:hover{box-shadow: 0px 0px 20px #c5bebe;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-content{padding: 10px; display: flex; flex-direction: column;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-logo{text-align: center;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-title{text-align: center; color: #000; font-family: 'Roboto'; font-size: 20px;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-thumb img {height: 200px; object-fit: cover; width: 100%;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-location{text-align: left; margin-top: 5px; display: flex; flex-direction: column;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-location span{color:var(--primary)}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .actions{display: flex; align-items:center; color: #000; font-family: 'Roboto';  justify-content: space-between; width: 100%; margin-top: 10px;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .actions .duration{display: flex; align-items: center;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .actions .duration i{margin-right:5px;}

.designCategoryWrapper .designCategoryContainer .rightsection ol li .links{display: flex;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .links a{ padding:8px 15px;font-weight: normal; display: block; color: #1bce52; font-size: 14px; border: 1px solid #1bce52;}
.designCategoryWrapper .designCategoryContainer .rightsection .tabcontent{display: none; -webkit-animation: fadeEffect 1s; animation: fadeEffect 1s; }


@-webkit-keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}


@keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}

@media only screen and (max-width: 1000px) {
  .designCategoryWrapper .designCategoryContainer .rightsection ol li {max-width: 45%;}

}


@media only screen and (max-width: 850px) {
    .designCategoryWrapper .designCategoryContainer .rightsection ol{width: 100%;}
  .designCategoryWrapper .designCategoryContainer .rightsection ol li {max-width: none;}
}

@media only screen and (max-width: 770px) {
  .designCategoryWrapper .designCategoryContainer{flex-direction: column;}
  .designCategoryWrapper .designCategoryContainer .rightsection{margin-left: 0; margin-top: 15px;}
  .designCategoryWrapper .designCategoryContainer .leftsection{max-width: none;}
  .designCategoryWrapper .designCategoryContainer .leftsection ol{flex-direction: row;}
  .designCategoryWrapper .designCategoryContainer .leftsection ol li{width: auto; margin: 0 10px}
}

@media only screen and (max-width: 600px) {
    .designCategoryWrapper .designCategoryContainer .leftsection ol{overflow-x: auto; white-space: nowrap;}
  .designCategoryWrapper .designCategoryContainer .rightsection ol li {max-width: 46%;}
  .app-body.ugpgprogram-page .app-div{left:0;}
}

@media only screen and (max-width: 500px) {
 .designCategoryWrapper .designCategoryContainer .rightsection ol{flex-direction: column; align-items: center;}
 .designCategoryWrapper .designCategoryContainer .rightsection ol li{width: 100%; max-width: 300px;}



 .UGPGProgramWrapper .categoryWrapper .banner{display: flex; white-space: nowrap; overflow-x: auto;}
 .UGPGProgramWrapper .categoryWrapper ol{white-space: nowrap; flex-direction: row; justify-content: flex-start;}

}

#no-data-admin{
    /* text-align: center; */
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    font-family: Lato;
    font-weight: 700;
    font-size: 25px;
    /* padding-top: 150px; */
    color: var(--text-black2);
}

.popup-add-program-content{
    position: relative;
    background: rgb(255, 255, 255);
    width: 64% !important;
    margin: auto;
    border: 1px solid rgb(187, 187, 187);
    padding: 5px;
    height: 75% !important;
}

.popup-data-program{
    height: 387px;
    padding-bottom: 5px;
    margin-top:10px;
}

.session-label{
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 600;
    margin-left: 16px;
    margin-top: 11px;
}

.popup-data-session{
    padding-top: 15px;
    height: 300px !important;
}

.popup-session-content{
    height:450px !important;
}

.content-message{
    margin-left: 13px;
    line-height: 33;
}

.prog-sem{
    overflow-y: scroll;
    height: 272px;
    margin-top: 26px;
}
.prog-sem div{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.prog-sem div span {
    display: block;
    min-width: 27px;
    height: 37px;
    border-radius: 5px;
    background-color: var(--white);
    border: 1px solid var(--primary);
    color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
    font-weight: bold;
    font-family: Lato;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}

.prog-sem div input{
    margin-left: 12px;
    width: 22%;
    font-size: 16px;
    font-family: Lato;
    color: var(--text-black);
    background-color: var(--white);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 9px 14px 10px 14px;
    border-radius: 4px;
}

.prog-sem div input[type="textarea"]{
    width:40%;
}

.popup-inputs-programs{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup-inputs-programs input, .popup-inputs2 input, .popup-inputs-programs select, .popup-inputs5 input{
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 12px 16px;
    border-radius: 4px;
}

.popup-inputs-programs input:first-child, .popup-inputs-programs select:first-child{
    width:20% !important;
}

.popup-inputs-session {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 640px;
}

.popup-inputs-session-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 640px;
}

.popup-inputs-session input, .popup-inputs2 input, .popup-inputs-session select, .popup-inputs5 input {
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 12px 16px;
    border-radius: 4px;
    width: 205px !important;
}

.popup-inputs-session-date input{
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 12px 16px;
    border-radius: 4px;
    width: 300px !important;
}

.popup-inputs-session input[type="textarea"]{
    width: 100% !important;
    height: 63px;
}

#remove-opt{
    margin-left: 10px;
    border-radius:5px !important;
    padding: 10px;
}

#remove-opt img{
    width: 23px;
}



.app-body.ugpgprogram-page .app-div {
    width: auto;
    padding: 10px 20px 25px 20px;
    top: 50px;
    left: 150px;
    height: calc(100vh - 55px);
    background-color: #fff;
    border-radius: 0px;
    right: 0;
}


.UGPGProgramWrapper{width:100%; border:0px solid #ff0000; font-family: Lato;}

.UGPGProgramWrapper .categoryWrapper{display: flex; width:100%; margin: 20px 0; align-items: center; justify-content: center; flex-direction: column;}
.UGPGProgramWrapper .categoryWrapper .heading{font-size:21px; font-weight:bold; font-family: Lato; margin-bottom: 15px;}
.UGPGProgramWrapper .categoryWrapper ol{display: flex; flex-wrap: wrap; margin:0; padding:0;}
.UGPGProgramWrapper .categoryWrapper ol li{display: flex; margin:0; margin-left: 15px; padding:0;}
.UGPGProgramWrapper .categoryWrapper ol li a{ color:#000; padding: 8px 25px; border-radius:5px; font-family: var(--defaultfont);  margin-left:0px; border:1px solid #ddd;}
.UGPGProgramWrapper .categoryWrapper ol li:first-child{margin-left: 0;}
.UGPGProgramWrapper .categoryWrapper ol li a:hover{background-color: #1bce52; color:#fff;}
.UGPGProgramWrapper .categoryWrapper ol li a.active{background-color: #1bce52; color:#fff;}
.UGPGProgramWrapper .categoryWrapper ol li .title{text-align: center;}


.designCategoryWrapper.businesstab{display: none;}
.designCategoryWrapper.technologytab{display: none;}
.designCategoryWrapper{display: flex; flex-direction: column; width: 100%; font-family: 'Roboto';} 
.designCategoryWrapper .designCategoryContainer{display: flex; width: 100%; align-items: flex-start; flex-direction: column;} 
.designCategoryWrapper .designCategoryContainer .leftsection{display: flex; width: 100%; margin-bottom: 30px;}
.designCategoryWrapper .designCategoryContainer .leftsection ol{list-style-type: none; flex-wrap: wrap; justify-content: center; margin:0; padding:0; width: 100%; display: flex;}
.designCategoryWrapper .designCategoryContainer .leftsection ol li{display: flex; list-style-type: none; margin:0; padding:0; box-sizing: border-box;}
.designCategoryWrapper .designCategoryContainer .leftsection ol li a{width: 100%; box-sizing: border-box; padding: 8px 10px; border-bottom:2px solid transparent; color: #666; font-size: 17px; display: flex;}
.designCategoryWrapper .designCategoryContainer .leftsection ol li a:hover{width: 100%; box-sizing: border-box; padding: 8px 10px; font-size: 17px; border-bottom:2px solid #1bce52; color: #000;}
.designCategoryWrapper .designCategoryContainer .leftsection ol li a.active{width: 100%; box-sizing: border-box; padding: 8px 10px; font-size: 17px; border-bottom:2px solid #1bce52; color: #000;}


.designCategoryWrapper .designCategoryContainer .rightsection{display: flex; flex-direction: column; flex: 1 1; margin-left:0px; margin-top:20px;  width: 100%;} 
.designCategoryWrapper .designCategoryContainer .rightsection ol{display: flex; justify-content: center; margin: 0; padding: 0; flex-wrap: wrap; margin:0; padding: 0; list-style-type: none;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li{box-shadow: 0px 0px 10px #ebebeb; margin: 0 8px 15px; width:23.333333%;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li:hover{box-shadow: 0px 0px 20px #c5bebe;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-content{padding: 10px; display: flex; flex-direction: column;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-logo{text-align: center;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-title{text-align: center; color: #000; font-family: 'Roboto'; font-size: 20px;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-thumb img {height: 200px; object-fit: cover; width: 100%;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-location{text-align: center; margin-top: 5px;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-location span{color: #999}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .actions{display: flex; align-items:center; color: #000; font-family: 'Roboto';  justify-content: space-between; width: 100%; margin-top: 10px;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .actions .duration{display: flex; align-items: center;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .actions .duration i{margin-right:5px;}

.designCategoryWrapper .designCategoryContainer .rightsection ol li .links{display: flex;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .links a{ padding:8px 15px;font-weight: normal; display: block; color: #1bce52; font-size: 14px; border: 1px solid #1bce52;}
.designCategoryWrapper .designCategoryContainer .rightsection .tabcontent{display: none; -webkit-animation: fadeEffect 1s; animation: fadeEffect 1s; }

.widget-dashboard1 .referralDialog-overlay{z-index:220000!important;}
.widget-dashboard1 .referralDialog-content{height:auto; padding:0px !important;}
.widget-dashboard1 .referralDialogWrapper{display:flex; flex-direction:row; position: relative; font-family: Lato;}
.widget-dashboard1 .referralDialogWrapper .leftsection{width:60%; position: relative; display:flex; padding:15px; flex-direction: column;}
.widget-dashboard1 .referralDialogWrapper .shape1{position:absolute; z-index:0; width:150px; background-color: #00f8b9; right:-40px; top:-40px; height: 150px; border-radius:100px;}
.widget-dashboard1 .referralDialogWrapper .shape2{position:absolute; width:150px; z-index:0; background-color: #0096f8; left:-40px; bottom:-40px; height: 150px; border-radius:100px;}
.widget-dashboard1 .referralDialogWrapper .headingtitle{border-bottom:1px solid #000; font-weight:bold; margin-bottom: 15px; padding-bottom: 10px; font-size:26px; color:#000;}
.widget-dashboard1 .referralDialogWrapper ol{margin:0; padding:0; display: flex; flex-direction:column; overflow-y: auto; max-height:300px;}
.widget-dashboard1 .referralDialogWrapper ol li{margin:15px 0 0; padding:0; display: flex; flex-direction:column;border: 0px solid #0edf0e;padding:0px; border-radius: 5px;}
.widget-dashboard1 .referralDialogWrapper ol li:first-child{margin-top: 0;}
.widget-dashboard1 .referralDialogWrapper ol li .question-title{font-weight:bold; margin-bottom:5px; padding-bottom:0px; font-size: 17px; color:#000;}
.widget-dashboard1 .referralDialogWrapper ol li .labelblock{display: flex; width: 100%; flex-wrap: wrap;}
.widget-dashboard1 .referralDialogWrapper ol li .labelinputblock{display: flex; width: 100%; flex-wrap: wrap;}
.widget-dashboard1 .referralDialogWrapper ol li input[type="text"]{max-width: none; width: 100%;}
.widget-dashboard1 .referralDialogWrapper ol li label{display: flex; width:100%; max-width: 50%; align-items: flex-start;justify-content:flex-start; padding:3px;}
.widget-dashboard1 .referralDialogWrapper ol li label input{margin-right:10px;}
.widget-dashboard1 .referralDialogWrapper ol li label span{min-width:60px; margin-right: 10px;}
.widget-dashboard1 .referralDialogWrapper ol li input[type="text"]{border:1px solid #ddd; padding:10px 10px; font-family: Lato;}
.widget-dashboard1 .referralDialogWrapper .question-action{text-align:right; align-items: flex-end; justify-items: flex-end;  border-top:0px solid #ddd; margin-top:15px; padding-top:0px;}
.widget-dashboard1 .referralDialogWrapper .question-action button{background-color: #000; color:#fff}

@-webkit-keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}

@media only screen and (max-width: 1000px) {
  .designCategoryWrapper .designCategoryContainer .rightsection ol li {max-width: 45%;}

}


@media only screen and (max-width: 850px) {
  .designCategoryWrapper .designCategoryContainer .rightsection ol li {max-width: 47%;}
}

@media only screen and (max-width: 770px) {
  .designCategoryWrapper .designCategoryContainer{flex-direction: column;}
  .designCategoryWrapper .designCategoryContainer .rightsection{margin-left: 0; margin-top: 15px;}
  .designCategoryWrapper .designCategoryContainer .leftsection{max-width: none;}
  .designCategoryWrapper .designCategoryContainer .leftsection ol{flex-direction: row;}
  .designCategoryWrapper .designCategoryContainer .leftsection ol li{width: auto; margin: 0 10px}
}

@media only screen and (max-width: 600px) {
    .designCategoryWrapper .designCategoryContainer .leftsection ol{overflow-x: auto; white-space: nowrap;}
  .designCategoryWrapper .designCategoryContainer .rightsection ol li {max-width: 46%;}
  .app-body.ugpgprogram-page .app-div{left:0;}
}

@media only screen and (max-width: 500px) {
 .designCategoryWrapper .designCategoryContainer .rightsection ol{flex-direction: column; align-items: center;}
 .designCategoryWrapper .designCategoryContainer .rightsection ol li{width: 100%; max-width: 300px;}
}
#no-data-admin{
    /* text-align: center; */
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    font-family: Lato;
    font-weight: 700;
    font-size: 25px;
    /* padding-top: 150px; */
    color: var(--text-black2);
}

.previewBannerImage{display: flex; align-items: flex-start;}
.previewBannerImage .previewBannerImage{width:65px; border-radius: 5px; display: flex; border:1px solid #1bce52; margin-left: 10px;}
.previewBannerImage img{max-width: 100%;  border-radius: 5px;}

.popup-add-program-content{
    position: relative;
    background: rgb(255, 255, 255);
    width: 64% !important;
    margin: auto;
    border: 1px solid rgb(187, 187, 187);
    padding: 5px;
    height: 75% !important;
}

.popup-data-program{
    height: 387px;
    padding-bottom: 5px;
    margin-top:10px;
}

.session-label{
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 600;
    margin-left: 16px;
    margin-top: 11px;
}

.popup-data-session{
    padding-top: 15px;
    height: 300px !important;
}

.popup-session-content{
    height:450px !important;
}

.content-message{
    margin-left: 13px;
    line-height: 33;
}

.prog-sem{
    overflow-y: scroll;
    height: 272px;
    margin-top: 26px;
}
.prog-sem div{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.prog-sem div span {
    display: block;
    min-width: 27px;
    height: 37px;
    border-radius: 5px;
    background-color: var(--white);
    border: 1px solid var(--primary);
    color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
    font-weight: bold;
    font-family: Lato;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}

.prog-sem div input{
    margin-left: 12px;
    width: 22%;
    font-size: 16px;
    font-family: Lato;
    color: var(--text-black);
    background-color: var(--white);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 9px 14px 10px 14px;
    border-radius: 4px;
}

.prog-sem div input[type="textarea"]{
    width:40%;
}

.popup-inputs-programs{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.popup-inputs-programs input:first-child{margin-left: 0;}
.popup-inputs-programs button{margin-left: 15px;}
.popup-inputs-programs input, .popup-inputs2 input, .popup-inputs-programs select, .popup-inputs5 input{
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 12px 16px;
    border-radius: 4px;
    margin-left: 15px;
}

.popup-inputs-programs input:first-child, .popup-inputs-programs select:first-child{
    width:20% !important;
}

.popup-inputs-session {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 640px;
    margin-bottom: 20px;
}

.popup-inputs-session-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 640px;
}

.popup-inputs-session input, .popup-inputs2 input, .popup-inputs-session select, .popup-inputs5 input {
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 12px 16px;
    border-radius: 4px;
    width: 205px !important;
}

.popup-inputs-session-date input{
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 12px 16px;
    border-radius: 4px;
    width: 300px !important;
}

.popup-inputs-session input[type="textarea"]{
    width: 100% !important;
    height: 63px;
}

#remove-opt{
    margin-left: 10px;
    border-radius:5px !important;
    padding: 10px;
}

#remove-opt img{
    width: 23px;
}



.app-body.ugpgprogram-page .app-div {
    width: auto;
    padding: 10px 20px 25px 20px;
    top: 50px;
    left: 150px;
    height: calc(100vh - 55px);
    background-color: #fff;
    border-radius: 0px;
    right: 0;
}


.UGPGProgramWrapper{width:100%; border:0px solid #ff0000; font-family: Lato;}

.UGPGProgramWrapper .categoryWrapper{display: flex; width:100%; margin: 20px 0; align-items: center; justify-content: center; flex-direction: column;}
.UGPGProgramWrapper .categoryWrapper .heading{font-size:21px; font-weight:bold; font-family: Lato; margin-bottom: 15px;}
.UGPGProgramWrapper .categoryWrapper ol{display: flex; flex-wrap: wrap; margin:0; padding:0;}
.UGPGProgramWrapper .categoryWrapper ol li{display: flex; min-width: 215px; margin:0; margin-left: 15px; padding:0;}
.UGPGProgramWrapper .categoryWrapper ol li a{ color:#000; width:100%; padding: 8px 25px; border-radius:5px; font-family: var(--defaultfont);  margin-left:0px; border:1px solid #ddd;}
.UGPGProgramWrapper .categoryWrapper ol li:first-child{margin-left: 0;}
.UGPGProgramWrapper .categoryWrapper ol li a:hover{background-color: var(--primary); color:var(--white);}
.UGPGProgramWrapper .categoryWrapper ol li a.active{background-color: var(--primary); color:var(--white);}
.UGPGProgramWrapper .categoryWrapper ol li .title{text-align: center;}


.designCategoryWrapper.businesstab{display: none;}
.designCategoryWrapper.technologytab{display: none;}
.designCategoryWrapper{display: flex; flex-direction: column; width: 100%; font-family: 'Roboto';} 
.designCategoryWrapper .designCategoryContainer{display: flex; width: 100%; align-items: flex-start; flex-direction: column;} 
.designCategoryWrapper .designCategoryContainer .leftsection{display: flex; width: 100%; margin-bottom: 30px;}
.designCategoryWrapper .designCategoryContainer .leftsection ol{list-style-type: none; flex-wrap: wrap; justify-content: center; margin:0; padding:0; width: 100%; display: flex;}
.designCategoryWrapper .designCategoryContainer .leftsection ol li{display: flex; list-style-type: none; margin:0; padding:0; box-sizing: border-box;}
.designCategoryWrapper .designCategoryContainer .leftsection ol li a{width: 100%; box-sizing: border-box; padding: 8px 10px; border-bottom:2px solid transparent; color: #666; font-size: 17px; display: flex;}
.designCategoryWrapper .designCategoryContainer .leftsection ol li a:hover{width: 100%; box-sizing: border-box; padding: 8px 10px; font-size: 17px; border-bottom:2px solid var(--primary); color: var(--black);}
.designCategoryWrapper .designCategoryContainer .leftsection ol li a.active{width: 100%; box-sizing: border-box; padding: 8px 10px; font-size: 17px; border-bottom:2px solid var(--primary); color: var(--black);}


.designCategoryWrapper .designCategoryContainer .rightsection{display: flex; flex-direction: column; flex: 1 1; margin-left:0px; margin-top:20px;  width: 100%;} 
.designCategoryWrapper .designCategoryContainer .rightsection ol{display: flex; justify-content: center; margin: 0; padding: 0; flex-wrap: wrap; margin:0; padding: 0; list-style-type: none;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li{box-shadow: 0px 0px 10px #ebebeb; border-radius: 14px; margin: 0 8px 15px; width: 100%; max-width:23.333333%;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li:hover{box-shadow: 0px 0px 20px #c5bebe;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-content{padding: 10px; display: flex; flex-direction: column;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-logo{text-align: center;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-title{min-height: 70px; text-align: left; color: #000; font-family: 'Roboto'; font-size: 20px;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-thumb img {height: 200px; object-fit: cover; width: 100%;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-location{text-align: left; margin-top: 10px;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .university-location span{min-height: 100px; color:var(--primary);margin-top: 5px;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .actions{display: flex; align-items:center; color: #000; font-family: 'Roboto';  justify-content: space-between; width: 100%; margin-top: 20px;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .actions .duration{display: flex; align-items: center;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .actions .duration i{margin-right:5px;}

.designCategoryWrapper .designCategoryContainer .rightsection ol li .links{display: flex;}
.designCategoryWrapper .designCategoryContainer .rightsection ol li .links a{ padding:8px 15px;font-weight: normal; display: block; color:var(--primary); font-size: 14px; border: 1px solid var(--primary);}
.designCategoryWrapper .designCategoryContainer .rightsection .tabcontent{display: block; -webkit-animation: fadeEffect 1s; animation: fadeEffect 1s; }


@-webkit-keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}


@keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}

@media only screen and (max-width: 1000px) {
  .designCategoryWrapper .designCategoryContainer .rightsection ol li {width: 45%;}

}


@media only screen and (max-width: 850px) {
  .designCategoryWrapper .designCategoryContainer .rightsection ol li {max-width: 47%;}
  .UGPGProgramWrapper .categoryWrapper .banner img{margin-left: 10px; margin-top: 10px;}
  .UGPGProgramWrapper .categoryWrapper .banner img:first-child{margin-left: 10px;}

}

@media only screen and (max-width: 770px) {
  .designCategoryWrapper .designCategoryContainer{flex-direction: column;}
  .designCategoryWrapper .designCategoryContainer .rightsection{margin-left: 0; margin-top: 15px;}
  .designCategoryWrapper .designCategoryContainer .leftsection{max-width: none;}
  .designCategoryWrapper .designCategoryContainer .leftsection ol{flex-direction: row;}
  .designCategoryWrapper .designCategoryContainer .leftsection ol li{width: auto; margin: 0 10px}
 
}

@media only screen and (max-width: 600px) {
    .designCategoryWrapper .designCategoryContainer .leftsection ol{overflow-x: auto; white-space: nowrap;}
  .designCategoryWrapper .designCategoryContainer .rightsection ol li {max-width: 46%;}
  .app-body.ugpgprogram-page .app-div{left:0;}
  
}

@media only screen and (max-width: 500px) {
 .designCategoryWrapper .designCategoryContainer .rightsection ol{flex-direction: column; align-items: center;}
 .designCategoryWrapper .designCategoryContainer .rightsection ol li{width: 100%; max-width: 300px;}
 .UGPGProgramWrapper .categoryWrapper ol{flex-wrap: nowrap; white-space: nowrap; overflow-x: auto; width:100%;}
 .UGPGProgramWrapper .categoryWrapper ol li{margin-left: 15px;}
 .UGPGProgramWrapper .categoryWrapper ol li:first-child{margin-left:0px;}
 .UGPGProgramWrapper .categoryWrapper .banner img:first-child{margin-left: 0px;}
}
  .app-header{display: flex; flex-direction: column;}
  .app-header .profile {align-self: flex-end;}
  .app-div.mycoach-div{top:150px; height: calc(100vh - 151px); background-color: #F2F4F6; padding-bottom: 0px; width: auto; left:250px; padding:0; border-radius:0}
  .mycoachpageadded .app-header{bordeR:0px solid #ff0000; top:10px; margin-bottom: 0;}


  .app-div.mycoach-div.mentorshipWrapper {left: 150px;}


  .mycoach-header{width:auto; left:250px; padding-left:28px;  position: fixed; z-index:19; top:40px; right:0; padding-right:24px;  display: flex;  align-self: flex-end;  justify-content: space-between;}
  .mycoach-header{display:flex; flex-direction: column;}
  .mycoach-header .intro-block{display: flex;font-family: Lato;  width: 100%; margin-top: 15px; margin-bottom: 15px; justify-content: space-between; width: 100%;  align-items: flex-start;}
  .mycoach-header .intro-block .user-block {display: flex; flex-direction: column; width: 80%;}
  .mycoach-header .intro-block .askanything{display: flex; cursor: pointer; flex-direction: column; align-items: center;}
  .mycoach-header .intro-block .askanything >  div:first-child{width: 40px; margin-bottom: 7px;}
  .mycoach-header .intro-block .askanything i{ width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; margin-bottom: 15px; border-radius:100%; background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}
  .mycoach-header .intro-block h3{margin-bottom: 15px; font-size: 16px; margin:0 0 10px; font-weight: 700px; font-family: Lato;}
  .mycoach-header .intro-block h3 span{color:var(--primary); font-size: 18px;}
  .mycoach-header .intro-block p{font-size: 14px; margin:0; line-height: 22px; font-weight: 400px; font-family: Lato;}


 .app-div.doubt-main{top: 150px;height: calc(100vh - 152px); background-color: #F2F4F6; width: auto; left: 250px; padding: 30px; border-radius: 0;}

  .profile-option div{box-shadow: 0px 3px 6px #9090901F; margin-right:10px}
  .mycoach-wrapper{display: flex; width: 100%; height:100%; flex-direction: column; font-family: Lato;}
  .mycoach-wrapper .intro-block{display: flex; width: 100%; margin-bottom: 15px; justify-content: space-between; width: 100%;  align-items: flex-start;}
  .mycoach-wrapper .intro-block div:first-child{display: flex; flex-direction: column; width: 50%;}
  .mycoach-wrapper .intro-block div:last-child{display: flex; flex-direction: column; align-items: center;}
  .mycoach-wrapper .intro-block div:last-child i{ width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; margin-bottom: 15px; border-radius:100%; background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}
  .mycoach-wrapper .intro-block h3{margin-bottom: 15px; font-size: 16px; margin:0 0 10px; font-weight: 700px; font-family: Lato;}
  .mycoach-wrapper .intro-block h3 span{color:var(--primary); font-size: 18px;}
  .mycoach-wrapper .intro-block p{font-size: 14px; margin:0; line-height: 22px; font-weight: 400px; font-family: Lato;}
  .mycoach-wrapper .mycoach-main{display: flex; width: 100%; background-color: #F2F4F6; padding:30px; border-radius:0px;}
  .mycoach-wrapper .mycoach-left{ display: flex; flex-direction: column; width: 100%; max-width: 300px;}
  .mycoach-wrapper .mycoach-right{ display: flex; flex-direction: column; width: 100%; margin-left: 25px;}

  .mycoach-wrapper .mycoach-left ol{ display: flex; flex-direction: column; width: 100%; margin:0; padding:0}
  .mycoach-wrapper .mycoach-left ol li{ display: flex; flex-direction: column; width: 100%; margin:0 0 15px; box-shadow: 0px 6px 8px #1D73F238; padding:15px; border-radius:12px; position: relative; }
  .course-academy{background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}
  .cacademy{background: transparent linear-gradient(122deg, #FF8A57 0%, #FF5502 100%) 0% 0% no-repeat padding-box;}
  .mycoach-wrapper .mycoach-left ol li:last-child{background: transparent linear-gradient(121deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box}
  .goal{background: transparent linear-gradient(123deg, #FFCE00 0%, #FF8A57 100%) 0% 0% no-repeat padding-box;}
  
  .mycoach-wrapper .mycoach-left ol li .icon{width: 48px;height: 48px; margin-bottom: 10px; display: flex; align-items: center; justify-content: center; border-radius:100%; background-color: #fff;}
  .mycoach-wrapper .mycoach-left ol li .icon img{max-width: 100%; width: 32px;}
  .mycoach-wrapper .mycoach-left ol li .arrow{position:absolute; right:10px; display: flex; align-items: center; justify-content: center; top:10px; width: 28px; height: 28px; border-radius:100%; background-color: rgba(255,255,255,0.2); }
  .mycoach-wrapper .mycoach-left ol li h3{font-size: 16px; color:#fff; margin:0 0 5px}
  .mycoach-wrapper .mycoach-left ol li:last-child h3{font-size: 20px; color:#fff; margin:0 0 49px}
  .mycoach-wrapper .mycoach-left ol li p{font-size: 14px; color:#fff; margin:0 0 0px}
  .mycoach-wrapper .mycoach-left ol li span{font-size: 14px; padding:5px 15px; border-radius: 21px;  color: #1D73F2; background-color:#fff; margin:0 0 0px}
  .mycoach-wrapper .mycoach-left ol li.gameBanner{background-image:url(/static/media/veranda-innovation.18d86c19.png); background-repeat: no-repeat; background-size: cover;}
  .mycoach-wrapper .mycoach-left ol li.gameBanner .icon{background-color:transparent}
  .mycoach-wrapper .mycoach-left ol li.gameBanner h3{font-size:20px}
  .mycoach-wrapper .mycoach-left ol li.gameBanner p{font-style: italic;}
  .mycoach-wrapper .mycoach-left ol li.gameBanner p + p{margin-top:2px}

  .mycoach-wrapper .mycoach-right .coach-block{display: flex; flex-direction: column; width: 100%; box-shadow: 0px 3px 6px #9090901F; background-color: #fff; border-radius: 18px; padding:20px}
  .mycoach-wrapper .mycoach-right .coach-block h3{font-size: 16px; color:#000; margin:0 0 15px}
  .mycoach-wrapper .mycoach-right .coach-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .mycoach-wrapper .mycoach-right .coach-block ol li{display: flex;  transition: all 0.2s ease; justify-content: flex-start; align-items: flex-start; width: 100%; max-width:27%; margin:0  20px 20px 0; flex-direction: column;}
  .mycoach-wrapper .mycoach-right .coach-block ol li a{color:#333333; font-size: 14px; width: 100%; display: flex; flex-direction: column; align-items: center; margin:0; justify-content: flex-start;}
  .mycoach-wrapper .mycoach-right .coach-block ol li i{background-color: #EEF0F3; display: flex;  align-items: center; justify-content: center;width: 100%; margin-bottom: 15px; min-height: 85px; border-radius:12px}
  .mycoach-wrapper .mycoach-right .coach-block ol li i img{max-width: 100%; width: 60px; height:60px;}
  .mycoach-wrapper .mycoach-right .coach-block ol li:hover{ -webkit-transform: translateY(-6px); transform: translateY(-6px);}

  .mycoach-wrapper .mycoach-right .fun-block{display: flex; flex-direction: column; width: 100%; max-width:65%; margin-left: 25px; box-shadow: 0px 3px 6px #9090901F; background-color: #fff; border-radius: 18px; padding:20px}
  .mycoach-wrapper .mycoach-right .fun-block h3{font-size: 16px; color:#000; margin:0 0 15px}
  .mycoach-wrapper .mycoach-right .fun-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .mycoach-wrapper .mycoach-right .fun-block ol li{display: flex; transition: all 0.2s ease;  width: 100%; max-width: 30%; border-radius:18px; color:#fff;  margin:0  0px 0px 20px; padding:15px; flex-direction: column;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(1){background-color:rgba(27,206,82,0.7);margin-left: 0;}
  .mycoach-wrapper .mycoach-right .fun-block ol li a{color:#fff; display: flex; flex-direction: column;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(2){background-color: rgba(255,82,2,0.7);}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(2) a{pointer-events: auto; opacity:1; cursor: pointer;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(3){background-color: rgba(95,168,255,0.7);}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(1) i{margin-bottom: 15px; align-items: center; justify-content: center; display: flex;     align-self: flex-end; background-color:rgba(96,96,25,0.2); border-radius:100%; width: 60px;  height:60px;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(2) i{margin-bottom: 15px; align-items: center; justify-content: center;   display: flex; align-self: flex-end; background-color:rgba(96,96,25,0.2); border-radius:100%; width: 60px; height:60px;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(2) i img{width:35px; max-width: 100%;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(3) i img{width:35px; max-width: 100%;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(3) i{margin-bottom: 15px;  align-items: center; justify-content: center; display: flex;  align-self: flex-end; background-color:rgba(96,96,25,0.2); border-radius:100%; width: 60px; height:60px;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:hover{ -webkit-transform: translateY(-6px); transform: translateY(-6px);}

  .mycoach-wrapper .mycoach-right-bottom{display: flex; width: 100%; margin-top: 20px;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block{box-shadow: 0px 3px 6px #9090901F; background-color: #fff; border-radius: 18px; padding:20px; width: 100%; max-width: 35%;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .date-block{display: flex; width: 100%; justify-content: space-between;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .date-block .date-left{display: flex; flex-direction: column; font-size: 16px; color:#000;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .date-block .date-left span{font-size: 14px; color:#707070; margin-bottom: 8px;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .date-block .date-right{display: flex; flex-direction: column; font-size: 16px; color:#000;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .date-block .date-right span{font-size: 14px; color:#707070; margin-bottom: 8px;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .learning-block{display: flex; margin-top: 20px; flex-direction: column; width: 100%;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .learning-block h4{ margin:0 0 10px; font-size:15px; border-bottom:1px solid #CCCCCC; color:#333333; font-weight: normal;; padding-bottom: 10px}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block ul{margin:0; padding:0;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block ul li{margin:5px 0 0; padding:0; list-style-type: disc; display: list-item; list-style-position: inside;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block a{color:#000}

  .comingsoon{display: flex; width: 100%; flex-direction:column; padding:15px;}
  .comingsoon .banner-block{display: flex;   margin:15px 0 0; justify-content: center; background-color: #fff; padding:15px;}
  .comingsoon .banner-block img {max-width: 100%;}
  .mycoach-wrapper .interest-block{box-shadow: 0px 3px 6px #9090901F;  margin-top: 20px; background-color: #fff; border-radius: 18px; padding:20px; width: 100%;}
  .mycoach-wrapper .interest-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .mycoach-wrapper .interest-block input{width: 100%; padding:5px 10px; width: 100%; border:0px none; color:#000; font-size:14px; border-bottom:1px solid #CCCCCC;}
  .mycoach-wrapper .interest-block input.isdata{border-bottom:1px solid transparent;}
  .mycoach-wrapper .interest-block input.isnotdata{border-bottom:1px solid #ccc;}
  .mycoach-wrapper .interest-block input.isdata:focus{border-bottom:1px solid #CCCCCC;}
  .mycoach-wrapper .interest-block .actions{margin-top: 20px; display: flex; align-items: flex-end; justify-content: flex-end;}
  .mycoach-wrapper .interest-block .actions button{ border-radius:5px; background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}
  .mycoach-wrapper .interest-block .actions.isdata{opacity:0; visibility: hidden;}

  .mycoach-wrapper .interest-block .actions.hasfocused{opacity:1; visibility: visible;} 

  .mycoach-wrapper .interest-block .formBlock{display: flex; flex-direction: column; width:100%;}
  .mycoach-wrapper .interest-block .formBlock.hasdata input{border-bottom:1px solid transparent}
  .mycoach-wrapper .interest-block .formBlock.hasdata input.hasfocused{border-bottom:1px solid #ccc}
  .mycoach-wrapper .interest-block .formBlock.hasdata .actions{display:none;}
  .mycoach-wrapper .interest-block .formBlock.hasdata .actions.hasfocused{display: flex;}

  .is_disabled{pointer-events: none; opacity:0.5; cursor: default;}

  .challenges-block{display: flex; flex-direction: column; width: 100%; background-color: #F2F4F6; padding: 25px 25px 150px; border-radius:0px;}
  .challenges-block h3{font-size: 22px; color:#000; margin:0 0 10px}
  .challenges-block p{font-size: 14px; color:#000; margin:0 0 15px}
  .challenges-block .design-block{display: flex; flex-direction: column; width: 100%; margin-top: 15px;}
  .challenges-block .design-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .challenges-block .design-block p{font-size: 14px; color:#000; margin:0 0 0px}
  .challenges-block .design-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .challenges-block .design-block li{display: flex; position: relative; background-color: #fff;  transition: all 0.2s ease; border-radius:12px; box-shadow: 0px 1px 6px #0000001A; justify-content: flex-start; align-items: center; width: 100%; max-width: 250px; padding:15px; margin:0  20px 20px 0; flex-direction: column;}
  .challenges-block .design-block li .thumb{display: flex; width: 210px; height:210px; border-radius: 0%; margin-bottom: 15px; background-color: #d2d3d5;  align-items: center; justify-content: center;}
  .challenges-block .design-block li .thumb-title{display: flex; height:60px; color:#000; margin-bottom: 10px; font-size: 16px; align-items: flex-start; width: 100%;}
  .challenges-block .design-block li .thumb-content{display: flex; line-height: 20px;  color:#999;  margin-bottom: 15px; font-size: 14px; align-items: center; width: 100%;}
  .challenges-block .design-block li .duedate-tag{display: flex; width: auto;}
  .challenges-block .design-block li .duedate-tag span{display: flex; background-color: #e5e5e5;  color:#000; padding:5px 10px; border-radius:5px;  margin-bottom:0px; font-size: 12px; align-items: center;}
  .challenges-block .design-block li .thumb-action{display: flex;  align-items: center; justify-content: center; width: 100%;}
  .challenges-block .design-block li .thumb-action a{box-shadow: 0px 6px 8px #5FA8FF38; margin-left: 10px; color:#fff; font-size: 12px; padding:10px 25px; border-radius:5px; background: transparent linear-gradient(288deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}
  .challenges-block .design-block li .thumb-action a:first-child{margin-left:0px;}
  .challenges-block .design-block li:hover{ -webkit-transform: translateY(-6px); transform: translateY(-6px); z-index: 20005;}

  .challenges-block .hoverOverlay{position: absolute; top:0; bottom:0; right:0; z-index:20000;  -webkit-perspective: 50em;  perspective: 50em;    transition: all 0.35s ease; left:0; opacity: 0; border-radius:12px; visibility: hidden; background-color:rgba(0,0,0,0.9); padding:15px}
  .challenges-block .design-block li .hoverOverlay .thumb-title {color:#fff; font-size:16px; font-weight:bold; height:auto; border-bottom:1px solid #fff; padding-bottom:15px}
  .challenges-block .design-block li .hoverOverlay .thumb-content {color:#fff; line-height: 23px; margin-bottom:15px;}
  .challenges-block .design-block li .hoverOverlay .thumb-action a { box-shadow: 0 6px 8px rgba(27,206,80,.2196078431372549); font-size:15px; background-color: var(--primary); background: linear-gradient(288deg, var(--primary) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box; color:#fff; font-weight: bold;}
  .challenges-block .design-block li:hover .hoverOverlay{opacity: 1; visibility: visible; z-index:20005;margin-bottom:-50px;  transition-delay: 0.1s;}


  .liveprojects-block{display: flex; flex-direction: column; width: 100%; background-color: #F2F4F6; padding:25px; border-radius:0px;}
  .liveprojects-block h3{font-size: 22px; color:#000; margin:0 0 10px; text-transform: capitalize;}
  .liveprojects-block .CircularProgressbar {margin-left: 30px;}
  .liveprojects-block h3 svg{max-width: 60px;}
  .liveprojects-block p{font-size: 14px; color:#000; margin:0 0 15px}
  .liveprojects-block .design-block{display: flex; flex-direction: column; width: 100%; margin-top: 15px;}
  .liveprojects-block .design-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .liveprojects-block .design-block p{font-size: 14px; color:#000; margin:0 0 0px}
  .liveprojects-block .design-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .liveprojects-block .design-block li{display: flex; position: relative; background-color: #fff;  transition: all 0.2s ease; border-radius:12px; box-shadow: 0px 1px 6px #0000001A; justify-content: flex-start; align-items: center; width: 100%; max-width: 250px; padding:15px; margin:0  20px 20px 0; flex-direction: column;}
  .liveprojects-block .design-block li .thumb{display: flex; width: 210px; height:210px; border-radius: 0%; margin-bottom: 15px; background-color: #d2d3d5;  align-items: center; justify-content: center;}
  .liveprojects-block .design-block li .thumb img{max-width: 100%;}
  .liveprojects-block .design-block li .thumb-title{display: flex;  color:#000; height:38px; margin-bottom: 10px; font-size: 16px; align-items: flex-start; width: 100%;}
  .liveprojects-block .design-block li .thumb-content{display: flex; line-height: 20px;  color:#999;  margin-bottom: 15px; font-size: 14px; align-items: center; width: 100%;}
  .liveprojects-block .design-block li .duedate-tag{display: flex; width: auto;}
  .liveprojects-block .design-block li .duedate-tag span{display: flex; background-color: #e5e5e5;  color:#000; padding:5px 10px; border-radius:5px;  margin-bottom:0px; font-size: 12px; align-items: center;}
  .liveprojects-block .design-block li .thumb-action{display: flex;  align-items: center; justify-content:center; width: 100%;}
  .liveprojects-block .design-block li .thumb-action a{box-shadow: 0px 6px 8px #5FA8FF38; margin-left: 10px; color:#fff; font-size: 12px; padding:10px 25px; border-radius: 5px; background: transparent linear-gradient(288deg, var(--primary-rgba) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box;}
  .liveprojects-block .design-block li .thumb-action a:first-child{margin-left:0px;}
  .liveprojects-block .design-block li:hover{ -webkit-transform: translateY(-6px); transform: translateY(-6px);}

  .liveprojects-block .hoverOverlay{position: absolute; top:0; bottom:0; right:0; -webkit-perspective: 50em; perspective: 50em;    transition: all 0.35s ease; left:0; opacity: 0; border-radius:12px; visibility: hidden; background-color:rgba(0,0,0,0.9); padding:15px}
  .liveprojects-block .design-block li .hoverOverlay .thumb-title {color:#fff; font-size:16px; font-weight:bold; height:auto; border-bottom:1px solid #fff; padding-bottom:15px}
  .liveprojects-block .design-block li .hoverOverlay .thumb-content {color:#fff; line-height: 23px; margin-bottom:15px;}
  .liveprojects-block .design-block li .hoverOverlay .thumb-action a { box-shadow: 0 6px 8px rgba(27,206,80,.2196078431372549); font-size:15px; background-color: var(--primary); background: linear-gradient(288deg, var(--primary) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box; color:var(--white); font-weight: bold;}
  .liveprojects-block .design-block li:hover .hoverOverlay{opacity: 1; visibility: visible;  transition-delay: 0.1s;}


  .mypercentage-block{display: flex; flex-direction: column; width: 100%; background-color: #F2F4F6; padding:25px; border-radius:0px;}
  .mypercentage-block h3{font-size: 22px; color:#000; margin:0 0 10px}
  .mypercentage-block .CircularProgressbar {margin-left: 30px;}
  .mypercentage-block h3 svg{max-width: 60px;}
  .mypercentage-block p{font-size: 14px; color:#000; margin:0 0 15px}
  .mypercentage-block .design-block{display: flex; flex-direction: column; width: 100%; margin-top: 15px;}
  .mypercentage-block .design-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .mypercentage-block .design-block p{font-size: 14px; color:#000; margin:0 0 0px}
  .mypercentage-block .design-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .mypercentage-block .design-block li{display: flex; background-color: #fff;  transition: all 0.2s ease; border-radius:12px; box-shadow: 0px 1px 6px #0000001A; justify-content: center; align-items: center; width: 100%; max-width:220px; padding:15px; margin:0  20px 20px 0; flex-direction: column;}
  .mypercentage-block .design-block li .percentage-value{display: flex; justify-content: flex-end; color:#1D73F2; margin-bottom: 10px; font-size: 32px; align-items: center; width: 100%;}
  .mypercentage-block .design-block li .percentage-notvalue{display: flex; justify-content: flex-end; color:#606060; margin-bottom: 10px; font-size: 22px; align-items: center; width: 100%;}
  .mypercentage-block .design-block li .percentage-title{display: flex; justify-content: flex-end; color:#000000; margin-top: 10px; font-size: 25px; align-items: center; width: 100%;}
  


  .certification-academy-block{display: flex; flex-direction: column; width: 100%; background-color: #F2F4F6; padding: 25px; border-radius:0px;}
  .certification-academy-block .topHeading{display: flex; align-items: center; margin-bottom: 15px;}
  .certification-academy-block .topHeading a{display: flex;     box-shadow: 0px 1px 6px #0000001A; background-color: #fff; justify-content: center; margin-right: 15px; align-items: center; width:40px; height: 40px; border-radius:5px;}
  .certification-academy-block h3{font-size: 22px; color:#000; margin:0 0 0px}
  .certification-academy-block p{font-size: 14px; color:#000; margin:0 0 15px}
  .certification-academy-block .design-block{display: flex; flex-direction: column; width: 100%; margin-top: 15px;}
  .certification-academy-block .design-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .certification-academy-block .design-block p{font-size: 14px; color:#000; margin:0 0 0px}
  .certification-academy-block .design-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .certification-academy-block .design-block li{display: flex; position:relative; background-color: #fff;  transition: all 0.2s ease; border-radius:12px; box-shadow: 0px 1px 6px #0000001A; justify-content: flex-start; align-items: center; width: 100%; max-width: 250px; padding:15px; margin:0  20px 20px 0; flex-direction: column;}
  .certification-academy-block .design-block li .thumb{display: flex; position:relative; width: 210px; height:150px; border-radius: 0%; margin-bottom: 15px; background-color: #d2d3d5;  align-items: center; justify-content: center;}
  .certification-academy-block .design-block li .thumb img{width: 210px; height:150px;}
  .certification-academy-block .design-block li .thumb-title{display: flex; height:38px; color:#000; margin-bottom: 10px; font-size: 16px; align-items: center; width: 100%;}
  .certification-academy-block .design-block li .thumb-content{display: flex; line-height: 20px;  color:#999;  margin-bottom: 15px; font-size: 14px; align-items: center; width: 100%;}
  .certification-academy-block .design-block li .thumb-content-block{display: flex; line-height: 20px;  color:#999;  margin-bottom: 15px; font-size: 14px; align-items: center; width: 100%;content-visibility:hidden;}
  .certification-academy-block .design-block li .duedate-tag{display: flex; width: auto;}
  .certification-academy-block .design-block li .duedate-tag span{display: flex; background-color: #e5e5e5;  color:#000; padding:5px 10px; border-radius:5px;  margin-bottom:0px; font-size: 12px; align-items: center;}
  .certification-academy-block .design-block li .thumb-action{display: flex;  align-items: center; justify-content: center; width: 100%;}
  .certification-academy-block .design-block li .thumb-action a{box-shadow: 0px 6px 8px #5FA8FF38; margin-left: 10px; color:#fff; font-size: 12px; padding:10px 25px; border-radius: 5px; background: transparent linear-gradient(288deg, var(--primary-rgba) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box;}
  .certification-academy-block .design-block li .thumb-action a:first-child{margin-left:0px;}
  .certification-academy-block .design-block li:hover{ -webkit-transform: translateY(-6px); transform: translateY(-6px);}

  .certification-academy-block .hoverOverlay{position: absolute; top:0; bottom:0; right:0; -webkit-perspective: 50em; perspective: 50em;    transition: all 0.35s ease; left:0; opacity: 0; border-radius:12px; visibility: hidden; background-color:rgba(0,0,0,0.9); padding:15px}
  .certification-academy-block .design-block li .hoverOverlay .thumb-title {color:#fff; font-size:16px; font-weight:bold; height:auto; border-bottom:1px solid #fff; padding-bottom:15px}
  .certification-academy-block .design-block li .hoverOverlay .thumb-content {color:#fff; line-height: 23px; margin-bottom:15px;}
  .certification-academy-block .design-block li .hoverOverlay .thumb-action a { box-shadow: 0 6px 8px rgba(27,206,80,.2196078431372549); font-size:15px; background-color: var(--primary); background: linear-gradient(288deg, var(--primary) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box; color:#fff; font-weight: bold;}
  .certification-academy-block .design-block li:hover .hoverOverlay{opacity: 1; visibility: visible;  transition-delay: 0.1s;}

  .stationaryshop-block{display: flex; flex-direction: column; width: 100%; background-color: #F2F4F6; padding: 25px; border-radius:0px;}
  .stationaryshop-block .topHeading{display: flex; align-items: center; margin-bottom: 10px;}
  .stationaryshop-block .topHeading a{display: flex;     box-shadow: 0px 1px 6px #0000001A; background-color: #fff; justify-content: center; margin-right: 15px; align-items: center; width:40px; height: 40px; border-radius:5px;}
  .stationaryshop-block h3{font-size: 22px; color:#000; margin:0 0 10px}
  .stationaryshop-block p{font-size: 14px; color:#000; margin:0 0 15px}
  .stationaryshop-block .design-block{display: flex; flex-direction: column; width: 100%; margin-top: 15px;}
  .stationaryshop-block .design-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .stationaryshop-block .design-block p{font-size: 14px; color:#000; margin:0 0 0px}
  .stationaryshop-block .design-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .stationaryshop-block .design-block li{display: flex; position: relative; background-color: #fff;  transition: all 0.2s ease; border-radius:12px; box-shadow: 0px 1px 6px #0000001A; justify-content: flex-start; align-items: center; width: 100%; max-width: 250px; padding:15px; margin:0  20px 20px 0; flex-direction: column;}
  .stationaryshop-block .design-block li .thumb{display: flex; width: 210px; height:210px; border-radius: 0%; margin-bottom: 15px; background-color: #d2d3d5;  align-items: center; justify-content: center;}
  .stationaryshop-block .design-block li .thumb img{max-width: 100%;}
  .stationaryshop-block .design-block li .thumb-title{display: flex; height:38px; color:#000; margin-bottom: 10px; font-size: 16px; align-items: flex-start; width: 100%;}
  .stationaryshop-block .design-block li .thumb-content{display: flex; line-height: 20px;  color:#999;  margin-bottom: 15px; font-size: 14px; align-items: center; width: 100%;}
  .stationaryshop-block .design-block li .duedate-tag{display: flex; width: auto;}
  .stationaryshop-block .design-block li .duedate-tag span{display: flex; background-color: #e5e5e5;  color:#000; padding:5px 10px; border-radius:5px;  margin-bottom:0px; font-size: 12px; align-items: center;}
  .stationaryshop-block .design-block li .thumb-action{display: flex;  align-items: center; justify-content:center; width: 100%;}
  .stationaryshop-block .design-block li .thumb-action a{box-shadow: 0px 6px 8px #5FA8FF38; margin-left: 10px; color:#fff; font-size: 12px; padding:10px 25px; border-radius: 5px; background: transparent linear-gradient(288deg, var(--primary-rgba) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box;}
  .stationaryshop-block .design-block li .thumb-action a:first-child{margin-left:0px;}
  .stationaryshop-block .design-block li:hover{ -webkit-transform: translateY(-6px); transform: translateY(-6px);}

  .stationaryshop-block .hoverOverlay{position: absolute; top:0; bottom:0; right:0; -webkit-perspective: 50em; perspective: 50em;    transition: all 0.35s ease; left:0; opacity: 0; border-radius:12px; visibility: hidden; background-color:rgba(0,0,0,0.9); padding:15px}
  .stationaryshop-block .design-block li .hoverOverlay .thumb-title {color:#fff; font-size:16px; font-weight:bold; height:auto; border-bottom:1px solid #fff; padding-bottom:15px}
  .stationaryshop-block .design-block li .hoverOverlay .thumb-content {color:#fff; line-height: 23px; margin-bottom:15px;}
  .stationaryshop-block .design-block li .hoverOverlay .thumb-action a { box-shadow: 0 6px 8px rgba(27,206,80,.2196078431372549); font-size:15px; background-color: var(--primary); background: linear-gradient(288deg, var(--primary) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box; color:#fff; font-weight: bold;}
  .stationaryshop-block .design-block li:hover .hoverOverlay{opacity: 1; visibility: visible;  transition-delay: 0.1s;}



  .mygoal-block{display: flex; flex-direction: column; width: 100%; background-color: #F2F4F6; padding: 25px; border-radius:0px;}
  .mygoal-block .topHeading{display: flex; align-items: center; margin-bottom: 10px;}
  .mygoal-block .topHeading a{display: flex;     box-shadow: 0px 1px 6px #0000001A; background-color: #fff; justify-content: center; margin-right: 15px; align-items: center; width:40px; height: 40px; border-radius:5px;}
  .mygoal-block h3{font-size: 22px; color:#000; margin:0 0 0px}
  .mygoal-block h6{font-size: 18px; color:#000; margin:15px 0 5px}
  .mygoal-block p{font-size: 14px; color:#000; margin:0 0 15px}
  .mygoal-block .design-block{display: flex; flex-direction: column; width: 100%;  box-shadow: 0px 1px 6px #0000001A;  margin-top: 15px; background-color: #fff; padding: 15px;}
  .mygoal-block .design-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .mygoal-block .design-block p{font-size: 14px; color:#000; margin:0 0 0px}
  .mygoal-block .design-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .mygoal-block .design-block li{display: flex; transition: all 0.2s ease; justify-content: center; align-items: center; width: 100%; padding:15px; margin:0  0px 20px 0; flex-direction: column;}
  .mygoal-block .design-block li .question-title{display: flex;  color:#000; margin-bottom: 17px; font-weight: bold; font-size: 18px; align-items: center; width: 100%;}
  .mygoal-block .design-block li .question-content{display: flex; line-height: 20px;  color:#999;  margin-bottom: 15px; font-size: 14px; align-items: center; width: 100%;}
  .mygoal-block .design-block li .question-action{display: flex;  align-items: center; justify-content: space-between; width: 100%;}
  .mygoal-block .design-block li .question-value{display: flex; flex-direction: column; width: 100%; align-items: flex-start;}
  .mygoal-block .design-block li .question-value .quill{width: 100%;}
  .mygoal-block .design-block li .question-value input {resize:none; width: 100%;  font-family: Lato;  font-size: 16px; border-radius:0;   line-height: 1.5; color: var(--text-black); padding:5px 10px; border:0px none; border-bottom: 1px solid var(--primary); background-color: var(--primary-rgba);}
  .mygoal-block .design-block li .question-value textarea {resize:none; width: 100%; min-height:80px;  font-family: Lato;  font-size: 16px; border-radius:0;   line-height: 1.5; color: var(--text-black); padding:5px 10px; border:0px none; border-radius:5px; background-color: transparent; border: 1px solid #ccc;}
  .mygoal-block .design-block .question-action {display: flex; margin-top: 15px; width: 100%; justify-content: flex-end; align-items: flex-end;}
  .mygoal-block .design-block .question-action button{border-radius:5px; background-color:var(--primary)}





  .askDialog.isvisible{display:flex;}
  .askDialog{position: fixed; display:flex; display: none; align-items: center; z-index: 2250000; justify-content: center; background-color:rgba(0,0,0,0.2); top:0; right:0; left:0; bottom:0}
  .askDialog .askDialog-inner{background-color: #fff; width: 100%; max-width: 600px; padding:32px 40px; border-radius:15px; position: relative;}
  .askDialog .askDialog-inner .popup-header{ width: 100%; text-align: center;  font-family: Lato; font-size: 18px;  font-weight: 700; color: var(--text-black);}
  .askDialog .askDialog-inner .close { position: absolute; top: 30px; right: 40px; font-weight: bold;  font-size: 21px;  cursor: pointer;
    -webkit-user-select: none;  -ms-user-select: none;  user-select: none;}
  .askDialog .askdialog-content{display: flex; width: 100%; margin-top: 35px;}
  .askDialog .askdialog-content .image-upload { width: 200px; margin-right: 20px; margin-top: 0;  display: flex;  flex-direction: column;  align-items: flex-start;}
  .askDialog .askdialog-content .image-upload  span { margin-left: 0px; font-family: Lato; font-size: 18px;font-weight: 600; color: var(--text-black2);}
  .askDialog .askdialog-content .image-upload img {width: 160px;}
  .askDialog textarea { width:100%; resize:none;  font-family: Lato;  font-size: 18px; line-height: 1.5; color: var(--text-black); padding: 14px 24px;  border-color: var(--light-black);   border-radius: 4px;}
  .askDialog .askdialog-action {display: flex; margin-top: 15px; width: 100%; justify-content: flex-end; align-items: flex-end;}
  .askDialog .askdialog-action button{border-radius:5px; background:transparent linear-gradient(288deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}

  .internshipDialog.isvisible{display:flex;}
  .internshipDialog{position: fixed; display:flex; display: none; align-items: center; z-index: 2250000; justify-content: center; background-color:rgba(0,0,0,0.2); top:0; right:0; left:0; bottom:0}
  .internshipDialog .internshipDialog-inner{background-color: #fff; width: 100%; max-width: 600px; padding:32px 40px; border-radius:15px; position: relative;}
  .internshipDialog .internshipDialog-inner .popup-header{ width: 100%; text-align: center;  font-family: Lato; font-size: 22px;  font-weight: 700; color: var(--text-black);}
  .internshipDialog .internshipDialog-inner .close { position: absolute; top: 30px; right: 40px; font-weight: bold;  font-size: 21px;  cursor: pointer;
    -webkit-user-select: none;  -ms-user-select: none;  user-select: none;}
  .internshipDialog .internshipdialog-content{display: flex; flex-direction: column; width: 100%; margin-top: 35px;}
  .internshipDialog .internshipdialog-content .form-block{display: flex; flex-direction: column; width: 100%; margin-bottom: 25px;}
  .internshipDialog .internshipdialog-content .form-block label{font-family: Lato; font-size: 16px; font-weight: 700; margin-bottom: 10px; color: var(--text-black);}
  .internshipDialog input { width:100%; resize:none; width: 100%;  font-family: Lato;  font-size: 16px; border-radius:0;   line-height: 1.5; color: var(--text-black); padding:5px 10px; border:0px none; border-bottom: 1px solid var(--light-black);}
  .internshipDialog .internshipdialog-action {display: flex; margin-top: 15px; width: 100%; justify-content: flex-end; align-items: flex-end;}
  .internshipDialog .internshipdialog-action button{border-radius:5px; background:transparent linear-gradient(288deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}


  .thanksDialog.isvisible{display:flex;}
  .thanksDialog{position: fixed; display:flex; display: none; align-items: center; z-index: 2250000; justify-content: center; background-color:rgba(0,0,0,0.2); top:0; right:0; left:0; bottom:0}
  .thanksDialog .thanksDialog-inner{background-color: #fff; width: 100%; max-width: 600px; padding:32px 40px; border-radius:15px; position: relative;}
  .thanksDialog .thanksDialog-inner .popup-header{ width: 100%; text-align: center;  font-family: Lato; font-size: 22px;  font-weight: 700; color: var(--text-black);}
  .thanksDialog .thanksDialog-inner .close { position: absolute; top: 30px; right: 40px; font-weight: bold;  font-size: 21px;  cursor: pointer;
    -webkit-user-select: none;  -ms-user-select: none;  user-select: none;}
  .thanksDialog .thanks-tick{display:flex; width: 100%;  font-family: Lato; font-size: 22px; color:#000; align-items: center; justify-content: center; flex-direction: column;}
  .thanksDialog .thanks-tick i{display:flex; align-items: center; margin-bottom:5px; width: 66px; height:66px; flex-shrink: 0; border-radius:100%; background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box; justify-content: center;}
  .thanksDialog .thanksDialog-content{display: flex; flex-direction: column; width: 100%; margin-top: 35px;}
  .thanksDialog ol{display:flex; margin:0; padding:0; display:flex; flex-direction: column;}
  .thanksDialog ol li{display:list-item; margin:15px 0 0; padding:0; list-style-type: decimal;  font-family: Lato; color:#666666; font-size:16px; list-style-position:inside;}


  .thanksApplyDialog.isvisible{display:flex;}
  .thanksApplyDialog{position: fixed; display:flex;  display: none; align-items: center; z-index: 2250000; justify-content: center; background-color:rgba(0,0,0,0.2); top:0; right:0; left:0; bottom:0}
  .thanksApplyDialog .thanksApplyDialog-inner{background-color: #fff; width: 100%; max-width: 400px; padding:32px 40px; border-radius:15px; position: relative;}
  .thanksApplyDialog .thanksApplyDialog-inner .popup-header{ justify-content: center; display: flex; align-items: center; width: 100%; text-align: center;  font-family: Lato; font-size: 22px;  font-weight: 700; color: var(--text-black);}
  .thanksApplyDialog .thanksApplyDialog-inner .close { position: absolute; top: 15px; right: 15px; font-weight: bolder;  font-size:15px;  cursor: pointer; -webkit-user-select: none;  -ms-user-select: none;  user-select: none;}
  .thanksApplyDialog i{display:flex; align-items: center; margin-right:10px; width: 40px; height:40px; flex-shrink: 0; border-radius:100%; background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box; justify-content: center;}
  .thanksApplyDialog i img{width:23px;}
  .thanksApplyDialog .thanksApplyDialog-content{display: flex; align-items: center; font-family: Lato; color:#999; font-size: 15px; flex-direction: column; width: 100%; margin-top: 30px;}



  .forMobile{display:flex; display: none;}
  .forMobile a{display:flex; position: relative; top:0}
  .forMobile span{width:30px; position: absolute; top:0; left:0; height:4px; background-color:var(--white);}
  .forMobile span:nth-child(1){top:0px}
  .forMobile span:nth-child(2){top:10px}
  .forMobile span:nth-child(3){top:20px}


  .mobileview .header2{left:0; z-index: 2200001;}
  
  .MobielCloseLink{position: absolute; top:10px; right:10px; color:#fff; display:none; font-weight: bold; font-size: 25px;} 

  .mobileOverlay {position:fixed; top:0; display:none; right:0; bottom:0; left:0; z-index: 220000; background-color:rgba(0,0,0,0.5)}

  .mobileview .mobileOverlay{display:flex;}


  .mycoach-wrapper .mycoach-right .fun-block-list h3{font-size: 16px; color:#000; margin:0 0 15px}
.mycoach-wrapper .mycoach-right .fun-block-list ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li{display: flex; transition: all 0.2s ease;  width: 100%; max-width: 30%; border-radius:18px; color:#fff;  margin:0  0px 0px 20px; padding:15px; flex-direction: column;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(1){background-image: url(/static/media/veranda-innovation.18d86c19.png);
    background-repeat: no-repeat;
    background-size: cover;margin-left: 0;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li a{color:#fff; display: flex; flex-direction: column;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(2){background-color: rgba(255,82,2,0.7);}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(2) a{pointer-events: auto; opacity:1; cursor: pointer;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(3) a{pointer-events: auto; opacity:1; cursor: pointer;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(3){background-color: rgba(95,168,255,0.7);}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(1) i{margin-bottom: 15px; align-items: center; justify-content: center; display: flex;     align-self: flex-end; background-color:rgba(96,96,25,0.2); border-radius:100%; width: 60px;  height:60px;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(2) i{margin-bottom: 15px; align-items: center; justify-content: center;   display: flex; align-self: flex-end; background-color:rgba(96,96,25,0.2); border-radius:100%; width: 60px; height:60px;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(2) i img{width:35px; max-width: 100%;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(3) i img{width:35px; max-width: 100%;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(3) i{margin-bottom: 15px;  align-items: center; justify-content: center; display: flex;  align-self: flex-end; background-color:rgba(96,96,25,0.2); border-radius:100%; width: 60px; height:60px;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:hover{ -webkit-transform: translateY(-6px); transform: translateY(-6px);}

.refer-more{
    margin-top: 15px;
    margin-left: 176px;
}

.refer-more button{
    margin-right: 10px;
}

.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(1) h3{
  font-size: 17px;
  color: #fff;
  margin: 0 0 5px;
}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(1) p{
  font-style: italic;
  font-size: 14px;
  color: #fff;
  margin: 0 0 0px;
}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(1) p + p{
  margin-top: 2px;
}

.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(1) .arrow{
  position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: rgba(255,255,255,0.2);
}

.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(3) {
    background: transparent linear-gradient(121deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;
}


.mycoach-wrapper .mycoach-right .fun-block-list ol li h3 {
    font-size: 17px;
    color: #fff;
    margin: 0 0 15px;
}
.mycoach-wrapper .mycoach-right .fun-block-list ol li .content{
    display: flex;
}

.mycoach-wrapper .mycoach-right .fun-block-list ol li .content i{
  margin-left: 30px;
}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:last-child span{
  font-size: 14px;
    padding: 5px 15px;
    border-radius: 21px;
    color: #1D73F2;
    background-color: #fff;
    margin: 0 0 0px;
}

  /*Media Query*/

  @media only screen and (min-width: 1000px) {
    .mycoach-wrapper .mycoach-right .coach-block ol li{ max-width: 21%;}
    .mycoach-wrapper .mycoach-right .fun-block ol li{width: 28%;}

    /* body{background-color:#fff!important} */
  } 


  @media only screen and (max-width: 1200px) {
    .mycoach-wrapper .mycoach-right .coach-block ol li{ max-width: 20%;}
    /* body{background-color:#fff!important} */
    #root{width:100%}
  }

  @media only screen and (max-width: 1100px) {
    .app-header .profile{min-height:31px;}
    .mycoach-wrapper .mycoach-right .coach-block ol li{ max-width: 21%; text-align: center;}
    .mycoach-wrapper .mycoach-main{flex-direction: column; padding:15px;}
    .mycoach-wrapper .mycoach-main{display:block}

    .mycoach-wrapper .mycoach-left ol{flex-direction: row; overflow-x: auto;}
    .mycoach-wrapper .mycoach-left{max-width:100%; margin-bottom:20px;}
    .mycoach-wrapper .mycoach-left ol li{margin:0 0 0 15px; min-width: 240px}
    .mycoach-wrapper .mycoach-left ol li:first-child{margin-left:0}
    .mycoach-wrapper .mycoach-right{margin-left:0}
    .mycoach-wrapper .mycoach-right-bottom{flex-direction:column;}
    .mycoach-wrapper .mycoach-right-bottom .subcribe-block{max-width:none;}
    .mycoach-wrapper .mycoach-right .fun-block{margin:25px 0 0 0; max-width:none;}

    .app-body{position: relative;}
    .app-div.mycoach-div{top:auto}
    .app-div.doubt-main{top:auto}

    .mycoach-header{position: static; flex-direction: column; background-color:#fff; padding:15px 28px; margin: 15px 0 0 250px;}
    .mycoach-header .intro-block{flex-direction:column; margin:0px;}
    .mycoach-header .intro-block .user-block{width: 100%; order:2; max-width:80%;}
    .mycoach-header .intro-block .askanything{order:1;  align-self: flex-end; position:absolute;}

    .app-header{position: static; padding: 28px 28px 0;}
    .profile-option div{background-color: #fff; margin-right:0}
    .mypercentage-block .design-block li{max-width: 100%; margin:0 0 15px}

    .popup-doubt-content{width:340px!important;}
    .popup-ask-doubt .doubt-content-div{flex-direction:column; height:auto; margin-top:0}
    .popup-ask-doubt .image-upload{width: 100%; align-items: center;}
    .popup-ask-doubt .ask-doubt-content{margin-top:0}
    .popup-ask-doubt .ask-doubt-content textarea {width: 100%; align-items: center;}
    .popup-ask-doubt .ask-doubt-action{margin-top:0px}

    .popup-doubt2-content{width:340px!important; height: auto!important;}
    .popup-doubt2-content .ask-doubt-content .doubt-content-div{flex-direction:column; height:auto}
    .popup-doubt2-content .ask-doubt-content .doubt-content-div .image-upload{width: 100%; align-items: center;}
    .popup-doubt2-content .ask-doubt-content textarea {width: 100%; align-items: center;}
    .popup-doubt2-content .ask-doubt-action{margin-top:20px}

    .thanksDialog .thanksDialog-inner{max-width: 520px;}
    .thanksDialog .thanks-tick{margin-bottom:15px}
    .internshipDialog .internshipDialog-inner{max-width: 520px;}
    .mycoach-wrapper .mycoach-right .fun-block-list ol{flex-direction: column;}
    .mycoach-wrapper .mycoach-right .fun-block-list ol li{max-width:none; margin:0 0 15px 0;}
  }

 
  @media only screen and (max-width: 900px) {

    body{background-color:var(--primary)!important}

    .mycoach-wrapper .mycoach-right .coach-block ol{align-items: center; justify-content: center;}
    .mycoach-wrapper .mycoach-right .coach-block ol li{max-width: 40%; margin:0 10px 20px}
    .mycoach-wrapper .mycoach-right .fun-block ol{overflow-x:auto; flex-wrap:nowrap}
    .mycoach-wrapper .mycoach-right .fun-block ol li{min-width: 160px;}
    .forMobile{display:none;}
    .MobielCloseLink{display:flex;}
    .header2{left:-250px}
    .app-div.mycoach-div{left:0}
    .mycoach-header{left:0}
    .app-header{left:0; padding-left:28px;}
    .app-div.doubt-main{left:0; height: calc(100vh - 205px);}
    .app-div.app-dashboard{left:0;    height: calc(100vh - 145px); background-color: #F2F4F6; top:auto; padding:25px; border-radius:0}
    .app-div.app-webinar{left:0;    height: calc(100vh - 145px); background-color: #F2F4F6; top:auto; padding:25px; border-radius:0}

    .app-div.app-studymaterial{left:0;    height: calc(100vh - 145px); background-color: #F2F4F6; top:auto; padding:25px; border-radius:0}
    .app-div.app-studymaterial .sm-chapters{width:100%;}
    .app-div.app-studymaterial .sm-unit-div{flex-direction:column;}
    .app-div.app-studymaterial .sm-unit-div1{width:100%; height:auto; margin-top: 10px;}
    .app-div.app-studymaterial .sm-unit-div1 span{position:static}

    .dashboard-main{display: flex; flex-direction: column;}
    .dashboard-main .dashboard-left{width:100%}
    .dashboard-main .dashboard-right{width:100%; margin-left:0}
    .dashboard-main .dashboard-left .dash-subject{width:100%; margin:0 0px 20px 0}
    .dashboard-main .dashboard-left .dash-practice{width:100%}
    .dropdown-content2{z-index:20}

    .single-doubt{display: flex; flex-direction: column;}
    .single-doubt-right{margin-bottom:15px; width: 100%;} 

    .popup-content-edit-pofile-content{position: fixed!important; z-index: 20004!important; left: 0;   top: 0;   bottom: 0;   right: 0;}
    .popup-content-edit-pofile-content .popup-inputs-edit-profile{flex-direction:column}
    .popup-content-edit-pofile-content .popup-inputs-edit-profile input:first-child{width:100%!important; margin-bottom:15px;}
    .popup-content-edit-pofile-content .popup-inputs-edit-profile input:last-child{width:100%!important; margin-bottom:15px;}
    .popup-content-edit-pofile-content .img-prev{width:100%; margin:0}

 

   #root{width:100%}

    .app-body{position: relative;}
    .app-div.mycoach-div{top:auto; width:100%}
    .app-div.doubt-main{top:auto; height: calc(100vh - 420px); right:0}
    

    .mycoach-header{position: static; flex-direction: column; background-color:#fff; padding:15px 28px; margin: 15px 0 0;}
    .mycoach-header .intro-block{flex-direction:column; margin:0px;}
    .mycoach-header .intro-block .user-block{width: 100%; order:2; max-width: 80%;}
    .mycoach-header .intro-block .askanything{order:1;  align-self: flex-end; position:absolute; font-size:0}

    .app-header{position: static; padding: 28px 28px 0; width: 100%;}
    .profile-option div{background-color: #fff;}
    .mypercentage-block .design-block li{max-width: 100%; margin:0 0 15px}

    
    .mycoach-header .intro-block .askanything .ask-doubt{z-index:11}


    .page-header{display:flex; flex-direction:column; justify-content: center;  align-items: center;}
    .page-header .page-header-left{margin-bottom:15px}


    .list-sticky{right:0; width:100%; padding: 25px;  height: calc(100vh - 168px);}
    .dash-test-first{margin:0 0 15px}
    .dash-test{width:100%;}

    .mycoach-wrapper .mycoach-right .fun-block-list ol{flex-direction: column;}
 

  }   

  @media only screen and (max-width: 900px) and (orientation:landscape){

    .app-div.mycoach-div{height: calc(100vh - 204px);}
    .app-div.doubt-main{top:auto; height: calc(100vh - 205px);}

  }

  

  @media only screen and (max-width: 770px) {

    .thanksDialog .thanksDialog-inner{max-width: 320px;}
    .internshipDialog .internshipDialog-inner{max-width: 320px;}

    .app-div.mycoach-div{height: calc(100vh - 420px);}
    .mycoach-wrapper .mycoach-right .coach-block{height:auto!important}
    .refer-more{
        margin-top: 15px;
        margin-left: 0px;
    }
  }
  

  @media only screen and (max-width: 470px) {

    .sm-chapters li{max-width: none;}
    

  }


.mycoach-wrapper .mycoach-right .fun-block-list {
    box-shadow: 0px 3px 6px #9090901F;
    background-color: #fff;
    border-radius: 18px;
    padding: 20px;
    width:100%;
}

@-webkit-keyframes shake {
  0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
  20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes shake {
  0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
  20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
}

/*Media Query*/

.ask-doubt {
  z-index: 999;
  background-color: transparent;
  position: static;
  right: 40px;
  bottom: 30px;
  display: none;
}

.mycoach-header .intro-block .ask-doubt{display: flex;}
.doubt-content-div{grid-gap:30px;gap:30px;}

.ask-doubt-content .filterBlock{display: flex; margin:15px 0; grid-gap:15px; gap:15px; flex-direction: row; width: 100%;}
.ask-doubt-content .filterBlock .block{height: auto; width: auto; grid-gap:10px; gap:10px; height: auto; min-height: unset;}

.ask-doubt-content .block{display: flex;  flex-direction: column;    height: 100%;  width: 100%; min-height: 100px;}
.ask-doubt-content .block textarea{width: 100%; font-size: 14px;}
.askdoubt-button {
  width: 80px;
  height: 80px;
  background-color: var(--primary);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 6px 6px 8px -3px #888888;
}

.popup-doubt-overlay{z-index: 2250000!important; }

.askdoubt-button {width:40px; height:40px; background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}

.askdoubt-button img {
  width: 40px;
}

.askdoubt-button img {
  width: 20px;
}

.popup-ask-doubt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.popup-ask-doubt .close {
  position: absolute;
  top: 30px;
  right: 40px;
  font-weight: bold;
  font-size: 21px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.popup-ask-doubt .close:hover {
  opacity: 0.8;
}

.ask-doubt-content {
  width: 100%;
  margin-top: 35px;
}

.ask-doubt-content select {
  width: 250px;
  font-family: Lato;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 9px 14px;
  border-radius: 4px;
}

.doubt-content-div {
  margin-top: 35px;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.image-upload {
  width: 200px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.image-upload span {
  margin-left: 12px;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  color: var(--text-black2);
}

.image-upload img {
  width: 160px;
}

.post-doubt-img {
  position: relative;
  margin-top: 20px;
  width: 210px;
  height: 140px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
}

.post-doubt-img .close {
  position: absolute;
  width: 24px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 6px;
  right: 10px;
  font-weight: bold;
  font-size: 17px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: var(--white);
  background-color: #444444;
}

.ask-doubt-content textarea {
  width: calc(100% - 230px);
  height: 100%;
  font-family: Lato;
  font-size: 18px;
  line-height: 1.5;
  color: var(--text-black);
  padding: 14px 24px;
  border-color: var(--primary);
  background-color: var(--primary-rgba);
  border-radius: 4px;
}

.ask-doubt-action {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.popup-doubt-subjects {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.popup-doubt-subjects div {
  padding: 10px 16px 10px 16px;
  background-color: var(--primary-rgba);
  color: var(--text-black2);
  margin-right: 18px;
  border-radius: 5px;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.popup-doubt-subjects div:last-child {
  margin-right: 0;
}

.popup-doubt-subjects div:hover {
  opacity: 0.85;
}

#selected-dsubject {
  background-color: var(--primary);
  color: var(--white);
}


@media only screen and (max-width: 600px) {  
  .ask-doubt{width:50px; height:50px; bottom: 20px; z-index: 2200001; left:50%; margin-left:-25px;}
  .ask-doubt .askdoubt-button{width:50px; height:50px;}
  .ask-doubt .askdoubt-button img {width:32px;}
  .popup-doubt-content{width: 350px!important; padding:15px!important; height:auto}
  .popup-doubt-content .popup-doubt-subjects div{padding:10px; margin:0px 10px 10px 0}
  .doubt-content-div{flex-direction:column; height:auto}
  .doubt-content-div .image-upload{width: 100%;margin-bottom:15px;}
  .doubt-content-div .image-upload img{width: 100px;}
  .doubt-content-div .image-upload span{margin-left:0}
  .doubt-content-div textarea{width: 100%; max-height:100px; min-height:100px}
  .doubt-content-div .image-upload .post-doubt-img{width: 100%;}
  .popup-doubt-content .ask-doubt-action{margin-top:15px;}
  .ask-doubt-content{height:300px; overflow:auto;}
}
#no-data-admin{
    /* text-align: center; */
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    font-family: Lato;
    font-weight: 700;
    font-size: 25px;
    /* padding-top: 150px; */
    color: var(--text-black2);
}

.curriculumsFilterBlock{display: flex; width: 100%; font-family: Lato; margin-bottom: 30px;  justify-content: space-between; align-items: center;}
.curriculumsFilterBlock div{display: flex; align-items: center;}
.curriculumsFilterBlock div select{max-width: 200px; margin-left: 15px;}
.curriculumsFilterBlock div select:first-child{margin-left: 0;}
.curriculumsFilterBlock div button:disabled{pointer-events: none; opacity: 0.6;}
.curriculumsFilterBlock div button{ background-color: var(--secondary); cursor: pointer; margin-left: 15px; white-space: nowrap; font-weight: 700; border-radius: 8px; display: flex; align-items: center; justify-content: center; padding: 11px 14px 12px 14px; font-size: 16px; color:#fff;}
.curriculumsFilterBlock div button img{max-width: 18px; margin-right: 5px;}
.curriculumsFilterBlock h1{font-size: 22px;}
.addCurriculumsDialog.open{display: flex;}
.addCurriculumsDialog{position: fixed;   font-family: Lato;  z-index: 999; top:0; left:0; right:0; bottom:0; background-color: rgba(0,0,0, 0.4); display: flex; display: none; align-items: center; justify-content: center; }
.addCurriculumsDialog .addCurriculumsContainer{background-color: #fff; padding: 15px; border-radius: 10px; width:100%; max-width: 500px;}
.addCurriculumsDialog .addCurriculumsContainer h1{border-bottom:1px solid #ddd; padding-bottom: 5px; font-size: 18px; border-bottom:1px solid #ddd; font-weight: 700; margin-bottom: 15px;}
.addCurriculumsDialog .addCurriculumsContainer .formWrapper{display: flex; flex-direction: column; width: 100%;}
.addCurriculumsDialog .addCurriculumsContainer .formWrapper .formGroup{display: flex; flex-direction: column; width: 100%; margin-bottom: 15px;}
.addCurriculumsDialog .addCurriculumsContainer .formWrapper .formGroup label{ padding:0; font-size: 16px; margin:0; margin-bottom: 5px; display: flex; width: 100%;}
.addCurriculumsDialog .addCurriculumsContainer .formWrapper .formGroup input[type="text"]{font-family: Lato; font-size: 16px; background-color: var(--secondary-rgba); border: 1px solid var(--secondary); color: var(--text-black); padding: 12px 16px; border-radius: 4px;}
.addCurriculumsDialog .addCurriculumsContainer .formWrapper .formGroup textarea{font-family: Lato; font-size: 16px; background-color: var(--secondary-rgba); border: 1px solid var(--secondary); color: var(--text-black); padding: 12px 16px; border-radius: 4px;}
.addCurriculumsDialog .addCurriculumsContainer .formWrapper .formGroup input[type="file"]{font-family: Lato; font-size: 16px; background-color: var(--secondary-rgba); border: 1px solid var(--secondary); color: var(--text-black); padding: 12px 16px; border-radius: 4px;}
.addCurriculumsDialog .addCurriculumsContainer .formWrapper .formGroup .note{font-size: 14px; color: #999; margin-top: 5px;}
.addCurriculumsDialog .addCurriculumsContainer .formActions{display: flex; width: 100%; justify-content: center;border-top:1px solid #ddd; padding-top: 10px;}
.addCurriculumsDialog .addCurriculumsContainer .formActions button{margin-left: 10px;}
.addCurriculumsDialog .addCurriculumsContainer .formActions button:first-child{margin-left:0px;}


.editCurriculumsDialog.open{display: flex;}
.editCurriculumsDialog{position: fixed;   font-family: Lato;  z-index: 999; top:0; left:0; right:0; bottom:0; background-color: rgba(0,0,0, 0.4); display: flex; display: none; align-items: center; justify-content: center; }
.editCurriculumsDialog .editCurriculumsContainer{background-color: #fff; padding: 15px; border-radius: 10px; width:100%; max-width: 500px;}
.editCurriculumsDialog .editCurriculumsContainer h1{border-bottom:1px solid #ddd; padding-bottom: 5px; font-size: 18px; border-bottom:1px solid #ddd; font-weight: 700; margin-bottom: 15px;}
.editCurriculumsDialog .editCurriculumsContainer .formWrapper{display: flex; flex-direction: column; width: 100%;}
.editCurriculumsDialog .editCurriculumsContainer .formWrapper .formGroup{display: flex; flex-direction: column; width: 100%; margin-bottom: 15px;}
.editCurriculumsDialog .editCurriculumsContainer .formWrapper .formGroup label{ padding:0; font-size: 16px; margin:0; margin-bottom: 5px; display: flex; width: 100%;}
.editCurriculumsDialog .editCurriculumsContainer .formWrapper .formGroup input[type="text"]{font-family: Lato; font-size: 16px; background-color: var(--secondary-rgba); border: 1px solid var(--secondary); color: var(--text-black); padding: 12px 16px; border-radius: 4px;}
.editCurriculumsDialog .editCurriculumsContainer .formWrapper .formGroup textarea{font-family: Lato; font-size: 16px; background-color: var(--secondary-rgba); border: 1px solid var(--secondary); color: var(--text-black); padding: 12px 16px; border-radius: 4px;}
.editCurriculumsDialog .editCurriculumsContainer .formWrapper .formGroup input[type="file"]{font-family: Lato; font-size: 16px; background-color: var(--secondary-rgba); border: 1px solid var(--secondary); color: var(--text-black); padding: 12px 16px; border-radius: 4px;}
.editCurriculumsDialog .editCurriculumsContainer .formActions{display: flex; width: 100%; justify-content: center;border-top:1px solid #ddd; padding-top: 10px;}
.editCurriculumsDialog .editCurriculumsContainer .formActions button{margin-left: 10px;}
.editCurriculumsDialog .editCurriculumsContainer .formWrapper .formGroup .note{font-size: 14px; color: #999; margin-top: 5px;}
.editCurriculumsDialog .editCurriculumsContainer .formActions button:first-child{margin-left:0px;}

.curriculumsWrapper{display: flex; width: 100%; font-family: Lato; }
.curriculumsWrapper ol{display: flex; width: 100%; margin:0; padding:0; flex-wrap: wrap;}
.curriculumsWrapper ol li{display: flex; flex-direction: column; width: 100%; max-width: 31%; padding:25px; margin: 20px 10px; border-radius:15px; background-color: var(--primary-rgba);}
.curriculumsWrapper ol li p{margin:5px 0 0;}
.curriculumsWrapper ol li .actions{display: flex; width: 100%; justify-content: flex-end;}
.curriculumsWrapper ol li .actions span{background-color: var(--light-black); display: flex; align-items: center; justify-content: center; width:40px; height:40px; border-radius: 8px;}
.curriculumsWrapper ol li .actions span img{width: 20px;}
.curriculumsWrapper ol li .actions a{background-color: var(--light-black); display: flex; align-items: center; justify-content: center; margin-left: 10px; width:40px; height:40px; border-radius: 8px;}
.curriculumsWrapper ol li .actions a img{width: 20px;}
.curriculumsLoader{display: flex; width: 100%; display: flex; align-items: center; background-color: #fff; justify-content: center; position: absolute;top:0; left:0; right:0; bottom:0;}
.curriculumsWrapper .nodatafound{display: flex; flex-direction: column; justify-content: center;}
.curriculumsWrapper .nodatafound span{color:#999; margin-top: 15px;}
.firbaseNotificationsWrapper{display: flex; width: 100%; max-width: 400px; flex-direction: column;  font-family: Lato; font-size: 14px; color: var(--text-black);}
.firbaseNotificationsWrapper h1{font-size: 24px;}
.firbaseNotificationsWrapper .formBlock{display: flex; flex-direction: column; width: 100%; margin-top: 20px;}
.firbaseNotificationsWrapper .formBlock label{font-weight: 700; font-size: 16px; padding:0; margin-bottom: 10px;}
.firbaseNotificationsWrapper .formBlock label .required{color: red;}
.firbaseNotificationsWrapper .formBlock{display: flex; flex-direction: column; width: 100%;}
.firbaseNotificationsWrapper .formBlock input[type="datetime-local"]{font-family: Lato; font-size: 16px;  background-color: var(--secondary-rgba); border: 1px solid var(--primary); color: var(--text-black); padding: 12px 16px; border-radius: 4px; width:100%;}
.firbaseNotificationsWrapper .formBlock input[type="text"]{font-family: Lato; font-size: 16px;  background-color: var(--secondary-rgba); border: 1px solid var(--primary); color: var(--text-black); padding: 12px 16px; border-radius: 4px; width:100%;}
.firbaseNotificationsWrapper .formBlock textarea{font-family: Lato; font-size: 16px;  background-color: var(--secondary-rgba); border: 1px solid var(--primary); color: var(--text-black); padding: 12px 16px; border-radius: 4px; width:100%;}
.firbaseNotificationsWrapper .formActions{margin-top: 20px; display: flex; width: 100%; justify-content: center; align-items: center;}
.firbaseNotificationsWrapper .marginLeft20{margin-left: 20px;}
.firbaseNotificationsWrapper .formBlock .block{display:flex; flex-direction: column; grid-gap:20px; gap:20px;}
.firbaseNotificationsWrapper .formBlock .css-1s2u09g-control{background-color: var(--secondary-rgba); border-color:var(--primary);}
.firbaseNotificationsWrapper .formBlock .css-1okebmr-indicatorSeparator{background-color:var(--primary);}
.firbaseNotificationsWrapper .formBlock .css-tj5bde-Svg{fill:var(--primary)}
.firbaseNotificationsWrapper .formBlock .css-14el2xx-placeholder{ color: var(--text-black);}
.mark-attendance-page .sub-head{ padding-bottom: 16px; margin-bottom: 16px; border-bottom: 1px solid rgba(0,0,0,0.14);}
.mark-attendance-page .heading h1{font-size: 24px; font-weight: 600; margin:0; padding: 0;}
.markAttendanceWrapper{display: flex; flex-direction: column; font-family: lato;}
.markAttendanceWrapper .markAttendanceContainer{display: flex; flex-direction: column;}
.markAttendanceWrapper .markAttendanceContainer ol{display: flex; grid-gap:15px; gap:15px; margin: 0; padding: 0; width: 100%; flex-direction: column;}
.markAttendanceWrapper .markAttendanceContainer ol li{  padding: 10px;  border-radius: 10px; border: 1px solid var(--primary); display: flex; align-items: center;  margin: 0;  grid-gap:15px;  gap:15px; width: 100%;}
.markAttendanceWrapper .markAttendanceContainer ol li .thumb{ display:flex; width: 100%;  max-width: 80px; display:none;}
.markAttendanceWrapper .markAttendanceContainer ol li:hover{background-color: var(--primary-rgba);}
.markAttendanceWrapper .markAttendanceContainer ol li .thumb img{max-width: 100%;}
.markAttendanceWrapper .markAttendanceContainer ol li .thumbContent{ display:flex; flex:1 1; align-items: center;}
.markAttendanceWrapper .markAttendanceContainer ol li .thumbContent div:nth-child(1){flex:1 1; grid-gap:6px; gap:6px; display: flex; flex-direction: column;}
.markAttendanceWrapper .markAttendanceContainer ol li .thumbContent h1{margin-bottom: 0;}
.markAttendanceWrapper .markAttendanceContainer ol li .thumbContent p{margin: 0;}
.mark-attendance-page .sub-head input[type="search"] {
    border: 1px solid var(--primary);
    background-color: var(--primary-rgba);
    border-radius: 5px;
    padding: 8px 10px;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px 20px;
    width: 100%;
}
.mark-attendance-page .sub-head  .filterBlock{display: flex;  font-family: lato; grid-gap:10px; gap:10px; align-items: center; min-width: 250px;}
.mark-attendance-page .byClassHeading{font-size: 16px; font-weight: 600; margin:0 0 25px; padding: 0;}
.markAttendanceWrapper .markAttendanceContainer ol li.noResultMessage{border:0px none; justify-content: center; align-items: center; padding: 20px; background-color: #ddd;;}
.export-file{
  margin-top: 10px;
  cursor: pointer;
}

.export-file img{
  width: 25px;
}

.export-file span{
  font-family: lato;
  font-size: 20px;
  border: 1px solid white;
  padding: 14px;
}


.buildCacheWrapper{display: flex; width: 100%; font-family: lato; flex-direction: column;}
.buildCacheWrapper h2{ border-bottom:0px solid #ddd; padding-bottom: 10px; margin:0 0 20px }
.buildCacheWrapper ol {display: flex; grid-gap:10px; gap:10px; margin:0; padding:0; flex-direction: column;}
.buildCacheWrapper ol li{display: flex; margin:0; border-radius: 5px; border: 1px solid #ddd; padding:10px; align-items: center; justify-content: space-between; }
.buildCacheWrapper ol li img{max-width: none; width: unset;}
.buildCacheWrapper ol li div{display: flex; align-items: center; grid-gap:0px; gap:0px;}
.buildCacheWrapper ol li div button{ margin-left: 20px;}
.buildCacheWrapper ol li div .LoaderImage{max-width: 45px; display: none;}
.buildCacheWrapper ol li div .LoaderImage img{max-width: 100%;}
.buildCacheWrapper ol li.clicked-class .LoaderImage{display: flex;}
.export-file{
  margin-top: 10px;
  cursor: pointer;
}

.export-file img{
  width: 25px;
}

.export-file span{
  font-family: lato;
  font-size: 20px;
  border: 1px solid white;
  padding: 14px;
}




.studentListingBlock{font-family: Lato; font-size: 14px;}
.studentListingBlock table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  font-family: 'Arial', sans-serif;
  min-width: 400px;

}

.studentListingBlock th, .studentListingBlock td {
  padding: 12px 15px;
  text-align: left;
}

.studentListingBlock th {

  text-transform: uppercase;
}

.studentListingBlock tr {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.studentListingBlock tr:nth-of-type(even) {
  background-color: #f3f3f3;
}


.courseDashboardWrapper{display: flex; flex-direction: column; width: 100%;}
.courseDashboardWrapper .courseDashboardAccordian{display: flex; background-color: #fff; flex-direction: column; padding: 20px; border-radius: 15px; width: 100%; box-shadow: 0px 0px 10px #e3dddd;}
.courseDashboardWrapper .courseDashboardAccordian .accordianData{display: flex; flex-direction: column; grid-gap:15px; gap:15px; width: 100%;}
.courseDashboardWrapper .courseDashboardAccordian .accordianData .thumb{display: flex; max-width: 80px;}
.courseDashboardWrapper .courseDashboardAccordian .accordianData .accordianInner .thumb{display: flex; max-width:34px;}
.courseDashboardWrapper .courseDashboardAccordian .accordianData .thumb img{max-width:100%; object-fit: cover;}
.courseDashboardWrapper .courseDashboardAccordian .accordianTop{display: flex;border-bottom:0px solid #eaeaea; padding-bottom: 10px; margin-bottom: 15px;   border-bottom:0px solid #eaeaea; padding-bottom:0px; margin-bottom: 10px; grid-gap:15px; gap:15px; width: 100%;}
.courseDashboardWrapper .courseDashboardAccordian .accordianInner{display: flex; background-color: #eeeeff; flex-direction: column; border: 1px solid #eaeaea; padding: 20px; border-radius: 15px; margin-bottom:0px;   border-bottom: 1px solid #eaeaea; padding-bottom: 10px; margin-bottom:0px; grid-gap:15px; gap:15px; width: 100%;}
.courseDashboardWrapper .courseDashboardAccordian .accordianTop{ align-items: center; cursor: pointer; }
.courseDashboardWrapper .courseDashboardAccordian .thumbContent{flex:1 1; display: flex; grid-gap:40px; gap:40px; align-items: center;}
.courseDashboardWrapper .courseDashboardAccordian .thumbContent .left{flex:1 1; flex-direction: column; display: flex; grid-gap:10px; gap:10px}
.courseDashboardWrapper .courseDashboardAccordian .thumbContent .completedPer{display: flex;     align-items: center;}
.courseDashboardWrapper .courseDashboardAccordian .thumbContent .left p{color:#999; font-size: 14px; margin:0;}
.courseDashboardWrapper .courseDashboardAccordian .accordianInner .accordianTop .thumbContent .left h3{font-size: 16px;}
.courseDashboardWrapper .courseDashboardAccordian .thumbContent .circular-progress{ width:80px; height: 80px; border-radius: 50%; display: flex; align-items: center; justify-content: center; -webkit-animation: css-progress 2s 1 forwards; animation: css-progress 2s 1 forwards; background: radial-gradient(closest-side, white 79%, transparent 80% 100%, white 0), conic-gradient(hotpink calc(var(--progress-value)* 1%), pink 0);}

.courseDashboardWrapper .courseDashboardAccordian .thumbContent .circular-progress + div{display: flex; align-items: center;}


.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner{display: flex; background-color: #fff; padding:15px; border-radius: 15px; flex-direction: column; grid-gap:30px; gap:30px;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .thumbContent{flex:1 1; display: flex; grid-gap:40px; gap:40px;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .weekWrapper{flex:1 1;display: flex; grid-gap:20px; gap:20px; flex-direction: column;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .completedPer{display: flex; align-items: center;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .completedPer span{color:var(--primary)}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .completedPer span.red{color:#e77f7f}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .weekWrapper ol{display: flex; flex-direction: column; grid-gap:10px; gap:10px; width: 100%; margin:0; padding: 0;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .weekWrapper ol li{display: flex; border-bottom: 1px solid #eaeaea; padding:0px 0 10px; justify-content: space-between; align-items: center; width: 100%; margin:0;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .weekWrapper ol li div{display: flex; align-items: center; grid-gap:10px; gap:10px;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .weekWrapper .accordianTop{margin-bottom: 0; cursor: pointer; border-bottom: 1px solid #eaeaea; padding: 0 0 10px;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .weekWrapper ol li:last-child{border-bottom:0px none;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .weekWrapper .accordianSubContent{height:0px; opacity: 0; display: none; visibility: hidden; transition: all 0.2s ease;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .weekWrapper .accordianTop.active + .accordianSubContent{height:auto; display:flex;  opacity: 1; visibility: visible;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .weekWrapper .accordianTop.active i{-webkit-transform:rotate(-180deg);transform:rotate(-180deg); transition: all 0.2s ease;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .weekWrapper .accordianTop i{transition: all 0.2s ease;}
.courseDashboardWrapper .courseDashboardAccordian .accordianSubInner .weekWrapper:last-child .accordianTop{border-bottom: 0px none;}

.app-body.learnerAttendance-listing .app-div {
  width: auto;
  padding: 10px 20px 25px 20px;
  top: 65px;
  left: 150px;
  height: calc(100vh - 55px);
  
}

.LoadingMessage{display: flex; align-items: center; width: 100%; justify-content: center;}
.LoadingMessage img{max-width: 100px;}

.AttendanceListWrapper{width: 100%;}
.AttendanceListWrapper ol{overflow-y: auto; max-height:calc(100vh - 400px);}
.RecordingListWrapper{width: 100%;}
.RecordingListWrapper ol{overflow-y: auto; max-height:calc(100vh - 400px);}


.attendanceReportsWrapper .attendanceReportsContainer .tabsWrapper{display: flex; grid-gap:20px; gap:20px; width: 100%; justify-content: center; align-items: center;}
.attendanceReportsWrapper .attendanceReportsContainer .tabsWrapper span{ cursor: pointer; font-size: 15px;    font-family: Lato;    border-radius: 5px;    color: var(--text-black2);   padding: 10px 16px 10px 16px; background-color: var(--primary-rgba);}
.attendanceReportsWrapper .attendanceReportsContainer .tabsWrapper span.selected {background-color: var(--primary); color: var(--white);}


.attendanceReportsWrapper .calendarView.hide {
  display:none;}

.attendanceReportsWrapper .calendarView {
  display: flex;
  width: 100%;
}


.attendanceReportsWrapper{display: flex; width: 100%; font-family: lato;}
.attendanceReportsWrapper h2{text-align: left; border-bottom: 1px solid #ddd;   font-family: Lato;  font-size: 1.4rem;  padding-bottom: 10px; margin-bottom: 20px; width: 100%;}
.attendanceReportsWrapper .attendanceReportsContainer{display: flex; width: 100%; position: relative; flex-direction: column;}
.attendanceReportsWrapper .header-op{display: flex; width: 100%;}
.attendanceReportsWrapper .header-op .calenderBlock{display: flex;}
.attendanceReportsWrapper .header-op .calenderBlock .currentDate{display: flex;}
.attendanceReportsWrapper .header-op .calenderBlock .currentDate > div{display: flex; align-items: center;}
.attendanceReportsWrapper .filtersWrapper {display:flex; flex-wrap: wrap; width: 100%; flex-direction: column;}
.attendanceReportsWrapper .filtersWrapper .labelWrapper{display: flex; align-items: center; flex-wrap: wrap; margin:10px 0 0px;  grid-gap: 20px;  gap: 20px; border-top:1px solid #ddd; padding-top: 20px; width: 100%;}
.attendanceReportsWrapper .filtersWrapper .labelBlock{display: flex; grid-gap:5px; gap:5px; width: 100%; max-width: 200px; flex-direction: column; align-items: flex-start; margin: 0 0px 0px 0;}
.attendanceReportsWrapper .filtersWrapper .labelBlock label{margin: 0; padding: 0;}
.attendanceReportsWrapper .attendanceListingTableWrapper{display: flex; flex-direction: column; width: 100%; margin-top: 30px;}
.attendanceReportsWrapper .attendanceListingTableWrapper .pagination-list{position: static;}
.attendanceReportsWrapper .attendanceListingTableWrapper h2{ border-bottom:0px solid #ddd; margin:0; padding:0px; font-size: 16px;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol{margin: 0; padding: 0; grid-gap:15px; gap:15px; display: flex; flex-direction: column;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li{margin: 0; background-color: #fff; border:1px solid #ddd;  padding:15px; border-radius: 6px; display: flex; grid-gap:20px; gap:20px; justify-content: space-between; align-items:center;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .info{display: flex; flex-direction: column; grid-gap:5px; gap:5px; flex:1 1; }
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .info .className {display: flex; align-items: center; grid-gap:5px; gap:5px; font-size: 14px;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .info .batchName {display: flex; align-items: center; grid-gap:5px; gap:5px; font-size: 14px;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .info .className span{font-size: 14px; color:#999}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .info .batchName span{font-size: 14px; color:#999}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .info .startTime{display: flex; align-items: center; grid-gap:5px; gap:5px; font-size: 14px;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .info .startTime span{font-size: 14px; color:#999}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .status{display: flex; flex-shrink: 0;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .viewRecording{display: flex; align-items: flex-end; grid-gap:5px; gap:5px; flex-direction: column;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .viewRecording .viewedTime{color:#000; font-size: 14px;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .viewRecording .viewedTime span{color:#999; font-size: 14px;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .viewRecording a{background-color: var(--primary); padding: 10px 20px; color: #fff; border-radius: 50px;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .status .status-absent{color:#ff0000;font-weight: bold;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .status .status-absent span{border-radius: 5px; background-color:#fbdada; padding: 5px 10px;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .status .status-present span{border-radius: 5px; background-color:#e5fff6; padding: 5px 10px;}
.attendanceReportsWrapper .attendanceListingTableWrapper ol li .status .status-present{color:green; font-weight: bold;}
.attendanceReportsWrapper .attendanceListingTableWrapper table{width:100%; border:1px solid #ddd; border-collapse: collapse;}
.attendanceReportsWrapper .attendanceListingTableWrapper table th{padding:10px 10px; text-transform: capitalize; border:1px solid #ddd; width:auto; min-width: 150px; text-align: left;}
.attendanceReportsWrapper .attendanceListingTableWrapper table td{padding:10px 10px; border:1px solid #ddd; width:auto; min-width: 150px; text-align: left;}
.attendanceReportsWrapper .attendanceListingTableWrapper table th:first-child{min-width: 70px;}
.attendanceReportsWrapper .attendanceListingTableWrapper table td:first-child{min-width: 70px;}
.attendanceReportsWrapper .attendanceListingTableWrapper table tr:nth-child(odd) td{background-color: #eeeded;}
.attendanceReportsWrapper .noReportsFound{border:1px solid #ddd; padding: 10px; margin-top: 30px; text-align: center;background-color:var(--secondary-rgba);}
.attendanceReportsWrapper .attendanceReportsContainer .reportsLoader{position: absolute; opacity: 0; visibility:hidden; left:0;  right:0; bottom:0; top:0; z-index: 220000; background-color: rgba(0,0,0,0.1); display: flex; align-items: center; justify-content: center;}
.attendanceReportsWrapper .attendanceReportsContainer .reportsLoader.show{opacity: 1; visibility: visible;}

.attendanceReportsWrapper .attendanceReportsContainer .reportsLoader span {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid var(--primary); /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.single-doubt, .single-doubt2 {
  position: relative;
  width: 100%;
  height: 244px;
  margin-bottom: 30px;
  border-radius: 20px;
  background-color: var(--white);
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px 0px #f5f5f5;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px 35px;
}
.page-doubt-subjects{margin-top: 20px;}
.page-header-right button{white-space: nowrap;}
.page-header-right select{max-width: 200px;}

.single-doubt, .single-doubt2{height: auto;}

.single-doubt:hover {
  box-shadow: 4px 4px 8px 0 #eeeeee;
}

.single-doubt2:hover {
  box-shadow: 2px 2px 4px 0px #f5f5f5;
}

.mycoach-header.widthChange .intro-block .user-block h3{margin-bottom: 0;}
.mycoach-header.widthChange{left:150px; top:65px}
.mycoach-header.widthChange .intro-block{margin-bottom: 0; align-items: center;}

.mycoach-header.widthChange .intro-block .askanything{flex-direction: row; grid-gap:10px; gap:10px;}
.mycoach-header.widthChange .intro-block .askanything > div:first-child{margin-bottom: 0;}

.single-doubt-left {
  min-width: calc(100% - 300px);
  position: relative;
  cursor: pointer;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Lato;
  color: var(--text-black);
  text-decoration: none;
  justify-content: space-between;
}

.doubt-by {
  font-family: Lato;
}

.doubt-by-div {
  display: flex;
  align-items: center;
}
.image-overlay{z-index: 2200000!important;}
.doubt-by-profile {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doubt-by-profile img {
  width: 36px;
}

.doubt-by-div div {
  display: flex;
  flex-direction: column;
}

.doubt-by-name {
  max-width: 250px;
  overflow: hidden;
  font-weight: 400;
  font-size: 14px;
}



.facultyCreateDisscusstionDialog.open{display: flex;}
.facultyCreateDisscusstionDialog{display: flex; display: none; flex-direction: column; font-family: Lato; z-index: 2200005; width: 100%; align-items: center; justify-content: center; position: fixed; top:0; right:0; bottom:0; left:0; background-color: rgba(0,0,0,0.6);}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer{display: flex;  width: 100%; flex-direction: column; background-color: #fff; max-width: 700px; padding: 15px; border-radius: 15px;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer h6{display: flex; margin:0px 0 5px; grid-gap:5px; gap:5px; font-size: 15px;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer h6 span{color: #6d6d6d;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer h5{margin: 0 0 20px; padding:0; border-bottom:1px solid #dbdbdb; font-size: 15px; align-items: center; justify-content: space-between; display: flex;  padding-bottom:5px; margin-bottom: 20px;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer h3{border-bottom:1px solid #dbdbdb; align-items: center; justify-content: space-between; display: flex;  padding-bottom:5px; margin-bottom:15px;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer h3 span{cursor: pointer;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .formWrapper{display: flex; grid-gap:40px; gap:40px; margin:20px 0 0; padding: 0; width: 100%;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .formWrapper .imagePreviewBlock{display: flex; flex-direction: column; flex:1 1}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .formWrapper .imagePreviewBlock .previewBlock{display: flex; text-align: center; margin-top:20px;  justify-content: center; flex:1 1; width: 100%;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .formWrapper .imagePreviewBlock .previewBlock img{object-fit: contain; max-height:100px;     height: 100%;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .formWrapper .imagePreviewBlock img{max-width: 200px;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .formWrapper .imagePreviewBlock .mainBlock{display: flex; flex-direction: column; grid-gap:30px; gap:30px; align-items: flex-start;  grid-gap:20px;  gap:20px; flex-direction: column; width: 100%;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .formWrapper .imagePreviewBlock .mainBlock .uploadBlock{display: flex; cursor: pointer; justify-content: center; margin-top:20px; padding:10px; grid-gap:5px; gap:5px; border:2px dashed var(--primary); align-items:center; color: var(--primary); width: 100%;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .formWrapper .DescriptionBlock{display: flex; flex-direction: column; flex:1 1}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .filterBlock{display: flex;  grid-gap:20px;  gap:20px; width: 100%; flex-wrap: wrap;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .filterBlock .block{display: flex; flex-direction: column;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .filterBlock .block label{margin: 0 0 5px; padding:0;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .formWrapper label{margin: 0 0 0px; display: flex; grid-gap:5px; gap:5px; width: 100%; padding: 0; font-size: 14px; font-family: Lato;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .actions{display: flex;  border-top:1px solid #dbdbdb; padding-top: 10px; margin-top:20px; width: 100%; justify-content:center; align-items: center;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer textarea{border:1px solid #dbdbdb; min-height: 200px; max-height: 200px;  margin:0; margin-top: 10px;  border-radius: 5px; resize: none; padding:5px 10px; font-size: 14px; font-family: Lato;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer select{border:1px solid #dbdbdb; min-width: 200px; max-width: 200px; margin:0; border-radius: 5px; resize: none; padding:5px 10px; font-size: 14px; font-family: Lato;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .uploadErrorMessage { font-size: 14px; font-family: Lato; color:#ff0000}

.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .doubtSubjectsBlock{ margin-top: 15px; display: flex; flex-wrap: wrap; width: 100%; grid-gap:15px; gap:15px;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .doubtSubjectsBlock  div{ cursor: pointer; font-weight: bold; border-radius: 50px;    font-size: 15px;   font-family: Lato; color: var(--text-black2); background-color: var(--primary-rgba); padding: 5px 20px;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .actions .LoadingMessage{width: auto;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .actions .LoadingMessage img{max-width: 40px;}

.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .doubtSubjectsBlock div#selected-subject{background-color: var(--primary); color:#fff;}
.facultyCreateDisscusstionDialog .facultyCreateDisscusstionContainer .required{color:#ff0000}


.doubt-by-time {
  margin-top: 6px;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-black3);
}

.single-doubt-info {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
}

.single-doubt-subject {
  display: inline-block;
  width: -webkit-max-content;
  width: max-content;
  padding: 4px 8px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: var(--white);
  border-radius: 3px;
  background-color: var(--secondary);
}

.responses-count {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-black2);
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.responses-count img {
  width: 20px;
  margin-right: 10px;
}

.single-doubt-description {
  width: 100%;
  margin-top:0px;
  font-size: 16px;
  line-height: 1.5;
  display: block;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  height: 72px;      /* fallback */
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  
  margin-bottom:15px;
  margin-top:15px
}

.single-doubt-description::first-line{font-weight: bold; color:var(--primary)}

#view_more {
  z-index: 10 !important;
  width: -webkit-max-content;
  width: max-content;
  bottom: 65px;
  position: absolute;
  margin-top: 6px;
  color: var(--primary);
  font-family: Lato;
  font-weight: bold;
  cursor: pointer;
}

#view_more2 {
  z-index: 10 !important;
  width: -webkit-max-content;
  width: max-content;
  bottom: 0px;
  position: absolute;
  margin-top: 6px;
  color: var(--primary);
  font-family: Lato;
  font-weight: bold;
  cursor: pointer;
}

.single-doubt-right {
  border-radius: 12px;
  min-width: 280px;
  height: 100%;
  margin-left: 20px;
  cursor: zoom-in;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #dddddd;
}
.single-doubt-left{min-width: inherit; width: 100%;}
.single-doubt-content-block .desc-content{flex:1 1; margin-right:0px; overflow-y: auto; min-height: 100px; max-height: 300px;}
.single-doubt-content-block{display: flex; width: 100%; justify-content: space-between; align-items: flex-start;}
.witharrowlink{display: flex; align-items: center;    margin-top: 15px;  color:var(--primary); align-self: flex-end; justify-content: flex-end;}
.witharrowlink img{margin-left:5px; -webkit-transform: rotate(180deg); transform: rotate(180deg);}

.single-doubt-right{margin-left:0; margin-right:30px; min-height:180px; max-height:180px;}

.image-upload.designChanges { align-items: center; background-color: #f9fafb; border: 2px dashed #d9d9d9; padding: 20px; border-radius:6px;}
.image-upload.designChanges  span{ margin-left: 0; margin:5px 0; color:var(--primary);  text-align: center; width: 100%;} 
.image-upload.designChanges img {width: 75px;}
.image-upload.designChanges .noteText{text-align: center;}
.image-upload.designChanges + textarea{background-color: #f9fafb;  border: 1px solid #d9d9d9;}
.image-upload.designChanges .post-doubt-img { margin-top:0; width: 100%; height: 75px;     background-size: contain;}
.image-upload.designChanges .post-doubt-img span{width: auto;    padding: 5px; color:#fff; margin:0; right:0!important;}

.response-header {
  z-index: 100 !important;
  position: sticky;
  top: -10px;
  width: 100%;
  height: 64px;
  padding-left: 30px;
  padding-right: 30px;
  padding: 0;;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
}
.fixed-pagination.noStyle{box-shadow: 0px 0px 0px 0px; position: static;}

.response-header h2 {
  font-family: Lato;
  font-size: 18px;
  color: var(--text-black);
  padding-left: 0;
  margin-left: 0;
}

.responses-data {
  margin-top: 25px;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  padding:0;
}

.single-response {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #e1e4e8;
  overflow: hidden;
  margin-bottom: 30px;
}

.single-response-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  height: 50px;
  color: #586069;
  background-color: #f6f8fa;
  border-bottom: 1px solid #d1d5da;
}

.single-response-header1 {
  font-family: Lato;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.single-response-dp {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-response-dp img {
  width: 26px;
}

.single-response-header1 span {
  color: var(--text-black);
  font-weight: 600;
  font-size: 16px;
  margin-right: 16px;
}

.is-author {
  padding: 6px 12px;
  background-color: var(--primary-rgba);
  color: var(--primary);
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
}

.single-response-content {
  position: relative;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  font-size: 14px;
}

.single-response-content-left {
  width: 100%;
  height: 150px;
}

.single-response-content span {
  width: 100%;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  font-size: 16px;
  line-height: 1.5;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  height: 96px;      /* fallback */
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
  color: var(--text-black2);
  cursor: pointer;
}

.single-response-content-right {
  border-radius: 12px;
  min-width: 280px;
  height: 180px;
  margin-left: 20px;
  cursor: zoom-in;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #dddddd;
}

.page-data.facultyNewDesign .page-header{border-bottom:1px solid #ddd; padding-bottom: 10px;}
.page-data.facultyNewDesign .page-header h1{margin-bottom: 0;}

.page-doubt-subjects {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 30px;
}
.newDesign.doubt-main .page-doubt-subjects{justify-content: center;}

.newDesign.doubt-main{left:150px; top:121px; height: calc(100vh - 121px);}
.newDesign.doubt-main .page-header{border-bottom:1px solid #ddd; padding-bottom: 10px;}
.newDesign.doubt-main .page-header h1{margin-bottom: 0;}
.newDesign.doubt-main{display: flex; flex-direction: column; background-color: #fff;}
.newDesign.doubt-main .page-data{grid-gap:40px;gap:40px; flex-direction: column; display: flex;}
.newDesign.doubt-main .single-doubt.directionChange{flex-direction: column;}
.newDesign.doubt-main .single-doubt .userInfo{display: flex; width: 100%;  margin-bottom: 15px;}
.newDesign.doubt-main .single-doubt.directionChange{max-width: 650px; margin: 0 auto; grid-gap:0px; gap:0px;}
.newDesign.doubt-main .single-doubt-right{max-width: none; margin:0; max-width: unset;     min-height: unset; width: 100%; max-height: unset; background-size: contain;width: 100%;   display: flex; align-items: center; justify-content: center;}

.newDesign.doubt-main .single-doubt.directionChange .actions{display: flex; align-items: center; justify-content: flex-end; width: 100%; margin-top: 0; margin-bottom: 10px;}
.newDesign.doubt-main .single-doubt.directionChange .actions a{color:var(--primary); grid-gap:5px; gap:5px; display: flex; align-items: center; font-size: 14px;   font-family: Lato;}
.newDesign.doubt-main .single-doubt2.directionChange{flex-direction: column;}
.newDesign.doubt-main .single-doubt2 .userInfo{display: flex; width: 100%; margin-bottom: 15px;}
.newDesign.doubt-main .single-doubt2.directionChange{max-width: 650px; margin: 0 auto; grid-gap:0px; gap:0px;}
.newDesign.doubt-main .single-doubt2-right{max-width: none; margin:0; max-width: unset; min-height: unset; width: 100%; max-height: unset; background-size: contain;  display: flex; align-items: center; justify-content: center;}

.newDesign.doubt-main .response-header {max-width: 650px; margin: 0 auto; padding:0; position: static;}
.newDesign.doubt-main .responses-data {max-width: 650px; margin: 0 auto; padding:0;}

.newDesign.doubt-main .single-response-content-left{height: auto;}
.newDesign.doubt-main .single-response-content span{height: auto;}

.noteText{color:#999;  font-family: lato; font-size: 14px;}
.noteErrorMessage{color:#ff0000;  font-family: lato; font-size: 14px;}

.newDesign.doubt-main .single-doubt-right img{max-width: 100%; max-height: 200px; object-fit: contain;} 
.CourseTabContent .filterBlock{display: flex; margin: 15px 0; grid-gap:20px; gap:20px; width: 100%;}
.CourseTabContent .filterBlock .block{display: flex; flex-direction: column;}
.CourseTabContent .filterBlock .block label{margin:0 0 8px; padding:0; font-size: 14px;   font-family: Lato;}
.CourseTabContent .filterBlock select{max-width: 150px;}
.CourseTabContent.open{display: block;}
.OpenTabContent.open{display: block;}
.CourseTabContent{display: none;}
.OpenTabContent{display: none;}

.pageDoubtTabs{display: flex; align-items: center; grid-gap:18px; gap:18px;}
.marginBottom20{margin-bottom: 20px;}

.BottomLink{display: flex; justify-content:flex-end; align-items: center; width: 100%; margin-top: 20px;}
.BottomLink a{width: auto;}
.page-data.facultyNewDesign{max-width: 650px; margin: 0 auto;}
.page-data.facultyNewDesign .single-doubt{flex-direction: column;}
.page-data.facultyNewDesign .single-doubt:hover{box-shadow: 0px 0px 10px var(--primary-rgba);}
.page-data.facultyNewDesign .single-doubt-right{margin-right: 0; max-width: none; max-height: unset; width: 100%; display: flex; align-items: center; justify-content: center;}
.page-data.facultyNewDesign .single-doubt-right img{max-width: 100%; max-height: 200px; object-fit: contain;}
.page-data.facultyNewDesign .single-doubt-left{grid-gap:15px;gap:15px;}
.page-data.facultyNewDesign .single-doubt-description{height: auto;}
.page-data.facultyNewDesign .single-doubt-description{height: auto; max-height: 350px; overflow-y: auto;}
.page-data.facultyNewDesign .single-doubt-info{position: static; margin-top: 10px;}

.page-data.facultyNewDesign .single-response-content-left{height: 70px;}

.noContentMessage{width:100%; text-align: center; max-width: 650px; margin:0 auto; width: 100%; font-family: lato; border:1px solid #ddd; padding: 10px;}

.noContentMessage.marginTop60{margin-top: 60px;}


.page-doubt-subjects{max-width: 650px; margin: 20px auto;}

.single-response.directionChange  .single-response-content-right{margin-right: 0;   margin-left: 0;   height: auto; max-width: none; max-height: unset; width: 100%; display: flex; align-items: center; justify-content: center;}
.single-response.directionChange  .single-response-content-right img{max-width: 100%; max-height: 200px; object-fit: contain;}

.single-response.directionChange .single-response-content{display: flex; grid-gap:20px; gap:20px; flex-direction: column; width: 100%;}



.page-data.facultyNewDesign{max-width: 650px; margin: 0 auto;}
.page-data.facultyNewDesign .single-doubt2{flex-direction: column;}
.page-data.facultyNewDesign .single-doubt2:hover{box-shadow: 0px 0px 10px var(--primary-rgba);}
.page-data.facultyNewDesign .single-doubt2-right{margin-right: 0; max-width: none; max-height: unset;}
.page-data.facultyNewDesign .single-doubt2-right img{max-width: 100%;}
.page-data.facultyNewDesign .single-doubt2-left{grid-gap:15px;gap:15px;}
.page-data.facultyNewDesign .single-doubt2-description{height: auto;}
.page-data.facultyNewDesign .single-doubt-info{position: static;}


.page-data.facultyNewDesign .deletePostLink{display: flex; color: var(--text-black3); grid-gap:5px; gap:5px; font-family: Lato; cursor: pointer; width:100%; margin-bottom: 10px; justify-content: flex-end; align-items: center;}

.pageDoubtTabs div {
  padding: 10px 16px 10px 16px;
  background-color: var(--primary-rgba);
  color: var(--text-black2);
  margin-right:0x;
  border-radius: 5px;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}
.pageDoubtTabs div:hover {
  opacity: 0.85;
}
.pageDoubtTabs .selected{background-color: var(--primary); color: var(--white);}


.page-doubt-subjects{margin-top:30}

.page-doubt-subjects div {
  padding: 10px 16px 10px 16px;
  background-color: var(--primary-rgba);
  color: var(--text-black2);
  margin-right: 18px;
  border-radius: 5px;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.page-doubt-subjects div:last-child {
  margin-right: 0;
}

.page-doubt-subjects div:hover {
  opacity: 0.85;
}
.topHeading.withBorder{border-bottom: 1px solid #ddd; padding-bottom: 10px;}
.topHeading{display: flex; align-items: center; margin-bottom: 10px;}
.topHeading a{display: flex;     box-shadow: 0px 1px 6px #0000001A; background-color: #fff; justify-content: center; margin-right: 15px; align-items: center; width:40px; height: 40px; border-radius:5px;}
.topHeading h3{font-size: 22px; color:#000; margin:0 0 0px}
.topHeading h1{margin-bottom: 0;}
  
.stationaryshop-block .design-block li .thumb img{width:auto}
.liveprojects-block .design-block li .thumb img{width:auto;}


.page-doubt-subjects .selected{background-color: var(--primary); color: var(--white);}

#selected-dsubject {
  background-color: var(--primary);
  color: var(--white);
}

.more_doubts {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.more_doubts button {
  font-size: 18px;
}


.webview .app-div {
  left: 0 !important;
}

.webview-header{
  left: 0 !important;
  top: 0 !important;
}

.webview-header.mycoach-header{margin-top:0; display: none;}
.webview-header.mycoach-header{margin-top:0; display: none;}
.webview-header.mycoach-header .intro-block{flex-direction: row; align-items: center;}
.webview-header.mycoach-header .intro-block .askanything{order:2; position: static;}
.webview-header.mycoach-header .intro-block .askanything .ask-doubt{margin-bottom: 0;;}
.webview-header.mycoach-header .intro-block .user-block h3{margin-bottom: 0;;}

@media only screen and (min-width:1024px) {
.webview .app-div.mycoach-div {height: calc(100vh - 0px); top:0}
}

@media only screen and (max-width:1024px) {
  .webview .app-div.mycoach-div {height: calc(100vh - 0px);}
  .webview.app-body.ugpgprogram-page .app-div{top:0; height: calc(100vh - 0px);}
  .webview.app-body.ugpgprogram-page .page-header{display: none;}


 }


@media only screen and (max-width: 770px) { 
  .webview .app-div.mycoach-div {height: calc(100vh - 0px);}
  .webview .challenges-block .design-block ol{justify-content: center; max-width: none;}
  .webview .challenges-block .design-block ol li{justify-content: center; margin: 0 10px 20px}
  .webview .certification-academy-block .design-block ol{justify-content: center; max-width: none;}
  .webview .certification-academy-block .design-block li{justify-content: center; margin: 0 10px 20px}
  .webview .liveprojects-block .design-block ol{justify-content: center; max-width: none;}
  .webview .liveprojects-block .design-block li{justify-content: center; margin: 0 10px 20px}
  .webview .certification-academy-block h3{font-size: 19px;}
  .webview .stationaryshop-block .design-block li{justify-content: center; margin: 0 10px 20px}
  .webview.app-body.ugpgprogram-page .app-div{top:0; height: calc(100vh - 0px);}
  .webview.app-body.ugpgprogram-page .page-header{display: none;}
  .webview .UGPGProgramWrapper .categoryWrapper{margin-top: 0;;}
  .webview .UGPGProgramWrapper .categoryWrapper ol{overflow-x: auto; justify-content: flex-start;  max-width: none; flex-wrap: nowrap;}
  .webview .UGPGProgramWrapper .categoryWrapper ol li{max-width: 40%; margin:0 15px 0px 0; white-space: nowrap;}
  .webview .UGPGProgramWrapper .categoryWrapper ol li a{width: 100%;}
  .webview .UGPGProgramWrapper .categoryWrapper ol li a .icon{max-width:100%; display: none;}
  .webview .UGPGProgramWrapper .categoryWrapper ol li img{max-width: 100%;}
  .webview .designCategoryWrapper .designCategoryContainer .leftsection ol{overflow-x: auto; justify-content: flex-start;  max-width:  none; flex-wrap: nowrap;}
  .webview .designCategoryWrapper .designCategoryContainer .leftsection ol li a{white-space: nowrap;}
}

@media only screen and (max-width: 770px) and (orientation: landscape){
.webview .challenges-block .design-block ol li{max-width: 234px; margin: 0 10px 20px}
.webview .app-div.mycoach-div {height: calc(100vh - 0px); top:0}
.webview .certification-academy-block .design-block li{max-width: 235px; margin: 0 10px 20px}
.webview .liveprojects-block .design-block li{max-width: 235px; margin: 0 10px 20px}
.webview .stationaryshop-block .design-block ol{justify-content: center; max-width: none;}
.webview .stationaryshop-block .design-block li{max-width: 235px; margin: 0 10px 20px}
.webview .UGPGProgramWrapper .categoryWrapper ol{max-width: none;}
}


@media only screen and (max-width: 812px) and (orientation: landscape){
  .webview .challenges-block .design-block ol li{max-width: 230px; margin: 0 10px 20px}
  .webview .app-div.mycoach-div {height: calc(100vh - 0px);}
  .webview .liveprojects-block .design-block li{max-width: 230px; margin: 0 10px 20px}
  .webview .certification-academy-block .design-block li{max-width: 230px; margin: 0 10px 20px}
  .webview .stationaryshop-block .design-block li{max-width: 230px; margin: 0 10px 20px}
  .webview.app-body.ugpgprogram-page .app-div{top:0; height: calc(100vh - 0px);}
  .webview.app-body.ugpgprogram-page .page-header{display: none;}
  
  }

@media only screen and (max-width: 600px) {  
  .page-doubt-subjects div{padding:10px;margin-right:10px; font-size:14px; margin-bottom:10px}
  .page-doubt-subjects div:last-child{margin-right:0}
  .single-doubt{flex-direction: column; height:auto}
  .single-doubt .single-doubt-right{height:200px; margin-left:0; margin-top:15px; min-width: inherit; width: 100%;}
  .doubt-by{flex-direction: column;}
  .doubt-by .single-doubt-info{position: static; margin-top:10px}
  .assignment-review-wrapper .assignment-review-container .imagepreview{margin:15px 0 15px}
  .assignment-review-wrapper .assignment-review-container .imagepreview img{max-width: 100%;}
  .single-doubt2{display: flex; flex-direction:column; padding:15px}
  .single-doubt2 .single-doubt-left{width: 100%;}
  .single-doubt2 .single-doubt-left .single-doubt-description{height:auto}
  .single-doubt2 .single-doubt-right{margin-left:0; margin-top:15px;}
}

@media only screen and (max-width:380px) {  
  .webview .UGPGProgramWrapper .categoryWrapper ol{max-width: 330px;}
  }

@media only screen and (max-width:350px) {  
.webview .UGPGProgramWrapper .categoryWrapper ol{max-width: 280px;}
}
.admin-div {
    overflow: hidden;
    width: calc(100vw - 290px);
    height: calc(100vh - 30px);
    position: fixed;
    top: 15px;
    right: 15px;
    border-radius: 30px;
    background-color: var(--white);
    padding: 30px 40px;
}
.popup-content{position: relative;}
.popup-content.adminCertificatesList{padding: 0!important; position: static; margin: 0;}
.popup-content.adminCertificatesList .close{position: absolute; background: transparent; color: #000; font-size: 34px; padding: 0;  margin-top: 0; top:0px; right:10px}

.add-new-button {
    cursor: pointer;
    color: var(--primary);
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
}
.heading-part2 button:last-child {
    margin-left: 0;
}
.zIndexchange .admin-header{max-width: 100%;}

.popup-content {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    max-width: 900px;
    margin: auto;
}

.popup-content .close {
    position: absolute;

    right: 10px;
    border: none;
    background: var(--primary);
    font-size: 24px;
    cursor: pointer;
}


table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}


button {
    background-color: var(--primary);
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
.container {
    text-align: start;
    margin-top: 60px;
    color: white;
    padding: 0 0px;
    margin: 0 0 20px;
}

.app-body {
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 140px;
    right: 300px;
    top: 0;
    background-color: var(--white);
}

.app-div.widthAuto {
    left: 150px
}

.marginTopZero {
    margin-top: 0
}


h2 {
    font-weight: 600;
    line-height: 1.4;
    font-family: Source Sans Pro;
    font-size: 19px;
    color: black;
    margin-left: 5px;

}

.flex-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 40px;
    gap: 40px;


}


.certificate {
    background: #f0f0f0;
    margin: 0px;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative;
    /* Required for aspect ratio hack */
  
    width: 100%;
    max-width: 400px;
}

.certificate img{max-width: 100%;}

.iframe-container {
    width: 100%;
    height: 200px;
    border: none;
    object-fit: contain;
}

.iframe-container img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.iframe-container {
    height: 650px;
    width: 100%;
}



.bg-color {
    background-color: #F7F7F9;
    padding: 24px;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
    margin: 20px 0px;
}

.flexCard {
    display: flex;
    justify-content: space-between;
    grid-gap: 32px;
    gap: 32px;
}

.flexCard .card {
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 7px 16px;
    width: 100%;
}

.cardTitle {
    color: #192130;
    font-size: 14px;
    font-weight: 500px;
}

.graphTitle {
    color: #192130;
    font-size: 15.57px;
    font-weight: 700;
}


.graphCount {
    color: #192130;
    font-size: 28px;
    font-weight: 700;
}

.graphCountName {
    color: #667085;
    font-size: 14px;
    font-weight: 400;
}

.flexCard .count {
    color: #000;
}

.flexCard .card .circle {
    width: 32px;
    height: 32px;
    background-color: #7062E3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userLogins {
    width: 100%;
}

.userLogins {
    width: 100%;
}

.userLogins .graph {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.userLogins .graph .right {
    flex-grow: 1;
}


.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexCard .buttons h4 {
    padding: 4px 10px;
    font-size: 12.11px;
    font-weight: 600;
    display: inline;
    cursor: pointer;
}

.flexCard .buttons h4:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

}

.flexCard .buttons h4:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

}

.flexCard .buttons .selected {
    background-color: #7062E3;
    color: #FFFFFF;


}

.flexCard .buttons .unselected {
    background-color: #EDEFF4;
    color: #344051;

}
.action-btn{
    display: flex;
    justify-content: space-between;
    flex-grow: 1;


}

.action-btn button{
    /* height: 50px; */
    margin-top : 7px !important

}
.select-range{
    display: flex;
    flex-grow: 1;
    align-items: center;
}
/* App.css or inline CSS */

.organization-toggle {
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px; 

}

.organization-toggle p {
  margin: 0;
  padding-right: 10px;
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  justify-items: center;
  
  
}
span.slider{
  padding: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--primary);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: white;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
          transform: translateX(26px);
}

/* src/components/AttendanceReport.css */
.attendance-report {
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* src/components/AttendanceReport.css */
.dropdown-container {
  width: 200px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
 
  margin-bottom: 20px;
  
}
.th-fixed-width {
  width: 150px; 
}

.dropdown-container select {
  width: 200px;
  padding: 5px;
  font-size: 14px;
  height: 30px;
}


.dropdown-container input {
  margin: 0;
  height: 30px;
  border: 1px solid;
}
th,
td {
  padding: 8px 12px;
  text-align: center;
  border: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}
.export-button-container{
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}



.attendance-report-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

}
th {
  width: 300px;
}
.admin-div {
    padding: 20px;
  }
  
  .filter-section {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .search-input {
    padding: 10px;
    width: 200px;
  }
  
  /* .date-filters label {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
   */
  .assessment-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .assessment-table th, .assessment-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .loading img {
    display: block;
    margin: 20px auto;
  }
  
  .error-message, .no-data-message {
    text-align: center;
    margin-top: 200px;
    color: black;
    font-weight: 500;
    font-size: 20px;
}

  
.organization-overview {
    font-family: Arial, sans-serif;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    min-height: 100vh;
  }
  
  .breadcrumb {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .student-details-container {
    margin-bottom: 20px;
  }
  
  .student-details-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .student-email-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 300px;
    text-align: center;
    background-color: #f9f9f9;
    color: #333;
    font-size: 14px;
  }
  
  .action-buttons {
    margin-top: 20px;
  }
  
  .action-buttons .btn {
    padding: 10px 15px;
    margin: 5px;
    background-color:var(--primary);
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .action-buttons .btn:hover {
    background-color: #5a3fcc;
  }
  
  .action-buttons .btn.disabled {
    background-color: #ddd;
    color: #aaa;
    cursor: not-allowed;
  }
  
  .user-not-found {
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  
.study-material-main {
  width: 100%;
  margin-top: 10px;
  flex-direction: column;
  display: flex;
}

.app-div.widget-app-div.app-studymaterial {
  left: 150px;
  top: 70px;
  height: calc(100vh - 75px);
  width: auto;
  padding-left: 25px;
    padding-right: 15px;
    /* overflow-x: hidden; */
}

.app-div.app-studymaterial.widget-app-div.is_faculty{width:76.1vw; left:auto; right:0;}

.study-material-main .subjectBanner{display: flex; width: 100%; max-height:300px; border-radius: 20px; margin-bottom:20px;}
.study-material-main .subjectBanner img{max-width: 100%; border-radius: 20px;}

.study-material-main .heading {
  margin-bottom: 25px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
}
.viewingOldSemesterData{text-align: center; width: 100%; font-size: 20px; font-family: Lato;}
.study-material-main .heading.alignLeft{justify-content:flex-start;}

.study-material-main h2 {
  margin-top: 0;
  font-size: 20px;
}

.sm-units {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.sm-unit-div {
  position: relative;
  width: 100%;
  height: 250px;
  background-color: var(--primary-rgba);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bgimage{background-repeat: no-repeat; background-size: cover;}
.bgimage .sm-unit-div1 span{top:10px}

.sm-units.newDesign .sm-unit-div {height: auto; flex-direction: column; background-color: transparent; align-items:flex-start;}
.sm-units.newDesign .sm-unit-div  .sm-unit-div1{margin-bottom: 15px;  width:100%;  justify-content: flex-start;}
.sm-units.newDesign .sm-unit-div  .sm-unit-div1 span{position:static; border-radius:5px; padding:10px; color: var(--white); max-width: 550px; width: 100%;  background: linear-gradient(124deg, var(--primary) 0%, rgba(0,0,0,0) 100%);}
.wid100{width: 100%;}
.sm-units.newDesign .sm-unit-div .sm-unit-div1 span img{max-width: 20px; margin-right:10px; height:auto; margin-top: 0;}
.sm-units.newDesign .sm-unit-div .sm-unit-div2{width: 100%; align-items:flex-start; justify-content: flex-start;}
.sm-units.newDesign .sm-unit-div .sm-unit-div2 .sm-unit-content{min-width:280px; justify-content: flex-start;}
.sm-units.newDesign .sm-unit-div .sm-unit-div2 .sm-unit-content div{margin-top: 25px;}
.sm-units.newDesign .sm-unit-div .sm-unit-div2 .sm-unit-content .sm-unit-content-thumbnail{background-size: contain; height: 125px!important; background-color: var(--primary-rgba); transition: all 0.2s ease;}
.sm-units.newDesign .sm-unit-div .sm-unit-div2 .sm-unit-content .sm-unit-content-thumbnail img{width:inherit}
.sm-units.newDesign .sm-unit-div .sm-unit-div2 .sm-unit-content:hover .sm-unit-content-thumbnail{-webkit-transform: scale(1.1);transform: scale(1.1);}
.sm-chapters li .sm-item{transition: all 0.2s ease;;}
.sm-chapters li .sm-item:hover{box-shadow: 6px 6px 12px 0px rgb(0 0 0 / 30%); box-shadow: 0 0 0 0 ;}
.sm-content-action.hello{margin-bottom: 5px;;}
.sm-unit-div1 span {
  position: absolute;
  top: 30px;
  left: 50px;
  font-family: Lato;
  font-weight: 600;
  font-size: 20px;
  color: var(--text-black);
}

.sm-unit-div1 {
  width: calc(500px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sm-unit-div1 img {
  margin-top: 25px;
  height: 180px;
}

.sm-unit-div2 {
  width: 320px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sm-unit-content {
  position: relative;
  overflow: hidden;
  display: block;
  width: 200px;
  height: 200px;
  background-color: var(--primary-rgba);
  /* background-color: #fff6e4; */
  cursor: pointer;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: 6px 6px 12px 0px rgba(0,0,0,0.10);
  transition: .3s all;
}

.sm-unit-content:hover {
  box-shadow: 6px 6px 12px 0px rgba(0,0,0,0.3);
}

.sm-unit-content-thumbnail {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 145px !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sm-unit-content img {
  width: 165px;
}

#sm-assess {
  width: 110px;
}

#sm-assessm {
  position: relative !important;
  width: 57px;
  margin-bottom: 10px;
}

.sm-unit-content div {
  margin-top: 15px;
  margin-bottom: 20px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-black);
  letter-spacing: 0.01em;
  display: -webkit-flex;
  justify-content: center;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  line-height: 1.4;     /* fallback */
  height: 20px;      /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  width: 100%;
}

.sm-layers {
  margin-top: 30px;
}

.wid100{width:100%;}

.sm-layers h3 {
  margin-left: 20px;
}

.sm-chapters {
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -15px !important;
  margin-left:0px !important;
  overflow: auto;
  grid-gap:15px;
  gap:15px;
}

.fileDownloadble{display: flex; justify-content: flex-end; width: 100%; max-width: 756px;}

.sm-chapters li {
  display: block;
  min-width: 280px;
  max-width: 280px;
  /* height: 200px;
  height: 240px; */
  margin-left: 15px;
  margin-right: 15px;
}


.sm-item, .sm-more {
  display: block;
  width: 100%;
  height: 200px;
  border-radius: 15px;
  background-color: var(--secondary-rgba);
  box-shadow: 1px 1px 6px 0px #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
}

.sm-item{height: 100%;}
.sm-item.correctionsclass{height: 100%; justify-content: flex-start;}
.sm-item.correctionsclass .sm-thumbnail{height: 160px;}

.sm-more {
  background-color: var(--white);
  font-family: Lato;
  font-weight: 600;
  font-size: 20px;
  color: var(--primary) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--secondary-rgba);
  bordeR:1px solid var(--primary)
}

.sm-more:hover {
  background-color: #f4f4f4;
  background-color:var(--secondary-rgba);
  color: var(--primary);
  bordeR:1px solid var(--primary)
}
.pdf-noweb .full-view{display: none;}
.pdffileonly .pdf-noweb .full-view{display: flex;}

.sm-thumbnail {
  width: 100%;
  height: 125px;
  overflow: hidden;
}

#sm-icon {
  position: absolute;
  top: 18px;
  left: 25px;
  width: 28px;
}

.sm-content-title {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  padding-top: 20px;
}

.sm-content-title.correctionsClass{height: auto; padding:0}
.sm-content-title.correctionsClass span{width: auto; padding:15px;}

.viewer-toolbar-right .viewer-toolbar-item:nth-child(3){display:none}

.content-title.correctionClass{max-width: 756px; width: 100%; margin-bottom:25px;}

.content-title.correctionClass + .content-section{bottom:-100px}

.content-title.correctionClass.justify{justify-content: space-between; display: flex; align-items: center;}
.content-title.correctionClass.justify .fileDownloadble{width: auto;}
.content-title.correctionClass.justify .fileDownloadble a{display:flex; grid-gap:5px; gap:5px; font-family: Lato; font-size: 14px; color:var(--primary)}

.sm-content-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
}

.sm-content-actions button {
  font-size: 15px;
}

.sm-content-actions div {
  margin-right: 10px;
}

.sm-content-actions div:last-child {
  margin-right: 0;
}

.sm-content-title span {
  text-align: center;
  width: 80%;
  color: var(--text-black);
}

.sm-thumbnail-img {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--primary-rgba);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s all;
}

.sm-thumbnail-img img {
  width: 120px;
}

.sm-item:hover .sm-thumbnail-img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.content-toc {
  position: absolute;
  width: 100%;
  height: calc(100vh - 150px);
  left: 0;
  top: 35px;
}

.content-controller {
  position: absolute;
  width: 400px;
  bottom: 30px;
  right: 0;
  height: 66px;
  /*background-color: var(--primary-rgba);*/
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 40px;
  padding-right: 40px;
}

.content-navs {
  display: flex;
  align-items: center;
  margin-right: 65px;
}

.content-navs button:first-child {
  margin-right: 20px;
}

.content-controller div:last-child {
  text-decoration: none;
  cursor: pointer;
}

.content-navs button {
  width: 40px;
  height: 40px;
  padding: 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.content-navs button:first-child {
  padding-right: 2px;
}

.content-navs button:last-child {
  padding-left: 2px;
}

.content-navs img {
  width: 20px !important;
}

.content-controller div:last-child img {
  width: 45px;
}

.content-header {
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: width .3s;
}

/* .study-material-main .heading {
  justify-content: space-between !important;
} */

.content-header .heading {
  width: 60px;
  height: 38px;
}

.content-tray {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content_dash {
  height: 6px;
  border-radius: 6px;
  background-color: var(--light-black3);
  cursor: pointer;
}

#content_dashed {
  background-color: var(--primary) !important;
}

.content-section {
  position: absolute;
  bottom: -85px;
  left: 0;
  width: 756px;
  height: calc(100% - 30px);
  /*display: flex;*/
  justify-content: center;
  transition: width .3s;
  overflow: auto;
}

.video-container-bws {
  /*width: 700px;
  max-width: 700px;*/
  height: 100%;
}

.content-section-loader {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 500px);
  height: calc(100% - 115px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-title {
  margin-top: 25px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  font-family: Lato;
}

.select-content {
  width: 100%;
  margin-top: 50px;
  font-family: Lato;
  font-weight: 600;
  font-size: 21px;
  text-align: center;
  color: var(--text-black2);
}

.assesment-preview2{
  height:440px !important;
}

.sm-unit-content-noexist{
  position: relative;
  overflow: hidden;
  display: block;
  width: 200px;
  height: 200px;
  /* background-color: var(--white); */
  background-color: #fff6e4;
  /* cursor: pointer; */
  border-radius: 16px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: 6px 6px 12px 0px rgba(0,0,0,0.10);
  transition: .3s all;
}

.sm-unit-content-noexist:hover {
  box-shadow: 6px 6px 12px 0px rgba(0,0,0,0.3);
}

.sm-unit-content-noexist img {
  width: 165px;
}

.popup-doubt-subjects{display: flex; align-items: center; grid-gap:10px; gap:10px;}
.popup-doubt-subjects div{margin-right: 0;}

.sm-unit-content-noexist div {
  margin-top: 15px;
  margin-bottom: 20px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-black);
  letter-spacing: 0.01em;
  display: -webkit-flex;
  justify-content: center;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  line-height: 1.4;     /* fallback */
  height: 20px;      /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  width: 100%;
}

.content-section-fullView .e-pv-viewer-container {
  width: 1248px !important;
  /*height: 430px!important;*/
}

.content-section-fullView .e-pv-page-container{
  width: 1239px !important;
}

.content-section-fullView .e-pv-page-div{
  left:288px !important;
}
.sm-units.newDesign.accordianActivate {margin-bottom: 20px;}
.sm-units.newDesign .sm-unit-div.accordianActivate .sm-unit-div1{ border:1px solid var(--primary); cursor: pointer; border-radius:5px; padding:10px}
.sm-units.newDesign .sm-unit-div.accordianActivate .accrodianArrow {position: absolute; right:10px; transition: all 0.2s ease;}
.sm-units.newDesign .sm-unit-div.accordianActivate .accrodianArrow.accrodianUpArrow{-webkit-transform: rotate(-180deg);transform: rotate(-180deg);}
/* .sm-units.newDesign .sm-unit-div.accordianActivate + .sm-layers{display: none;} */
/* .sm-units.newDesign .sm-unit-div.accordianActivate .sm-unit-div2{display: none;} */


.sessonDisplayDialog.open{display: flex;}
/* .sessonDisplayDialog{position: fixed; background-color: rgba(0,0,0,0.1); display: flex;  font-family: lato; display: none; align-items: center; justify-content: center; top:0; left:0; right:0; bottom:0; z-index: 12;} */
.sessonDisplayDialog{position: fixed; background-color: rgba(0,0,0,0.1); display: flex;  font-family: lato; display: none; align-items: center; justify-content: center; top:0; left:0; right:0; bottom:0; z-index: 999999;}
.sessonDisplayDialog .sessonDisplayContainer{border-radius:10px; position: relative; background-color: #fff; padding:15px; width: 100%; max-width: 600px; box-shadow: 4px 4px 12px -6px var(--text-black2);}
.sessonDisplayDialog .sessonDisplayContainer h3{border-bottom:1px solid #ddd; display:flex; justify-content: space-between; margin-bottom: 20px; padding-bottom: 5px;}
.sessonDisplayDialog .sessonDisplayContainer h3 span{cursor: pointer;}
.sessonDisplayDialog .dialogClose{position: absolute; cursor: pointer; right:-5px; top:-10px; width: 24px; background-color: #fff; color: #000; height: 24px; display: flex; align-items: center; justify-content: center; border-radius: 100%;}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList{display: flex; flex-direction: column; width: 100%; max-height: 400px; overflow-y: auto; }
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList ol {margin:0; padding:0; flex-direction: column; grid-gap:20px; gap:20px; display: flex; width: 100%;}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList .sessonInfo{position: relative; grid-gap:10px; gap:10px; flex-direction: column; display: flex; align-items: flex-start;}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList .sessonInfo h3{border-bottom: 0px none; margin:0; padding: 0;}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList .sessonInfo p{font-size: 14px; border-bottom: 0px none; color:#666; margin:0; padding: 0;}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList ol li {margin:0; position: relative; flex-direction: column; background-color: #f7f7f7; padding:15px; border-radius: 10px; display: flex; width: 100%;}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList ol li .actions{display: flex; margin-top: 15px; padding-top: 15px; border-top: 1px solid #ddd; justify-content:space-between;}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList ol li .actions #live_class_location{display: flex; position: static;}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList ol li .session-band{display: flex; width: 100%; justify-content: flex-start; align-items: center;}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList ol li .session-band span{position: static; right:0px; top:0px; padding:5px 10px; background-color:#cfcfcf; border-radius: 5px; color:var(--text-black2);}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList .noContentFound{display: flex; justify-content: center; align-items: center; font-size: 14px; color:#999}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList ol li .actions  #action-reverse{display: flex; align-items: center;}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList ol li .actions  #view-recording{display: flex; align-items: center;}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList ol li .actions  #view-recording a{display: flex; align-items: center;}
.sessonDisplayDialog .sessonDisplayContainer .SessonContentList ol li .actions  #view-recording button{width:auto!important}

.studymaterialpage .ViewAllSessionsLink{display: flex; cursor: pointer; grid-gap:5px; gap:5px; align-items:flex-end; justify-content: flex-end; font-family: lato; color:var(--primary); margin-bottom: 20px;}
.studymaterialpage .ViewAllSessionsLink span{text-decoration: underline;}
body.studyMaterialPage .app-body{z-index: 1;}




@media only screen and (max-width: 1000px) {  
  .content-header{width: auto;}
  .sm-unit-div1{width: calc(300px);}
  .sm-unit-div1 img{ height: 112px;}
  .content-body{padding:20px; max-width:900px;}
  .content-section{padding:20px 20px 20px 0; position: static; margin-top:15px; width: 100%; height: calc(100% - 60px); bottom:0}
  .content-filter-section{z-index: 220000; width: 100%;}
  .sm-units{margin-bottom:15px}
  .sm-units:last-child{margin-bottom:0px}
  
}

@media only screen and (max-width: 600px) {  
  .sm-unit-div{display: flex; width: 100%; flex-direction:column; height:auto; padding:15px}
  .sm-unit-div .sm-unit-div1{width: 100%;  display: flex; flex-direction:column;}
  .sm-unit-div1 img{width: auto; height:115px}
  .sm-unit-div .sm-unit-div1 span{position: static;}
  .content-body{padding:20px; max-width:500px;}
 .summary-values{width: 100%; display: flex; flex-direction:column}
 .summary-values #total_question_data{width: 100%;}
 #total_question_data:nth-child(2n) {margin-left:0;}
 .sm-unit-content{width: 150px;}
 .content-toc{left:20px; right:20px; width:auto; height: calc(100vh - 180px);}
 .content-controller{padding:15px; border:1px solid #ddd; width:auto; bottom:0; left:0; right:0; bottom:70px;}
 .assesment-preview{padding-bottom:0}
 .assesment-preview .action-buttonsx2{position:static; margin-top:20px;}
  .content-navs{align-items: flex-start; width: 100%; padding-left:0; justify-items: flex-start; margin-right:0}
  .assignment-review-listing ol li .listing-top{flex-direction: column;}
  .assignment-review-listing ol li .listing-top .listing-top-left{flex-direction: column;  margin-bottom:15px}
  .assignment-review-listing ol li .listing-top .listing-top-left .thumb-content{margin-left:0; margin-top:10px}
  .assignment-review-listing ol li .listing-top .listing-top-left .class-content{margin-left:0; margin-top:10px}
  .assignment-review-listing ol li .listing-top .listing-top-left .section-content{margin-left:0; margin-top:10px}
  .assignment-review-listing ol li .listing-top .listing-top-left .subject-content{margin-left:0; margin-top:10px}

  .listing-bottom .list-view{display:flex; flex-direction: column;}
  .listing-bottom .list-view .view-content{max-width: none; margin-top:10px}
  .listing-bottom .list-view .review-content{margin-right:0; margin-top:10px}
  .listing-bottom .list-view .marks-block{margin-right:0; margin-top:10px}

  .assignment-review-last-bottom{flex-direction:column}
  .assignment-review-last-bottom .assignment-last-bottom-right{flex-direction:column}
  .assignment-review-last-bottom .assignment-last-bottom-right .grading-block{margin:10px 0 10px}
  .assignment-review-last-bottom .assignment-last-bottom-right .assignment-head4{margin:10px 0 10px}
  .assignment-review-last-bottom-button1{position: static; width: auto; height:auto;}
  .assignment-review-wrapper .assignment-review-container .imagepreview img{width: auto; height:auto}
  .app-div.widget-app-div.app-studymaterial{left:0}
  .sm-units.newDesign .sm-unit-div{padding-left: 0;}

 
}





.dash-test-first{
  float:left;
  margin-right:100px;
  color:var(--primary);
  width:100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Lato;
  font-weight: 700;
  font-size: 20px;
  padding-top: 30px;
}

.headingTitle{border-bottom: 1px solid #ddd;}
.headingTitle.paddingBottom10{padding-bottom: 10px;}

.app-div.page-header-faculty{left:270px}

.dash-test-first span:last-child {
  color: var(--text-black2);
  margin-top: 8px;
  font-size: 18px;
}

.dash-test2x {
  margin-top: 6px !important;
}

.dash-test div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dash-test span {
  width: 100%;
  color: var(--text-black2);
}

.dash-test span.studentsMap{display: flex; margin-left: 15px; font-size: 14px; font-family: Lato;  width:auto; margin-left: 6px;}
.dash-test span.studentsMap label{margin: 0; padding: 0; font-size: 14px; font-family: Lato; }

.dash-test {
  position: relative;
  width:530px;
  height: 190px;
  margin-bottom: 30px;
  border-radius: 10px;
  transition: .2s all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  text-decoration: none;
  background-color: var(--primary-rgba);
  background-color:rgba(27,206,80,0.2);
  padding: 20px 24px;
}

.dash-flex2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dash-flex2-img {
  display: flex;
  justify-content: flex-end;
}

.lec-mode-dash {
  position: absolute;
  right: 0;
  top: 25px;
  padding: 8px 14px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: var(--primary);
  text-transform: uppercase;
  letter-spacing: 0.01em;
  display: inline-block;
  width: -webkit-max-content !important;
  width: max-content !important;
}

.dash-flex2-title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  line-height: 1.35;
  font-size: 16px;
  margin-bottom: 14px;
  max-height: 44px;
}

.page-header-test{
  align-items: flex-end;
  background-color: transparent;
}
.page-header-faculty{
  align-items: flex-end;
  background-color: transparent;
}

.page-header-faculty-practice.marginZero{margin-left: 0;}

.page-header-faculty-practice{
  margin-left: 400px;
  margin-left: 0;
}

.list-sticky-practice.widthAuto{width: auto; left:0; right:0; margin-left: 0;}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
  color: var(--text-black) !important;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: var(--secondary);
}
.react-calendar__tile.react-calendar__month-view__days__day{
  color:var(--primary);
}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend{
  color:crimson;
}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth{
  color:#aaa;
}
.react-calendar__navigation button {
  color:var(--primary);
  background: none;
}
.react-calendar {
  width: 869px;
  background: white;
  border: 0px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  margin-top: 10px;
  z-index: 1;
  position: fixed;
  background-color: var(--secondary-rgba);
  border-radius: 16px;
  padding: 30px 20px;
  box-shadow: 4px 4px 12px -4px #aaaaaa;
}
.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day{
  background-color: var(--secondary);
}

.react-calendar-date {
  padding-top: 5px;
  padding-bottom: 5px;
  color:var(--text-black);
  font-family: Lato !important;
  font-weight: 700 !important;
  font-size: 19px !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.react-calendar__navigation {
  margin-bottom: 22px;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #aaa !important;
}

.react-calendar-date img {
  width: 28px;
  margin-left: 20px;
}

.react-calendar__navigation__arrow {
  font-size: 28px;
}

.react-calendar__tile {
  text-align: center;
  padding: 12px 16px !important;
  background: none;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 15px;
}

.react-calendar__month-view__weekdays__weekday{
  margin-left:43px;
  font-size:15px;
  text-decoration: none;
}

.react-calendar__decade-view__years__year, .react-calendar__year-view__months__month,
.react-calendar__century-view__decades__decade {
  color: var(--primary) !important;
}

.action-button{
  border-radius: 9px;
  margin-right: 15px;
  font-size:15px;
}
.react-calendar__tile.react-calendar__tile--active{
  background-color: var(--primary-rgba)
}

.list-sticky {
  width: 72vw;
  position: absolute;
  top: 90px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: calc(100vh - 260px);
  overflow: auto;
}
.back-to-recent{
    font-family: Lato;
    font-size: 14px;
    /* font-weight: 600; */
    color: var(--white);
    /* border: 1px solid var(--primary); */
    background-color:var(--primary);
    /* border: none; */
    padding-left: 23px;
    border-radius: 40px;
    cursor: pointer;
    /* margin-left: 16px; */
    width: 88px;
}
.back-to-recent-faculty{
    font-family: Lato;
    font-size: 14px;
    /* font-weight: 600; */
    color: var(--white);
    /* border: 1px solid var(--primary); */
    background-color: var(--primary);
    /* border: none; */
    padding-left: 23px;
    border-radius: 40px;
    cursor: pointer;
    /* margin-left: 16px; */
    width:88px;
}


.faculty-dash-actions {
  position: absolute;
  bottom: 20px;
  right: 24px;
  display: flex;
  align-items: center;
  width: -webkit-max-content !important;
  width: max-content !important;
  
}
.faculty-dash-actions div{
  margin-right: 15px;
}
.faculty-dash-actions div:last-child{
  margin-right: 0px;
}

.faculty-dash-actions button{
  width:130px !important;
}

.dash-test-bottom {
  display: flex;
  align-items: flex-end;
}
.list-content{
  height: 100vh
}

.no-current-records{
    width: 79%;
    /* height: 104px; */
    /* border: 1px solid crimson; */
    margin-bottom: 22px;
    position: relative;
    /* border-radius: 10px; */
    transition: .2s all;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    padding: 26px 28px;
}

.dash-test-no-found{
    width: 530px;
    margin-bottom: 30px;
    border-radius: 10px;
    transition: .2s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: Lato;
    font-size: 19px;
    font-weight: 600;
    color: #2e6d96;
    text-decoration: none;
    background-color: var(--primary-rgba);
    padding: 19px;
}
.list-sticky-practice{
    width: 68vw;
    width:auto;
    position: absolute;
    top: 90px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: calc(100vh - 260px);
    overflow: auto;
    margin-left: 48px;
}

.attempt-modal-content {
  position: relative !important;
  width: 800px !important;
  height: 580px !important;
  overflow-y: auto;
}

.subjectImage{
  width: 100px;
  border-radius: 100%;
  height: 100px;
}

.facultysm a{
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
  border: 1px solid var(--primary);
  background-color: var(--primary);
  border: none;
  padding: 6px 20px 8px 20px;
  border-radius: 40px;
  cursor: pointer;
}

.page-header.styleChange{ border-bottom: 1px solid #ddd; padding-bottom: 10px;}

.page-header.styleChange h1{margin-bottom: 0;}

.page-header.styleChange .page-header-left{display: flex; align-items: center;}


.userAssessmentsListing{display: flex; flex-direction: column; width: 100%;}
.userAssessmentsListing table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  font-family: Lato;
  min-width: 400px;

}
.userAssessmentsListing td.actions{text-align: left;}
.userAssessmentsListing td .actions{display: flex; margin-top: 0; justify-content: flex-start;}
.userAssessmentsListing td .actions span{align-items: center; display: flex; grid-gap:5px; gap:5px; color: var(--primary);}
.userAssessmentsListing td .attempted{background-color: var(--primary); padding: 5px 15px; border-radius: 5px; color:var(--white)}
.userAssessmentsListing th, .userAssessmentsListing td {
  padding: 12px 15px;
  text-align: left;
}

.userAssessmentsListing th {
  text-transform: uppercase;
}

.userAssessmentsListing tr {
  border-bottom: 1px solid #dddddd;
 
}
.userAssessmentsListing td:nth-child(2),.userAssessmentsListing td:last-child {
  cursor: pointer;
}

.userAssessmentsListing tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

@media only screen and (max-width: 1000px) {  
  .list-sticky-practice{width: auto; margin-left:0;  width: 78vw; padding-right: 20px; right:0px}
  .react-calendar{width: 100%; max-width: 770px;}
  .dash-test{width: 100%; height:auto}
  .dash-test-no-found{width: 100%;}
  .list-content{padding-right:0px;}
  .list-sticky{width: 78vw;  padding-right:20px;}
  .attempt-modal-content .assesment-preview2{padding-right:10px; height:450px!important;}
  .attempt-modal-content{width: 100%; max-width:650px!important; padding:15px!important}
  .page-header-faculty-practice{margin-left:0}
  .page-header-faculty-practice .page-header-right select{min-width:95px; max-width:95px;}
  .faculty-dash-actions{margin-top:15px;}
  .faculty-dash-actions div{margin:0; margin-bottom:10px;}
  .submit-by-date span{margin-left:0!important}

  
}

@media only screen and (max-width: 600px) {  
  .list-sticky-practice{width: auto; left:20px; right:0px; height: calc(100vh - 320px);}
  .list-sticky{width: auto; left:20px; right:0px}
  .attempt-modal-content{max-width: 450px!important; height:auto!important;}
  .action-buttonsx3{position: static; margin-top:15px}
  .action-buttonsx3 button{font-size:14px;}
  .react-calendar{width: 100%; max-width: 275px;  max-height:300px; overflow:auto;}
  .react-calendar .react-calendar__navigation button{padding:5px 10px;}
  .success-modal-content{width:95%!important}
  .success-modal-content .success-div span{max-width: none; width: 100%; font-size: 19px;}
  .attempt-modal-content .assesment-preview2{padding-right:10px; height:400px!important;}
  .dash-flex2-img{align-items:flex-start}
}

@media only screen and (max-width: 480px) {  
  .attempt-modal-content{max-width: 310px!important;}
  
}

@media only screen and (max-width: 400px) {  
  .attempt-modal-content{max-width:275px!important; }
  
}
.video-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .video-player {
    width: 100%;
    display: block;
  }
  
  .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    padding: 20px;
    transition: opacity 0.3s ease;
  }
  
  .controls-hidden .controls {
    opacity: 0;
  }
  
  .control-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    grid-gap: 4px;
    gap: 4px;
  }
  
  .control-button {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
    transition: -webkit-transform 0.1s ease;
    transition: transform 0.1s ease;
    transition: transform 0.1s ease, -webkit-transform 0.1s ease;
    margin: 0px;
    
  }
  
  .control-button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .seek-bar {
    flex-grow: 1;
    margin: 0 10px;
    -webkit-appearance: none;
    background: rgba(255, 255, 255, 0.2);
    outline: none;
    border-radius: 10px;
    height: 5px;
    transition: height 0.1s ease;
  }
  
  .seek-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .seek-bar::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .seek-bar:hover {
    height: 8px;
  }
  
  .volume-control {
    display: flex;
    align-items: center;
  }
  
  .volume-slider {
    width: 80px;
    -webkit-appearance: none;
    background: rgba(255, 255, 255, 0.2);
    outline: none;
    border-radius: 10px;
    height: 5px;
  }
  
  .volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .volume-slider::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .playback-rate {
    /* background: rgba(17, 17, 17, 0.1); */
    border: 1px solid rgba(19, 19, 19, 0.2);
    /* color: white; */
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 14px;
    width: 100px;
  }
  
  .time-display {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
  }
  



.dash-test-first{
  float:left;
  margin-right:100px;
  color:var(--primary);
  width:100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Lato;
  font-weight: 700;
  font-size: 20px;
  padding-top: 30px;
}

.notficationWRAPPER .app-div{left:147px;}
.notficationWRAPPER .page-header h1{font-size: 24px;}
.notficationWRAPPER .list-sticky{overflow: unset; width: calc(100% - 30px);}
.notificationFilter{display: flex; grid-gap:15px; gap:15px; padding-bottom: 10px; border-bottom:1px solid var(--light-black); align-items: center; width: 100%;  font-family: Lato;}
.notificationFilter label{display: flex; padding: 0;}
.notificationFilter div{display: flex; align-items: center; grid-gap:15px; gap:15px;}
.notificationFilter div span{ background-color: transparent; cursor: pointer; padding: 5px 10px; border-radius: 4px; color: var(--primary);}
.notificationFilter div span.selected{ background-color: var(--primary); padding: 5px 10px; border-radius: 4px; color: var(--white);}

.noNoitification{text-align: center; font-family: Lato; background-color:var(--light-black2); color:var(--text-black); padding: 20px 10px;}

.pagination-list.positionChange{position: static; width: 100%;}
.dash-test-first span:last-child {
  color: var(--text-black2);
  margin-top: 8px;
  font-size: 18px;
}

.dash-test2x {
  margin-top: 6px !important;
}

.dash-test div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dash-test span {
  width: 100%;
  color: var(--text-black2);
}

.dash-test {
  position: relative;
  width:530px;
  height: 190px;
  margin-bottom: 30px;
  border-radius: 10px;
  transition: .2s all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  text-decoration: none;
  background-color:var(--primary-rgba)!important;
  padding: 20px 24px;
}

.dash-flex2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dash-flex2-img {
  display: flex;
  justify-content: flex-end;
}

.lec-mode-dash {
  position: absolute;
  right: 0;
  top: 25px;
  padding: 8px 14px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: var(--primary);
  text-transform: uppercase;
  letter-spacing: 0.01em;
  display: inline-block;
  width: -webkit-max-content !important;
  width: max-content !important;
}

.dash-flex2-title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  line-height: 1.35;
  font-size: 16px;
  margin-bottom: 14px;
  max-height: 44px;
}

.page-header-test{
  align-items: flex-end;
  background-color: transparent;
}
.page-header-faculty{
  align-items: flex-end;
  background-color: transparent;
}

.page-header-faculty-practice{
  margin-left: 400px;
}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
  color: var(--text-black) !important;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: var(--secondary);
}
.react-calendar__tile.react-calendar__month-view__days__day{
  color:var(--primary);
}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend{
  color:crimson;
}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth{
  color:#aaa;
}
.react-calendar__navigation button {
  color:var(--primary);
  background: none;
}
.react-calendar {
  width: 869px;
  background: white;
  border: 0px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  margin-top: 10px;
  z-index: 1;
  position: fixed;
  background-color: #FFEFEE;
  border-radius: 16px;
  padding: 30px 20px;
  box-shadow: 4px 4px 12px -4px #aaaaaa;
}
.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day{
  background-color: var(--secondary);
  color:var(--white)
}

.react-calendar-date {
  padding-top: 5px;
  padding-bottom: 5px;
  color:var(--text-black);
  font-family: Lato !important;
  font-weight: 700 !important;
  font-size: 19px !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.react-calendar__navigation {
  margin-bottom: 22px;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #aaa !important;
}

.react-calendar-date img {
  width: 28px;
  margin-left: 20px;
}

.react-calendar__navigation__arrow {
  font-size: 28px;
}

.react-calendar__tile {
  text-align: center;
  padding: 12px 16px !important;
  background: none;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 15px;
}

.react-calendar__month-view__weekdays__weekday{
  margin-left:43px;
  font-size:15px;
  text-decoration: none;
}

.react-calendar__decade-view__years__year, .react-calendar__year-view__months__month,
.react-calendar__century-view__decades__decade {
  color: var(--primary) !important;
}

.action-button{
  border-radius: 9px;
  margin-right: 15px;
  font-size:15px;
}
.react-calendar__tile.react-calendar__tile--active{
  background-color: var(--primary-rgba)
}

.list-sticky {
  width: 72vw;
  position: absolute;
  top: 90px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: calc(100vh - 260px);
  overflow: auto;
}
.back-to-recent{
    font-family: Source Sans Pro; 
    font-size: 14px;
    /* font-weight: 600; */
    color: var(--white);
    /* border: 1px solid var(--primary); */
    background-color:var(--primary);
    /* border: none; */
    padding-left: 23px;
    border-radius: 40px;
    cursor: pointer;
    /* margin-left: 16px; */
    width: 88px;
}
.back-to-recent-faculty{
    font-family: Source Sans Pro;
    font-size: 14px;
    /* font-weight: 600; */
    color: var(--white);
    /* border: 1px solid var(--primary); */
    background-color: var(--primary);
    /* border: none; */
    padding-left: 23px;
    border-radius: 40px;
    cursor: pointer;
    /* margin-left: 16px; */
    width:88px;
}


.faculty-dash-actions {
  position: absolute;
  bottom: 20px;
  right: 24px;
  display: flex;
  align-items: center;
  width: -webkit-max-content !important;
  width: max-content !important;
  
}
.faculty-dash-actions div{
  margin-right: 15px;
}
.faculty-dash-actions div:last-child{
  margin-right: 0px;
}

.faculty-dash-actions button{
  width:130px !important;
}

.dash-test-bottom {
  display: flex;
  align-items: flex-end;
}
.list-content{
  height: 100vh
}

.no-current-records{
    width: 79%;
    /* height: 104px; */
    /* border: 1px solid crimson; */
    margin-bottom: 22px;
    position: relative;
    /* border-radius: 10px; */
    transition: .2s all;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    padding: 26px 28px;
}

.dash-test-no-found{
    width: 530px;
    margin-bottom: 30px;
    border-radius: 10px;
    transition: .2s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: Lato;
    font-size: 19px;
    font-weight: 600;
    color: #2e6d96;
    text-decoration: none;
    background-color:var(--primary-rgba);
    padding: 19px;
}
.list-sticky-practice{
    width: 68vw;
    position: absolute;
    top: 90px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: calc(100vh - 260px);
    overflow: auto;
    margin-left: 48px;
}

.attempt-modal-content {
  position: relative !important;
  width: 800px !important;
  height: 580px !important;
  overflow-y: auto;
}

.subjectImage{
  width: 100px;
  border-radius: 100%;
  height: 100px;
}

.facultysm a{
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
  border: 1px solid var(--primary);
  background-color: var(--primary);
  border: none;
  padding: 6px 20px 8px 20px;
  border-radius: 40px;
  cursor: pointer;
}

.notificationsWrapper{position: relative; width:100%; margin-bottom:0px; padding: 0 20px 20px 0; border-radius: 10px;  transition: .2s all;  display: flex;
  flex-direction: column;  font-family: Lato; font-weight: 600;   text-decoration: none;}
.notificationsWrapper .notificationsContainer{display: flex; flex-direction: column; width: 100%}
.notificationsWrapper .notificationsContainer .block{display: flex; border-radius: 10px; margin-top:10px; flex-direction:column; border: 1px solid var(--light-black3); width: 100%; box-shadow: 0 0 5px var(--light-black3); background-color:var(--white);  padding:15px;}
.notificationsWrapper .notificationsContainer .block .notificationTop{display: flex; width: 100%; grid-gap:10px; gap:10px;}
.notificationsWrapper .notificationsContainer .block .notificationThumb{display:flex; max-width: 50px; height:50px; width: 100%;}
.notificationsWrapper .notificationsContainer .block .notificationThumb img{ cursor: pointer; height: 100%; object-fit: contain; width: 100%;}
.notificationsWrapper .notificationsContainer .block .notificationContent{display: flex; flex:1 1}
.notificationsWrapper .notificationsContainer .block.unread{box-shadow: 0 0 5px var(--primary-rgba); background-color:var(--white);  border: 1px solid var(--primary); }
.notificationsWrapper .notificationsContainer .block:first-child{margin-top:0;}
.notificationsWrapper .notificationsContainer .block .title{color: var(--text-black); width: 100%; transition: all 0.2s ease; display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px; font-size: 16px; font-weight: normal;}
.notificationsWrapper .notificationsContainer .block .title span{width: 15px; cursor: pointer; transition: all 0.2s ease;}
.notificationsWrapper .notificationsContainer .block .title span img{max-width: 100%; transition: all 0.2s ease;}
.notificationsWrapper .notificationsContainer .block.open .title span img{-webkit-transform: rotate(180deg);transform: rotate(180deg);}
.notificationsWrapper .notificationsContainer .block.open .content-desc{display: flex;}
.notificationsWrapper .notificationsContainer .block .content-desc{color:#8e8989; transition: all 0.2s ease; display: none; padding:10px 0; font-size: 14px; font-weight: normal;}
.notificationsWrapper .notificationsContainer .block .content-desc a{color: #489198; text-decoration: underline;}
.notificationsWrapper .notificationsContainer .block .content-desc a:hover{color: #489198; text-decoration: none;}
.notificationsWrapper .notificationsContainer .block.unread .content-desc{color:var(--text-black)}

.ImagePreviewDialog.open{display: flex;}
.ImagePreviewDialog{position: fixed;  z-index: 220000000; background-color: rgba(0,0,0,0.7); top:0; right:0; bottom:0; left:0; display: flex; align-items: center; display: none; justify-content: center;}
.ImagePreviewDialogContainer{background-color:var(--white); width: 100%; max-width: 500px; padding: 10px; border-radius: 15px; box-shadow: 0 0 6px #ddd;}
.ImagePreviewDialogContainer span{font-size: 22px; display: flex; margin-bottom: 10px; cursor: pointer; justify-content: flex-end; align-items: flex-end;}
.ImagePreviewDialogContainer .imageWrapper{width: 100%; display: flex; justify-content: center;}
.ImagePreviewDialogContainer .imageWrapper img{max-height: 400px; height: 100%; max-width: 100%; object-fit: contain;}

@media only screen and (max-width: 1000px) {  
  .list-sticky-practice{width: auto; margin-left:0;  width: 78vw; padding-right: 20px; right:0px}
  .react-calendar{width: 100%; max-width: 770px;}
  .dash-test{width: 100%; height:auto}
  .dash-test-no-found{width: 100%;}
  .list-content{padding-right:0px;}
  .list-sticky{width: 78vw;  padding-right:20px;}
  .attempt-modal-content .assesment-preview2{padding-right:10px; height:450px!important;}
  .attempt-modal-content{width: 100%; max-width:650px!important; padding:15px!important}
  .page-header-faculty-practice{margin-left:0}
  .page-header-faculty-practice .page-header-right select{min-width:95px; max-width:95px;}
  .faculty-dash-actions{margin-top:15px;}
  .faculty-dash-actions div{margin:0; margin-bottom:10px;}
  .submit-by-date span{margin-left:0!important}

  
}

@media only screen and (max-width: 600px) {  
  .list-sticky-practice{width: auto; left:20px; right:0px; height: calc(100vh - 320px);}
  .list-sticky{width: auto; left:20px; right:0px}
  .attempt-modal-content{max-width: 450px!important; height:auto!important;}
  .action-buttonsx3{position: static; margin-top:15px}
  .action-buttonsx3 button{font-size:14px;}
  .react-calendar{width: 100%; max-width: 275px;  max-height:300px; overflow:auto;}
  .react-calendar .react-calendar__navigation button{padding:5px 10px;}
  .success-modal-content{width:95%!important}
  .success-modal-content .success-div span{max-width: none; width: 100%; font-size: 19px;}
  .attempt-modal-content .assesment-preview2{padding-right:10px; height:400px!important;}
  .dash-flex2-img{align-items:flex-start}
}

@media only screen and (max-width: 480px) {  
  .attempt-modal-content{max-width: 310px!important;}
  
}

@media only screen and (max-width: 400px) {  
  .attempt-modal-content{max-width:275px!important; }
  
}
#no-data-admin{
	/* text-align: center; */
	width: 100%;
	display: flex;
	/* align-items: center; */
	justify-content: center;
	font-family: Lato;
	font-weight: 700;
	font-size: 25px;
	/* padding-top: 150px; */
	color: var(--text-black2);
}

.list-sticky-free-course{
	margin-top: 50px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill,minmax(30%,1fr));
    padding-bottom: 40px;
}
.page-doubt-subjects div{margin-bottom: 10px !important;}
.list-sticky-free-course{position: absolute; margin-top: 90px !important}
.dashboard-main {
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.dashboard-right {
  /*position: fixed;*/
  z-index: 10;
  width: 30vw;
  right: 50px;
}
.subjectsWrapper{flex-direction: column; display: flex; width: 100%; max-width: 30%; margin:10px 10px; padding:0; border-radius:10px;}
.subjectsWrapper:hover{box-shadow: 4px 4px 12px -6px var(--text-black2);}
.subjectsWrapper:hover .dash-subject-fac{box-shadow: 0px 0px 0px 0px var(--text-black2);}
.subjectsWrapper .dash-subject-fac{width:100%; margin:0px; padding: 10px;}
.subjectsWrapper .dash-subject-fac a{display: flex; justify-content: center; align-items: center; flex-direction: column; font-size: 15px;}
.subjectsWrapper .dash-subject-fac a span{font-size: 15px;}
.subjectsWrapper .subjectBatchesLink {border-top:1px solid #fff; font-family: lato;  padding:10px 10px 10px; display: flex; justify-content:center}
.subjectsWrapper .subjectBatchesLink span{color:#fff; cursor: pointer; font-size: 15px; font-weight: 700;}

.batchesDialogWrapper.open{display: flex;}
.batchesDialogWrapper{position: fixed; background-color: rgba(0,0,0,0.1); display: flex; display: none; align-items: center; justify-content: center; top:0; left:0; right:0; bottom:0; z-index: 12;}
.batchesDialogWrapper .batchesDialogContainer{border-radius:10px; background-color: #fff; padding:15px; width: 100%; max-width: 500px; box-shadow: 4px 4px 12px -6px var(--text-black2);}
.batchesDialogWrapper .batchesDialogContainer h3{border-bottom:1px solid #ddd; display:flex; justify-content: space-between; margin-bottom: 0px; padding-bottom: 5px;}
.batchesDialogWrapper .batchesDialogContainer h3 span{cursor: pointer;}
.batchesDialogWrapper .batchesDialogContainer ol{display: flex; font-family: lato; overflow-y: auto; max-height: 300px;  flex-direction: column; width: 100%; margin:0; padding:0;}
.batchesDialogWrapper .batchesDialogContainer ol li{display: flex; border:0px solid #ddd; padding:10px 10px; border-radius:5px; margin-top: 10px; width: 100%;}
.batchesDialogWrapper .batchesDialogContainer ol li:nth-child(odd){background-color:var(--primary-rgba);}
.finalAssessmentBox{width:100%; display: flex; font-family: lato;}
.finalAssessmentBox a{font-family: lato; position: relative;  box-shadow: 0px 6px 8px #1d73f238; padding:15px; border-radius:12px; display: flex; width: 100%; max-width: 50%;; background: transparent linear-gradient(121deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}
.finalAssessmentBox .icon {
  width: 48px;
  height: 48px;
  margin-right:20px;
  display: flex;
  justify-content: center;
}
.finalAssessmentBox .button{display: flex; width: 100%;}
.finalAssessmentBox .button p{color:#333; font-weight: bold; border-radius:8px; padding: 5px 10px; background-color: #fff;}
.finalAssessmentBox .arrow {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: rgba(255,255,255,0.2);
}
.app-div.widget-app-dashboard {
  left: 150px;
  top: 70px;
  height: calc(100vh - 75px);
}

.dashboard-profile {
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:0px;
  font-family:lato;
}

.dashboard-profile hr{border:1px solid var(--primary)}
.dashboard-schedule.newDesign{padding:15px; box-sizing: border-box; width: auto;}
.dashboard-schedule.newDesign .dash-sch-head{padding-left: 0;}
.dashboard-schedule.newDesign .dash-sch-head div{display: flex; width: 100%; flex-direction: row;}
.dashboard-schedule.newDesign .dash-sch-head div span{width: auto; margin-right: 10px; margin-bottom: 0;}
.dash-lecture.newDesign:last-child{border-bottom:0px none}
.dash-sch-head.newDesign{border-bottom:1px solid #ddd; padding-left: 10px; padding-right: 10px; padding-bottom: 10px; margin-bottom:0px;}
.dash-lecture.newDesign{display: flex; width: 100%; padding:10px; border-bottom:1px solid #ddd; align-items: center; margin-bottom:0px;  }
.dash-lecture.newDesign .dash-lecture-date{padding: 0; border-right: 0px  none;}
.dash-lecture.newDesign .newDesign{display: flex; width: 100%; flex-direction: column; padding:0px 0 0px 0px; margin-left: 15px;}
.dash-lecture.newDesign .newDesign .dash-lecture-date-title{flex-direction: column; display: flex; padding: 0;}
.dash-lecture.newDesign .newDesign .dash-lecture-date-title .newDesign{display: flex; width: auto; margin-top: 5px; margin-bottom:0px; margin-left: 0; flex-direction: row;}
.dash-lecture.newDesign .newDesign .dash-lecture-date-title .newDesign  .dash-lec-title-hrs{border-left:1px solid #ddd; max-width:150px; max-height:inherit; overflow:inherit; -webkit-box-orient: inherit; padding-left: 10px; margin-left: 10px;}
.dash-lecture.newDesign .newDesign .dash-lecture-date-title .newDesign  .dash-lec-title-hrs:empty{display: none;}
.dash-lecture.newDesign .newDesign .dash-lecture-date-title .newDesign  .dash-lec-title-hrs:first-child{border-left:0px solid #ddd; margin-top: 0; padding-left:0px; margin-left:0px;}
.dash-lecture.newDesign .newDesign .dash-lec-title-hrs{color:#999; margin-top:0px;}
.dash-lecture.newDesign .newDesign .dash-lec-hrs{color:#999; margin-top:0px; margin-bottom: 0; border-left:1px solid #ddd; padding-left: 10px; margin-left: 10px;}
.dash-lecture.newDesign .newDesign .actions{ display: flex; width:100%; margin-bottom: 10px;}
.dash-lecture.newDesign .newDesign .actions .testType{background-color: #fff6e4; text-align: right; width: 100%; background: linear-gradient(124deg, rgba(0,0,0,0) 0%, rgba(255,246,228,1) 100%); text-transform: uppercase; padding: 3px 15px; font-weight: 600; font-size: 12px; color:#000; font-family: lato; border:0px solid #e3e3ec;}
.dash-lecture.newDesign .newDesign .actions a{background-color: #489198; padding: 4px; border-radius: 5px; text-align: center; font-family: lato; color:#fff}
.dash-lecture.newDesign .newDesign .dash-lecture-date-third{justify-content: flex-end; padding-left: 0; margin-top:10px;}
.dash-lecture.newDesign .newDesign .dash-lecture-date-third div{justify-content: flex-end; width: 100%; padding:0; margin-top: 0; background-color: transparent; border:0px none}
.dash-lecture.newDesign .newDesign .dash-lecture-date-third span{justify-content: flex-end; display: flex; width: 100%; padding:0; font-size: 12px; margin-top: 0; background-color: transparent; color: var(--secondary); border:0px none}
.dash-lecture.newDesign .newDesign .dash-lecture-date-third span a{background-color: var(--secondary); color:#fff; font-family: lato; padding: 6px 10px; border-radius: 5px; }
.dash-lecture.newDesign .newDesign .dash-lecture-date-third div a{width:auto; padding:6px 10px}
.dash-lecture.newDesign .newDesign .dash-lecture-date-third div a:last-child{margin-right: 0;}
.close{
  z-index: 1;
  cursor: pointer;
}
.dash-lecture.newDesign .newDesign.margin-top10{margin-top: 0px;}
.referral-end-content{
  height:200px;
}

.error{
  color: red;
  font-family: 'Lato';
  font-size: 16px;
}

.dash-prof-img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white) !important;
  border: 1px dashed var(--primary);
  box-shadow: 5px 5px 10px 0px var(--primary-rgba);
}

.dash-prof-img img {
  width: 70px;
}

.dash-prof-img-in {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dash-prof-name {
  /*margin-top: 5px;
  margin-bottom: 5px;*/
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  color: var(--text-black);
  white-space: normal;
  word-break: break-word;
  text-align: center;
}

.drop-profile-creds div {
    max-width:191px;
    overflow: hidden;
    font-family: Lato;
    font-weight: 600;
    font-size: 15px;
    background-color: var(--secondary);
    color: var(--white);
    display: inline-block;
    width: -webkit-max-content;
    width: max-content;
    margin-top: 10px;
    margin-top: 2px;
    padding: 8px 12px 9px 12px;
    border-radius: 8px;
}

.drop-profile-creds-faculty div{
  max-width: 140px;
    overflow: hidden;
    font-family: Lato;
    font-weight: 600;
    font-size: 15px;
    background-color:var(--primary);
    color: var(--white);
    /* display: inline-block; */
    /* width: max-content; */
    /* margin-top: 10px; */
    margin-bottom: 10px;
    padding: 8px 12px 9px 12px;
}

.dashboard-profile .drop-profile-creds {
  margin-left: 0;
  text-align: center;
  line-height: 1.4;
  cursor: pointer;
  margin-bottom:10px;
  font-family:lato;
}

.dashboard-profile .drop-profile-creds:hover {
  opacity: 0.85;
}

.dashboard-title{display: flex; width: 100%; padding: 0 15px 15px;  justify-content: space-between; align-items: center;}
.dashboard-title .drop-profile-creds{margin:0; padding:0; font-family: Lato;
  font-size: 18px; flex-direction: column; display: flex;
  font-weight: 600;
  color: var(--primary);} 
.dashboard-title .drop-profile-creds span{width:50px; margin-top: 5px; display: flex; height: 2px; background-color: #000;}
.dashboard-title .viewall-btn{padding: 0;background-color: var(--primary) !important;}

.dashboard-schedule {
  z-index: 10;
  bottom: 25px;
  /* right: 50px; */
  width: 100%;
  /* height: calc(100vh - 440px); */
  background-color:var(--primary-rgba);
  border-radius: 6px;
  padding: 24px 0px 100px 0px;
  /* overflow-y: auto; */
  margin-top: 40px;
  margin-left: 34px;
}

.dashboard-left .dashboard-schedule{display: none;}

.dash-sch-head {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
  padding-left:0px;
  padding-right: 30px;
}

.IADashboardWrapper .courses-widget .quick-links-widget ol li div:nth-child(2) span {
    border: 1px solid #ddd;
    text-decoration: none;
    display: flex;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    background-color: #1d73f2;
}

.dash-sch-head div {
  display: flex;
  font-family: Lato;
  color: var(--text-black2);
  font-size: 16px;
  font-weight: 600;
  flex-direction: column;
}

.dash-sch-head span {
  color: var(--primary);
  font-size: 18px;
  margin-bottom: 6px;
}

.dash-lecture {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

#activity_type {
  position: absolute;
  background-color: var(--primary);
  color: var(--white);
  font-family: Lato;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  /* right: 30px;
  bottom: 0px; */
  text-transform: uppercase;
  margin-left: 0px;
  margin-top: -19px;
}

.dash-lecture:last-child {
  margin-bottom: 0;
}

.dash-lecture-date {
  /*width: 118px;*/
  padding-left: 18px;
  padding-right: 11px;
  border-right: 1px solid var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: var(--secondary);
  padding-top: 14px;
  padding-bottom: 14px;
  white-space: nowrap;
}

.dash-lecture-date div {
  font-size: 16px;
  color: var(--text-black);
}

.dash-lecture-date span {
  font-size: 14px;
  color: var(--primary);
  margin-top: 5px;
  margin-bottom: 5px;
}

.dash-lecture-date-third{
  padding-left: 20px;
  /* padding-right: 66px; */
  /* border-right: 1px solid var(--white); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Lato;
  width: 100%;
}

.dash-lecture-date-third div {
  /* background-color: #c5e5ea; */
  color: black;
  font-family: Lato;
  /* font-size: 12px; */
  /* font-weight: 600; */
  padding: 3px 15px;
  /* text-transform: uppercase; */
  /* width: 100px; */
  text-align: center;
  white-space: nowrap;
}


.dash-lecture-date-third div:first-child {
  text-transform: uppercase;
  background-color: #c5e5ea;
  border: 1px solid #e3e3ec;
}

.dash-lecture-date-third div:last-child {
  font-family: Lato;
  /* padding: 3px 15px; */
  /* border: 2px solid #c5e5ea; */
  /* text-align: center; */
  white-space: nowrap;
  /* border-radius: 17px; */
  color: white !important;
  margin-top: 5px;
}

.dash-lecture-date-third div:last-child a{
  color: white !important;
  margin-right: 4px;
  padding: 4px;
  text-align: center;
  background-color:var(--primary);
  border-radius: 5px;
}

.dash-lecture-date-third span {
  color: #000000;
}

.dash-lecture-title {
  padding-left: 20px;
  padding-right: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  font-family: Lato;
  justify-content: center;
}

.dash-lecture-title a, .dash-lecture-title span {
  margin-top: 4px;
  font-family: Lato;
  color: var(--primary);
  font-weight: 700;
  font-size: 15px;
}

.dash-lecture-title a:hover {
  opacity: 0.85;
}

.dash-lec-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-black);
  display: flex;
  /* overflow: hidden; */
  /* -webkit-box-decoration-break: clone; */
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  line-height: 1.4;
  /* max-height: 23px; */
  /* -webkit-line-clamp: 1; */
  /* -webkit-box-orient: vertical; */
  /* max-width: 128px; */
  word-break: break-word;
  width: 100%;
  /* border: 1px solid; */
  white-space: normal;
}

.dash-lec-title-hrs{
  font-size: 14px;
  font-weight: 600;
  color:var(--black);
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  line-height: 1.4;     /* fallback */
  max-height: 23px;      /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-width: 180px;
}

.dash-lec-hrs {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-black3);
  margin-top: 8px;
  margin-bottom: 6px;
  display: -webkit-box;
  /*overflow: hidden;*/
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  line-height: 1.4;     /* fallback */
  /*max-height: 20px; */     /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  /*-webkit-box-orient: vertical;*/
}

.dash-lec-hrs-faculty{
  color:var(--black);
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 6px;
}

.dashboard-left {
  width: 40vw;
}

.dashboard-main h1 {
  font-size: 20px;  
} 

.dashboard-subjects {
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.dash-subject {
  width: 30%;
  height: 150px;
  margin-bottom: 30px;
  border-radius: 10px;
  transition: .2s all;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px;
}
.dash-subject-fac {
  width: 30%;
  height: 150px;
  margin-bottom: 30px;
  border-radius: 10px;
  transition: .2s all;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.dash-subject span {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: var(--white);
  text-decoration: none;
  text-align: center;
  line-height: 1.36;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  height: 39px;      /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.dash-subject-fac span {
  font-family: Lato;
  font-size: 11px;
  font-weight: 600;
  color: var(--white);
  text-decoration: none;
  text-align: center;
  line-height: 1.36;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  height: 39px;      /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.dash-fac-detail {
  font-size: 15px;
  line-height: 1.36;
}
.dash-fac-detail span{
  margin-top: 6px;
}

.dashboard-practices {
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.dash-practice {
    width: 47%;
    height: 150px;
    margin-bottom: 30px;
    border-radius: 10px;
    transition: .2s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    color: var(--white);
    text-decoration: none;
    background-color:var(--primary-rgba);
    padding: 16px 18px;
    background-repeat: no-repeat;
    background-position: 160px 7px;
    box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2);
}

.dash-practice:nth-child(2n) {
  margin-left: 6%;
}

#dash-practice1 {
  background-color: var(--secondary-rgba);
}

.dash-practice span {
  width: 100%;
  color: var(--text-black2);
}

.dash-practice div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dash-practice button {
  /*font-size: 16px !important;*/
  white-space: nowrap !important;
  /*padding: 5px 14px 6px 14px !important;*/
  padding: 5px 0px 0px 0px !important;
  /*background-color: #c5e5ea !important;*/
  color: var(--white);
}

.ra-button{
  white-space: nowrap !important;
  padding: 1px !important;
  background-color: var(--primary-rgba) !important;
  color: #489198 !important;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
  border: 1px solid var(--primary);
  background-color: var(--primary);
  border: none;
  padding: 6px 20px 8px 20px;
  border-radius: 40px;
  cursor: pointer;
}

.dash-practice a {
  margin-right: 10px;
}

.dash-practice a:last-child {
  margin-right: 0px;
}

.dash-subject div {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.dash-subject-fac div {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.dash-subject:hover {
  box-shadow: 4px 4px 12px -6px var(--text-black2);
}
.dash-subject-fac:hover {
  box-shadow: 4px 4px 12px -6px var(--text-black2);
}

.dash-subject:nth-child(3n), .dash-subject:nth-child(3n - 1) {
  margin-left: 5%;
}
.dash-subject-fac:nth-child(3n), .dash-subject-fac:nth-child(3n - 1) {
  margin-left: 5%;
}

.dash-subject img {
  width: 40px;
}
.dash-subject-fac img {
  width: 40px;
}
.dash-subject{position: relative;}
.dash-subject div.attendancePercentage{display: flex; font-family: Lato; border-radius: 10px 10px 0 0; position: absolute; top:0px; background-color: #f1f1f1; width: 100%; height: auto; margin-bottom: 0;}
.dash-subject div.attendancePercentage span{height: auto; transition: all 0.2s ease; z-index: 2; position: relative; height: 20px;  border-radius: 10px 10px 0 0;   width: 30%; position: absolute; left:0; background-color: #FCBC51;}
.dash-subject div.attendancePercentage i{height: auto; width:100%; position: relative; z-index: 3; text-align: center; color:var(--text-black2);font-style: normal;}


.dashboard-exercise {
  margin-top: 40px;
}

.dashboard-exercise a {
  display: flex;
  align-items: center;
}

.dashboard-exercise h1 {
  margin-bottom: 0;
}

.dashboard-exercise a img {
  width: 18px;
  margin-left: 18px;
}

.dashboard-category {
  margin-top: 40px;
}

.dashboard-category a {
  display: flex;
  /*align-items: center;*/
}

.dashboard-category .careerBoosterLink{align-items: flex-start;}

.dashboard-category h1 {
  margin-bottom: 0;
}

.dashboard-category a img {
  width: 18px;
  margin-left: 18px;
}

.dashboard-category .course-image-div  img {
  width: 177px;
  height: 128px;
  object-fit: contain;
  padding-top: 16px;
}

.dashboard-assignment-list{
    display: flex;
    margin-top: 25px;
  }
  .dashboard-assignment-list a{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 15px;
    box-shadow: 0px 6px 8px #1D73F238;
    padding: 15px;
    border-radius: 12px;
    position: relative;
    margin-right: 10px;
  }

.welcomeheading{display: flex; margin-bottom:25px; line-height: 28px; font-family: Lato; font-size: 18px;}
.welcomeheading span{font-weight: bold; color:var(--primary); margin-left:10px;}

  .dashboard-assignment-list a:nth-child(1){
    background: transparent linear-gradient(122deg, #FF8A57 0%, #FF5502 100%) 0% 0% no-repeat padding-box;
  }
  .dashboard-assignment-list a:nth-child(3){
    background: transparent linear-gradient(121deg, #7ecacd 0%, #129ac2 100%) 0% 0% no-repeat padding-box;
  }

  .dashboard-assignment-list a:nth-child(2){
    background: transparent linear-gradient(121deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;
  }

  .dashboard-list-downarrow {cursor: pointer; width:100%; color:#000; display: flex; justify-content: center; align-items: center; height:40px; margin-top:-50px; position: relative; background: rgb(255,255,255);
background: linear-gradient(97deg, rgba(255,255,255,0.31976540616246496) 5%, rgba(250,254,255,1) 48%, rgba(255,255,255,0.5550595238095238) 85%); }

.dashboard-schedule.newDesign.marginBottom{margin-bottom: 20px; margin-top: 0; flex-direction: column; font-family: Lato;}
.dashboard-schedule.newDesign.marginBottom + .dashboard-schedule.newDesign {margin-top: 10px;}
.dashboard-schedule.newDesign.marginBottom .dashboard-title{position: relative; cursor: pointer;}
.dashboard-schedule.newDesign.marginBottom .dashboard-title img{position: absolute; transition: all 0.2s ease; max-width: 20px; right:5px; top:5px}
.dashboard-schedule.newDesign.marginBottom .dashboard-title.open + ol{display: flex;}
.dashboard-schedule.newDesign.marginBottom .dashboard-title.open img{-webkit-transform: rotate(90deg);transform: rotate(90deg);}
.dashboard-schedule.newDesign.marginBottom ol{margin: 0; padding:0; display: flex; display: none; flex-direction: column; width: 100%;}
.dashboard-schedule.newDesign.marginBottom ol li {margin-top: 5px; display: flex; background-color: #fff; border: 1px solid #ddd; border-radius:5px; padding: 10px; width:100%; justify-content: space-between; align-items: flex-start;}
.dashboard-schedule.newDesign.marginBottom ol li:first-child{margin-top: 0;}
.dashboard-schedule.newDesign.marginBottom ol li h5{font-size: 18px; margin:0 0 5px}
.dashboard-schedule.newDesign.marginBottom ol li p{font-size: 14px; margin:0 0 0px}
.dashboard-schedule.newDesign.marginBottom ol li a{color: var(--primary); text-decoration: underline;}
.dashboard-schedule.newDesign.marginBottom ol li a:hover{color: var(--primary); text-decoration: none;}
.nocurriculumfound{display: flex; width: 100%; text-align: center; justify-content: center;}

.semesterTabsWrapper{margin-bottom: 10px;}
.semesterTabsWrapper a{display: flex;  box-shadow: 0px 1px 6px #0000001A; background-color: #fff; justify-content: center; margin-right:0px; align-items: center; width:40px; height: 40px; border-radius:5px;}
.semesterTabsWrapper{display: flex; width: 100%; font-family: Lato; grid-gap:10px; gap:10px;}
.semesterTabsWrapper ul{display: flex;  width: 100%; grid-gap:10px; gap:10px; margin-top: 0; padding:0; overflow-x: auto;}
.semesterTabsWrapper ul li{display: flex; border-radius: 5px; border:1px solid  var(--primary); padding:10px 10px;}
.semesterTabsWrapper ul li.active{background-color: var(--primary); color:var(--white)}
.semesterTabsWrapper ul li:hover{cursor: pointer; }

  .dashboard-assignment-list a .arrow {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: rgba(255,255,255,0.2);
  }

   .dashboard-assignment-list a .icon {
    width: 48px;
    height: 48px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }
  .dashboard-assignment-list a .icon img{width:48px;}


.all-courses {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 20px;
}

.goto-course {
  display: block;
  position: relative;
  width: 30%;
  height: 200px;
  border-radius: 15px;
  background-color: var(--primary-rgba);
  border: 1px solid #dddddd;
  overflow: hidden;
  cursor: pointer;
}

.goto-course-list{
  display: block;
  position: relative;
  height: 200px;
  border-radius: 3px;
  /*background-color: var(--primary-rgba);*/
  border: 1px solid #dddddd;
  overflow: hidden;
  cursor: pointer;
}

.goto-course:hover img {
  -webkit-transform: scale(1.32);
          transform: scale(1.32);
}

/*.goto-course-list:hover img {
  transform: scale(1.32);
}*/

.goto-course:nth-child(3n), .goto-course:nth-child(3n - 1) {
  margin-left: 5%;
}

.goto-course-list:nth-child(3n), .goto-course-list:nth-child(3n - 1) {
  margin-left: 5%;
}

.course-image {
  width: 90%;
  height: 140px;
  top: 0;
  overflow: hidden;
}

.course-image-div {
  width: 100%;
  height: 100%;
  /*background-color: var(--secondary-rgba);*/
  transition: .3s all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.course-image-div img {
  width: 60px;
  transition: .3s all;
}

.goto-course span{
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  color: var(--text-black);
  text-decoration: none;
}

.goto-course-list span {
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.59;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.course-div {
  width: 30%;
  display: block;
  position: relative;
  height: 200px;
  border-radius: 15px;
  border: 1px solid #eeeeee;
  overflow: hidden;
  cursor: pointer;
}

.course-div div:first-child {
  /* position: absolute; */
  /* top: 0; */
  height: 100px;
  width: 100%;
  background-color: var(--secondary-rgba);
  display: flex;
  align-items: center;
  justify-content: center;
}

.course-div div img {
  height: 60px;
  transition: .2s all;
}

.course-div:hover img {
  -webkit-transform: scale(1.16);
          transform: scale(1.16);
}

.course-div div:last-child {
  line-height: 1.5;
  color: var(--text-black);
  font-family: Lato;
  font-size: 15px;
  /* padding-left: 50px; */
  /* padding-right: 15px; */
  padding-top: 25px;
  /* padding-bottom: 20px; */
  justify-content: center;
  text-align: center;
}

.course-div:nth-child(3n), .course-div:nth-child(3n - 1) {
  margin-left: 5%;
}

.courses-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.courses-popup span {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  color: var(--text-black);
}

.courses-popup-tray {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -15px !important;
}

.courses-popup-tray li {
  min-width: 180px;
  max-width: 180px;
  height: 200px;
  margin-left: 17px;
  margin-right: 17px;
  display: block;
  position: relative;
}

.courses-popup-tray .course-div {
  width: 100% !important;
  margin-right: 0;
  margin-left: 0;
}

.dash-lec-title-list{
      /* padding-left: 20px; */
    padding-right: 30px;
    /* padding-bottom: 20px; */
    display: flex;
    flex-direction: column;
    /* font-family: Lato; */
    justify-content: center;
    /* width: 416px; */
    border: 1px solid;
}


  .dash-lecture-date-title{    
    width: 400px;
    /* padding-right: 25px; */
    /* border-right: 1px solid var(--white); */
    display: inline-block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    color: var(--secondary);
    padding-top: 14px;
    padding-bottom: 14px;
    /* white-space: nowrap; */
    /* margin-right: 75px; */
    margin-left: 8px;
  }
  .dash-lecture-date-title span a{color: var(--secondary); font-size: 14px;}

  .dash-lecture-date-title{width:100%; display:flex; flex-direction: row; align-items: flex-start;}
  .dash-lecture-date-title .left{width:100%; display:flex; flex-direction: column; align-items: flex-start;}

  .dashboard-main-learner{
    z-index: 10;
    width: 100%;
    /* display: flex; */
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
  }

  .dashboard-main-learner span{
    color:#415973 !important;
  }

  .category-list{
    width:200px;
    margin-right: 20px;
    margin-bottom: 16px;
    box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2)
  }

  .dashboard-practices-course{
    margin-top: 18px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }


  /* .column1x3{
    width:100% !important;
  }
  .column1x2{
    width:66.66% !important;
  }
  .column1x1{
    width:33.33% !important;
  } */

  .dash-flex2-title-label{
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    line-height: 1.35;
    /* font-size: 16px; */
    font-size: 13px;
    margin-bottom: 0px;
    max-height: 44px;
    color:var(--black);
    padding-top:3px;
    margin-top: 2px;
  }

  .dash-flex2-title-1{
    font-size:14px !important;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    line-height: 1.35;
    margin-bottom: 14px;
    max-height: 44px;
  }

  .dash-subject-fac{position: relative;}
  .dash-subject-fac .classMap{display:flex; font-family: Lato; padding: 0 0 5px; white-space:normal; flex-wrap: wrap; width:100%; height:auto; margin:0;}
  .dash-subject-fac .classMap div{display:flex; width:100%; height:auto; align-items: flex-start; margin:1px 0; color:#fff; font-size:12px;}
  .dash-subject-fac .classMap div i{width:100%; max-width:72px; text-align: center; font-style: normal; white-space: nowrap; margin-right:4px; overflow:hidden; display: block; font-size:12px;}
  .dash-subject-fac .classMap .morecontent{width:100%; margin-top:10px; font-size:12px; color:#fff; height:auto; display: flex; justify-content: center;}
  .dash-subject-fac .hoverOverlay{position: absolute; border-radius: 10px; padding:10px; transition:all 0.2s ease; height:0px; width:100%; font-family: Lato; margin:0; opacity: 0; visibility: hidden; top:0; right:0; bottom:0; left:0; background-color:rgba(0,0,0,0.8);}
  .dash-subject-fac .hoverOverlay ul{display: flex; flex-direction: column; margin:0; padding:0; width: 100%; font-family: Lato;}
  .dash-subject-fac .hoverOverlay ul li{display: flex;margin:2px 0; width: 100%; justify-content: flex-start; color:#fff; font-size:12px; font-family: Lato;}
  .dash-subject-fac .hoverOverlay ul li i{max-width: 80px; white-space: nowrap; overflow: hidden; margin-right:5px; justify-content: flex-start; font-family: Lato;}
  .dash-subject-fac .hoverOverlay ul li p{margin:0}
  .dash-subject-fac:hover .hoverOverlay{height:100%; opacity: 1; visibility: visible;}


/*IADashboardWrapper Css Start From here*/
.IADashboardWrapper{display: flex; flex-direction: column; width: 100%; font-family: Lato;}
.IADashboardWrapper .main-layout-section{display: flex; width: 100%;}
.IADashboardWrapper .main-layout-section .left-section-layout{display: flex; flex-direction: column; width: 100%;}
.IADashboardWrapper .main-layout-section .right-section-layout{display: flex; flex-direction: column; width: 100%;}

.IADashboardWrapper .IADashboardContainer{flex-wrap: wrap; display: flex; width: 100%; flex-direction: column;}
.IADashboardWrapper .userinfo-widget{display: flex; width: 100%; border:0px solid #ddd; padding:10px; padding:0; border-radius:5px;}
.IADashboardWrapper .userinfo-widget .thumb{margin-right: 10px; max-width: 150px;}
.IADashboardWrapper .userinfo-widget .thumb img{max-width:100%;}
.IADashboardWrapper .userinfo-widget div{font-weight: bold; color:inherit;  font-size: 24px; margin-bottom: 15px;}
.IADashboardWrapper .userinfo-widget div img{max-width: 100%;}
.IADashboardWrapper .userinfo-widget > div:first-child{margin-bottom: 0;}
.IADashboardWrapper .userinfo-widget p{ margin:0; padding:0; margin-top:5px; line-height:24px; font-size:17px; color:inherit}
.IADashboardWrapper .userinfo-widget a{color:inherit}
.IADashboardWrapper .userinfo-widget .explorebtn{display: flex; margin:15px 0 0; justify-content: flex-end;}
.IADashboardWrapper .userinfo-widget .explorebtn a{ border: 1px solid #ddd; background-color: #fff; text-decoration: none; color: #000;  padding: 8px 15px; font-size: 17px;  border-radius: 5px;}

.IADashboardWrapper .column-widget{width: 100%; display: flex; margin:0px; border:0px solid #ddd; padding:6px; border-radius:5px; flex-direction: column;}
.IADashboardWrapper .column-widget:nth-child(1){width:100%;}
.IADashboardWrapper .column-widget:nth-child(2){width:100%}


.IADashboardWrapper .ifs-program-widget {display: flex; position: relative; box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%); width: 100%; flex-direction: column; padding:10px; background-color: #fff;}
.IADashboardWrapper .ifs-program-widget .heading-title{display: flex; width: 100%; font-weight: bold;  margin-bottom: 10px; color:#000; font-size: 19px;}
.IADashboardWrapper .ifs-program-widget .title {display: flex; width: 100%; font-weight: bold;  margin-bottom: 10px; color:#000; font-size: 17px;}
.IADashboardWrapper .ifs-program-widget .desc {display: flex; width: 100%; font-weight: normal;  margin-bottom: 10px; color:#999; font-size: 15px;}
.IADashboardWrapper .ifs-program-widget .program-actions{display: flex;  align-items: center; justify-content: space-between; width: 100%;}
.IADashboardWrapper .ifs-program-widget .program-actions .button{display: flex;}
.IADashboardWrapper .ifs-program-widget .program-actions .button a{background-color: #489198; text-decoration: none; color: #fff;  padding: 8px 15px;  font-size: 17px;  border-radius: 5px;}
.IADashboardWrapper .ifs-program-widget .program-actions .date{display: flex; flex-direction: column; color:#000; font-size: 15px;}
.IADashboardWrapper .ifs-program-widget .program-actions .date span{color:#999; font-size: 14px; margin-top: 5px;}
.IADashboardWrapper .ifs-program-widget .share-icon{position: absolute; right:10px; top:10px;}
.IADashboardWrapper .ifs-program-widget .share-icon img{max-width: 22px;}

.IADashboardWrapper .ift-program-widget {display: flex; position: relative; box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%); width: 100%; flex-direction: column; padding:10px; background-color: #fff;}
.IADashboardWrapper .ift-program-widget .heading-title{display: flex; width: 100%; font-weight: bold;  margin-bottom: 10px; color:#000; font-size: 19px;}
.IADashboardWrapper .ift-program-widget .title {display: flex; width: 100%; font-weight: bold;  margin-bottom: 10px; color:#000; font-size: 17px;}
.IADashboardWrapper .ift-program-widget .desc {display: flex; width: 100%; font-weight: normal;  margin-bottom: 10px; color:#999; font-size: 15px;}
.IADashboardWrapper .ift-program-widget .note {display: flex; width: 100%; font-weight: normal;  margin-bottom: 10px; color:#999; font-size: 13px;}
.IADashboardWrapper .ift-program-widget .program-actions{display: flex;  align-items: center; justify-content:center; width: 100%;}
.IADashboardWrapper .ift-program-widget .program-actions .button{display: flex; margin-left: 15px;}
.IADashboardWrapper .ift-program-widget .program-actions .button:first-child{margin-left:0px;}
.IADashboardWrapper .ift-program-widget .program-actions .button a{background-color: #489198; text-decoration: none; color: #fff;  padding: 8px 15px;  font-size: 17px;  border-radius: 5px;}
.IADashboardWrapper .ift-program-widget .program-actions .date{display: flex; flex-direction: column; color:#000; font-size: 15px;}
.IADashboardWrapper .ift-program-widget .program-actions .date span{color:#999; font-size: 14px; margin-top: 5px;}
.IADashboardWrapper .ift-program-widget .share-icon{position: absolute; right:10px; top:10px;}
.IADashboardWrapper .ift-program-widget .share-icon img{max-width: 22px;}

.IADashboardWrapper .ift-survey-widget {display: flex; position: relative; box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%); width: 100%; flex-direction: column; padding:10px; background-color: #fff;}
.IADashboardWrapper .ift-survey-widget .heading-title{display: flex; width: 100%; font-weight: bold;  margin-bottom: 10px; color:#000; font-size: 19px;}
.IADashboardWrapper .ift-survey-widget .title {display: flex; width: 100%; font-weight: bold;  margin-bottom: 10px; color:#000; font-size: 17px;}
.IADashboardWrapper .ift-survey-widget .desc {display: flex; width: 100%; font-weight: normal;  margin-bottom: 10px; color:#999; font-size: 15px;}
.IADashboardWrapper .ift-survey-widget .survey-actions{display: flex;  align-items:center; justify-content:flex-end; width: 100%;}
.IADashboardWrapper .ift-survey-widget .survey-actions .button{display: flex;}
.IADashboardWrapper .ift-survey-widget .survey-actions .button a{background-color: #489198; text-decoration: none; color: #fff;  padding: 8px 15px;  font-size: 17px;  border-radius: 5px;}
.IADashboardWrapper .ift-survey-widget .survey-actions .date{display: flex; flex-direction: column; color:#000; font-size: 15px;}
.IADashboardWrapper .ift-survey-widget .survey-actions .date span{color:#999; font-size: 14px; margin-top: 5px;}
.IADashboardWrapper .ift-survey-widget .share-icon{position: absolute; right:10px; top:10px;}
.IADashboardWrapper .ift-survey-widget .share-icon img{max-width: 22px;}


.IADashboardWrapper .courses-widget.className-widget-heading{display: flex; box-shadow: 0px 0px 0px 0px; padding-top:0; margin:15px 0 0; padding-bottom: 0;}
.IADashboardWrapper .courses-widget.className-widget-heading .userinfo-widget div{margin-bottom: 0;}
.IADashboardWrapper .courses-widget.className-widget-heading .userinfo-widget > div:last-child{max-width: none;}
.IADashboardWrapper .courses-widget.className-widget-heading .userinfo-widget > div:first-child{max-width: none; margin-right:0;}
.IADashboardWrapper .courses-widget.className-widget-heading .userinfo-widget{padding:0;}
.IADashboardWrapper .courses-widget.className-widget-heading .userinfo-widget div{margin-bottom:0px;}

.IADashboardWrapper .courses-widget.className-widget-heading .userinfo-widget >  div:first-child{width:100%; max-width: 60px; margin-right: 10px;}
.IADashboardWrapper .courses-widget.className-widget-heading .userinfo-widget >  div:first-child img{max-width: 100%;}

.IADashboardWrapper .right-section-layout .courses-widget{box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 10%); border-radius:5px;}

.IADashboardWrapper .courses-widget.image-background{padding:0; border-radius:5px;}
.IADashboardWrapper .courses-widget.image-background img{padding:0; border-radius:5px; display: flex;}

.IADashboardWrapper .courses-widget .quick-recommendations-widget {display: flex; width: 100%; flex-direction: column; padding:0px; background-color: #fff;}
.IADashboardWrapper .courses-widget .quick-recommendations-widget .heading-title{display: flex; width: 100%; justify-content: flex-start; font-weight: bold; text-align: left;  margin-bottom: 10px; color:#000; font-size: 19px;}
.IADashboardWrapper .courses-widget .quick-recommendations-widget  ol{margin:0; padding: 0; flex-direction: column; overflow-y: auto; max-height: 400px; flex-wrap: inherit;}
.IADashboardWrapper .courses-widget .quick-recommendations-widget  ol li{margin:10px 0 0; flex-direction: row;  line-height: 22px; font-size: 14px; max-width: none; border-radius:5px; padding: 10px; border:1px solid #ddd; align-items: flex-start;  list-style-type: none; width: 100%; display: flex;}
.IADashboardWrapper .courses-widget .quick-recommendations-widget  ol li .course-thumb{ overflow: hidden; flex-shrink: 0; display: flex; width:64px; height: auto; margin-right: 10px; border:1px solid #ddd;}
.IADashboardWrapper .courses-widget .quick-recommendations-widget  ol li .course-thumb img{max-width: 100%;}
.IADashboardWrapper .courses-widget .quick-recommendations-widget  ol li a{display: flex; align-items: flex-start; width:100%; color: #000; padding:0px; font-weight: bold;}
.IADashboardWrapper .courses-widget .quick-recommendations-widget  ol li:hover{background-color: #f6f6f6;}
.IADashboardWrapper .courses-widget .quick-recommendations-widget  ol li .details{color:#999; margin-top: 5px; display: flex; flex-wrap: wrap;;}
.IADashboardWrapper .courses-widget .quick-recommendations-widget  ol li .details span:first-child{color:#000; font-weight: normal;  margin-left:0;}
.IADashboardWrapper .courses-widget .quick-recommendations-widget  ol li .details span:last-child{color:#999;  font-weight: normal; margin-left: 10px;}

.IADashboardWrapper .courses-widget .quick-links-widget{display: flex; border-radius:5px; width: 100%; background-color: #fff; flex-direction: column; padding:0px;}
.IADashboardWrapper .courses-widget .quick-links-widget .heading-title{font-weight: bold; margin-bottom: 10px; color:#000; font-size: 19px; text-align: left;}
.IADashboardWrapper .courses-widget .quick-links-widget ol{margin:0; padding: 0;}
.IADashboardWrapper .courses-widget .quick-links-widget ol li{margin:10px 0 0; flex-direction: row;  line-height: 22px; font-size: 14px; max-width: none; border-radius:5px; padding: 10px; border:1px solid #ddd; align-items: center;  list-style-type: none; justify-content: space-between; width: 100%; display: flex;}
.IADashboardWrapper .courses-widget .quick-links-widget ol{max-height: 350px; overflow-y: auto;}
.IADashboardWrapper .courses-widget .quick-links-widget ol li:first-child{margin-top:0}
.IADashboardWrapper .courses-widget .quick-links-widget ol li div:first-child{flex:1 1;}
.IADashboardWrapper .courses-widget .quick-links-widget ol li div:first-child a{background-color: #3ac47d; color:#fff}
.IADashboardWrapper .courses-widget .quick-links-widget ol li div:last-child{width:130px; padding: 10px; color:#999; display: flex; justify-content: center;}
.IADashboardWrapper .courses-widget .quick-links-widget ol li div:last-child a{border:1px solid #ddd; text-decoration: none; color:#fff; padding:5px 15px; border-radius:5px;}
.IADashboardWrapper .courses-widget .quick-links-widget ol li:nth-child(even) div:last-child a {background-color: #3ac47d;}
.IADashboardWrapper .courses-widget .quick-links-widget ol li div:nth-child(2) a {border:1px solid #ddd; text-decoration: none; display:flex; color:#fff; padding:5px 15px; border-radius:5px; background-color: #1d73f2;}
.IADashboardWrapper .courses-widget .quick-links-widget ol li:nth-child(odd) div:last-child a {background-color:var(--secondary);}
.IADashboardWrapper .courses-widget .quick-links-widget ol li:nth-child(odd){border-left:4px solid #3ac47d;}
.IADashboardWrapper .courses-widget .quick-links-widget ol li:nth-child(even){border-left:4px solid #f7b924;}

.IADashboardWrapper .courses-widget{display: flex; width: 100%; flex-direction: column; padding:10px; margin:0px; box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%); box-shadow: 0px 0px 0px 0px;}
.IADashboardWrapper .courses-widget ol{margin:0; padding: 0; flex-wrap: wrap; width: 100%; display: flex;}
.IADashboardWrapper .courses-widget ol li{margin:10px 0px; width: 100%; align-items: center; max-width:none; padding: 0; flex-direction: column; display: flex; }
.IADashboardWrapper .courses-widget ol li .course-title{font-family:var(--defaultfont); margin-top: 8px; color:#000; font-size:14px; text-align: center;}
.IADashboardWrapper .courses-widget ol li .course-thumb{border:1px solid #ddd; display: block; width: 100%; max-width: 80px; overflow: hidden; height: 80px; max-height: 80px;}

.IADashboardWrapper .courses-widget a{color: inherit;}
.IADashboardWrapper .courses-widget.paid-course{padding-top: 0; padding-bottom: 0;}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item.paid-course-single-item:nth-child(1) {background: transparent linear-gradient(122deg, #FF8A57 0%, #FF5502 100%) 0% 0% no-repeat padding-box; margin-bottom: 0;}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item.paid-course-single-item:nth-child(2){background: transparent linear-gradient(123deg, #FFCE00 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item.paid-course-single-item:nth-child(3){background:transparent linear-gradient(123deg, #fb00ff 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item.paid-course-single-item:nth-child(4){background:transparent linear-gradient(123deg, #00e1ff 0%, #1e0b03 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item.paid-course-single-item:nth-child(5){background:transparent linear-gradient(123deg, #00dcff 0%, #ff5a14 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item.paid-course-single-item:nth-child(6){background:transparent linear-gradient(123deg, #ff000c 0%, #fd14ff 100%) 0% 0% no-repeat padding-box}

.IADashboardWrapper .right-section-layout .courses-widget.paid-course ol{flex-wrap: initial;}
.IADashboardWrapper .right-section-layout .courses-widget.paid-course ol li{margin:0 10px 0 0;}
.IADashboardWrapper .right-section-layout .courses-widget.paid-course ol li:last-child{margin-right:0;}

.IADashboardWrapper .courses-widget.text-color-custom .userinfo-widget div{color:#000}
.IADashboardWrapper .courses-widget.text-color-custom .userinfo-widget p{color:#7f7373}
.IADashboardWrapper .courses-widget.text-color-custom .userinfo-widget div:first-child{margin-bottom: 0;}

.IADashboardWrapper .courses-widget.image-left .userinfo-widget{display: flex; flex-direction: row;}
.IADashboardWrapper .courses-widget.image-left .userinfo-widget > div:first-child{max-width: 100px; margin-right: 15px;  margin-bottom: 0; display: flex; flex-direction: column;}
.IADashboardWrapper .courses-widget.image-left .userinfo-widget > div:last-child{flex:1 1; margin-bottom: 0;}

.IADashboardWrapper .courses-widget.background-color-random .slick-slide:nth-child(1) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random .slick-slide:nth-child(2) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random .slick-slide:nth-child(3) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random .slick-slide:nth-child(4) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random .slick-slide:nth-child(5) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random .slick-slide:nth-child(6) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random .slick-slide:nth-child(7) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random .slick-slide:nth-child(8) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random .slick-slide:nth-child(9) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random .slick-slide:nth-child(10) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random .slick-slide:nth-child(11) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random .slick-slide:nth-child(12) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:5px; text-align: center;}


.IADashboardWrapper .courses-widget.background-color-random ul li.slide:nth-child(1) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random ul li.slide:nth-child(2) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random ul li.slide:nth-child(3) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random ul li.slide:nth-child(4) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random ul li.slide:nth-child(5) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random ul li.slide:nth-child(6) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random ul li.slide:nth-child(7) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random ul li.slide:nth-child(8) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random ul li.slide:nth-child(9) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random ul li.slide:nth-child(10) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random ul li.slide:nth-child(11) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:5px; text-align: center;}
.IADashboardWrapper .courses-widget.background-color-random ul li.slide:nth-child(12) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:5px; text-align: center;}

.IADashboardWrapper .carousel-root .carousel-slider{padding-bottom: 10px;}
.IADashboardWrapper .carousel .control-dots{margin: 0;}
.IADashboardWrapper .carousel .control-dots .dot{width:12px; height:12px; border:1px solid #2c2c2c;}
.IADashboardWrapper .carousel .control-dots .dot.selected{background-color: #ddd; border:1px solid #ddd;}

.IADashboardWrapper .courses-widget.background-color-random  .slick-slide p{color: #fff; font-size: 17px;}
.IADashboardWrapper .courses-widget.background-color-random  .slick-slide div{color: #fff; font-size: 24px; margin-bottom:0px;}
.IADashboardWrapper .column-widget .slick-dots{bottom:0;}
.IADashboardWrapper .column-widget .slick-dots li button:before{font-size: 14px;}
/*.IADashboardWrapper .slick-slider {margin:0 -30px;}
.IADashboardWrapper .slick-slide{margin-left: 15px; margin-right: 15px;}*/



.IADashboardWrapper .courses-widget.paid-course ol li { position: relative; width:100%; max-width: none; align-items: flex-start; padding: 15px; margin-bottom:15px; border-radius:12px;}
.IADashboardWrapper .courses-widget.paid-course ol li .course-title{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper .courses-widget.paid-course ol li .course-title a{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper .courses-widget.paid-course ol li .course-desc{font-size: 15px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper .courses-widget.paid-course ol li .course-description{font-size: 18px; line-height: 24px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper .courses-widget.paid-course ol li .arrow{position: absolute; right: 10px;display: flex;align-items: center;justify-content: center;  top: 10px;  width: 28px;  height: 28px;  border-radius: 100%;
  background-color: hsla(0,0%,100%,.2);}
.IADashboardWrapper .courses-widget.paid-course ol li .arrow a{display: flex; align-items: center;}  
.IADashboardWrapper .courses-widget.paid-course ol li .arrow a img{max-width: 100%;}
.IADashboardWrapper .courses-widget.paid-course ol li:first-child{margin-top: 0;;}
.IADashboardWrapper .courses-widget.paid-course ol li:nth-child(1){background: transparent linear-gradient(122deg, #FF8A57 0%, #FF5502 100%) 0% 0% no-repeat padding-box; margin-bottom: 0;}
.IADashboardWrapper .courses-widget.paid-course ol li:nth-child(2){background: transparent linear-gradient(123deg, #FFCE00 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper .courses-widget.paid-course ol li:nth-child(3){background:transparent linear-gradient(123deg, #fb00ff 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper .courses-widget.paid-course ol li:nth-child(4){background:transparent linear-gradient(123deg, #00e1ff 0%, #1e0b03 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper .courses-widget.paid-course ol li:nth-child(5){background:transparent linear-gradient(123deg, #00dcff 0%, #ff5a14 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper .courses-widget.paid-course ol li:nth-child(6){background:transparent linear-gradient(123deg, #ff000c 0%, #fd14ff 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper .courses-widget.paid-course ol li .course-thumb{ width:70px; height: 70px; border-radius:100%; background-color: #fff; display: flex; align-items: center; justify-content: center; margin-bottom: 10px;}


.IADashboardWrapper .courses-widget.paid-course .paid-course-item{position: relative; margin:0 10px; max-width: none; align-items: flex-start; padding: 15px; margin-bottom:15px; width:auto!important; display:block!important; border-radius:12px;}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item .course-thumb{ width:70px; height: 70px; padding:10px; border-radius:100%; background-color: #fff; display: flex; align-items: center; justify-content: center; margin-bottom: 10px;}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item .course-thumb img{max-width: 100%;}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item .course-title{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item .course-title a{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item .course-desc{font-size: 15px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item .course-description{font-size: 18px; text-align: left; line-height: 24px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item .arrow{position: absolute; right: 10px;display: flex;align-items: center;justify-content: center;  top: 10px;  width: 28px;  height: 28px;  border-radius: 100%; background-color: hsla(0,0%,100%,.2);}
.IADashboardWrapper .courses-widget.paid-course .paid-course-item .arrow a{display: flex; align-items: center;}  
.IADashboardWrapper .courses-widget.paid-course .paid-course-item .arrow a img{max-width: 100%;}
.IADashboardWrapper .courses-widget.paid-course .slick-slide:first-child{margin-top: 0;}
.IADashboardWrapper .courses-widget.paid-course .slick-slide{border-radius:5px;}
.IADashboardWrapper .courses-widget.paid-course .slick-slide:nth-child(1) .paid-course-item{background: transparent linear-gradient(122deg, #FF8A57 0%, #FF5502 100%) 0% 0% no-repeat padding-box; margin-bottom: 0;}
.IADashboardWrapper .courses-widget.paid-course .slick-slide:nth-child(2) .paid-course-item{background: transparent linear-gradient(123deg, #FFCE00 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper .courses-widget.paid-course .slick-slide:nth-child(3) .paid-course-item{background:transparent linear-gradient(123deg, #fb00ff 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper .courses-widget.paid-course .slick-slide:nth-child(4) .paid-course-item{background: linear-gradient(to right, #02aab0, #00cdac);}
.IADashboardWrapper .courses-widget.paid-course .slick-slide:nth-child(5) .paid-course-item{background: linear-gradient(to right, #ffe259, #ffa751);}
.IADashboardWrapper .courses-widget.paid-course .slick-slide:nth-child(6) .paid-course-item{background: linear-gradient(to right, #1a2980, #26d0ce);}
.IADashboardWrapper .courses-widget.paid-course .slick-slide:nth-child(7) .paid-course-item{background: linear-gradient(to right, #ed4264, #ffedbc);}
.IADashboardWrapper .courses-widget.paid-course .slick-slide:nth-child(8) .paid-course-item{background: linear-gradient(to right, #c21500, #ffc500);}
.IADashboardWrapper .courses-widget.paid-course .slick-slide:nth-child(9) .paid-course-item{background: linear-gradient(to right, #00d2ff, #3a7bd5);}
.IADashboardWrapper .courses-widget.paid-course .slick-slide:nth-child(10) .paid-course-item{background: linear-gradient(to right, #52c234, #061700);}
 


.IADashboardWrapper .courses-widget.free-course ol li .course-thumb img{max-width: 100%; width: 60px; height: 60px;}
.IADashboardWrapper .courses-widget.free-course ol{ flex-wrap: wrap; width: 100%; display: flex; margin:0; padding: 0;}
.IADashboardWrapper .courses-widget.free-course ol li {margin:8px 8px; border-radius: 10px; height: 120px; transition: all 0.2s ease; ; width: 100%; align-items: center;  max-width: 30%;  padding: 0;  flex-direction: column; display: flex;}
.IADashboardWrapper .courses-widget.free-course ol li .course-thumb { display: flex; background-color: #eef0f3; border-radius:5px;  align-items: center;  justify-content: center; font-size: 39px;  border: 0px solid #ddd;  width: 100%;  max-width: 125px;  overflow: hidden;  height:85px; margin:0 auto;  max-height: 85px;}
.IADashboardWrapper .courses-widget.free-course ol li .course-title {font-family: var(--defaultfont); margin-top: 8px;  color: #000;  font-size: 16px; text-align: center;}
.IADashboardWrapper .courses-widget.free-course ol li .course-title a {font-family: var(--defaultfont); margin-top: 8px;  color: #000;  font-size: 16px; text-align: center;}
.IADashboardWrapper .courses-widget.free-course ol li:hover{-webkit-transform: translateY(-6px);transform: translateY(-6px); box-shadow: 4px 4px 12px -6px var(--text-black2);};
.IADashboardWrapper .courses-widget.free-course ol li:nth-child(1) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper .courses-widget.free-course ol li:nth-child(2) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper .courses-widget.free-course ol li:nth-child(3) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper .courses-widget.free-course ol li:nth-child(4) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper .courses-widget.free-course ol li:nth-child(5) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper .courses-widget.free-course ol li:nth-child(6) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper .courses-widget.free-course ol li:nth-child(7) .course-thumb{background-color: #faecef; color:#fff;}
.IADashboardWrapper .courses-widget.free-course ol li:nth-child(8) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper .courses-widget.free-course ol li:nth-child(9) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper .courses-widget.free-course ol li:nth-child(10) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper .courses-widget.free-course ol li:nth-child(11) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper .courses-widget.free-course ol li:nth-child(12) .course-thumb{background-color: #eef0f3; color:#fff;}

.IADashboardWrapper .courses-widget ol.referal-widget li:first-child{margin-top: 0;}
.IADashboardWrapper .courses-widget ol.referal-widget li { position: relative; width:100%; max-width: none; align-items: flex-start; padding: 15px; margin-bottom:15px; border-radius:12px;}
.IADashboardWrapper .courses-widget ol.referal-widget li .course-title{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper .courses-widget ol.referal-widget li .course-title a{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper .courses-widget ol.referal-widget li .course-desc{font-size: 15px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper .courses-widget ol.referal-widget li .course-description{font-size: 18px; line-height: 24px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper .courses-widget ol.referal-widget li .arrow{position: absolute; right: 10px;display: flex;align-items: center;justify-content: center;  top: 10px;  width: 28px;  height: 28px;  border-radius: 100%;
  background-color: hsla(0,0%,100%,.2);}
.IADashboardWrapper .courses-widget ol.referal-widget li{background: linear-gradient(to right, rgb(103, 178, 111), rgb(76, 162, 205));}
.IADashboardWrapper .courses-widget ol.referal-widget li .course-description{font-size: 18px; line-height: 24px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper .courses-widget ol.referal-widget li .course-thumb{ width:70px; height: 70px; border-radius:100%; background-color: #fff; display: flex; align-items: center; justify-content: center; margin-bottom: 10px;}
.IADashboardWrapper .courses-widget ol.referal-widget li .course-thumb img{max-width: 100%; width: 60px; height: 60px;}
.IADashboardWrapper .courses-widget ol.referal-widget li a{text-decoration: underline; color:#000}
.IADashboardWrapper .courses-widget ol.referal-widget li a:hover{text-decoration: none; color:#000}

.IADashboardWrapper .courses-widget.tag-course ol li { align-items: flex-start; display: flex; justify-content: space-between;  transition: all .2s ease;  width: 100%;  max-width: 31%;  border-radius: 18px;  color: #fff;  margin: 0 0 0 20px;  padding: 15px;  flex-direction: row;min-height: 150px}
.IADashboardWrapper .courses-widget.tag-course ol li a{display: flex;width:100%; justify-content: space-between;}
.IADashboardWrapper .courses-widget.tag-course ol li:first-child { background-image: url(/static/media/imaginxp-gamebanner.c6fc67bf.jpg); background-repeat: no-repeat;  background-size: cover;  margin-left: 0;}
.IADashboardWrapper .courses-widget.tag-course ol li:nth-child(2) {background-color: rgba(255,82,2,.7);}
.IADashboardWrapper .courses-widget.tag-course ol li:nth-child(3) {background: transparent linear-gradient(121deg,#5fa8ff,#1d73f2) 0 0 no-repeat padding-box;}
.IADashboardWrapper .courses-widget.tag-course ol li .course-thumb{ margin-left: 15px; order:2;  align-items: center;  justify-content: center; display: flex;  background-color: rgba(96,96,25,.2);  border-radius: 100%;  width: 60px;  height: 60px;}
.IADashboardWrapper .courses-widget.tag-course ol li:first-child a{display: flex; justify-content: space-between;}

.IADashboardWrapper .courses-widget.tag-course ol li div.arrow{ margin-left: 15px; order:2;  align-items: center;  justify-content: center; display: flex;  background-color: rgba(96,96,25,.2);  border-radius: 100%;  width: 19px;  height: 19px;}
.IADashboardWrapper .courses-widget.tag-course ol li:first-child div.arrow .content{order:1}
.IADashboardWrapper .courses-widget.tag-course ol li:first-child .content h3{color:#fff; font-size: 17px;}
.IADashboardWrapper .courses-widget.tag-course ol li:first-child .content p{color:#fff; font-size: 17px; margin:10px 0 0 ;}
.IADashboardWrapper .courses-widget.tag-course ol li:first-child .content p:last-child{color:#fff; font-size: 12px;}
.IADashboardWrapper .courses-widget.tag-course ol li .course-title {font-size: 17px; order:1; color: #fff;  margin: 0 0 15px;}
.IADashboardWrapper .courses-widget.tag-course ol li .course-title a{font-size: 17px; order:1; color: #fff;  margin: 0 0 15px;}

.app-body.widget-dashboard .app-div{width: auto; padding:10px 20px 25px 20px; top: 50px; left:150px; height: calc(100vh - 55px);}
.header2.new-dashboard-navigation{width:100%; max-width: 150px; left:0; top:0; padding:0; height: calc(100vh - 0px);}
.header2.new-dashboard-navigation .sidebar-links.new-dashboard-navigation{flex-direction: column;}
.header2.new-dashboard-navigation .sidebar-links.new-dashboard-navigation a{flex-direction: column; width: 100%;}
.header2.new-dashboard-navigation .sidebar-links.new-dashboard-navigation a span:first-child{margin-bottom: 10px; width:45px; height:45px;}
.header2.new-dashboard-navigation .sidebar-links.new-dashboard-navigation a span:last-child{margin-left:0px;word-break: break-word;white-space: normal; text-align: center;}


.app-header.widget-dashboard{width:auto; z-index: 220002; left: 150px; padding:10px 20px 20px 20px; margin-bottom: 0; top: 0px; right:0;}

.app-header.widget-dashboard .profile{/*justify-content: space-between; width: 100%;*/ align-items: center;width:100%;}
.app-header.widget-dashboard .profile .TopHeaderLinks{display: flex; font-family: Lato; font-size: 17px; justify-content: space-between; align-items: center; width: 100%;}
.app-header.widget-dashboard .profile .TopHeaderLinks .innovationText{display: flex; align-items: center;}
.app-header.widget-dashboard .profile .TopHeaderLinks .innovationText img{/*max-width: 68px;*/ margin-right: 10px;}

.widget-dashboard .referralDialog-overlay{z-index:220000!important;}
.widget-dashboard .referralDialog-content{height:auto; padding:0px !important; background-color:#ffe198!important}
.widget-dashboard .referralDialogWrapper{display:flex; flex-direction:row; position: relative; font-family: Lato;}
.widget-dashboard .referralDialogWrapper .leftsection{width:60%; position: relative; display:flex; padding:15px; flex-direction: column;}
.widget-dashboard .referralDialogWrapper .rightsection{width:40%; display:flex; flex-direction: column;  background-size: cover; background-image: url(/static/media/referral-rightbg1.68a68baf.png); background-repeat:no-repeat; }
.widget-dashboard .referralDialogWrapper .shape1{position:absolute; z-index:0; width:150px; background-color: #00f8b9; right:-40px; top:-40px; height: 150px; border-radius:100px;}
.widget-dashboard .referralDialogWrapper .shape2{position:absolute; width:150px; z-index:0; background-color: #0096f8; left:-40px; bottom:-40px; height: 150px; border-radius:100px;}
.widget-dashboard .referralDialogWrapper .headingtitle{border-bottom:1px solid #000; font-weight:bold; margin-bottom: 15px; padding-bottom: 10px; font-size:26px; color:#000;}
.widget-dashboard .referralDialogWrapper ol{margin:0; padding:0; display: flex; flex-direction:column; overflow-y: auto; max-height:300px;}
.widget-dashboard .referralDialogWrapper ol li{margin:15px 0 0; padding:0; display: flex; flex-direction:column;border: 0px solid #0edf0e;padding:0px; border-radius: 5px;}
.widget-dashboard .referralDialogWrapper ol li:first-child{margin-top: 0;}
.widget-dashboard .referralDialogWrapper ol li .question-title{font-weight:bold; margin-bottom:5px; padding-bottom:0px; font-size: 17px; color:#000;}
.widget-dashboard .referralDialogWrapper ol li .labelblock{display: flex; width: 100%; flex-wrap: wrap;}
.widget-dashboard .referralDialogWrapper ol li .labelinputblock{display: flex; width: 100%; flex-wrap: wrap;}
.widget-dashboard .referralDialogWrapper ol li input[type="text"]{max-width: none; width: 100%;}
.widget-dashboard .referralDialogWrapper ol li label{display: flex; width:100%; max-width: 50%; align-items: flex-start;justify-content:flex-start; padding:3px;}
.widget-dashboard .referralDialogWrapper ol li label input{margin-right:10px;}
.widget-dashboard .referralDialogWrapper ol li label span{min-width:60px; margin-right: 10px;}
.widget-dashboard .referralDialogWrapper ol li input[type="text"]{border:1px solid #ddd; padding:10px 10px; font-family: Lato;}
.widget-dashboard .referralDialogWrapper .question-action{text-align:right; align-items: flex-end; justify-items: flex-end;  border-top:0px solid #ddd; margin-top:15px; padding-top:0px;}
.widget-dashboard .referralDialogWrapper .question-action button{background-color: #000; color:#fff}
/*IADashboardWrapper Css ends here*/

.app-div.app-dashboard{left:250px;top:70px; height:calc(100vh - 75px)}
.app-div.app-dashboard{left:250px;top:70px; height:calc(100vh - 75px)}
.app-div.app-dashboard .dashboard-schedule{width:auto;}
.app-div.app-dashboard .dashboard-schedule .viewall-btn{background-color: transparent!important;}
.app-div.app-dashboard .dashboard-schedule .viewall-btn a{border:0px solid #ddd; font-family: Lato;text-decoration: none; display: flex;  color: #fff;  padding: 5px 15px;  border-radius: 5px;  background-color: var(--primary);}

.app-div.widget-app-dashboard{left:150px; top:70px; height:calc(100vh - 75px)}
.app-div.widget-app-dashboard .dashboard-schedule{width:auto}
.app-div.widget-app-dashboard .dashboard-schedule .viewall-btn{background-color: transparent!important;}
.app-div.widget-app-dashboard .dashboard-schedule .viewall-btn a{border:0px solid #ddd; font-family: Lato; text-decoration: none; display: flex;  color: #fff;  padding: 5px 15px;  border-radius: 5px;  background-color: var(--primary);}
  
.app-div.widget-app-div{left:150px; top:70px; height:calc(100vh - 75px)}
.app-div.widget-app-div .dashboard-schedule{width:auto}
.app-div.widget-app-div .dashboard-schedule .viewall-btn{background-color: transparent!important;}
.app-div.widget-app-div .dashboard-schedule .viewall-btn a{border:0px solid #ddd; font-family: Lato; text-decoration: none; display: flex;  color: #fff;  padding: 5px 15px;  border-radius: 5px;  background-color: var(--primary);}

.app-list-div .list-sticky{width:auto; left:0;}

.list-sticky.widthAuto{width:auto; left:0;}

.list-sticky.notwidgetheader{width:auto; left:0; right:0;}
.padding20{padding: 20px;}

.page-header-test{z-index: 2;}
.list-sticky{width: 84vw; height: calc(100vh - 180px); top:110px}
/* .list-sticky{width: 87vw; height: calc(100vh - 180px); top:110px} */
/*.app-div{left: 150px; top:70px; height:calc(100vh - 75px)}*/

.app-body.widget-dashboard .referral-thanksmsg{display: flex; flex-direction: column; font-family: Lato;}
.app-body.widget-dashboard .referral-thanksmsg .thanksmsg{font-size: 26px; justify-content: center;  align-items: center;display: flex; flex-direction: column; font-family: Lato; font-weight: bold;}
.app-body.widget-dashboard .referral-thanksmsg .thanksmsg i{margin-bottom:0px; max-width: 65px;}
.app-body.widget-dashboard .referral-thanksmsg .thanksmsg i img{max-width: 100%;}
.app-body.widget-dashboard .referral-thanksmsg .addmore-msg{display: flex; align-items: center; margin-top: 20px; flex-direction: column; width: 100%;}
.app-body.widget-dashboard .referral-thanksmsg .addmore-msg a{font-size: 17px; color:#fff; background-color:#1bce52; padding:8px 25px; display: flex; border-radius:5px; text-transform: capitalize; margin-top: 15px; text-decoration: none;}
.app-body.widget-dashboard .referral-thanksmsg .addmore-msg a:hover{font-size: 17px; text-decoration: none;}
.app-body.widget-dashboard .referral-end-content{height:auto}

.iserrorfound-content{
      height: 50% !important;
      width: 25% !important;
      font-size: 16px;
      font-family: 'Lato';
      text-align: center;
      padding: 19px !important;
      border-radius: 8px !important;
}

.ohno{
    font-size: 20px;
    color: red;
    font-weight: 600;
}

.connection_failed{
  margin: 10px;
  color: #153550;
  font-size: 16px;
  font-weight: 600;
}
/*Responsive Css Start From here*/

@media only screen and (max-width: 1150px){
  .app-header.widget-dashboard{background-color: #fff; position: fixed;}
  .app-body.widget-dashboard .app-div{background-color: var(--white); border-radius:0px;}
}

@media only screen and (max-width: 1150px){
  .app-header.widget-dashboard{background-color: #fff; position: fixed;}
  .app-body.widget-dashboard .app-div{background-color: var(--white); border-radius:0px;}
  .IADashboardWrapper .main-layout-section .left-section-layout{flex-direction: column;  max-width: none;}
  .IADashboardWrapper .main-layout-section .right-section-layout{flex-direction: column;  max-width: none;}
}


@media only screen and (max-width: 930px){
  .IADashboardWrapper .courses-widget.free-course ol li{max-width: 28%;}

}

@media only screen and (max-width: 850px){
  .IADashboardWrapper .courses-widget.tag-course ol li{width: 29%;}
  .IADashboardWrapper .courses-widget .quick-links-widget ol li{flex-direction: column;}
  .IADashboardWrapper .main-layout-section{flex-direction: column;}
  .IADashboardWrapper .main-layout-section .left-section-layout{width: 100%!important;}
  .IADashboardWrapper .main-layout-section .right-section-layout{width: 100%!important;}
}



@media only screen and (max-width: 600px){
  .app-body.widget-dashboard .app-div{left: 0;  top:85px; height: calc(100vh - 135px);}
  .IADashboardWrapper .courses-widget.free-course ol{width: 100%; flex-wrap: wrap;}
  .IADashboardWrapper .courses-widget.free-course ol li{max-width: 29%; height: auto;}
  .app-div.widget-app-dashboard{left:0;}
  .app-header.widget-dashboard{position: static;}
  .app-div.widget-app-dashboard{top:95px;}
  .dash-lecture.newDesign .newDesign{margin-left: 0; margin-top: 10px;}
}




@media only screen and (max-width: 812px) and (orientation: landscape){
  .IADashboardWrapper .tag-course.courses-widget ol{flex-direction: column;}
  .IADashboardWrapper .tag-course.courses-widget ol li{max-width: none; margin:0 0 15px 0; width: 100%;}
  .app-body.widget-dashboard .app-div{right:0;}
}

  
@media only screen and (max-width:670px) and (orientation: landscape){
  .IADashboardWrapper .courses-widget.free-course ol li{max-width: 29%; }
 
 }

   
@media only screen and (max-width:570px) and (orientation: landscape){
  .IADashboardWrapper .courses-widget.free-course ol{flex-direction: row;}
  .IADashboardWrapper .courses-widget.free-course ol li{max-width: 29%; }
 
 }

  @media only screen and (max-width: 1000px) {  

    .dashboard-main .dashboard-left{width:100%}
    .dashboard-schedule{width:auto}
    .dashboard-main .dashboard-left .dashboard-practices-course{width: 100%; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-main .dashboard-left .dashboard-practices-course .course-div{width: 100%; flex-shrink: 0;  max-width: 300px;}

    .dashboard-main .dashboard-left .dashboard-subjects{width: 100%; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-main .dashboard-left .dashboard-subjects .dash-subject-fac{width: 100%; flex-shrink: 0;  max-width: 300px;}

    .dashboard-subjects{width: 100%; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-subjects .dash-subject{width: 100%; flex-shrink: 0; margin-left:15px;  max-width: 300px; flex-wrap: nowrap;}
    .dashboard-subjects .dash-subject:first-child{margin-left:0}
    .dashboard-exercise .dashboard-practices{width: 100%; padding:10px 0; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-exercise .dashboard-practices .dash-practice{width: 100%; flex-shrink: 0; margin:0 10px;  max-width: 300px; flex-wrap: nowrap;}

    .dashboard-main .dashboard-left .dashboard-subjects{width: 100%; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-main .dashboard-left .dashboard-subjects .dash-subject-fac{width: 100%; flex-shrink: 0;   flex-shrink: 0;}
    .dashboard-main .dashboard-left .dashboard-subjects .subjectsWrapper{flex-shrink: 0; max-width: 50%;}
    .dashboard-main .dashboard-left .dashboard-subjects .subjectsWrapper .dash-subject-fac{width: 100%; flex-shrink: 0;  max-width: none;}

  
    .dashboard-practices{width: 100%; padding:10px 0; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-main .dashboard-right{display:none;}
    .drop-profile-creds{position: relative;}
    .drop-profile-creds div{width: 100%;}
    .drop-profile-creds + #edit-prof{position: absolute; top:0;}

  }

  @media screen and (max-width: 770px){
    .app-div.app-dashboard{left:0; width: calc(100%);}
    .dashboard-main .dashboard-left .dash-subject{margin-left: 20px;}
    .dashboard-main .dashboard-left .dash-subject:first-child{margin-left:0px;}
    .dashboard-main .dashboard-left .dashboard-schedule{display: flex; flex-direction: column; margin-left: 0; margin-bottom: 50px;}
    .dash-lecture {flex-direction: column;  padding: 10px;  background-color: #fff;  align-items: flex-start; }
    .app-div.app-dashboard .dashboard-schedule .viewall-btn {
        background-color: transparent!important;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
    .app-div.app-dashboard, .app-div.app-studymaterial, .app-div.app-webinar{left:0; width:100%; height: calc(100vh - 139px);}
  }

  @media only screen and (max-width: 600px) {  
    .dashboard-exercise:first-child{margin-top:0}
    .dashboard-main .dashboard-left .dashboard-practices-course .course-div{width: 100%; flex-shrink: 0;  max-width: 200px;}
    .dashboard-subjects .dash-subject{width: 100%; flex-shrink: 0;  max-width:200px; flex-wrap: nowrap;}
    .dash-subject-fac{ width: 100%; flex-shrink: 0;  max-width:200px; flex-wrap: nowrap;}
    .sm-layers{width:100%}
    .sm-layers .sm-chapters{width: 100%;}
    .dashboard-schedule{padding: 10px;}
    .dash-lecture{flex-direction:column; padding:10px; background-color:#fff; align-items: flex-start;}
    .dash-lecture .dash-lecture-date{display: flex;  border-right:0px none; flex-direction: row; padding:0px}
    .dash-lecture .dash-lecture-date span{margin:0 5px}
    .dash-lecture-date-title{flex-direction:column; width:100%; margin-left:0}
   
    .dash-lecture-date-third{align-items: flex-end; padding-left:0}
    .dash-lecture-date-third div + div{padding-right:0; margin-top:15px;}
    .dash-sch-head{padding-left:0;}
    .dash-sch-head div{display: flex;}
    .dashboard-main .dashboard-left .dashboard-schedule{margin-left:0; margin-bottom:50px;display:flex; flex-direction: column;}

    .app-body.widget-dashboard .app-div{width: 100%;}

    .app-header.widget-dashboard{width: auto; left: 6px;  right: 15px;}

    .IADashboardWrapper .courses-widget.tag-course ol{flex-direction: column;}
    .IADashboardWrapper .courses-widget.tag-course ol li{width: 100%; max-width: none; margin:0 0 15px 0}
    .IADashboardWrapper .courses-widget.tag-course ol li a{width: 100%;}
    body.mycoachpageadded{background-color: #fff!important;}

    .app-header.widget-dashboard .profile .TopHeaderLinks{flex-direction: column;}
    .app-header.widget-dashboard .innovationText{flex-direction: column; margin-bottom:5px; font-size: 16px; align-items: flex-start;}
    .app-header.widget-dashboard .profile{flex-direction: column;}
    .app-header.widget-dashboard .profile #dropper{display: flex; position: absolute; top:10px; right:10px;}
    .app-header.widget-dashboard .logout-setting{top:12px; right:30px; position: absolute;}
    .mycoachpageadded .header2.mobilenavigationActive{height:auto}
    .app-header.widget-dashboard .profile .TopHeaderLinks{justify-content: flex-start; align-items: flex-start;}
    .app-header.widget-dashboard .profile .TopHeaderLinks .innovationText{align-items: flex-start;}

    .app-div.widget-app-div{left:0}
    .app-div.widget-app-div{top:85px}

    .app-header.widget-dashboard .app-div.mycoach-div{height: calc(100vh - 283px);}

    .widget-dashboard .referralDialog-content{width: 85%!important;}

    .app-div.app-dashboard{left:0}

    .dashboard-main .dashboard-left .dash-subject{margin-left: 20px;}
    .dashboard-main .dashboard-left .dash-subject:first-child{margin-left:0px;}
    .dash-lecture.newDesign .newDesign .dash-lecture-date-title .newDesign{flex-direction: column; width: 100%;}
    .dash-lecture.newDesign .newDesign .dash-lecture-date-title .newDesign .dash-lec-title-hrs{padding-left: 0; border:0px none; margin-left: 0; margin-top: 8px;}
    .dash-lecture.newDesign .newDesign .dash-lec-hrs{margin-left: 0; padding-left: 0; border:0px none; margin-top: 8px;}
    
  }

  @media screen and (max-width: 770px) and (orientation: landscape){  
    .app-div.app-dashboard{left:0; width: calc(100%);}
    .dashboard-main .dashboard-left .dash-subject{margin-left: 20px;}
    .dashboard-main .dashboard-left .dash-subject:first-child{margin-left:0px;}
    .dashboard-main .dashboard-left .dashboard-schedule{display: flex; flex-direction: column; margin-left: 0; margin-bottom: 50px;}
    .dash-lecture {flex-direction: column;  padding: 10px;  background-color: #fff;  align-items: flex-start; }
    .app-div.mycoach-div {height: calc(100vh - 203px)}
    .app-div.app-dashboard, .app-div.app-studymaterial, .app-div.app-webinar{left:0; width:100%; height: calc(100vh - 60px);}
  }

  @media screen and (max-width: 812px) and (orientation: landscape){  
    .app-div.app-dashboard{left:0; width: calc(100%);}
    .dashboard-main .dashboard-left .dash-subject{margin-left: 20px;}
    .dashboard-main .dashboard-left .dash-subject:first-child{margin-left:0px;}
    .dashboard-main .dashboard-left .dashboard-schedule{display: flex; flex-direction: column; margin-left: 0; margin-bottom: 50px;}
    .dash-lecture {flex-direction: column;  padding: 10px;  background-color: #fff;  align-items: flex-start; }
    .app-div.app-dashboard .dashboard-schedule .viewall-btn {
          background-color: transparent!important;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          margin-bottom: 10px;
      }
      .app-div.app-dashboard, .app-div.app-studymaterial, .app-div.app-webinar{left:0; width:100%; height: calc(100vh - 60px);}
  }
/*Responsive Css ends here*/
.studioProjectWrapper{display: flex; width:100%; height: 100vh; font-family: Lato; padding-right:0px;}
.studioProjectWrapper .studioProjectContainer{display: flex; width:100%; flex-direction: column;}
.studioProjectWrapper .studioProjectContainer .filtersBlock{display: flex; width: 100%; justify-content: space-between; margin-bottom: 20px;}
.studioProjectWrapper .studioProjectContainer .filtersBlock > div {display: flex; align-items: center;}
.studioProjectWrapper .studioProjectContainer .filtersBlock div select{margin-right: 20px; min-width: 250px; max-width: 250px;}
.studioProjectWrapper .studioProjectContainer .filtersBlock div .multi-select{margin-right: 20px; min-width: 350px; max-width: 350px;}
.studioProjectWrapper .studioProjectContainer .filtersBlock div .multi-select .dropdown-container{width:100%; height: auto; background-color: var(--secondary-rgba); font-weight: normal; border: 1px solid var(--primary);}
.studioProjectWrapper .studioProjectContainer .filtersBlock div .multi-select .dropdown-content{display: flex;  flex-direction: column;}
.studioProjectWrapper .studioProjectContainer .filtersBlock h1{font-size: 20px; margin-bottom: 0;}
.studioProjectWrapper .studioProjectContainer .filtersBlock .addNewBtn{text-align: center; cursor: pointer; font-weight: 700; background-color: var(--primary); color:#fff; padding: 6px 20px 8px 20px; border-radius:40px; min-width: 130px;}

.studioProjectDialog.open{display: flex;}
.studioProjectDialog{position: fixed; z-index: 15; display: flex; display: none; left:0; font-family: Lato; right:0; bottom:0; top:0; align-items: center; justify-content: center; background-color: rgba(0,0,0,0.5);}
.studioProjectDialog .studioProjectDialogContainer{background-color: #fff; width:100%; max-width: 650px; border-radius:15px; padding:15px;}
.studioProjectDialog .studioProjectDialogContainer .formWrapper{display: flex; width:100%; margin-bottom: 25px;}
.studioProjectDialog .studioProjectDialogContainer .formWrapper.fullWidth .formBlock{max-width: none;}
.studioProjectDialog .studioProjectDialogContainer .formWrapper .formBlock{display: flex; margin-left: 15px; flex-direction: column; width: 100%; max-width: 50%;}
.studioProjectDialog .studioProjectDialogContainer .formWrapper .formBlock:first-child{margin-left: 0;}
.studioProjectDialog .studioProjectDialogContainer h2{font-size: 20px; margin-bottom: 0; padding-bottom: 5px; border-bottom:1px solid #ddd; margin-bottom: 20px;}
.studioProjectDialog .studioProjectDialogContainer label{font-size:16px; font-family: Lato; font-weight: 500; padding:0; margin:0 0 5px}
.studioProjectDialog .studioProjectDialogContainer .formWrapper input[type="text"]{font-family: Lato; border: 1px solid var(--primary); font-size: 16px; width:100%; color: var(--text-black); padding: 8px 14px; border-radius:6px; background-color: var(--secondary-rgba);}
.studioProjectDialog .studioProjectDialogContainer .formWrapper select{font-family: Lato; font-size: 16px; width:100%;     border: 1px solid var(--primary); color: var(--text-black); padding: 8px 14px; border-radius:6px; background-color: var(--secondary-rgba);}
.studioProjectDialog .studioProjectDialogContainer .formWrapper input[type="datetime-local"]{font-family: Lato; font-size: 16px;    border: 1px solid var(--primary);  width:100%; color: var(--text-black); padding: 8px 14px; border-radius:6px; background-color: var(--secondary-rgba);}
.studioProjectDialog .studioProjectDialogContainer .formActions{display: flex; width: 100%; justify-content: center; border-top:1px solid #ddd; padding-top: 10px;}
.studioProjectDialog .studioProjectDialogContainer .formActions span{text-align: center; margin-left: 20px; cursor: pointer; font-weight: 700; background-color: var(--primary); color:#fff; padding: 6px 20px 8px 20px; border-radius:40px; min-width: 130px;}
.studioProjectDialog .studioProjectDialogContainer .formActions span:first-child{margin-left: 0;}
.studioProjectDialog .studioProjectDialogContainer .formActions span.is_disabled{opacity: 0.4; pointer-events: none;}
.studioProjectDialog .studioProjectDialogContainer .formWrapper .multi-select{margin:0px;width:100%; max-width: 350px;}
.studioProjectDialog .studioProjectDialogContainer .formWrapper .multi-select .dropdown-container{width:100%; height: auto; background-color: var(--secondary-rgba); font-weight: normal; border: 1px solid var(--primary);}
.studioProjectDialog .studioProjectDialogContainer .formWrapper .multi-select .dropdown-content{display: flex;  flex-direction: column; padding:0px}
.studioProjectDialog .studioProjectDialogContainer .formWrapper .multi-select .dropdown-content input{padding: 0; margin:0;}
.studioProjectDialog .studioProjectDialogContainer .dropdown-content label{display:block; padding:0;}
.studioProjectDialog .studioProjectDialogContainer .dropdown-content .item-renderer{display:flex; font-family: Lato; font-size: 16px;}
.studioProjectDialog .studioProjectDialogContainer .dropdown-content .item-renderer input[type="text"]{margin:0 5px 0 0; width:auto;}
.studioProjectDialog .studioProjectDialogContainer .dropdown-content .item-renderer input[type="checkbox"]{margin:0 10px 0 0; width:auto;}
.studioProjectDialog .studioProjectDialogContainer .dropdown-content .panel-content{display: flex; flex-direction: column; padding:10px; width:100%; font-family: Lato; font-size: 14px; font-weight: normal;}
.studioProjectDialog .studioProjectDialogContainer .dropdown-content .panel-content .select-panel{display: flex; padding:10px; flex-direction: column; width:100%; font-family: Lato; font-size: 16px; font-weight: normal;}
.studioProjectDialog .studioProjectDialogContainer .dropdown-content .panel-content .select-panel .select-item{width:100%;margin-bottom: 10px;}
.studioProjectDialog .studioProjectDialogContainer .dropdown-content .panel-content span{text-align: left; display: flex; font-family: Lato; font-size: 16px; font-weight: normal;}
.studioProjectDialog .studioProjectDialogContainer .dropdown-content .panel-content label{margin:0 0px 0 0; padding: 10px; display: flex; align-items: flex-start; font-family: Lato; font-size: 16px; font-weight: normal;}
.studioProjectDialog .studioProjectDialogContainer .dropdown-content .panel-content ul{display: flex; flex-direction: column; width:100%}
.studioProjectDialog .studioProjectDialogContainer .dropdown-content .panel-content ul li{display: flex; width:100%; margin:0;}
.studioProjectDialog .studioProjectDialogContainer .dropdown-content .panel-content ul li label{display: flex; width:100%}


.studioProjectEditDialog.open{display: flex;}
.studioProjectEditDialog{position: fixed; z-index: 15; display: flex; display: none; left:0; font-family: Lato; right:0; bottom:0; top:0; align-items: center; justify-content: center; background-color: rgba(0,0,0,0.5);}
.studioProjectEditDialog .studioProjectDialogContainer{background-color: #fff; width:100%; max-width: 650px; border-radius:15px; padding:15px;}
.studioProjectEditDialog .studioProjectDialogContainer .formWrapper{display: flex; width:100%; margin-bottom: 25px;}
.studioProjectEditDialog .studioProjectDialogContainer .formWrapper.fullWidth .formBlock{max-width: none;}
.studioProjectEditDialog .studioProjectDialogContainer .formWrapper .formBlock{display: flex; margin-left: 15px; flex-direction: column; width: 100%; max-width: 50%;}
.studioProjectEditDialog .studioProjectDialogContainer .formWrapper .formBlock:first-child{margin-left: 0;}
.studioProjectEditDialog .studioProjectDialogContainer h2{font-size: 20px; margin-bottom: 0; padding-bottom: 5px; border-bottom:1px solid #ddd; margin-bottom: 20px;}
.studioProjectEditDialog .studioProjectDialogContainer label{font-size:16px; font-family: Lato; font-weight: 500; padding:0; margin:0 0 5px}
.studioProjectEditDialog .studioProjectDialogContainer .formWrapper input[type="text"]{font-family: Lato; border: 1px solid var(--primary); font-size: 16px; width:100%; color: var(--text-black); padding: 8px 14px; border-radius:6px; background-color: var(--secondary-rgba);}
.studioProjectEditDialog .studioProjectDialogContainer .formWrapper select{font-family: Lato; font-size: 16px; width:100%;     border: 1px solid var(--primary); color: var(--text-black); padding: 8px 14px; border-radius:6px; background-color: var(--secondary-rgba);}
.studioProjectEditDialog .studioProjectDialogContainer .formWrapper input[type="datetime-local"]{font-family: Lato; font-size: 16px;    border: 1px solid var(--primary);  width:100%; color: var(--text-black); padding: 8px 14px; border-radius:6px; background-color: var(--secondary-rgba);}
.studioProjectEditDialog .studioProjectDialogContainer .formActions{display: flex; width: 100%; justify-content: center; border-top:1px solid #ddd; padding-top: 10px;}
.studioProjectEditDialog .studioProjectDialogContainer .formActions span{text-align: center; margin-left: 20px; cursor: pointer; font-weight: 700; background-color: var(--primary); color:#fff; padding: 6px 20px 8px 20px; border-radius:40px; min-width: 130px;}
.studioProjectEditDialog .studioProjectDialogContainer .formActions span:first-child{margin-left: 0;}
.studioProjectEditDialog .studioProjectDialogContainer .formWrapper .multi-select{margin:0px;width:100%; max-width: 350px;}
.studioProjectEditDialog .studioProjectDialogContainer .formWrapper .multi-select .dropdown-container{width:100%; height: auto; background-color: var(--secondary-rgba); font-weight: normal; border: 1px solid var(--primary);}
.studioProjectEditDialog .studioProjectDialogContainer .formWrapper .multi-select .dropdown-content{display: flex;  flex-direction: column; padding:10px}
.studioProjectEditDialog .studioProjectDialogContainer .formWrapper .multi-select .dropdown-content input{padding: 0; margin:0;}
.studioProjectEditDialog .studioProjectDialogContainer .dropdown-content label{display:block; padding:0;}
.studioProjectEditDialog .studioProjectDialogContainer .dropdown-content .item-renderer{display:flex; font-family: Lato; font-size: 16px;}
.studioProjectEditDialog .studioProjectDialogContainer .dropdown-content .item-renderer input[type="text"]{margin:0 5px 0 0; width:auto;}
.studioProjectEditDialog .studioProjectDialogContainer .dropdown-content .item-renderer input[type="checkbox"]{margin:0 10px 0 0; width:auto;}
.studioProjectEditDialog .studioProjectDialogContainer .dropdown-content .panel-content{display: flex; flex-direction: column; padding:10px; width:100%; font-family: Lato; font-size: 14px; font-weight: normal;}
.studioProjectEditDialog .studioProjectDialogContainer .dropdown-content .panel-content .select-panel{display: flex; padding:10px; flex-direction: column; width:100%; font-family: Lato; font-size: 16px; font-weight: normal;}
.studioProjectEditDialog .studioProjectDialogContainer .dropdown-content .panel-content .select-panel .select-item{width:100%;margin-bottom: 10px;}
.studioProjectEditDialog .studioProjectDialogContainer .dropdown-content .panel-content span{text-align: left; display: flex; font-family: Lato; font-size: 16px; font-weight: normal;}
.studioProjectEditDialog .studioProjectDialogContainer .dropdown-content .panel-content label{margin:0 0px 0 0; padding: 10px; display: flex; align-items: flex-start; font-family: Lato; font-size: 16px; font-weight: normal;}
.studioProjectEditDialog .studioProjectDialogContainer .dropdown-content .panel-content ul{display: flex; flex-direction: column; width:100%}
.studioProjectEditDialog .studioProjectDialogContainer .dropdown-content .panel-content ul li{display: flex; width:100%; margin:0;}
.studioProjectEditDialog .studioProjectDialogContainer .dropdown-content .panel-content ul li label{display: flex; width:100%}


.projectsListingWrapper{display: flex; width: 100%;}
.projectsListingWrapper ol{display: flex; width: 100%; margin:0; padding:0; display: grid; grid-gap: 30px; margin-top: 30px; grid-template-columns: repeat(auto-fill,minmax(30%,1fr)); padding-bottom: 40px;}
.projectsListingWrapper ol li{display: flex; width: 100%;  border-radius:15px; background-color: var(--secondary-rgba); padding:20px; box-shadow: 1px 1px 6px 0 #eee;}
.projectsListingWrapper .nodatafound{display: flex; margin-top: 50px; font-size: 18px; padding:30px 20px; border-radius:15px; background-color:var(--primary-rgba); border:1px solid #ddd; width: 100%; justify-content: center; align-items: center;}
.projectsListingWrapper ol li .projectThumb{display: flex; width: 100%; max-width: 80px; margin-right: 15px;}
.projectsListingWrapper ol li .projectContent{display: flex; flex:1 1; align-items: flex-start;}
.projectsListingWrapper ol li .projectContent .contentLeft{display: flex; flex-direction: column; flex:1 1}
.projectsListingWrapper ol li .projectContent .contentLeft div{margin-top: 10px; font-weight: 700;}
.projectsListingWrapper ol li .projectContent .contentLeft div span{margin-left: 5px; font-weight: 500;}
.projectsListingWrapper ol li .projectContent .contentLeft .Batches +  div{background-color: #fff; padding:5px;}
.projectsListingWrapper ol li .projectContent .actions{display: flex; margin-top: 0; width:auto;}
.projectsListingWrapper ol li .projectContent .actions span{display: flex; margin-left: 15px;}
.projectsListingWrapper ol li .projectContent .actions span:first-child{margin-left: 0;}
.projectsListingWrapper ol li .projectContent .actions span img{max-width: 20px;}
.projectsListingWrapper ol li .projectContent .contentLeft .batchList span{margin-left:0;}


.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:last-child{width: 140px; justify-content: flex-end; padding:0}
.app-body.studioProjectLearner .app-div {
    width: auto;
    padding: 10px 20px 25px 20px;
    top: 50px;
    left: 150px;
    height: calc(100vh - 55px);
}
.learnerView{display: flex; width: 100%; font-family: Lato;  margin-top: 40px;}
.learnerView h3{font-size: 22px; margin-bottom: 15px;}
.learnerView .projectDate{font-size: 14px; margin-bottom: 10px; font-weight: 700;}
.learnerView .projectDate span{margin-left: 5px; font-weight: 400;}
.learnerView .viewLeft{display:flex; flex:1 1; flex-direction: column;}
.learnerView .viewRight{display:flex; max-width: 500px; width: 100%; flex-direction: column;}
.learnerView .viewRight .uploadBlock{border:1px solid var(--primary); cursor: pointer; padding:35px; border-radius:5px; background-color: #fff; display: flex; align-items: center; justify-content: center;}
.learnerView .viewRight .uploadBlock img{max-width: 100px;}
.learnerView .viewRight .uploadBlock span{text-align: center;}
.learnerView .viewRight div{margin-top: 10px; align-items: center; text-align: center; width:100%; display: flex; justify-content: center;}
.learnerView .viewRight div button{margin-left: 10px;}

.userListWrapper{display: flex; width: 100%; background-color:var(--primary-rgba); padding:20px; border-radius:10px; margin-top: 15px;}
.userListWrapper:first-child{margin-top: 0;}
.userListWrapper .userListLeft{display: flex; align-items: flex-start; flex:1 1}
.userListWrapper .userListLeft .userListThumb{display: flex; background-color: #fff; flex-shrink: 0; width: 100%; max-width: 100px; min-height: 100px;  max-height: 100px; border:1px solid var(--primary); border-radius:100%; margin-right: 15px;}
.userListWrapper .userListLeft .userListThumb img{max-width:100%; border-radius:100%}
.userListWrapper .userListLeft .userListContent{display: flex; flex-direction: column;}
.userListWrapper .userListLeft h3{margin-bottom: 10px;}
.userListWrapper .userListLeft p{margin:0; margin-bottom:10px; font-weight: 700;}
.userListWrapper .userListLeft p span{margin-left: 10px; font-weight: 500;}
.userListWrapper .userListRight{display: flex; flex-direction: column;}
.userListWrapper .userListRightActions{display: flex; flex-direction: column;}
.userListWrapper .userListRight .marks{margin-top: 15px; display: flex; align-items: center; justify-content: flex-end;}
.userListWrapper .userListRight .marks span{margin-right: 10px; font-weight: 700;}
.userListWrapper .userListRight .marks input[type="text"]{border:1px solid #ddd; border-radius:5px; padding: 8px 10px; width:100%; max-width:80px;}
.userListWrapper .userListRight .marks input[type="number"]{border:1px solid #ddd; border-radius:5px; padding: 8px 10px; width:100%; max-width:80px;}
.userListWrapper .userListRight .marks input::-webkit-outer-spin-button,
.userListWrapper .userListRight .marks input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.userListWrapper .userListRightActions a{font-family: Source Sans Pro; font-size: 18px;  font-weight: 600;   border-radius: 40px;   padding: 6px 20px 8px 20px;   background-color: var(--primary);   color: var(--white);}
.userListWrapper .userListRight .marks button{border-radius:5px; margin-left: 5px;}
.userListWrapper .userListRight .userFile{display: flex; align-items: center;}
.userListWrapper .userListRight .userFile i{margin-right: 10px; width:100%; max-width: 20px;}
.userListWrapper .userListRight .userFile i img{max-width: 100%}
.userListWrapper .userListRight .userFile a{display: flex; align-items: center; margin-left: 10px;}
.nodatafound{display: flex; margin-top: 50px; font-size: 18px; padding:30px 20px; border-radius:15px; background-color:var(--primary-rgba); border:1px solid #ddd; width: 100%; justify-content: center; align-items: center;}
ul.pagination{display: flex!important; width: 100%; justify-content: center; align-items: center; font-family: Source Sans Pro; }
ul.pagination li{display: flex; width:auto; justify-content: center; align-items: center; margin:0 2px}
ul.pagination li a{border-radius:5px}
ul.pagination li.active a{color:var(--white)}
.supportPageWrapper{display: flex; width:100%;  font-family: Lato;}
  .supportPageWrapper .supportPageContainer {display: flex; flex-direction: column; width: 100%;}
  .supportPageWrapper .supportPageContainer h3{font-weight: 700; line-height: normal; line-height: initial;   font-family: Lato; font-size:22px; margin:20px 0 15px; color:#515151;}
  .supportPageWrapper .supportPageContainer p{margin-bottom: 10px; font-size:16px; font-weight: 700; color: #000;  font-family: Lato;}
  .supportPageWrapper .supportPageContainer .tableWrapper{display: flex; font-size:15px; margin-top: 15px; width: 100%;}
  .supportPageWrapper .supportPageContainer  ol {display: flex; flex-direction: column; margin:0; padding:0}
  .supportPageWrapper .supportPageContainer  ol li  { margin-top: 10px; font-size:16px; list-style-position: inside; display: list-item; list-style-type: auto;}
  .supportPageWrapper .supportPageContainer .tableWrapper table{border-collapse: collapse; width: 100%}
  .supportPageWrapper .supportPageContainer .tableWrapper table th{padding:5px 10px; border: 1px solid #ddd;}
  .supportPageWrapper .supportPageContainer .tableWrapper table td{padding:10px 10px; bordeR: 1px solid #ddd;}
  .supportPageWrapper .supportPageContainer .tableWrapper table td a{color: var(--primary); text-decoration: underline;}
  .supportPageWrapper .supportPageContainer .tableWrapper table td a:hover{color: var(--primary); text-decoration: none;}

  @media screen and (max-width: 1280px){
  .support-page .app-div{background-color: #fff; border-radius:0; height: calc(100vh - 120px);}
  }
  @media screen and (max-width:700px){
  .supportPageWrapper .tableWrapper{overflow-x: auto; white-space:nowrap;}
  .supportPageWrapper .supportPageContainer h3{font-size: 20px;}
  }
.viewAttendanceReportsWrapper .calendarView.hide{display: none;}
.viewAttendanceReportsWrapper .header-op{display: flex; position: sticky; top:0; z-index: 2; background-color:#fff; border-bottom:1px solid #ddd; margin-bottom: 10px; align-items: center; grid-gap:10px; gap:10px; width: 100%; justify-content: space-between;}
.viewAttendanceReportsWrapper .header-op .currentDate{color: var(--text-black); font-family: Lato; font-size: 19px; font-weight: 700;}
.viewAttendanceReportsWrapper .header-op .react-calendar-date span{margin:0}
.viewAttendanceReportsWrapper .header-op .currentDate > div{display: flex; align-items: center;}
.viewAttendanceReportsWrapper .header-op .calenderBlock{display: flex; align-items: center; grid-gap:20px; gap:20px;}
.viewAttendanceReportsWrapper .header-op .filterBlock{display: flex; align-items: center; grid-gap:10px; gap:10px;}
.viewAttendanceReportsWrapper .header-op .filterBlock select{min-width: 170px; max-width: 170px;}
.viewAttendanceReportsWrapper .header-op .filterBlock label{margin: 0; font-weight: 700; font-family: Lato; font-size: 16px;}
.viewAttendanceReportsWrapper .calendarView{display: flex; width: 100%;}
.viewAttendanceReportsWrapper .react-calendar{margin-top: 50px;}
.app-body.attendance-report-page .app-div{padding-top: 0;}
.viewAttendanceReportsWrapper .calendarView .react-calendar__tile:hover abbr{color:#fff;}
.viewAttendanceReportsWrapper .calendarView .react-calendar__tile.react-calendar__tile--active:hover abbr{color:#fff;}
.viewAttendanceReportsWrapper .calendarView .react-calendar__tile.react-calendar__tile--now abbr{color:var(--text-black);}
.viewAttendanceReportsWrapper .calendarView .react-calendar__tile.react-calendar__tile--now:hover abbr{color:#fff;}
.viewAttendanceReportsWrapper{display: flex; width: 100%; flex-direction: column;  font-family: Lato; font-size: 14px; color: var(--text-black);}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer{display: flex; width: 100%; flex-direction: column;  font-family: Lato; font-size: 14px; color: var(--text-black);}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing{display: flex; width: 100%;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing ol{display: flex; grid-gap: 30px; gap: 30px; width: 100%; flex-direction: column; margin:0; padding:0;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing ol li .attendanceTop{display: flex; grid-gap:10px; gap:10px; width: 100%;padding: 10px; flex-direction: column;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing ol li{display: flex; width: 100%; grid-gap:40px; gap:40px;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing ol li .attendanceDate{display: flex; min-width: 100px; flex-direction: column; align-items: center; justify-content: center;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing ol li .attendanceDate .date{font-family: Lato; font-size: 19px; font-weight: 700; color: var(--text-black);}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing ol li .attendanceContent{display: flex; flex-wrap: wrap;  justify-content: space-between; grid-gap:20px; gap:20px; flex-direction: row; height: auto;  padding:5px; box-shadow: 0px 0px 0px 0 #eee; border-radius:0px;width: auto; max-width: none;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing ol li .attendanceContent .attendanceActionsMain{display: flex;     box-shadow: 1px 1px 10px 0 #eee; border: 1px solid var(--secondary-rgba);  justify-content: space-between; min-width: 250px; max-width: 250px; grid-gap: 20px; gap: 20px; flex-direction: column; background-color: #fff; padding: 0px; border-radius:5px 5px;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing ol li .attendanceContent .title{font-family: Lato; font-size: 19px; font-weight: 700; color: var(--text-black);}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing .attendanceActions{display: flex; justify-content: space-between; padding: 10px; width: 100%; grid-gap:0px; gap:0px;     background-color: #f5f5f5;    border-top: 1px solid #e8e0e0; padding:0px; border-radius:0 0px 5px 5px;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing .attendanceActions .classWise{display: flex; justify-content: center; align-items: center; flex-direction: column; grid-gap:5px; gap:5px; padding: 15px; max-width: 50%; width: 100%;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing .attendanceActions .classWise.present{border-radius: 5px; border:0px solid green}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing .attendanceActions .classWise.absent{border-radius: 5px; border:0px solid #f68e8e}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing .attendanceActions .classWise .present{padding:0px; border-radius: 5px; color: green;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing .attendanceActions .classWise .absent{padding:0px; border-radius: 5px; color: #f68e8e;}


.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing .attendanceActions .liveClasswise .present{padding:0px; border-radius: 5px; color: green;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing .attendanceActions .liveClasswise .absent{padding:0px; border-radius: 5px; color: #f68e8e;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing .attendanceActions .liveClasswise{display: flex; justify-content: center;     max-width: 50%; width: 100%;  align-items: center; border-left:1px solid #ddd; flex-direction: column; grid-gap:5px; gap:5px; padding: 15px; }
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing .attendanceActions label{margin:0; padding:0; font-family: Lato; font-weight: 700; font-size: 14px; color: var(--text-black);}
.viewAttendanceReportsWrapper .noDataFound{display: flex; width: 100%; padding:30px; align-items: center; justify-content: center;}
.viewAttendanceReportsWrapper .viewAttendanceReportsContainer .attendanceReportListing .attendanceActions label img{max-width: 28px;}
.blog-post-container {
    background-color: #fff;
}

.action-btn {
    display: flex;
    justify-content: space-between;


}

.app-div.blog-post-container.faculty {
    left: 250px
}

.app-div.blog-post-container {
    left: 150px;
    top: 65px;
    height: calc(100vh - 90px);
}


.app-div.blog-post-container .action-btn {
    border-bottom: 1px solid #dadada;
    padding-bottom: 10px;
}

.app-div.blog-post-container .action-btn h1 {
    margin-bottom: 0;
}

.studioProjectDialog .studioProjectDialogContainer {

    position: relative;
    max-width: 850px;
}

.studioProjectDialog .editor {
    height: 40% !important;
}

.close {
    top: 8px !important;
    right: 15px !important;
    position: absolute;
    padding: 0px;
}



input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
}



.editor-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.editor-form h3 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.editor-form .block {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 8px;
    gap: 8px;
}

.editor-form .block label {
    margin: 0;
    padding: 0;
    color: #999
}

.editor-form .block select {
    bordeR: 1px solid #ccc;
    font-size: 14px;
    background-color: transparent;
}

.editor-form .block input {
    bordeR: 1px solid #ccc;
    font-size: 14px;
    background-color: transparent;
    margin: 0;
}

.editor-form .block .ql-editor p {
    font-size: 14px;
    font-style: normal;
}

.editor-form .block .ql-editor.ql-blank::before {
    font-style: normal;
}

.editor-form .block .noteText {
    text-align: right;
}

.ql-editor {
    min-height: 100px !important;
    max-height: 100px;
}

.submit-btn {
    margin: 10px 0px;
}

.blog-post-container img {
    margin-top: 10px;
}

h1 {
    font-size: 1.4rem !important;
}

h1.marginBottomZero {
    margin-bottom: 0;
}

.add-blog {
    height: 40px !important;
}

.submit-btn {
    margin: 0px auto;
    margin-top: 10px;
}

.blog-list table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1em;
    font-family: 'Arial', sans-serif;
    min-width: 400px;

}

.blog-post-container .action-btn .actions {
    width: auto;
}

.blog-post-container .action-btn .actions select {
    max-width: 250px;
}

.blog-list th,
.blog-list td {
    padding: 12px 15px;
    text-align: left;
}

.blog-list th {

    text-transform: uppercase;
}

.blog-list tr {
    border-bottom: 1px solid #dddddd;

}

.blog-list td:nth-child(2),
.blog-list td:last-child {
    cursor: pointer;
}

.blog-list tr:nth-of-type(even) {
    background-color: #f3f3f3;
}



/* Additional styles to align the user name at the end */
.blog-list .user-name {
    text-align: right;
    /* Align text to the right */
    width: 100%;
    /* Ensure it takes up remaining space */
}

.preview-title {
    margin: 10px 0px;
}

.selectedBlogContent {
    max-width: 900px !important;



}

.selected-content {

    overflow: auto;
    max-height: 300px !important;
}

.jodit-wysiwyg {
    max-height: 200px;
}

.jodit-source,
.jodit-source .jodit-source__mirror-fake,
.jodit-container:not(.jodit_inline) {
    max-height: 200px;
}

.jodit_fullsize-box_true {
    z-index: 2000000000000 !important;
}

.flex {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

.jodit-container:not(.jodit_inline) .jodit-workplace {
    max-height: 100px !important;
}
.AluminiSpeakWrapper{display: flex; flex-direction: column; width: 100%;}
.AluminiSpeakWrapper ol{margin:40px  0 ; padding:0; display: flex; width: 100%;}
.AluminiSpeakWrapper ol li{margin:0; padding:0; display: flex; width: 100%; max-width:400px; border:1px solid #ddd; border-radius: 24px;}
.AluminiSpeakWrapper ol li iframe{border-radius: 24px;}
.container {
    text-align: start;
    margin-top: 60px;
    color: white;
    padding: 0 0px;
    margin: 0 0 20px;
}

.app-body {
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 140px;
    right: 300px;
    top: 0;
    background-color: var(--white);
}

.app-div.widthAuto {
    left: 150px
}

.marginTopZero {
    margin-top: 0
}


h2 {
    font-weight: 600;
    line-height: 1.4;
    font-family: Source Sans Pro;
    font-size: 19px;
    color: black;
    margin-left: 5px;

}

.flex-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 40px;
    gap: 40px;


}


.certificate {
    background: #f0f0f0;
    margin: 0px;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative;
    /* Required for aspect ratio hack */
  
    width: 100%;
    max-width: 400px;
}

.certificate img{max-width: 100%;}

.iframe-container {
    width: 100%;
    height: 200px;
    border: none;
    object-fit: contain;
}

.iframe-container img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.iframe-container {
    height: 650px;
    width: 100%;
}



.university-list-container {
  padding: 20px;
  background-color: #f9f9f9;
 height: 700px;
 margin-left: 200px;
 object-fit: contain;

  overflow-x: hidden;
}


.university-list-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 1.5rem;
}


.university-list {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 10px;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
}


.university-card {
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-snap-align: start;
  padding: 20px;
}


.university-logo {
  width: 100%;
  height: 150px;
  object-fit: contain;
  background-color: #f5f5f5;
  padding: 20px;
}


.university-details {
  padding: 15px;
  text-align: center;
  width: 100%;
}


.university-details h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}


.programs-container {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
}

.programs-list {
  width: 800px;
  display: flex;
  flex-direction: row;
  grid-gap: 15px;
  gap: 15px;
  padding-bottom: 10px;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
}


.program-card {



  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  text-align: bottom;
  grid-gap: 10px;
  gap: 10px;
}


.program-logo {
  width: 60px;
  height: 60px;
  object-fit: cover;
}


.program-info {
  display: inline-flex;
  flex-direction: column;
}

.program-info h4 {
  font-size: 1rem;
  margin: 0;
  margin-bottom: 5px;
}

.program-info p {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}
.popup-content {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    max-width: 500px;
    margin: auto;
}

.input-container {
    margin-top: 20px;
}

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.input-field {
    width: 48%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.file-upload {
    width: 48%;
    padding: 8px;
}

.add-new-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.close {
    cursor: pointer;
    font-size: 1.5em;
    float: right;
}
.noticeboard-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
    gap: 20px;
    padding: 20px;
}

.card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.card-image img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.card-content {
    padding: 15px;
}

.card-content h3 {
    margin: 0;
    font-size: 1.2em;
}

.card-content p {
    margin: 10px 0;
}

.card-actions {
    padding: 15px;
    /* background-color: #2DA77D; */
    
    text-align: center;
}

.card-actions a {
    color: #007bff;
    text-decoration: none;
}



/* Overall layout */
.app-body {
    padding: 20px;

    min-height: 100vh;
  }
  

  /* Container and row styling */
  .container-fluid {
    padding: 20px;
  }
  
  .row {
    margin: 0;
  }
  
  /* Sidebar styling */
  .sidebar {

    padding: 15px;
    border-radius: 8px;
  }
  
  .sidebar h2 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  

  li{
    font-size: 18px;
    margin: 10px 0px;
    font-weight: 700;
    font-family: 'Times New Roman', Times, serif;
  }
  /* Video list styling */
  .list-group-item {
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .list-group-item:hover {
    background-color: #e9ecef;
  }
  
  .list-group-item.active {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  /* Main content area */
  .main-content {
    padding: 15px;
  }
  
  /* ReactPlayer styling */
  .react-player {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .col-md-3, .col-md-9 {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .react-player {
      height: 300px !important;
    }
  }
.export-file{
  margin-top: 10px;
  cursor: pointer;
}

.export-file img{
  width: 25px;
}

.export-file span{
  font-family: lato;
  font-size: 20px;
  border: 1px solid white;
  padding: 14px;
}


.facultyReportsWrapper{display: flex; width: 100%; font-family: lato;}
.facultyReportsWrapper .facultyReporttsContainer{display: flex; width: 100%; position: relative; flex-direction: column;}

.facultyReportsWrapper .filtersWrapper {display:flex; flex-wrap: wrap; width: 100%; flex-direction: column;}
.facultyReportsWrapper .filtersWrapper .labelWrapper{display: flex; align-items: center; flex-wrap: wrap; margin:10px 0 0px;  grid-gap: 20px;  gap: 20px; border-top:1px solid #ddd; padding-top: 20px; width: 100%;}
.facultyReportsWrapper .filtersWrapper .labelBlock{display: flex; grid-gap:5px; gap:5px; width: 100%; max-width: 200px; flex-direction: column; align-items: flex-start; margin: 0 0px 0px 0;}
.facultyReportsWrapper .filtersWrapper .labelBlock label{margin: 0; padding: 0;}
.facultyReportsWrapper .assessmentsReportsActions{border-top:1px solid #ddd; display: flex; margin-top: 10px; padding-top: 10px; justify-content: flex-end; align-items: center;}
.facultyReportsWrapper .assessmentsReportsDownload{border-top:0px solid #ddd; display: flex; margin: 10px 0 10px; padding: 10px 0; justify-content: flex-end; align-items: center;}
.facultyReportsWrapper .assessmentsTableWrapper{display: flex; flex-direction: column; width: 100%; margin-top: 30px;}
.facultyReportsWrapper .assessmentsTableWrapper h2{ border-bottom:0px solid #ddd; padding-bottom: 10px;}
.facultyReportsWrapper .assessmentsTableWrapper table{width:100%; border:1px solid #ddd; border-collapse: collapse;}
.facultyReportsWrapper .assessmentsTableWrapper table th{padding:10px 10px; text-transform: capitalize; border:1px solid #ddd; width:auto; min-width: 150px; text-align: left;}
.facultyReportsWrapper .assessmentsTableWrapper table td{padding:10px 10px; border:1px solid #ddd; width:auto; min-width: 150px; text-align: left;}
.facultyReportsWrapper .assessmentsTableWrapper table th:first-child{min-width: 70px;}
.facultyReportsWrapper .assessmentsTableWrapper table td:first-child{min-width: 70px;}
.facultyReportsWrapper .assessmentsTableWrapper table tr:nth-child(odd) td{background-color: #eeeded;}
.facultyReportsWrapper .noReportsFound{border:1px solid #ddd; padding: 10px; margin-top: 30px; text-align: center;background-color:var(--secondary-rgba);}
.facultyReportsWrapper .assessmentsReportsContainer .reportsLoader{position: absolute; opacity: 0; visibility:hidden; left:0;  right:0; bottom:0; top:0; z-index: 220000; background-color: rgba(0,0,0,0.1); display: flex; align-items: center; justify-content: center;}
.facultyReportsWrapper .assessmentsReportsContainer .reportsLoader.show{opacity: 1; visibility: visible;}

.assessmentsReportsWrapper .assessmentsReportsContainer .reportsLoader span {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid var(--primary); /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.previewStudentQueryDialog.open{display: flex;}
.previewStudentQueryDialog{position: fixed; background-color: rgba(0,0,0,0.1); display: flex;  font-family: lato; display: none; align-items: center; justify-content: center; top:0; left:0; right:0; bottom:0; z-index: 12;}
.previewStudentQueryDialog .previewStudentQueryContainer{border-radius:10px; display: flex; flex-direction: column; grid-gap:15px; gap:15px; position: relative; background-color: #fff; padding:15px; width: 100%; max-width: 850px; box-shadow: 4px 4px 12px -6px var(--text-black2);}
.previewStudentQueryDialog .previewStudentQueryContainer h3{border-bottom:1px solid #ddd; display:flex; justify-content: space-between; margin-bottom:0px; padding-bottom: 5px;}
.previewStudentQueryDialog .previewStudentQueryContainer h4{border-bottom:1px solid #ddd; display:flex; justify-content: space-between; margin:0px; font-size: 16px; padding-bottom: 5px;}
.previewStudentQueryDialog .previewStudentQueryContainer h3 span{cursor: pointer;}
.previewStudentQueryDialog .dialogClose{position: absolute; cursor: pointer; right:-5px; top:-10px; width: 24px; background-color: #fff; color: #000; height: 24px; display: flex; align-items: center; justify-content: center; border-radius: 100%;}
.previewStudentQueryDialog .block{display: flex; flex-direction: column; grid-gap:10px; gap:10px; width: 100%;}
.previewStudentQueryDialog .block label{margin:0; padding:0; display: flex;  font-size: 14px; color:var(--text-black2);align-items: center; grid-gap:10px; gap:10px;}
.previewStudentQueryDialog .block label span{ color:#999;}
.previewStudentQueryDialog .block1{display: flex; grid-gap:20px; gap:20px; width: 100%;}
.previewStudentQueryDialog .block1 .imagePreview{max-width: 200px; width: 100%; flex-shrink: 0;}
.previewStudentQueryDialog .block1 .description{width:calc(100% - 220px); color:#a4a2a2; font-size: 14px;  flex-wrap: wrap; flex:1 1; word-wrap: break-word; white-space: normal;}
.previewStudentQueryDialog .block textarea{width: 100%; padding: 10px; border:1px solid #ddd; min-height: 100px; border-radius:5px; font-size: 14px; font-family: lato;}
.previewStudentQueryDialog .reply-list{display: flex; grid-gap:20px; gap:20px; width: 100%; flex-direction: column; overflow-y: auto; max-height: 100px;}
.previewStudentQueryDialog .reply-list .replyblock{display: flex; grid-gap:15px; gap:15px;  width: 100%; }
.previewStudentQueryDialog .reply-list .replyblock .reply{display: flex; grid-gap:15px; gap:15px;  width: 100%; }
.previewStudentQueryDialog .reply-list .replyblock .reply p{margin: 0; }
.previewStudentQueryDialog .reply-list .replyblock .reply img{margin: 0; }
.previewStudentQueryDialog .reply-list .replyblock .reply .post-doubt-img{width: 80px; margin-top:0; height: auto;}
.previewStudentQueryDialog .reply-list .replyblock .reply .post-doubt-img img { margin-top: 0; object-fit: contain; max-width: 100%;}


.login-div {
	display: flex;
	align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 100%; 
}

.forgot-pw {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 0px;
    margin-bottom: 30px;
	position: fixed;
	bottom:0;
	left:0;
	margin:0 auto;
	right: 0;
	top:0;
	align-items: center;
}

.forgot-left{display: flex; align-items: center;}
.button-class{
	display: flex;
}

.login_left {
	overflow: hidden;
	top: 0;
	left: 0;
	position: fixed;
	min-height: 100%;
	min-width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--secondary-rgba);
	background-color: var(--white);
	padding-bottom: 50px;
}

.forgot-left {
	background-color: var(--primary-rgba);
    width: 50%;
}

.forgot-image img{
	width: 100%;
    height: auto;
    margin-top:0px;
}


.login_left .rec-item-wrapper {
  height: 100%;
}

.forgot-left .rec-item-wrapper {
  height: 100%;
}

.login_left .rec-item-wrapper .rec {
  height: 100%;
}

.forgot-left .rec-item-wrapper .rec {
  height: 100%;
}

.login_left  .rec-swipable-null {
  height: 100%;
}

.forgot-left  .rec-swipable-null {
  height: 100%;
}

.login_left items {
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center;
  height: 500px;
  height:auto;
}

.login_left items img {
  width: auto;
  height: 250px;
  height:auto;
  max-width: 100%;
}

.login_left .rec-pagination {
  z-index: 1;
  position: fixed; 
  bottom: 45px
}

.login_left .rec-pagination .rec-dot{opacity: 0.50; border-radius:10px; width: 15px; height:12px; box-shadow:0px 0px 0px 0px;	background-color:var(--primary-rgba);}
.login_left .rec-pagination .rec-dot.rec-dot_active{opacity: 1; width: 36px; background-color: var(--primary);}

.login_left .rec-item-wrapper:focus{box-shadow:0px 0px 0px 0px; outline:0px none;}
.login_left .rec div:focus{box-shadow:0px 0px 0px 0px; outline:0px none;}
.login_left .rec:focus{box-shadow:0px 0px 0px 0px; outline:0px none;}

.login_left .rec-slider-container:focus{outline:0px none;}
.login_left .rec-slider:focus{outline:0px none;}
.login_left button:disabled{  color: var(--primary-rgba) !important; box-shadow:0px 0px 0px 0px; background-color: transparent!important;}
.login_left .rec-arrow-left {
  display: none !important;
}
.login_left .rec-arrow-right {
  display: none !important;
}

.login-image {
	width: auto;
	text-align: center;
}

.login-image img {
	width: 100%;
}

.forgot-image{
	height: auto;
	overflow: hidden;
	margin-top:0px !important;
}

.login-title {
	margin-top: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	font-family:Lato;
  font-size: 38px;
  font-weight: 600;
  color: var(--primary);
  line-height: 1.24;
}

.login-title span {
	margin-top: 15px;
	font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: var(--text-black);
  max-width: 520px;
}

.top_login_left {
	width: 180px;
	position: absolute;
	top: 0;
	right: -30px;
}

.bottom_login_left {
	width: 90px;
	position: absolute;
	bottom: 0;
	left: 0px;	
}




.login_right {
	overflow: hidden;
	top: 0;
	right: 0;
	position: fixed;
	min-height: 100%;
	min-width: 50%;
	padding-bottom:0px;
	display: flex;
	flex-direction: column;
	align-items: center;
  justify-content: center;
  background-color: var(--white);
}


.login-form-outer{
	box-shadow: 3px 0px 15px 0px rgba(82,80,82,0.69);
	background-color:#fff;
	border-radius:10px;
	padding: 25px;
	width: 100%;
	max-width: 430px;
	
}

.registerForm{padding:20px; font-family: Lato; margin-top: 0px;}

.loginWithUserNameLink{width:100%; font-family: Lato; cursor: pointer; padding:15px 15px 15px; font-size: 18px; text-align: center; font-weight: 600; color: var(--primary);}

.loginWithUserNameLink span{padding-bottom:2px; border-bottom:1px solid var(--primary); }
.loginWithUserNameLink:hover span{border-bottom:1px solid transparent}

.signUpLink{width:100%; font-family: Lato; cursor: pointer; padding:15px 15px 15px; text-align: center;}
.signUpLink span{padding-bottom:2px; border-bottom:1px solid var(--primary); font-size: 18px; text-align: center; font-weight: 600; color: var(--primary);}
.signUpLink:hover span{border-bottom:1px solid transparent}
.signUpLink p{font-size: 16px; margin:0 0 5px; text-align: center; font-weight: 400; color: var(--black);}
.loginWithOTPLink{width:100%; font-family: Lato; cursor: pointer; padding: 15px  15px 15px; font-size: 18px; text-align: center; font-weight: 600; color: var(--primary);}



.forgot-right {
	background-color: white;
	padding-top:0px;
	width: 50%;

}

.forgotWrapper{display: flex; align-items: center; background-color: var(--white); padding: 20px; border-radius:20px; justify-content: center; width:100%; max-width: 850px;}

.login_header {
	width: 100%;
	margin-bottom: 0px;
	margin-bottom: 30px;
	margin-top:40px;
	display: flex;
	justify-content: center;
}
.login_header span { 
	display: flex; 
	align-items: center; 
	justify-content: center; 
	width: auto; 
	height:63px; 
	border-radius: 40px;
	background-color: transparent;
	
  }
  .login_header {
	  width: 100%;
	  margin-bottom:30px;
	  display: flex;
	  justify-content: center;
  }
  .login-form-outer {
	box-shadow: 3px 0 15px 0 rgb(82 80 82 / 69%);
	background-color: #fff;
	border-radius: 10px;
	padding: 0px;
  }
  .OTPFormWrapper{
	padding:20px;
  font-family: Lato;
  margin-top: 0px;
  }
  .OTPFormWrapper .step1{display: flex; width: 100%; flex-direction: column;}
  .OTPFormWrapper .step1 input{text-align: left; font-size: 16px; padding:5px 10px 10px 40px;} 
  .OTPFormWrapper .step1 .block{display: flex; width: 100%;}
  .OTPFormWrapper .step1 select{font-size: 16px; width:100%; margin-right: 10px; max-width: 130px; padding:5px 10px; border-radius:0; border:0px none; border-bottom: 1px solid var(--light-black); background-color: transparent;}
  .OTPFormWrapper .step2{display: flex; width: 100%; flex-direction: column;}
  .OTPFormWrapper .step2 input{width: 40px; margin: 0 10px; padding:0; text-align: center;}
  .OTPFormWrapper .step2 .title{text-align: center;}
  .OTPFormWrapper .step2 .inputBlock{display: flex; margin:15px 0; width: 100%; justify-content: center;}
  .OTPFormWrapper .step2 .buttonBlock {justify-content: space-between;}
  .OTPFormWrapper .step2 .buttonBlock a{font-size: 18px; font-weight:700; margin-right: 10px; text-decoration: none; color: var(--primary);}
  .buttonBlock{display: flex; width: 100%; justify-content: center; margin-top: 20px; align-items: center;}
  
  .registerForm .select_field{display: flex; width: 100%; margin:15px 0}
  .registerForm  .selectField{display: flex; width: 100%; margin:15px 0}
  .registerForm .selectField select{font-size: 16px; width:100%; margin-right: 10px; max-width: none; padding:5px 10px; border-radius:0; border:0px none; border-bottom: 1px solid var(--light-black); background-color: transparent;}
  .registerForm .select_field select{font-size: 16px; width:100%; margin-right: 10px; max-width: 130px; padding:5px 10px; border-radius:0; border:0px none; border-bottom: 1px solid var(--light-black); background-color: transparent;}
  .registerForm .select_field input{margin: 0 10px; padding: 5px 10px 10px 40px; text-align: left;}
  .registerForm .login-actions{margin-top: 10px;;}


  .orDivider{display: flex; position: relative; margin:20px 0 0; align-items: center; width: 100%; font-family: Lato;justify-content:center} 
  .orDivider:before{content: "";position: absolute;height: 1px;left: 0;right: 0;top: 50%;background: #e6e8ec;}
  .orDivider span{ width:32px; position: relative; height:32px; display: flex; align-items: center; background-color: #fff; justify-content: center;  font-size: 11px; text-transform: uppercase;  border: 1px solid #e6e8ec; border-radius: 100%; padding: 8px 11px;}
  .succMsg{color:#009c2e; display: none; -webkit-animation: cssAnimation 0s ease-in 4s forwards; animation: cssAnimation 0s ease-in 4s forwards; border:1px solid #009c2e;  font-family: Lato; font-size:14px; background-color: #e4fed5; padding:10px; border-radius:5px; width:100%}
  .OTPFormWrapper .expireOTP{text-align: center; color: #999;}

  @-webkit-keyframes cssAnimation {
	from {
		visibility:visible;
	opacity:1;
	display: flex;
	}
  to {
		visibility:hidden;
	opacity:0;
	display: none;
	}
  }

  @keyframes cssAnimation {
	from {
		visibility:visible;
	opacity:1;
	display: flex;
	}
  to {
		visibility:hidden;
	opacity:0;
	display: none;
	}
  }
  
.forgot_header{
	width: 100%;
	margin-bottom: 24px;
	display: flex;
	justify-content: center;
}

.login_header span { 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  width: 100px; 
  height: 63px; 
  border-radius: 40px;
  background-color:var(--primary);
  background-color: transparent;
}
.login-form {
	display: flex;
	flex-direction: column;
	width: 430px !important;
	align-items: center;
	margin-top: 0px;
	padding:15px;
	
}
.loginDisabled{pointer-events: none; opacity: 0.5;}
.disabledMessage{ font-family: Lato; text-align: center; margin: 20px 0 0;}
.login_header span { 
	display: flex; 
	align-items: center; 
	justify-content: center; 
	width: 100px; 
	height:100px; 
	border-radius: 40px;
	
  }
.forgot_header span { 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  width: 100%; 
  height: 175px; 
  border-radius: 40px;
  background-color:var(--primary-rgba);
  background-color: transparent;
  max-width: 200px;
}

.login_header span img {  
  width: 100;
  height:auto;	
  object-fit: contain;
}

.forgot_header span img {  
  width: auto;
  height: auto;	
  object-fit: contain;
}

.login_header img {
	width: 170px !important;
	width: 230px !important;
}

.forgot_header img {
	width: auto !important;
}



.languageTranslator.learner{position: relative; top:0;}
.languageTranslator{display: flex; position: absolute; top:10px;  right:0; width:100%; align-items: center; max-width: 280px; flex-direction: column; margin-bottom: 0px; font-family: Lato; }
.languageTranslator .block{display: flex; align-items: center;}
.languageTranslator .block label{margin-right:10px; padding: 0;}
.languageTranslator select{border: 1px solid var(--primary); width:auto;  font-family: Lato; background-color: transparent; font-size: var(--fontsize14); padding: 5px 16px; border-radius:4px;}

.login_title {
  font-family: Lato;
  font-size: 21px;
	font-weight: 600;
	color: var(--primary);
	margin-top:0px;
}

.forgot_title {
	font-family: Lato;
	font-size: 34px;
	font-weight: 600;
	color: var(--primary);
	text-align: center;
	margin-bottom: 6px;
}

.login-sub-title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  font-family: Lato;
  letter-spacing: 0.01em;
  color: var(--text-black);
}

.forgot-sub-title {
	/*margin-left: 54px;
	margin-left: 67px;*/
	font-size: 17px !important;
	font-weight: 700 !important;
	font-family: Lato;
	text-align: center;
	margin:16px;
	color: #5e5050;
}

.login-form {
	display: flex;
	flex-direction: column;
	width: 430px !important;
	align-items: center;
	margin-top: 0px;
	padding:15px;
	
	
}

.login-form .login-form1{flex-direction: column; width: 100%;}
.login-form .login-form2{flex-direction: column; width: 100%;}

.login-form  .visible {display: flex;}
.login-form  .notvisible  {display: none;}


.input_field {
	width: 100%;
	height: auto;
	position: relative;
	margin-top: 15px;
  margin-bottom: 15px;
}

.login_right .userNameInput{ background-size: 34px; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAApdJREFUaEPtmV1OwkAQxy0f73gCMQFe5QZyA/EE6gmUE1hPAJ5APYFyg3qD8gok4AnknS//QxZDSLvbzgyCpJsYEtPZ/f92Zna6U+/kSIZ3JBwnGciheTLzSOaRHe3ATkJrOBzeLBaLBjTXPc+rk/blchniJ8zlckGlUnnT5lEFAUATAG2IL9uEAmoMoBaAPrSA1ED6/f4LAG7TCAPQa61Wu0tjE/esCggHYi1IC0YMQuEEMe+SXYUnr6VhJgIZjUal2Ww2AkRJAgLbSaFQOMeYcOcRgQwGgwcs3OYuvmXXqlarHe5cUpAAC19yF9+y6wKkyZ1LCrLkLhxlBxC2HrYhCUFo/X8Qk+jfmUcidmBvoYVCGKIGXGh4BbWohyq/ei/jDFGOoBh2IOCes3CEzRM84nPnEoEgT8qmIHLX/7UzBXHMnUgEYk4u2sVHrgBjJ/IGzSEGoUkkuSLNjfUGqoDQUTydToO0iU8QxWKxIXnHUgWhyQhmPp/7SZMf0M/5fN7XgFALrc38oAMA3vEhtIH/n23lzhdAA3iBANiJHZWPKqEVl+im+q9qA06lUGv3/xxEeJKlMmd5xNQPquhRlThEYevaVOBl8yrOFp7rccIuFQgEUNxTzaBf26CbXoA/agFtDgInW9eNkmypttBvopEYRNJgSKQk4qE0jYlEIPuAWHMlhXGCIJx8E07cjdWwc77CWEEUuyRSGGeXxQqi3CWRwli7LC4QOjW0uiRSkE+cYnTiRQ4XCN3JXUelVGBS+wlATrkgql2SpIrjnrPd6V0eOQ4QyYVJuvvb9q4LmNUjGp12LSBXx95ZEM1XKLpfqLR90oKRJ/B1y3d9dnCCpF14X89nIPvaeVYdOTSxNj1ZaB2at47GIz+rGhFC4lTk5QAAAABJRU5ErkJggg==); background-position: left center; background-repeat: no-repeat; padding-left:35px;}
.login_right .passwordInput{ background-size: 34px; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAltJREFUaEPtWd1twjAQJhI8l05AkYDXthM0TFDYgE5QmKDtBKUblAmaDQgbwCsgQTegzyCl30W2RCNK7sCJ3NR+iUgu9vfj+M7GKxWkeQXhUXJEbHPSOeIcyUiBzKfWarWqAnu1Xq+vM+IQd5sJkfl83vM87x79dxLgA9wfNRqNwDQpo0RI/d1u9wGQfgrQsFwud+HSxhQhY0SIxHa7HUPxGwVugispP1W/6T45dEe/oyiaViqVtikyxogsFotQg8R10Gw2h4fURlwf91/VsxBxbROuGCGyXC47UJimFLVfSWjACTJtkCERzmpGiAAYTSH6uD8B6oqDCO+sEVeDAKNWq9XjvHMsxhSRiOvGAVc2IH9pGxH2NIEjPsCPiQCInC3o2R0QEIDSjjgibmopBf7v1FIZ/BUZnLI0FYQm2wbLcYCMP5BmfLEjKAinIHFtEn2yLypfkFtuJWOIiCQyuGQccSzE6kqqZBERLLPPQPQkRnXaCy/ILzQeqzkiLJlQeyHuXcX2cK0x36MwOxzBBzvD6uPr1UdtumhvwiVjDZEHrDzajdiIRPmeZo4dRA6tOsJVzw4ikDvAqtPdlz2xi/wbjhBKuPKGXEBb2xLcGOK7eUxDv/fcGkcI0wSu+Or7CHGNDx6YzRFJFeqEzO4csSYhKnuL4ch+OX5C+W/Vx07G6BPHeBkWNOuICLD/CHVEUpUT1kqp/R0LyHSHqA4ewhz27DNUzvrvCZYgoh0i9ajIDNUpygVrFH7QlzpF6Wd+isLHlG+k2JF84fFHc0T4WuUT6RzJR2f+KIVx5BssvlxCINKfLQAAAABJRU5ErkJggg==); background-position: left center; background-repeat: no-repeat; padding-left:35px;}
.login_right .mobileInput{ background-size: 34px; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAatJREFUaEPtWe1NwzAQbaIMABOQSEn+wgbABmxQmICOABuUDcoEjEDZAH4nUsoElP9RwjMkEEKQ6wuhbvQsVWpl39nv3n24Z2cykuGMBMeEQGxjkoyQkYEs0Mu1kiS5Ksty6jiO3+d80LGCjkUURddSPWIgaZrOcYBL6cZdcgBzE4bhTKJTBCTLsr08z18kG+pkPM/bD4JgrVvXnhcBgUudQNF9rQzM3MKaK9PN1XrI+pCdNmRP4WJLU11/AgSbijZXh+1gV6Rr60AUGDBc7jwjBNIKIrpWM2v1CXa6Fl2rYYF2QaRrVcZhHWl4CdMv02/HtZYxwhj5Spff/lixjrCO/MwYrCOsIzbXEbR0LuI4Xpi2cNR6NPrOIH9nS/NBguE3ma22g8YNBC7zBITzCuUMHcXDDRBbx8gr+rh+3cc16BdbB+QBPVx1lfkcuOUu8eNYw4p1QNZgBIR8dNatZGTTQyFGHl3XfY+RoihUjBzpYuRfnxWq/L/7Dz21VdXTG76f43Ogs7Rm/hnz6ulN6RMN0aVRtNPAQgQysIGN1ZMRY5MNLEBGBjawsfo3mLJwQjmdBdgAAAAASUVORK5CYII=); background-position: left center; background-repeat: no-repeat; padding-left:35px;}


.input_field2 .togglePassword {
	background-color: transparent !important;
	height: 36px;
}

.input_field span {
	font-family: Lato;
	position: absolute;
	left: 20px;
	background-color: #ffffff;
	font-size: 14px;
	color: #555;
	top: -12px;
	padding-left: 6px;
	padding-right: 6px;
	padding-bottom: 2px;
	padding-top: 2px;
}

.togglePassword {
	cursor: pointer;
	width: 50px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #ffffff;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 1px;
	top: 6px;
}

.togglePassword img {
  width: 25px;
}

.login_right input {
	font-family: Lato;
	font-size: 16px;
	width: 100%;
  border: none;
  border-bottom: 1px solid var(--light-black);
  background-color: #ffffff;
  letter-spacing: 0.02em;
  padding: 20px 0;
  color: var(--text-black);
}

.otp-block{display: flex; width: 100%; font-family: Lato;  color: var(--black);  font-size: 16px; justify-content: space-between; align-items: center;}
.otp-block div{color: #ff0000;}
.otp-block a{color: var(--primary); font-family: Lato; font-size: 16px; pointer-events: none; opacity: 0.6;} 
.otp-block a.resendactive{pointer-events:all; opacity: 1;}

.step-2-btn{margin-top: 20px;;}

.login-form input:-internal-autofill-selected{background-color:var(--primary-rgba)!important;}

.login-form input:-webkit-autofill {
	box-shadow: 0px 0px 0px 0px;
    background-color: var(--primary-rgba)!important;
}


.forgot-right input {
	font-family: Lato;
	font-size: 18px;
	width: 88%;
	border: none;
	border-bottom: 1px solid var(--light-black);
	background-color: #ffffff;
	letter-spacing: 0.02em;
	padding: 20px 0;
	color: var(--text-black);
	margin-left: 28px;
}


.register-actions{
	display: flex;
	width: 100%;
	justify-content:center;
}

.formblock{display: flex; flex-direction: column;}

.tabsWrapper{display:flex; width: 100%; margin-bottom: 20px;}
.tabsWrapper a{border-bottom: 2px solid #e4e4e4; font-family: Lato; margin-left: 15px; color: #e4e4e4;   text-align: center; width: 50%; padding-right: 0;  padding: 10px 0;}
.tabsWrapper a.active{background-color: transparent; box-shadow: inset 0 0 0 0px var(--primary);  border-bottom: 2px solid var(--primary);  color: var(--primary);
}
.tabsWrapper a:first-child{margin-left: 0;}
.register-step-one{width: 100%; flex-direction: column;}
.register-step-two{width: 100%; flex-direction: column;}

.next-btn{display: flex; align-items: center; justify-content:center; width: 100%;}
.form-heading{font-family: Lato;color: #555; font-size: 22px; margin: 0px 0 15px; width: 100%; text-align: center;}
.register-steps{display: flex; width: 100%;}
.register-steps ul{display: flex; width: 100%; margin:0; padding:0}
.register-steps ul li{display: flex; width:50%; margin:0; padding:0; position: relative; justify-content: center;}
.register-steps ul li:last-child:before{position: absolute; z-index:-2; content: ''; width:100%; margin-left: 0px; left:-38%; height: 1px; background-color:#555; top:50%;}
.register-steps ul li span{font-family: Lato; background-color: #555; color:#fff; width: 45px; height: 45px; border-radius:100%; display: flex; align-items: center; justify-content: center;}
.register-steps ul li.completed-step span{background-color:var(--primary); color:#fff}
.register-steps ul li .completed-step1 span{background-color:var(--primary); color:#fff}
.register-steps ul li .completed-step2 span{background-color:var(--primary); color:#fff}

.otp-login-field{display: flex; width: 100%; align-items: center; flex-direction: column;}
.otp-login-field button{margin-top: 20px;}


.isdisable{pointer-events: none; opacity: 0.5;}
.isnotvisible{display: none;}
.isvisible{display: flex;}

.error-msg{color:#ff0000; font-family: Lato; text-align: left; width: 100%;}
.error-msg + button{margin-top: 20px;}
.success-msg{color:var(--primary); font-family: Lato; text-align: left; width: 100%;}
.success-msg + button{margin-top: 20px;}

.login-actions {
  display: flex; 
  width: 100%; 
  align-items: center; 
  justify-content:space-between;
  flex-direction: column;
}

.login-actions a {
  font-family: Lato;
  font-weight: 600;
	font-size: 14px;
	text-align: left;
	margin-bottom: 20px;
  color: var(--primary);
  text-decoration: none;
  align-self: flex-end;
}

.register-block{
	font-family: Lato;
	font-size: 16px;
	margin-top: 30px;
	color:#555
}

.register-block a{color: var(--primary); font-size: 17px;}

.login-form .input_field {
  position: relative;
}

.login-form .user-input-symbol {
  position: absolute; 
  left:10px; 
  top:17px;
}

.login-form .user-password-symbol {
  position: absolute; 
  left:10px; 
  top:10px;
}

.login_right button {
  font-size: 18px !important;
}

.forgot-right button {
    font-size: 15px !important;
    margin-left: 27px;
}

.fw_input-forgot2{
	margin-top:16px 
}

.fw_input-forgot{
	margin-top:16px 
}

.fw_input-forgot button{
	margin-top: 27px;
}

.togglePassword-on-forgot{
	cursor: pointer;
	width: 50px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #ffffff;
	height: 39px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 26px;
	top:0px;
}
.togglePassword-on-forgot img {
  width: 30px;
}


.addDownloadWrapper{display: flex; align-items: center; padding: 15px 15px 15px; display: none; flex-direction: column; text-align: center; color: #999; font-size:15px; font-family:Lato;}
.addDownloadWrapper div{display: flex; align-items: center; margin-top: 10px; }
.addDownloadWrapper div a:first-child{margin-left: 0;}
.addDownloadWrapper a{margin-left: 10px; border: 1px solid var(--primary); border-radius:100%; display:flex; align-content: center; justify-content: center; width:40px; height: 40px;  color: #60c2cb;  font-size: 17px; text-decoration:none}
.addDownloadWrapper a:hover{margin-left: 10px;color: var(--primary); font-size: 17px; text-decoration:underline}
.addDownloadWrapper a img{max-width:100%; padding: 7px;}
@media only screen and (max-width: 1000px) {
	.login-div{justify-content: center; left:0; bottom:0; top:0; right:0}
	.login_left{display: none;}
	.login_right{left:auto; top:auto; right:auto; box-shadow: 3px 0 15px 0 rgba(82,80,82,.69); background-color: transparent;
		border-radius: 10px; padding: 0px; min-width:auto; min-height: auto; width: 100%;}
	.login_right .login-form{width: 100%!important;}	
	.login_right{max-width: 460px; background-color: transparent;}	
	.login_header{margin-bottom:20px}
	.login_header span{width: 100px; height: 100px;}
	.login_header span img{height:67.9px}
	.login_title{font-size:24px;}
	.login-sub-title{font-size:18px;}
	.forgot-pw{display: flex; position: fixed; height: 100%; left:0; right:0; justify-content: center; align-items: center; width: 100%;} 
	.forgot-left{display: none;}	
	.forgotWrapper{background-color: transparent;}
	.forgot-right{box-shadow: 3px 0 15px 0 rgba(82,80,82,.69); background-color: #fff; border-radius: 10px; padding: 25px; width: 100%; max-width: 460px;}
	.forgot_title{padding-left:0; text-align: center; font-size:24px;}
	.forgot-sub-title{font-size:16px;}	
	.forgot_header{margin-bottom:20px}
	.forgot_header span{width: 100px; height: 100px;}
	.forgot_header span img{height:auto}
	.forgot-right input{margin-left:0; width: 100%;}
	.login-form-outer{width: 100%;}
  }

  @media only screen and (max-width: 560px) {
	.login_right{max-width: 360px;}	
	.forgot-right{max-width: 360px;}
	.forgot-sub-title{font-size:14px;}
  } 
  
  @media only screen and (max-width: 400px) {
	.login_right{max-width: 300px;}	
	.forgot-right{max-width: 300px;}
	.forgot-sub-title{font-size:12px;}
	
  }
.login-div {
	display: flex;
	align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 100%; 
}


.button-class{
	display: flex;
}

.login_left-map {
	overflow: hidden;
	top: 0;
	left: 0;
	position: fixed;
	min-height: 100%;
	min-width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	padding-bottom: 50px;
}



.login_left .rec-item-wrapper {
  height: 100%;
}

.forgot-left .rec-item-wrapper {
  height: 100%;
}

.login_left .rec-item-wrapper .rec {
  height: 100%;
}

.forgot-left .rec-item-wrapper .rec {
  height: 100%;
}

.login_left  .rec-swipable-null {
  height: 100%;
}

.forgot-left  .rec-swipable-null {
  height: 100%;
}

.login_left items {
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center;
  height: 500px;
}

.login_left items img {
  width: auto;
  height: 250px;
}

.login_left .rec-pagination {
  z-index: 1;
  position: absolute; 
  bottom: 80px
}

.login_left .rec-pagination .rec-dot{opacity: 0.50; border-radius:10px; width: 15px; height:12px; box-shadow:0px 0px 0px 0px;	background-color:var(--primary-rgba);}
.login_left .rec-pagination .rec-dot.rec-dot_active{opacity: 1; width: 36px; background-color: var(--primary);}

.login_left .rec-item-wrapper:focus{box-shadow:0px 0px 0px 0px; outline:0px none;}
.login_left .rec div:focus{box-shadow:0px 0px 0px 0px; outline:0px none;}
.login_left .rec:focus{box-shadow:0px 0px 0px 0px; outline:0px none;}

.login_left .rec-slider-container:focus{outline:0px none;}
.login_left .rec-slider:focus{outline:0px none;}
.login_left button:disabled{  color: var(--primary-rgba) !important; box-shadow:0px 0px 0px 0px; background-color: transparent!important;}
.login_left .rec-arrow-left {
  display: none !important;
}
.login_left .rec-arrow-right {
  display: none !important;
}

.login-image {
	width: auto;
	text-align: center;
}

.login-image img {
	width: 100%;
}

.forgot-image{
	height: auto;
	overflow: hidden;
}

.login-title {
	margin-top: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	font-family: Lato;
  font-size: 32px;
  font-weight: 600;
  color: var(--primary);
  line-height: 1.24;
}

.login-title span {
	margin-top: 15px;
	font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: var(--text-black);
  max-width: 520px;
}

.top_login_left {
	width: 180px;
	position: absolute;
	top: 0;
	right: -30px;
}

.bottom_login_left {
	width: 90px;
	position: absolute;
	bottom: 0;
	left: 0px;	
}

/* .login_right {
	overflow: hidden;
	top: 0;
	right: 0;
	position: fixed;
	min-height: 100%;
	min-width: 50%;
	padding-bottom: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
  justify-content: center;
  background-color: var(--white);
} */

.login_header {
	width: 100%;
	margin-bottom:0px;
	display: flex;
	justify-content: center;
}
.forgot_header{
	width: 100%;
	margin-bottom: 24px;
	display: flex;
	justify-content: center;
}

/* .login_header span { 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  width: 175px; 
  height: 175px; 
  border-radius: 40px;
  background-color: rgba(96, 176, 203, 0.1);
} */

.forgot_header span { 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  width: auto; 
  height: auto; 
  border-radius: 40px;
  background-color:var(--primary-rgba);
  background-color: transparent;
}

/* .login_header span img {  
  width: 133px;
  height: auto;	
  object-fit: contain;
} */

.forgot_header span img {  
  width: auto;
  height: auto;	
  object-fit: contain;
  max-width: 100%;
}

.forgot_header img {
	width: auto !important;
	
}

.login_title {
  font-family: Lato;
  font-size:21px;
	font-weight: 600;
	color: var(--primary);
	text-align:center;
	margin-bottom: 20px;
}

.forgot_title {
	font-family: Lato;
	font-size: 34px;
	font-weight: 600;
	color: var(--primary);
	text-align: center;
	margin-bottom: 6px;
}

.login-sub-title {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
  font-family: Lato;
  letter-spacing: 0.01em;
  color: var(--text-black);
}

.forgot-sub-title {
	/*margin-left: 54px;
	margin-left: 67px;*/
	font-size: 15px;
	font-weight: 400;
	font-family: Lato;
	text-align: center;
}



.input_field {
	width: 100%;
	height: auto;
	position: relative;
	margin-top: 15px;
  margin-bottom: 15px;
}



.input_field2 .togglePassword {
	background-color: transparent !important;
	height: 36px;
}

.input_field span {
	font-family: Lato;
	position: absolute;
	left: 20px;
	background-color: #ffffff;
	font-size: 14px;
	color: #555;
	top: -12px;
	padding-left: 6px;
	padding-right: 6px;
	padding-bottom: 2px;
	padding-top: 2px;
}

.togglePassword {
	cursor: pointer;
	width: 50px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #ffffff;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 1px;
	top: 6px;
}

.togglePassword img {
  width: 25px;
}

.login_right input {
	font-family: Lato;
	font-size: 16px;
	width: 100%;
  border: none;
  border-bottom: 1px solid var(--light-black);
  background-color: #ffffff;
  letter-spacing: 0.02em;
  padding: 20px 20px;
  color: var(--text-black);
}

.forgot-right input {
	font-family: Lato;
	font-size: 18px;
	width: 88%;
	border: none;
	border-bottom: 1px solid var(--light-black);
	background-color: #ffffff;
	letter-spacing: 0.02em;
	padding: 20px 0;
	color: var(--text-black);
	margin-left: 28px;
}

.login-actions {
  display: flex; 
  width: 100%; 
  margin-top:0px;
  align-items: center; 
  justify-content:space-between;
}

.login-actions a {
	font-family: Lato;
  font-weight: 400;
	font-size: 16px;
	text-align: left;
  color: var(--primary);
  text-decoration: none;
}

.login-form .input_field {
  position: relative;
}

.login-form .user-input-symbol {
  position: absolute; 
  left:10px; 
  top:17px;
}

.login-form .user-password-symbol {
  position: absolute; 
  left:10px; 
  top:10px;
}

.login_right button {
  font-size:18px !important;
}

.login_right button.login{padding:10px 40px 10px 40px; font-size: 20px!important;}

.forgot-right button {
    font-size: 15px !important;
    margin-left: 27px;
}

.fw_input-forgot2{
	margin-top:16px 
}

.fw_input-forgot{
	margin-top:16px 
}

.fw_input-forgot button{
	margin-top: 27px;
}

.togglePassword-on-forgot{
	cursor: pointer;
	width: 50px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #ffffff;
	height: 39px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 26px;
	top:0px;
}
.togglePassword-on-forgot img {
  width: 30px;
}


@media only screen and (max-width: 1000px) {
	.login-div{justify-content: center; left:0; bottom:0; top:0; right:0}
	.login_left{display: none;}
	.login_right{left:auto; top:auto; right:auto; box-shadow: 3px 0 15px 0 rgba(82,80,82,.69); background-color:transparent;
		border-radius: 10px; padding: 0x; min-width:auto; min-height: auto; width: 100%;}
	.login_right .login-form{width: 100%!important;}	
	.login_right{max-width: 460px;}	
	.login_header{margin-bottom:20px}
	.login_header span{width: 100px; height: 100px;}
	.login_header span img{height:67.9px}
	.login_title{font-size:24px;}
	.login-sub-title{font-size:18px;}
	.forgot-pw{display: flex; position: fixed; top:0; bottom:0; left:0; right:0; justify-content: center; align-items: center; width: 100%;} 
	.forgot-left{display: none;}	
	.forgot-right{box-shadow: 3px 0 15px 0 rgba(82,80,82,.69); 
		border-radius: 10px; padding: 25px; width: 100%; max-width: 460px;}
	.forgot_title{padding-left:0; text-align: center; font-size:24px;}
	.forgot-sub-title{font-size:16px;}	
	.forgot_header{margin-bottom:20px}
	.forgot_header span{width: auto; height: auto; padding:10px; border-radius:10px;}
	.forgot_header span img{height:auto}
	.forgot-right input{margin-left:0; width: 100%;}
  }

  @media only screen and (max-width: 560px) {
	.login_right{max-width: 360px;}	
	.forgot-right{max-width: 360px;}
	.forgot-sub-title{font-size:14px;}
  } 
  
  @media only screen and (max-width: 400px) {
	.login_right{max-width: 300px;}	
	.forgot-right{max-width: 300px;}
	.forgot-sub-title{font-size:12px;}
	
  }
html:lang(ar) *{
	text-align: right;
}

.languageTranslator.admin{position: static;}
.languageTranslator.faculty{position: static;}

html:lang(ar) input[type="text"]{direction: rtl}
html:lang(ar) input[type="password"]{direction: rtl}
html:lang(ar) input[type="tel"]{direction: rtl}
html:lang(ar) input[type="email"]{direction: rtl}
html:lang(ar) input[type="datetime-local"]{direction: rtl}
html:lang(ar) select{direction: rtl}
html:lang(ar) textarea{direction: rtl}

html:lang(ar) .OTPFormWrapper .step1 input{text-align: right;  direction: rtl}
html:lang(ar) .login_right .passwordInput{padding-right:20px; direction: rtl;}
html:lang(ar) .prof-options-div{align-items: flex-end;}
html:lang(ar) .changePasswordWrapper .input_field input{padding-right: 20px; direction: rtl;}
html:lang(ar) input[type="text"]{padding-right: 20px; direction: rtl}
html:lang(ar) input[type="email"]{padding-right: 20px; direction: rtl}
html:lang(ar) input[type="password"]{padding-right: 20px; direction: rtl}
html:lang(ar) .forgot-right input{padding-right:10px; direction: rtl;}
html:lang(ar) .login_right .userNameInput{padding-right: 20px;}
html:lang(ar) .togglePassword-on-forgot{left:38px}
html:lang(ar) .togglePassword{left:2px; top:0!important}
html:lang(ar) .button-class{justify-content: flex-end;}
html:lang(ar) .IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:first-child{order:2}
html:lang(ar) .IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:last-child{order:1; width:auto}
html:lang(ar) .IADashboardWrapper1 .courses-widget .quick-links-widget ol li:first-child{order:2}
html:lang(ar) .IADashboardWrapper1 .courses-widget .quick-links-widget ol li:last-child{order:1}
html:lang(ar) .IADashboardWrapper1 .courses-widget .quick-links-widget ol li a{margin-right: 15px;}
html:lang(ar) .dashboard-title .drop-profile-creds{order:2}
html:lang(ar) .app-div.widget-app-dashboard .dashboard-schedule .viewall-btn{order:1}
html:lang(ar) .dash-practice div{order:unset!important; background-position: left;}
html:lang(ar) .ask-doubt-content textarea{padding-right:20px; direction: rtl;}
html:lang(ar) .liveprojects-block .design-block li .thumb-content{ justify-content: flex-end;}
html:lang(ar) .dash-flex2 div:nth-child(1){order:2}
html:lang(ar) .dash-flex2 div:nth-child(2){order:1}
html:lang(ar) .dash-flex2 div:nth-child(2) .dash-flex2-img{ justify-content: flex-start;}
html:lang(ar) .lec-mode-dash{left:0; border-radius:0 4px 4px 0}
html:lang(ar) .popup-inputs input[type="text"]{padding-right: 20px;}
html:lang(ar) .popup-inputs-session input, html:lang(ar) .popup-inputs2 input, html:lang(ar) .popup-inputs-session select, html:lang(ar) .popup-inputs5 input{padding-right: 20px;}
html:lang(ar) .popup-data input[type="text"]{padding-right: 20px;}
html:lang(ar) .popup-data input[type="email"]{padding-right: 20px;}
html:lang(ar) .popup-data input[type="password"]{padding-right: 20px;}
html:lang(ar) .popup-data select{padding-right: 20px;}
html:lang(ar)  .popup-session-content .popup-actions{justify-content: flex-start;}
html:lang(ar) .grid-item .grid-up-v1 .grid-sec{order:2}
html:lang(ar) .grid-item .grid-up-v1 .grid-sec{order:1}
html:lang(ar) .grid-item .grid-up-v1 .title-grid{order:2}
html:lang(ar) .grid-item .grid-up-v1 .grid-actions{order:1}
html:lang(ar)  .grid-item .grid-up-v1 .title-grid4{order:2; justify-content:flex-end; align-items: center;}
html:lang(ar)  .grid-item .grid-up-v1 .title-grid4 .dp_div{order:2; margin-right: 0; margin-left: 12px;}
html:lang(ar)  .grid-item .grid-up-v1 .title-grid4 .dp_div + div{order:1}
html:lang(ar) .grid-item .grid-up-v1 #section-button2:last-child{margin-left: 0;}
html:lang(ar) .grid-item .grid-up-v1 .title-grid2{order:2; justify-content:flex-end; align-items: center;}
html:lang(ar) .grid-item .grid-up-v1 .grid-actions{order:1;}
html:lang(ar) .grid-item .grid-up-v1 .grid-sec{order:1;}
html:lang(ar) .grid-item .grid-up-v1 .grid-image{order:2;}
html:lang(ar) .delete-down button{padding: 0 20px!important;}
html:lang(ar) .edit-div-5{text-align: left;}
html:lang(ar) .back-to-recent{ padding-right: 10px;}
html:lang(ar) .faculty-dash-actions{left:24px;}
html:lang(ar) .faculty-dash-actions a:nth-child(1){margin-right: 10px;}
html:lang(ar) .dash-test-bottom div:nth-child(1){order:2; align-items: flex-end;}
html:lang(ar) .dash-test-bottom div:nth-child(2){order:1}
html:lang(ar) .sm-units.newDesign .sm-unit-div .sm-unit-div1 span{text-align: left;}
html:lang(ar) .sm-layers h3{text-align: left;}
html:lang(ar) .sm-layers .sm-content-title *{text-align: center;}
html:lang(ar) .your-answer{left:0;}
html:lang(ar) .grid-item .grid-up{text-align: left;}
html:lang(ar) .grid-item .grid-up .title-grid{text-align: left;}
html:lang(ar)  .grid-item .grid-up span{text-align: left;}
html:lang(ar) .assg-header1 *{text-align: left;}
html:lang(ar) .asm-info *{text-align: left;}
html:lang(ar)  #session-band{left:0;right: auto;}
html:lang(ar) .session-content{align-items: flex-end;}
html:lang(ar) .edit-lecture-button{left: 143px !important;}
html:lang(ar) .edit-lecture-button2{left: 98px !important;}
html:lang(ar) .edit-lecture-button3{left: 54px !important;}
html:lang(ar) .edit-lecture-button4{left: 6px !important;}
html:lang(ar) .circle1{text-align: center;}
html:lang(ar) .dash-test-bottom div:first-child{width:100%}
.main {
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
  background-color: var(--white);
}

.header {
	width: 1200px;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.logo {
	width: 120px;
	font-family: Source Sans Pro;
	font-size: 24px;
	color: var(--primary);
	font-weight: 700;
}

.logo img {
  width: 75px;
  width: 145px;
  cursor: pointer;
}

.signup {
	width: 120px;
	display: flex;
	justify-content: flex-end;
}

.signup a {
  text-decoration: none;
}

.signup button {
  font-size: 19px;
  padding: 10px 25px 12px 25px;
}

.header-middle {
  display: flex;
  align-items: center;
}

.header-middle div {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  color: var(--text-black2);
  margin-left: 45px;
  margin-right: 45px;
  padding-bottom: 10px;
  border-bottom: 1px solid transparent;
  transition: .4s all;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
}

.header-middle div:hover {
  border-color: var(--text-black3);
}

.form-div {
  width: 100%;
  height: calc(100vh - 120px);
}


@media only screen and (max-width: 1000px) {  
  .header{width: 100%;}
  .header .logo{width: auto;} 
  .header-middle div{margin-left:20px; margin-right:20px;}
  .header .signup{width: auto;}
}


@media only screen and (max-width: 600px) {  
  .header{display: flex; flex-direction: column; height:auto}
  .header-middle{flex-direction: column;}
  .header-middle div{margin:10px 0}
}
.scorecard-header {
  font-family: Lato;
	position: relative;
	width: 100vw;
  height: 340px;
  background-image: linear-gradient(to bottom, var(--primary), var(--primary-rgba));
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}

.scorecard-header .close {
	z-index: 100;
	height: 26px;
	position: absolute;
	top: 26px;
	left: 40px;
	font-size: 50px;
	color: #ffffff;
	cursor: pointer;
}

.scorecard-header .close:hover {
	opacity: 0.8;
}

.scorecard-header-left {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
  height: 0;
  border-bottom: 340px solid white;
  border-right: 60px solid transparent;
}

.scorecard-header-right {
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
  height: 0;
  border-bottom: 340px solid white;
  border-left: 60px solid transparent;
}

.scorecard-main {
  position: absolute;
  width: 100%;
  left: 0;
  padding-top: 40px;
  font-family: Lato;
	display: flex;
	flex-direction: column;
  align-items: center;
  background-color: var(--white);
  min-height: 65vh;
}

.scorecard-main a {
	width: 400px;
	color:#707070; 
	text-decoration: none; 
	border-bottom: 2px solid transparent; 
	position:relative; 
	padding: 6px 14px 16px 14px;
	text-align: center;
	margin-right: 20px;
	margin-left: 20px;
	font-family: Lato; 
	font-size: 17px;
  color: #333;
  cursor: pointer;
}

.scorecard-main a:hover {
	color: #000;
	color: initial;
}

.ui.secondary.pointing.menu .active.item {
	font-weight: bold !important;
	border-color:var(--primary) !important;
}

.overall_scorehead {
	text-transform: uppercase;
	font-family: Lato;
  font-size: 31px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.overall_scorehead_display {
	margin-top: 45px;
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.overall_scorehead_div {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #ffffff;
	width: 150px;
}

.single_scorecard {
	width: 82px;
	height: 82px;
	border-radius: 50%;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 10px solid var(--white);
	margin-bottom: 10px;
	font-weight: bold;
}

.overall_scorehead_div span {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 16px;
}

.overall_scorehead_div:nth-child(2) {
	margin-left: 50px;
	margin-right: 50px;
}

.overall_avg_time {
	border-radius: 2px;
  background-color:var(--primary);
  padding-left: 30px;
  padding-right: 30px;
  height: 33px;
  display: flex;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  align-items: center;
  text-align: center;
}

.scorecard_nav {
	width: 1000px;
	display: flex;
	align-items: center;
	padding-top: 20px;
	justify-content: flex-start;
	border-bottom: 1px solid #ddd;
}

.scorecard-overall {
	margin-top: 40px;
	width: 1100px;
	display: grid;
	grid-gap: 40px;
  grid-template-columns: repeat(auto-fill,minmax(530px,1fr));
	grid-auto-rows: auto;
	grid-template-rows: auto 1fr;
	margin-bottom: 100px;
	padding-bottom: 50px;
}

.scorecard-overall-cards {
	width: 530px;
	height: 270px;
	border-radius: 4px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 25px 30px;
}

.scorecard-overall-cards span {
	font-size: 15px;
	letter-spacing: 0.01em;
	font-weight: bold;
	color: #333333;
	text-transform: uppercase;
}

.score_high {
	background-color: var(--primary);
}

.score_avg {
	background-color: var(--primary-rgba);
}

.score_low {
	background-color: var(--primary-rgba);
}

.scorecard_card {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 75%;
	margin-bottom: 5px;
	width: 351px;
	height: 120px;
}

.scorecard_cardx {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 75%;
	margin-bottom: 5px;
	width: 351px;
	height: 85px;
}

.scorecard_card2 {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
}

.scorecard_questions_bar {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	width: 100%;
}

.scorecard_questions_bar span {
	min-width: 50px;
	max-width: 50px;
	font-size: 17px;
	text-align: right;
	margin-right: 10px;
}

.scorecard_bar_ques {
	height: 30px;
	border-radius: 4px;
}

.scorecard_colors {
	display: flex;
	align-items: center;
}

.scorecard_single_color {
	display: flex;
	align-items: center;
}

.scorecard_single_color:nth-child(2) {
	margin-left: 25px;
	margin-right: 25px;
}

.scorecard_square_color {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	border-radius: 3px;
}

.scorecard_square_color2 {
	margin-right: 10px;
	font-weight: bold;
	color: #222222;
	padding: 2px 5px;
	border-radius: 3px;
}

.scorecard_colors span {
	text-transform: none;
	font-weight: 400;
	font-size: 14px;
}

.scorecard_times {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;
}

.scorecard_times:nth-child(2) {
	margin-right: 35px;
	margin-left: 35px;
}

.scorecard_time {
	width: 100px;
	height: 100px;
	font-weight: bold;
	font-size: 21px;
	color: #111;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-bottom: 15px;
}

.scorecard_times span {
	font-weight: 400;
	color: #333333;
	text-align: center;
	text-transform: none;
	font-size: 14px;
}

.scorecard_patterns {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.scorecard_patterns span {
	font-weight: 400;
	font-size: 15px;
	text-transform: none;
}

.scorecard_pattern {
	width: 100%;
	display: flex;
	height: 110px;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 10px;
}

.scorecard_patterns {
	width: 14%;
}

.scorecard_patterns:nth-child(2) {
	margin-left: 40px;
	margin-right: 40px;
}

.scorecard_patterns:nth-child(3) {
	margin-right: 40px;
}

.answer_pattern {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
}

.answer_pattern span {
	font-weight: bold;
}

.high_pattern {
	width: 10px;
	border-radius: 4px;
}

{/*Student Wise Scorecard*/}
.student_scorecard {
	width: 940px;
	margin-top: 40px;
	margin-bottom: 50px;
	padding-bottom: 40px;
}

.scorecard_student_header {
	width: 100%;
	border-radius: 2px;
  background-color: #eeeeee;
	display: flex;
	align-items: center;
	padding-top: 7px;
	padding-bottom: 9px;
}

.scorecard_scroll_list {
	width: 100%;
	margin-top: 25px;
}

.scorecard_student_content {
	width: 100%;
	display: flex;
	margin-bottom: 18px;
}

.scorecard_header_title {
	display: flex;
	font-weight: bold;
	font-size: 14px;
}

.scorecard_student_title {
	display: flex;
	color: #222222;
}

{/*Chapter Wise*/}
.wise_scorecard {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 1000px;
	margin-top: 40px;
	margin-bottom: 50px;
	padding-bottom: 40px;
}

.wise_scorecard .scorecard_colors {
	margin-bottom: 40px;
}

.scorecard_chapter_content {
	width: 100%;
	display: flex;
}

.scorecard_chapter_header {
	width: 100%;
	border-radius: 20px;
	background-color: #f2f7dc;
	display: flex;
	align-items: center;
	padding-top: 7px;
	padding-bottom: 9px;
}

.chapter_header {
	display: flex;
	align-items: center;
	width: 24%;
	padding-left: 30px;
}

.wise_header {
	width: 19%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.wise_header span {
	padding: 10px 0px;
	width: 55px;
	text-align: center;
}

{/*Correct Answers*/}
.scorecard_card3 {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	margin-bottom: 20px;
}

.scorecard_correct_answers {
	width: 120px;
  height: 60px;
  background-color: #ffffff;
  border-top-left-radius: 120px; 
  border-top-right-radius: 120px;
  border: 12px solid #f5f5f5;
  border-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: bold;
  font-size: 23px;
  margin-bottom: 20px;
}

{/*Accuracy*/}
.scorecard_accuracy {
	width: 100px;
	height: 100px;
	font-weight: bold;
	font-size: 21px;
	color: #111;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-bottom: 15px;
	background-color: #ffffff;
	border: 12px solid #f5f5f5;
}

{/*Max Score*/}
.scorecard_card4 {
	position: relative;
	display: flex;
	align-items: flex-end;
	width: 75%;
	margin-bottom: 5px;
	width: 390px;
	height: 120px;
}

.scorecard_mountain {
	position: absolute;
	width: 351px;
	height: 100%;
	bottom: 0;
	left: 0;
}

.scorecard_numbers {
	height: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.max_score_tri {
  border-left: 20px solid transparent;
  border-right: 351px solid;
  height: 0;
  width: 100%;
  position: absolute;
  right: 0px;
  bottom: 0;
  z-index: 2;
}

.max_score_high {
	z-index: 1;
	border-right: 351px solid #cbedf7;
}

.max_score_avg {
	z-index: 1;
	border-right: 351px solid #91dbf1;
}

.max_score_low {
	z-index: 1;
	border-right: 351px solid #65acc1;
}

.scorecard_number_label {
	position: absolute;
	font-weight: bold;
	color: #555;
	padding: 1px 5px 2px 5px;
	text-align: center;
	display: inline;
	font-size: 14px;
	border-radius: 4px;
	margin-bottom: -10px;
	margin-left: -25px;
	cursor: pointer;
}

.scorecard_number_label:hover {
	z-index: 2;
}


.dpp-header {
	z-index: 10;
	width: 100vw;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 60px;
	padding-right: 40px;
	border-bottom: 1px solid #dddddd;
}

.dpp-header span {
	font-weight: bold;
	font-size: 17px;
}

.dpp-header-navs {
	display: flex;
	align-items: center;
}

.dpp-header-navs a {
	font-family: Lato;
	padding: 7px 40px 8px 40px;
	color: #ffffff;
	font-weight: bold;
	font-size: 15px;
	background-color: #66bb6a;
	border-radius: 2px;
}

.dpp-header-navs a:hover {
	opacity: 0.8;
}

.dpp-header-navs button {
	font-family: Lato;
	padding: 7px 40px 7px 40px;
	color: #333333;
	border: 1px solid #333333;
	font-weight: bold;
	font-size: 15px;
	background-color: #ffffff;
	border-radius: 2px;
	margin-left: 20px;
	cursor: pointer;
}

.dpp-header-navs button:hover {
	opacity: 0.8;
}

.exit_button {
	font-family: Lato, sans-serif;
	padding: 7px 40px 7px 40px;
	color: #333333 !important;
	border: 1px solid #333333;
	font-weight: bold;
	font-size: 15px;
	background-color: #ffffff !important;
	border-radius: 2px;
	margin-left: 20px;
	cursor: pointer;
}

.exit_button:hover {
	opacity: 0.8;
}

.dpp-footer {
	background-color: #ffffff;
	z-index: 100;
	position: fixed;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	padding-left: 50px;
	padding-right: 40px;
	width: 100%;
	height: 95px;
	border-top: 1px solid #dddddd;
}

.dpp_student_button {
	height: 40px;
  width: 80px;
	position: absolute;
	background-color: #eee;
	bottom: 95px;
	left: 50%;
	margin-left: -40px;
	border-top-left-radius: 80px;
  border-top-right-radius: 80px;
  border: solid 1px #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
  cursor: pointer;
}

.dpp_student_button:hover {
	opacity: 0.8;
}

.dpp_student_button img {
	width: 26px;
}

.dpp_student_list {
	position: relative;
	width: 100%;
	height: 90%;
	padding-top: 45px;
	overflow-y: hidden;
}

.dpp_student_list .close {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 42px;
	color: #888888;
	cursor: pointer;
}

.dpp_student_list .close:hover {
	color: #555555;
}

.dpp_student_header {
	width: 100%;
	border-radius: 20px;
	background-color: #f2f7dc;
	display: flex;
	align-items: center;
	padding-top: 7px;
	padding-bottom: 9px;
}

.dpp_scroll_list {
	width: 100%;
	height: 78%;
	overflow-y: auto;
	margin-top: 25px;
}

.dpp_student_content {
	width: 100%;
	display: flex;
	margin-bottom: 18px;
}

.dpp_header_title {
	display: flex;
	font-weight: bold;
	font-size: 14px;
}

.dpp_student_title {
	display: flex;
	color: #222222;
}

.rank_header {
	width: 12%;
	justify-content: center;
}

.name_header {
	width: 23%;
	padding-left: 50px;
}

.similar_header {
	width: 16%;
	justify-content: center;
}

.dpp-difficulty {
	min-width: 120px;
	margin-right: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.dpp-difficulty span {
	position: relative;
	display: flex;
	justify-content: center;
}

.pin {
	position: absolute;
	bottom: 0;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.Easy-pin {
	position: absolute;
	bottom: 0;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transform: rotate(-85deg);
          transform: rotate(-85deg);
  margin-left: 5px;
}

.Medium-pin {
	position: absolute;
	bottom: 0;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-left: 5px;
}

.Hard-pin {
	position: absolute;
	bottom: 0;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin-left: -5px;
}

.Very {
	position: absolute;
	bottom: 0;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transform: rotate(85deg);
          transform: rotate(85deg);
  margin-left: -5px;	
}

.dpp-difficult {
	margin-top: 6px;
	display: flex;
	align-items: center;
	color: #666666;
}

.dpp-difficult p {
  margin-top: 0;
  margin-bottom: 0;
	color: #222222;
	font-weight: bold;
	margin-left: 6px;
}

.dpp-details {
	display: flex;
  align-items: center;
  font-family: Lato;
}

.dpp_average {
	position: relative;
	border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  margin-right: 20px;
}

.dpp_average:last-child {
	margin-right: 0;
}

.dpp_avg_icon {
	width: 42px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 15px;
}

.dpp_avg_icon img {
	width: 100%;
}

.dpp_avg_numbers {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: #666666;
}

.dpp_avg_numbers span {
	text-transform: lowercase;
	font-size: 17px;
	font-weight: bold;
	margin-bottom: 2px;
	color: #222222;
}

.dpp_sidebar {
	transition: .25s all;
	position: fixed;
	right: 0;
	top: 70px;
	height: calc(100vh - 165px);
	width: 25%;
	border-left: 1px solid #ddd;
	overflow-y: auto;
}

.dpp_language {
	width: 100%;
	height: 50px;
	background-color: #f5f5f5;
	display: flex;
	align-items: center;
	padding-left: 25px;
	padding-right: 25px;
	font-weight: bold;
	font-size: 17px;
}

.dpp_instructions {
	display: flex;
	align-items: center;
	height: 50px;
	width: 100%;
	border-bottom: 1px solid #eeeeee;
}

.dpp_options {
	width: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
}

.dpp_options:first-child {
	border-right: 1px solid #eee;
}

.dpp_sections {
	margin: 20px 20px;
}

.dpp_section {
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #eee;
}

.dpp_single_section {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: rgba(0,0,0,0.80);
	font-weight: bold;
	cursor: pointer;
}

.dpp_single_section span {
	margin-left: 10px;
}

.dpp_all_questions {
	width: 100%;
	display: grid;
	grid-gap: 10px;
  grid-template-columns: repeat(auto-fill,minmax(40px,1fr));
	grid-auto-rows: auto;
	grid-template-rows: auto 1fr;
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: center;
}

.dpp_single_ques {
	cursor: pointer;
	display: inline-block;
	padding: 6px 6px;
  border-radius: 2px;
}

.single_ques {
	transition: .25s all;
	position: fixed;
	overflow-y: auto;
	left: 0;
	top: 70px;
	width: 33%;
	height: calc(100vh - 165px);
	padding-left: 60px;
	padding-right: 60px;
	padding-top: 40px;
	padding-bottom: 60px;
}

.ques_section {
	font-size: 15px;
	font-weight: bold;
	color: #777;
}

.ques_id {
	font-size: 17px;
	font-weight: bold;
	margin-top: 15px;
}

.ques_title {
	font-family: Lato;
	line-height: 1.5;
	font-size: 19px;
	letter-spacing: 0.01em;
	color: #2a2929;
	margin-top: 25px;
	word-wrap: break-word;
}

.single_solution {
	transition: .25s all;
	border-left: 1px solid #ccc;
	position: fixed;
	overflow-y: auto;
	left: 33%;
	top: 70px;
	width: 42%;
	height: calc(100vh - 165px);
	padding-left: 40px;
	padding-right: 60px;
	padding-top: 40px;
	padding-bottom: 80px;
}

.solution_options {
	margin-bottom: 40px;
}

.an_option {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
}

.an_option:last-child {
	margin-bottom: 0;
}

.single_option {
	font-family: Lato;
	font-size: 16px;
	line-height: 1.6;
	color: #343434;
	position: relative;
	width: 320px;
	min-height: 55px;
	border-radius: 8px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.single_option_content {
	z-index: 1;
	font-size: 15px;
	color: #222222;
	letter-spacing: 0.01em;
}

.option_metric {
	border-radius: 8px;
	position: absolute;
	left: 0;
	height: 100%;
	background-color: #d8d8d8;
	opacity: 0.28;
	z-index: 0;
}

.option_metric_no {
	font-weight: bold;
	font-size: 17px;
	margin-left: 20px;
	color: #333333;
}

.solution_statement {
	margin-top: 40px;
	font-size: 15px;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

.solution_statement span {
	font-weight: bold;
	margin-right: 8px;
}
.circle{
	position: relative;
	top: 12px;
	left: 11px;
	text-align: center;
	width: 94px;
	height: 94px;
	border-radius: 100%;
	background-color: #E6F4F7;
}
.prec{
    top: 30px;
    position: relative;
    font-size: 20px !important;
}

.active-border{
    position: relative;
    text-align: center;
    width: 117px;
    height: 116px;
    border-radius: 100%;
    background-color:#39B4CC;
    
    
}

.active-border1 {
    position: relative;
    text-align: center;
    width: 117px;
    height: 70px;
    /* border-radius: 100%; */
    background-color: var(--primary-rgba);
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
}
.circle1 {
    position: relative;
    top: 12px;
    left: 11px;
    text-align: center;
    width: 94px;
    height: 58px;
    /* border-radius: 100%; */
    background-color: white;
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
}

.scoreNotify{
	margin-right: 200px;
	font-size: 12px;
	font-weight: 600;
	font-family: lato;
}



@media only screen and (max-width: 1000px) {  
	.scorecard-header{width:100vw}
	.scorecard-header .close{right:20px; left:auto; top:0px}
	.scorecard-header .overall_scorehead{font-size:23px}
	.scorecard-header .scorecard-header-left{display: none;}
	.scorecard-header .scorecard-header-right{display: none;}
	.scorecard-main{padding:20px;background-color:#fff; left:0; right:0px}
	.scorecard-main .scorecard-overall{flex-direction: column; width:100%; display: flex}
	.scorecard-main .scorecard-overall-cards{width: 100%; height:auto}
	.scorecard-main .scorecard-overall-cards .scorecard_card{width: 100%; margin-top:15px}
}

@media only screen and (max-width: 600px) {  
	.overall_scorehead_display .overall_scorehead_div{width: 100px;}
	.overall_scorehead_div:nth-child(2){margin-left:25px; margin-right:25px;}
	.overall_scorehead_display .overall_scorehead_div .single_scorecard{width: 50px; height:50px;}
	.overall_scorehead_display .overall_scorehead_div span{text-align: center;}
	.scorecard-main .scorecard_card2{width:100%; flex-direction:column; margin-top:15px;}
	.scorecard-main .scorecard_card3{width:100%; flex-direction:column; margin-top:15px;}

}
.rect-auto,
.c100.p51 .slice,
.c100.p52 .slice,
.c100.p53 .slice,
.c100.p54 .slice,
.c100.p55 .slice,
.c100.p56 .slice,
.c100.p57 .slice,
.c100.p58 .slice,
.c100.p59 .slice,
.c100.p60 .slice,
.c100.p61 .slice,
.c100.p62 .slice,
.c100.p63 .slice,
.c100.p64 .slice,
.c100.p65 .slice,
.c100.p66 .slice,
.c100.p67 .slice,
.c100.p68 .slice,
.c100.p69 .slice,
.c100.p70 .slice,
.c100.p71 .slice,
.c100.p72 .slice,
.c100.p73 .slice,
.c100.p74 .slice,
.c100.p75 .slice,
.c100.p76 .slice,
.c100.p77 .slice,
.c100.p78 .slice,
.c100.p79 .slice,
.c100.p80 .slice,
.c100.p81 .slice,
.c100.p82 .slice,
.c100.p83 .slice,
.c100.p84 .slice,
.c100.p85 .slice,
.c100.p86 .slice,
.c100.p87 .slice,
.c100.p88 .slice,
.c100.p89 .slice,
.c100.p90 .slice,
.c100.p91 .slice,
.c100.p92 .slice,
.c100.p93 .slice,
.c100.p94 .slice,
.c100.p95 .slice,
.c100.p96 .slice,
.c100.p97 .slice,
.c100.p98 .slice,
.c100.p99 .slice,
.c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}
.pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  position: absolute;
  border: 0.08em solid var(--primary);
  width: 0.84em;
  height: 0.84em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.pie-fill,
.c100.p51 .bar:after,
.c100.p51 .fill,
.c100.p52 .bar:after,
.c100.p52 .fill,
.c100.p53 .bar:after,
.c100.p53 .fill,
.c100.p54 .bar:after,
.c100.p54 .fill,
.c100.p55 .bar:after,
.c100.p55 .fill,
.c100.p56 .bar:after,
.c100.p56 .fill,
.c100.p57 .bar:after,
.c100.p57 .fill,
.c100.p58 .bar:after,
.c100.p58 .fill,
.c100.p59 .bar:after,
.c100.p59 .fill,
.c100.p60 .bar:after,
.c100.p60 .fill,
.c100.p61 .bar:after,
.c100.p61 .fill,
.c100.p62 .bar:after,
.c100.p62 .fill,
.c100.p63 .bar:after,
.c100.p63 .fill,
.c100.p64 .bar:after,
.c100.p64 .fill,
.c100.p65 .bar:after,
.c100.p65 .fill,
.c100.p66 .bar:after,
.c100.p66 .fill,
.c100.p67 .bar:after,
.c100.p67 .fill,
.c100.p68 .bar:after,
.c100.p68 .fill,
.c100.p69 .bar:after,
.c100.p69 .fill,
.c100.p70 .bar:after,
.c100.p70 .fill,
.c100.p71 .bar:after,
.c100.p71 .fill,
.c100.p72 .bar:after,
.c100.p72 .fill,
.c100.p73 .bar:after,
.c100.p73 .fill,
.c100.p74 .bar:after,
.c100.p74 .fill,
.c100.p75 .bar:after,
.c100.p75 .fill,
.c100.p76 .bar:after,
.c100.p76 .fill,
.c100.p77 .bar:after,
.c100.p77 .fill,
.c100.p78 .bar:after,
.c100.p78 .fill,
.c100.p79 .bar:after,
.c100.p79 .fill,
.c100.p80 .bar:after,
.c100.p80 .fill,
.c100.p81 .bar:after,
.c100.p81 .fill,
.c100.p82 .bar:after,
.c100.p82 .fill,
.c100.p83 .bar:after,
.c100.p83 .fill,
.c100.p84 .bar:after,
.c100.p84 .fill,
.c100.p85 .bar:after,
.c100.p85 .fill,
.c100.p86 .bar:after,
.c100.p86 .fill,
.c100.p87 .bar:after,
.c100.p87 .fill,
.c100.p88 .bar:after,
.c100.p88 .fill,
.c100.p89 .bar:after,
.c100.p89 .fill,
.c100.p90 .bar:after,
.c100.p90 .fill,
.c100.p91 .bar:after,
.c100.p91 .fill,
.c100.p92 .bar:after,
.c100.p92 .fill,
.c100.p93 .bar:after,
.c100.p93 .fill,
.c100.p94 .bar:after,
.c100.p94 .fill,
.c100.p95 .bar:after,
.c100.p95 .fill,
.c100.p96 .bar:after,
.c100.p96 .fill,
.c100.p97 .bar:after,
.c100.p97 .fill,
.c100.p98 .bar:after,
.c100.p98 .fill,
.c100.p99 .bar:after,
.c100.p99 .fill,
.c100.p100 .bar:after,
.c100.p100 .fill {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.c100 {
  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  margin: 0 0.1em 0.1em 0;
  background-color:var(--primary-rgba);
}
.c100.p100 .bar{ border:0.08em solid var(--primary-rgba)}
.c100.p100 .fill{ border:0.08em solid var(--primary-rgba)}
.c100 *,
.c100 *:before,
.c100 *:after {
  box-sizing: content-box;
}
.c100.center {
  float: none;
  margin: 0 auto;
}
.c100.big {
  font-size: 240px;
}
.c100.small {
  font-size: 80px;
}
.c100 > span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: black;
  display: block;
  text-align: center;
  white-space: nowrap;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.c100:after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: #f5f5f5;
  width: 0.84em;
  height: 0.84em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}
.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}
.c100.p1 .bar {
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.c100.p2 .bar {
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.c100.p3 .bar {
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.c100.p4 .bar {
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.c100.p5 .bar {
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}
.c100.p6 .bar {
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.c100.p7 .bar {
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.c100.p8 .bar {
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.c100.p9 .bar {
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.c100.p10 .bar {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}
.c100.p11 .bar {
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.c100.p12 .bar {
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.c100.p13 .bar {
  -webkit-transform: rotate(46.800000000000004deg);
  transform: rotate(46.800000000000004deg);
}
.c100.p14 .bar {
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.c100.p15 .bar {
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}
.c100.p16 .bar {
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.c100.p17 .bar {
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.c100.p18 .bar {
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.c100.p19 .bar {
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.c100.p20 .bar {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}
.c100.p21 .bar {
  -webkit-transform: rotate(75.60000000000001deg);
  transform: rotate(75.60000000000001deg);
}
.c100.p22 .bar {
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.c100.p23 .bar {
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.c100.p24 .bar {
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.c100.p25 .bar {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.c100.p26 .bar {
  -webkit-transform: rotate(93.60000000000001deg);
  transform: rotate(93.60000000000001deg);
}
.c100.p27 .bar {
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.c100.p28 .bar {
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.c100.p29 .bar {
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.c100.p30 .bar {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}
.c100.p31 .bar {
  -webkit-transform: rotate(111.60000000000001deg);
  transform: rotate(111.60000000000001deg);
}
.c100.p32 .bar {
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.c100.p33 .bar {
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.c100.p34 .bar {
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.c100.p35 .bar {
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}
.c100.p36 .bar {
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.c100.p37 .bar {
  -webkit-transform: rotate(133.20000000000002deg);
  transform: rotate(133.20000000000002deg);
}
.c100.p38 .bar {
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.c100.p39 .bar {
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.c100.p40 .bar {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}
.c100.p41 .bar {
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.c100.p42 .bar {
  -webkit-transform: rotate(151.20000000000002deg);
  transform: rotate(151.20000000000002deg);
}
.c100.p43 .bar {
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.c100.p44 .bar {
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.c100.p45 .bar {
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}
.c100.p46 .bar {
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.c100.p47 .bar {
  -webkit-transform: rotate(169.20000000000002deg);
  transform: rotate(169.20000000000002deg);
}
.c100.p48 .bar {
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.c100.p49 .bar {
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.c100.p50 .bar {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.c100.p51 .bar {
  -webkit-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.c100.p52 .bar {
  -webkit-transform: rotate(187.20000000000002deg);
  transform: rotate(187.20000000000002deg);
}
.c100.p53 .bar {
  -webkit-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.c100.p54 .bar {
  -webkit-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.c100.p55 .bar {
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
}
.c100.p56 .bar {
  -webkit-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.c100.p57 .bar {
  -webkit-transform: rotate(205.20000000000002deg);
  transform: rotate(205.20000000000002deg);
}
.c100.p58 .bar {
  -webkit-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.c100.p59 .bar {
  -webkit-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.c100.p60 .bar {
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
}
.c100.p61 .bar {
  -webkit-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.c100.p62 .bar {
  -webkit-transform: rotate(223.20000000000002deg);
  transform: rotate(223.20000000000002deg);
}
.c100.p63 .bar {
  -webkit-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.c100.p64 .bar {
  -webkit-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.c100.p65 .bar {
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
}
.c100.p66 .bar {
  -webkit-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.c100.p67 .bar {
  -webkit-transform: rotate(241.20000000000002deg);
  transform: rotate(241.20000000000002deg);
}
.c100.p68 .bar {
  -webkit-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.c100.p69 .bar {
  -webkit-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.c100.p70 .bar {
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
}
.c100.p71 .bar {
  -webkit-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.c100.p72 .bar {
  -webkit-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.c100.p73 .bar {
  -webkit-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.c100.p74 .bar {
  -webkit-transform: rotate(266.40000000000003deg);
  transform: rotate(266.40000000000003deg);
}
.c100.p75 .bar {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.c100.p76 .bar {
  -webkit-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.c100.p77 .bar {
  -webkit-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.c100.p78 .bar {
  -webkit-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.c100.p79 .bar {
  -webkit-transform: rotate(284.40000000000003deg);
  transform: rotate(284.40000000000003deg);
}
.c100.p80 .bar {
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
}
.c100.p81 .bar {
  -webkit-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.c100.p82 .bar {
  -webkit-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.c100.p83 .bar {
  -webkit-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.c100.p84 .bar {
  -webkit-transform: rotate(302.40000000000003deg);
  transform: rotate(302.40000000000003deg);
}
.c100.p85 .bar {
  -webkit-transform: rotate(306deg);
  transform: rotate(306deg);
}
.c100.p86 .bar {
  -webkit-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.c100.p87 .bar {
  -webkit-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.c100.p88 .bar {
  -webkit-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.c100.p89 .bar {
  -webkit-transform: rotate(320.40000000000003deg);
  transform: rotate(320.40000000000003deg);
}
.c100.p90 .bar {
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
}
.c100.p91 .bar {
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.c100.p92 .bar {
  -webkit-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.c100.p93 .bar {
  -webkit-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.c100.p94 .bar {
  -webkit-transform: rotate(338.40000000000003deg);
  transform: rotate(338.40000000000003deg);
}
.c100.p95 .bar {
  -webkit-transform: rotate(342deg);
  transform: rotate(342deg);
}
.c100.p96 .bar {
  -webkit-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.c100.p97 .bar {
  -webkit-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.c100.p98 .bar {
  -webkit-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.c100.p99 .bar {
  -webkit-transform: rotate(356.40000000000003deg);
  transform: rotate(356.40000000000003deg);
}
.c100.p100 .bar {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.c100:hover {
  cursor: default;
}
.c100:hover > span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
  color: black;
}
.c100:hover:after {
  top: 0.04em;
  left: 0.04em;
  width: 0.92em;
  height: 0.92em;
}
.c100.dark {
  background-color: #777777;
}
.c100.dark .bar,
.c100.dark .fill {
  border-color: #c6ff00 !important;
}
.c100.dark > span {
  color: #777777;
}
.c100.dark:after {
  background-color: #666666;
}
.c100.dark:hover > span {
  color: #c6ff00;
}
.c100.green .bar,
.c100.green .fill {
  border-color: #4db53c !important;
}
.c100.green:hover > span {
  color: #4db53c;
}
.c100.green.dark .bar,
.c100.green.dark .fill {
  border-color: #5fd400 !important;
}
.c100.green.dark:hover > span {
  color: #5fd400;
}
.c100.orange .bar,
.c100.orange .fill {
  border-color: #dd9d22 !important;
}
.c100.orange:hover > span {
  color: #dd9d22;
}
.c100.orange.dark .bar,
.c100.orange.dark .fill {
  border-color: #e08833 !important;
}
.c100.orange.dark:hover > span {
  color: #e08833;
}

.optedout-wrapper{display: flex; position: absolute; left:0; top:0; right:0; bottom:0; width: 100%; height:100%; display: flex; align-items: center; justify-content: center; background-color: var(--color1); font-family:arial;}
.optedout-wrapper .optedout-container{box-shadow: 0 12px 22px 0 rgba(72, 145, 152, 0.2); position: relative; background-color:#fff; width: 100%;  max-width: 400px; display: flex; flex-direction: column; border-radius: 10px; padding:40px 40px 20px;}
.optedout-wrapper .optedout-container img{max-width: 100%; margin: 0 auto 15px;}
.optedout-wrapper .optedout-container > div{font-size: 24px; color: #000; text-align: center;}
.optedout-wrapper .optedout-container p{font-size: 14px; line-height: 24px; color: #999; text-align: center;}
.optedout-wrapper .optedout-container p + div{border-top:1px solid #ddd;  padding-top: 10px; font-size: 14px; line-height: 24px; color: #999; text-align: center; margin-top: 20px;}
.optedout-wrapper .optedout-container a{color:#489198;}
.optedout-wrapper .optedout-container a:hover{text-decoration: none;}
.assessment-div {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.assessment-head {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 120px;
    border-bottom: 1px solid #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
}
.assessment-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 95px;
    border-top: 1px solid #dddddd;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 80px;
    padding-right: 80px;
    background-color: var(--white);
}

.widget-div{
	display: flex;
    /* justify-content: space-between; */
    border: 0px solid;
    width: 100%!important;
    height: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f2f1ef;
}

.widget-div-first-child{
	width:250px;
  font-size: 15px;
  padding:0px;
  background-color: #fff;
  position: fixed;
  left: -250px;
  z-index: 220000;
  height: 100%;
  transition: all 0.2s ease;

}

.row-type-content{
  height:25% !important;
}

.row-type-content button{
  position: fixed;
  top: 406px;
}

.row-type-content input{
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 5px 16px;
    border-radius: 4px;
    width: 160px;
    width: 100%;
}

.widget-div-first-child.widgetsidebaropen{left:0;height:100%;overflow:scroll;}
.widget-div-first-child.widgetsidebaropen .settings-customizer{left:250px}

.settings-customizer{position: fixed; transition: all 0.2s ease; top:50%; cursor: pointer; margin-top: -1.5rem; box-shadow: -0.15rem 0 1.75rem 0 rgb(34 39 46 / 15%); left:0px; border-radius:0px 6px 6px 0px; display: flex; width: 3rem; height: 3rem; align-items: center; justify-content: center; background-color:#fff;}

/* .settings-customizer img{animation: fa-spin 2s infinite linear;} */

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.widget-css-setting{padding:10px;}
.second-child {display: flex; align-items: center;  font-family: Lato; font-size: 13px;}
.setting .second-child input[type="checkbox"]{margin:0 0px 0 0};

.second-child-page-url{
  width: 100%;
}
.second-child select{font-family: Lato; font-size: 13px;}

.second-child-page-url .multi-select{width:100%; margin-left: 0;}
.second-child-page-url .multi-select .dropdown-container{height: auto;}
h1{font-size: 16px; margin-bottom: 20px;}

.widget-heading{ border-bottom:0px solid #000;  padding-bottom: 5px; margin: 15px;}

.react-colorful{
	width: 100%!important;
    height: 156px;
}

.widget-div-second-child{
	width:calc(100% - 250px);
  width:100%;
  overflow-y: auto;

}

.first-child{
  width: 100%;
  font-family: lato;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 10px;
  border-bottom:1px solid #dee2e6 ;
  border-top:1px solid #dee2e6 ;
  padding-bottom: 5px;
  background-color: #f8f9fa;
  padding: 5px 10px;
  color:#495057;
  text-transform: capitalize;

}

hr{
  background-color: var(--primary) !important;
}

.second-child{
	    margin-left: 0px;
}
.setting{
	margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  
}

li img{
	width: 100px;
}

.characters {
  list-style: none;
  padding-left: 0;
}

.characters li {
  display: flex;
  align-items: center;
  border: solid 2px #d0d0d0;
  border-radius: .2em;
  padding: .5em .8em .5em .5em;
  margin-bottom: 1em;
}

.characters p {
  max-width: none;
  font-weight: bold;
  margin: 0;
}

.characters-thumb {
  overflow: hidden;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  background-color: #e8e8e8;
  padding: .5em;
  margin-right: .5em;
}

.characters-thumb img {
  display: block;
  width: 100%;
  height: auto;
}

.App-widget{
	width: 100% !important;
	border:0px solid !important;;
}

.App-header-widget{
	position: unset !important;
	top: 15px;
    width: 260px;
    background-color: transparent;
    left: 15px;
    height: calc(100vh - 30px);
    padding-top: 20px;
    padding-bottom: 20px;
}

.widget-setting-button{display: flex; width: 100%; padding:10px; justify-content: center; background-color: #fff; box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%); position: sticky; position: -webkit-sticky;  top:0; z-index: 220000;}
.widget-setting-button .widget-button{padding:2px 15px; margin-left: 15px; color:#fff}
.widget-setting-button .widget-button:first-child{margin-left: 0;}

.setting input,select {
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 5px 16px;
    border-radius: 4px;
    width:160px;
    width:100%;
}

.setting input[type="checkbox"]{
  width: 29px;
}


.dashboard-main {
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.widget-button{
    color: var(--white);
    border-color:var(--secondary);
    background-color: var(--secondary);
    display: inline-block;
    font-weight: 400;
    /* color: #212529; */
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 8px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.facebookLoader { -webkit-animation-duration: 1s; animation-duration: 1s; -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;  -webkit-animation-iteration-count: infinite;  animation-iteration-count: infinite; -webkit-animation-name: placeHolderShimmer; animation-name: placeHolderShimmer; -webkit-animation-timing-function: linear; animation-timing-function: linear;  background: #f6f7f8;  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);background-size: 1000px 104px;  height: 100px;  position: relative;  overflow: hidden;
}
.facebookLoader .inter-crop {background: #FFF; width: 20px; height: 100%;  position: absolute; top: 0;  left: 100px;}

.facebookLoader .inter-right--top {background: #FFF; width: 100%; height: 20px;  position: absolute; top: 20px;  left: 100px;}

.facebookLoader .inter-right--bottom { background: #FFF; width: 100%; height: 20px; position: absolute;  top: 60px; left: 100px;}

@-webkit-keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.sub-div-01 span{
  font-family:"lato";
}

.widget-view-a{
  color: black;
  font-weight: 600;
}

/*.sub-div-01 div {
  margin-top:10px;
}

.sub-div-01 div span {
    width: 200px;
    padding: 10px;
}*/
/*.banner-type-content{
  height: 30% !important;
  width: 31% !important;
}*/

#type-button{
  position: fixed;
  top:500px;
}


.dashboard-right {
  /*position: fixed;*/
  z-index: 10;
  width: 30vw;
  right: 50px;
}

.dashboard-profile {
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:0px;
}

.close{
  z-index: 1;
  cursor: pointer;
}

.referral-end-content{
  height:200px;
}

.error{
  color: red;
  font-family: 'Lato';
  font-size: 16px;
}

.dash-prof-img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white) !important;
  border: 1px dashed var(--primary);
  box-shadow: 5px 5px 10px 0px var(--primary-rgba);
}

.dash-prof-img img {
  width: 70px;
}

.dash-prof-img-in {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dash-prof-name {
  /*margin-top: 5px;
  margin-bottom: 5px;*/
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  color: var(--text-black);
}

.drop-profile-creds div {
    max-width:300px;
    overflow: hidden;
    font-family: Lato;
    font-weight: 600;
    font-size: 15px;
    background-color: #2DA77D;
    color: var(--white);
    display: inline-block;
    width: -webkit-max-content;
    width: max-content;
    margin-top: 10px;
    margin-top: 2px;
    padding: 8px 12px 9px 12px;
    border-radius: 8px;
    margin-top: 2%;
    margin-bottom: 0px;
}

.drop-profile-creds-faculty div{
  max-width: 140px;
    overflow: hidden;
    font-family: Lato;
    font-weight: 600;
    font-size: 15px;
    background-color: var(--primary);
    color: var(--white);
    /* display: inline-block; */
    /* width: max-content; */
    /* margin-top: 10px; */
    margin-bottom: 10px;
    padding: 8px 12px 9px 12px;
}

.dashboard-profile .drop-profile-creds {
  margin-left: 0;
  text-align: center;
  line-height: 1.4;
  cursor: pointer;
  margin-bottom:10px;
  font-family:lato;
}

.dashboard-profile .drop-profile-creds:hover {
  opacity: 0.85;
}

.dashboard-title{display: flex; width: 100%; padding: 0 15px 15px;  justify-content: space-between; align-items: center;}
.dashboard-title .drop-profile-creds{margin:0; padding:0; font-family: Source Sans Pro;
  font-size: 18px; flex-direction: column; display: flex;
  font-weight: 600;
  color: var(--primary);} 
.dashboard-title .drop-profile-creds span{width:50px; margin-top: 5px; display: flex; height: 2px; background-color: #000;}
.dashboard-title .viewall-btn{padding: 0;background-color: var(--primary) !important;}

.dashboard-schedule {
  z-index: 10;
  bottom: 25px;
  /* right: 50px; */
  width: 100%;
  /* height: calc(100vh - 440px); */
  background-color: var(--primary-rgba);
  border-radius: 6px;
  padding: 24px 0px 100px 0px;
  /* overflow-y: auto; */
  margin-top: 40px;
  margin-left: 34px;
}

.dashboard-left .dashboard-schedule{display: none;}

.dash-sch-head {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:nth-child(2) span {
    border: 1px solid inherit;
    text-decoration: none;
    display: flex;
    color:inherit;
    padding: 5px 15px;
    border-radius: inherit;
    background-color:inherit;
}

.quick-links-widget button{
  font-size: 14px;
}

.dash-sch-head div {
  display: flex;
  font-family: Lato;
  color: var(--text-black2);
  font-size: 16px;
  font-weight: 600;
  flex-direction: column;
}

.dash-sch-head span {
  color: var(--primary);
  font-size: 18px;
  margin-bottom: 6px;
}

.dash-lecture {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

#activity_type {
  position: absolute;
  background-color: var(--primary);
  color: var(--white);
  font-family: Lato;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  /* right: 30px;
  bottom: 0px; */
  text-transform: uppercase;
  margin-left: 0px;
  margin-top: -19px;
}

.dash-lecture:last-child {
  margin-bottom: 0;
}

.dash-lecture-date {
  /*width: 118px;*/
  padding-left: 18px;
  padding-right: 11px;
  border-right: 1px solid var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: var(--secondary);
  padding-top: 14px;
  padding-bottom: 14px;
  white-space: nowrap;
}

.dash-lecture-date div {
  font-size: 16px;
  color: var(--text-black);
}

.dash-lecture-date span {
  font-size: 14px;
  color: var(--primary);
  margin-top: 5px;
  margin-bottom: 5px;
}

.dash-lecture-date-third{
  padding-left: 20px;
  /* padding-right: 66px; */
  /* border-right: 1px solid var(--white); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Lato;
  width: 100%;
}

.dash-lecture-date-third div {
  /* background-color: #c5e5ea; */
  color: black;
  font-family: Lato;
  /* font-size: 12px; */
  /* font-weight: 600; */
  padding: 3px 15px;
  /* text-transform: uppercase; */
  /* width: 100px; */
  text-align: center;
  white-space: nowrap;
}


.dash-lecture-date-third div:first-child {
  text-transform: uppercase;
  background-color: #c5e5ea;
  border: 1px solid #e3e3ec;
}

.dash-lecture-date-third div:last-child {
  font-family: Lato;
  /* padding: 3px 15px; */
  /* border: 2px solid #c5e5ea; */
  /* text-align: center; */
  white-space: nowrap;
  /* border-radius: 17px; */
  color: white !important;
  margin-top: 5px;
}

.dash-lecture-date-third div:last-child a{
  color: white !important;
  margin-right: 4px;
  padding: 4px;
  text-align: center;
  background-color: var(--primary);
  border-radius: 5px;
}

.dash-lecture-date-third span {
  color: #000000;
}

.dash-lecture-title {
  padding-left: 20px;
  padding-right: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  font-family: Lato;
  justify-content: center;
}

.dash-lecture-title a, .dash-lecture-title span {
  margin-top: 4px;
  font-family: Lato;
  color: var(--primary);
  font-weight: 700;
  font-size: 15px;
}

.dash-lecture-title a:hover {
  opacity: 0.85;
}

.dash-lec-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-black);
  display: flex;
  /* overflow: hidden; */
  /* -webkit-box-decoration-break: clone; */
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  line-height: 1.4;
  /* max-height: 23px; */
  /* -webkit-line-clamp: 1; */
  /* -webkit-box-orient: vertical; */
  /* max-width: 128px; */
  word-break: break-word;
  width: 100%;
  /* border: 1px solid; */
  white-space: normal;
}

.dash-lec-title-hrs{
  font-size: 14px;
  font-weight: 600;
  color:var(--black);
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  line-height: 1.4;     /* fallback */
  max-height: 23px;      /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-width: 180px;
}

.dash-lec-hrs {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-black3);
  margin-top: 8px;
  margin-bottom: 6px;
  display: -webkit-box;
  /*overflow: hidden;*/
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  line-height: 1.4;     /* fallback */
  /*max-height: 20px; */     /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  /*-webkit-box-orient: vertical;*/
}

.dash-lec-hrs-faculty{
  color:var(--black);
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 6px;
}

.dashboard-left {
  width: 40vw;
}

.dashboard-main h1 {
  font-size: 20px;  
} 

.dashboard-subjects {
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.dash-subject {
  width: 30%;
  height: 150px;
  margin-bottom: 30px;
  border-radius: 10px;
  transition: .2s all;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px;
}
.dash-subject-fac {
  width: 30%;
  height: 150px;
  margin-bottom: 30px;
  border-radius: 10px;
  transition: .2s all;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.dash-subject span {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: var(--white);
  text-decoration: none;
  text-align: center;
  line-height: 1.36;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  height: 39px;      /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.dash-subject-fac span {
  font-family: Lato;
  font-size: 11px;
  font-weight: 600;
  color: var(--white);
  text-decoration: none;
  text-align: center;
  line-height: 1.36;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  height: 39px;      /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.dash-fac-detail {
  font-size: 15px;
  line-height: 1.36;
}
.dash-fac-detail span{
  margin-top: 6px;
}

.dashboard-practices {
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.dash-practice {
    width: 47%;
    height: 150px;
    margin-bottom: 30px;
    border-radius: 10px;
    transition: .2s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    color: var(--white);
    text-decoration: none;
    background-color: var(--primary-rgba);
    padding: 16px 18px;
    background-repeat: no-repeat;
    background-position: 160px 7px;
    box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2);
}

.dash-practice:nth-child(2n) {
  margin-left: 6%;
}

#dash-practice1 {
  background-color: var(--secondary-rgba);
}

.dash-practice span {
  width: 100%;
  color: var(--text-black2);
}

.dash-practice div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dash-practice button {
  /*font-size: 16px !important;*/
  white-space: nowrap !important;
  /*padding: 5px 14px 6px 14px !important;*/
  padding: 5px 10px 5px 10px !important;
  /*background-color: #c5e5ea !important;*/
  color: var(--white);
}

.ra-button{
  white-space: nowrap !important;
  padding: 1px !important;
  background-color:var(--primary-rgba) !important;
  color: #489198 !important;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
  border: 1px solid var(--primary);
  background-color: var(--primary);
  border: none;
  padding: 6px 20px 8px 20px;
  border-radius: 40px;
  cursor: pointer;
}

.dash-practice a {
  margin-right: 10px;
}

.dash-practice a:last-child {
  margin-right: 0px;
}

.dash-subject div {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.dash-subject-fac div {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.dash-subject:hover {
  box-shadow: 4px 4px 12px -6px var(--text-black2);
}
.dash-subject-fac:hover {
  box-shadow: 4px 4px 12px -6px var(--text-black2);
}

.dash-subject:nth-child(3n), .dash-subject:nth-child(3n - 1) {
  margin-left: 5%;
}
.dash-subject-fac:nth-child(3n), .dash-subject-fac:nth-child(3n - 1) {
  margin-left: 5%;
}

.dash-subject img {
  width: 40px;
}
.dash-subject-fac img {
  width: 40px;
}

.dashboard-exercise {
  margin-top: 40px;
}

.dashboard-exercise a {
  display: flex;
  align-items: center;
}

.dashboard-exercise h1 {
  margin-bottom: 0;
}

.dashboard-exercise a img {
  width: 18px;
  margin-left: 18px;
}

.dashboard-category {
  margin-top: 40px;
}

.dashboard-category a {
  display: flex;
  /*align-items: center;*/
}

.dashboard-category .careerBoosterLink{align-items: flex-start;}

.dashboard-category h1 {
  margin-bottom: 0;
}

.dashboard-category a img {
  width: 18px;
  margin-left: 18px;
}

.dashboard-category .course-image-div  img {
  width: 177px;
  height: 128px;
  object-fit: contain;
  padding-top: 16px;
}

.dashboard-assignment-list{
    display: flex;
    margin-top: 25px;
  }
  .dashboard-assignment-list a{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 15px;
    box-shadow: 0px 6px 8px #1D73F238;
    padding: 15px;
    border-radius: 12px;
    position: relative;
    margin-right: 10px;
  }

.welcomeheading{display: flex; margin-bottom:25px; line-height: 28px; font-family: Lato; font-size: 18px;}
.welcomeheading span{font-weight: bold; color:var(--primary); margin-left:10px;}

  .dashboard-assignment-list a:nth-child(1){
    background: transparent linear-gradient(122deg, #FF8A57 0%, #FF5502 100%) 0% 0% no-repeat padding-box;
  }
  .dashboard-assignment-list a:nth-child(3){
    background: transparent linear-gradient(121deg, #7ecacd 0%, #129ac2 100%) 0% 0% no-repeat padding-box;
  }

  .dashboard-assignment-list a:nth-child(2){
    background: transparent linear-gradient(121deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;
  }

  .dashboard-list-downarrow {cursor: pointer; width:100%; color:#000; display: flex; justify-content: center; align-items: center; height:40px; margin-top:-50px; position: relative; background: rgb(255,255,255);
background: linear-gradient(97deg, rgba(255,255,255,0.31976540616246496) 5%, rgba(250,254,255,1) 48%, rgba(255,255,255,0.5550595238095238) 85%); }

  .dashboard-assignment-list a .arrow {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: rgba(255,255,255,0.2);
  }

   .dashboard-assignment-list a .icon {
    width: 48px;
    height: 48px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }


.all-courses {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 20px;
}

.goto-course {
  display: block;
  position: relative;
  width: 30%;
  height: 200px;
  border-radius: 15px;
  background-color: var(--primary-rgba);
  border: 1px solid #dddddd;
  overflow: hidden;
  cursor: pointer;
}

.goto-course-list{
  display: block;
  position: relative;
  height: 200px;
  border-radius: 3px;
  /*background-color: var(--primary-rgba);*/
  border: 1px solid #dddddd;
  overflow: hidden;
  cursor: pointer;
}

.goto-course:hover img {
  -webkit-transform: scale(1.32);
          transform: scale(1.32);
}

/*.goto-course-list:hover img {
  transform: scale(1.32);
}*/

.goto-course:nth-child(3n), .goto-course:nth-child(3n - 1) {
  margin-left: 5%;
}

.goto-course-list:nth-child(3n), .goto-course-list:nth-child(3n - 1) {
  margin-left: 5%;
}

.course-image {
  width: 90%;
  height: 140px;
  top: 0;
  overflow: hidden;
}

.course-image-div {
  width: 100%;
  height: 100%;
  /*background-color: var(--secondary-rgba);*/
  transition: .3s all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.course-image-div img {
  width: 60px;
  transition: .3s all;
}

.goto-course span{
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  color: var(--text-black);
  text-decoration: none;
}

.goto-course-list span {
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.59;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.course-div {
  width: 30%;
  display: block;
  position: relative;
  height: 200px;
  border-radius: 15px;
  border: 1px solid #eeeeee;
  overflow: hidden;
  cursor: pointer;
}

.course-div div:first-child {
  /* position: absolute; */
  /* top: 0; */
  height: 100px;
  width: 100%;
  background-color: var(--secondary-rgba);
  display: flex;
  align-items: center;
  justify-content: center;
}

.course-div div img {
  height: 60px;
  transition: .2s all;
}

.course-div:hover img {
  -webkit-transform: scale(1.16);
          transform: scale(1.16);
}

.course-div div:last-child {
  line-height: 1.5;
  color: var(--text-black);
  font-family: Lato;
  font-size: 15px;
  /* padding-left: 50px; */
  /* padding-right: 15px; */
  padding-top: 25px;
  /* padding-bottom: 20px; */
  justify-content: center;
  text-align: center;
}

.course-div:nth-child(3n), .course-div:nth-child(3n - 1) {
  margin-left: 5%;
}

.courses-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.courses-popup span {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  color: var(--text-black);
}

.courses-popup-tray {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -15px !important;
}

.courses-popup-tray li {
  min-width: 180px;
  max-width: 180px;
  height: 200px;
  margin-left: 17px;
  margin-right: 17px;
  display: block;
  position: relative;
}

.courses-popup-tray .course-div {
  width: 100% !important;
  margin-right: 0;
  margin-left: 0;
}

.dash-lec-title-list{
      /* padding-left: 20px; */
    padding-right: 30px;
    /* padding-bottom: 20px; */
    display: flex;
    flex-direction: column;
    /* font-family: Lato; */
    justify-content: center;
    /* width: 416px; */
    border: 1px solid;
}


  .dash-lecture-date-title{    
    width: 400px;
    /* padding-right: 25px; */
    /* border-right: 1px solid var(--white); */
    display: inline-block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    color: var(--secondary);
    padding-top: 14px;
    padding-bottom: 14px;
    /* white-space: nowrap; */
    /* margin-right: 75px; */
    margin-left: 8px;
  }

  .dash-lecture-date-title{width:100%; display:flex; flex-direction: row; align-items: flex-start;}
  .dash-lecture-date-title .left{width:100%; display:flex; flex-direction: column; align-items: flex-start;}

  .dashboard-main-learner{
    z-index: 10;
    width: 100%;
    /* display: flex; */
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
  }

  .dashboard-main-learner span{
    color:#415973 !important;
  }

  .category-list{
    width:200px;
    margin-right: 20px;
    margin-bottom: 16px;
    box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2)
  }

  .dashboard-practices-course{
    margin-top: 18px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }


  /* .column1x3{
    width:100% !important;
  }
  .column1x2{
    width:66.66% !important;
  }
  .column1x1{
    width:33.33% !important;
  } */

  .dash-flex2-title-label{
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    line-height: 1.35;
    /* font-size: 16px; */
    font-size: 13px;
    margin-bottom: 0px;
    max-height: 44px;
    color:var(--black) !important;
    padding-top:3px;
    margin-top: 2px;
  }

  .dash-flex2-title-1{
    font-size:14px !important;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    line-height: 1.35;
    margin-bottom: 14px;
    max-height: 44px;
  }

  .dash-subject-fac{position: relative;}
  .dash-subject-fac .classMap{display:flex; font-family: Lato; padding: 0 0 5px; white-space:normal; flex-wrap: wrap; width:100%; height:auto; margin:0;}
  .dash-subject-fac .classMap div{display:flex; width:100%; height:auto; align-items: flex-start; margin:1px 0; color:#fff; font-size:12px;}
  .dash-subject-fac .classMap div i{width:100%; max-width:72px; text-align: center; font-style: normal; white-space: nowrap; margin-right:4px; overflow:hidden; display: block; font-size:12px;}
  .dash-subject-fac .classMap .morecontent{width:100%; margin-top:10px; font-size:12px; color:#fff; height:auto; display: flex; justify-content: center;}
  .dash-subject-fac .hoverOverlay{position: absolute; border-radius: 10px; padding:10px; transition:all 0.2s ease; height:0px; width:100%; font-family: Lato; margin:0; opacity: 0; visibility: hidden; top:0; right:0; bottom:0; left:0; background-color:rgba(0,0,0,0.8);}
  .dash-subject-fac .hoverOverlay ul{display: flex; flex-direction: column; margin:0; padding:0; width: 100%; font-family: Lato;}
  .dash-subject-fac .hoverOverlay ul li{display: flex;margin:2px 0; width: 100%; justify-content: flex-start; color:#fff; font-size:12px; font-family: Lato;}
  .dash-subject-fac .hoverOverlay ul li i{max-width: 80px; white-space: nowrap; overflow: hidden; margin-right:5px; justify-content: flex-start; font-family: Lato;}
  .dash-subject-fac .hoverOverlay ul li p{margin:0}
  .dash-subject-fac:hover .hoverOverlay{height:100%; opacity: 1; visibility: visible;}


/*.IADashboardWrapper1 Css Start From here*/



.IADashboardWrapper1{display: flex; flex-direction: column; width: 100%; font-family: Lato;}
.IADashboardWrapper1 .main-layout-section{display: flex; width: 100%; flex-direction: column!important;}
.IADashboardWrapper1  .rowWrapper{display:flex; width:100%; flex-direction: column; overflow-x: auto; position: relative;}
.IADashboardWrapper1  .rowWrapper .rowInner {display:flex; width:100%;}

.overlay-actions{visibility:hidden; display: flex; padding:5px 10px; background-color: #e1e1e1; z-index:1; align-items: center; opacity: 0; border-radius:5px; position: absolute; font-family: lato; font-weight: 400; right:10px; top:10px;}
.overlay-actions a{margin-left: 10px; display: flex; align-items: center; font-family: lato; text-decoration: underline; color:#000; font-weight: 400; font-size: 14px;}
.overlay-actions a:first-child{margin-left:0px;}
.overlay-actions a img{max-width: 20px; margin-right: 5px;}
.overlay-actions a:hover{text-decoration: none;}
.IADashboardWrapper1 .rowWrapper .rowWidget{min-height: 45px;}
.IADashboardWrapper1 .rowWrapper .rowWidgetPreview{min-height: 11px;}
.IADashboardWrapper1 .rowWrapper h3{font-size: 18px; padding:10px; width:100%; margin:0; display: flex;  font-weight: bold;}

.IADashboardWrapper1 .column-widget.rowInnerBorder{border:2px dashed  #000!important}
.IADashboardWrapper1 .rowWrapperBorder{border:2px dashed  #000!important}


.IADashboardWrapper1  .rowWrapper .column-widget:hover .overlay-actions{visibility:visible; opacity: 1;}
.IADashboardWrapper1  .rowWrapper .rowWidget:hover .overlay-actions{visibility:visible; opacity: 1;}
.IADashboardWrapper1 a{color:inherit}

.IADashboardWrapper1 .main-layout-section .left-section-layout{display: flex; flex-direction: column; width: 100%; max-width: 33.333%;}
.IADashboardWrapper1 .main-layout-section .right-section-layout{display: flex; flex-direction: column; width: 100%; max-width: 66.666%}

.IADashboardWrapper1 .IADashboardContainer1{display: flex; width: 100%; flex-direction: column;}
.IADashboardWrapper1 .userinfo-widget{display: flex; width: 100%; flex-direction: column; border:0px solid inherit; padding:0px; border-radius:inherit;}
.IADashboardWrapper1 .userinfo-widget div{font-weight: inherit; color:inherit;  font-size: inherit; margin-bottom: 15px;}
.IADashboardWrapper1 .userinfo-widget > div:first-child{margin-bottom: 0;}
.IADashboardWrapper1 .userinfo-widget p{ margin:0; padding:0; margin-top:5px; white-space: normal; line-height:24px; font-size:inherit; color:inherit;}

.IADashboardWrapper1 .userinfo-widget .explorebtn{display: flex; margin:15px 0 0}
.IADashboardWrapper1 .userinfo-widget .explorebtn a{ border: 1px solid inherit;background: transparent;border: 1px solid white;box-shadow: 0px 1px 0px 4px #555; text-decoration: none; color:x;  padding: 8px 15px; font-size: 17px;  border-radius: 5px;}

.IADashboardWrapper1 .column-widget{display: flex; flex-shrink: 0; position: relative; margin:0px;padding:6px; border-radius:inherit; flex-direction: column; width:inherit}

.IADashboardWrapper1 .column-widget.column1x1{width: 33.333333333%;}
.IADashboardWrapper1 .column-widget.column1x2{width:66.66666666%;}
.IADashboardWrapper1 .column-widget.column1x3{width: 100%;}
.IADashboardWrapper1 .column-widget.column2x1{width: 33.333333333%;}
.IADashboardWrapper1 .column-widget.column2x2{width:66.66666666%;}
.IADashboardWrapper1 .column-widget.column2x3{width: 100%;}



.col-75-partition{
  display: flex;
}

.col-75-partition select{
  width:68%;
}

.popup-content.banner-type-content .col-75 .multi-select{
  width: 68% !important;
  justify-content: space-between;
}

.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item.paid-course-single-item:first-child {
    background: transparent linear-gradient(122deg,#ff8a57,#ff5502) 0 0 no-repeat padding-box;margin-bottom: 0;
}

.IADashboardWrapper1 .courses-widget.image-jugad ol.referal-widget li{ background-repeat: no-repeat; background-position: center; background-size: cover; background-image: url(https://test-rakeshxp.s3.ap-south-1.amazonaws.com/My+Coach+Banner.png);}
.IADashboardWrapper1 .courses-widget.image-jugad ol.referal-widget li .course-thumb{opacity: 0;}
.IADashboardWrapper1 .courses-widget.image-jugad ol.referal-widget li .course-title{opacity: 0;}
.IADashboardWrapper1 .courses-widget.image-jugad ol.referal-widget li .course-description{opacity: 0;}

.IADashboardWrapper1 .courses-widget.className-widget-heading .userinfo-widget{
  padding:0;
  flex-direction: row;
}

.IADashboardWrapper1 .courses-widget.className-widget-heading .userinfo-widget h3{padding:0}
.IADashboardWrapper1 .userinfo-widget div{margin-bottom: 0;}
.IADashboardWrapper1 .userinfo-widget div:first-child{margin-right: 0px;}
.IADashboardWrapper1 .userinfo-widget div:last-child{margin-right:0px;}
.IADashboardWrapper1 .userinfo-widget div p{margin-top:0px;}

.IADashboardWrapper1 .className-widget-heading img {
    max-width: 50px !important;
}

.carousel .slide{text-align: inherit;}

.IADashboardWrapper1 .courses-widget.className-widget-heading{
      display: flex;
    box-shadow: 0 0 0 0;
    padding-top: 0;
    margin: 15px 0 0;
    padding-bottom: 0;
}

.admin-loader-v1{
    border: 4px solid #ffffff;
    border-radius: 50%;
    border-top: 4px solid #80f6a3;
    width: 50px;
    height: 50px;
    -webkit-animation: admin-spin 1s linear infinite;
            animation: admin-spin 1s linear infinite;
}


.IADashboardWrapper1 .courses-widget .quick-links-widget{display: flex; border-radius:5px; width: 100%; background-color: #fff; flex-direction: column; padding:0px;}
.IADashboardWrapper1 .courses-widget .quick-links-widget .heading-title{font-weight: bold; margin-bottom: 10px; color:#000; font-size: 19px; text-align: center;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol{margin:0; padding: 0;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li{margin:10px 0 0; flex-direction: row; font-weight: 500;  line-height: 22px; font-size: 14px; max-width: none; border-radius:5px; padding: 10px; border:1px solid #ddd; align-items: center;  list-style-type: none; justify-content: space-between; width: 100%; display: flex;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol{max-height: 261px; overflow-y: auto;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li .notesTitle{text-transform:capitalize;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li .videoTitle{text-transform:capitalize;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li .action-button{padding: 5px 10px; border-radius:5px; color:#fff;  background-color: var(--primary); margin-right:0;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li:first-child{margin-top:0}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:first-child{flex:1 1;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:first-child a{background-color: #3ac47d; color:#fff}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:last-child{width:130px; padding: 10px; color:#999; display: flex; justify-content: center;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:last-child a{border:1px solid #ddd; text-decoration: none; color:#fff; padding:5px 15px; border-radius:5px;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li:nth-child(even) div:last-child a {background-color:var(--primary); border-radius:40px}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li div:nth-child(2) a {border:1px solid #ddd; text-decoration: none; display:flex; color:#fff; padding:5px 15px; border-radius:5px; background-color: #1d73f2;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li:nth-child(odd) div:last-child a {background-color: var(--primary); border-radius:40px;     padding: 6px 20px 6px 20px;
  font-family: Source Sans Pro;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li:nth-child(odd){border-left:4px solid #3ac47d;}
.IADashboardWrapper1 .courses-widget .quick-links-widget ol li:nth-child(even){border-left:4px solid #f7b924;}

.IADashboardWrapper1 .courses-widget{display: flex; width: 100%; flex-direction: column; padding:0px; margin:0px;}
.IADashboardWrapper1 .courses-widget ol{margin:0; padding: 0; flex-wrap: wrap; width: 100%; display: flex;}
.IADashboardWrapper1 .courses-widget ol li{margin:10px 0px; width: 100%; align-items: center; max-width:none; padding: 0; flex-direction: column; display: flex; }
.IADashboardWrapper1 .courses-widget ol li .course-title{font-family:inherit; margin-top: 8px; color:#000; font-size:14px; text-align: center;}
.IADashboardWrapper1 .courses-widget ol li .course-thumb{border:1px solid #ddd; display: block; width: 100%; max-width: 80px; overflow: hidden; height: 80px; max-height: 80px;}



.IADashboardWrapper1 .courses-widget.text-color-custom .userinfo-widget div{color:#000}
.IADashboardWrapper1 .courses-widget.text-color-custom .userinfo-widget p{color:inherit}
.IADashboardWrapper1 .courses-widget.text-color-custom .userinfo-widget div:first-child{margin-bottom: 0;}

.IADashboardWrapper1 .courses-widget.image-left .userinfo-widget{display: flex; flex-direction: row;}
.IADashboardWrapper1 .courses-widget.image-left .userinfo-widget > div:first-child{max-width: 100px; margin-right: 15px;  margin-bottom: 0; display: flex; flex-direction: column;}
.IADashboardWrapper1 .courses-widget.image-left .userinfo-widget > div:last-child{flex:1 1; margin-bottom: 0;}

.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(1) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(2) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(3) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(4) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(5) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(6) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(7) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(8) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(9) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(10) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(11) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random .slick-slide:nth-child(12) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}


.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(1) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(2) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(3) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(4) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(5) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(6) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(7) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(8) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(9) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(10) .userinfo-widget{background-color: #10ca93; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(11) .userinfo-widget{background-color: #00afef; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}
.IADashboardWrapper1 .courses-widget.background-color-random ul li.slide:nth-child(12) .userinfo-widget{background-color: #3a7afe; color:#fff; padding:10px; border-radius:inherit; text-align: inherit;}

.IADashboardWrapper1 .carousel-root .carousel-slider{padding-bottom: 0px;}
.IADashboardWrapper1 .carousel .control-dots{margin: 0;}
.IADashboardWrapper1 .carousel .control-dots .dot{width:12px; height:12px; border:1px solid #2c2c2c;}
.IADashboardWrapper1 .carousel .control-dots .dot.selected{background-color: #ddd; border:1px solid #ddd;}

.IADashboardWrapper1 .courses-widget.background-color-random  .slick-slide p{color: #fff; font-size: 17px;}
.IADashboardWrapper1 .courses-widget.background-color-random  .slick-slide div{color: #fff; font-size: 24px; margin-bottom:0px;}
.IADashboardWrapper1 .column-widget .slick-dots{bottom:0;}
.IADashboardWrapper1 .column-widget .slick-dots li button:before{font-size: 14px;}
/*.IADashboardWrapper1 .slick-slider {margin:0 -30px;}
.IADashboardWrapper1 .slick-slide{margin-left: 15px; margin-right: 15px;}*/

.popup-content.banner-type-content{height:auto; padding: 20px!important; font-family: "lato"; overflow: inherit;}
.popup-content.banner-type-content .sub-div-01 span{font-size: 17px; font-weight: bold;}
.popup-content.banner-type-content .col-75{margin-top: 0;}
.popup-content.banner-type-content .col-75 label{padding:0;}
.popup-content.banner-type-content .row{margin-top: 15px;}
.popup-content.banner-type-content .col-25 label{padding:0;}
.popup-content.banner-type-content #type-button.dialogactions{position:static; margin-top:15px; border-top: 1px solid #ddd; padding-top: 10px; display: flex; width: 100%; justify-content: center;}
.popup-content.banner-type-content .col-75 .multi-select{width: 100%; margin-left: 0;}

.popup-content.banner-type-content .dropdown-content{padding: 10px;}
.popup-content.banner-type-content .dropdown-content label{padding: 10px;}
.popup-content.banner-type-content .multi-select .dropdown-container{height: auto;}

.popup-content.row-type-content{height:auto!important; padding: 20px!important; font-family: "lato"; overflow: inherit;}
.popup-content.row-type-content .sub-div-01 span{font-size: 17px; font-weight: bold;}
.popup-content.row-type-content .col-75{margin-top: 0;}
.popup-content.row-type-content .col-75 label{padding:0;}
.popup-content.row-type-content .row{margin-top: 15px;}
.popup-content.row-type-content .col-25 label{padding:0;}
.popup-content.row-type-content #type-button.dialogactions{position:static; margin-top:15px; border-top: 1px solid #ddd; padding-top: 10px; display: flex; width: 100%; justify-content: center;}
.popup-content.row-type-content #type-button.dialogactions button{position: static;}
.popup-content.row-type-content .dropdown-content{padding: 10px;}
.popup-content.row-type-content .multi-select .dropdown-container{height: auto;}

.banner-type-content input {
    font-family: Lato;
    font-size: 16px;
    background-color: var(--secondary-rgba);
    border: 1px solid var(--primary);
    color: var(--text-black);
    padding: 5px 16px;
    border-radius: 4px;
    width: 160px;
    width: 100%;
}


.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item { background: transparent linear-gradient(122deg,#ff8a57,#ff5502) 0 0 no-repeat padding-box;position: relative; margin: 0 10px;   max-width: none;align-items: flex-start;  padding: 15px;  margin-bottom: 15px;  width: auto!important;  display: flex!important;  border-radius: 12px;  border:1px;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-thumb{ width:70px; height: 70px; flex-shrink: 0; padding:10px; border-radius:100%; background-color: #fff; display: flex; align-items: center; justify-content: center; margin-right: 10px;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-thumb img{max-width: 100%;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-title{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-title a{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-content{display: flex; flex-direction: column;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-desc{font-size: 15px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-description{font-size: 18px; text-align: left; line-height: 24px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .arrow{position: absolute; right: 10px;display: flex;align-items: center;justify-content: center;  top: 10px;  width: 28px;  height: 28px;  border-radius: 100%; background-color: hsla(0,0%,100%,.2);}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .arrow a{display: flex; align-items: center;}  
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .arrow a img{max-width: 100%;}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:first-child{margin-top: 0;}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide{border-radius:5px;}

.IADashboardWrapper1 .courses-widget.paid-course .slick-slide .paid-course-item{border:1px solid #ddd; background-color: #fff; background: #fff; }
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide .paid-course-item  .course-title{color: var(--primary);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide .paid-course-item  .course-title a{color: var(--primary);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide .paid-course-item  .course-description{color:#666; line-height: 30px;}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide .paid-course-item .arrow{background-color: var(--primary);}

.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item{border:1px solid #ddd; background-color: #fff; background: #fff; }
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-title{color: var(--primary);}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-title a{color: var(--primary);}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .course-description{color:#666; line-height: 30px;}
.IADashboardWrapper1 .courses-widget.paid-course .paid-course-item .arrow{background-color: var(--primary);}

/* .IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(1) .paid-course-item {background: transparent linear-gradient(122deg, #FF8A57 0%, #FF5502 100%) 0% 0% no-repeat padding-box; margin-bottom: 0;}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(2) .paid-course-item{background: transparent linear-gradient(123deg, #FFCE00 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(3) .paid-course-item{background:transparent linear-gradient(123deg, #fb00ff 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(4) .paid-course-item{background: linear-gradient(to right, #02aab0, #00cdac);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(5) .paid-course-item{background: linear-gradient(to right, #ffe259, #ffa751);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(6) .paid-course-item{background: linear-gradient(to right, #1a2980, #26d0ce);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(7) .paid-course-item{background: linear-gradient(to right, #ed4264, #ffedbc);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(8) .paid-course-item{background: linear-gradient(to right, #c21500, #ffc500);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(9) .paid-course-item{background: linear-gradient(to right, #00d2ff, #3a7bd5);}
.IADashboardWrapper1 .courses-widget.paid-course .slick-slide:nth-child(10) .paid-course-item{background: linear-gradient(to right, #52c234, #061700);} */


.IADashboardWrapper1 .courses-widget.paid-course{padding-top: 0; padding-bottom: 0;}
.IADashboardWrapper1 .courses-widget.paid-course ol li { position: relative; width:100%; max-width: none; align-items: flex-start; padding: 15px; margin-bottom:15px; border-radius:12px;}
.IADashboardWrapper1 .courses-widget.paid-course ol li .course-title{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper1 .courses-widget.paid-course ol li .course-title a{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper1 .courses-widget.paid-course ol li .course-desc{font-size: 15px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget.paid-course ol li .course-description{font-size: 18px; line-height: 24px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget.paid-course ol li .arrow{    position: absolute; right: 10px;display: flex;align-items: center;justify-content: center;  top: 10px;  width: 28px;  height: 28px;  border-radius: 100%;
  background-color: hsla(0,0%,100%,.2);}
  .IADashboardWrapper1 .courses-widget.paid-course ol li:first-child{margin-top: 0;;}
.IADashboardWrapper1 .courses-widget.paid-course ol li:nth-child(1){background: transparent linear-gradient(122deg, #FF8A57 0%, #FF5502 100%) 0% 0% no-repeat padding-box; margin-bottom: 0;}
.IADashboardWrapper1 .courses-widget.paid-course ol li:nth-child(2){background: transparent linear-gradient(123deg, #FFCE00 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course ol li:nth-child(3){background:transparent linear-gradient(123deg, #fb00ff 0%, #FF8A57 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course ol li:nth-child(4){background:transparent linear-gradient(123deg, #00e1ff 0%, #1e0b03 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course ol li:nth-child(5){background:transparent linear-gradient(123deg, #00dcff 0%, #ff5a14 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course ol li:nth-child(6){background:transparent linear-gradient(123deg, #ff000c 0%, #fd14ff 100%) 0% 0% no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.paid-course ol li .course-thumb{ width:70px; height: 70px; border-radius:100%; background-color: #fff; display: flex; align-items: center; justify-content: center; margin-bottom: 10px;}
.IADashboardWrapper1 .courses-widget.free-course ol li .course-thumb img{max-width: 100%; width: 60px; height: 60px;}

.IADashboardWrapper1 .courses-widget.free-course ol li a{width:100%}

.IADashboardWrapper1 .courses-widget.free-course ol{ flex-wrap: wrap; width: 100%; display: flex; margin:0; padding: 0;}
.IADashboardWrapper1 .courses-widget.free-course ol li {margin:8px 8px; border-radius: 10px; height: 120px; transition: all 0.2s ease; ; width: 100%; align-items: center;  max-width: 30%;  padding: 0;  flex-direction: column; display: flex;}
.IADashboardWrapper1 .courses-widget.free-course ol li .course-thumb { display: flex; background-color: #eef0f3; border-radius:5px;  align-items: center;  justify-content: center; font-size: 39px;  border: 0px solid #ddd;  width: 100%;  max-width: 125px;  overflow: hidden;  height:85px; margin:0 auto;  max-height: 85px;}
.IADashboardWrapper1 .courses-widget.free-course ol li .course-title {font-family:inherit; font-weight: 400; margin-top: 8px;  color: #000;  font-size: 16px; text-align: center;}
.IADashboardWrapper1 .courses-widget.free-course ol li .course-title a {font-family: inherit; margin-top: 8px;  color: #000;  font-size: 16px; text-align: center;}
.IADashboardWrapper1 .courses-widget.free-course ol li:hover{-webkit-transform: translateY(-6px);transform: translateY(-6px); box-shadow: 4px 4px 12px -6px var(--text-black2);};
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(1) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(2) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(3) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(4) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(5) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(6) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(7) .course-thumb{background-color: #faecef; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(8) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(9) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(10) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(11) .course-thumb{background-color: #eef0f3; color:#fff;}
.IADashboardWrapper1 .courses-widget.free-course ol li:nth-child(12) .course-thumb{background-color: #eef0f3; color:#fff;}

.IADashboardWrapper1 .courses-widget ol.referal-widget li:first-child{margin-top: 0;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li { position: relative; width:100%; max-width: none; align-items: flex-start; padding: 15px; margin-bottom:0px; border-radius:12px;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-title{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-title a{font-size: 22px; font-weight: normal;  color: #fff;  margin: 0 0 10px; text-align: left;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-desc{font-size: 15px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-description{font-size: 18px; line-height: 24px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .arrow{position: absolute; right: 10px;display: flex;align-items: center;justify-content: center;  top: 10px;  width: 28px;  height: 28px;  border-radius: 100%;
  background-color: hsla(0,0%,100%,.2);}
.IADashboardWrapper1 .courses-widget ol.referal-widget li{background: linear-gradient(to right, var(--primary), var(--primary));}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-description{font-size: 18px; line-height: 24px; font-weight: normal;  color: #fff;  margin: 0 0 0px;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-thumb{ width:70px; height: 70px; border-radius:100%; background-color: #fff; display: flex; align-items: center; justify-content: center; margin-bottom: 10px;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li .course-thumb img{max-width: 100%; width: 60px; height: 60px;}
.IADashboardWrapper1 .courses-widget ol.referal-widget li a{text-decoration: underline; color:#000}
.IADashboardWrapper1 .courses-widget ol.referal-widget li a:hover{text-decoration: none; color:#000}

.IADashboardWrapper1 .courses-widget.tag-course ol li { align-items: flex-start; display: flex; justify-content: space-between;  transition: all .2s ease;  width: 100%;  max-width: 31%;  border-radius: 18px;  color: #fff;  margin: 0 0 0 20px;  padding: 15px;  flex-direction: row;min-height: 150px}
.IADashboardWrapper1 .courses-widget.tag-course ol li a{display: flex;width:100%; justify-content: space-between;}
.IADashboardWrapper1 .courses-widget.tag-course ol li:first-child { background-image: url(/static/media/veranda-innovation.18d86c19.png); background-repeat: no-repeat;  background-size: cover;  margin-left: 0;}
.IADashboardWrapper1 .courses-widget.tag-course ol li:nth-child(2) {background-color: rgba(255,82,2,.7);}
.IADashboardWrapper1 .courses-widget.tag-course ol li:nth-child(3) {background: transparent linear-gradient(121deg,#48a9a6,#48a9a6) 0 0 no-repeat padding-box}
.IADashboardWrapper1 .courses-widget.tag-course ol li .course-thumb{ margin-left: 15px; order:2;  align-items: center;  justify-content: center; display: flex;  background-color: rgba(96,96,25,.2);  border-radius: 100%;  width: 60px;  height: 60px;}
.IADashboardWrapper1 .courses-widget.tag-course ol li:first-child a{display: flex; justify-content: space-between;}

.IADashboardWrapper1 .courses-widget.tag-course ol li div.arrow{ margin-left: 15px; order:2;  align-items: center;  justify-content: center; display: flex;  background-color: rgba(96,96,25,.2);  border-radius: 100%;  width: 19px;  height: 19px;}
.IADashboardWrapper1 .courses-widget.tag-course ol li:first-child div.arrow .content{order:1}
.IADashboardWrapper1 .courses-widget.tag-course ol li:first-child .content h3{color:#fff; font-size: 17px;}
.IADashboardWrapper1 .courses-widget.tag-course ol li:first-child .content p{color:#fff; font-size: 17px; margin:10px 0 0 ;}
.IADashboardWrapper1 .courses-widget.tag-course ol li:first-child .content p:last-child{color:#fff; font-size: 12px;}
.IADashboardWrapper1 .courses-widget.tag-course ol li .course-title {font-size: 17px; order:1; color: #fff;  margin: 0 0 15px;}
.IADashboardWrapper1 .courses-widget.tag-course ol li .course-title a{font-size: 17px; order:1; color: #fff;  margin: 0 0 15px;}

.IADashboardWrapper1 .courses-widget.image-background{padding:0; border-radius:5px;}
.IADashboardWrapper1 .courses-widget.image-background img{padding:0; border-radius:5px; display: flex;}
.IADashboardWrapper1 .userinfo-widget div img{max-width: 100%;}

.app-body.widget-dashboard .app-div{width: auto; padding:10px 20px 25px 20px; top: 50px; left:150px; height: calc(100vh - 55px);}
.header2.new-dashboard-navigation{width:100%; max-width: 150px; left:0; top:0; padding:0; height: calc(100vh - 0px);}
.header2.new-dashboard-navigation .sidebar-links.new-dashboard-navigation{flex-direction: column;}
.header2.new-dashboard-navigation .sidebar-links.new-dashboard-navigation a{flex-direction: column; width: 100%;}
.header2.new-dashboard-navigation .sidebar-links.new-dashboard-navigation a span:first-child{margin-bottom: 10px; width:45px; height:45px;}
.header2.new-dashboard-navigation .sidebar-links.new-dashboard-navigation a span:last-child{margin-left:0px;word-break: break-word;white-space: normal;}

.IADashboardWrapper1 .courses-widget .quick-recommendations-widget {display: flex; width: 100%; flex-direction: column; padding:0px; background-color: #fff;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget .heading-title{display: flex; width: 100%; justify-content: flex-start; font-weight: bold; text-align: left;  margin-bottom: 10px; color:#000; font-size: 19px;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol{margin:0; padding: 0; flex-direction: column; overflow-y: auto; max-height: 261px; flex-wrap: inherit;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li{margin:10px 0 0; flex-direction: row;  line-height: 22px; font-size: 14px; max-width: none; border-radius:5px; padding: 10px; border:1px solid #ddd; align-items: flex-start;  list-style-type: none; width: 100%; display: flex;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li .course-thumb{ overflow: hidden; flex-shrink: 0; display: flex; width:64px; height: auto; margin-right: 10px; border:1px solid #ddd;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li .course-thumb img{max-width: 100%;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li a{display: flex; align-items: flex-start; width:100%; color: #000; padding:0px; font-weight: bold;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li:hover{background-color: #f6f6f6;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li .details{color:#999; margin-top: 5px; display: flex; flex-wrap: wrap;;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li .details span:first-child{color:#000; font-weight: normal;  margin-left:0;}
.IADashboardWrapper1 .courses-widget .quick-recommendations-widget  ol li .details span:last-child{color:#999;  font-weight: normal; margin-left: 10px;}


.arrow img{width:20px !important;}
.app-header.widget-dashboard{width:auto; z-index: 220002; left: 150px; padding:10px 20px 20px 20px; margin-bottom: 0; top: 0px; right:0;}

.app-header.widget-dashboard .profile{/*justify-content: space-between; width: 100%;*/ align-items: center;}
.app-header.widget-dashboard .profile .TopHeaderLinks{display: flex; font-family: Lato; font-size: 17px; justify-content: space-between; align-items: center; width: 100%;}
.app-header.widget-dashboard .profile .TopHeaderLinks .innovationText{display: flex; align-items: center;}
.app-header.widget-dashboard .profile .TopHeaderLinks .innovationText img{margin-right: 10px;}

.widget-dashboard .referralDialog-overlay{z-index:220000!important;}
.widget-dashboard .referralDialog-content{height:auto; padding:0px !important; background-color:#ffe198!important}
.widget-dashboard .referralDialogWrapper{display:flex; flex-direction:row; position: relative; font-family: Lato;}
.widget-dashboard .referralDialogWrapper .leftsection{width:60%; position: relative; display:flex; padding:15px; flex-direction: column;}
.widget-dashboard .referralDialogWrapper .rightsection{width:40%; display:flex; flex-direction: column;  background-size: cover; background-image: url(/static/media/referral-rightbg1.68a68baf.png); background-repeat:no-repeat; }
.widget-dashboard .referralDialogWrapper .shape1{position:absolute; z-index:0; width:150px; background-color: #00f8b9; right:-40px; top:-40px; height: 150px; border-radius:100px;}
.widget-dashboard .referralDialogWrapper .shape2{position:absolute; width:150px; z-index:0; background-color: #0096f8; left:-40px; bottom:-40px; height: 150px; border-radius:100px;}
.widget-dashboard .referralDialogWrapper .headingtitle{border-bottom:1px solid #000; font-weight:bold; margin-bottom: 15px; padding-bottom: 10px; font-size:26px; color:#000;}
.widget-dashboard .referralDialogWrapper ol{margin:0; padding:0; display: flex; flex-direction:column; overflow-y: auto; max-height:300px;}
.widget-dashboard .referralDialogWrapper ol li{margin:15px 0 0; padding:0; display: flex; flex-direction:column;border: 0px solid #0edf0e;padding:0px; border-radius: 5px;}
.widget-dashboard .referralDialogWrapper ol li:first-child{margin-top: 0;}
.widget-dashboard .referralDialogWrapper ol li .question-title{font-weight:bold; margin-bottom:5px; padding-bottom:0px; font-size: 17px; color:#000;}
.widget-dashboard .referralDialogWrapper ol li .labelblock{display: flex; width: 100%; flex-wrap: wrap;}
.widget-dashboard .referralDialogWrapper ol li .labelinputblock{display: flex; width: 100%; flex-wrap: wrap;}
.widget-dashboard .referralDialogWrapper ol li input[type="text"]{max-width: none; width: 100%;}
.widget-dashboard .referralDialogWrapper ol li label{display: flex; width:100%; max-width: 50%; align-items: flex-start;justify-content:flex-start; padding:3px;}
.widget-dashboard .referralDialogWrapper ol li label input{margin-right:10px;}
.widget-dashboard .referralDialogWrapper ol li label span{min-width:60px; margin-right: 10px;}
.widget-dashboard .referralDialogWrapper ol li input[type="text"]{border:1px solid #ddd; padding:10px 10px; font-family: Lato;}
.widget-dashboard .referralDialogWrapper .question-action{text-align:right; align-items: flex-end; justify-items: flex-end;  border-top:0px solid #ddd; margin-top:15px; padding-top:0px;}
.widget-dashboard .referralDialogWrapper .question-action button{background-color: #000; color:#fff}
/*.IADashboardWrapper1 Css ends here*/

.app-div.app-dashboard{left:250px;top:70px; height:calc(100vh - 75px)}
.app-div.app-dashboard .dashboard-schedule{width:auto}
.app-div.app-dashboard .dashboard-schedule .viewall-btn{background-color: transparent!important;}
.app-div.app-dashboard .dashboard-schedule .viewall-btn a{border:0px solid #ddd; font-family: Source Sans Pro; text-decoration: none; display: flex;  color: #fff;  padding: 5px 15px;  border-radius: 5px;  background-color: var(--primary);}

.app-div.widget-app-dashboard{left:150px; top:70px; height:calc(100vh - 75px)}
.app-div.widget-app-dashboard .dashboard-schedule{width:auto}
.app-div.widget-app-dashboard .dashboard-schedule .viewall-btn{background-color: transparent!important;}
.app-div.widget-app-dashboard .dashboard-schedule .viewall-btn a{border:0px solid #ddd; font-family: Source Sans Pro; text-decoration: none; display: flex;  color: #fff;  padding: 5px 15px;  border-radius: 5px;  background-color: var(--primary);}
.app-div.widget-app-div{left:150px; top:70px; height:calc(100vh - 75px)}
.app-div.widget-app-div .dashboard-schedule{width:auto}
.app-div.widget-app-div .dashboard-schedule .viewall-btn{background-color: transparent!important;}
.app-div.widget-app-div .dashboard-schedule .viewall-btn a{border:0px solid #ddd; font-family: Source Sans Pro; text-decoration: none; display: flex;  color: #fff;  padding: 5px 15px;  border-radius: 5px;  background-color: var(--primary);}

.app-list-div .list-sticky{width:auto; left:0;}

.list-sticky.notwidgetheader{width:auto; left:0; right:0;}

.page-header-test{z-index: 2;}
.list-sticky{width: 87vw; height: calc(100vh - 180px); top:110px}
/*.app-div{left: 150px; top:70px; height:calc(100vh - 75px)}*/

.app-body.widget-dashboard .referral-thanksmsg{display: flex; flex-direction: column; font-family: Lato;}
.app-body.widget-dashboard .referral-thanksmsg .thanksmsg{font-size: 26px; justify-content: center;  align-items: center;display: flex; flex-direction: column; font-family: Lato; font-weight: bold;}
.app-body.widget-dashboard .referral-thanksmsg .thanksmsg i{margin-bottom:0px; max-width: 65px;}
.app-body.widget-dashboard .referral-thanksmsg .thanksmsg i img{max-width: 100%;}
.app-body.widget-dashboard .referral-thanksmsg .addmore-msg{display: flex; align-items: center; margin-top: 20px; flex-direction: column; width: 100%;}
.app-body.widget-dashboard .referral-thanksmsg .addmore-msg a{font-size: 17px; color:#fff; background-color:#1bce52; padding:8px 25px; display: flex; border-radius:5px; text-transform: capitalize; margin-top: 15px; text-decoration: none;}
.app-body.widget-dashboard .referral-thanksmsg .addmore-msg a:hover{font-size: 17px; text-decoration: none;}
.app-body.widget-dashboard .referral-end-content{height:auto}
/*Responsive Css Start From here*/

@media only screen and (max-width: 1150px){
  .app-header.widget-dashboard{background-color: #fff; position: fixed;}
  .app-body.widget-dashboard .app-div{background-color: var(--white); border-radius:0px;}
}

@media only screen and (max-width: 1150px){
  .app-header.widget-dashboard{background-color: #fff; position: fixed;}
  .app-body.widget-dashboard .app-div{background-color: var(--white); border-radius:0px;}
  .IADashboardWrapper1 .main-layout-section{flex-direction: column;}
  .IADashboardWrapper1 .main-layout-section .left-section-layout{flex-direction: column;  max-width: none;}
  .IADashboardWrapper1 .main-layout-section .right-section-layout{flex-direction: column;  max-width: none;}
}


@media only screen and (max-width: 1050px){
  .IADashboardWrapper1 .rowWrapper{overflow-x: auto;}
  .IADashboardWrapper1 .rowWrapper .rowInner{min-width:900px;}
}

@media only screen and (max-width: 850px){
  .IADashboardWrapper1 .courses-widget.tag-course ol li{width: 29%;}
}



@media only screen and (max-width: 600px){
  .app-body.widget-dashboard .app-div{left: 0;  top:90px; height: calc(100vh - 135px);}
  .IADashboardWrapper1 .courses-widget.free-course ol{width: 100%; flex-wrap: wrap;}
  .IADashboardWrapper1 .courses-widget.free-course ol li{max-width: 29%; height: auto;}
  .app-div.widget-app-dashboard{left:0;}
  .app-header.widget-dashboard{position: static;}
  .app-div.widget-app-dashboard{top:95px;}
  .IADashboardWrapper1 .rowInner .tag-course.courses-widget ol{flex-wrap: nowrap; flex-direction: row;}
  .IADashboardWrapper1 .rowInner .courses-widget.tag-course ol li {margin-left: 20px;}
  .IADashboardWrapper1 .rowInner .courses-widget.tag-course ol li:first-child{margin-left:0px;}
}




@media only screen and (max-width: 812px) and (orientation: landscape){
  .IADashboardWrapper1 .tag-course.courses-widget ol{flex-direction: column;}
  .IADashboardWrapper1 .tag-course.courses-widget ol li{max-width: none; margin:0 0 15px 0; width: 100%;}
  .app-body.widget-dashboard .app-div{right:0;}
  .IADashboardWrapper1 .rowInner .tag-course.courses-widget ol{flex-wrap: nowrap; flex-direction: row;}
  .IADashboardWrapper1 .rowInner .courses-widget.tag-course ol li {margin-left: 20px;}
  .IADashboardWrapper1 .rowInner .courses-widget.tag-course ol li:first-child{margin-left:0px;}
}

  
@media only screen and (max-width:670px) and (orientation: landscape){
  .IADashboardWrapper1 .courses-widget.free-course ol li{max-width: 29%; }
 
 }

   
@media only screen and (max-width:570px) and (orientation: landscape){
  .IADashboardWrapper1 .courses-widget.free-course ol{flex-direction: row;}
  .IADashboardWrapper1 .courses-widget.free-course ol li{max-width: 29%; }
 
 }

  @media only screen and (max-width: 1000px) {  

    .dashboard-main .dashboard-left{width:100%}
    .dashboard-schedule{width:auto}
    .dashboard-main .dashboard-left .dashboard-practices-course{width: 100%; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-main .dashboard-left .dashboard-practices-course .course-div{width: 100%; flex-shrink: 0;  max-width: 300px;}

    .dashboard-main .dashboard-left .dashboard-subjects{width: 100%; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-main .dashboard-left .dashboard-subjects .dash-subject-fac{width: 100%; flex-shrink: 0;  max-width: 300px;}

    .dashboard-subjects{width: 100%; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-subjects .dash-subject{width: 100%; flex-shrink: 0; margin-left:15px;  max-width: 300px; flex-wrap: nowrap;}
    .dashboard-subjects .dash-subject:first-child{margin-left:0}
    .dashboard-exercise .dashboard-practices{width: 100%; padding:10px 0; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-exercise .dashboard-practices .dash-practice{width: 100%; flex-shrink: 0; margin:0 10px;  max-width: 300px; flex-wrap: nowrap;}
  
    .dashboard-practices{width: 100%; padding:10px 0; overflow-x:auto; flex-wrap:nowrap}
    .dashboard-main .dashboard-right{display:none;}
    .drop-profile-creds{position: relative;}
    .drop-profile-creds div{width: 100%;}
    .drop-profile-creds + #edit-prof{position: absolute; top:0;}

  }

  @media screen and (max-width: 770px){
    .app-div.app-dashboard{left:0; width: calc(100%);}
    .dashboard-main .dashboard-left .dash-subject{margin-left: 20px;}
    .dashboard-main .dashboard-left .dash-subject:first-child{margin-left:0px;}
    .dashboard-main .dashboard-left .dashboard-schedule{display: flex; flex-direction: column; margin-left: 0; margin-bottom: 50px;}
    .dash-lecture {flex-direction: column;  padding: 10px;  background-color: #fff;  align-items: flex-start; }
    .app-div.app-dashboard .dashboard-schedule .viewall-btn {
        background-color: transparent!important;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
    .app-div.app-dashboard, .app-div.app-studymaterial, .app-div.app-webinar{left:0; width:100%; height: calc(100vh - 139px);}
  }

  @media only screen and (max-width: 600px) {  
    .dashboard-exercise:first-child{margin-top:0}
    .dashboard-main .dashboard-left .dashboard-practices-course .course-div{width: 100%; flex-shrink: 0;  max-width: 200px;}
    .dashboard-subjects .dash-subject{width: 100%; flex-shrink: 0;  max-width:200px; flex-wrap: nowrap;}
    .dash-subject-fac{ width: 100%; flex-shrink: 0;  max-width:200px; flex-wrap: nowrap;}
    .sm-layers{width:100%}
    .sm-layers .sm-chapters{width: 100%;}
    .dashboard-schedule{padding: 10px;}
    .dash-lecture{flex-direction:column; padding:10px; background-color:#fff; align-items: flex-start;}
    .dash-lecture .dash-lecture-date{display: flex;  border-right:0px none; flex-direction: row; padding:0px}
    .dash-lecture .dash-lecture-date span{margin:0 5px}
    .dash-lecture-date-title{flex-direction:column; width:100%; margin-left:0}
    .dash-lecture-date-third{align-items: flex-end; padding-left:0}
    .dash-lecture-date-third div + div{padding-right:0; margin-top:15px;}
    .dash-sch-head{padding-left:0;}
    .dash-sch-head div{display: flex;}
    .dashboard-main .dashboard-left .dashboard-schedule{margin-left:0; margin-bottom:50px;display:flex; flex-direction: column;}

    .app-body.widget-dashboard .app-div{width: 100%;}

    .app-header.widget-dashboard{width: auto; left: 6px;  right: 15px;}

    .IADashboardWrapper1 .courses-widget.tag-course ol{flex-direction: column;}
    .IADashboardWrapper1 .courses-widget.tag-course ol li{width: 100%; max-width: none; margin:0 0 15px 0}
    .IADashboardWrapper1 .courses-widget.tag-course ol li a{width: 100%;}
    body.mycoachpageadded{background-color: #fff!important;}

    .app-header.widget-dashboard .profile .TopHeaderLinks{flex-direction: column;}
    .app-header.widget-dashboard .profile .TopHeaderLinks .appDownload{display: none;}
    .app-header.widget-dashboard .innovationText{flex-direction: column; margin-bottom:5px; font-size: 16px; align-items: flex-start;}
    .app-header.widget-dashboard .profile{flex-direction: column;}
    .app-header.widget-dashboard .profile #dropper{display: flex; position: absolute; top:10px; right:10px;}
    .app-header.widget-dashboard .logout-setting{top:12px; right:30px; position: absolute;}
    .mycoachpageadded .header2.mobilenavigationActive{height:auto}
    .app-header.widget-dashboard .profile .TopHeaderLinks{justify-content: flex-start; align-items: flex-start;}
    .app-header.widget-dashboard .profile .TopHeaderLinks .innovationText{align-items: flex-start;}

    .app-div.widget-app-div{left:0}
    .app-div.widget-app-div{top:85px}

    .app-header.widget-dashboard .app-div.mycoach-div{height: calc(100vh - 283px);}

    .widget-dashboard .referralDialog-content{width: 85%!important;}

    .app-div.app-dashboard{left:0}

    .dashboard-main .dashboard-left .dash-subject{margin-left: 20px;}
    .dashboard-main .dashboard-left .dash-subject:first-child{margin-left:0px;}
    
  }

  @media screen and (max-width: 770px) and (orientation: landscape){  
    .app-div.app-dashboard{left:0; width: calc(100%);}
    .dashboard-main .dashboard-left .dash-subject{margin-left: 20px;}
    .dashboard-main .dashboard-left .dash-subject:first-child{margin-left:0px;}
    .dashboard-main .dashboard-left .dashboard-schedule{display: flex; flex-direction: column; margin-left: 0; margin-bottom: 50px;}
    .dash-lecture {flex-direction: column;  padding: 10px;  background-color: #fff;  align-items: flex-start; }
    .app-div.mycoach-div {height: calc(100vh - 203px)}
    .app-div.app-dashboard, .app-div.app-studymaterial, .app-div.app-webinar{left:0; width:100%; height: calc(100vh - 60px);}
  }

  @media screen and (max-width: 812px) and (orientation: landscape){  
    .app-div.app-dashboard{left:0; width: calc(100%);}
    .dashboard-main .dashboard-left .dash-subject{margin-left: 20px;}
    .dashboard-main .dashboard-left .dash-subject:first-child{margin-left:0px;}
    .dashboard-main .dashboard-left .dashboard-schedule{display: flex; flex-direction: column; margin-left: 0; margin-bottom: 50px;}
    .dash-lecture {flex-direction: column;  padding: 10px;  background-color: #fff;  align-items: flex-start; }
    .app-div.app-dashboard .dashboard-schedule .viewall-btn {
          background-color: transparent!important;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          margin-bottom: 10px;
      }
      .app-div.app-dashboard, .app-div.app-studymaterial, .app-div.app-webinar{left:0; width:100%; height: calc(100vh - 60px);}
  }
/*Responsive Css ends here*/
.defaultDialogWrapper{display: flex; position:fixed; align-items: center; justify-content: center; top:0; bottom:0; left:0; right:0; background-color: rgba(0,0,0,0.7);}
.defaultDialogWrapper .defaultDialogContainer{background-color: #fff; flex-direction: column; padding:20px; display: flex; align-items: center; justify-content: center; border-radius:15px; max-width: 500px; font-family: Lato; font-size:20px;}
.defaultDialogWrapper .defaultDialogContainer .admin-loader{margin-bottom: 15px;}

.passwordDialogWrapper{display: flex; position:fixed; align-items: center; justify-content: center; top:0; bottom:0; left:0; right:0; background-color: rgba(0,0,0,0.7);}
.passwordDialogWrapper .passwordDialogContainer{background-color: #fff; width:100%;  min-width:400px; max-width:400px; flex-direction: column; padding:20px; display: flex; align-items: center; justify-content: center; border-radius:15px; font-family: Lato; font-size:20px;}
.passwordDialogWrapper .passwordDialogContainer h2{justify-content: flex-start; width:100%; border-bottom:1px solid #ddd; padding-bottom: 5px; margin-bottom:5px;}
.passwordDialogWrapper .passwordDialogContainer .formBlock{display: flex; width:100%; flex-direction: column;}
.passwordDialogWrapper .passwordDialogContainer .formBlock .block{display: flex; flex-direction: column; margin-top: 15px;}
.passwordDialogWrapper .passwordDialogContainer .formBlock .block input{padding: 8px 10px; transition: all 0.2s ease; border-radius:5px; border: 1px solid var(--primary); }
.passwordDialogWrapper .passwordDialogContainer .formBlock .block input:focus{ box-shadow: 0px 0px 5px var(--primary); }
.passwordDialogWrapper .passwordDialogContainer .formBlock .actions{margin-top: 15px; display: flex; justify-content: flex-end;}

.notFoundDialogWrapper{display: flex; position:fixed; align-items: center; justify-content: center; top:0; bottom:0; left:0; right:0; background-color: rgba(0,0,0,0.7);}
.notFoundDialogWrapper .notFoundDialogContainer{background-color: #fff; flex-direction: column; padding:20px; display: flex; align-items: center; justify-content: center; border-radius:15px;  min-width: 500px; max-width: 500px; font-family: Lato; font-size:20px;}



.errorMsg{color:#840404; bordeR:1px solid #840404;  font-family: Lato; font-size:14px; background-color: #e4caca; padding:10px; border-radius:5px; width:100%}
.successMsg{color:#009c2e; bordeR:1px solid #009c2e;  font-family: Lato; font-size:14px; background-color: #e4fed5; padding:10px; border-radius:5px; width:100%}

.is_disabled{opacity: 0.5; pointer-events: none;}


.nextDialogWrapper{display: flex; position:fixed; align-items: center; justify-content: center; top:0; bottom:0; left:0; right:0; background-color: rgba(0,0,0,0.7); font-family: Lato; font-size:20px;}
.nextDialogWrapper .nextDialogContainer{background-color: #fff; width:100%;  min-width:850px; max-width:850px; flex-direction: column; padding:20px; display: flex; align-items: center; justify-content: center; border-radius:15px; font-family: Lato; font-size:20px;}
.nextDialogWrapper .nextDialogContainer h2{justify-content: flex-start; text-align:left; font-size: 19px; width:100%; margin-bottom:10px;}
.nextDialogWrapper .nextDialogContainer h3{justify-content: flex-start; text-align:left; font-size: 20px; color:#1bce52; width:100%; margin-bottom:10px;}
.nextDialogWrapper .nextDialogContainer .scancodeContainer{display: flex; width: 100%; align-items: center;}
.nextDialogWrapper .nextDialogContainer .scancodeContainer .left-section{display: flex; flex-direction: column; width:100%; max-width: 60%;}
.nextDialogWrapper .nextDialogContainer .scancodeContainer .right-section{display: flex; flex-direction: column; width:100%; max-width:40%; margin-left:30px;}
.nextDialogWrapper .nextDialogContainer .scancodeContainer ol{display: flex; width: 100%; flex-direction: column; margin:0; padding: 0;}
.nextDialogWrapper .nextDialogContainer .scancodeContainer ol li{ align-items: center; display: flex; font-family: Lato; font-size:18px; width: 100%; padding:0px; margin:15px 0 0}
.nextDialogWrapper .nextDialogContainer .scancodeContainer ol li span{ background-color: #f1f0f0; padding:5px 10px; display: inline-flex;}
.nextDialogWrapper .nextDialogContainer .scancodeContainer ol li i{ display: flex; border-radius:100%; background-color:#1bce52; margin-right: 10px; width: 10px; height: 10px;}
.nextDialogWrapper .nextDialogContainer .scancode-section{margin-top: 30px; flex-direction: column; display: flex; width: 100%;}
.nextDialogWrapper .nextDialogContainer .scancode-section h2{text-align: center; font-size:23px}
.nextDialogWrapper .nextDialogContainer .scancode-section p{margin:0 0 10px; text-align: center;}
.nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section{margin: 20px auto 0px; display: flex; width:100%; align-items:center; justify-content: center;}
.nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .left-section{display: flex; width: 100%; max-width: 30%; flex-direction: column;}
.nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .right-section{display: flex; width: 100%; max-width: 30%; flex-direction: column;}
.nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section p{font-size: 15px; width: 100%; max-width: none; text-align:center; margin:10px auto 0}
.nextDialogWrapper .nextDialogContainer .scancode-section  img{max-height:170px; border-radius: 5px; border: 1px solid #ddd; padding: 5px; margin: 0 auto 0; width: auto; height: auto; margin-top:0px;}
.nextDialogWrapper .nextDialogContainer .scancode-section .app-store-links{display: flex; width: 100%; flex-direction: column; position: relative; align-items: flex-start;}
.nextDialogWrapper .nextDialogContainer .scancode-section .app-store-links .or-sep{   margin-top:8px; margin-bottom: 8px; font-size: 14px; text-align: center; font-weight: 500; color: #666; display: inline-block; width: 30px; height: 20px;  background: #fff; margin-left: auto; margin-right: auto;}
.nextDialogWrapper .nextDialogContainer .scancode-section .orsep{   margin-top: 15px; margin-bottom: 15px; font-size: 14px; text-align: center; font-weight: 500; color: #666; display: inline-block; width: 30px; height: 20px;  background: #fff;}
.nextDialogWrapper .nextDialogContainer .scancode-section .app-store-links .playstore-btn{display: flex; width: 100%; justify-content: center;}
.nextDialogWrapper .nextDialogContainer .scancode-section .app-store-links .playstore-btn img{margin-top: 0; border: 0px none; padding: 0;}
.nextDialogWrapper .nextDialogContainer .scancode-section .app-store-links p{text-align: center;}
.nextDialogWrapper .nextDialogContainer .scancode-section .app-store-links p a{color:#1bce52; text-decoration: underline; margin-top: 15px; display: flex; justify-content: center;}
.nextDialogWrapper .nextDialogContainer .scancode-section .app-store-links p a:hover{text-decoration: none;}


@media only screen and (max-width:870px){
    .nextDialogWrapper .nextDialogContainer{min-width: auto; max-width: 700px;}

}

@media only screen and (max-width:720px){
    .nextDialogWrapper .nextDialogContainer{min-width: auto; max-width:600px;}
    .nextDialogWrapper .nextDialogContainer h2{font-size: 18px;}
    .nextDialogWrapper .nextDialogContainer h3{font-size: 16px;}
    .nextDialogWrapper .nextDialogContainer .scancode-section h2{font-size: 22px;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .left-section{max-width: none;}

}

@media only screen and (max-width:600px){
    .nextDialogWrapper .nextDialogContainer{min-width: auto; max-width:450px;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer{flex-direction: column;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .left-section{max-width: none; order:2}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .right-section{max-width: none; order:1; margin-left: 0; align-items: center;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .right-section img{max-width: 50%;}
    .nextDialogWrapper .nextDialogContainer h2{font-size: 20px; margin-bottom: 20px; text-align: center}
    .nextDialogWrapper .nextDialogContainer h3{font-size: 18px; margin-bottom: 20px; text-align: center}
    .nextDialogWrapper .nextDialogContainer .scancode-section h2{font-size: 22px;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .left-section{max-width: none;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section{flex-direction: column;}
    .nextDialogWrapper .nextDialogContainer .scancode-section{margin-top: 0;;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .right-section{max-width: none;}
    .nextDialogWrapper .nextDialogContainer{display: block;}

}

@media only screen and (max-width:470px){
    .nextDialogWrapper .nextDialogContainer{min-width: auto; max-width:395px;}
    .passwordDialogWrapper .passwordDialogContainer{min-width: inherit; max-width: 300px;}
    .nextDialogWrapper .nextDialogContainer{min-width: auto; max-width:395px; overflow-y: auto; max-height:650px;}
}

@media only screen and (max-width:380px){
    .nextDialogWrapper .nextDialogContainer{min-width: auto; max-width:300px;}
    .passwordDialogWrapper .passwordDialogContainer{min-width: inherit; max-width: 300px;}
    .nextDialogWrapper .nextDialogContainer{min-width: auto; overflow-y: auto; max-height:500px;}
}

@media only screen and (max-width: 825px) and (orientation: landscape){
    .nextDialogWrapper .nextDialogContainer{min-width: auto; overflow-y: auto; max-height: 350px;} 
    .nextDialogWrapper .nextDialogContainer{min-width: auto; max-width:650px;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer{flex-direction: column;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .left-section{max-width: none; order:2}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .right-section{max-width: none; order:1; margin-left: 0; align-items: center;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .right-section img{max-width: 50%;}
    .nextDialogWrapper .nextDialogContainer h2{font-size: 20px; margin-bottom: 20px; text-align: center}
    .nextDialogWrapper .nextDialogContainer h3{font-size: 18px; margin-bottom: 20px; text-align: center}
    .nextDialogWrapper .nextDialogContainer .scancode-section h2{font-size: 22px;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .left-section{max-width: none;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section{flex-direction: column;}
    .nextDialogWrapper .nextDialogContainer .scancode-section{margin-top: 0;;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .right-section{max-width: none;}
    .nextDialogWrapper .nextDialogContainer{display: block;}
}

@media only screen and (max-width: 570px) and (orientation: landscape){
    .nextDialogWrapper .nextDialogContainer{min-width: auto; overflow-y: auto; max-height: 300px;} 
    .nextDialogWrapper .nextDialogContainer{min-width: auto; max-width:450px;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer{flex-direction: column;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .left-section{max-width: none; order:2}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .right-section{max-width: none; order:1; margin-left: 0; align-items: center;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .right-section img{max-width: 50%;}
    .nextDialogWrapper .nextDialogContainer h2{font-size: 20px; margin-bottom: 20px; text-align: center}
    .nextDialogWrapper .nextDialogContainer h3{font-size: 18px; margin-bottom: 20px; text-align: center}
    .nextDialogWrapper .nextDialogContainer .scancode-section h2{font-size: 22px;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .left-section{max-width: none;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section{flex-direction: column;}
    .nextDialogWrapper .nextDialogContainer .scancode-section{margin-top: 0;;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .right-section{max-width: none;}
    .nextDialogWrapper .nextDialogContainer{display: block;}
}

@media only screen and (max-width: 670px) and (orientation: landscape){
    .nextDialogWrapper .nextDialogContainer{min-width: auto; overflow-y: auto; max-height: 300px;} 
    .nextDialogWrapper .nextDialogContainer{min-width: auto; max-width:450px;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer{flex-direction: column;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .left-section{max-width: none; order:2}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .right-section{max-width: none; order:1; margin-left: 0; align-items: center;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .right-section img{max-width: 50%;}
    .nextDialogWrapper .nextDialogContainer h2{font-size: 20px; margin-bottom: 20px; text-align: center}
    .nextDialogWrapper .nextDialogContainer h3{font-size: 18px; margin-bottom: 20px; text-align: center}
    .nextDialogWrapper .nextDialogContainer .scancode-section h2{font-size: 22px;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .left-section{max-width: none;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section{flex-direction: column;}
    .nextDialogWrapper .nextDialogContainer .scancode-section{margin-top: 0;;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .right-section{max-width: none;}
    .nextDialogWrapper .nextDialogContainer{display: block;}
}

@media only screen and (max-width: 770px) and (orientation: landscape){
    .nextDialogWrapper .nextDialogContainer{min-width: auto; overflow-y: auto; max-height: 300px;} 
    .nextDialogWrapper .nextDialogContainer{min-width: auto; max-width:450px;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer{flex-direction: column;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .left-section{max-width: none; order:2}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .right-section{max-width: none; order:1; margin-left: 0; align-items: center;}
    .nextDialogWrapper .nextDialogContainer .scancodeContainer .right-section img{max-width: 50%;}
    .nextDialogWrapper .nextDialogContainer h2{font-size: 20px; margin-bottom: 20px; text-align: center}
    .nextDialogWrapper .nextDialogContainer h3{font-size: 18px; margin-bottom: 20px; text-align: center}
    .nextDialogWrapper .nextDialogContainer .scancode-section h2{font-size: 22px;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .left-section{max-width: none;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section{flex-direction: column;}
    .nextDialogWrapper .nextDialogContainer .scancode-section{margin-top: 0;;}
    .nextDialogWrapper .nextDialogContainer .scancode-section .qr-code-section .right-section{max-width: none;}
    .nextDialogWrapper .nextDialogContainer{display: block;}
}

.learnfestWrapper{display:flex; width: 100%; background-color: #f8f9fa; height:100%; padding-left:0px; font-family: Lato; font-size:14px;}
.learnfestWrapper .learnfestContainer{display:flex; flex-direction: column; width: 100%; padding:30px 20px 20px;}
.learnfestWrapper .learnfestContainer ol{display: flex; align-items: flex-start; width: 100%; overflow-y: auto; flex-wrap: wrap; margin:0; padding:0;}
.learnfestWrapper .learnfestContainer ol li{display: flex; max-width: 30%;  width: 100%;}
.cardWrapper{display: flex; box-shadow: 0 0 16px #ccc; align-items:center; flex-direction: column; width: 100%; margin: 10px; padding: 15px; border-top:4px solid #008E96}
.cardWrapper .cardTop{display: flex; width: 100%;}
.cardWrapper .cardThumb{ width: 100%; margin-right: 20px;  width:100px; height:100px; border-radius:100%; box-shadow: 0 0 16px #ccc;}
.cardWrapper .cardThumb img{border-radius:100%; width:inherit;}
.cardWrapper .cardContent{width:100%; display: flex; flex-direction: column; width: 100%; box-sizing: border-box; padding:0 10px 10px 10px;}
.cardWrapper .cardTitle{font-size:18px; color:#323232; font-weight: bold; margin-bottom: 10px;}
.cardWrapper .cardTime{font-size: 17px; color:#FF7418; font-weight: bold; padding:5px 0; margin-bottom: 10px;}
.cardWrapper .cardBy{font-size: 16px; width:100%; margin-top:0px; color:#323232; padding:10px; background-color: #E6E3E3;}
.learnfestWrapper .headingTitle{font-size:22px; border-bottom:2px solid #ddd; padding-bottom: 10px;  color:#323232; font-weight: bold; margin-bottom: 10px;}

.cardWrapper .joinnow{display: flex; width: 100%;}
.cardWrapper .joinnow a{background-color: #ff8600; color:#fff; padding:5px 15px; border-radius:4px;}

@media only screen and (max-width: 1270px){
 .learnfestWrapper .learnfestContainer ol li{max-width: 48%;}
    
}

@media only screen and (max-width:600px){
    .learnfestWrapper .learnfestContainer{padding-top:20px; padding-bottom:80px;}
    .learnfestWrapper{padding-left:0;}

}


@media only screen and (max-width:560px){
    .learnfestWrapper .learnfestContainer ol{flex-direction: column; flex-wrap: inherit;}
    .learnfestWrapper .learnfestContainer ol li{max-width: none;}

}

@media only screen and (max-width: 820px){
    .learnfestWrapper .learnfestContainer ol li{max-width: 100%;}
  }



@media only screen and (max-width: 670px) and (orientation: landscape){
    .learnfestWrapper .learnfestContainer ol li{max-width: 100%;}
  }

  @media only screen and (max-width: 920px) and (orientation: landscape){
    .learnfestWrapper .learnfestContainer ol li{max-width: 100%;}
  }


.CheckInternetDialogWrapper{display: flex; flex-direction: column; font-family: Lato; z-index: 2200005; width: 100%; align-items: center; justify-content: center; position: fixed; top:0; right:0; bottom:0; left:0; background-color: rgba(0,0,0,0.6);}
.CheckInternetDialogWrapper .CheckInternetDialogContainer{display: flex;  width: 100%; flex-direction: column; background-color: #fff; max-width: 450px; padding: 15px; border-radius: 15px;}
.CheckInternetDialogWrapper .CheckInternetDialogContainer span{cursor: pointer; text-align: right;}
.CheckInternetDialogWrapper .CheckInternetDialogContainer h1{color:var(--text-black); border-bottom:0px solid #e9e8e8; font-size:22px; padding-bottom:0px; text-align: center;  margin:0;}
.CheckInternetDialogWrapper .CheckInternetDialogContainer .actions{display: flex; align-items: center; grid-gap:15px; gap:15px; padding-top: 10px; border-top:1px solid #e9e8e8; }
.CheckInternetDialogWrapper .CheckInternetDialogContainer .actions a{color:var(--text-black2)}
.CheckInternetDialogWrapper .CheckInternetDialogContainer p{color:var(--text-black2); text-align: center; line-height: 25px;}
.tryagainblink {
  animation: blink-animation 1.5s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.iserrorfound-content{
      height: 50% !important;
      width: 25% !important;
      font-size: 16px;
      font-family: 'Lato';
      text-align: center;
      padding: 19px !important;
      border-radius: 8px !important;
}

.ohno{
    font-size: 20px;
    color: red;
    font-weight: 600;
}

.connection_failed{
  margin: 10px;
  color: #153550;
  font-size: 16px;
  font-weight: 600;
}

