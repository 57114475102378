.popup-content {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    max-width: 500px;
    margin: auto;
}

.input-container {
    margin-top: 20px;
}

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.input-field {
    width: 48%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.file-upload {
    width: 48%;
    padding: 8px;
}

.add-new-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.close {
    cursor: pointer;
    font-size: 1.5em;
    float: right;
}
.noticeboard-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

.card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.card-image img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.card-content {
    padding: 15px;
}

.card-content h3 {
    margin: 0;
    font-size: 1.2em;
}

.card-content p {
    margin: 10px 0;
}

.card-actions {
    padding: 15px;
    /* background-color: #2DA77D; */
    
    text-align: center;
}

.card-actions a {
    color: #007bff;
    text-decoration: none;
}


