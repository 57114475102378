.bg-color {
    background-color: #F7F7F9;
    padding: 24px;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 20px 0px;
}

.flexCard {
    display: flex;
    justify-content: space-between;
    gap: 32px;
}

.flexCard .card {
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 7px 16px;
    width: 100%;
}

.cardTitle {
    color: #192130;
    font-size: 14px;
    font-weight: 500px;
}

.graphTitle {
    color: #192130;
    font-size: 15.57px;
    font-weight: 700;
}


.graphCount {
    color: #192130;
    font-size: 28px;
    font-weight: 700;
}

.graphCountName {
    color: #667085;
    font-size: 14px;
    font-weight: 400;
}

.flexCard .count {
    color: #000;
}

.flexCard .card .circle {
    width: 32px;
    height: 32px;
    background-color: #7062E3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userLogins {
    width: 100%;
}

.userLogins {
    width: 100%;
}

.userLogins .graph {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.userLogins .graph .right {
    flex-grow: 1;
}


.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexCard .buttons h4 {
    padding: 4px 10px;
    font-size: 12.11px;
    font-weight: 600;
    display: inline;
    cursor: pointer;
}

.flexCard .buttons h4:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

}

.flexCard .buttons h4:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

}

.flexCard .buttons .selected {
    background-color: #7062E3;
    color: #FFFFFF;


}

.flexCard .buttons .unselected {
    background-color: #EDEFF4;
    color: #344051;

}