/* Overall layout */
.app-body {
    padding: 20px;

    min-height: 100vh;
  }
  

  /* Container and row styling */
  .container-fluid {
    padding: 20px;
  }
  
  .row {
    margin: 0;
  }
  
  /* Sidebar styling */
  .sidebar {

    padding: 15px;
    border-radius: 8px;
  }
  
  .sidebar h2 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  

  li{
    font-size: 18px;
    margin: 10px 0px;
    font-weight: 700;
    font-family: 'Times New Roman', Times, serif;
  }
  /* Video list styling */
  .list-group-item {
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .list-group-item:hover {
    background-color: #e9ecef;
  }
  
  .list-group-item.active {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  /* Main content area */
  .main-content {
    padding: 15px;
  }
  
  /* ReactPlayer styling */
  .react-player {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .col-md-3, .col-md-9 {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .react-player {
      height: 300px !important;
    }
  }