  .app-header{display: flex; flex-direction: column;}
  .app-header .profile {align-self: flex-end;}
  .app-div.mycoach-div{top:150px; height: calc(100vh - 151px); background-color: #F2F4F6; padding-bottom: 0px; width: auto; left:250px; padding:0; border-radius:0}
  .mycoachpageadded .app-header{bordeR:0px solid #ff0000; top:10px; margin-bottom: 0;}


  .app-div.mycoach-div.mentorshipWrapper {left: 150px;}


  .mycoach-header{width:auto; left:250px; padding-left:28px;  position: fixed; z-index:19; top:40px; right:0; padding-right:24px;  display: flex;  align-self: flex-end;  justify-content: space-between;}
  .mycoach-header{display:flex; flex-direction: column;}
  .mycoach-header .intro-block{display: flex;font-family: Lato;  width: 100%; margin-top: 15px; margin-bottom: 15px; justify-content: space-between; width: 100%;  align-items: flex-start;}
  .mycoach-header .intro-block .user-block {display: flex; flex-direction: column; width: 80%;}
  .mycoach-header .intro-block .askanything{display: flex; cursor: pointer; flex-direction: column; align-items: center;}
  .mycoach-header .intro-block .askanything >  div:first-child{width: 40px; margin-bottom: 7px;}
  .mycoach-header .intro-block .askanything i{ width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; margin-bottom: 15px; border-radius:100%; background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}
  .mycoach-header .intro-block h3{margin-bottom: 15px; font-size: 16px; margin:0 0 10px; font-weight: 700px; font-family: Lato;}
  .mycoach-header .intro-block h3 span{color:var(--primary); font-size: 18px;}
  .mycoach-header .intro-block p{font-size: 14px; margin:0; line-height: 22px; font-weight: 400px; font-family: Lato;}


 .app-div.doubt-main{top: 150px;height: calc(100vh - 152px); background-color: #F2F4F6; width: auto; left: 250px; padding: 30px; border-radius: 0;}

  .profile-option div{box-shadow: 0px 3px 6px #9090901F; margin-right:10px}
  .mycoach-wrapper{display: flex; width: 100%; height:100%; flex-direction: column; font-family: Lato;}
  .mycoach-wrapper .intro-block{display: flex; width: 100%; margin-bottom: 15px; justify-content: space-between; width: 100%;  align-items: flex-start;}
  .mycoach-wrapper .intro-block div:first-child{display: flex; flex-direction: column; width: 50%;}
  .mycoach-wrapper .intro-block div:last-child{display: flex; flex-direction: column; align-items: center;}
  .mycoach-wrapper .intro-block div:last-child i{ width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; margin-bottom: 15px; border-radius:100%; background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}
  .mycoach-wrapper .intro-block h3{margin-bottom: 15px; font-size: 16px; margin:0 0 10px; font-weight: 700px; font-family: Lato;}
  .mycoach-wrapper .intro-block h3 span{color:var(--primary); font-size: 18px;}
  .mycoach-wrapper .intro-block p{font-size: 14px; margin:0; line-height: 22px; font-weight: 400px; font-family: Lato;}
  .mycoach-wrapper .mycoach-main{display: flex; width: 100%; background-color: #F2F4F6; padding:30px; border-radius:0px;}
  .mycoach-wrapper .mycoach-left{ display: flex; flex-direction: column; width: 100%; max-width: 300px;}
  .mycoach-wrapper .mycoach-right{ display: flex; flex-direction: column; width: 100%; margin-left: 25px;}

  .mycoach-wrapper .mycoach-left ol{ display: flex; flex-direction: column; width: 100%; margin:0; padding:0}
  .mycoach-wrapper .mycoach-left ol li{ display: flex; flex-direction: column; width: 100%; margin:0 0 15px; box-shadow: 0px 6px 8px #1D73F238; padding:15px; border-radius:12px; position: relative; }
  .course-academy{background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}
  .cacademy{background: transparent linear-gradient(122deg, #FF8A57 0%, #FF5502 100%) 0% 0% no-repeat padding-box;}
  .mycoach-wrapper .mycoach-left ol li:last-child{background: transparent linear-gradient(121deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box}
  .goal{background: transparent linear-gradient(123deg, #FFCE00 0%, #FF8A57 100%) 0% 0% no-repeat padding-box;}
  
  .mycoach-wrapper .mycoach-left ol li .icon{width: 48px;height: 48px; margin-bottom: 10px; display: flex; align-items: center; justify-content: center; border-radius:100%; background-color: #fff;}
  .mycoach-wrapper .mycoach-left ol li .icon img{max-width: 100%; width: 32px;}
  .mycoach-wrapper .mycoach-left ol li .arrow{position:absolute; right:10px; display: flex; align-items: center; justify-content: center; top:10px; width: 28px; height: 28px; border-radius:100%; background-color: rgba(255,255,255,0.2); }
  .mycoach-wrapper .mycoach-left ol li h3{font-size: 16px; color:#fff; margin:0 0 5px}
  .mycoach-wrapper .mycoach-left ol li:last-child h3{font-size: 20px; color:#fff; margin:0 0 49px}
  .mycoach-wrapper .mycoach-left ol li p{font-size: 14px; color:#fff; margin:0 0 0px}
  .mycoach-wrapper .mycoach-left ol li span{font-size: 14px; padding:5px 15px; border-radius: 21px;  color: #1D73F2; background-color:#fff; margin:0 0 0px}
  .mycoach-wrapper .mycoach-left ol li.gameBanner{background-image:url("../../public/images/veranda-innovation.png"); background-repeat: no-repeat; background-size: cover;}
  .mycoach-wrapper .mycoach-left ol li.gameBanner .icon{background-color:transparent}
  .mycoach-wrapper .mycoach-left ol li.gameBanner h3{font-size:20px}
  .mycoach-wrapper .mycoach-left ol li.gameBanner p{font-style: italic;}
  .mycoach-wrapper .mycoach-left ol li.gameBanner p + p{margin-top:2px}

  .mycoach-wrapper .mycoach-right .coach-block{display: flex; flex-direction: column; width: 100%; box-shadow: 0px 3px 6px #9090901F; background-color: #fff; border-radius: 18px; padding:20px}
  .mycoach-wrapper .mycoach-right .coach-block h3{font-size: 16px; color:#000; margin:0 0 15px}
  .mycoach-wrapper .mycoach-right .coach-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .mycoach-wrapper .mycoach-right .coach-block ol li{display: flex;  transition: all 0.2s ease; justify-content: flex-start; align-items: flex-start; width: 100%; max-width:27%; margin:0  20px 20px 0; flex-direction: column;}
  .mycoach-wrapper .mycoach-right .coach-block ol li a{color:#333333; font-size: 14px; width: 100%; display: flex; flex-direction: column; align-items: center; margin:0; justify-content: flex-start;}
  .mycoach-wrapper .mycoach-right .coach-block ol li i{background-color: #EEF0F3; display: flex;  align-items: center; justify-content: center;width: 100%; margin-bottom: 15px; min-height: 85px; border-radius:12px}
  .mycoach-wrapper .mycoach-right .coach-block ol li i img{max-width: 100%; width: 60px; height:60px;}
  .mycoach-wrapper .mycoach-right .coach-block ol li:hover{ transform: translateY(-6px);}

  .mycoach-wrapper .mycoach-right .fun-block{display: flex; flex-direction: column; width: 100%; max-width:65%; margin-left: 25px; box-shadow: 0px 3px 6px #9090901F; background-color: #fff; border-radius: 18px; padding:20px}
  .mycoach-wrapper .mycoach-right .fun-block h3{font-size: 16px; color:#000; margin:0 0 15px}
  .mycoach-wrapper .mycoach-right .fun-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .mycoach-wrapper .mycoach-right .fun-block ol li{display: flex; transition: all 0.2s ease;  width: 100%; max-width: 30%; border-radius:18px; color:#fff;  margin:0  0px 0px 20px; padding:15px; flex-direction: column;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(1){background-color:rgba(27,206,82,0.7);margin-left: 0;}
  .mycoach-wrapper .mycoach-right .fun-block ol li a{color:#fff; display: flex; flex-direction: column;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(2){background-color: rgba(255,82,2,0.7);}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(2) a{pointer-events: auto; opacity:1; cursor: pointer;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(3){background-color: rgba(95,168,255,0.7);}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(1) i{margin-bottom: 15px; align-items: center; justify-content: center; display: flex;     align-self: flex-end; background-color:rgba(96,96,25,0.2); border-radius:100%; width: 60px;  height:60px;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(2) i{margin-bottom: 15px; align-items: center; justify-content: center;   display: flex; align-self: flex-end; background-color:rgba(96,96,25,0.2); border-radius:100%; width: 60px; height:60px;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(2) i img{width:35px; max-width: 100%;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(3) i img{width:35px; max-width: 100%;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:nth-child(3) i{margin-bottom: 15px;  align-items: center; justify-content: center; display: flex;  align-self: flex-end; background-color:rgba(96,96,25,0.2); border-radius:100%; width: 60px; height:60px;}
  .mycoach-wrapper .mycoach-right .fun-block ol li:hover{ transform: translateY(-6px);}

  .mycoach-wrapper .mycoach-right-bottom{display: flex; width: 100%; margin-top: 20px;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block{box-shadow: 0px 3px 6px #9090901F; background-color: #fff; border-radius: 18px; padding:20px; width: 100%; max-width: 35%;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .date-block{display: flex; width: 100%; justify-content: space-between;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .date-block .date-left{display: flex; flex-direction: column; font-size: 16px; color:#000;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .date-block .date-left span{font-size: 14px; color:#707070; margin-bottom: 8px;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .date-block .date-right{display: flex; flex-direction: column; font-size: 16px; color:#000;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .date-block .date-right span{font-size: 14px; color:#707070; margin-bottom: 8px;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .learning-block{display: flex; margin-top: 20px; flex-direction: column; width: 100%;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block .learning-block h4{ margin:0 0 10px; font-size:15px; border-bottom:1px solid #CCCCCC; color:#333333; font-weight: normal;; padding-bottom: 10px}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block ul{margin:0; padding:0;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block ul li{margin:5px 0 0; padding:0; list-style-type: disc; display: list-item; list-style-position: inside;}
  .mycoach-wrapper .mycoach-right-bottom .subcribe-block a{color:#000}

  .comingsoon{display: flex; width: 100%; flex-direction:column; padding:15px;}
  .comingsoon .banner-block{display: flex;   margin:15px 0 0; justify-content: center; background-color: #fff; padding:15px;}
  .comingsoon .banner-block img {max-width: 100%;}
  .mycoach-wrapper .interest-block{box-shadow: 0px 3px 6px #9090901F;  margin-top: 20px; background-color: #fff; border-radius: 18px; padding:20px; width: 100%;}
  .mycoach-wrapper .interest-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .mycoach-wrapper .interest-block input{width: 100%; padding:5px 10px; width: 100%; border:0px none; color:#000; font-size:14px; border-bottom:1px solid #CCCCCC;}
  .mycoach-wrapper .interest-block input.isdata{border-bottom:1px solid transparent;}
  .mycoach-wrapper .interest-block input.isnotdata{border-bottom:1px solid #ccc;}
  .mycoach-wrapper .interest-block input.isdata:focus{border-bottom:1px solid #CCCCCC;}
  .mycoach-wrapper .interest-block .actions{margin-top: 20px; display: flex; align-items: flex-end; justify-content: flex-end;}
  .mycoach-wrapper .interest-block .actions button{ border-radius:5px; background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}
  .mycoach-wrapper .interest-block .actions.isdata{opacity:0; visibility: hidden;}

  .mycoach-wrapper .interest-block .actions.hasfocused{opacity:1; visibility: visible;} 

  .mycoach-wrapper .interest-block .formBlock{display: flex; flex-direction: column; width:100%;}
  .mycoach-wrapper .interest-block .formBlock.hasdata input{border-bottom:1px solid transparent}
  .mycoach-wrapper .interest-block .formBlock.hasdata input.hasfocused{border-bottom:1px solid #ccc}
  .mycoach-wrapper .interest-block .formBlock.hasdata .actions{display:none;}
  .mycoach-wrapper .interest-block .formBlock.hasdata .actions.hasfocused{display: flex;}

  .is_disabled{pointer-events: none; opacity:0.5; cursor: default;}

  .challenges-block{display: flex; flex-direction: column; width: 100%; background-color: #F2F4F6; padding: 25px 25px 150px; border-radius:0px;}
  .challenges-block h3{font-size: 22px; color:#000; margin:0 0 10px}
  .challenges-block p{font-size: 14px; color:#000; margin:0 0 15px}
  .challenges-block .design-block{display: flex; flex-direction: column; width: 100%; margin-top: 15px;}
  .challenges-block .design-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .challenges-block .design-block p{font-size: 14px; color:#000; margin:0 0 0px}
  .challenges-block .design-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .challenges-block .design-block li{display: flex; position: relative; background-color: #fff;  transition: all 0.2s ease; border-radius:12px; box-shadow: 0px 1px 6px #0000001A; justify-content: flex-start; align-items: center; width: 100%; max-width: 250px; padding:15px; margin:0  20px 20px 0; flex-direction: column;}
  .challenges-block .design-block li .thumb{display: flex; width: 210px; height:210px; border-radius: 0%; margin-bottom: 15px; background-color: #d2d3d5;  align-items: center; justify-content: center;}
  .challenges-block .design-block li .thumb-title{display: flex; height:60px; color:#000; margin-bottom: 10px; font-size: 16px; align-items: flex-start; width: 100%;}
  .challenges-block .design-block li .thumb-content{display: flex; line-height: 20px;  color:#999;  margin-bottom: 15px; font-size: 14px; align-items: center; width: 100%;}
  .challenges-block .design-block li .duedate-tag{display: flex; width: auto;}
  .challenges-block .design-block li .duedate-tag span{display: flex; background-color: #e5e5e5;  color:#000; padding:5px 10px; border-radius:5px;  margin-bottom:0px; font-size: 12px; align-items: center;}
  .challenges-block .design-block li .thumb-action{display: flex;  align-items: center; justify-content: center; width: 100%;}
  .challenges-block .design-block li .thumb-action a{box-shadow: 0px 6px 8px #5FA8FF38; margin-left: 10px; color:#fff; font-size: 12px; padding:10px 25px; border-radius:5px; background: transparent linear-gradient(288deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}
  .challenges-block .design-block li .thumb-action a:first-child{margin-left:0px;}
  .challenges-block .design-block li:hover{ transform: translateY(-6px); z-index: 20005;}

  .challenges-block .hoverOverlay{position: absolute; top:0; bottom:0; right:0; z-index:20000;  perspective: 50em;    transition: all 0.35s ease; left:0; opacity: 0; border-radius:12px; visibility: hidden; background-color:rgba(0,0,0,0.9); padding:15px}
  .challenges-block .design-block li .hoverOverlay .thumb-title {color:#fff; font-size:16px; font-weight:bold; height:auto; border-bottom:1px solid #fff; padding-bottom:15px}
  .challenges-block .design-block li .hoverOverlay .thumb-content {color:#fff; line-height: 23px; margin-bottom:15px;}
  .challenges-block .design-block li .hoverOverlay .thumb-action a { box-shadow: 0 6px 8px rgba(27,206,80,.2196078431372549); font-size:15px; background-color: var(--primary); background: linear-gradient(288deg, var(--primary) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box; color:#fff; font-weight: bold;}
  .challenges-block .design-block li:hover .hoverOverlay{opacity: 1; visibility: visible; z-index:20005;margin-bottom:-50px;  transition-delay: 0.1s;}


  .liveprojects-block{display: flex; flex-direction: column; width: 100%; background-color: #F2F4F6; padding:25px; border-radius:0px;}
  .liveprojects-block h3{font-size: 22px; color:#000; margin:0 0 10px; text-transform: capitalize;}
  .liveprojects-block .CircularProgressbar {margin-left: 30px;}
  .liveprojects-block h3 svg{max-width: 60px;}
  .liveprojects-block p{font-size: 14px; color:#000; margin:0 0 15px}
  .liveprojects-block .design-block{display: flex; flex-direction: column; width: 100%; margin-top: 15px;}
  .liveprojects-block .design-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .liveprojects-block .design-block p{font-size: 14px; color:#000; margin:0 0 0px}
  .liveprojects-block .design-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .liveprojects-block .design-block li{display: flex; position: relative; background-color: #fff;  transition: all 0.2s ease; border-radius:12px; box-shadow: 0px 1px 6px #0000001A; justify-content: flex-start; align-items: center; width: 100%; max-width: 250px; padding:15px; margin:0  20px 20px 0; flex-direction: column;}
  .liveprojects-block .design-block li .thumb{display: flex; width: 210px; height:210px; border-radius: 0%; margin-bottom: 15px; background-color: #d2d3d5;  align-items: center; justify-content: center;}
  .liveprojects-block .design-block li .thumb img{max-width: 100%;}
  .liveprojects-block .design-block li .thumb-title{display: flex;  color:#000; height:38px; margin-bottom: 10px; font-size: 16px; align-items: flex-start; width: 100%;}
  .liveprojects-block .design-block li .thumb-content{display: flex; line-height: 20px;  color:#999;  margin-bottom: 15px; font-size: 14px; align-items: center; width: 100%;}
  .liveprojects-block .design-block li .duedate-tag{display: flex; width: auto;}
  .liveprojects-block .design-block li .duedate-tag span{display: flex; background-color: #e5e5e5;  color:#000; padding:5px 10px; border-radius:5px;  margin-bottom:0px; font-size: 12px; align-items: center;}
  .liveprojects-block .design-block li .thumb-action{display: flex;  align-items: center; justify-content:center; width: 100%;}
  .liveprojects-block .design-block li .thumb-action a{box-shadow: 0px 6px 8px #5FA8FF38; margin-left: 10px; color:#fff; font-size: 12px; padding:10px 25px; border-radius: 5px; background: transparent linear-gradient(288deg, var(--primary-rgba) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box;}
  .liveprojects-block .design-block li .thumb-action a:first-child{margin-left:0px;}
  .liveprojects-block .design-block li:hover{ transform: translateY(-6px);}

  .liveprojects-block .hoverOverlay{position: absolute; top:0; bottom:0; right:0; perspective: 50em;    transition: all 0.35s ease; left:0; opacity: 0; border-radius:12px; visibility: hidden; background-color:rgba(0,0,0,0.9); padding:15px}
  .liveprojects-block .design-block li .hoverOverlay .thumb-title {color:#fff; font-size:16px; font-weight:bold; height:auto; border-bottom:1px solid #fff; padding-bottom:15px}
  .liveprojects-block .design-block li .hoverOverlay .thumb-content {color:#fff; line-height: 23px; margin-bottom:15px;}
  .liveprojects-block .design-block li .hoverOverlay .thumb-action a { box-shadow: 0 6px 8px rgba(27,206,80,.2196078431372549); font-size:15px; background-color: var(--primary); background: linear-gradient(288deg, var(--primary) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box; color:var(--white); font-weight: bold;}
  .liveprojects-block .design-block li:hover .hoverOverlay{opacity: 1; visibility: visible;  transition-delay: 0.1s;}


  .mypercentage-block{display: flex; flex-direction: column; width: 100%; background-color: #F2F4F6; padding:25px; border-radius:0px;}
  .mypercentage-block h3{font-size: 22px; color:#000; margin:0 0 10px}
  .mypercentage-block .CircularProgressbar {margin-left: 30px;}
  .mypercentage-block h3 svg{max-width: 60px;}
  .mypercentage-block p{font-size: 14px; color:#000; margin:0 0 15px}
  .mypercentage-block .design-block{display: flex; flex-direction: column; width: 100%; margin-top: 15px;}
  .mypercentage-block .design-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .mypercentage-block .design-block p{font-size: 14px; color:#000; margin:0 0 0px}
  .mypercentage-block .design-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .mypercentage-block .design-block li{display: flex; background-color: #fff;  transition: all 0.2s ease; border-radius:12px; box-shadow: 0px 1px 6px #0000001A; justify-content: center; align-items: center; width: 100%; max-width:220px; padding:15px; margin:0  20px 20px 0; flex-direction: column;}
  .mypercentage-block .design-block li .percentage-value{display: flex; justify-content: flex-end; color:#1D73F2; margin-bottom: 10px; font-size: 32px; align-items: center; width: 100%;}
  .mypercentage-block .design-block li .percentage-notvalue{display: flex; justify-content: flex-end; color:#606060; margin-bottom: 10px; font-size: 22px; align-items: center; width: 100%;}
  .mypercentage-block .design-block li .percentage-title{display: flex; justify-content: flex-end; color:#000000; margin-top: 10px; font-size: 25px; align-items: center; width: 100%;}
  


  .certification-academy-block{display: flex; flex-direction: column; width: 100%; background-color: #F2F4F6; padding: 25px; border-radius:0px;}
  .certification-academy-block .topHeading{display: flex; align-items: center; margin-bottom: 15px;}
  .certification-academy-block .topHeading a{display: flex;     box-shadow: 0px 1px 6px #0000001A; background-color: #fff; justify-content: center; margin-right: 15px; align-items: center; width:40px; height: 40px; border-radius:5px;}
  .certification-academy-block h3{font-size: 22px; color:#000; margin:0 0 0px}
  .certification-academy-block p{font-size: 14px; color:#000; margin:0 0 15px}
  .certification-academy-block .design-block{display: flex; flex-direction: column; width: 100%; margin-top: 15px;}
  .certification-academy-block .design-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .certification-academy-block .design-block p{font-size: 14px; color:#000; margin:0 0 0px}
  .certification-academy-block .design-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .certification-academy-block .design-block li{display: flex; position:relative; background-color: #fff;  transition: all 0.2s ease; border-radius:12px; box-shadow: 0px 1px 6px #0000001A; justify-content: flex-start; align-items: center; width: 100%; max-width: 250px; padding:15px; margin:0  20px 20px 0; flex-direction: column;}
  .certification-academy-block .design-block li .thumb{display: flex; position:relative; width: 210px; height:150px; border-radius: 0%; margin-bottom: 15px; background-color: #d2d3d5;  align-items: center; justify-content: center;}
  .certification-academy-block .design-block li .thumb img{width: 210px; height:150px;}
  .certification-academy-block .design-block li .thumb-title{display: flex; height:38px; color:#000; margin-bottom: 10px; font-size: 16px; align-items: center; width: 100%;}
  .certification-academy-block .design-block li .thumb-content{display: flex; line-height: 20px;  color:#999;  margin-bottom: 15px; font-size: 14px; align-items: center; width: 100%;}
  .certification-academy-block .design-block li .thumb-content-block{display: flex; line-height: 20px;  color:#999;  margin-bottom: 15px; font-size: 14px; align-items: center; width: 100%;content-visibility:hidden;}
  .certification-academy-block .design-block li .duedate-tag{display: flex; width: auto;}
  .certification-academy-block .design-block li .duedate-tag span{display: flex; background-color: #e5e5e5;  color:#000; padding:5px 10px; border-radius:5px;  margin-bottom:0px; font-size: 12px; align-items: center;}
  .certification-academy-block .design-block li .thumb-action{display: flex;  align-items: center; justify-content: center; width: 100%;}
  .certification-academy-block .design-block li .thumb-action a{box-shadow: 0px 6px 8px #5FA8FF38; margin-left: 10px; color:#fff; font-size: 12px; padding:10px 25px; border-radius: 5px; background: transparent linear-gradient(288deg, var(--primary-rgba) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box;}
  .certification-academy-block .design-block li .thumb-action a:first-child{margin-left:0px;}
  .certification-academy-block .design-block li:hover{ transform: translateY(-6px);}

  .certification-academy-block .hoverOverlay{position: absolute; top:0; bottom:0; right:0; perspective: 50em;    transition: all 0.35s ease; left:0; opacity: 0; border-radius:12px; visibility: hidden; background-color:rgba(0,0,0,0.9); padding:15px}
  .certification-academy-block .design-block li .hoverOverlay .thumb-title {color:#fff; font-size:16px; font-weight:bold; height:auto; border-bottom:1px solid #fff; padding-bottom:15px}
  .certification-academy-block .design-block li .hoverOverlay .thumb-content {color:#fff; line-height: 23px; margin-bottom:15px;}
  .certification-academy-block .design-block li .hoverOverlay .thumb-action a { box-shadow: 0 6px 8px rgba(27,206,80,.2196078431372549); font-size:15px; background-color: var(--primary); background: linear-gradient(288deg, var(--primary) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box; color:#fff; font-weight: bold;}
  .certification-academy-block .design-block li:hover .hoverOverlay{opacity: 1; visibility: visible;  transition-delay: 0.1s;}

  .stationaryshop-block{display: flex; flex-direction: column; width: 100%; background-color: #F2F4F6; padding: 25px; border-radius:0px;}
  .stationaryshop-block .topHeading{display: flex; align-items: center; margin-bottom: 10px;}
  .stationaryshop-block .topHeading a{display: flex;     box-shadow: 0px 1px 6px #0000001A; background-color: #fff; justify-content: center; margin-right: 15px; align-items: center; width:40px; height: 40px; border-radius:5px;}
  .stationaryshop-block h3{font-size: 22px; color:#000; margin:0 0 10px}
  .stationaryshop-block p{font-size: 14px; color:#000; margin:0 0 15px}
  .stationaryshop-block .design-block{display: flex; flex-direction: column; width: 100%; margin-top: 15px;}
  .stationaryshop-block .design-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .stationaryshop-block .design-block p{font-size: 14px; color:#000; margin:0 0 0px}
  .stationaryshop-block .design-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .stationaryshop-block .design-block li{display: flex; position: relative; background-color: #fff;  transition: all 0.2s ease; border-radius:12px; box-shadow: 0px 1px 6px #0000001A; justify-content: flex-start; align-items: center; width: 100%; max-width: 250px; padding:15px; margin:0  20px 20px 0; flex-direction: column;}
  .stationaryshop-block .design-block li .thumb{display: flex; width: 210px; height:210px; border-radius: 0%; margin-bottom: 15px; background-color: #d2d3d5;  align-items: center; justify-content: center;}
  .stationaryshop-block .design-block li .thumb img{max-width: 100%;}
  .stationaryshop-block .design-block li .thumb-title{display: flex; height:38px; color:#000; margin-bottom: 10px; font-size: 16px; align-items: flex-start; width: 100%;}
  .stationaryshop-block .design-block li .thumb-content{display: flex; line-height: 20px;  color:#999;  margin-bottom: 15px; font-size: 14px; align-items: center; width: 100%;}
  .stationaryshop-block .design-block li .duedate-tag{display: flex; width: auto;}
  .stationaryshop-block .design-block li .duedate-tag span{display: flex; background-color: #e5e5e5;  color:#000; padding:5px 10px; border-radius:5px;  margin-bottom:0px; font-size: 12px; align-items: center;}
  .stationaryshop-block .design-block li .thumb-action{display: flex;  align-items: center; justify-content:center; width: 100%;}
  .stationaryshop-block .design-block li .thumb-action a{box-shadow: 0px 6px 8px #5FA8FF38; margin-left: 10px; color:#fff; font-size: 12px; padding:10px 25px; border-radius: 5px; background: transparent linear-gradient(288deg, var(--primary-rgba) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box;}
  .stationaryshop-block .design-block li .thumb-action a:first-child{margin-left:0px;}
  .stationaryshop-block .design-block li:hover{ transform: translateY(-6px);}

  .stationaryshop-block .hoverOverlay{position: absolute; top:0; bottom:0; right:0; perspective: 50em;    transition: all 0.35s ease; left:0; opacity: 0; border-radius:12px; visibility: hidden; background-color:rgba(0,0,0,0.9); padding:15px}
  .stationaryshop-block .design-block li .hoverOverlay .thumb-title {color:#fff; font-size:16px; font-weight:bold; height:auto; border-bottom:1px solid #fff; padding-bottom:15px}
  .stationaryshop-block .design-block li .hoverOverlay .thumb-content {color:#fff; line-height: 23px; margin-bottom:15px;}
  .stationaryshop-block .design-block li .hoverOverlay .thumb-action a { box-shadow: 0 6px 8px rgba(27,206,80,.2196078431372549); font-size:15px; background-color: var(--primary); background: linear-gradient(288deg, var(--primary) 0%, var(--primary) 100%) 0% 0% no-repeat padding-box; color:#fff; font-weight: bold;}
  .stationaryshop-block .design-block li:hover .hoverOverlay{opacity: 1; visibility: visible;  transition-delay: 0.1s;}



  .mygoal-block{display: flex; flex-direction: column; width: 100%; background-color: #F2F4F6; padding: 25px; border-radius:0px;}
  .mygoal-block .topHeading{display: flex; align-items: center; margin-bottom: 10px;}
  .mygoal-block .topHeading a{display: flex;     box-shadow: 0px 1px 6px #0000001A; background-color: #fff; justify-content: center; margin-right: 15px; align-items: center; width:40px; height: 40px; border-radius:5px;}
  .mygoal-block h3{font-size: 22px; color:#000; margin:0 0 0px}
  .mygoal-block h6{font-size: 18px; color:#000; margin:15px 0 5px}
  .mygoal-block p{font-size: 14px; color:#000; margin:0 0 15px}
  .mygoal-block .design-block{display: flex; flex-direction: column; width: 100%;  box-shadow: 0px 1px 6px #0000001A;  margin-top: 15px; background-color: #fff; padding: 15px;}
  .mygoal-block .design-block h3{font-size: 18px; color:#000; margin:0 0 15px}
  .mygoal-block .design-block p{font-size: 14px; color:#000; margin:0 0 0px}
  .mygoal-block .design-block ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
  .mygoal-block .design-block li{display: flex; transition: all 0.2s ease; justify-content: center; align-items: center; width: 100%; padding:15px; margin:0  0px 20px 0; flex-direction: column;}
  .mygoal-block .design-block li .question-title{display: flex;  color:#000; margin-bottom: 17px; font-weight: bold; font-size: 18px; align-items: center; width: 100%;}
  .mygoal-block .design-block li .question-content{display: flex; line-height: 20px;  color:#999;  margin-bottom: 15px; font-size: 14px; align-items: center; width: 100%;}
  .mygoal-block .design-block li .question-action{display: flex;  align-items: center; justify-content: space-between; width: 100%;}
  .mygoal-block .design-block li .question-value{display: flex; flex-direction: column; width: 100%; align-items: flex-start;}
  .mygoal-block .design-block li .question-value .quill{width: 100%;}
  .mygoal-block .design-block li .question-value input {resize:none; width: 100%;  font-family: Lato;  font-size: 16px; border-radius:0;   line-height: 1.5; color: var(--text-black); padding:5px 10px; border:0px none; border-bottom: 1px solid var(--primary); background-color: var(--primary-rgba);}
  .mygoal-block .design-block li .question-value textarea {resize:none; width: 100%; min-height:80px;  font-family: Lato;  font-size: 16px; border-radius:0;   line-height: 1.5; color: var(--text-black); padding:5px 10px; border:0px none; border-radius:5px; background-color: transparent; border: 1px solid #ccc;}
  .mygoal-block .design-block .question-action {display: flex; margin-top: 15px; width: 100%; justify-content: flex-end; align-items: flex-end;}
  .mygoal-block .design-block .question-action button{border-radius:5px; background-color:var(--primary)}





  .askDialog.isvisible{display:flex;}
  .askDialog{position: fixed; display:flex; display: none; align-items: center; z-index: 2250000; justify-content: center; background-color:rgba(0,0,0,0.2); top:0; right:0; left:0; bottom:0}
  .askDialog .askDialog-inner{background-color: #fff; width: 100%; max-width: 600px; padding:32px 40px; border-radius:15px; position: relative;}
  .askDialog .askDialog-inner .popup-header{ width: 100%; text-align: center;  font-family: Lato; font-size: 18px;  font-weight: 700; color: var(--text-black);}
  .askDialog .askDialog-inner .close { position: absolute; top: 30px; right: 40px; font-weight: bold;  font-size: 21px;  cursor: pointer;
    -webkit-user-select: none;  user-select: none;}
  .askDialog .askdialog-content{display: flex; width: 100%; margin-top: 35px;}
  .askDialog .askdialog-content .image-upload { width: 200px; margin-right: 20px; margin-top: 0;  display: flex;  flex-direction: column;  align-items: flex-start;}
  .askDialog .askdialog-content .image-upload  span { margin-left: 0px; font-family: Lato; font-size: 18px;font-weight: 600; color: var(--text-black2);}
  .askDialog .askdialog-content .image-upload img {width: 160px;}
  .askDialog textarea { width:100%; resize:none;  font-family: Lato;  font-size: 18px; line-height: 1.5; color: var(--text-black); padding: 14px 24px;  border-color: var(--light-black);   border-radius: 4px;}
  .askDialog .askdialog-action {display: flex; margin-top: 15px; width: 100%; justify-content: flex-end; align-items: flex-end;}
  .askDialog .askdialog-action button{border-radius:5px; background:transparent linear-gradient(288deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}

  .internshipDialog.isvisible{display:flex;}
  .internshipDialog{position: fixed; display:flex; display: none; align-items: center; z-index: 2250000; justify-content: center; background-color:rgba(0,0,0,0.2); top:0; right:0; left:0; bottom:0}
  .internshipDialog .internshipDialog-inner{background-color: #fff; width: 100%; max-width: 600px; padding:32px 40px; border-radius:15px; position: relative;}
  .internshipDialog .internshipDialog-inner .popup-header{ width: 100%; text-align: center;  font-family: Lato; font-size: 22px;  font-weight: 700; color: var(--text-black);}
  .internshipDialog .internshipDialog-inner .close { position: absolute; top: 30px; right: 40px; font-weight: bold;  font-size: 21px;  cursor: pointer;
    -webkit-user-select: none;  user-select: none;}
  .internshipDialog .internshipdialog-content{display: flex; flex-direction: column; width: 100%; margin-top: 35px;}
  .internshipDialog .internshipdialog-content .form-block{display: flex; flex-direction: column; width: 100%; margin-bottom: 25px;}
  .internshipDialog .internshipdialog-content .form-block label{font-family: Lato; font-size: 16px; font-weight: 700; margin-bottom: 10px; color: var(--text-black);}
  .internshipDialog input { width:100%; resize:none; width: 100%;  font-family: Lato;  font-size: 16px; border-radius:0;   line-height: 1.5; color: var(--text-black); padding:5px 10px; border:0px none; border-bottom: 1px solid var(--light-black);}
  .internshipDialog .internshipdialog-action {display: flex; margin-top: 15px; width: 100%; justify-content: flex-end; align-items: flex-end;}
  .internshipDialog .internshipdialog-action button{border-radius:5px; background:transparent linear-gradient(288deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;}


  .thanksDialog.isvisible{display:flex;}
  .thanksDialog{position: fixed; display:flex; display: none; align-items: center; z-index: 2250000; justify-content: center; background-color:rgba(0,0,0,0.2); top:0; right:0; left:0; bottom:0}
  .thanksDialog .thanksDialog-inner{background-color: #fff; width: 100%; max-width: 600px; padding:32px 40px; border-radius:15px; position: relative;}
  .thanksDialog .thanksDialog-inner .popup-header{ width: 100%; text-align: center;  font-family: Lato; font-size: 22px;  font-weight: 700; color: var(--text-black);}
  .thanksDialog .thanksDialog-inner .close { position: absolute; top: 30px; right: 40px; font-weight: bold;  font-size: 21px;  cursor: pointer;
    -webkit-user-select: none;  user-select: none;}
  .thanksDialog .thanks-tick{display:flex; width: 100%;  font-family: Lato; font-size: 22px; color:#000; align-items: center; justify-content: center; flex-direction: column;}
  .thanksDialog .thanks-tick i{display:flex; align-items: center; margin-bottom:5px; width: 66px; height:66px; flex-shrink: 0; border-radius:100%; background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box; justify-content: center;}
  .thanksDialog .thanksDialog-content{display: flex; flex-direction: column; width: 100%; margin-top: 35px;}
  .thanksDialog ol{display:flex; margin:0; padding:0; display:flex; flex-direction: column;}
  .thanksDialog ol li{display:list-item; margin:15px 0 0; padding:0; list-style-type: decimal;  font-family: Lato; color:#666666; font-size:16px; list-style-position:inside;}


  .thanksApplyDialog.isvisible{display:flex;}
  .thanksApplyDialog{position: fixed; display:flex;  display: none; align-items: center; z-index: 2250000; justify-content: center; background-color:rgba(0,0,0,0.2); top:0; right:0; left:0; bottom:0}
  .thanksApplyDialog .thanksApplyDialog-inner{background-color: #fff; width: 100%; max-width: 400px; padding:32px 40px; border-radius:15px; position: relative;}
  .thanksApplyDialog .thanksApplyDialog-inner .popup-header{ justify-content: center; display: flex; align-items: center; width: 100%; text-align: center;  font-family: Lato; font-size: 22px;  font-weight: 700; color: var(--text-black);}
  .thanksApplyDialog .thanksApplyDialog-inner .close { position: absolute; top: 15px; right: 15px; font-weight: bolder;  font-size:15px;  cursor: pointer; -webkit-user-select: none;  user-select: none;}
  .thanksApplyDialog i{display:flex; align-items: center; margin-right:10px; width: 40px; height:40px; flex-shrink: 0; border-radius:100%; background: transparent linear-gradient(123deg, #1BCE52 0%, #1D73F2 100%) 0% 0% no-repeat padding-box; justify-content: center;}
  .thanksApplyDialog i img{width:23px;}
  .thanksApplyDialog .thanksApplyDialog-content{display: flex; align-items: center; font-family: Lato; color:#999; font-size: 15px; flex-direction: column; width: 100%; margin-top: 30px;}



  .forMobile{display:flex; display: none;}
  .forMobile a{display:flex; position: relative; top:0}
  .forMobile span{width:30px; position: absolute; top:0; left:0; height:4px; background-color:var(--white);}
  .forMobile span:nth-child(1){top:0px}
  .forMobile span:nth-child(2){top:10px}
  .forMobile span:nth-child(3){top:20px}


  .mobileview .header2{left:0; z-index: 2200001;}
  
  .MobielCloseLink{position: absolute; top:10px; right:10px; color:#fff; display:none; font-weight: bold; font-size: 25px;} 

  .mobileOverlay {position:fixed; top:0; display:none; right:0; bottom:0; left:0; z-index: 220000; background-color:rgba(0,0,0,0.5)}

  .mobileview .mobileOverlay{display:flex;}


  .mycoach-wrapper .mycoach-right .fun-block-list h3{font-size: 16px; color:#000; margin:0 0 15px}
.mycoach-wrapper .mycoach-right .fun-block-list ol{display: flex; margin:0; padding:0; width: 100%;  flex-wrap: wrap;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li{display: flex; transition: all 0.2s ease;  width: 100%; max-width: 30%; border-radius:18px; color:#fff;  margin:0  0px 0px 20px; padding:15px; flex-direction: column;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(1){background-image: url(../../public/images/veranda-innovation.png);
    background-repeat: no-repeat;
    background-size: cover;margin-left: 0;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li a{color:#fff; display: flex; flex-direction: column;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(2){background-color: rgba(255,82,2,0.7);}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(2) a{pointer-events: auto; opacity:1; cursor: pointer;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(3) a{pointer-events: auto; opacity:1; cursor: pointer;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(3){background-color: rgba(95,168,255,0.7);}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(1) i{margin-bottom: 15px; align-items: center; justify-content: center; display: flex;     align-self: flex-end; background-color:rgba(96,96,25,0.2); border-radius:100%; width: 60px;  height:60px;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(2) i{margin-bottom: 15px; align-items: center; justify-content: center;   display: flex; align-self: flex-end; background-color:rgba(96,96,25,0.2); border-radius:100%; width: 60px; height:60px;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(2) i img{width:35px; max-width: 100%;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(3) i img{width:35px; max-width: 100%;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(3) i{margin-bottom: 15px;  align-items: center; justify-content: center; display: flex;  align-self: flex-end; background-color:rgba(96,96,25,0.2); border-radius:100%; width: 60px; height:60px;}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:hover{ transform: translateY(-6px);}

.refer-more{
    margin-top: 15px;
    margin-left: 176px;
}

.refer-more button{
    margin-right: 10px;
}

.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(1) h3{
  font-size: 17px;
  color: #fff;
  margin: 0 0 5px;
}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(1) p{
  font-style: italic;
  font-size: 14px;
  color: #fff;
  margin: 0 0 0px;
}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(1) p + p{
  margin-top: 2px;
}

.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(1) .arrow{
  position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: rgba(255,255,255,0.2);
}

.mycoach-wrapper .mycoach-right .fun-block-list ol li:nth-child(3) {
    background: transparent linear-gradient(121deg, #5FA8FF 0%, #1D73F2 100%) 0% 0% no-repeat padding-box;
}


.mycoach-wrapper .mycoach-right .fun-block-list ol li h3 {
    font-size: 17px;
    color: #fff;
    margin: 0 0 15px;
}
.mycoach-wrapper .mycoach-right .fun-block-list ol li .content{
    display: flex;
}

.mycoach-wrapper .mycoach-right .fun-block-list ol li .content i{
  margin-left: 30px;
}
.mycoach-wrapper .mycoach-right .fun-block-list ol li:last-child span{
  font-size: 14px;
    padding: 5px 15px;
    border-radius: 21px;
    color: #1D73F2;
    background-color: #fff;
    margin: 0 0 0px;
}

  /*Media Query*/

  @media only screen and (min-width: 1000px) {
    .mycoach-wrapper .mycoach-right .coach-block ol li{ max-width: 21%;}
    .mycoach-wrapper .mycoach-right .fun-block ol li{width: 28%;}

    /* body{background-color:#fff!important} */
  } 


  @media only screen and (max-width: 1200px) {
    .mycoach-wrapper .mycoach-right .coach-block ol li{ max-width: 20%;}
    /* body{background-color:#fff!important} */
    #root{width:100%}
  }

  @media only screen and (max-width: 1100px) {
    .app-header .profile{min-height:31px;}
    .mycoach-wrapper .mycoach-right .coach-block ol li{ max-width: 21%; text-align: center;}
    .mycoach-wrapper .mycoach-main{flex-direction: column; padding:15px;}
    .mycoach-wrapper .mycoach-main{display:block}

    .mycoach-wrapper .mycoach-left ol{flex-direction: row; overflow-x: auto;}
    .mycoach-wrapper .mycoach-left{max-width:100%; margin-bottom:20px;}
    .mycoach-wrapper .mycoach-left ol li{margin:0 0 0 15px; min-width: 240px}
    .mycoach-wrapper .mycoach-left ol li:first-child{margin-left:0}
    .mycoach-wrapper .mycoach-right{margin-left:0}
    .mycoach-wrapper .mycoach-right-bottom{flex-direction:column;}
    .mycoach-wrapper .mycoach-right-bottom .subcribe-block{max-width:none;}
    .mycoach-wrapper .mycoach-right .fun-block{margin:25px 0 0 0; max-width:none;}

    .app-body{position: relative;}
    .app-div.mycoach-div{top:auto}
    .app-div.doubt-main{top:auto}

    .mycoach-header{position: static; flex-direction: column; background-color:#fff; padding:15px 28px; margin: 15px 0 0 250px;}
    .mycoach-header .intro-block{flex-direction:column; margin:0px;}
    .mycoach-header .intro-block .user-block{width: 100%; order:2; max-width:80%;}
    .mycoach-header .intro-block .askanything{order:1;  align-self: flex-end; position:absolute;}

    .app-header{position: static; padding: 28px 28px 0;}
    .profile-option div{background-color: #fff; margin-right:0}
    .mypercentage-block .design-block li{max-width: 100%; margin:0 0 15px}

    .popup-doubt-content{width:340px!important;}
    .popup-ask-doubt .doubt-content-div{flex-direction:column; height:auto; margin-top:0}
    .popup-ask-doubt .image-upload{width: 100%; align-items: center;}
    .popup-ask-doubt .ask-doubt-content{margin-top:0}
    .popup-ask-doubt .ask-doubt-content textarea {width: 100%; align-items: center;}
    .popup-ask-doubt .ask-doubt-action{margin-top:0px}

    .popup-doubt2-content{width:340px!important; height: auto!important;}
    .popup-doubt2-content .ask-doubt-content .doubt-content-div{flex-direction:column; height:auto}
    .popup-doubt2-content .ask-doubt-content .doubt-content-div .image-upload{width: 100%; align-items: center;}
    .popup-doubt2-content .ask-doubt-content textarea {width: 100%; align-items: center;}
    .popup-doubt2-content .ask-doubt-action{margin-top:20px}

    .thanksDialog .thanksDialog-inner{max-width: 520px;}
    .thanksDialog .thanks-tick{margin-bottom:15px}
    .internshipDialog .internshipDialog-inner{max-width: 520px;}
    .mycoach-wrapper .mycoach-right .fun-block-list ol{flex-direction: column;}
    .mycoach-wrapper .mycoach-right .fun-block-list ol li{max-width:none; margin:0 0 15px 0;}
  }

 
  @media only screen and (max-width: 900px) {

    body{background-color:var(--primary)!important}

    .mycoach-wrapper .mycoach-right .coach-block ol{align-items: center; justify-content: center;}
    .mycoach-wrapper .mycoach-right .coach-block ol li{max-width: 40%; margin:0 10px 20px}
    .mycoach-wrapper .mycoach-right .fun-block ol{overflow-x:auto; flex-wrap:nowrap}
    .mycoach-wrapper .mycoach-right .fun-block ol li{min-width: 160px;}
    .forMobile{display:none;}
    .MobielCloseLink{display:flex;}
    .header2{left:-250px}
    .app-div.mycoach-div{left:0}
    .mycoach-header{left:0}
    .app-header{left:0; padding-left:28px;}
    .app-div.doubt-main{left:0; height: calc(100vh - 205px);}
    .app-div.app-dashboard{left:0;    height: calc(100vh - 145px); background-color: #F2F4F6; top:auto; padding:25px; border-radius:0}
    .app-div.app-webinar{left:0;    height: calc(100vh - 145px); background-color: #F2F4F6; top:auto; padding:25px; border-radius:0}

    .app-div.app-studymaterial{left:0;    height: calc(100vh - 145px); background-color: #F2F4F6; top:auto; padding:25px; border-radius:0}
    .app-div.app-studymaterial .sm-chapters{width:100%;}
    .app-div.app-studymaterial .sm-unit-div{flex-direction:column;}
    .app-div.app-studymaterial .sm-unit-div1{width:100%; height:auto; margin-top: 10px;}
    .app-div.app-studymaterial .sm-unit-div1 span{position:static}

    .dashboard-main{display: flex; flex-direction: column;}
    .dashboard-main .dashboard-left{width:100%}
    .dashboard-main .dashboard-right{width:100%; margin-left:0}
    .dashboard-main .dashboard-left .dash-subject{width:100%; margin:0 0px 20px 0}
    .dashboard-main .dashboard-left .dash-practice{width:100%}
    .dropdown-content2{z-index:20}

    .single-doubt{display: flex; flex-direction: column;}
    .single-doubt-right{margin-bottom:15px; width: 100%;} 

    .popup-content-edit-pofile-content{position: fixed!important; z-index: 20004!important; left: 0;   top: 0;   bottom: 0;   right: 0;}
    .popup-content-edit-pofile-content .popup-inputs-edit-profile{flex-direction:column}
    .popup-content-edit-pofile-content .popup-inputs-edit-profile input:first-child{width:100%!important; margin-bottom:15px;}
    .popup-content-edit-pofile-content .popup-inputs-edit-profile input:last-child{width:100%!important; margin-bottom:15px;}
    .popup-content-edit-pofile-content .img-prev{width:100%; margin:0}

 

   #root{width:100%}

    .app-body{position: relative;}
    .app-div.mycoach-div{top:auto; width:100%}
    .app-div.doubt-main{top:auto; height: calc(100vh - 420px); right:0}
    

    .mycoach-header{position: static; flex-direction: column; background-color:#fff; padding:15px 28px; margin: 15px 0 0;}
    .mycoach-header .intro-block{flex-direction:column; margin:0px;}
    .mycoach-header .intro-block .user-block{width: 100%; order:2; max-width: 80%;}
    .mycoach-header .intro-block .askanything{order:1;  align-self: flex-end; position:absolute; font-size:0}

    .app-header{position: static; padding: 28px 28px 0; width: 100%;}
    .profile-option div{background-color: #fff;}
    .mypercentage-block .design-block li{max-width: 100%; margin:0 0 15px}

    
    .mycoach-header .intro-block .askanything .ask-doubt{z-index:11}


    .page-header{display:flex; flex-direction:column; justify-content: center;  align-items: center;}
    .page-header .page-header-left{margin-bottom:15px}


    .list-sticky{right:0; width:100%; padding: 25px;  height: calc(100vh - 168px);}
    .dash-test-first{margin:0 0 15px}
    .dash-test{width:100%;}

    .mycoach-wrapper .mycoach-right .fun-block-list ol{flex-direction: column;}
 

  }   

  @media only screen and (max-width: 900px) and (orientation:landscape){

    .app-div.mycoach-div{height: calc(100vh - 204px);}
    .app-div.doubt-main{top:auto; height: calc(100vh - 205px);}

  }

  

  @media only screen and (max-width: 770px) {

    .thanksDialog .thanksDialog-inner{max-width: 320px;}
    .internshipDialog .internshipDialog-inner{max-width: 320px;}

    .app-div.mycoach-div{height: calc(100vh - 420px);}
    .mycoach-wrapper .mycoach-right .coach-block{height:auto!important}
    .refer-more{
        margin-top: 15px;
        margin-left: 0px;
    }
  }
  

  @media only screen and (max-width: 470px) {

    .sm-chapters li{max-width: none;}
    

  }


.mycoach-wrapper .mycoach-right .fun-block-list {
    box-shadow: 0px 3px 6px #9090901F;
    background-color: #fff;
    border-radius: 18px;
    padding: 20px;
    width:100%;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

/*Media Query*/
