.iserrorfound-content{
      height: 50% !important;
      width: 25% !important;
      font-size: 16px;
      font-family: 'Lato';
      text-align: center;
      padding: 19px !important;
      border-radius: 8px !important;
}

.ohno{
    font-size: 20px;
    color: red;
    font-weight: 600;
}

.connection_failed{
  margin: 10px;
  color: #153550;
  font-size: 16px;
  font-weight: 600;
}
