
/* In your main CSS file or component file */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.admin-div {
    /* padding: 20px; */
    background-color: #f9f9f9;
    /* overflow-y: auto;
    overflow-x: auto; */
    
}

/* .college-info-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
    scroll-behavior: smooth;

} */
.carousel-section {
    margin-bottom: 20px;
}

.carousel-slide {
    padding: 10px;
}

.carousel-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.college-info-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1500px;
    margin: 0 auto;
    max-height: 80vh; /* Adjust this value as needed */
    overflow-y: auto;
    padding: 20px; /* Optional: Add padding for better spacing */
    box-sizing: border-box;
}


.banner-section {
    position: relative;
}

.college-banner {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.college-logo {
    position: absolute;
    /* bottom: -40px; */
    right: 0px;
    top: 40;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.college-details {
    padding: 60px 20px 20px 20px;
    text-align: center;
}

.college-details h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.meta-info {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

.action-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}
/* .checkbox-row {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
  
  } */

.admin-div .checkbox-row {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
  }

.action-buttons button {
    background-color: #fff;
    border: 1px solid#218838;
    color:#218838;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.action-buttons button:hover {
    background-color:#218838;
    color: #fff;
}

.save-btn, .compare-btn, .brochure-btn {
    min-width: 100px;
}

.brochure-btn {
    background-color: #28a745;
    color: white;
    border: none;
}

.brochure-btn:hover {
    background-color: #218838;
}

