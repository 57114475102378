.video-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .video-player {
    width: 100%;
    display: block;
  }
  
  .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    padding: 20px;
    transition: opacity 0.3s ease;
  }
  
  .controls-hidden .controls {
    opacity: 0;
  }
  
  .control-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 4px;
  }
  
  .control-button {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
    transition: transform 0.1s ease;
    margin: 0px;
    
  }
  
  .control-button:hover {
    transform: scale(1.1);
  }
  
  .seek-bar {
    flex-grow: 1;
    margin: 0 10px;
    -webkit-appearance: none;
    background: rgba(255, 255, 255, 0.2);
    outline: none;
    border-radius: 10px;
    height: 5px;
    transition: height 0.1s ease;
  }
  
  .seek-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .seek-bar::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .seek-bar:hover {
    height: 8px;
  }
  
  .volume-control {
    display: flex;
    align-items: center;
  }
  
  .volume-slider {
    width: 80px;
    -webkit-appearance: none;
    background: rgba(255, 255, 255, 0.2);
    outline: none;
    border-radius: 10px;
    height: 5px;
  }
  
  .volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .volume-slider::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .playback-rate {
    /* background: rgba(17, 17, 17, 0.1); */
    border: 1px solid rgba(19, 19, 19, 0.2);
    /* color: white; */
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 14px;
    width: 100px;
  }
  
  .time-display {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
  }
  