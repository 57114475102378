.vjs_video_3-dimensions.vjs-fluid{
  padding-top: 48%;
}

.video-container {
  width: 100%;
  position: relative;
}

.video-container2 {
  width: 100vw;
}

video {
  outline: none;
}

.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}

#play_pause {
	background-color: transparent;
	width: 33%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 33%;
}

#rewind {
	background-color: transparent;
	width: 33%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

#rewind img {
	width: 18%;
	opacity: 0.65;
	cursor: pointer;
}

#forward {
	background-color: transparent;
	width: 33%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;	
	display: flex;
	align-items: center;
	justify-content: center;
}

#forward img {
	width: 18%;
	opacity: 0.65;
	cursor: pointer;
}

.YoutubePlayer{display: flex; height:450px}
.videoSurvayDialog.open{display: flex;}
.videoSurvayDialog{position: fixed; background-color: rgba(0,0,0,0.4); top:0; right:0; bottom:0;   font-family: lato;   z-index: 2200000; left:0; display: flex; display:none; align-items: center; justify-content: center;}
.videoSurvayDialog .videoSurvayContainer{position: relative; flex-direction: column; display: flex; width:100%; max-width: 600px; background-color:#fff; border-radius: 10px; padding: 15px; box-shadow: 0 0 5px #ddd;;}
.videoSurvayDialog .videoSurvayContainer .dialogClose{position: absolute; cursor: pointer; top:10px; right:10px; color:#000; font-size: 16px;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper{display: flex; width: 100%; flex-direction: column;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol{margin:0; padding:0; gap:20px; display: flex; flex-direction: column;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li{margin:0; display: flex; flex-direction: row; gap:15px; padding:0; padding:10px; bordeR:1px solid #ddd; padding:10px; border-radius: 10px;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .question{text-align: left; flex:1; font-family: inherit; font-size: 16px;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .rating{text-align: center; gap:15px; display: flex; justify-content: center;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .rating label{display:flex; align-items: center; margin:0; padding:0; justify-content: center;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .rating label input[type="radio"]{display: none;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .rating label span{width:32px; height: 32px; display:flex; align-items: center; justify-content: center; border-radius: 100%; border:1px solid #ddd;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .rating label input[type="radio"]:checked +  span{background-color: var(--primary); color:#fff;}
.videoSurvayDialog .videoSurvayContainer .contentWrapper ol li .rating span:hover{ cursor: pointer; border:1px solid var(--primary); background-color: var(--primary); color:#fff;}
.videoSurvayDialog .videoSurvayContainer  h3{margin-bottom: 15px; text-align: center;}
.videoSurvayDialog .videoSurvayContainer .formActions{display: flex; gap:15px; width: 100%; justify-content: center; align-items: center; margin-top:15px; border-top:1px solid #ddd;padding-top: 10px;}
.videoSurvayDialog .videoSurvayContainer .formActions span{cursor: pointer; display: none; color:var(--primary); text-decoration: underline;}
.videoSurvayDialog .videoSurvayContainer .formActions span:hover{cursor: pointer; color:var(--primary); text-decoration: none;}

.videoSurvayThankuDialog.open{display: flex;}
.videoSurvayThankuDialog{position: fixed; background-color: rgba(0,0,0,0.4); top:0; right:0; bottom:0;   font-family: lato;   z-index: 2200000; left:0; display: flex; display: none;  align-items: center; justify-content: center;}
.videoSurvayThankuDialog .videoSurvayThankuDialogContainer{position: relative; flex-direction: column; display: flex; width:100%; max-width: 600px; background-color:#fff; border-radius: 10px; padding: 15px; box-shadow: 0 0 5px #ddd;;}
.videoSurvayThankuDialog .videoSurvayThankuDialogContainer .dialogClose{position: absolute; cursor: pointer; top:10px; right:10px; color:#000; font-size: 16px;}
.videoSurvayThankuDialog .videoSurvayThankuDialogContainer  h3{margin:0; margin-bottom: 5px; font-size: 28px; color: green; text-align: center;}
.videoSurvayThankuDialog .videoSurvayThankuDialogContainer  h5{margin:0; margin-bottom: 15px; font-size: 16px; color:#999; text-align: center;}
.videoSurvayThankuDialog .videoSurvayThankuDialogContainer  p{padding:0; margin:0; line-height: 24px; text-align: center;}
.videoSurvayThankuDialog .videoSurvayThankuDialogContainer .formActions{display: flex; width: 100%; justify-content: center; align-items: center; margin-top:15px; border-top:1px solid #ddd;padding-top: 10px;}

