.admin-div {
    padding: 20px;
  }
  
  .filter-section {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .search-input {
    padding: 10px;
    width: 200px;
  }
  
  /* .date-filters label {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
   */
  .assessment-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .assessment-table th, .assessment-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .loading img {
    display: block;
    margin: 20px auto;
  }
  
  .error-message, .no-data-message {
    text-align: center;
    margin-top: 200px;
    color: black;
    font-weight: 500;
    font-size: 20px;
}

  