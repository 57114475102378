/* src/components/AttendanceReport.css */
.attendance-report {
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* src/components/AttendanceReport.css */
.dropdown-container {
  width: 200px;
  display: flex;
  gap: 10px;
 
  margin-bottom: 20px;
  
}
.th-fixed-width {
  width: 150px; 
}

.dropdown-container select {
  width: 200px;
  padding: 5px;
  font-size: 14px;
  height: 30px;
}


.dropdown-container input {
  margin: 0;
  height: 30px;
  border: 1px solid;
}
th,
td {
  padding: 8px 12px;
  text-align: center;
  border: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}
.export-button-container{
  display: flex;
  gap: 4px;
}



.attendance-report-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

}
th {
  width: 300px;
}