@import url('https://fonts.googleapis.com/css?family=Lato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200italic,300,300italic,400,400italic,600,600italic,700,700italic,900,900italic');
@import url('https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Muli:200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,900');

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --primary: #2ea87e;
  --primary-rgba: rgba(167,37,38,0.10);
  --secondary: #FED41E;
  --secondary-rgba:var(--primary-rgba);
  --white: #ffffff;
  --black: #000000;
  --black2: rgba(0,0,0,0.90);
  --text-black: rgba(0,0,0,0.84);
  --text-black2: rgba(0,0,0,0.74);
  --text-black3: rgba(0,0,0,0.60);
  --light-black: rgba(0,0,0,0.20);
  --light-black3: rgba(0,0,0,0.10);
  --light-black2: rgba(0,0,0,0.02);
}

::selection {
  background: var(--primary); /* WebKit/Blink Browsers */
  color: #ffffff;
}

::-moz-selection {
  background: var(--primary); /* Gecko Browsers */
  color: #ffffff;
}

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  width: 100vw;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  background-color: var(--primary);
  align-items: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;  
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-rgba);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

html {
  overflow-y: auto;
}

a, button, input, textarea, select, option {
  outline: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

textarea {
  resize: none;
}

input[type=checkbox] {
  width: 18px;
  height: 18px;
  background-color: var(--white);
  cursor: pointer;
  margin-right: 14px;
}

button {
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
  border: 1px solid var(--primary);
  background-color: var(--primary);
  border: none;
  padding: 6px 20px 8px 20px;
  border-radius: 40px;
  cursor: pointer;
}

#button-reverse {
  background-color: white;
  border: 1px solid var(--primary);
  color: var(--primary);
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

button:hover {
  opacity: 0.9;
}

header {
  position: fixed;
  top: 15px;
  top: 0px;
  width: 260px;
  background-color: transparent;
  left: 15px;
  height: calc(100vh - 30px);
  padding-top: 20px;
  padding-bottom: 20px;
}

.header2 {
  z-index: 2200001;
  position: fixed;
  top: 40px;
  width: 20.9vw;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: var(--white);
  box-shadow: -4px -4px 15px 0px #ffffff9e, 4px 4px 15px 0px #a3b1c6a8;
  left: 40px;
  height: calc(100vh - 80px);
  height: calc(100%);
  padding-top: 45px;
  padding-bottom: 65px;
  padding-left: 40px;
  padding-right: 40px;
  overflow-y: auto;
  transition: all 0.2s ease;
  z-index: 998;
}

.header2{top:0; left:0; padding-top:0; padding-left:0px; padding-right:0px; width:250px; z-index:12; border-radius: 0px; box-shadow: -3px -3px 6px 0px #ffffff9e, 3px 3px 6px 0px #a3b1c6a8;}
.header2 .sc-logo-div-up{background-color:var(--primary); margin: 10px 0;}
/* .header2{width:5.9vw; padding:10px; border-radius:5px} */
.header2 .sidebar-links{margin-top:0px;}
.header2 .sidebar-links a{position: relative; border-left:6px solid transparent; padding:15px; margin-bottom:0}
.header2 .sidebar-links a:hover{background-color: #F5F7FB; color:var(--primary); border-left:6px solid var(--primary);;}
.header2 .sidebar-links a span{opacity: 1; white-space: nowrap; visibility: visible; transform: translate3d(0%,0,0);}
.header2 .sidebar-links a img{margin: 0 15px 0 0;}
.sc-logo-div{background-size: contain;}
.sc-logo-div img{width: 50px;}
.header2 .sidebar-links #selected-sl{background-color: #F5F7FB; color:var(--primary); font-weight: normal; border-left:6px solid var(--primary);}
/* .sidebar-links #selected-sl:before{content: " "; position: absolute; height: 6px; width: 6px; top: 50%; margin-top:-3px; left:0px; background-color:var(--primary); border-radius: 50%;} */

/* .header2:hover{width: 15.9vw;} */
.header2:hover .sidebar-links a span {opacity: 1; visibility: visible; transform: translate3d(0,0,0);}




.paper-modal {
	position: fixed !important;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  z-index: 10000 !important;
  display: flex;
  align-items: flex-start;
  overflow-y: hidden;
  justify-content: center;
  background-color: #ffffff;
}

.app-body {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--white);
}

.content-body-container {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-body {
  position: relative;
  width: 1200px;
  max-width: 1200px;
  min-height: 100vh;
}

.app-div {
  width: 76.1vw;
  height: calc(100vh - 130px);
  position: fixed;
  top: 120px;
  right: 0px;
  border-radius: 30px;
  background-color: transparent;
  padding: 10px 50px 25px 50px;
  overflow-y: auto;
  padding-bottom: 80px;
}
.app-div{width: auto; left:250px; padding-left:25px; padding-right:15px}


#path-b, #path-d {
  z-index: -1 !important;
  position: fixed;
  top: 0;
  right: 0;
}

#path-b {
  width: 500px;
 
}

#path-d {
  width: 600px;
}

.admin-div {
  overflow: hidden;
  width: calc(100vw - 290px);
  height: calc(100vh - 30px);
  position: fixed;
  top: 15px;
  right: 15px;
  border-radius: 30px;
  background-color: var(--white);
  padding: 30px 40px;
}

.react-notification-root {
  top: 0;
  left: 0;
}

.notification-content {
  font-family: Lato;
  padding-bottom: 12px !important;
}

.notification-item .notification-title {
  font-size: 16px !important;
}

.notification-item .notification-message {
  font-size: 15px !important;
  line-height: 1.4 !important;
}

.admin-div-loader {
  z-index: 100;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.study-material-loader{
  z-index: 100;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.05); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-loader {
  border: 8px solid #ffffff;
  border-radius: 50%;
  border-top: 8px solid var(--primary);
  width: 60px;
  height: 60px;
  -webkit-animation: admin-spin 1s linear infinite; /* Safari */
  animation: admin-spin 1s linear infinite;
}

@-webkit-keyframes admin-spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes admin-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.list-loader {
  border: 4px solid var(--primary-rgba);
  border-radius: 50%;
  border-top: 4px solid var(--primary);
  width: 40px;
  height: 40px;
  -webkit-animation: admin-spin 0.5s linear infinite; /* Safari */
  animation: admin-spin 0.5s linear infinite;
}

.admin-header {
  z-index: 100 !important;
	position: sticky;
	top: 5px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
  /* background-color: var(--white); */
  margin-bottom: 16px;
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(0,0,0,0.14);
}

.app-header {
  z-index: 2200000;
	position: fixed;
  top: 55px;
  right: 50px;
	width: 69.16vw;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
  background-color: transparent;
  margin-bottom: 16px;
}

.app-header{display: flex; align-self: flex-end; justify-content: space-between; left:250px; padding-left: 12px; width: auto;}

/*Page header*/
.studioProjectWrapper .studioProjectContainer .filtersBlock{border-bottom: 1px solid #dadada; padding-bottom: 10px;}

.page-header.withStyle{border-bottom: 1px solid #dadada; padding-bottom:10px}
.page-header.withStyle h1{margin-bottom: 0;}


.page-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: transparent;
}

.page-header.styleChange{align-items: center;}

.page-header-right {
  z-index: 10;
  display: flex;
  align-items: center;
}

.page-header-right button {
  margin-left: 30px;
}

.page-header-right select {
  min-width: 150px;
  font-family: Lato;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 8px 14px;
  border-radius: 6px;
  margin-left: 20px;
}

.page-header-right select:first-child {
  margin-left: 0;
}

.page-data {
  position: relative;
  margin-top: 40px;
  width: 100%;
}

.search-head {
  display: flex;
  align-items: center;
}

.search-head img {
  width: 18px;
  margin-right: 8px;
}

.search-head input {
	width: 320px;
	border: none;
	outline: none;
	background-color: var(--white);
	font-family: Lato;
  font-size: 18px;
}

.app-search-head {
  width: 360px;
  border-radius: 30px;
  height: 45px;
  /*background-color: var(--primary-rgba);*/
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-search-head img {
  width: 20px;
  margin-left: 16px;
}

.app-search-head input {
  background-color: transparent;
  border: none;
  height: 100%;
  width: calc(100% - 62px);
  font-family: Lato;
  font-size: 16px;
  margin-right: 16px;
}

.profile {
	display: flex;
	align-items: center;
}
.profile-option .colorbg{background-color: var(--primary); border-radius:5px; display: flex; align-items: center; justify-self:center}
.profile-option .colorbg img{padding:5px; margin-right: 0;}
.profile-option {
  position: relative;
	margin-right: 25px;
	font-family: Lato;
	font-weight: 600;
	font-size: 15px;
	display: flex;
	align-items: center;
  cursor: pointer;
  user-select: none;
}

.profile-option .profileArrow{width: 17px; margin-right: 0;}

#dropper .profile-option {
  margin-right: 0;
}

#dropper .profile-option span{word-wrap: break-word; max-width: 160px;}

.profile-option img {
  width: 25px;
  margin-right: 8px;
}

.profile-option div {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}

.profile-option:last-child {
	margin-right: 0px;
}

.profile-option:last-child img {
	margin-right: 8px;
}

.main-part {
  z-index: 1;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  height: calc(100% - 60px);
  overflow-y: auto;
}

.popup-content {
  z-index: 100 !important;
  position: relative;
  width: 600px;
  height: auto;
  background-color: white;
  border-radius: 15px;
  border: none !important;
  padding: 32px 40px !important;
  overflow: hidden;
}

.image-content {
  width: 100vw !important;
  height: 100vh !important;
  background: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-content .close {
  position: absolute;
  top: 30px;
  right: 40px;
  font-weight: bold;
  color: var(--white);
  font-size: 31px !important;
  cursor: pointer;
  user-select: none;
}

.image-popup {
  position: relative;
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-popup img {
  max-height: 100%;
  max-width: 100%;
  transition: .3s all;
  cursor: zoom-in;
}

.image-popup img:hover {
  transform: scale(1.2);
}

.description-popup {
  height: auto;
  width: 100%;

  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  font-size: 18px;
  line-height: 1.7;
  color: var(--text-black2);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  position: fixed; top:0;
  right:0; bottom:0; left:0;
  
}

.description-popup-container{padding: 20px;  background-color: var(--white);  border-radius: 15px; width: 100%; max-width: 600px; max-height: 500px; overflow-y: auto;}

.popup-doubt-content, .popup-doubt2-content {
  z-index: 100 !important;
  position: relative;
  width: 860px !important;
  height: 480px;
  height: auto;
  background-color: white;
  border-radius: 15px;
  border: none !important;
  padding: 32px 40px !important;
  overflow: hidden;
}

.popup-doubt2-content {
  height: 400px !important;
}

.react-multiple-carousel__arrow {
  z-index: 10 !important;
}

.popup-content2-content {
  position: relative;
  width: 600px;
  height: auto;
  background-color: white;
  border-radius: 15px;
  border: none !important;
  padding: 32px 40px !important;
  overflow: hidden;
}

.popup-header, .popup-header2 {
  width: 100%;
  text-align: center;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  color: var(--text-black);
}

.popup-header2 {
  font-size: 20px;
}

.popup-content .close, .popup-content2-content .close {
  position: absolute;
  top: 30px;
  right: 40px;
  font-weight: bold;
  font-size: 21px;
  cursor: pointer;
  user-select: none;
}

.popup-content .close:hover, .popup-content2-content .close:hover {
  opacity: 0.8;
}

.popup-content3-content {
  width: 600px !important;
  height: 340px !important;
  background-color: white;
  border-radius: 25px;
  border: none !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 0 !important;
}

.popup-content6-content {
  width: 600px !important;
  height: auto !important;
  background-color: white;
  border-radius: 25px;
  border: none !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 40px !important;
}

.assessment-descrip {
  width: 100%;
  height: 100%;
}

.assessment-descrip {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.assessment-descrip div {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.assessment-descrip span {
  font-family: Lato;
  color: var(--text-black2);
  font-size: 18px;
  font-weight: 700;
}

.assessment-descrip textarea {
  margin-top: 20px;
  width: 100%;
  height: 150px;
  border-color: var(--primary);
  background-color: var(--primary-rgba);
  border-radius: 4px;
  font-family: Lato;
  font-size: 16px;
  line-height: 1.5;
  padding: 12px 20px;
}

.popup-content4-content {
  width: 700px !important;
  height: auto ;
  background-color: white;
  border-radius: 25px;
  border: none !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 35px 40px !important;
}

.popup-content5-content {
  position: relative;
  width: 600px;
  height: auto;
  background-color: white;
  border-radius: 15px;
  border: none !important;
  padding: 32px 40px !important;
  overflow: hidden;
}

.popup-data4 {
  width: 100%;
  height: auto;
}

.delete-up {
  height: 270px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Lato;
  font-weight: 600;
  font-size: 23px;
  color: var(--text-black);
  letter-spacing: 0.01em;
  line-height: 1.5;
}

.delete-up img {
  width: 74px;
  margin-bottom: 20px;
}

.delete-down {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
}

.delete-down button {
  width: 50% !important;
  height: 100% !important;
  padding: 0 0 !important;
  border: none;
  font-family: Lato;
  font-size: 24px;
  border-radius: 0 !important;
}

.delete-down button:hover {
  opacity: 0.85;
}

.delete-down button:first-child {
  background-color: var(--white);
  color: var(--primary);
}

.delete-down button:last-child {
  background-color: var(--primary);
  color: var(--white);
}

.popup-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  padding-top: 25px;
  padding-bottom: 25px;
}

.popup-data2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 195px;
  padding-top: 45px;
  padding-bottom: 30px;
}

.popup-data4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 45px;
  padding-bottom: 0px;
}

.popup-data5 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  padding-top: 45px;
  padding-bottom: 0px;
}

.condition-goto {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.condition-goto img {
  width: 80px;
  margin-bottom: 20px;
}

.condition-goto a {
  font-size: 21px !important;
  border-radius: 50px !important;
  padding: 10px 20px 11px 20px !important;
}

.condition-goto span {
  margin-top: 20px;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0.01em;
}

.popup-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.popup-content .popup-inputs:last-child{margin-bottom: 0;}
.popupx3-inputs{margin-bottom: 15px;}
.popupx-inputs, .popup-inputs5{margin-bottom: 15px;}
.popup-data4 .popup-inputs5{margin-bottom: 15px;}
.popup-data4 .popup-inputs5:last-child{margin-bottom: 0;}
.popupx-dropdowns{margin-bottom: 15px;}
.popup-data4 .singlerow{margin-bottom: 15px;}
.popup-content4-content{height: auto;}
.popup-instructions {
  margin-top: 20px;
  width: 100%;
  height: 125px;
  border-color: var(--primary);
  border-radius: 4px;
  font-family: Lato;
  font-size: 16px;
  line-height: 1.5;
  background-color: var(--secondary-rgba);
  padding: 12px 20px;
}

#popup-inputs-y {
  justify-content: flex-start;
}

.popup-inputs2 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.popup-inputs2 .img-prev {
  margin-right: 35px;
  margin-left: 0;
}

.popup-inputs input, .popup-inputs2 input, .popup-inputs select, .popup-inputs5 input {
  font-family: Lato;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

.popup-inputs5 input {
  width: 60%;
}

.popup-inputs5 span {
  width: 160px;
}

.popupx-inputs, .popup-inputs5 {
  display: flex;
  align-items: center;
}

.popup-admin {
  display: flex;
  align-items: center;
}

.popup-admin-child {
  display: flex;
  align-items: center;
  margin-right: 45px;
}

.popup-admin-child span {
  font-family: Lato;
  font-weight: 400;
  color: var(--text-black);
  font-size: 18px;
  margin-left: 20px;
}

.popup-admin-child:last-child {
  margin-right: 0;
}

.popupx-inputs .asm-setting-duration {
  margin-top: 0 !important;
  margin-left: 25px !important;
}

.popupx-inputs span, .popup-inputs5 span {
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
}

.popupx-inputs input, .popupx2-inputs input, .popupx3-inputs input {
  width: 50%;
  font-family: Lato;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

.popupx2-inputs input {
  width: 100%;
}

.popupx3-inputs {
  display: flex;
  justify-content: space-between;
}

.popupx3-inputs input {
  height: 43px;
}

.popupx3-inputs input:first-child {
  width: 46% !important;
}

.popupx3-inputs input:nth-child(2) {
  width: 50% !important;
}

.popup-inputs input:first-child, .popup-inputs select:first-child {
  width: 64%;
}

.popup-inputs select:nth-child(2) {
  width: 33%;
}

#sp-popup-inputs, #doc-input {
  width: 100%;
}

.img-prev {
  width: max-content;
  height: max-content;
  padding: 8px 20px;
  cursor: pointer;
  background-color: var(--secondary-rgba);
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.img-prev span {
  color: var(--primary);
  font-size: 22px;
  margin-right: 16px !important;
  margin-left: 0 !important;
}

.img-prev:hover {
  opacity: 0.8;
}

.img-prev img {
  max-height: 50px;
  max-width: 100px;
}

.css-19gs8ny-Popover {
  padding: 8px 8px !important;
}

.css-mw1il1-ColorPicker {
  width: 160px !important;
}

.popup-color {
  width: 32%;
  height: 100%;
  display: flex;
  align-items: center;
}

#back_name {
  text-transform: uppercase;
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  color: var(--text-black);
  margin-left: 12px;
}

.css-cnfek5-Popover {
  top: -10px !important;
}

.css-1yn2e29-InputColor {
  height: 100% !important;
}

.css-yxnuqv-ColorPicker {
  width: 150px !important;
  height: 100px !important;
}

.css-199jh5z-ColorPicker {
  display: none !important;
}

.popup-dropdowns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-dropdown-div {
  width: 31%;
  display: flex;
  flex-direction: column;
}

.popup-dropdown-div label {
  padding-left: 10px;
  padding-right: 10px;
  font-family: Lato;
  font-weight: 700;
  color: var(--primary);
  font-size: 16px;
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
  padding:0;
}

.popup-data select, .popup-data2 select {
  width: 100%;
  font-family: Lato;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

#slight-popup-data {
  padding-top: 24px;
}

.popup-data4 select {
  width: 35%;
  font-family: Lato;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

.popup-data4 select:first-child {
  margin-right: 25px;
}

.popup-inputs input:last-child {
  width: 32%;
}

.popup-actions {
  text-decoration: none;
  width: 100%;
  bottom: 35px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.sub-head {
  margin-top: 16px;
	width: 100%;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.sub-head2 {
  margin-top: 24px;
	width: 100%;
	display: flex;
	align-items: baseline;
  justify-content: flex-end;
  font-family: Lato;
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
}

.sub-head2 a {
  color: var(--primary);
}

.heading-part1 {
	min-width: 50%;
}

.heading-part2 {
  display: flex;
  align-items: center;
  min-width: 50%;
  justify-content: flex-end;
}

.heading-part2 button:last-child {
  margin-left: 15px;
}

.heading-part2 select {
  font-family: Lato;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 9px 14px;
  border-radius: 4px;
}

.heading {
  display: flex;
  align-items: center;
  overflow-x: hidden;
}

#trace-back {
  display: block;
  width: 46px;
  height: 38px;
  border-radius: 8px;
  background-color: var(--secondary-rgba);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 0 !important;
  margin-right: 14px;
}

#trace-back:hover {
  opacity: 0.8;
}

#trace-back img {
  width: 20px;
}

.filter-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filter-buttons span {
  font-weight: 700;
  font-family: Lato;
  font-size: 18px;
  cursor: pointer;
  color: var(--primary);
}

.filter-buttons button {
  margin-left: 20px;
}

h1 {
	font-weight: 600;
	font-family: Lato;
	font-size: 24px;
	color: var(--text-black);
	margin: 0 0;
}

h2 {
  font-weight: 600;
  line-height: 1.4;
	font-family: Lato;
	font-size: 19px;
	color: var(--text-black);
	margin: 0 0;
}

h3 {
  font-weight: 600;
  line-height: 1.4;
	font-family: Lato;
	font-size: 18px;
	color: var(--text-black);
	margin: 0 0;
}

.sub-head span, .sub-head a {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--secondary);
  padding: 11px 14px 12px 14px;
  margin-left: 20px;
  cursor: pointer;
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
  color: white;
  text-decoration: none;
}

.sub-head span:hover, .sub-head a:hover {
  opacity: 0.8;
}

.sub-head img {
  width: 18px;
  margin-right: 8px;
}

.sub-filter {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
  margin-bottom: 10px;
}

.sub-filter select, .sub-filter-x {
  background-color: var(--primary-rgba);
  border: 1px solid var(--primary);
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 12px;
  width: 200px;
  border-radius: 5px;
  margin-right: 25px;
}

.sub-filter-x {
  cursor: default;
  background-color: var(--primary-rgba);
  border: 1px solid var(--primary);
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 16px;
  width: 200px;
  border-radius: 5px;
  margin-right: 25px;
}

.sub-filter select:last-child {
  margin-right: 0;
}

#send-invite {
  display: flex;
  align-items: center;
  font-family: Lato;
  color: #374957;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

#send-invite img {
  width: 20px;
  margin-right: 10px;
}

#send-invite:hover {
  color: var(--primary);
}

.list-head {
  position: sticky;
  top: 0px;
	background-color: var(--white);
	width: 100%;
	border-bottom: 1px solid #ccc;
	padding: 20px 24px 16px 24px;
	display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 18px;
}

.list-headx {
  position: sticky;
  top: 0px;
	background-color: var(--white);
	width: 100%;
	padding: 20px 24px 16px 24px;
	display: flex;
	align-items: center;
  margin-bottom: 18px;
}

.list-head div {
	font-family: Lato;
	color: var(--black2);
	font-size: 16px;
	font-weight: 700;
	padding-left: 1px;
}

.the-list {
  width: 100%;
	padding-left: 3px;
  padding-right: 3px;
}

.list-item {
	width: 100%;
	display: flex;
	box-shadow: 1px 1px 6px 0px #eeeeee;
  padding: 20px 24px;
  min-height: 70px !important;
	border-radius: 10px;
  margin-bottom: 15px;
  background-color: var(--secondary-rgba);
}

.list-item div {
	font-family: Lato;
	color: var(--text-black2);
	font-size: 16px;
	font-weight: 400;
  padding-right: 40px;
  overflow-wrap: break-word;
}

.list-item div:last-child {
	padding-right: 0;
}

.the-grid {
  margin-top: 50px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill,minmax(30%,1fr));
  grid-template-columns: repeat(3,minmax(0,1fr));
  padding-bottom: 40px;
}

.grid-item {
  width: 100%;
  height: 200px !important;
  border-radius: 15px !important;
  background-color: var(--secondary-rgba);
  box-shadow: 1px 1px 6px 0px #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px;
}

.grid-up {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.grid-up:nth-child(2) {
  align-items: flex-end;
}

.grid-up span {
  margin-top: 4px;
  font-family: Lato;
  font-size: 16px;
}

#grid-up-span {
  display: -webkit-box;
  overflow: hidden;
  box-decoration-break: clone;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  width: 60%;
  overflow-wrap: break-word;
  line-height: 1.4;
  color: var(--text-black);
}

.grid-image {
  width: 46px;
  height: 46px;
  border-radius: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title-image {
  width: 40px !important;
  height: 40px !important;
  border-radius: 6px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 12px !important;
}

.grid-sec {
  width: 60%;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  font-family: Lato;
  font-size: 14px;
  color: var(--text-black3);
}

.grid-sec2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.grid-sec2 a:last-child {
  margin-top: 10px;
}

#section-button {
  width: max-content !important;
  height: max-content !important;
  color: var(--white);
  background-color: var(--primary);
  padding: 8px 16px;
  border-radius: 10px;
  font-family: Lato;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  text-decoration: none;
}

#section-button:hover {
  opacity: 0.8;
}

#section-button2 {
  width: max-content !important;
  height: max-content !important;
  background-color: transparent !important;
  color: var(--primary);
  font-family: Lato;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 0;
  padding: 0;
}

#section-button2 img {
  width: 18px;
  margin-right: 12px;
}

#section-button2:hover {
  opacity: 0.8;
}

#section-button2:last-child {
  margin-left: 20px;
}

#section-button3 {
  color: var(--primary);
  font-family: Lato;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}

#section-button3:hover {
  opacity: 0.8;
}

.grid-middle {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  font-family: Lato;
  font-size: 14px;
  color: var(--text-black3);
}

.grid-middle span {
  margin-top: 4px;
  font-family: Lato;
  font-size: 16px;
  color: var(--text-black);
}

.grid-down {
  display: flex;
  justify-content: flex-end;
}

.grid-actions {
  display: flex;
  align-items: center;
}

.action-item, .action-item2 {
	display: flex;
	align-items: center;
}

.action-item span, .action-item a, .action-item2 span {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 15px;
	cursor: pointer;
	border-radius: 10px;
}

#asm-edit-button {
  background-color: var(--primary-rgba);
  color: var(--primary);
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
  border-radius: 8px;
  padding: 4px 12px;
  cursor: pointer;
  margin-left: 30px;
  text-decoration: none;
}

#asm-edit-button:hover {
  opacity: 0.8;
}

.action-item span:hover, .action-item a:hover, .action-item2 span:hover {
	background-color: rgba(95, 125, 149, 0.15);
}

.grid-actions span, .grid-actions a {
  display: block;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
  margin-right: 10px;
  margin-top: 0px;
	cursor: pointer;
	border-radius: 10px;
}

.grid-actions span:hover, .grid-actions a:hover {
	background-color: rgba(95, 125, 149, 0.15);
}

.action-item button, .action-item2 button {
	background-color: var(--primary);
	color: var(--white);
	font-family: Lato;
	font-size: 16px;
	border: none;
	font-weight: 600;
	padding: 7px 12px 8px 12px;
	border-radius: 10px;
  cursor: pointer;
  margin-right: 15px;
}

.action-item2 a {
  margin-right: 15px;
}

.action-item2 a:last-child {
  margin-right: 0;
}

.grid-actions button {
	background-color: var(--primary);
	color: var(--white);
	font-family:Lato;
	font-size: 16px;
	border: none;
	font-weight: 600;
	padding: 7px 12px 8px 12px;
	border-radius: 10px;
  cursor: pointer;
}

.action-item button:last-child, .action-item2 button:last-child {
  margin-right: 0;
}

.action-item button:hover, .action-item2 button:hover {
	opacity: 0.95;
}

.grid-actions button:hover {
	opacity: 0.95;
}

.action-item span:last-child, .action-item2 span:last-child {
	margin-right: 0;
}

.grid-actions span:last-child {
	margin-right: 0;
}

.action-item img, .action-item2 img {
	width: 20px;
}

.grid-actions img {
	width: 20px;
}

.asm-head-edit {
  display: flex;
  width: max-content;
  align-items: baseline;
  justify-content: flex-start;
}

/*Assessment*/

.list-assessment .list-title, .the-assessment-list .title-item, .the-assessment-list .title-item2,
.the-assessment-list .title-item3 {
	width: 25%;
}

.title-item3 input[type=checkbox] {
  align-self: center;
}

.list-assessment .list-section, .the-assessment-list .section-item {
	width: 12.5%;
}

.list-assessment .list-class, .the-assessment-list .class-item {
	width: 12.5%;
}

.list-assessment .list-email, .the-assessment-list .email-item {
  width: 25%;
  overflow-wrap: break-word;
  display: flex;
  align-items: flex-start;
}

.list-assessment .list-subject, .the-assessment-list .subject-item {
	width: 12.5%;
}

.list-assessment .list-faculty, .the-assessment-list .faculty-item {
	width: 15%;
}

.list-assessment .list-action, .the-assessment-list .action-item {
	width: 25%;
}

.the-assessment-list .action-item2 {
  width: 35%;
}

.list-assessment .list-action2 {
  width: 35%;
}

.title-grid, .title-grid2, .title-grid3, .title-grid4 {
  width: 60%;
}

.title-grid, .title-item {
  font-family: Lato;
	display: flex;
	flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  color: var(--text-black);
}

.title-grid3 {
  font-family: Lato;
  display: flex;
  align-items: flex-start;
}

.title-grid3 img {
  width: 25px;
  margin-top: 3px;
  margin-right: 12px;
}

.title-grid3 div {
  display: flex;
  flex-direction: column;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
}

.title-grid3 div span {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.title-grid4 {
  font-family: Lato;
  display: flex;
  align-items: center;
}

.title-item3 {
  font-family: Lato;
  display: flex;
  align-items: flex-start;
}

.title-grid4 img, .title-item3 img {
  width: 40px;
  margin-right: 12px;
}

.dp_div {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 12px;
}

#study-material-title {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
}

.title-item2 {
  text-decoration: none;
  font-family: Lato;
  display: flex;
  cursor: pointer;
  color: var(--text-black);
}

.title-grid2 {
  text-decoration: none;
  font-family: Lato;
  display: flex;
  align-items: center;
  color: var(--text-black);
}

.section-item {
  display: flex;
  align-items: flex-start;
}

.title-grid2 div {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 8px;
}

.section-item div {
  display: flex;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 8px;
}

.title-grid span, .title-item span {
  margin-top: 4px;
	font-size: 14px;
	font-weight: 700;
	color: var(--text-black3);
}

.edit-div {
  margin-top: 30px;
  width: 100%;
  min-height: 200px;
  background-color: var(--light-black2);
  border-radius: 20px;
  padding: 45px 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.edit-div-1 {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.edit-dp {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid var(--primary-rgba);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.edit-dp #img-holder{
  color:var(--primary)
}

.edit-dp:hover {
  opacity: 0.8;
}

.edit-dp span {
  background-color: #000000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  color: white;
  font-weight: bold;
  top: 20px;
  font-size: 20px;
}

.edit-dp #img-holder {
  width: 100px !important;
  border-radius: 0;
}

.edit-sub-div-1 {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.edit-sub-div-1 input {
  font-family: Lato;
  width: 320px;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
  margin-top: 20px;
}

.edit-div-2 {
  margin-top: 30px;
  display: flex;
}

.edit-div-2 input, .edit-div-2 div {
  font-family: Lato;
  width: 275px;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
  margin-right: 20px;
}

.edit-div-3, .edit-div-4 {
  margin-top: 30px;
  display: flex;
}

.edit-div-5 {
  margin-top: 30px;
}

.edit-div-3 select, .edit-div-5 select, .edit-div-3 input, .edit-div-4 select {
  font-family: Lato;
  font-size: 16px;
  background-color: var(--secondary-rgba);
  border: 1px solid var(--primary);
  color: var(--text-black);
  padding: 12px 16px;
  border-radius: 4px;
}

.edit-div-3 select, .edit-div-3 input, .edit-div-5 select {
  width: 275px;
  margin-right: 20px;
}

.edit-div-4 select {
  width: 570px;
}

.edit-buttons {
  margin-top: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.edit-buttons button:last-child {
  margin-left: 20px;
}

#no-data {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-weight: 700;
  font-size: 25px;
  padding-top: 150px;
  color: var(--text-black2);
}

.react-notification-root {z-index: 2200000!important;}

@media only screen and (max-width: 600px) { 
  .popup-doubt2-content{height:auto!important; padding:15px!important; width:90%!important;}
  .popup-doubt2-content .ask-doubt-action{margin-top:10px}
  .profile-option div{flex-shrink: 0;}
  .profile-option{margin-right:10px;}
  .profile-option img{margin-right:0px;}
  .popup-content5-content{width: 90%!important; height:auto;padding:15px!important;}
  .popup-content5-content .popup-data5{height:auto; padding-top:20px;}
  .popup-content5-content .popup-inputs{flex-direction:column}
  .popup-content5-content .popup-inputs input{width:100%; margin-bottom:10px;}
}
