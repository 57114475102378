.admin-div {
    overflow: hidden;
    width: calc(100vw - 290px);
    height: calc(100vh - 30px);
    position: fixed;
    top: 15px;
    right: 15px;
    border-radius: 30px;
    background-color: var(--white);
    padding: 30px 40px;
}
.popup-content{position: relative;}
.popup-content.adminCertificatesList{padding: 0!important; position: static; margin: 0;}
.popup-content.adminCertificatesList .close{position: absolute; background: transparent; color: #000; font-size: 34px; padding: 0;  margin-top: 0; top:0px; right:10px}

.add-new-button {
    cursor: pointer;
    color: var(--primary);
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
}
.heading-part2 button:last-child {
    margin-left: 0;
}
.zIndexchange .admin-header{max-width: 100%;}

.popup-content {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    max-width: 900px;
    margin: auto;
}

.popup-content .close {
    position: absolute;

    right: 10px;
    border: none;
    background: var(--primary);
    font-size: 24px;
    cursor: pointer;
}


table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}


button {
    background-color: var(--primary);
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}