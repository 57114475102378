.container {
    text-align: start;
    margin-top: 60px;
    color: white;
    padding: 0 0px;
    margin: 0 0 20px;
}

.app-body {
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 140px;
    right: 300px;
    top: 0;
    background-color: var(--white);
}

.app-div.widthAuto {
    left: 150px
}

.marginTopZero {
    margin-top: 0
}


h2 {
    font-weight: 600;
    line-height: 1.4;
    font-family: Source Sans Pro;
    font-size: 19px;
    color: black;
    margin-left: 5px;

}

.flex-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 40px;


}


.certificate {
    background: #f0f0f0;
    margin: 0px;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative;
    /* Required for aspect ratio hack */
  
    width: 100%;
    max-width: 400px;
}

.certificate img{max-width: 100%;}

.iframe-container {
    width: 100%;
    height: 200px;
    border: none;
    object-fit: contain;
}

.iframe-container img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.iframe-container {
    height: 650px;
    width: 100%;
}


